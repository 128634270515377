import { allGuestUserList, guestList, viewGuestUserList } from "../../actionCreators/admin/guestManagement";
import { call, put, takeEvery } from "redux-saga/effects";
import GuestManagementService from "../../../services/admin/guestManagement";
import { CREATE_GUEST_WATCHER, UPDATE_GUEST_WATCHER, DELETE_GUEST_WATCHER, GET_GUEST_WATCHER, ADD_GUEST_USER, GET_ALL_GUEST_USER, GET_VIEW_GUEST_USER, UPDATE_VIEW_GUEST_USER, DELETE_VIEW_GUEST_USER } from "../../actionTypes/admin";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getGuestEffect(action) {
    try {
      yield put(updateOpenLoader());
      let { data } = yield call(GuestManagementService.getAll, action.payload);
  
      yield put(guestList(data.recordset));
      yield put(updateFalseLoader());
      //yield put(updateAlert('Success', 'success'));

    } catch (e) {
      yield put(updateFalseLoader());
      yield put(updateAlert('Something Went Wrong!', 'error'));
    }
  }
  
function* createGuestEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestManagementService.create, action.payload);
    action.setOpen(false)
    yield put(guestList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* updateGuestEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestManagementService.update, action.id, action.payload);
    yield put(guestList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* deleteGuestEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestManagementService.delete, action.id, action.payload);

    yield put(guestList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Deleted Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* addGuestUserEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(GuestManagementService.addUser, action.payload);
    action.setAddDialogOpen(false);
    yield put(updateFalseLoader());
    yield put(updateAlert('Added Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getAllGuestUserEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestManagementService.getAllConUser, action.payload);
    yield put(allGuestUserList(data.recordset));

    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getViewGuestUserEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestManagementService.getViewConUser, action.payload);
    yield put(viewGuestUserList(data.recordset));

    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* updateViewGuestUserEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(GuestManagementService.updateViewConUser, action.payload);
    let getdata = {nGuestID: action.nGuestID}
    let { data : data1 } = yield call(GuestManagementService.getViewConUser, getdata);
    yield put(viewGuestUserList(data1.recordset));

    yield put(updateFalseLoader());
    yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.message, 'error'));
  }
}

function* deleteViewGuestUserEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(GuestManagementService.deleteViewConUser, action.payload);
    let getdata = {nGuestID: action.nGuestID}
    let { data: data1 } = yield call(GuestManagementService.getViewConUser, getdata);
    yield put(viewGuestUserList(data1.recordset));

    yield put(updateFalseLoader());
    yield put(updateAlert('Deleted Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

//saga 
export const GuestManagementSaga= [
     takeEvery(GET_GUEST_WATCHER, getGuestEffect),
     takeEvery(CREATE_GUEST_WATCHER, createGuestEffect),
     takeEvery(UPDATE_GUEST_WATCHER, updateGuestEffect),
     takeEvery(DELETE_GUEST_WATCHER, deleteGuestEffect),
     takeEvery(ADD_GUEST_USER, addGuestUserEffect),
     takeEvery(GET_ALL_GUEST_USER, getAllGuestUserEffect),
     takeEvery(GET_VIEW_GUEST_USER, getViewGuestUserEffect),
     takeEvery(UPDATE_VIEW_GUEST_USER, updateViewGuestUserEffect),
     takeEvery(DELETE_VIEW_GUEST_USER, deleteViewGuestUserEffect)
]


// food clerk
import Dashboard from '../pages/foodClerk';
import Menu from "../pages/user/menu";
import OrderHistory from "../pages/user/orderManagement/orderHistory";
import ViewCart from "../pages/user/viewCart";
//profile
import Profile from '../pages/profile'
//layout
import NavLayout from '../layouts/dashboard';

const FoodClerk =  {
  path: '/foodClerk',
  element: <NavLayout />,
  children: [
    { index: true, element: <Dashboard /> },
    { path: "profile", element: <Profile /> },
    { path: "employeeBooking", element: <Menu /> },
    { path: "contractorBooking", element: <Menu /> },
    // { path: "kiosk", element: <Kiosk /> },
    { path: "orderHistory", element: <OrderHistory /> },
    { path: "viewCart", element: <ViewCart /> },
  ]
}

export default FoodClerk;

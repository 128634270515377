import styled from "@emotion/styled";
import { Button } from "@mui/material";

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.Bblack,
    '&:hover': {
      backgroundColor: theme.palette.common.Bblack,
    },
  }));

  export default ColorButton
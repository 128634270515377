import React from 'react';
import { useState } from 'react';
import Scrollbar from '../../../Scrollbar';
import MenuItemTableHead from './tableHead';
import MenuItemTableRow from './tableRow';
// material
import {
    Table,
    TableBody,
    TableContainer,
    TableCell,
    Typography,
    TableRow,
    Pagination,
    Box
} from '@mui/material';

const TABLE_HEAD = [
    { id: '#', label: '#', alignRight: false },
    { id: 'cMealName', label: 'Meal Name', alignRight: false },
    { id: 'cMenuName', label: 'Menu Code', alignRight: false },
    { id: 'cItemName', label: 'Item Name', alignRight: false },
    { id: 'cItemDesc', label: 'Item Description', alignRight: false },
    { id: 'Division', label: 'Division', alignRight: false },
    { id: 'nQuantity', label: 'Quantity', alignRight: false },
    { id: 'nRate', label: 'Rate', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const MenuItemMasterTable = ({searchVal, searchText, tableArr, divisionId, page, setPage}) => {

    let firstIndex = 0;

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('mealName');
    const [rowsPerPage] = useState(5);
    const [disableEdit, setDisableEdit] = useState(false);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    const tableData = searchText ? searchVal : tableArr
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800, overflow:'unset' }}>
                        <Table>
                            <MenuItemTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                //   rowCount={USERLIST.length}
                                //   numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    // .slice(page * 5, page * 5 + 5)
                                    .map((m, i) => {
                                        return (
                                            <MenuItemTableRow divisionId={divisionId} key={m.ID} rowData={m} index={i} setDisableEdit={setDisableEdit} disableEdit= {disableEdit} page={page}/>
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>

                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default MenuItemMasterTable;
import { menuMasterList, mealDropdownList, menuDropdownList } from "../../actionCreators/admin/menuMaster";
import { call, put, takeEvery } from "redux-saga/effects";
import MenuMasterService from "../../../services/admin/menuMaster";
import { GET_MENU_MASTER_WATCHER, UPDATE_MENU_MASTER_WATCHER, CREATE_MENU_MASTER_WATCHER, DELETE_MENU_MASTER_WATCHER, GET_MEAL_DROPDOWN_WATCHER, GET_MENU_DROPDOWN_WATCHER } from "../../actionTypes/admin";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getMenuMasterEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(MenuMasterService.getAll, action.payload);
    yield put(menuMasterList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    //yield put(updateAlert('Error', 'error'));
  }
}

function* getMealDropDownEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(MenuMasterService.getMealDropDown);
    yield put(mealDropdownList(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    //yield put(updateAlert('Error', 'error'));
  }
}

function* getMenuDropDownEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(MenuMasterService.getMenuDropDown, action.payload);
    yield put(menuDropdownList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* createMenuMasterEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(MenuMasterService.create, action.payload);
    action.setOpen(false)
    yield put(menuMasterList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* updateMenuMasterEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(MenuMasterService.update, action.id, action.payload);

    yield put(menuMasterList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* deleteMenuMasterEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(MenuMasterService.delete, action.id, action.payload);

    yield put(menuMasterList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Deleted Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}


//saga 
export const MenuMasterSaga = [
  takeEvery(GET_MENU_MASTER_WATCHER, getMenuMasterEffect),
  takeEvery(UPDATE_MENU_MASTER_WATCHER, updateMenuMasterEffect),
  takeEvery(CREATE_MENU_MASTER_WATCHER, createMenuMasterEffect),
  takeEvery(DELETE_MENU_MASTER_WATCHER, deleteMenuMasterEffect),
  takeEvery(GET_MEAL_DROPDOWN_WATCHER, getMealDropDownEffect),
  takeEvery(GET_MENU_DROPDOWN_WATCHER, getMenuDropDownEffect),

]



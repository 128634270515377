import React, { useEffect } from 'react';
import { Card, Grid, Button, InputAdornment, Stack, Typography } from "@mui/material";
import SearchField from '../../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import BlackButton from '../../overrides/blackButton';
import { getConvertedDate } from '../../../../utils/common';
import { ExportCsv } from "@material-table/exporters";
import { addMonths, subMonths } from 'date-fns';

import { useSelector } from 'react-redux';

const CommonHeaderForTapReport = ({ handleSearch, searchText, handleSubmitAction, rowData, columnData, defaultDate = null, vendorState,setVendorState, invalidErr, setInvalidErr }) => {

    console.log("rowData",rowData)
    // const [open, setOpen] = useState(false);
    let last = new Date();
    last.setDate(last.getDate() - 1)
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const [fieldErr, setFieldErr] = React.useState(false)
    const currentDate = new Date()
    const division = localStorage.getItem('division')
    const { UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)
    const threeMonthsAgo = subMonths(currentDate, 3);
    useEffect(()=>{
        setFromDate(null)
        setToDate(null)
        setVendorState('')
    }, [cafeteriaId])

    const handleFromChange = (e) => {
        setFromDate(e)
        // setToDate(null)
        // if(fromDate <= toDate){
        //     setInvalidErr({ ...invalidErr, fromInvalidErr:false, toInvalidErr:false})
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr:true, toInvalidErr:true})
        // }
    }

    const handleToChange = (e) => {
        setToDate(e)
        // if(fromDate !== null){
        //     setToDate(e)
        //     setFieldErr(false)
        //     if(toDate <= fromDate){
        //         setInvalidErr({ ...invalidErr, fromInvalidErr:false, toInvalidErr:false})
        //     }
        //     else{
        //         setInvalidErr({ ...invalidErr, fromInvalidErr:true, toInvalidErr:true})
        //     }
        // }
        // else{
        //     // setToDate(null)
        //     setFieldErr(true)
        // }
    }

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={4} xs={12} md={4} sm={4} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px',

                                }
                            }}
                            placeholder='Search' fullWidth onChange={handleSearch} value={searchText} />
                    </Grid>
                    <Grid item lg={8} xs={12} md={8} sm={8} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={fromDate}
                                    label='From Date'
                                    // maxDate={currentDate}
                                    maxDate={currentDate}
                                    minDate={threeMonthsAgo}
                                    onChange={handleFromChange}
                                    renderInput={(params) => <SearchField sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '48px',

                                            },
                                            '& label': {
                                                fontSize: '.9rem'
                                            }
                                        }} fullWidth {...params} 
                                        error={invalidErr.fromInvalidErr === true ? true : false}
                                        helperText={invalidErr.fromInvalidErr === true ? 'Invalid Date' : ''}
                                    />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={fromDate !== null ? toDate : null}
                                    label='To Date'
                                    maxDate={currentDate}
                                    minDate={threeMonthsAgo}
                                    // minDate={fromDate}
                                    // maxDate={currentDate}
                                    onChange={handleToChange}
                                    renderInput={(params) => <SearchField sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '48px',
                                            },
                                            '& label': {
                                                fontSize: '.9rem'
                                            }
                                        }} fullWidth {...params} 
                                        error={invalidErr.toInvalidErr === true ? true : fieldErr === true ? fromDate !== null ? false : true : false}
                                        helperText={invalidErr.toInvalidErr === true ? 'Invalid Date' : fieldErr === true ? fromDate !== null ? '' : 'Please Choose From Date First..!' : ''}
                                    />}
                                />
                            </LocalizationProvider>

                        </Stack>
                    </Grid>
              
                    <Grid item lg={4} xs={12} md={4} sm={4} >
                        <Button
                            variant='contained'
                            sx={{
                                height: '48px',
                                backgroundColor: 'common.Bblack',
                                '&:hover': { backgroundColor: 'common.Bblack' }
                            }}
                            fullWidth
                            endIcon={<DoubleArrowIcon />}
                            onClick={() => {
                                handleSubmitAction(getConvertedDate(fromDate), getConvertedDate(toDate))
                                setFieldErr(false)
                                // if (fromDate === null || toDate === null) {
                                //     setFromDate(new Date())
                                //     setToDate(new Date())
                                // }
                            }}
                        >
                            <Typography variant='t16_400_1'>Submit</Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={4} xs={12} md={4} sm={4} display='flex'>
                        <Button variant='contained' sx={{ height: '49px' }} endIcon={<DoubleArrowIcon />} fullWidth onClick={() => ExportCsv(columnData, rowData, "Export file")}>
                            <Typography variant='t16_500'>Export to Excel</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default CommonHeaderForTapReport;
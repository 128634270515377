import React,{useContext, useEffect} from 'react'
import { Typography, Grid } from "@mui/material";
import DayOrder from './dayOrder';
import NextDayBooking from './nextDay';
import { useDispatch, useSelector } from 'react-redux';
import { getContractorDashboardWatcher } from '../../../redux/actionCreators/contractor';
import VendorDialog from '../../user/home/vendorDialog';
import Context from '../../../context/context';

export default function Dashboard() {
    const dispatch = useDispatch();
    const { ProfileReducers: { loginState } } = useSelector(state => state)
    const cEmployeeName = loginState[0]?.cEmployeeName || ''
    const userId = loginState[0]?.nID || ''
    const vendorId = sessionStorage.getItem('vendorId')
    const { state } = useContext(Context)

    useEffect(() => {
        if (vendorId !== null) {
            let data ={
                "ACTION":"ContractorDashboard",
                UserID: userId,
                CVENDORID: vendorId !== "" ? `${vendorId}` : 'null'
            }
            dispatch(getContractorDashboardWatcher(data));
        }
    }, [userId, dispatch, vendorId])
    
    return (
        <>        
            {vendorId == null && <VendorDialog />}
            <Grid container style={{ padding: '24px' }}>
                <Grid item xs={12}>
                    <Typography variant='t12_400' >Dashboard</Typography>
                </Grid>
                <Grid item mt='8px' xs={12}>
                    <Typography variant='t24_700'>{`Welcome, ${cEmployeeName}`}</Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '20px' }}>
                    <DayOrder />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '20px' }}>
                    <NextDayBooking />
                </Grid>
            </Grid>
        </>
    )
}

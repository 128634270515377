import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card, Button } from "@mui/material";
import CommonHeader from '../../../components/foodVendor/commonHeader/header';
import OrderTrackingTable from '../../../components/admin/reports/orderTracking/orderTrackingTable';
import { getOrderTrackingWatcher } from '../../../redux/actionCreators/admin/reports';
import { useDispatch, useSelector } from 'react-redux';
import { TABLE_HEAD } from '../../../components/admin/reports/orderTracking/orderTrackingTable';
import SplOrderSubTable, { SUBTABLE_HEAD } from '../../../components/foodVendor/reports/specialOrderTracking/subTable'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { ExportCsv } from "@material-table/exporters";
import { SUB_TABLE_HEAD } from '../../../components/admin/reports/orderTracking/orderTrackChildTable';
import Context from '../../../context/context';
import { getConvertedDate } from '../../../utils/common';

const OrderTracking = () => {

    const { ReportsReducers: { OrderTrackingList }, ProfileReducers: {loginState}, UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)

    // const vendorId = loginState[0]?.nVendorID || ''
    const role = loginState[0]?.nRoleID || ''

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [pageChange, setPageChange] = useState(false)
    const [page, setPage] = useState(1);
    const [eventVal, setEventVal] = useState('')
    const { state, setState } = useContext(Context);
    const [vendorState, setVendorState] = useState('')
    // const [fieldErr, setFieldErr] = React.useState({ fromErr : false, toErr : false })
    const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = OrderTrackingList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }
    const nUserID = loginState[0]?.nID || 0

    const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''

    const handleSubmitAction = (fromDate, toDate) => {
        let currentDate = new Date()
        let curDate = getConvertedDate(currentDate)

        // if(toDate >= fromDate && toDate <= curDate && fromDate <= curDate){
            console.log('insisde coside')
            const data = {
                nUserID,
                "cAction": "TrackSearch",
                "Fdate": fromDate !== null ? fromDate : currentDate,
                "Tdate": toDate !== null ? toDate : currentDate,
                NDIVISIONID: cafeteriaId,
                // CVENDORID: state !== "" ? `${state}` : 'null'
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getOrderTrackingWatcher(data))
            setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
            setPage(1)
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true})
        // }
    }

    useEffect(() => {
        if (cafeteriaId) {
            let data = {
                "cAction": "Track",
                NDIVISIONID: cafeteriaId,
                CVENDORID: nRoleID === 3 ? String(vendorId) :  'null'
                // CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getOrderTrackingWatcher(data))
        }
    }, [dispatch, cafeteriaId])

    useEffect(()=>{
        if(eventVal)
        handleSearch(eventVal);
    },[OrderTrackingList])

    return (
        <>
            {!pageChange ?
                <Box p="24px">

                    <Breadcrumbs content={["Reports", "Order Tracking"]} />

                    <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                        <Typography variant="t24_700"> Order Tracking </Typography>
                    </Box>
                    <Box p='10px 0'>
                        <CommonHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={OrderTrackingList} columnData={[...TABLE_HEAD,...SUB_TABLE_HEAD]} defaultDate={new Date()} setVendorState={setVendorState} invalidErr={invalidErr} setInvalidErr={setInvalidErr}/>
                    </Box>
                    <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                        <OrderTrackingTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                    </Card>

                </Box>

                :

                <>
                    <Box p="24px">
                        <Box p='20px 0' display='flex' justifyContent='right' >
                            <Button sx={{ mr: '15px', minWidth: '100px', }} variant='contained' onClick={() => setPageChange(false)} >
                                Back
                            </Button>
                            <Button variant='contained' sx={{ height: '50px', minWidth: '150px', mr: '15px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(SUBTABLE_HEAD, OrderTrackingList, "Special Order Tracking")}>
                                <Typography variant='t16_500'>Export to Excel</Typography>
                            </Button>

                        </Box>
                        <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                            <SplOrderSubTable />
                        </Card>
                    </Box>
                </>

            }
        </>
    )
}

export default OrderTracking;
import { CREATE_GUEST_WATCHER, UPDATE_GUEST_WATCHER, DELETE_GUEST_WATCHER, GET_GUEST_WATCHER, GUEST_LIST, ADD_GUEST_USER, GET_ALL_GUEST_USER, GET_VIEW_GUEST_USER, UPDATE_VIEW_GUEST_USER, DELETE_VIEW_GUEST_USER, ALL_GUEST_USER_LIST, VIEW_GUEST_USER_LIST } from "../../actionTypes/admin";

// Worker triggering actionCreators

export function getGuestWatcher(data) {
    return { type: GET_GUEST_WATCHER, payload: data };
}

export function createGuestWatcher(data, setOpen) {
    return { type: CREATE_GUEST_WATCHER, payload: data, setOpen };
}

export function updateGuestWatcher(id, data) {
    return { type: UPDATE_GUEST_WATCHER, payload: data, id };
}

export function deleteGuestWatcher(id, data) {
    return { type: DELETE_GUEST_WATCHER, payload: data, id };
}

export function addGuestUserWatcher(data, setAddDialogOpen) {
    return { type: ADD_GUEST_USER, payload: data, setAddDialogOpen};
}

export function getAllGuestUserWatcher(data) {
    return { type: GET_ALL_GUEST_USER, payload: data};
}

export function getViewGuestUserWatcher(data) {
    return { type: GET_VIEW_GUEST_USER, payload: data};
}

export function upadteViewGuestUserWatcher(data, nGuestID) {
    return { type: UPDATE_VIEW_GUEST_USER, payload: data, nGuestID};
}

export function deleteViewGuestUserWatcher(data, nGuestID) {
    return { type: DELETE_VIEW_GUEST_USER, payload: data, nGuestID};
}


// Redux state changing actionCreators
export function guestList(data) {
    return { type: GUEST_LIST, payload: data };
}

export function allGuestUserList(data) {
    return { type: ALL_GUEST_USER_LIST, payload: data };
}

export function viewGuestUserList(data) {
    return { type: VIEW_GUEST_USER_LIST, payload: data };
}

export const LOGIN_WATCHER = "LOGIN_WATCHER";
export const GET_PROFILE_WATCHER = "GET_PROFILE_WATCHER";
export const PROFILE_UPDATE_WATCHER = "PROFILE_UPDATE_WATCHER";
export const PASSWORD_UPDATE_WATCHER = "PASSWORD_UPDATE_WATCHER";
export const FORGET_PASSWORD_WATCHER = "FORGET_PASSWORD_WATCHER";
export const GET_ROLE_NAMES_WATCHER = "GET_ROLE_NAMES_WATCHER";
export const USER_CHECK_WATCHER = "USER_CHECK_WATCHER";
export const SEND_MAIL_WATCHER = "SEND_MAIL_WATCHER";
export const USER_ABOUT_DETAILS_WATCHER = "USER_ABOUT_DETAILS_WATCHER"

//redux state
export const UPDATE_LOGIN = "UPDATE_LOGIN";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_ROLE_NAMES = "UPDATE_ROLE_NAMES";
export const USER_ABOUT_DETAILS_LIST = "USER_ABOUT_DETAILS_LIST"

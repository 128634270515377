// Contractor
import Dashboard from '../pages/contractor/dashboard';
import ContractorBooking from '../pages/contractor/reports/contractorBooking';
import ContractorDeliveryHistory from '../pages/contractor/reports/deliveryHistory'
import OrderBooking from '../pages/contractor/dashboard/orderBooking/orderBooking';
//reports
import CRoleSwipeHistory from '../pages/contractor/reports/CRoleSwipeHistory';
//profile
import Profile from '../pages/profile'
import ChangePassword from '../pages/changePassword'
//layout
import NavLayout from '../layouts/dashboard';

const Contractor =  {
  path: '/contractor',
  element: <NavLayout />,
  children: [
    { index: true, element: <Dashboard /> },
    { path: 'contractorBookingHistory', element: <ContractorBooking /> },
    { path: 'deliveryHistory', element: <ContractorDeliveryHistory /> },
    { path: 'orderBooking', element: <OrderBooking /> },
    { path: "swipeHistory", element: <CRoleSwipeHistory /> },
    { path: "profile", element: <Profile /> },
    { path: "changePassword", element: <ChangePassword /> },
  ]
}

export default Contractor;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CssTextField from "../../../components/overrides/longSearchField";
import { getVendorOnlyWatcher, getVendorWatcher } from "../../../redux/actionCreators/admin/vendorManagement";
import Context from "../../../context/context";
import { clearCartWatcher } from "../../../redux/actionCreators/user/home";

export default function VendorDialog() {
  const [open, setOpen] = React.useState(true);
  const [formValues, setFormValues] = React.useState({ vendor : null})
  const { VendorReducers: { vendorOnlyList, vendorList }, UserHomeReducers: { cafeteriaId, cartList }, ProfileReducers: { loginState } } = useSelector((state) => state)
  const dispatch = useDispatch();
  const { state, setState } = React.useContext(Context);
  const nDivisionID = loginState[0]?.nDivisionID || 0
  const nUserID = loginState[0]?.nID || 0
  // Use the state value
  // console.log('isjeudh',state);

  // Update the state
//   const updateState = () => {
//     setState(newState);
//   };

  React.useEffect(() => {
    // if(cafeteriaId){
        let data = { ACTION: "SELECT", SDIVISIONID: String(nDivisionID) }
        dispatch(getVendorWatcher(data))
    // }
  }, [dispatch])

  // console.log('vendorOnlyList',formValues.vendor)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(formValues.vendor != cartList?.map((d) => d.cVendorID)[0]){
      const cartData = {
        ACTION: "CHECKLIST",
        nUserID,
      };
      dispatch(clearCartWatcher(nUserID, cartData));
    }
    setState(formValues.vendor);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        // onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Please Choose Your Vendor"}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
          <Grid item lg={6} xs={6} md={6}>
            <Box pb="15px">
              <Typography variant="t14_600"> Vendor </Typography>
            </Box>
            <Autocomplete
              id="combo-box-demo"
              name="vendor"
              fullWidth
              options={vendorList}
              size="small"
              autoHighlight
              onChange={(event, value) => {
                if(value !== null){
                    setFormValues({ ...formValues, vendor : value.NID});
                    sessionStorage.setItem('vendorId', value.NID)
                    // console.log('sodfoerg',value.NID, cartList?.map((d) => d.cVendorID)[0])
                    // if(value.NID != cartList?.map((d) => d.cVendorID)[0]){
                    //   const cartData = {
                    //     ACTION: "CHECKLIST",
                    //     nUserID,
                    //   };
                    //   dispatch(clearCartWatcher(nUserID, cartData));
                    // }
                }
                else{
                    setFormValues({ ...formValues, vendor : null });
                }
              }}
              getOptionLabel={(option) => option.CVENDORNAME}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  placeholder="Vendor"
                  name="vendor"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: 50,
                    },
                  }}
                //   error={Boolean(touched.vendor && errors.vendor)}
                //   helperText={touched.vendor && errors.vendor}
                />
              )}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button
            style={{
              width: "100px",
              height: "36px",
              left: "0px",
              top: "0px",
            }}
            variant="contained"
            fullWidth
            size="medium"
            onClick={handleSubmit}
            disabled={formValues.vendor === null ? true : false}
            // onClick={()=>{handleCheckout();setOpenCD(false)}}
          >
            <Typography color="common.white" variant="t14_400">
              Submit
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from 'react';
import Scrollbar from '../../../../../Scrollbar';
// material
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    TableContainer,
    Box,
    Typography
} from '@mui/material';

const TotalQuantityTable = ({ tableData, userType }) => {

    const isUserNotFound = tableData?.length === 0;

    return (
        <>
            <Box >
                <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }} >
                        <Table>
                            <TableHead>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <TableCell >
                                        <Box sx={{ width: 'fit-content', display: 'flex' }}>
                                            <Typography variant='t13_600' >
                                                {'Meal Name'}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell >
                                        <Box sx={{ width: 'fit-content', display: 'flex' }}>
                                            <Typography variant='t13_600' >
                                                {'Total Quantity'}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell >
                                        <Box sx={{ width: 'fit-content', display: 'flex' }}>
                                            <Typography variant='t13_600' >
                                                {'Swiped'}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData?.map(m =>
                                    <TableRow key={m.cMealName}>
                                        <TableCell align="left">
                                            <Typography variant='t12_500' >
                                                {m.cMealName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t12_500' >
                                                {m.Quantity}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t12_500' >
                                                {m.swiped}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
        </>
    )
}

export default TotalQuantityTable;
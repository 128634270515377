import React, { useEffect, useRef, useState } from 'react'
import { Typography, Card, Grid, Box, Autocomplete } from "@mui/material";
import Breadcrumbs from "../../../components/breadcrumbs";
// import ContractorBookTable from '../../../components/admin/kitchen/contractorBook/contractorTable';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CssTextField from '../../../components/overrides/longSearchField';
import BlackButton from '../../../components/overrides/blackButton'
import { createContractBookWatcher, getContractorNameListWatcher, cancelContractBookWatcher, cnsListWatcher, selectAllMealWatcher, noteWatcher, clearCnsListState } from '../../../redux/actionCreators/admin/contractorBook';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TimeCalc, getConvertedDate, getConvertedDate2 } from '../../../utils/common';
// import _ from 'lodash'
import CancelDialog from '../../../components/admin/kitchen/contractorBook/cancelDialog';
import { updateAlert } from '../../../redux/actionCreators/alert';
import { getDropDownWatcher } from "../../../redux/actionCreators/admin/employeeManagement";
import { getVendorWatcher } from '../../../redux/actionCreators/admin/vendorManagement';

export default function ContractorBook() {

    const dispatch = useDispatch();
    const { ContractorBookReducers: { contractorNameList, menuIdList, noteState }, ProfileReducers: { loginState }, EmployeeReducers: { divisionList }, VendorReducers: { vendorList } } = useSelector((state) => state)

    const nUserID = loginState[0]?.nID
    const nDivisionID = loginState[0]?.nDivisionID
    const roleName = loginState[0]?.cRoleName
    const [menuData, setMenuData] = useState()
    const tempinitsform = useRef();
    const tempinits = useRef();
    const tempinit = useRef();

    // const cafeteriaList = cafeteriaName?.filter(m => m.id !== 0) || []

    // const [dateChange, setDateChange] = useState(false)

    const Schema = Yup.object().shape({
        contractorID: Yup.string().min(1, 'Too Short!').max(500, 'Too Long!').required('Please select contractor'),
        vendorID: Yup.string().min(1, 'Too Short!').max(500, 'Too Long!').required('Please select vendor'),
    });

    const formik = useFormik({
        initialValues: {
            contractorID: '',
            vendorID: '',
            orderDate: new Date(),
            supperDate: '',
            divisionId: roleName === 'Admin' ? nDivisionID : '',
        },
        validationSchema: Schema,

        onSubmit: () => {
            const { contractorID, vendorID, orderdate, menuID, orderDate, supperDate, divisionId,...rest } = { ...formik.values }

            let mealArr = []

            Object.keys(rest).forEach((key, index) => {
                const mealObj = menuIdList.find(m => m.cmealName === key) || {}
                if (rest[key] !== 0 && +rest[key] !== +mealObj.nQuantity) {
                    mealArr.push({ ...mealObj, nQuantity: +rest[key] })
                }
            })
            const data = {
                "ACTION": "InsertOrderBooking",
                "nContractorID": `${contractorID}`,
                "bookingData": mealArr,
                nUserID,
                nBookingID: getBookingId(),
                OrderDate:orderDate,
                nDivisionID: values.divisionId,
                CVENDORID: `${values.vendorID}`
            }
            if (mealArr.length) {
                dispatch(createContractBookWatcher(data))
            }
        }
    });

    const initsform = () => {
        // dispatch(getDropDownWatcher());
        dispatch(getContractorNameListWatcher())
        dispatch(selectAllMealWatcher({ "ACTION": "SelectAllMeal" }))
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        let dayAfterTomorrow = new Date();
        dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
        setFieldValue("orderDate", tomorrow)
        setFieldValue("supperDate", getConvertedDate(dayAfterTomorrow))
        dispatch(clearCnsListState())
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, []);

    // useEffect(() => {
    //     dispatch(getDropDownWatcher());
    //     dispatch(getContractorNameListWatcher())
    //     dispatch(selectAllMealWatcher({ "ACTION": "SelectAllMeal" }))
    //     let tomorrow = new Date();
    //     tomorrow.setDate(tomorrow.getDate() + 1);
    //     let dayAfterTomorrow = new Date();
    //     dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    //     setFieldValue("orderDate", tomorrow)
    //     setFieldValue("supperDate", getConvertedDate(dayAfterTomorrow))
    // }, [])

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = formik;

    useEffect(() => {
        dispatch(noteWatcher({ role: 'admin', orderDate: values.orderDate }))
    }, [values.orderDate]);

    const inits = () => {
        if (values.vendorID && values.contractorID) {
            let data = {
                "ACTION": "Cns",
                "nID": `${values.contractorID}`,
                nDivisionID: values.divisionId,
                OrderDate: values.orderDate,
                CVENDORID: `${values.vendorID}`
            }
            dispatch(cnsListWatcher(data))
        }
    };
    
    tempinits.current = inits;
    
    useEffect(() => {
        tempinits.current();
    }, [values.vendorID, values.contractorID, values.orderDate]);


    // useEffect(() => {
    //     if (values.contractorID) {
    //         let data = {
    //             "ACTION": "Cns",
    //             "nID": `${values.contractorID}`
    //         }
    //         dispatch(cnsListWatcher(data))
    //     }
    // }, [values.contractorID])

    const getBookingId = () => {
        const get = menuIdList.find(d => d.nid) || {}
        return get.nid
    }

    const init = () => {
        setMenuData(menuIdList)
        if (menuIdList.length && values.contractorID) {
            menuIdList.map(m => (setFieldValue(m.cmealName, m.nQuantity)))
        }        
    };
    
    tempinit.current = init;
    
    useEffect(() => {
        tempinit.current();
    }, [menuIdList]);

    console.log('sdfsfsdf',getFieldProps(`Early morning snacks`))

    useEffect(()=>{
        dispatch(getDropDownWatcher())
    },[dispatch])

    // useEffect(() => {
    //     if (menuIdList.length && values.contractorID) {
    //         menuIdList.map(m => (setFieldValue(m.cmealName, m.nQuantity)))
    //     }
    // }, [menuIdList])

    const handleCancel = () => {

        if (!getBookingId()) {
            return dispatch(updateAlert('There is no order to cancel', 'error'))
        }

        if (values.contractorID !== '') {
            let id = getBookingId()
            let data = {
                ACTION: "OrderCancel",
                nContractorId: values.contractorID,
                CVENDORID: `${values.vendorID}`,                
                nDivisionID: values.divisionId,
                OrderDate: getConvertedDate2(values.orderDate)
            }
            dispatch(cancelContractBookWatcher(id, data))
        }
    }

    useEffect(() => {
        setFieldValue('contractorID', '')
        setFieldValue('vendorID', '')
        let tempData = [
            {
                "cmealName": "Snacks",
                "nQuantity": '',
            },
            {
                "cmealName": "Breakfast",
                "nQuantity": '',
            },
            {
                "cmealName": "Early morning snacks",
                "nQuantity": '',
            },
            {
                "cmealName": "Dinner",
                "nQuantity": '',
            },
            {
                "cmealName": "Lunch",
                "nQuantity": '',
            }
        ]
        tempData.map(m => (setFieldValue(m.cmealName, m.nQuantity)))
        let data = { ACTION: "SELECT", SDIVISIONID: `${values.divisionId}` }
        dispatch(getVendorWatcher(data))
    }, [values.divisionId])

    const breakfastMissing = !menuIdList.some(obj => obj.cmealName === 'Breakfast');
    const lunchMissing = !menuIdList.some(obj => obj.cmealName === 'Lunch');
    const dinnerMissing = !menuIdList.some(obj => obj.cmealName === 'Dinner');
    const snacksMissing = !menuIdList.some(obj => obj.cmealName === 'Snacks');
    const morningSnacksMissing = !menuIdList.some(obj => obj.cmealName === 'Early morning snacks');
    // const earlyMorningMissing = !menuIdList.some(obj => obj.cmealName === 'Early morning');

    // console.log('sdkmdskf', breakfastMissing, earlyMorningMissing);

    return (
        <div key='mainDiv'>
            <Box p='30px'>
                <Breadcrumbs content={["Kitchen", "Contractor Book"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' >Contractor Book </Typography>
                </Box>
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "20px 20px 0px 0px" }}>
                    <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >

                    <Grid
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            {
                                roleName === 'Admin' ?
                                    <CssTextField
                                        placeholder='Division'
                                        name={`Division`}
                                        fullWidth
                                        // {...getFieldProps(`divisionId`)}
                                        value={divisionList.find(d=>d.id === values.divisionId)?.name || ''}
                                        InputProps={{
                                            readOnly: true,
                                        }}

                                    />
                                    :
                                <Autocomplete
                                id="combo-box-demo"
                                name="divisionId"
                                fullWidth
                                options={divisionList}
                                size="medium"
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("divisionId", value.id);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Division"
                                    name='divisionId'
                                    required={true}
                                    error={Boolean(touched.divisionId && errors.divisionId)}
                                    helperText={touched.divisionId && errors.divisionId}
                                />}
                            />
                            }
                            
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12} sx={{ justifyContent: 'left' }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                fullWidth
                                options={vendorList}
                                name="vendorID"
                                value={vendorList?.filter((f) => f.NID === values.vendorID).map((d) => d)[0] || {CVENDORNAME : ''}}
                                // isOptionEqualToValue={(option, value) => option.NID === (values.vendorID || null)}
                                autoHighlight
                                getOptionLabel={(option) => option.CVENDORNAME}
                                onChange={(event, value) => {
                                    setFieldValue("vendorID", value?.NID);
                                }}
                                renderInput={(params) => <CssTextField {...params}
                                    name="vendorID"
                                    label="Select Vendor"
                                    required={true}
                                    error={Boolean(touched.vendorID && errors.vendorID)}
                                    helperText={touched.vendorID && errors.vendorID}
                                />}
                            />
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12} sx={{ justifyContent: 'left' }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                fullWidth
                                options={contractorNameList.filter(d=> values.divisionId === d.nDivisionID)}
                                name="contractorID"
                                value={contractorNameList.find(d=> values.contractorID === d.ID) || {Name:'', ID:'0', nDivisionID: '0'}}
                                isOptionEqualToValue={(option, value) => option.ID === (values.contractorID || null)}
                                autoHighlight
                                // defaultValue={values.contractorID === "0" ? selectObj : selectObj}
                                getOptionLabel={(option) => option.Name}
                                onChange={(event, value) => {
                                    setFieldValue("contractorID", value?.ID);
                                }}
                                renderInput={(params) => <CssTextField {...params}
                                    name="contractorID"
                                    label="Select Contractor"
                                    required={true}
                                    error={Boolean(touched.contractorID && errors.contractorID)}
                                    helperText={touched.contractorID && errors.contractorID}
                                />}
                            />
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12} sx={{ justifyContent: 'left' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label='Order Date'
                                    inputFormat="dd/MM/yyyy"
                                    // inputFormat="DD-MM-YYYY"
                                    renderInput={(params) => <CssTextField {...params}
                                        fullWidth />}
                                    name="orderDate"
                                    // {...getFieldProps("orderDate")}
                                    value={values.orderDate}
                                    // error={Boolean(touched.orderDate && errors.orderDate)}
                                    // helperText={dateChange ? "Order date cannot be changed" : ""}
                                    onChange={(newValue) => {
                                        setFieldValue("orderDate", newValue);
                                        // setDateChange(true)

                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Card>
                <br />
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "6px 6px 0px 0px", padding: '20px', paddingTop: 50 }}>
                            <Grid container spacing={2} display='flex' flexDirection='row' justifyContent='left' sx={{ padding: '15px', pt: 0 }}>

                                {/* <Box bgcolor='primary.main' height='50px' width='80px' m='auto 10px 0 20px' borderRadius='4px' display='flex' alignItems='center' justifyContent='center'>
                                <Typography variant='t16_400' color='common.white'>{`${m.name}`}</Typography></Box> */}
                                <Grid item
                                    lg={2.4}
                                    xs={4}
                                    md={3}
                                >
                                    <Typography variant='t14_600'> Breakfast </Typography>
                                    <CssTextField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '42px'
                                        }
                                    }}
                                        placeholder='Breakfast'
                                        name={`Breakfast`}
                                        fullWidth
                                        size='medium'
                                        {...getFieldProps(`Breakfast`)}
                                        disabled={ breakfastMissing ? true : false}
                                    />
                                </Grid>

                                <Grid item
                                    lg={2.4}
                                    xs={4}
                                    md={3}
                                >
                                    <Typography variant='t14_600'> Lunch </Typography>
                                    <CssTextField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '42px'
                                        }
                                    }}
                                        placeholder='Lunch'
                                        name={`Lunch`}
                                        fullWidth
                                        size='medium'
                                        {...getFieldProps(`Lunch`)}
                                        disabled={lunchMissing ? true : false}
                                    />
                                </Grid>

                                <Grid item
                                    lg={2.4}
                                    xs={4}
                                    md={3}
                                >
                                    <Typography variant='t14_600'> Snacks </Typography>
                                    <CssTextField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '42px'
                                        }
                                    }}
                                        placeholder='Snacks'
                                        name={`Snacks`}
                                        fullWidth
                                        size='medium'
                                        {...getFieldProps(`Snacks`)}
                                        disabled={snacksMissing ? true : false}
                                    />
                                </Grid>

                                <Grid item
                                    lg={2.4}
                                    xs={4}
                                    md={3}
                                >
                                    <Typography variant='t14_600'> Dinner </Typography>
                                    <CssTextField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '42px'
                                        }
                                    }}
                                        placeholder='Dinner'
                                        name={`Dinner`}
                                        fullWidth
                                        size='medium'
                                        {...getFieldProps(`Dinner`)}
                                        disabled={dinnerMissing ? true : false}
                                    />
                                </Grid>

                                <Grid item
                                    lg={2.4}
                                    xs={4}
                                    md={3}
                                >
                                    <Typography variant='t14_600'> Early morning snacks </Typography>
                                    <CssTextField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '42px'
                                        }
                                    }}
                                        placeholder='Early morning snacks'
                                        name={`Early morning snacks`}
                                        fullWidth
                                        size='medium'
                                        {...getFieldProps(`Early morning snacks`)}
                                        disabled={morningSnacksMissing ? true : false}
                                    />
                                </Grid>

                                {/* <Grid item
                                    lg={2.4}
                                    xs={4}
                                    md={3}
                                >
                                    <Typography variant='t14_600'> Early morning </Typography>
                                    <CssTextField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '42px'
                                        }
                                    }}
                                        placeholder='Early morning'
                                        name={`earlyMorning`}
                                        fullWidth
                                        size='medium'
                                        {...getFieldProps(`earlyMorning`)}
                                        disabled={earlyMorningMissing ? true : false}
                                    />
                                </Grid> */}

                                {/* <Grid item
                                    lg={2}
                                    xs={6}
                                    md={3}
                                >
                                    <Typography variant='t14_600'> {'Snacks(Take away)'} </Typography>
                                    <CssTextField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '42px'
                                        }
                                    }}
                                        placeholder='Snacks(Take away)'
                                        name={`Snacks(Take away)`}
                                        fullWidth
                                        size='medium'
                                        {...getFieldProps(`Snacks(Take away)`)}

                                    />
                                </Grid> */}
                            </Grid>
                    <Box color='common.sred' style={{ padding: '20px', display: 'flex', flexDirection: 'column', spacing: '10px' }}>
                        <Typography variant='t14_600'>Note:</Typography>
                        {noteState}
                    </Box>
                </Card>

                {/* <Grid container
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                    spacing={2}
                    sx={{ paddingTop: '50px' }}
                >
                    <Grid item >
                        <Button sx={{
                            width: '120px',
                            height: '42px',
                            left: '0px',
                            top: '0px',
                        }} variant='contained' color='inherit'
                            onClick={handleCancel}>
                            <Typography variant='t14_400' color='common.black'> Cancel </Typography>
                        </Button>
                    </Grid>
                    <Grid item >
                        <Button sx={{
                            width: '120px',
                            height: '42px',
                            left: '0px',
                            top: '0px',
                            backgroundColor: 'common.Bblack'
                        }} variant='contained' color='primary' onClick={handleSubmit}>
                            <Typography variant='t14_400' color='inherit'>Save</Typography>
                        </Button>
                    </Grid>
                </Grid> */}

                <Grid container
                    item={true}
                    display='flex'
                    justifyContent='flex-end'
                    flexDirection='row'
                    paddingTop='30px'
                    spacing={2}
                >
                    <Grid
                        item={true}
                    >
                        <CancelDialog handleCancel={handleCancel} />
                    </Grid>
                    <Grid
                        item={true}
                    >
                        <BlackButton
                            style={{
                                width: '120px',
                                height: '42px',
                                left: '0px',
                                top: '0px',
                            }}
                            variant='contained'
                            fullWidth
                            size='medium'
                            // disabled={bookingTime ? false : true}
                            type='save'
                            onClick={handleSubmit}
                        >
                            <Typography color='inherit' variant='t16_400'>Save</Typography>
                        </BlackButton>
                    </Grid>
                </Grid>
            </Box>
        </div >
    )
}

import { DraftsOutlined, PhoneInTalkOutlined } from '@mui/icons-material'
import { Box, Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import Breadcrumbs from '../../../components/breadcrumbs'

export default function ContactPage() {
    const { ProfileReducers: { loginState, userAboutDetails } } = useSelector(state => state)
    const divisionID = loginState[0]?.nDivisionID || 0
    const { shortCode, name, managerNumber, bookingNumber, email, address } = userAboutDetails[0]
    console.log('sdsfwee',shortCode, name, managerNumber, bookingNumber, email, address)
    return (
        <div>
            <Box p={{ sm: '30px', xs: '20px' }}>
                <Breadcrumbs content={["Contact Us"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' >Contact Us </Typography>
                </Box>
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "20px 20px 0px 0px" }}>
                    <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                        {/* {
                            divisionID === 4 ?
                            <>
                                <Grid item lg={6} xs={12} sm={6} md={6} >
                                    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Grid item mb='10px' xs={12}>
                                            <Typography variant='t20_600'>BHARANI HOSPITALITY SERVICES</Typography>
                                        </Grid>
                                        <Grid xs={12} item paddingTop='20px'>
                                            <Typography variant='t16_400_1' color='common.t'>Ebony, S1, 2nd Floor, #7-1/9, 
                                                <br />
                                                Eagle Street Cross Road, Langford Town, Off Hosur Road,

                                                Bangalore – 560 025.</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item lg={6} xs={12} sm={6} md={6}>
                                    <Box display='flex' height='100%' width='100%' flexDirection='column' >

                                        <Box mt='auto' >
                                            <Box pt='10px' display='flex' alignItems='center'>
                                                <DraftsOutlined color='primary' />

                                                <Typography variant='t16_400' paddingLeft='10px'>deeksha_shetty@bharani.group</Typography>
                                            </Box>
                                            <Box pt='10px' display='flex' alignItems='center' >
                                                <PhoneInTalkOutlined color='primary' />

                                                <Typography variant='t16_400' display={{ xs: 'none', md: 'block' }} paddingLeft='10px'>Booking (8am to 6pm) :</Typography>

                                                <Typography variant='t16_600' paddingLeft='10px'> +918022210677</Typography>
                                            </Box>
                                            <Box pt='10px' display='flex' alignItems='center' >
                                                <PhoneInTalkOutlined color='primary' />

                                                <Typography variant='t16_400' display={{ xs: 'none', md: 'block' }} paddingLeft='10px'>Shift Duty Manager (24x7) :</Typography>

                                                <Typography variant='t16_600' paddingLeft='10px'> +918022210877</Typography>
                                            </Box>
                                        </Box>

                                    </Box>

                                </Grid>
                            </>
                                : */}
                            <>
                        
                                <Grid item lg={6} xs={12} sm={6} md={6} >
                                    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Grid item mb='10px' xs={12}>
                                            <Typography variant='t20_600'>{shortCode}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='t16_400_1'>{name}</Typography>
                                        </Grid>
                                        <Grid xs={12} item paddingTop='20px'>
                                            <Typography variant='t16_400_1' color='common.t'>
                                                {/* A Unit of Anjali Hotels Pvt Ltd */}
                                                {/* <br /> */}
                                                {/* No.19/1, Second Avenue, Paranur Village, */}

                                                {/* Mahindra World City, Chengalpattu - 603004. */}
                                                {address}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item lg={6} xs={12} sm={6} md={6}>
                                    <Box display='flex' height='100%' width='100%' flexDirection='column' >

                                        <Box mt='auto' >
                                            <Box pt='10px' display='flex' alignItems='center'>
                                                <DraftsOutlined color='primary' />

                                                <Typography variant='t16_400' paddingLeft='10px'>{email}</Typography>
                                            </Box>
                                            <Box pt='10px' display='flex' alignItems='center' >
                                                <PhoneInTalkOutlined color='primary' />

                                                <Typography variant='t16_400' display={{ xs: 'none', md: 'block' }} paddingLeft='10px'>Booking (8am to 6pm) :</Typography>

                                                <Typography variant='t16_600' paddingLeft='10px'>{bookingNumber}</Typography>
                                            </Box>
                                            <Box pt='10px' display='flex' alignItems='center' >
                                                <PhoneInTalkOutlined color='primary' />

                                                <Typography variant='t16_400' display={{ xs: 'none', md: 'block' }} paddingLeft='10px'>Shift Duty Manager (24x7) :</Typography>

                                                <Typography variant='t16_600' paddingLeft='10px'>{managerNumber}</Typography>
                                            </Box>
                                        </Box>

                                    </Box>

                                </Grid>
                            </>
                        {/* } */}
                    </Grid>
                </Card>
            </Box>
        </div >
    )
}

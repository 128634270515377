import React from 'react';
import { useState } from 'react';
import MyRatingsHead from './myRatingHead';
import Scrollbar from '../../Scrollbar';
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Pagination,
    Box,
    Rating
} from '@mui/material';
// import { useSelector } from 'react-redux';

export const TABLE_HEAD = [
    { id: 'MenuName ', label: 'Menu Name', alignRight: false, title: 'Menu Name', field: 'MenuName' },
    { id: 'Rating', label: 'Rating', alignRight: false, title: 'Rating', field: 'Rating' },
    { id: 'RateDate', label: 'Date', alignRight: false, title: 'Date', field: 'RateDate' },
    { id: 'comment', label: 'Comment', alignRight: false, title: 'Comment', field: 'comment' },
    { id: 'vendorName', label: 'Vendor Name', alignRight: false, title: 'Vendor Name', field: 'vendorName' }
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const MyRatingsTable = ({ searchVal, searchText, ratingList, page, setPage }) => {

    let firstIndex = 0
    
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    const tableData = searchText ? searchVal : ratingList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{overflow:'unset'}} >
                        <Table>
                            <MyRatingsHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                //   rowCount={USERLIST.length}
                                //   numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData
                                    .slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    .map((row, i) => {
                                        const { MenuName, Rating: ratingVal, RateDate,cVendorName } = row;

                                        return (
                                            <TableRow
                                                // hover
                                                key={i}
                                                tabIndex={-1}
                                                sx={{ '& > *': { borderBottom: 'unset' } }}
                                            >

                                                <TableCell align="left" sx={{ width: '300px' }}>
                                                    <Typography variant='t14_600'>
                                                        {MenuName}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left" sx={{ width: '340px' }}>
                                                    <Rating size='small' name="read-only" value={ratingVal} readOnly />
                                                </TableCell>
                                                <TableCell align="left" sx={{ width: '340px' }}>
                                                    <Typography variant='t14_600'>
                                                        {RateDate}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left" sx={{ width: '340px' }}>
                                                    <Typography variant='t14_600'>
                                                        {RateDate}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left" sx={{ width: '340px' }}>
                                                    <Typography variant='t14_600'>
                                                        {cVendorName}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}

                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default MyRatingsTable;
//swipe
import SwipeDashboardContractor from '../pages/swipeScreen/dashboardContractor';
//profile
import Profile from '../pages/profile'
import ChangePassword from '../pages/changePassword'
//layout
import NavLayout from '../layouts/dashboard';

const Swipe = {
  path: '/swipe',
  element: <NavLayout />,
  children: [
    { index: true, element: <SwipeDashboardContractor /> },
    // { path: 'dashboardContractor', element: <SwipeDashboardContractor /> },
    { path: "profile", element: <Profile /> },
    { path: "changePassword", element: <ChangePassword /> },
  ]
}

export default Swipe;

import React from 'react'
import { Typography, Grid } from "@mui/material";
import MealBooking from './mealBooking';

export default function GuestOrderBooking() {
    return (
        <>
            <Grid container style={{ padding: '24px' }}>
                <Grid item xs={12}>
                    <Typography variant='t12_400' >Dashboard</Typography>
                </Grid>
                <Grid item mt='8px' xs={12}>
                    <Typography variant='t24_700'> Order Booking </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '20px' }}>
                    <MealBooking />
                </Grid>
            </Grid>
        </>
    )
}

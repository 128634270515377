import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card, Button } from "@mui/material";
import UserHistoryTable from '../../../components/admin/reports/userHistory/table';
import CommonHeader from '../../../components/admin/reports/commonHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getUserHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import { TABLE_HEAD } from '../../../components/admin/reports/userHistory/table';
import { ExportCsv } from "@material-table/exporters";
import { SUBTABLE_HEAD } from '../../../components/admin/reports/userHistory/subTable';
import UserHistorySubTable from '../../../components/admin/reports/userHistory/subTable';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Context from '../../../context/context';
import { getCurDate } from '../../../utils/common';

const UserHistory = () => {
    const {ProfileReducers: { loginState }, ReportsReducers: { UserHistoryList, UserHistoryViewList }, UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [pageChange, setPageChange] = useState(false);
    const [page, setPage] = useState(1);
    const [eventVal, setEventVal] = useState('')
    const { state, setState } = useContext(Context);
    const [vendorState, setVendorState] = useState('')
    const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })
    const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''

    useEffect(()=>{
        if (eventVal) {
            handleSearch(eventVal)
        }
    })

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = UserHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        let currentDate = new Date()
        let curDate = getCurDate(currentDate)
        
        // if(toDate >= fromDate){
            //&& toDate <= curDate && fromDate <= curDate
            const data = {
                ACTION: "GetUserReportSearch",
                StartDate: fromDate !== null ? fromDate : currentDate,
                EndDate: toDate !== null ? toDate : currentDate,
                UserId: "33",
                NDIVISIONID: cafeteriaId,
                // CVENDORID: state !== "" ? `${state}` : 'null'
                CVENDORID: nRoleID === 3 ? String(vendorId) : (  vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getUserHistoryWatcher(data))
            setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
            setPage(1)
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true })
        // }
    }

    return (
        <>
            {!pageChange ?
                <Box p="24px">

                    <Breadcrumbs content={["Reports ", "User History"]} />

                    <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                        <Typography variant="t24_700">User History</Typography>
                    </Box>

                    <Box p='10px 0'>
                        <CommonHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={UserHistoryList} columnData={TABLE_HEAD} setVendorState={setVendorState} invalidErr={invalidErr} setInvalidErr={setInvalidErr}/>
                    </Box>

                    <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                        <UserHistoryTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} setPageChange={setPageChange} vendorState={vendorState} />
                    </Card>

                </Box>

                :

                <>
                    <Box p="24px">
                        <Box p='20px 0' display='flex' justifyContent='right' >
                            <Button sx={{ mr: '15px', minWidth: '100px', }} variant='contained' onClick={() => setPageChange(false)} >
                            <Typography variant='t16_500'>
                                Back
                                </Typography>
                            </Button>
                            <Button variant='contained' sx={{ height: '50px', minWidth: '150px', mr: '15px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(SUBTABLE_HEAD, UserHistoryViewList, "Delivery History")}>
                                <Typography variant='t16_500'>Export to Excel</Typography>
                            </Button>

                        </Box>
                        <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                            <UserHistorySubTable />
                        </Card>
                    </Box>
                </>

            }
        </>
    )
}

export default UserHistory;
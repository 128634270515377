import React from 'react';
// material
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    TableContainer,
    Box,
    Typography
} from '@mui/material';
import Scrollbar from '../Scrollbar';
import { base_url } from '../../http-common';

const FoodTable = ({ tableData, tableHead }) => {

    const isUserNotFound = tableData?.length === 0;

    return (
        <>
            <Box >
                <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {tableHead.map(d => (
                                        <TableCell key={d.id} >
                                            <Box sx={{ width: 'fit-content', display: 'flex' }}>
                                                <Typography variant='t14_600' >
                                                    {d.label}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                    ))}

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData?.map(m =>
                                    <TableRow key={m.id}>
                                        {Object.values(m).map((d, i) => (
                                            <TableCell align="left" key={i} width='300px'>
                                                {i === 1 ?
                                                    <Box
                                                        component="img"
                                                        src={`${base_url}/${d}`}
                                                        sx={{
                                                            height: 50,
                                                            minWidth: 50,
                                                            width: 50,
                                                            borderRadius: "12px",
                                                            objectFit: "cover",
                                                        }}
                                                    ></Box>
                                                    :
                                                    <Typography variant='t18_400' >
                                                        {d}
                                                    </Typography>
                                                }

                                            </TableCell>
                                        ))}
                                    </TableRow>
                                )}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
        </>
    )
}

export default FoodTable;
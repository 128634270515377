import React, { useState, useEffect, useContext } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import CommonHeader from '../../../components/admin/reports/commonHeader';
import OrderHistoryTable from '../../../components/admin/reports/orderHistory/table';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import {TABLE_HEAD} from '../../../components/admin/reports/orderHistory/table';
import Context from '../../../context/context';
import { getCurDate } from '../../../utils/common';

const OrderHistory = () => {
    const { ReportsReducers: { OrderHistoryList }, UserHomeReducers: { cafeteriaId }, ProfileReducers: { loginState } } = useSelector((state) => state)
 
    const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [eventVal, setEventVal] = useState('')
    const { state, setState } = useContext(Context);
    const [vendorState, setVendorState] = useState('')
    const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })

    useEffect(() => {
        console.log('setVendorState', setVendorState(''));
        if (cafeteriaId) {
            let data = {
                "ACTION": "AdminContractorList",
                NDIVISIONID: cafeteriaId,  
                CVENDORID: nRoleID === 3 ? String(vendorId) : 'null'
            }
            dispatch(getOrderHistoryWatcher(data))
        }
    }, [dispatch, cafeteriaId])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }
    
    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = OrderHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        let currentDate = new Date()
        let curDate = getCurDate(currentDate)
        
        // if(toDate >= fromDate){
            //&& toDate <= curDate && fromDate <= curDate
            const data = {
                ACTION: "AdminSearchContractorList",
                FromDate: fromDate,
                ToDate: toDate,
                NDIVISIONID: cafeteriaId,
                // CVENDORID: state !== "" ? `${state}` : 'null'
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getOrderHistoryWatcher(data))
            setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
            setPage(1)
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true })
        // }
    }

    useEffect(()=>{
        if(eventVal)
        handleSearch(eventVal);
    },[OrderHistoryList])

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports ", "Order History"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Order History</Typography>
                </Box>
                <Box p='10px 0'>
                    <CommonHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData = {OrderHistoryList} columnData ={TABLE_HEAD} type= {'order'} setVendorState={setVendorState} invalidErr={invalidErr} setInvalidErr={setInvalidErr}/>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <OrderHistoryTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} dataList={OrderHistoryList}/>
                </Card>
                
            </Box>
        </>
    )
}

export default OrderHistory;
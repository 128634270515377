import { GET_BOOKING_HISTORY_WATCHER, BOOKING_HISTORY_LIST, GET_CONTRACTOR_DASHBOARD_WATCHER, CONTRACTOR_DASHBOARD_DATA, CREATE_CONTRACTOR_ORDER_WATCHER, CANCEL_CONTRACTOR_ORDER_WATCHER, GET_CROLE_SWIPE_HISTORY_WATCHER, CROLE_SWIPE_HISTORY_LIST } from "../../actionTypes/contractor";

// Worker triggering actionCreators

export function getBookingHistoryWatcher(data) {
    return { type: GET_BOOKING_HISTORY_WATCHER, payload: data};
}

export function getContractorDashboardWatcher(data) {
    return { type: GET_CONTRACTOR_DASHBOARD_WATCHER, payload: data};
}

export function createContractorOrderWatcher(data) {
    return { type: CREATE_CONTRACTOR_ORDER_WATCHER, payload: data};
}

export function cancelContractOrderWatcher(id, data, response) {
    return { type: CANCEL_CONTRACTOR_ORDER_WATCHER, payload: data, response, id};
}

export function getCRoleSwipeHistoryWatcher(data) {
    return { type: GET_CROLE_SWIPE_HISTORY_WATCHER, payload: data };
}

// Redux state changing actionCreators

export function bookingHistoryList(data) {
    return { type: BOOKING_HISTORY_LIST, payload: data };
}

export function contractorDashboardList(data) {
    return { type: CONTRACTOR_DASHBOARD_DATA, payload: data };
}

export function cRoleSwipeHistoryList(data) {
    return { type: CROLE_SWIPE_HISTORY_LIST, payload: data };
}

import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import OrderList from "./orderList";
import { getGst } from "../../../../utils/gst";

const OrderCard = ({ value }) => {
  // const getTotal = value.reduce((a, b) => a + b.nToatlRate, 0);

  return (
    <>
    <Card
      sx={{
        borderRadius: "16px 16px 0px 0px",
        bgcolor: "common.white",
        p: "30px",
      }}
    >
      <Grid container mb="30px">
        <Grid item xs={7}>
          <Box display="flex" flexDirection="column">
            <Typography variant="t18_600">Order #{value.nID}</Typography>
            <Typography variant="t14_400" color="grey.500" mt="11px">
              {value.dOrderDate}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex">
            {/* <Box display="flex" flexDirection="column">
              <Typography variant="t18_600">Payment Method</Typography>
              <Typography variant="t14_400" color="grey.500" mt="11px">
                Cash
              </Typography>
            </Box> */}

            <Box ml="auto" display="flex" flexDirection="column">
              <Typography variant="t18_600">Total</Typography>
              <Typography variant="t18_600" color="primary" mt="11px">
                ₹{value.nToatlRate + +getGst(value.nToatlRate)} 
                <Typography ml='5px' variant="t14_400" color="common.sred">
                 (incl gst)
              </Typography>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Divider />

      <Box mt="26px">
        <Typography variant="t18_600">Order Menu</Typography>

        <Box mt="10px">
          <Grid container spacing={3}>
            {/* {value.map((d) => ( */}
              <Grid item xs={12} sm={6} md={6}>
                <OrderList value={value} />
              </Grid>
            {/* ))} */}
          </Grid>
        </Box>
      </Box>
    </Card>
   </>
  );
};

export default OrderCard;

import React, { useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow,
    Collapse,
    Card,
} from '@mui/material';




const TapReportsTableRow = ({ rowData, handleRequestSort, order, orderBy }) => {

 // console.log("orderTrackingViewList",orderTrackingViewList);

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >

                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.tapDate}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.cRFID}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.status}
                    </Typography>
                </TableCell>

                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.orderBy}
                    </Typography>
                </TableCell>
        </TableRow>
     </>
    )
}

export default TapReportsTableRow;
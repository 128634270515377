import React, { useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow,
    Box,
    Button
} from '@mui/material';
import ActionButtons from '../../../admin/dashboard/actionButtons';
import { base_url } from '../../../../http-common';
import EditTextField from '../../../overrides/editField';
import { updateCarouselWatcher, deleteCarouselWatcher } from '../../../../redux/actionCreators/admin/carousel';
import { useDispatch } from 'react-redux'
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined';
import { styled } from '@mui/material/styles';

const Input = styled('input')({
    display: 'none',
});

const CarouselTableRow = ({ rowData, index }) => {

    const dispatch = useDispatch();

    const [status, setStatus] = useState('list');
    const [editData, setEditData] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [imageName, setImageName] = useState('')

    const handleEditChange = (e) => {
        const { name, value } = e.target
        setEditData({ ...editData, [name]: value })
    }

    const handleEdit = () => {
        let data = { ...rowData }
        setEditData(data);
        setStatus('edit');
    }

    const handleDelete = async () => {
        let data = { ACTION: "DELETE" }
        await dispatch(deleteCarouselWatcher(rowData.nId, { data }))
        setStatus('list');
        setDeleteDialogOpen(false);
    }

    const tickIconFunction = async () => {
        let values = { ...editData }
        let data = {
            ACTION: "Update",
            cImage: values.cImage,
            cDescription: values.cDescription,
            cUrl: values.cUrl
        }
        await dispatch(updateCarouselWatcher(rowData.nId, data));
        setStatus('list');
    }

    const cancelIconFunction = () => {
        setEditData({});
        setStatus('list');
    }

    const onFileChange = (e) => {
        if (e) {
            var file = e.target.files[0];
            var name = e.target.files[0].name;
            setImageName(name);
            // setImageFile(file);
            var reader = new FileReader();
            reader.onloadend = function () {
                // setImageFile(reader.result)
                setEditData({ ...editData, cImage: reader.result })
            }
            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            <TableRow
                hover={status === 'list' ? true : false}
                tabIndex={-1}
            >
                {status === 'list' ?
                    <>
                        <TableCell align="left">
                            <Box
                                component="img"
                                src={`${base_url}/${rowData.cImage}`}
                                sx={{
                                    height: 67,
                                    width: 59,
                                    borderRadius: "12px",
                                    objectFit: "cover",
                                    mr: "10px",
                                }}
                            ></Box>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cDescription}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cUrl}
                            </Typography>
                        </TableCell>
                    </>
                    :
                    <>
                        <TableCell align="left">
                            <label htmlFor="contained-button-file">
                                <Input accept="image/*" id="contained-button-file" type="file" onChange={onFileChange} />
                                <Button
                                    variant="contained"
                                    component="span"
                                    size='medium'
                                    // onClick={imageFunction}
                                    style={{ justifyContent: "space-between" }}
                                    endIcon={<FileUploadOutlined />}
                                    type='button'
                                    sx={{
                                        backgroundColor: 'common.editfield', color: 'common.black',
                                        "&:hover": {
                                            backgroundColor: "common.editfield"
                                        }, minWidth: '150px', height: '40px'
                                    }}
                                >
                                    <Typography variant='t11_600'>
                                        {imageName ? imageName : 'Browse Icon'}
                                    </Typography>
                                </Button >
                            </label>
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='cDescription'
                                value={editData.cDescription}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='cUrl'
                                value={editData.cUrl}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                    </>
                }
                <TableCell align="right" sx={{ width: '200px' }}>
                    <ActionButtons type={'carousel'} setStatus={setStatus} status={status} setEditData={setEditData} rowData={rowData} tickIconFunction={tickIconFunction} handleDelete={handleDelete} handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} setDeleteDialogOpen={setDeleteDialogOpen} deleteDialogOpen={deleteDialogOpen} />
                </TableCell>
            </TableRow>
        </>
    )
}

export default CarouselTableRow
import { ORDER_SUMMARY_LAST_WEEK_DATA, ORDER_SUMMARY_LAST_DAY_DATA, ORDER_SUMMARY_FILTERED_DATA, SWIPE_ORDER_SUMMARY_DATA, MEAL_COUNT_DATA } from "../../actionTypes/admin";

const intialState = {
  orderSummaryFilteredData: [],
  LastDayData: [],
  swipeOrderSummaryData: [],
  mealCountData: [],
};

export default function OrderSummaryReducers(state = intialState, action) {
  switch (action.type) {

    case ORDER_SUMMARY_LAST_WEEK_DATA:
      return { ...state, LastWeekData: action.payload };
    case ORDER_SUMMARY_LAST_DAY_DATA:
      return { ...state, LastDayData: action.payload };
    case ORDER_SUMMARY_FILTERED_DATA:
      return { ...state, orderSummaryFilteredData: action.payload };
    case SWIPE_ORDER_SUMMARY_DATA:
      return { ...state, swipeOrderSummaryData: action.payload };
    case MEAL_COUNT_DATA:
      return { ...state, mealCountData: action.payload };

    default:
      return state;
  }
}

import { MENU_ITEM_MASTER_LIST } from "../../actionTypes/admin";

const intialState = {
  menuItemMasterList: [],
};

export default function MenuItemMasterReducers(state = intialState, action) {
  switch (action.type) {

    case MENU_ITEM_MASTER_LIST:
      return { ...state, menuItemMasterList: action.payload };

    default:
      return state;
  }
}

import { Navigate, useRoutes } from "react-router-dom";

// layouts
import Layout from "../layouts";
//not found
import NotFound from "../pages/page404";
//login
import Login from "../pages/login";
//ResetPassword
import ResetPassword from "../pages/resetPassword";
import Invoice from "../pages/admin/invoice";
//Roles
import Admin from "./admin";
import Employee from "./user";
import FoodAdmin from "./foodVendor";
import ContractorAdmin from "./contractor";
import GuestAdmin from "./guest";
import CounterAdmin from "./swipe";
import FoodClerk from "./foodClerk";
import KioskAdmin from "./kioskAdmin";
import Cab from "./cab";
import Kiosk from "./kiosk";
import NoLogin from "./noLogin";
import SuperAdmin from "./superAdmin";
import { useSelector } from "react-redux";
import UserInvoice from "../pages/admin/userInvoice";
import NewJoinersInvoice from "../pages/admin/internInvoice";

// ----------------------------------------------------------------------

export default function Router() {

  const { ProfileReducers: { loginState } } = useSelector(state => state)

  const roles = {
    Admin,
    Employee,
    FoodAdmin,
    ContractorAdmin,
    GuestAdmin,
    CounterAdmin,
    FoodClerk,
    SuperAdmin: Admin,
    SpecialGuest: Employee,
    Intern: Employee,
    NewJoiners: Employee,
  }

  const getRoutes = () => {
    if (loginState.length) {
      const cRoleName = loginState[0].cRoleName
      return [roles[cRoleName]]
    } else {
      return [NoLogin]
    }
  }

  return useRoutes([
    ...getRoutes(),
    {
      path: "/",
      element: <Layout />,
      children: [
        { index: true, element: <Login /> },
        { path: "login", element: <Login /> },
        { path: "404", element: <NotFound /> },
        { path: "resetPassword/:userName", element: <ResetPassword /> },
        { path: "invoice/:new/:role/:type/:orderDate/:orderNo", element: <NewJoinersInvoice /> },
        { path: "invoice/:type/:from/:to/:contractorID", element: <Invoice/> },
        { path: "invoice/:type/:orderDate/:userName", element: <UserInvoice/> },
        {
          path: "*",
          element: <Navigate to="/404" />,
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

import { call, put, takeEvery } from "redux-saga/effects";
import { CREATE_GUEST_ORDER_WATCHER, CANCEL_GUEST_ORDER_WATCHER, GET_GROLE_SWIPE_HISTORY_WATCHER, GUEST_ROLE_USER_COUNT_WATCHER, GUEST_TYPE_WATCHER } from "../../actionTypes/guest";
import { gRoleSwipeHistoryList, guestTypeList, selectGuestRoleCountList } from "../../actionCreators/guest";
import GuestRoleServices from "../../../services/guest/index"
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";
// import { cnsMenuIdList } from "../../actionCreators/admin/contractorBook";
import { cnsMenuIdGuestList } from "../../actionCreators/admin/guestBook";

// function* guestBookingHistoryEffect(action) {
//   try {
//     yield put(updateOpenLoader());
//     let { data } = yield call(GuestRoleServices.getGuestBookingHistory, action.payload);
//     yield put(guestBookingHistoryList(data.recordset));
//     yield put(updateFalseLoader());
//     //yield put(updateAlert('Success', 'success'));
//   } catch (e) {
//     yield put(updateFalseLoader());
//     yield put(updateAlert('Something Went Wrong!', 'error'));
//   }
// }

// function* guestDashboardEffect(action) {
//   try {
//     yield put(updateOpenLoader());
//     let { data } = yield call(GuestRoleServices.getGuestDashboard, action.payload);

//     yield put(guestDashboardList(data.recordsets));
//     yield put(updateFalseLoader());
//     //yield put(updateAlert('Success', 'success'));
//   } catch (e) {
//     yield put(updateFalseLoader());
//     yield put(updateAlert('Something Went Wrong!', 'error'));
//   }
// }

function* createGuestOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestRoleServices.createGuestOrder, action.payload);

    // yield put(contractorDashboardList(data.recordsets));
    yield put(cnsMenuIdGuestList(data));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
}

function* cancelGuestOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestRoleServices.cancelGuestOrder, action.id, action.payload);
    // action.response(data)
    yield put(cnsMenuIdGuestList(data));
    // yield put(contractorDashboardList(data.recordsets));
    yield put(updateFalseLoader());
    yield put(updateAlert('Order Cancelled', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
}

function* getGRoleSwipeHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestRoleServices.getGRoleSwipeHistory, action.payload);
    yield put(gRoleSwipeHistoryList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* selectGuestRoleCountEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestRoleServices.getGuestRoleCount, action.payload);
    yield put(selectGuestRoleCountList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* guestTypeEffect(action) {
  try {
    yield put(updateOpenLoader());
    // let { data } = yield call(GuestRoleServices.getGuestRoleCount, action.payload);
    yield put(guestTypeList(action.payload));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    // yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}


//saga 
export const guestRoleSaga = [
  // takeEvery(GET_GUEST_BOOKING_HISTORY_WATCHER, guestBookingHistoryEffect),
  // takeEvery(GET_GUEST_DASHBOARD_WATCHER, guestDashboardEffect),
  takeEvery(CREATE_GUEST_ORDER_WATCHER, createGuestOrderEffect),
  takeEvery(CANCEL_GUEST_ORDER_WATCHER, cancelGuestOrderEffect),
  takeEvery(GET_GROLE_SWIPE_HISTORY_WATCHER, getGRoleSwipeHistoryEffect),
  takeEvery(GUEST_ROLE_USER_COUNT_WATCHER, selectGuestRoleCountEffect),
  takeEvery(GUEST_TYPE_WATCHER, guestTypeEffect)
]
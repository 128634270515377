import { GET_KIOSK_DASHBOARD_LIST, GET_KIOSK_MENU_LIST, GET_NOTIFICATION_COUNT_LIST, GET_NOTIFICATION_DETAILS_LIST } from "../../actionTypes/kiosk";

const intialState = {
  kioskDashboardList: [],
  kioskMenuList: [],
  notificationDetails: [],
  notificationCount: [],
  
};

export default function KioskRoleReducers(state = intialState, action) {
  switch (action.type) {

    case GET_KIOSK_DASHBOARD_LIST:
      return { ...state, kioskDashboardList: action.payload };

    case GET_KIOSK_MENU_LIST:
      return { ...state, kioskMenuList: action.payload };

    case GET_NOTIFICATION_DETAILS_LIST:
      return { ...state, notificationDetails: action.payload }

    case GET_NOTIFICATION_COUNT_LIST:
      return { ...state, notificationCount: action.payload }

    default:
      return state;
  }
}

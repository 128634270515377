import React, { useEffect, useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getGuestDeliveryHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import { TABLE_HEAD } from '../../../components/admin/reports/guestDeliveryHistory/guestDeliveryHistoryTable';
import GuestDeliveryHistoryTable from '../../../components/admin/reports/guestDeliveryHistory/guestDeliveryHistoryTable';
import GuestDeliveryHistoryHeader from '../../../components/admin/reports/guestDeliveryHistory/header';
import { SUB_TABLE_HEAD } from '../../../components/admin/reports/guestDeliveryHistory/gDHistoryChildTable';

const GuestDeliveryHistory = () => {

    const { ReportsReducers: { GuestDeliveryHistoryList }, UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [eventVal, setEventVal] = useState('')

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = GuestDeliveryHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        const data = {
            "ACTION": "GuestDeliverySearch",
            "FromDate": fromDate,
            "ToDate": toDate,
            // "nCafeteriaId": cafeteriaId,
            "UserID": 33,
            NDIVISIONID: cafeteriaId
        }
        dispatch(getGuestDeliveryHistoryWatcher(data))
        setPage(1)
    }

    useEffect(()=>{
        if(eventVal)
        handleSearch(eventVal);
    },[GuestDeliveryHistoryList])

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Guest Delivery History"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Guest Delivery History</Typography>
                </Box>
                <Box p='10px 0'>
                    <GuestDeliveryHistoryHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData = {GuestDeliveryHistoryList} columnData ={[...TABLE_HEAD,...SUB_TABLE_HEAD]}/>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <GuestDeliveryHistoryTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                </Card>

            </Box>
        </>
    )
}

export default GuestDeliveryHistory;
import { SWIPE_CONTRACTOR_LIST, SWIPE_EMPLOYEE_LIST, SWIPE_LIST } from "../../actionTypes/screen";

const intialState = {
    SwipeContractorList: [],
    SwipeEmployeeList: [],
    SwipeList: []
};

export default function SwipeReducers(state = intialState, action) {
    switch (action.type) {

        case SWIPE_CONTRACTOR_LIST:
            return { ...state, SwipeContractorList: action.payload };

        case SWIPE_EMPLOYEE_LIST:
            return { ...state, SwipeEmployeeList: action.payload };

        case SWIPE_LIST:
            return { ...state, SwipeList: action.payload };

        default:
            return state;
    }
}

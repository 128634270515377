import PropTypes from 'prop-types';
// material
// import { visuallyHidden } from '@mui/utils';
import { TableRow, TableCell, TableHead, Typography } from '@mui/material';
// import SwapVertIcon from '@mui/icons-material/SwapVert';

// ----------------------------------------------------------------------

SaleReportTableHead.propTypes = {
    //order: PropTypes.oneOf(['asc', 'desc']),
    //orderBy: PropTypes.string,
    //rowCount: PropTypes.number,
    headLabel: PropTypes.array,
    //numSelected: PropTypes.number,
    //onRequestSort: PropTypes.func,
    //onSelectAllClick: PropTypes.func
};

export default function SaleReportTableHead({
    rowCount,
    headLabel,
    numSelected,
    onSelectAllClick
}) {
    // const createSortHandler = (property) => (event) => {
    //     onRequestSort(event, property);
    // };



    return (
        <TableHead>
            <TableRow sx={{ '& > *': { borderBottom: 'unset', backgroundColor: 'common.weekTab', color: 'inherit' } }}>
                {headLabel.map((headCell) => {
                    return <TableCell
                    sx={{ width: '200px' }}
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                    //sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Typography variant='t14_400' color='common.white' >
                            {headCell.label}
                        </Typography>
                    </TableCell>
                })}
            </TableRow>
        </TableHead>
    );
}

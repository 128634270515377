import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete, Box, Button, Grid, Stack, Typography } from '@mui/material'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CssTextField from '../overrides/longSearchField';
import SwipeCard from './swipeCard';
import { useSelector, useDispatch } from 'react-redux';
import { getFoodDetailsWatcher, getSwipeContractorWatcher, getSwipeEmployeeWatcher, updateDeliveryWatcher } from '../../redux/actionCreators/screen/screen'
import FoodTable from './foodTable';
import { useMemo } from 'react';

export default function Contractor() {

    const { SwipeReducers: { SwipeContractorList }, ProfileReducers: { loginState } } = useSelector((state) => state)

    const [type, setChange] = useState('Employee')
    const [session, setSession] = useState('')
    const [isWait, setIsWait] = useState(false)
    const [message, setMessage] = useState('')
    const [Username, setUsername] = useState('')
    const [tableHead, setTableHead] = useState([])
    const [tableData, setTableData] = useState([])
    const tempinitsform = useRef();

    const AccessPointID = loginState[0]?.nAccessPointID || 0
    const nMealTime = loginState[0]?.nMealTime || 0
    const nCounterID = loginState[0]?.nCounterID || 0
    const nDivisionID = loginState[0]?.nDivisionID || 0
    const cEmployeeName = loginState[0]?.cEmployeeName || ''

    const Opt = [
        {
            id: '1',
            label: 'Employee'
        },
        {
            id: '2',
            label: 'Contractor'
        },
    ]

    const dispatch = useDispatch()

    const handleRefresh = () => {
        if (localStorage.getItem('userType') === "Contractor") {
            dispatch(getSwipeContractorWatcher({ nDivisionID: `${nDivisionID}` }))
        }
        localStorage.setItem('hdnRFID', "")
        localStorage.setItem('hdnProcess', "Start")
        GetNextSwipeEntry();
    }

    // useEffect(() => {
    //     setInterval(function () {

    //         var d = new Date(); // for now
    //         d.getHours(); // => 9
    //         d.getMinutes(); // =>  30
    //         d.getSeconds(); // => 51
    //         const time = new Date().toTimeString().slice(0, 5)

    //         if (time >= '06:00:00' && time < '10:00:00') {
    //             setSession("Breakfast")
    //         }
    //         else if (time >= '11:00:00' && time < '14:00:00') {
    //             setSession("Lunch")
    //         }
    //         else if (time >= '14:00:00' && time < '17:00:00') {
    //             setSession("Snacks")
    //         }
    //         else if (time >= '19:00:00' && time < '22:00:00') {
    //             setSession("Dinner")
    //         }
    //         else if (time >= '03:30:00' && time < '05:30:00') {
    //             setSession("Supper")
    //         }

    //         if (localStorage.getItem('userType') === "Contractor") {
    //             dispatch(getSwipeContractorWatcher())
    //         }
    //         var hdnProcess = localStorage.getItem('hdnProcess')
    //         var hdnRFID = localStorage.getItem('hdnRFID')

    //         console.log(hdnProcess, hdnRFID)
    //         if (hdnRFID) {
    //             GetBookingDetails(hdnRFID);
    //         }
    //         else if (hdnProcess === "Start") {
    //             GetNextSwipeEntry();
    //         }
    //         else if (hdnProcess === "") {
    //             GetNextSwipeEntry();
    //         }
    //         else {
    //             GetNextSwipeEntry();
    //         }

    //     }, 2000);
    // }, [])

    const initsform = () => {

    };

    tempinitsform.current = initsform;

    useEffect(() => {
        const getId = setInterval(function () {

            var d = new Date(); // for now
            d.getHours(); // => 9
            d.getMinutes(); // =>  30
            d.getSeconds(); // => 51
            const time = new Date().toTimeString().slice(0, 5)

            if (time >= '06:00:00' && time < '08:30:00') {
                setSession("Breakfast")
            }
            else if (time >= '10:30:00' && time < '13:30:00') {
                setSession("Lunch")
            }
            else if (time >= '14:30:00' && time < '15:30:00') {
                setSession("Snacks")
            }
            else if (time >= '19:00:00' && time < '20:30:00') {
                setSession("Dinner")
            }
            else if (time >= '02:30:00' && time < '03:30:00') {
                setSession("Supper")
            }

            if (localStorage.getItem('userType') === "Contractor") {
                dispatch(getSwipeContractorWatcher({ nDivisionID: `${nDivisionID}` }))
            }
            var hdnProcess = localStorage.getItem('hdnProcess') || ""
            var hdnRFID = localStorage.getItem('hdnRFID') || ""
            var vCreatedAt = localStorage.getItem('vCreatedAt') || ""

            // if (hdnRFID) {
            //     GetBookingDetails(hdnRFID, vCreatedAt);
            // }
            // else
            if (hdnProcess === "Start") {
                GetNextSwipeEntry();
            }
            else if (hdnProcess === "") {
                GetNextSwipeEntry();
            }

        }, 2000);

        const getType = localStorage.getItem('userType')
        if (getType) {
            setChange(getType)
        } else {
            setChange('Employee')
            localStorage.setItem('userType', 'Employee')
        }

        return () => {
            clearInterval(getId)
        }

    }, []);

    function GetNextSwipeEntry() {
        let data = {
            "nAccessPointID": Number(AccessPointID),
        }

        if (cEmployeeName === 'Test Velachery') {
            data.ACTION = 'OldSwipes'
        }

        dispatch(getSwipeEmployeeWatcher(data, (response) => {
            const res = response.recordset
            if (res.length > 0) {
                localStorage.setItem('hdnRFID', res[0].nRFIDUserID)
                localStorage.setItem('vCreatedAt', res[0].vCreatedAt)
                const { nRFIDUserID, vCreatedAt, nAccessPointID } = res[0]
                GetBookingDetails(nRFIDUserID, vCreatedAt, nAccessPointID);

            }
            else {

                localStorage.setItem('hdnRFID', "")
                localStorage.setItem('hdnProcess', "Start")
                setMessage('Waiting For Next Swipe')
                setIsWait(true)
            }
        }))
    }

    function GetBookingDetails(nRFIDUserID, vCreatedAt, nAccessPointID) {

        let data = {
            "nAccessPointID": Number(nAccessPointID),
            nRFIDUserId: nRFIDUserID,
            nMealTimeId: nMealTime,
            vCreatedAt
        }
        dispatch(getFoodDetailsWatcher(data, (response) => {
            let booked = 0;
            let bookedfull = 0;
            const tblhead = []
            const tbldata = []
            const res = response.recordset
            if (res.length > 0) {

                if (res[0].cUserType === "CONTRACTOR" && res[0].cMealName === "Already Delivered") {
                    booked = 1;
                    localStorage.setItem('hdnRFID', "")
                    setMessage(`Already Delivered at ${res[0].swipeTime}`)
                    // GetNextSwipeEntry();
                    localStorage.setItem('hdnProcess', "Start")
                }
                else {
                    localStorage.setItem('hdnProcess', "Stop")

                    if (res[0].cUserType === "EMPLOYEE") {
                        setUsername(res[0].cEmployeeName)
                    }
                    else {
                        setUsername(res[0].cEmployeeName)
                    }

                    tblhead.push({ id: 1, label: 'SI. No' })
                    tblhead.push({ id: 2, label: 'Food' })

                    if (res[0].cUserType === "EMPLOYEE") {
                        tblhead.push({ id: 3, label: 'Employee name' })
                    } else {
                        tblhead.push({ id: 4, label: 'Type' })
                    }
                    // tblhead.push({ id: 5, label: 'Purchase No' })
                    // tblhead.push({ id: 6, label: 'Booking ID' })
                    tblhead.push({ id: 7, label: 'Menu' })
                    tblhead.push({ id: 7, label: 'MenuDesc' })
                    //  debugger;
                    if (res[0].cUserType === "EMPLOYEE") {
                        tblhead.push({ id: 8, label: 'Quantity' })
                    }
                    else {
                        tblhead.push({ id: 9, label: 'Total Quantity' })
                        tblhead.push({ id: 10, label: 'Delivered Quantity' })
                    }
                    tblhead.push({ id: 11, label: 'Action' })

                    for (var i = 1; i <= res.length; i++) {
                        //  debugger;
                        const obj = {}
                        obj.id = i
                        obj.cImage = res[i - 1].cImage
                        if (res[0].cUserType === "EMPLOYEE") {
                            obj.cEmployeeName = res[i - 1].cEmployeeName
                        } else {
                            obj.cUserType = res[i - 1].cUserType
                        }
                        // obj.cOrderID = res[i - 1].cOrderID
                        // obj.cBookingID = res[i - 1].cBookingID
                        obj.cMenuName = res[i - 1].cMenuName
                        obj.cMenuDesc = res[i - 1].cMenuDesc
                        // debugger;
                        if (res[i - 1].cUserType === "EMPLOYEE") {
                            obj.nQuantity = res[i - 1].nQuantity
                        }
                        else {
                            obj.nQuantity = res[i - 1].nQuantity
                            obj.nDeliveredQty = res[i - 1].nQuantity === res[i - 1].nDeliveredQty ? res[i - 1].nDeliveredQty : res[i - 1].nDeliveredQty + 1
                        }
                        if (res[i - 1].bIsDelivered === false) {

                            if (res[i - 1].cUserType === "EMPLOYEE") {
                                obj.action = 'Delivered'
                                UpdateDeliveredAuto(res[i - 1].nBDID, res[i - 1].cUserType, nRFIDUserID, vCreatedAt, nAccessPointID)

                            } else {
                                if(+obj.nQuantity === +obj.nDeliveredQty){
                                    obj.action = 'Delivered'
                                }
                                UpdateDeliveredAuto(res[i - 1].nBDID, res[i - 1].cUserType, nRFIDUserID, vCreatedAt, nAccessPointID)
                            }
                        }
                        else {
                            obj.action = `Already Delivered at ${res[i - 1].dDeliveryDate}`
                            if (res[i - 1].cUserType === "EMPLOYEE") {
                                localStorage.setItem('hdnRFID', "")
                                localStorage.setItem('hdnProcess', "Start")

                            } else {
                                bookedfull = 1;
                                localStorage.setItem('hdnRFID', "")
                                localStorage.setItem('hdnProcess', "Start")

                            }

                        }
                        tbldata.push(obj)
                    }
                }

            }
            else {

                localStorage.setItem('hdnRFID', "")
                setMessage('No Booking Found')
                localStorage.setItem('hdnProcess', "Start")

            }
            if (localStorage.getItem('userType') === "Contractor") {
                if (res.length > 0) {
                    setTableHead(tblhead)
                    setTableData(tbldata)
                }
            } else if (localStorage.getItem('userType') === "Employee") {
                if (res.length > 0) {
                    setTableHead(tblhead)
                    setTableData(tbldata)
                }
            }
        }))
    }

    function UpdateDeliveredAuto(BDID, UserType, nRFIDUserID, vCreatedAt, nAccessPointID) {

        let data = {
            nBDID: BDID,
            nRFIDUserID: nRFIDUserID,
            nCounterID,
            vCreatedAt,
            nAccessPointID: Number(nAccessPointID)
        }
        dispatch(updateDeliveryWatcher(data, (res) => {
            GetNextSwipeEntry();
        }))

    }

    const defa = useMemo(() => localStorage.getItem('userType') === 'Contractor' ? Opt[1] : Opt[0], [])

    return (
        <>
            <Grid container spacing={3} sx={{ display: 'flex' }} >
                <Grid item lg={4} xs={12} md={4} sm={12} sx={{ justifyContent: 'left' }}>
                    <Typography variant="t24_700">{session}</Typography>
                </Grid>
                <Grid item lg={8} xs={12} md={8} sm={12} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Box>
                            <Autocomplete
                                onChange={(e, newValue) => {

                                    setChange(newValue?.label);
                                    localStorage.setItem('userType', newValue?.label)

                                }}
                                disablePortal
                                id="combo-box-demo"
                                name="selectMeal"
                                defaultValue={defa}
                                //value={formValues.selectType === null ? '' : formValues.selectType}
                                fullWidth
                                options={Opt}
                                size="medium"
                                autoHighlight

                                isOptionEqualToValue={data => data.label === type}
                                renderInput={(params) => <CssTextField sx={{
                                    '& .MuiOutlinedInput-input': {
                                        height: '16px',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        minWidth: '187px',
                                        backgroundColor: 'common.white',
                                        color: 'common.black',
                                        '& fieldset': {
                                            borderColor: 'grey.400',
                                        },
                                    },
                                    '& label': {
                                        fontSize: '.9rem',
                                        color: 'common.black',
                                    },
                                }} {...params} />}
                            />
                        </Box>

                        <Button onClick={handleRefresh} variant='contained' sx={{ height: '48px', minWidth: '200px', justifyContent: 'space-between', pl: '20px', pr: '20px' }} endIcon={<DoubleArrowIcon />} >
                            <Typography variant='t16_400_1'> Refresh </Typography>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            <Box minHeight='calc(100vh - 265px)' display='flex'>
                <Grid container spacing={3} mt='21px'>

                    {!tableData.length ?
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant='t24_700'>{message}</Typography>
                        </Grid>

                        :
                        <>
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <Typography pl='10px' variant='t24_700'>{message}</Typography>
                                <Box mt='10px'>
                                    <Typography pl='10px' variant='t18_400'>{Username}</Typography>
                                </Box>

                                <Box mt='15px' >
                                    <FoodTable tableHead={tableHead} tableData={tableData} />
                                </Box>
                            </Grid>
                        </>
                    }

                    {type === 'Contractor' &&
                        <>
                            {Boolean(SwipeContractorList.length) && SwipeContractorList.filter(d => d.nQuantity).map(m =>
                                <Grid item key={m.nId} xs={12} xl={2.4} md={3} sm={4}>
                                    <SwipeCard data={m} />
                                </Grid>
                            )}
                        </>
                    }

                </Grid>
            </Box>

        </>
    )
}

import { menuItemMasterList } from "../../actionCreators/admin/menuItemMaster";
import { call, put, takeEvery } from "redux-saga/effects";
import MenuItemMasterService from "../../../services/admin/menuItemMaster";
import { GET_MENU_ITEM_MASTER_WATCHER, UPDATE_MENU_ITEM_MASTER_WATCHER, CREATE_MENU_ITEM_MASTER_WATCHER, DELETE_MENU_ITEM_MASTER_WATCHER } from "../../actionTypes/admin";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getMenuItemMasterEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(MenuItemMasterService.getAll, action.payload);
    yield put(menuItemMasterList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* createMenuItemMasterEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(MenuItemMasterService.create, action.payload);
    action.setOpen(false)
    yield put(menuItemMasterList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* updateMenuItemMasterEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(MenuItemMasterService.update, action.id, action.payload);
    yield put(menuItemMasterList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* deleteMenuItemMasterEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(MenuItemMasterService.delete, action.id, action.payload);

    yield put(menuItemMasterList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Deleted Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}


//saga 
export const MenuItemMasterSaga = [
  takeEvery(GET_MENU_ITEM_MASTER_WATCHER, getMenuItemMasterEffect),
  takeEvery(UPDATE_MENU_ITEM_MASTER_WATCHER, updateMenuItemMasterEffect),
  takeEvery(CREATE_MENU_ITEM_MASTER_WATCHER, createMenuItemMasterEffect),
  takeEvery(DELETE_MENU_ITEM_MASTER_WATCHER, deleteMenuItemMasterEffect),
]



import React, { useEffect, useRef } from 'react'
import { Typography, Grid } from "@mui/material";
import AdminUserDetails from './userDetails';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileWatcher } from '../../redux/actionCreators/profile';

export default function AdminProfile() {

  const dispatch = useDispatch()
  const {ProfileReducers: {loginState}} = useSelector(state => state)

  const nUserID = loginState[0]?.nID || 0
  const tempinitsform = useRef();

  const initsform = () => {
    const data = {
      "ACTION":"ProfileUpdate"
    }
    dispatch(getProfileWatcher(nUserID, data))
  };

  tempinitsform.current = initsform;

  useEffect(() => {
    tempinitsform.current();
  }, []);

  // useEffect(()=>{
  //   const data = {
  //     "ACTION":"ProfileUpdate"
  //   }
  //   dispatch(getProfileWatcher(nUserID, data))
  // },[])

  return (
    <>
      <Grid container style={{ padding: '24px' }}>
        <Grid item xs={12}>
          <Typography variant='t12_400' >Dashboard</Typography>
        </Grid>
        <Grid item mt='8px' xs={12}>
          <Typography variant='t24_700'>My Profile</Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '20px' }}>
          <AdminUserDetails />
        </Grid>
      </Grid>
    </>
  )
}

import { Box, Card, Typography } from '@mui/material'
import React from 'react'

export default function SwipeCard({ data }) {

    const { cEmployeeName, nDeliveredQty, nQuantity } = data

    const getQuan = () => {
        let quantity = "";
        if (nQuantity === 0) {
            quantity = "No Booking"
        } else {
            if ((nQuantity - nDeliveredQty) === 0) {
                quantity = "Delivered"
            } else {
                quantity = nQuantity - nDeliveredQty;
            }
        }
        return quantity
    }


    return (
        <Box display='flex' width='100%' height='100px' alignItems='center' flexDirection='column'>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 6px 6px", width: '100%', height: '100%' }}>
                <Box sx={{ backgroundColor: 'primary.main', display: 'flex', justifyContent: 'center', p: '10px' }}>
                    <Typography variant='t16_500' color='common.white' >
                        {getQuan()}
                    </Typography>
                </Box>
                <Box sx={{ backgroundColor: 'common.white', display: 'flex', justifyContent: 'center', p: '10px' }}>
                    <Typography textAlign='center' variant='t16_500' >
                        {cEmployeeName}
                    </Typography>
                </Box>
            </Card>
        </Box>
    )
}

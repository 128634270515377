import React from 'react';
import { Button, Card, Grid, InputAdornment, Typography } from "@mui/material";
import SearchField from '../../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const CarousalHeader = ({ handleSearch, searchText, setOpen }) => {

    return (
        <>
            <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '30px', display: 'flex', justifyContent: 'flex-end' }} >
                    <Grid item lg={9} xl={10} xs={12} md={8} sm={8} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: 50
                                }
                            }}
                            placeholder='Search'
                            onChange={handleSearch}
                            value={searchText}
                            fullWidth />
                    </Grid>
                    <Grid item lg={3} xl={2} xs={12} md={4} sm={4} sx={{ justifyContent: 'left' }}>
                        <Button variant='contained' size='medium' sx={{ height: 50 }} fullWidth endIcon={<DoubleArrowIcon />} onClick={() => setOpen(true)}
                        >
                            <Typography variant='t18_500'>Add Image</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default CarousalHeader;
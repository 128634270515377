import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card, Button } from "@mui/material";

import {  getTapReportWatcher } from '../../../redux/actionCreators/admin/reports';
import { useDispatch, useSelector } from 'react-redux';
import Context from '../../../context/context';
import CommonHeaderForTapReport from '../../../components/admin/reports/tapReport/header';
import TapReportsTable, { TABLE_HEAD } from '../../../components/admin/reports/tapReport/tapReportTable';

const TapReport = () => {

    const { ReportsReducers: { getTapReportsList }, ProfileReducers: {loginState}, UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)

    // const vendorId = loginState[0]?.nVendorID || ''
    const role = loginState[0]?.nRoleID || ''

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [pageChange, setPageChange] = useState(false)
    const [page, setPage] = useState(1);
    const [eventVal, setEventVal] = useState('')
    const { state, setState } = useContext(Context);
    const [vendorState, setVendorState] = useState('')
    // const [fieldErr, setFieldErr] = React.useState({ fromErr : false, toErr : false })
    const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = getTapReportsList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }
    const nUserID = loginState[0]?.nID || 0


    const handleSubmitAction = (fromDate, toDate) => {
        let currentDate = new Date()

            const data = {
                nUserID,
                "cAction": "getTapReportSearch",
                "Fdate": fromDate !== null ? fromDate : currentDate,
                "Tdate": toDate !== null ? toDate : currentDate,
                NDIVISIONID: cafeteriaId,
               
             
            }
            dispatch(getTapReportWatcher(data))
            setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
            setPage(1)
      
    }

    useEffect(() => {
        if (cafeteriaId) {
            let data = {
                "cAction": "getTapReport",
                NDIVISIONID: cafeteriaId,
            }
            dispatch(getTapReportWatcher(data))
        }
    }, [dispatch, cafeteriaId])

    useEffect(()=>{
        if(eventVal)
        handleSearch(eventVal);
    },[getTapReportsList])
console.log("getTapReportsList",getTapReportsList)
    return (
        <>

                <Box p="24px">

                    <Breadcrumbs content={["Reports", "Tap Report"]} />

                    <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                        <Typography variant="t24_700"> Tap Report </Typography>
                    </Box>
                    <Box p='10px 0'>
                        <CommonHeaderForTapReport handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={getTapReportsList} 
                        columnData={TABLE_HEAD} defaultDate={new Date()} setVendorState={setVendorState} invalidErr={invalidErr} setInvalidErr={setInvalidErr}/>
                    </Box>
                    <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                        <TapReportsTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                    </Card>

                </Box>

              
        </>
    )
}

export default TapReport;
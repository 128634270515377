import React, { useState } from 'react';
import {
    TableRow,
    TableCell,
    Typography,
    Collapse,
    Card,
    Autocomplete
} from '@mui/material';
import EmployeeChildTable from './empChildTable';
import ActionButtons from '../../dashboard/actionButtons';
import EditTextField from '../../../overrides/editField';
import { updateEmployeeWatcher, deleteEmployeeWatcher } from '../../../../redux/actionCreators/admin/employeeManagement';
import { useDispatch, useSelector } from 'react-redux'
import { emailValidation, phoneValidation } from './commonRegex';

const EmpTableRow = ({ rowData, handleRequestSort, order, orderBy, role, isSA }) => {
    const dispatch = useDispatch();
    const { EmployeeReducers: { roleNameList }, UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)

    const [expandOpen, setExpandOpen] = useState(false);
    const [status, setStatus] = useState('list');
    const [editData, setEditData] = useState({});
    const [passErr, setPassErr] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [formErrors, setFormErrors] = useState({
        cEmployeeID: null,
        cEmployeeName: null,
        cEmailID: null,
        cMobileNumber: null,
        nDivisionID: null,
        cRFID: null,
        nCategory: null,
        nDepartment: null,
        nCounterID: null,
        DaysToExpiry: null,
        nAccessPoint: null,
    });

    // console.log("formErrors",formErrors)
    const [requiredFields] = useState([
        'cEmployeeID',
        'cEmployeeName',
        'cEmailID',
        'cMobileNumber',
        'nDivisionID',
    ]);
    const [empRequiredFields] = useState([
        'cEmployeeID',
        'cEmployeeName',
        'cEmailID',
        'cMobileNumber',
        'nDivisionID',
        'cRFID',
        'nCategory',
        'nDepartment',
        'nAccessPoint'
    ]);
    const [foodAdminRequiredFields] = useState([
        'nDivisionID',
        'cEmployeeID',
        'cEmployeeName',
        'cEmailID',
        'cMobileNumber',
    ]);
    const [counterAdminRequiredFields] = useState([
        'nDivisionID',
        'cEmployeeID',
        'cEmployeeName',
        'cEmailID',
        'cMobileNumber',
        'nCounterID'
    ]);
    const [newJoinerRequiredFields] = useState([
        'cEmployeeID',
        'cEmployeeName',
        'cEmailID',
        'cMobileNumber',
        'nDivisionID',
        'cRFID',
        'nCategory',
        'nDepartment',
        'DaysToExpiry',
    ]);
    const [valid, setValid] = useState(false)
    const [emailValid, setEmailValid] = useState(true)
    const [phoneValid, setPhoneValid] = useState(true)

    console.log('emailValid', emailValid)

    const handleEditChange = (e) => {
        const { name, value } = e.target
        setEditData({ ...editData, [name]: value })
        if (name === 'cPassword' && value.length < 8) {
            setPassErr('minimum 8 characters')
        }
        else {
            setPassErr('')
        }
        if(name === 'cEmailID'){
            // let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            // let isValidEmail = emailRegex.test(value);
            let isValidEmail = emailValidation(value)
            setEmailValid(isValidEmail)
        }
        if(name === 'cMobileNumber'){
            // let phoneRegex = /^(?!.*-.*-.*-)(?=(?:\d{8,10}$)|(?:(?=.{9,11}$)[^-]*-[^-]*$)|(?:(?=.{10,12}$)[^-]*-[^-]*-[^-]*$)  )[\d-]+$/
            // let isValidPhoneNo = phoneRegex.test(value);
            let isValidPhoneNo = phoneValidation(value)
            setPhoneValid(isValidPhoneNo)
        }
        validationHandler(name, value)
    }

    const validationHandler = (name, value) => {

        // console.log("val",value)
        if (!Object.keys(formErrors).includes(name)) return;
        let requireValues = rowData.cRoleName === 'NewJoiners' ? newJoinerRequiredFields : rowData.cRoleName === 'CounterAdmin' ? counterAdminRequiredFields : rowData.cRoleName === 'FoodAdmin' ? foodAdminRequiredFields : rowData.cRoleName === 'Employee' || rowData.cRoleName === 'FoodClerk' || rowData.cRoleName === 'SpecialGuest' ? empRequiredFields : requiredFields
        if (
          requireValues.includes(name) &&
          (value === null ||
            value === 'null' ||
            value === '' ||
            value === false ||
            (Object.keys(value || {}) && value?.value === null))
        ) {
          setFormErrors({
            ...formErrors,
            [name]: capitalize(name) + ' is Required!',
          });
          setValid(true)
        } 
        else {
          setFormErrors({
            ...formErrors,
            [name]: null,
          });
          setValid(false)
        }
    };
    
    const capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const handleEdit = () => {
        let data = { ...rowData }
        setEditData(data);
        setStatus('edit');
    }

    const handleDelete = async () => {
        let data = {
            ACTION: "DELETE",
            SROLEID: role,
            isSA,
            SDIVISIONID: cafeteriaId
        }
        await dispatch(deleteEmployeeWatcher(rowData.nID, { data }))
        setStatus('list');
        setDeleteDialogOpen(false);
    }

    console.log('asdfe',rowData.cRoleName)

    const tickIconFunction = async () => {
        let values = { ...editData }
        let data = {
            ACTION: "UPDATE",
            CEMPLOYEEID: values.cEmployeeID,
            CEMPLOYEENAME: values.cEmployeeName,
            CUSERNAME: values.cUserName,
            cPassword: values.cPassword,
            NROLEID: values.nRoleID,
            CMOBILENUMBER: values.cMobileNumber,
            CEMAILID: values.cEmailID,
            NDEPARTMENTID: values.nDepartmentID,
            NCONTRACTORID: values.nContractorID,
            NSECTORID: values.nSectorID,
            NDESIGNATIONID: values.nDesignationID,
            NDIVISIONID: values.nDivisionID,
            CRFID: values.cRFID,
            NMODIFIEDBY: values.nModifiedBy,
            nCounterID: values.nCounterID,
            nAccessPoint: values.nAccessPoint,
            NCATEGORY: values.nCategory,
            NDEPARTMENT: values.nDepartment,
            SROLEID : role,
            isSA,
            SDIVISIONID: cafeteriaId,
            DAYSTOEXPIRY: values.DaysToExpiry
        }
        console.log('sddf',values.nAccessPoint);
        // data.cMenuName = JSON.stringify(editData.cMenuName)
        if (!values.cPassword) {
            return setPassErr('Password is required')
        }
        if (passErr) {
            setPassErr('Minimum 8 characters')
        }
        else {
            setPassErr('')
            // console.log("uuuuuu",emailValid,valid,values.nCategory)
            if(!valid && emailValid && phoneValid &&
                (
                    (rowData.cRoleName === 'Employee' || rowData.cRoleName === 'SpecialGuest' || rowData.cRoleName === 'FoodClerk')
                        ? (values.nCategory > 0 && values.nDepartment > 0 && values.nAccessPoint)
                        : (rowData.cRoleName === 'NewJoiners')
                            ? (values.nCategory > 0 && values.nDepartment > 0)
                            : true
                )){
                dispatch(updateEmployeeWatcher(rowData.nID, data));
                setEmailValid(true)
                setPhoneValid(true)
                setStatus('list');
            }
        }
    }

    const cancelIconFunction = () => {
        setEditData({});
        setEmailValid(true)
        setPhoneValid(true)
        setStatus('list');
        setPassErr('')
    }

    let roleName = (roleNameList.length && roleNameList.find(m => m.id === rowData.nRoleID)) || {}

    return (
        <>

            <TableRow
                hover
                tabIndex={-1}
                sx={{ '& > *': { borderBottom: 'unset' } }}
            >
                {status === 'list' ?
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cEmployeeID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cEmployeeName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cEmailID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cMobileNumber}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cRoleName}
                            </Typography>
                        </TableCell>
                    </>
                    :
                    
                        rowData.cRoleName === 'FoodAdmin' || rowData.cRoleName === 'ContractorAdmin' || rowData.cRoleName === 'GuestAdmin'?   

                        <>
                            <TableCell align="left">
                                <Typography variant='t14_600'>
                                    {rowData.cEmployeeID}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant='t14_600'>
                                    {rowData.cEmployeeName}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant='t14_600'>
                                    {rowData.cEmailID}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant='t14_600'>
                                    {rowData.cMobileNumber}
                                </Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='t14_600'>
                                    {rowData.cRoleName}
                                </Typography>
                            </TableCell>
                        </>
                        :
                        <>
                            <TableCell align="left" sx={{ display: 'flex', alignItems: 'center' }}>
                                <EditTextField
                                    name='cEmployeeID'
                                    value={editData.cEmployeeID}
                                    onChange={handleEditChange}
                                    error={editData.cEmployeeID ? false : formErrors.cEmployeeID !== null ? true : false}
                                    helperText={editData.cEmployeeID ? '' : formErrors.cEmployeeID !== null ? 'Id Number is required' : ''}
                                />
                            </TableCell>
                            <TableCell align="left">
                                <EditTextField
                                    name='cEmployeeName'
                                    value={editData.cEmployeeName}
                                    onChange={handleEditChange}
                                    error={editData.cEmployeeName ? false : formErrors.cEmployeeName !== null ? true : false}
                                    helperText={editData.cEmployeeName ? '' : formErrors.cEmployeeName !== null ? 'User Name is required' : ''}
                                />
                            </TableCell>
                            <TableCell align="left">
                                <EditTextField
                                    name='cEmailID'
                                    value={editData.cEmailID}
                                    onChange={handleEditChange}
                                    error={editData.cEmailID ? emailValid ? false : true : formErrors.cEmailID !== null ? true : false}
                                    helperText={editData.cEmailID ? emailValid ? '' : 'Email Id is Invalid' : formErrors.cEmailID !== null ? 'Email Id is required' : ''}
                                />
                            </TableCell>
                            <TableCell align="left">
                                <EditTextField
                                    name='cMobileNumber'
                                    value={editData.cMobileNumber}
                                    onChange={handleEditChange}
                                    error={editData.cMobileNumber ? phoneValid ? false : true : formErrors.cMobileNumber !== null ? true : false}
                                    helperText={editData.cMobileNumber ? phoneValid ? '' : 'PhoneNo is Invalid' : formErrors.cMobileNumber !== null ? 'Mobile Number is required' : ''}
                                />
                            </TableCell>
                            <TableCell align="left" >
                                {/* <Autocomplete
                                    id="combo-box-demo"
                                    name="role"
                                    fullWidth
                                    options={roleNameList}
                                    autoHighlight
                                    size='small'
                                    defaultValue={roleName}
                                    // value={roleName.name}
                                    onChange={(event, value) => {
                                        setEditData({ ...editData, "nRoleID": value.id });
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <EditTextField {...params}
                                        sx={{ minWidth: '160px' }}
                                        // label="Edit Role"
                                        name='role'
                                    />}
                                /> */}
                                <Typography variant='t14_600'>
                                    {rowData.cRoleName}
                                </Typography>
                            </TableCell>
                        </>
                }
                <TableCell align="right" sx={{ minWidth: '100px' }}>
                    <ActionButtons status={status} setStatus={setStatus} expandOpen={expandOpen} setExpandOpen={setExpandOpen} type={'employee'} tickIconFunction={tickIconFunction} handleDelete={handleDelete} handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} setDeleteDialogOpen={setDeleteDialogOpen} deleteDialogOpen={deleteDialogOpen} />
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell colSpan={6} style={{ padding: 0 }}>
                    <Collapse in={expandOpen} timeout="auto" unmountOnExit>
                        <Card sx={{ bgcolor: 'primary.table' }} >
                            <EmployeeChildTable expandOpen={expandOpen} rowData={rowData} handleRequestSort={handleRequestSort} orderBy={orderBy} order={order} handleEditChange={handleEditChange} status={status} editData={editData} setEditData={setEditData} passErr={passErr} formErrors={formErrors} setValid={setValid} />
                        </Card>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>

    )
}

export default EmpTableRow
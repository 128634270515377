import React, { useEffect, useRef } from 'react';
import { Typography, Card, Grid, Button, Box } from "@mui/material";
import CssTextField from '../../../../components/overrides/longSearchField'
// import BlackButton from '../../../../components/overrides/blackButton'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { clearCnsListState, cnsListWatcher, noteWatcher } from '../../../../redux/actionCreators/admin/contractorBook';
import { createContractorOrderWatcher, cancelContractOrderWatcher } from '../../../../redux/actionCreators/contractor';
import { updateAlert } from '../../../../redux/actionCreators/alert';
import CancelDialog from '../../../../components/admin/kitchen/contractorBook/cancelDialog';
import { getDropDownWatcher } from '../../../../redux/actionCreators/admin/employeeManagement';
import { getConvertedDate, getConvertedDate2 } from '../../../../utils/common';
// import Context from '../../../../context/context';

const MealBooking = () => {

    const dispatch = useDispatch();
    const { ContractorBookReducers: { menuIdList, noteState }, EmployeeReducers: { cafeteriaName } } = useSelector((state) => state)

    const { ProfileReducers: { loginState } } = useSelector(state => state)
    const nUserName = loginState[0]?.cUserName || ''
    const nContractorName = loginState[0]?.cEmployeeName || ''
    const nContractorId = loginState[0]?.nID || ''
    const nUserID = loginState[0]?.nID
    const nDivisionID = loginState[0]?.nDivisionID || 0
    const daysToBook = loginState[0]?.DaysToBook || 1
    const vendorId = sessionStorage.getItem('vendorId')
    const tempinitsform = useRef();
    const tempinits = useRef();
    const tempinit = useRef();
    const temp = useRef();
    var today = new Date();
    let minDate = new Date(today);
    minDate.setDate(minDate.getDate() + 1)
    let maxDate = new Date(today);
    maxDate.setDate(maxDate.getDate() + daysToBook)
    // const { state, setState } = useContext(Context);
    
    // const cafeteriaList = cafeteriaName?.filter(m => m.id !== 0) || []

    const Schema = Yup.object().shape({
        // contractorID: Yup.string().min(1, 'Too Short!').max(500, 'Too Long!').required('Please select contractor'),
    });

    const formik = useFormik({
        initialValues: {
            userName: '',
            contractorID: '',
            contractorName: '',
            orderDate: '',
            supperDate: ''
        },
        validationSchema: Schema,

        onSubmit: () => {
            const { userName, contractorID, orderDate, contractorName, supperDate, ...rest } = { ...formik.values }
            let mealArr = []

            Object.keys(rest).forEach((key, index) => {
                const mealObj = menuIdList.find(m => m.cmealName === key) || {}
                if (rest[key] !== 0 && +rest[key] !== +mealObj.nQuantity) {
                    mealArr.push({ ...mealObj, nQuantity: +rest[key] })
                }
            })

            const data = {
                "ACTION": "InsertOrderBooking",
                "nContractorID": `${contractorID}`,
                "bookingData": mealArr,
                nUserID,
                nBookingID: getBookingId(),
                nDivisionID,
                OrderDate: orderDate,
                CVENDORID: `${vendorId}`
            }

            if (mealArr.length) {
                dispatch(createContractorOrderWatcher(data))
            }
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = formik;

    useEffect(() => {
        dispatch(noteWatcher({ role: 'contractor', orderDate: values.orderDate }))
    }, [values.orderDate]);

    // const initsform = () => {
    //     if (values.contractorID !== '') {
    //         let tomorrow = new Date(today);
    //         tomorrow.setDate(tomorrow.getDate() + 1);
    //         let data = {
    //             "ACTION": "Cns",
    //             "nID": `${values.contractorID}`,
    //             nDivisionID,
    //             OrderDate: tomorrow
    //         }
    //         dispatch(cnsListWatcher(data, true))
    //     }
    // };

    // tempinitsform.current = initsform;

    // useEffect(() => {
    //     tempinitsform.current();
    // }, [values.contractorID, nDivisionID]);

    const initsform = () => {
        if (values.contractorID !== '') {
            let tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);
            let data = {
                "ACTION": "Cns",
                "nID": `${values.contractorID}`,
                nDivisionID,
                CVENDORID: vendorId !== "" ? `${vendorId}` : 'null',
                OrderDate: values.orderDate //tomorrow
            }
            dispatch(cnsListWatcher(data, true))
        }
    };

    tempinitsform.current = initsform;

    useEffect(() => {
        tempinitsform.current();
    }, [values.contractorID, vendorId, values.orderDate]);

    // console.log('asddsfds',values.orderDate)

    // useEffect(() => {
    //     if (values.contractorID !== '') {
    //         let data = {
    //             "ACTION": "Cns",
    //             "nID": `${values.contractorID}`
    //         }
    //         dispatch(cnsListWatcher(data, true))
    //     }
    // }, [values.contractorID])

    const getBookingId = () => {
        const get = menuIdList.find(d => d.nid) || {}
        return get.nid
    }

    const inits = () => {
        if (menuIdList.length) {
            menuIdList.map(m => (setFieldValue(m.cmealName, m.nQuantity)))
        }
    };

    tempinits.current = inits;

    useEffect(() => {
        tempinits.current();
    }, [menuIdList]);


    // useEffect(() => {
    //     if (menuIdList.length) {
    //         menuIdList.map(m => (setFieldValue(m.cmealName, m.nQuantity)))
    //     }
    // }, [menuIdList])

    const init = () => {
        if (!cafeteriaName.length) {
            dispatch(getDropDownWatcher());
        }
    };

    tempinit.current = init;

    useEffect(() => {
        tempinit.current();
    }, [cafeteriaName]);


    // useEffect(() => {
    //     if (!cafeteriaName.length) {
    //         dispatch(getDropDownWatcher());
    //     }
    // }, [cafeteriaName])

    const tempform = () => {
        setFieldValue("contractorID", nContractorId)
        setFieldValue("contractorName", nContractorName)
        setFieldValue("userName", nUserName)
        const today = new Date();
        let tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        setFieldValue("orderDate", tomorrow)
        setFieldValue("supperDate", getConvertedDate(tomorrow))
        dispatch(clearCnsListState())
    };

    temp.current = tempform;

    useEffect(() => {
        temp.current();
    }, []);


    // useEffect(() => {
    //     setFieldValue("contractorID", nContractorId)
    //     setFieldValue("contractorName", nContractorName)
    //     setFieldValue("userName", nUserName)
    //     const today = new Date();
    //     let tomorrow = new Date(today);
    //     tomorrow.setDate(tomorrow.getDate() + 1);
    //     setFieldValue("orderDate", tomorrow)
    // }, [])

    const handleCancel = () => {

        if (!getBookingId()) {
            return dispatch(updateAlert('There is no order to cancel', 'error'))
        }

        if (values.contractorID !== '') {
            let id = getBookingId()
            let data = {
                ACTION: "OrderCancel",
                nContractorId: values.contractorID,
                CVENDORID: `${vendorId}`,
                nDivisionID,
                OrderDate: getConvertedDate2(values.orderDate),
            }
            dispatch(cancelContractOrderWatcher(id, data))
        }
    }

    // console.log('ksjdidj',getConvertedDate2(values.orderDate))

    const breakfastMissing = !menuIdList.some(obj => obj.cmealName === 'Breakfast');
    const lunchMissing = !menuIdList.some(obj => obj.cmealName === 'Lunch');
    const dinnerMissing = !menuIdList.some(obj => obj.cmealName === 'Dinner');
    const snacksMissing = !menuIdList.some(obj => obj.cmealName === 'Snacks');
    const morningSnacksMissing = !menuIdList.some(obj => obj.cmealName === 'Early morning snacks');
    // const earlyMorningMissing = !menuIdList.some(obj => obj.cmealName === 'Early morning');

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}` }}>
                <Grid container spacing={3} sx={{ padding: '30px' }} >
                    <Grid item lg={6} xs={6} sx={{ justifyContent: 'left' }}>
                        <div>
                            <Typography variant='t20_600'> Meal Booking </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={3} display='flex' flexDirection='row' justifyContent='left' sx={{ padding: '30px', pb: '60px' }}>
                    <Grid item
                        lg={4}
                        xs={12}
                        md={4}
                        sm={4}
                    >
                        <Typography variant='t14_600'> User Name </Typography>
                        <CssTextField sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '42px'
                            }
                        }}
                            placeholder='User Name'
                            name='contractorName'
                            fullWidth
                            size='medium'
                            InputProps={{
                                readOnly: true,
                            }}
                            {...getFieldProps("contractorName")}
                            error={Boolean(touched.contractorName && errors.contractorName)}
                            helperText={touched.contractorName && errors.contractorName}
                        />
                    </Grid>

                    <Grid item
                        lg={4}
                        xs={12}
                        md={4}
                        sm={4}
                    >
                        <Typography variant='t14_600'> User ID </Typography>
                        <CssTextField sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '42px'
                            }
                        }}
                            placeholder='User ID'
                            name='userName'
                            fullWidth
                            size='medium'
                            InputProps={{
                                readOnly: true,
                            }}
                            {...getFieldProps("userName")}
                            error={Boolean(touched.userName && errors.userName)}
                            helperText={touched.userName && errors.userName}
                        />
                    </Grid>

                    <Grid item
                        lg={4}
                        xs={12}
                        md={4}
                        sm={4}
                    >
                        <Typography variant='t14_600'> Order Date </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                // label='Order Date'
                                name="orderDate"
                                minDate={minDate}
                                maxDate={maxDate}
                                onChange={(newValue) => {
                                    setFieldValue("orderDate", newValue)
                                }}
                                // {...getFieldProps("orderDate")}
                                value={values.orderDate}
                                renderInput={(params) => <CssTextField {...params} fullWidth sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>

                <Grid container spacing={3} display='flex' flexDirection='row' justifyContent='left' sx={{ padding: '30px', pt: 0 }}>

                    <Grid item
                        lg={2.3}
                        xs={12}
                        sm={4}
                        md={4}
                    >
                        <Typography variant='t14_600'> Breakfast </Typography>
                        <CssTextField sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '42px'
                            }
                        }}
                            placeholder='Breakfast'
                            name={`Breakfast`}
                            fullWidth
                            size='medium'
                            {...getFieldProps(`Breakfast`)}
                            value={values[`Breakfast`] || ''}
                            disabled={ breakfastMissing ? true : false}
                        />
                    </Grid>

                    <Grid item
                        lg={2.3}
                        xs={12}
                        sm={4}
                        md={4}
                    >
                        <Typography variant='t14_600'> Lunch </Typography>
                        <CssTextField sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '42px'
                            }
                        }}
                            placeholder='Lunch'
                            name={`Lunch`}
                            fullWidth
                            size='medium'
                            {...getFieldProps(`Lunch`)}
                            value={values[`Lunch`] || ''}
                            disabled={lunchMissing ? true : false}
                        />
                    </Grid>

                    <Grid item
                        lg={2.3}
                        xs={12}
                        sm={4}
                        md={4}
                    >
                        <Typography variant='t14_600'> Snacks </Typography>
                        <CssTextField sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '42px'
                            }
                        }}
                            placeholder='Snacks'
                            name={`Snacks`}
                            fullWidth
                            size='medium'
                            {...getFieldProps(`Snacks`)}
                            value={values[`Snacks`] || ''}
                            disabled={snacksMissing ? true : false}
                        />
                    </Grid>

                    <Grid item
                        lg={2.3}
                        xs={12}
                        sm={6}
                        md={6}
                    >
                        <Typography variant='t14_600'> Dinner </Typography>
                        <CssTextField sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '42px'
                            }
                        }}
                            placeholder='Dinner'
                            name={`Dinner`}
                            fullWidth
                            size='medium'
                            {...getFieldProps(`Dinner`)}
                            value={values[`Dinner`] || ''}
                            disabled={dinnerMissing ? true : false}
                        />
                    </Grid>

                    <Grid item
                        lg={2.8}
                        xs={12}
                        sm={6}
                        md={6}
                    >
                        <Typography variant='t14_600'> Early morning snacks </Typography>
                        <CssTextField sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '42px'
                            }
                        }}
                            placeholder='Early morning snacks'
                            name={`Early morning snacks`}
                            fullWidth
                            size='medium'
                            {...getFieldProps(`Early morning snacks`)}
                            value={values[`Early morning snacks`] || ''}
                            disabled={morningSnacksMissing ? true : false}
                        />
                    </Grid>

                    {/* <Grid item
                        lg={2.8}
                        xs={12}
                        sm={6}
                        md={6}
                    >
                        <Typography variant='t14_600'> Early morning snacks </Typography>
                        <CssTextField sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '42px'
                            }
                        }}
                            placeholder='Early morning'
                            name={`Early morning`}
                            fullWidth
                            size='medium'
                            {...getFieldProps(`Early morning`)}
                            value={values[`Early morning`] || ''}
                            disabled={earlyMorningMissing ? true : false}
                        />
                    </Grid> */}

                    {/* <Grid item
                                lg={2}
                                xs={6}
                                md={3}
                            >
                                <Typography variant='t14_600'> {'Snacks(Take away)'} </Typography>
                                <CssTextField sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                    placeholder='Snacks(Take away)'
                                    name={`Snacks(Take away)`}
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps(`Snacks(Take away)`)}
                                    value={values[`Snacks(Take away)`] || ''}
                                />
                            </Grid> */}
                </Grid>

                <Box color='common.sred' style={{ padding: '20px', display: 'flex', flexDirection: 'column', spacing: '10px' }}>
                    <Typography variant='t14_600'>Note:</Typography>
                    {noteState}
                </Box>

                <Grid container
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                    spacing={2}
                    sx={{ padding: '50px' }}
                >
                    <Grid item >
                        <CancelDialog handleCancel={handleCancel} />
                    </Grid>
                    <Grid item >
                        <Button sx={{
                            width: '120px',
                            height: '42px',
                            left: '0px',
                            top: '0px',
                            backgroundColor: 'common.Bblack',
                            '&: hover': {
                                backgroundColor: 'common.Bblack',
                            }
                        }} variant='contained' color='primary' onClick={handleSubmit}
                            type='submit'
                        >
                            <Typography variant='t16_400' color='inherit'>Save</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </>

    )
}

export default MealBooking;
import React from 'react';

// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer
} from '@mui/material';
// import { useSelector } from 'react-redux'
import CRoleDHistoryChildTableHead from './cRoleDHistoryChildHead';


export const SUB_TABLE_HEAD = [
    { id: 'Booking ID ', label: 'Booking ID', alignRight: false, title: 'Booking ID', field: 'nBookingID' },
    { id: 'Contractor ID', label: 'Contractor ID', alignRight: false, title: 'Contractor ID', field: 'Contractor ID' },
    // { id: 'Cafeteria', label: 'Cafeteria', alignRight: false, title: 'Cafeteria', field: 'Cafeteria' },
    { id: 'MenuType', label: 'MenuType', alignRight: false, title: 'MenuType', field: 'Menu Name' },
    { id: 'Rate', label: 'Rate', alignRight: false, title: 'Rate', field: 'Rate' },
    { id: 'Total Rate', label: 'Total Rate', alignRight: false, title: 'Total', field: 'Total Rate' },
];

const CRoleDHistoryChildTable = ({ expandOpen, rowData, order, orderBy, handleRequestSort }) => {

    // const { EmployeeReducers: { accessPointList, divisionList, departmentNameList, categoryList } } = useSelector((state) => state)

    // let division = divisionList.find(m => m.id === rowData.nDivisionID) || {}
    // let category = categoryList.find(m => m.name === rowData.categoryName) || {}
    // let department = departmentNameList.find(m => m.name === rowData.departmentName) || {}
    // let getAccesspointNames = rowData.nAccessPoint?.split(',') || []
    // let accessPoints = accessPointList.filter(m => getAccesspointNames.includes(m.name)) || []

    return (
        <>
            {expandOpen &&
                <TableContainer sx={{ overflow: 'unset' }}>
                    <Table>
                        <CRoleDHistoryChildTableHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={SUB_TABLE_HEAD}
                            //   rowCount={USERLIST.length}
                            //   numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            <TableRow
                                // hover
                                tabIndex={-1}
                                sx={{ '& > *': { borderBottom: 'unset' } }}
                            >
                                <>
                                    <TableCell align="left" sx={{ width: '100px' }}>
                                        <Typography variant='t14_600'>
                                            {rowData["nBookingID"]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ width: '100px' }}>
                                        <Typography variant='t14_600'>
                                            {rowData["Contractor ID"]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ width: '130px' }}>
                                        <Typography variant='t14_600'>
                                            {rowData["Menu Name"]}
                                        </Typography>
                                    </TableCell>
                                    {/* <TableCell align="left" sx={{ width: '130px' }}>
                                        <Typography variant='t14_600'>
                                            {rowData.Cafeteria}
                                        </Typography>
                                    </TableCell> */}
                                    <TableCell align="left" sx={{ width: '10px' }}>
                                        <Typography variant='t14_600'>
                                            {rowData.Rate}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ width: '10px' }}>
                                        <Typography variant='t14_600'>
                                            {rowData["Total Rate"]}
                                        </Typography>
                                    </TableCell>
                                </>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    )
}

export default CRoleDHistoryChildTable;
import { rosterList } from "../../actionCreators/admin/rosterManagement";
import { call, put, takeEvery } from "redux-saga/effects";
import RosterManagementService from "../../../services/admin/rosterManagement";
import { GET_ROSTER_WATCHER, CREATE_ROSTER_WATCHER, UPDATE_ROSTER_WATCHER, DELETE_ROSTER_WATCHER } from "../../actionTypes/admin";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getRosterEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(RosterManagementService.getAll, action.payload);
    yield put(rosterList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* createRosterEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(RosterManagementService.create, action.payload);
    action.setPage(false);
    yield put(rosterList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* updateRosterEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(RosterManagementService.update, action.id, action.payload);

    yield put(rosterList(data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* deleteRosterEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(RosterManagementService.delete, action.id, action.payload);

    yield put(rosterList(data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Deleted Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

//saga 
export const RosterManagementSaga = [
  takeEvery(GET_ROSTER_WATCHER, getRosterEffect),
  takeEvery(CREATE_ROSTER_WATCHER, createRosterEffect),
  takeEvery(UPDATE_ROSTER_WATCHER, updateRosterEffect),
  takeEvery(DELETE_ROSTER_WATCHER, deleteRosterEffect),
]


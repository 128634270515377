// material
import { visuallyHidden } from '@mui/utils';
import { Box, TableRow, TableCell, TableHead, TableSortLabel, Typography } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';

// ----------------------------------------------------------------------

export default function TableHeader({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  invoice
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
      <TableCell align="left">
          <Typography variant='t14_400'>
            #
          </Typography>
        </TableCell>
        {headLabel.map((headCell) => {
          return <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              //   hideSortIcon
              active={true}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={SwapVertIcon}
            >
              <Typography variant='t14_400'>
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        })}
        {invoice &&
          <TableCell align='center' sx={{ minWidth: '80px' }}>
            <Typography variant='t14_400'>
              Invoice
            </Typography>
          </TableCell>
        }
        <TableCell align="right">
          <Typography variant='t14_400'>
            Action
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

 const FileUploadButton = styled(Button)(({ theme }) => ({
    color: theme.palette.grey[400],
    background: theme.palette.common.searchBG,
    boxShadow: "none",
    justifyContent: 'space-between',
    height: '56px',

    "&:hover": {
      backgroundColor: theme.palette.common.searchBG,
      boxShadow: 'none'
  },
  }));

  export default FileUploadButton;
import React, { useState } from 'react';
import { Card, Grid, Button, InputAdornment, Typography } from "@mui/material";
import SearchField from '../../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CreationDialog from '../../../dialog';
import NewPushNotification from '../../../admin/reports/pushNotification/newPushNotify'

const PushNotificationHeader = ({ handleSearch, searchText }) => {

    const [open, setOpen] = useState(false);

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={9} xs={9} md={9} sm={9} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px',
                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            onChange={handleSearch}
                            value={searchText} />
                    </Grid>
                    <Grid item lg={3} xs={3} md={3} sm={3} sx={{ justifyContent: 'center', color: 'common.black' }} >

                        <Button variant='contained' sx={{ height: '50px', minWidth: '200px' }} fullWidth endIcon={<DoubleArrowIcon />} onClick={() => setOpen(true)}>
                            <Typography variant='t18_500'>Send Notification</Typography>
                        </Button>

                    </Grid>
                </Grid>
                <CreationDialog open={open} setOpen={setOpen} >
                    <NewPushNotification setOpen={setOpen} />
                </CreationDialog>
            </Card>
        </>
    )
}

export default PushNotificationHeader;
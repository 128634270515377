// USER Watcher Actions
    //home
    export const MENU_LIST_WATCHER = "MENU_LIST_WATCHER";
    export const CLEAR_CART_WATCHER = "CLEAR_CART_WATCHER";
    export const GET_DAYSOFWEEK_WATCHER = "GET_DAYSOFWEEK_WATCHER";
    export const GET_ORDER_TOTALCOST_WATCHER = "GET_ORDER_TOTALCOST_WATCHER";
    export const VIEW_CART_WATCHER = "VIEW_CART_WATCHER";
    export const WEEKLY_BOOKING_WATCHER = "WEEKLY_BOOKING_WATCHER";
    export const MEAL_LIST_WATCHER = "MEAL_LIST_WATCHER";
    export const CAFE_MENUS_WATCHER = "CAFE_MENUS_WATCHER";
    export const ADD_QUANTITY_WATCHER = "ADD_QUANTITY_WATCHER";

    //kiosk
    export const KIOSK_CLEAR_CART_WATCHER = "KIOSK_CLEAR_CART_WATCHER";
    export const KIOSK_GET_ORDER_TOTALCOST_WATCHER = "KIOSK_GET_ORDER_TOTALCOST_WATCHER";
    export const KIOSK_VIEW_CART_WATCHER = "KIOSK_VIEW_CART_WATCHER";
    export const KIOSK_WATCHER = "KIOSK_WATCHER";
    export const KIOSK_ADD_MINUS_WATCHER = "KIOSK_ADD_MINUS_WATCHER";
    export const KIOSK_ADD_CART_WATCHER = "KIOSK_ADD_CART_WATCHER";
    export const KIOSK_GET_CART_WATCHER = "KIOSK_GET_CART_WATCHER";
    export const KIOSK_BOOKING_WATCHER = "KIOSK_BOOKING_WATCHER";
    //feedback
    export const FEEDBACK_LIST_WATCHER = "FEEDBACK_LIST_WATCHER";

    //rating
    export const RATING_LIST_WATCHER = "RATING_LIST_WATCHER";
    export const ADD_CART_WATCHER = "ADD_CART_WATCHER";
    export const GET_CART_WATCHER = "GET_CART_WATCHER";
    export const ADD_MINUS_WATCHER = "ADD_MINUS_WATCHER";

// USER Reducer Actions
    //home
    export const SELECTED_ITEMS = "SELECTED_ITEMS";
    export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
    export const UPDATE_MENUS = "UPDATE_MENUS";
    export const UPDATE_CART = "UPDATE_CART";
    export const UPDATE_DAYSOFWEEK = "UPDATE_DAYSOFWEEK";
    export const UPDATE_ORDER_TOTALCOST = "UPDATE_ORDER_TOTALCOST";
    export const UPDATE_VIEW_CART = "UPDATE_VIEW_CART";
    export const UPDATE_WEEKLY_BOOKING = "UPDATE_WEEKLY_BOOKING";
    export const UPDATE_CAFETERIA_ID = "UPDATE_CAFETERIA_ID";
    export const UPDATE_KIOSK = "UPDATE_KIOSK";

    //kiosk
    export const KIOSK_UPDATE_CART = "KIOSK_UPDATE_CART";
    export const KIOSK_UPDATE_ORDER_TOTALCOST = "KIOSK_UPDATE_ORDER_TOTALCOST";
    export const KIOSK_UPDATE_VIEW_CART = "KIOSK_UPDATE_VIEW_CART";
    export const KIOSK_UPDATE_WEEKLY_BOOKING = "KIOSK_UPDATE_WEEKLY_BOOKING";
    export const KIOSK_UPDATE = "KIOSK_UPDATE";

    //feedback
    export const UPDATE_FEEDBACK_LIST = "UPDATE_FEEDBACK_LIST";
    //rating
    export const UPDATE_RATING_LIST = "UPDATE_RATING_LIST";

import { GUEST_BOOK_LIST, CREATE_GUEST_BOOK_WATCHER, CANCEL_GUEST_BOOK_WATCHER, GUEST_NAME_LIST_WATCHER, GUEST_NAME_LIST, CNS_MENUID_GUEST_LIST, CNS_RATE_GUEST_LIST, CNS_WATCHER_GUEST, ALl_MEAL_WATCHER_GUEST, ALl_MEAL_GUEST_LIST, GET_GUEST_COUNT_LIST, GUEST_USER_COUNT_WATCHER } from "../../actionTypes/admin";

// Worker triggering actionCreators
export function createGuestBookWatcher(data) {
    return { type: CREATE_GUEST_BOOK_WATCHER, payload: data };
}

export function cancelGuestBookWatcher(id, data, response) {
    return { type: CANCEL_GUEST_BOOK_WATCHER, payload: data, response, id};
}

export function getGuestNameListWatcher(data) {
    return { type: GUEST_NAME_LIST_WATCHER, payload: data };
}

export function cnsListWatcherGuest(data, isCon) {
    return { type: CNS_WATCHER_GUEST, payload: data , isCon};
}

export function selectAllMealWatcherGuest(data) {
    return { type: ALl_MEAL_WATCHER_GUEST, payload: data };
}

export function selectGuestCountWatcher(data) {
    return { type: GUEST_USER_COUNT_WATCHER, payload: data };
}

// Redux state changing actionCreators
export function guestBookList(data) {
    return { type: GUEST_BOOK_LIST, payload: data };
}

export function guestNameList(data) {
    return { type: GUEST_NAME_LIST, payload: data };
}

export function cnsRateGuestList(data) {
    return { type: CNS_RATE_GUEST_LIST, payload: data };
}

export function cnsMenuIdGuestList(data) {
    return { type: CNS_MENUID_GUEST_LIST, payload: data };
}

export function selectAllMealGuestList(data) {
    return { type: ALl_MEAL_GUEST_LIST, payload: data };
}

export function selectGuestCountList(data) {
    return { type: GET_GUEST_COUNT_LIST, payload: data };
}
import http from "../../http-common";

class VendorManagementService {

    getAll(data) {
        return http.post("/admin/getVendorManagement", data);
    }
    getAllVendor(data) {
        return http.post("/admin/getVendorList", data);
    }
    get(id) {
        return http.get(`/admin/vendorManagement/${id}`);
    }
    create(data) {
        return http.post("/admin/addVendorManagement", data);
    }
    update(id, data) {
        return http.put(`/admin/vendorManagement/${id}`, data);
    }
    delete(id, data) {
        return http.delete(`/admin/vendorManagement/${id}`, data);
    }

}

export default new VendorManagementService();
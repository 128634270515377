import React from 'react';
import { Button, Typography, Box } from '@mui/material';

const DuplicationDesign = ({ rfid, setDuplicateDialogOpen }) => {
    const Rfid = rfid.join(', ')

    return (
        <>
            <Box p='10px'>
                <Box >
                    <Typography variant='t16_500' color='common.black'>There are some duplications of RFID: </Typography>
                </Box>
                <Box ml='30px' mt='5px'>
                    <Typography variant='t16_400' color='common.weekTab'>{`${Rfid}`}. </Typography>
                </Box>
                <Box mt='10px'>
                    <Typography variant='t16_500' color='common.black'>Please change and try again...</Typography>
                </Box>
            </Box>
            <Box display='flex' justifyContent='right' mt='2px'>
                <Button onClick={() => setDuplicateDialogOpen(false)}
                    sx={{
                        height: '35px',
                        ml: '30px',
                        minWidth: '80px',
                        backgroundColor: 'primary.main',
                        marginTop: 'auto'
                    }}
                    variant='contained'
                    size='small'
                    type='button'
                >
                    <Typography variant='t16_500' color='grey.0'>Ok</Typography>
                </Button>
            </Box>
        </>
    )
}

export default DuplicationDesign;
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { IconButton } from '@mui/material';

const ViewPopup = ({ setOpen, viewData }) => {
    console.log("viewData",viewData)
    return (
        <>
            <Box sx={{ display: 'flex' }}>
            {
              viewData.gateway === 'CASHFREE' ? 
              <Stack spacing={2}>
                    <Typography variant='t15_600' >
                        Status : <Typography variant='t16_400' >{viewData?.resultInfo?.resultStatus}</Typography>
                    </Typography>
                    <Typography variant='t15_600'>
                        Amount : <Typography variant='t16_400' >{viewData?.txnAmount}</Typography>
                    </Typography>
                    <Typography variant='t15_600'>
                        Method : <Typography variant='t16_400' >{viewData?.paymentMode}</Typography>
                    </Typography>
                    <Typography variant='t15_600'>
                        Payment Gateway : <Typography variant='t16_400' >{viewData?.gateway}</Typography>
                    </Typography>
                    <Typography variant='t15_600'>
                        Amount Refunded : <Typography variant='t16_400' >{viewData?.refundAmt}</Typography>
                    </Typography>
                </Stack>
                 :
                <Stack spacing={2}>
                    <Typography variant='t15_600' >
                        Status : <Typography variant='t16_400' >{viewData?.items[0]?.status}</Typography>
                    </Typography>
                    <Typography variant='t15_600'>
                        Amount : <Typography variant='t16_400' >{viewData?.items[0]?.amount / 100}</Typography>
                    </Typography>
                    <Typography variant='t15_600'>
                        Method : <Typography variant='t16_400' >{viewData?.items[0]?.method}</Typography>
                    </Typography>
                    <Typography variant='t15_600'>
                        Payment Gateway : <Typography variant='t16_400' >{viewData?.gateway}</Typography>
                    </Typography>
                    <Typography variant='t15_600'>
                        Amount Refunded : <Typography variant='t16_400' >{viewData?.items[0]?.amount_refunded}</Typography>
                    </Typography>
                </Stack>
             }

                <Box mt='-10px' mr='-10px' ml='auto'>
                    <IconButton onClick={() => setOpen(false)} >
                        <ClearOutlinedIcon />
                    </IconButton>
                </Box>
            </Box>
        </>
    )
}

export default ViewPopup
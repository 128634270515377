import { Typography } from "@mui/material";
import NavSection from "../../components/NavSection";
import { sidebarAdminMenu, sidebarAdminReportMenu, sidebarReportMenu } from "./sidebarConfig/admin";
import { sidebarMain, Cab } from "./sidebarConfig/user";
import { sidebarDashboard, sidebarIpsdVendorReports, sidebarVendorReports } from "./sidebarConfig/foodVendor"
import { sidebarFoodClerkMain } from "./sidebarConfig/foodClerk"
import { Contractor } from "./sidebarConfig/contractor"
import { Guest } from "./sidebarConfig/guest"
import { Swipe } from "./sidebarConfig/swipe"
import { KioskAdmin } from "./sidebarConfig/kiosk";
import { SuperAdmin } from "./sidebarConfig/superAdmin";
import { useSelector } from "react-redux";

export const AdminContent = (
    <>
        <NavSection navConfig={sidebarAdminMenu} />

        <Typography mt='30px' mb='15px' ml='25px' variant='t18_400' color='common.sideBarText' >
            Reports
        </Typography>
        {/* <hr/> sx={{ textDecoration: 'underline' }}*/}

        <NavSection navConfig={sidebarReportMenu} />

    </>
);

export const UserContent = (
    <>
        <NavSection navConfig={sidebarMain} />
    </>
);

export const FoodClerkContent = (
    <>
        <NavSection navConfig={sidebarFoodClerkMain} />
    </>
);

export const FoodVendorContent = (
    <>
        <NavSection navConfig={sidebarDashboard} />

        <Typography mt='30px' mb='15px' ml='25px' variant='t18_400' color='common.sideBarText' >
            Reports
        </Typography>
        {/* <hr/> sx={{ textDecoration: 'underline' }} */}

        <NavSection navConfig={sidebarVendorReports} />
    </>
);

export const ContractorContent = (
    <>
        <NavSection navConfig={Contractor} />
    </>
);

export const GuestContent = (
    <>
        <NavSection navConfig={Guest} />
    </>
);

export const SwipeContent = (
    <>
        <NavSection navConfig={Swipe} />
    </>
);

export const CabContent = (
    <>
        <NavSection navConfig={Cab} />
    </>
);

export const KioskContent = (
    <>
        <NavSection navConfig={KioskAdmin} />
    </>
);

export const SuperAdminContent = (
    <>
        <NavSection navConfig={SuperAdmin} />
    </>
);
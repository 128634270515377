import { OPEN_LOADER, FALSE_LOADER, UPDATE_ALERT, UPDATE_ALERT_CLOSE } from "../../actionTypes/spinner";

const intialState = {
  isLoader: false,
  isAlert: false,
  message: '',
  color: '',
  spinnerCount: 0
};

export default function LoaderReducers(state = intialState, action) {
  switch (action.type) {

    case OPEN_LOADER:
      return { ...state, isLoader: action.payload, spinnerCount: state.spinnerCount + 1 };

    case FALSE_LOADER:
      return { ...state, isLoader: action.payload, spinnerCount: state.spinnerCount - 1 };

    case UPDATE_ALERT:
      return { ...state, isAlert: true, message: action.message, color: action.color };

    case UPDATE_ALERT_CLOSE:
      return { ...state, isAlert: false};

    default:
      return state;
  }
}

// material
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button, Avatar, Typography, Autocomplete } from '@mui/material';
// components
import MenuIcon from '@mui/icons-material/Menu';
// import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CartButton from './cartButton'
// import CssTextField from '../../components/overrides/searchField';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { forgetPasswordWatcher, getUserAboutDetailsWatcher, updateLogin } from '../../redux/actionCreators/profile';
import { useDispatch, useSelector } from 'react-redux';
import { updateAlert } from '../../redux/actionCreators/alert';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { base_url } from '../../http-common';
// import Cafeteria from './cafeteria';
import Division from './division';
// import LogoutDialog from '../../components/logoutDialog';
// import { useState } from 'react';
// import Scrollbar from '../../components/Scrollbar';
import useResponsive from '../../hooks/useResponsive';
import NotificationAction from './notificationAction';
import { useContext, useEffect, useState } from 'react';
import { clearCartWatcher, updateCafeteriaId } from '../../redux/actionCreators/user/home';
import CssTextField from '../../components/overrides/searchField';
import Context from '../../context/context';
import { getVendorOnlyWatcher, getVendorWatcher } from '../../redux/actionCreators/admin/vendorManagement';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 236;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  // boxShadow: 'none',
  // backdropFilter: 'blur(6px)',
  // WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.common.sideBar,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  },
  boxShadow: `0px 2px 10px ${theme.palette.grey[300]}`
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// const CafeteriaLocations = [
//   { label: 'Cafeteria 01' },
//   { label: 'Cafeteria 02' },
//   { label: 'Cafeteria 03' },
// ];

// ----------------------------------------------------------------------

export default function DashboardNavbar({ onOpenSidebar, setOpenNotification }) {

  const { pathname } = useLocation();
  const isUser = !!matchPath({ path: '/user', end: false }, pathname) 
  const isAdmin = !!matchPath({ path: '/admin', end: false }, pathname)
  const [formValues, setFormValues] = useState({ vendor : null})
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { VendorReducers: { vendorOnlyList, vendorList }} = useSelector((state) => state)
  const { state, setState } = useContext(Context);

  const isMobile = useResponsive('down', 'sm');

  const { ProfileReducers: { loginState, userAboutDetails }, UserHomeReducers: {cartList} } = useSelector(state => state)

  const roleName = loginState[0]?.cRoleName
  const nDivisionID = loginState[0]?.nDivisionID
  const nUserID = loginState[0]?.nID || 0
  const vendorId = sessionStorage.getItem('vendorId')

  // console.log('vendorId',vendorId)
  const handleProfile = () => {
    const getPath = pathname.split('/')[1]
    navigate(`/${getPath}/profile`)
  }

  const handleLogout = () => {
    sessionStorage.clear()
    localStorage.clear()
    dispatch(updateLogin([]))
    dispatch(updateAlert('Successfully logged out', 'success'))
    // setOpen(false)
  }

  // const [open, setOpen] = useState(false)

  useEffect(()=>{
    if(roleName==='Admin' || roleName==='FoodAdmin'){
      dispatch(updateCafeteriaId(`${nDivisionID}`));
    }
  },[roleName, dispatch, nDivisionID])

  useEffect(()=>{
    setState(formValues.vendor)    
  },[formValues.vendor])

  useEffect(() => {
    if(!vendorList.length && roleName === 'Employee' || roleName === 'SpecialGuest' || roleName === 'ContractorAdmin' || roleName === 'NewJoiners' || roleName === 'GuestAdmin'){
        let data = { ACTION: "SELECT",SDIVISIONID: String(nDivisionID) }
        dispatch(getVendorWatcher(data))
    }
    if(!userAboutDetails.length){
      const divisionID = loginState[0].nDivisionID
      let userData = {
        "nDivisionID": `${divisionID}`
      }
      dispatch(getUserAboutDetailsWatcher(userData))
    }
  }, [dispatch])

  // useEffect(()=>{
  //   if(state !== cartList?.map((d) => d.cVendorID)[0]){
  //     const cartData = {
  //       ACTION: "CHECKLIST",
  //       nUserID,
  //     };
  //     dispatch(clearCartWatcher(nUserID, cartData));
  //   }
  // },[state])

  // console.log('sdfer',roleName)

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        {isMobile && isUser ?
          <div />
          :

          <Stack direction="row" alignItems="center" spacing={{ xs: 2, sm: 3 }}>
            {
              roleName === 'KioskAdmin' &&
                <NotificationAction setOpenNotification={setOpenNotification} />
            }
            <Button variant='outlined' sx={{ height: 40 }} endIcon={<LogoutIcon />} onClick={handleLogout}>
              <Typography color="common.black" variant="t14_400">
                Logout
              </Typography>
            </Button>

            {/* <Box pb="15px">
              <Typography variant="t14_600"> Vendor </Typography>
            </Box> sx={{borderStyle : 'groove', borderRadius: '16px'}}*/}

            {
              roleName === 'Employee' || roleName === 'SpecialGuest' || roleName === 'ContractorAdmin' || roleName === 'NewJoiners' || roleName === 'GuestAdmin' ?
              <Box>
                <Autocomplete
                  id="combo-box-demo"
                  name="vendor"
                  options={vendorList}
                  value={vendorList?.filter((f) => f.NID == vendorId).map((d) => d)[0] || {CVENDORNAME : ''}}
                  onChange={(event, value) => {
                    // console.log('sodfoerg',value)
                    if(value !== null){
                        // setState(value.NID)
                        setFormValues({ ...formValues, vendor : value.NID});
                        sessionStorage.setItem('vendorId', value.NID)
                        if(value.NID != cartList?.map((d) => d.cVendorID)[0]){
                          const cartData = {
                            ACTION: "CHECKLIST",
                            nUserID,
                          };
                          dispatch(clearCartWatcher(nUserID, cartData));
                        }
                    }
                    // else{
                    //     // setState(null)
                    //     setFormValues({ ...formValues, vendor : null });
                    // }
                  }}
                  getOptionLabel={(option) => option.CVENDORNAME}
                  sx={{ minWidth: 173 }}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      placeholder="Vendor"
                      label="Selected Vendor"
                      name="vendor"
                      variant='outlined'
                      size='small'
                    />
                  )}
                />
              </Box>
              :
              ''
            }

            {/* <LogoutDialog open={open} setOpen={setOpen} handleLogout={handleLogout} /> */}

            {(isUser || (roleName==='FoodClerk')) &&
              <>
                {/* <Cafeteria /> */}
                <CartButton />
              </>
            }
            {isAdmin && (roleName==='SuperAdmin') && <Division />}

            {/* <Box pr='10px'>
                <Badge color="error" variant="dot">
                  <NotificationsNoneIcon sx={{ color: 'common.black' }} />
                </Badge>
              </Box> */}


            <Box position='relative' onClick={handleProfile} sx={{ cursor: 'pointer' }} >
              <Button variant='contained' sx={{ justifyContent: 'left', height: '40px', pr: 5 }}>
                <Typography variant='t16_400' >{loginState[0]?.cEmployeeName}</Typography>
              </Button>

              <Avatar sx={{ position: 'absolute', top: -2, bottom: 0, right: -20, width: 45, height: 45, border: theme => `3px solid ${theme.palette.common.sideBar}` }} src={`${base_url}/${loginState[0]?.cImage}`}>
                <PersonIcon fontSize='large' />
              </Avatar>
            </Box>
          </Stack>
        }

      </ToolbarStyle>
    </RootStyle>
  );
} 

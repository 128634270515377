import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpecialGuestHistoryTable from "../../../../components/admin/reports/specialGuest/table";
import CommonHeader from "../../../../components/admin/reports/commonHeader";
import { getNormalGuestHistoryWatcher } from "../../../../redux/actionCreators/admin/reports";
import { NORMAL_GUEST_TABLE_HEAD } from "../../../../components/admin/reports/specialGuest/table";
import GuestHistorySubTable, {
  SUBTABLE_HEAD,
} from "../../../../components/admin/reports/specialGuest/subTable";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ExportCsv } from "@material-table/exporters";
import Breadcrumbs from "../../../../components/breadcrumbs";
import Context from "../../../../context/context";
import { getCurDate } from "../../../../utils/common";

export default function NormalGuest() {
  const {
    ReportsReducers: { NormalGuestList, GuestHistoryViewList },
    UserHomeReducers: { cafeteriaId },
    ProfileReducers: { loginState },
  } = useSelector((state) => state);

  const vendorId = loginState[0]?.nVendorID || ''
  // console.log("profile", loginState);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchVal, setSearchVal] = useState([]);
  const [pageChange, setPageChange] = useState(false);
  const [page, setPage] = useState(1);
  const [eventVal, setEventVal] = useState("");
  const { state, setState } = useContext(Context);
  const [vendorState, setVendorState] = useState('')
  const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })

 
  const nRoleID = loginState[0]?.nRoleID || ''

  useEffect(() => {
    if (!pageChange) {
      let data = { ACTION: "GetGuestReport", 
      NDIVISIONID: cafeteriaId,
      CVENDORID: nRoleID === 3 ? String(vendorId) : 'null'
     };
      dispatch(getNormalGuestHistoryWatcher(data));
    }
  }, [dispatch, cafeteriaId, pageChange]);

  useEffect(() => {
    if (eventVal) {
      handleSearch(eventVal);
    }
  });

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  const handleSearch = (e) => {
    let val = e?.target?.value || "";
    setSearchText(val);
    setEventVal(e);
    const searchRegex = new RegExp(escapeRegExp(val), "i");
    const filteredRows = NormalGuestList.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]);
      });
    });

    setSearchVal(filteredRows);
    setPage(1);
  };

  const handleSubmitAction = (fromDate, toDate) => {
    let currentDate = new Date()
    let curDate = getCurDate(currentDate)

    // if(toDate >= fromDate && toDate <= curDate && fromDate <= curDate){
      const data = {
        ACTION: "GetGuestReportSearch",
        StartDate: fromDate,
        EndDate: toDate,
        UserId: "33",
        NDIVISIONID: cafeteriaId,
        // CVENDORID: state !== "" ? `${state}` : 'null'
        CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
      };
      dispatch(getNormalGuestHistoryWatcher(data));
      setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
      setPage(1);
    // }
    // else{
    //     setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true })
    // }
  };
  return (
    <>
      {!pageChange ? (
        <Box p="24px">
          <Breadcrumbs content={["Reports ", "Guest History"]} />

          <Box mt="14px" mb="8px" display="flex" alignItems="center">
            <Typography variant="t24_700">Guest History</Typography>
          </Box>

          <Box p="10px 0">
            <CommonHeader
              handleSearch={handleSearch}
              searchText={searchText}
              handleSubmitAction={handleSubmitAction}
              rowData={NormalGuestList}
              columnData={NORMAL_GUEST_TABLE_HEAD}
              defaultDate={new Date()} setVendorState={setVendorState} invalidErr={invalidErr} setInvalidErr={setInvalidErr}
            />
          </Box>

          <Card
            style={{
              boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`,
              borderRadius: "6px 6px 0px 0px",
              padding: "20px",
            }}>
            <SpecialGuestHistoryTable
              page={page}
              setPage={setPage}
              searchVal={searchVal}
              searchText={searchText}
              setPageChange={setPageChange}
              SplGuestList={NormalGuestList}
              cafeteriaId={cafeteriaId}
            />
          </Card>
        </Box>
      ) : (
        <Box p="24px">
          <Grid
            container
            spacing={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            pb="15px">
            <Grid item lg={9} md={7} sm={6} xs={12}>
              <Typography variant="t18_600">
                Guest Name : {GuestHistoryViewList[0]?.EmployeeName}
              </Typography>
            </Grid>
            {/* <Grid item lg={6.5} md={4.5} sm={3.5} xs={12}>
              <Typography variant="t18_600">
                UserName : {GuestHistoryViewList[0]?.UserName}
              </Typography>
            </Grid> */}
            <Grid
              item
              lg={1}
              md={2}
              sm={3}
              xs={12}
              display="flex"
              justifyContent="flex-end">
              <Button
                fullWidth
                sx={{ height: "48px" }}
                variant="contained"
                onClick={() => setPageChange(false)}>
                <Typography variant="t16_500">Back</Typography>
              </Button>
            </Grid>
            <Grid
              item
              lg={2}
              md={3}
              sm={3}
              xs={12}
              display="flex"
              justifyContent="flex-end">
              <Button
                fullWidth
                variant="contained"
                sx={{ height: "48px" }}
                endIcon={<DoubleArrowIcon />}
                onClick={() =>
                  ExportCsv(
                    SUBTABLE_HEAD,
                    GuestHistoryViewList,
                    "VIP Guest History"
                  )
                }>
                <Typography variant="t16_500">Export to Excel</Typography>
              </Button>
            </Grid>
          </Grid>
          <Card
            style={{
              boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`,
              borderRadius: "6px 6px 0px 0px",
              padding: "20px",
            }}>
            <GuestHistorySubTable />
          </Card>
        </Box>
      )}
    </>
  );
}

// import { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {

  // const navigate = useNavigate()

  // const { ProfileReducers: { loginState } } = useSelector(state => state)

  // useEffect(() => {
  //   if (!loginState.length) {
  //     navigate('/login')
  //   }
  // }, [])

  return (
    <>
      <Outlet />
    </>
  );
}

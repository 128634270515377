import { UPDATE_RATING_LIST } from "../../actionTypes/user";

const intialState = {
  ratingList: []
};

export default function UserRatingReducers(state = intialState, action) {
  switch (action.type) {

    case UPDATE_RATING_LIST:
      return { ...state, ratingList: action.payload };

    default:
      return state;
  }
}

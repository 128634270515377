import { Box, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import Breadcrumbs from '../../../components/breadcrumbs'

export default function AboutUs() {
  const { ProfileReducers: { loginState, userAboutDetails } } = useSelector(state => state)
  const divisionID = loginState[0]?.nDivisionID || 0
  const { shortCode, name } = userAboutDetails[0]
  return (
    <>
      <Box p='30px'>
        <Breadcrumbs content={["About Us"]} />

        <Card sx={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "6px 6px 0px 0px", padding: { md: '20px', xs: '10px' }, mt: '30px' }}>
          <CardContent >
            <Typography variant='t24_600'>About Us</Typography>
          </CardContent>

          { divisionID === 4 ? 
            <>
              <CardContent>
                <Typography variant='t14_400'>  
                  In India {name} Group provides Catering and Support Services including extensive outdoor catering expertise to clients in a wide variety of sectors and marketplaces. Everyone in {name} is committed to consistently delivering superior service in the most efficient way, for the shared benefit of our clients, customers, employees, and other stakeholders.
                </Typography>
              </CardContent>
              <CardContent>
                <Typography variant='t18_600'>Our Vision – </Typography>
                <Typography variant='t14_400'>
                  sets out what we want to be To be a world-class provider of contract foodservice and support services , renowned for our great people, our great service, and our great results.
                </Typography>
              </CardContent>

              <CardContent>
                <Typography variant='t18_600'>Our Values – </Typography>
                <Typography variant='t14_400'>
                  these set out what we collectively believe in and guide our behaviours ,can-do Safely, Responsibility, Win Through Teamwork, Passion for Quality, Openness, Trust and Integrity.
                </Typography>
              </CardContent>

              <CardContent>
                <Typography variant='t18_600'>Our Mission – </Typography>
                <Typography variant='t14_400'>
                  sets out how we are going to achieve this Everyone in Vassi is committed to consistently delivering superior service in the most efficient way, for the shared benefit of our customers and employees.
                </Typography>
              </CardContent>
            </>
            :
            <>
              <CardContent>
                <Typography variant='t14_400'>
                  {name} is a collaborative confluence of 2 industry experts, each having their own illustrious career arc in this field. With their combined expertise and a futuristic vision - {name} firmly took a new direction, expanding rapidly along a new vertical - breaking the manual, intensive process into an organised, well-balanced, technologically scalable routine - which delivered higher volumes of food production that was also tasty, healthy and hygienic.
                </Typography>
              </CardContent>
            </>
          }

        </Card>
      </Box>
    </>
  )
}

import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { IconButton } from '@mui/material';

const KioskViewPopup = ({ setOpen, viewData: {body={}} }) => {
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Stack spacing={2}>
                    <Typography variant='t15_600' >
                        Status : <Typography variant='t16_400' >{body.resultInfo?.resultStatus}</Typography>
                    </Typography>
                    <Typography variant='t15_600'>
                        Amount : <Typography variant='t16_400' >{body.txnAmount}</Typography>
                    </Typography>
                    <Typography variant='t15_600'>
                        Method : <Typography variant='t16_400' >{body.paymentMode}</Typography>
                    </Typography>
                    <Typography variant='t15_600'>
                        Bank Name : <Typography variant='t16_400' >{body.bankName}</Typography>
                    </Typography>
                    <Typography variant='t15_600'>
                        Amount Refunded : <Typography variant='t16_400' >{body.refundAmt}</Typography>
                    </Typography>
                </Stack>

                <Box mt='-10px' mr='-10px' ml='auto'>
                    <IconButton onClick={() => setOpen(false)} >
                        <ClearOutlinedIcon />
                    </IconButton>
                </Box>
            </Box>
        </>
    )
}

export default KioskViewPopup
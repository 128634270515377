import React, { useContext, useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CssTextField from '../../overrides/longSearchField';
import { getVendorWatcher } from '../../../redux/actionCreators/admin/vendorManagement';
import { useDispatch, useSelector } from 'react-redux';
import Context from '../../../context/context';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function VendorFilter({ setVendorState, page }) {

    const dispatch = useDispatch()
    const { VendorReducers: { vendorList }, UserHomeReducers: { cafeteriaId }, ProfileReducers: { loginState } } = useSelector((state) => state)
    const { setState } = useContext(Context);
    const [vendorValue, setVendorValue] = useState([])

  const { nRoleID, nVendorID } = loginState[0]

  useEffect(() => {
    let data = { ACTION: "SELECT", SDIVISIONID: cafeteriaId }
    if (nRoleID === 3) {
      !vendorList.length && dispatch(getVendorWatcher(data))
    } else {
      dispatch(getVendorWatcher(data))
    }
    // if(page !== 'dayByDayOrder'){
      setVendorValue([])
    // }

  }, [cafeteriaId, dispatch])

    // useEffect(() => {
    //   setState('')
    // },[])

  const handleChange = (e, value) => {
      // console.log('value', value);
        const vendorNID = value.map(d => d.NID).join()
        setState(vendorNID)
        setVendorValue(value)
        setVendorState(vendorNID)
    }

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={ nRoleID === 3 ? vendorList.filter(item => item.NID === nVendorID) : vendorList}
      value={ nRoleID === 3 ? vendorList.filter(item => item.NID === nVendorID) : vendorValue}
      disableCloseOnSelect
      getOptionLabel={(option) => option.CVENDORNAME}
      onChange={handleChange}
      readOnly={nRoleID === 3 ? true : false}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.CVENDORNAME}
        </li>
      )}
      sx={{ pl : '20px'}}
      renderInput={(params) => (
        <CssTextField {...params} label="Vendor" placeholder="Select Vendor" />
      )}
    />
  );
}

import http from "../../http-common";

class KioskService {

    addCart(data) {
        return http.post("/user/addKiosk", data);
    }

    getCart(data) {
        return http.post("/user/getKiosk", data);
    }

    addMinus(id, data) {
        return http.post(`/user/kiosk/${id}`, data);
    }

    clearCart(id) {
        return http.delete(`/user/clearCartKiosk/${id}`);
    }

    kioskBooking(data) {
        return http.post(`/user/kioskBooking`, data);
    }

    // getOrderTotalCost(id) {
    //     return http.post(`/user/getOrderTotalCost/${id}`);
    // }

    // viewCart(data) {
    //     return http.post(`/user/viewCart`, data);
    // }

}

export default new KioskService();
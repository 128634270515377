import React from 'react';
import {
    TableCell,
    Typography,
    TableRow,
} from '@mui/material';

const SwipeTableRow = ({ rowData, type }) => {

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                <TableCell align="left" width='70px'>
                    <Typography variant='t14_600'>
                        {rowData.cEmployeeName}
                    </Typography>
                </TableCell>
                <TableCell align="left" width='70px'>
                    <Typography variant='t14_600'>
                        {rowData.cUserName}
                    </Typography>
                </TableCell>
                <TableCell align="left" width='70px'>
                    <Typography variant='t14_600'>
                        {rowData.cMenuName}
                    </Typography>
                </TableCell>
                <TableCell align='left' width='100px'>
                    <Typography variant='t14_600'>
                        {rowData.dOrderDate.slice(0,10)}
                    </Typography>
                </TableCell>
                {type === 'swipe' && 
                <TableCell align='left' width='100px'>
                    <Typography variant='t14_600'>
                        {rowData.dDeliveryDate}
                    </Typography>
                </TableCell>
                }
            </TableRow>
        </>
    )
}

export default SwipeTableRow
import React, { useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow,
    Collapse,
    Card
} from '@mui/material';
import DHistoryChildTable from './dHistoryChildTable';
import ActionButtons from '../../dashboard/actionButtons';


const DeliveryHistoryTableRow = ({ rowData, index, handleRequestSort, order,orderBy }) => {

    const [expandOpen, setExpandOpen] = useState(false);
    const [status, setStatus] = useState('list');

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                {/* <TableCell align="left" sx={{ width: '100px' }}>
                    <Typography variant='t14_600'>
                        {rowData["nBookingID"]}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '200px' }}>
                    <Typography variant='t14_600'>
                        {rowData["Contractor ID"]}
                    </Typography>
                </TableCell> */}
                <TableCell align="left" sx={{ width: '200px' }}>
                    <Typography variant='t14_600'>
                        {rowData["Contractor Name"]}
                    </Typography>
                </TableCell>
                {/* <TableCell align="left" sx={{ width: '130px' }}>
                    <Typography variant='t14_600'>
                        {rowData.Cafeteria}
                    </Typography>
                </TableCell> */}
                <TableCell align="left" sx={{ width: '180px' }}>
                    <Typography variant='t14_600'>
                        {rowData.MealName}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '130px' }}>
                    <Typography variant='t14_600'>
                        {rowData.cDivisionName}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '180px' }}>
                    <Typography variant='t14_600'>
                        {rowData.OrderDate}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '180px' }}>
                    <Typography variant='t14_600'>
                        {rowData.Quantity}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '180px' }}>
                    <Typography variant='t14_600'>
                        {rowData.Swiped}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '170px' }}>
                    <Typography variant='t14_600'>
                        {rowData.UnSwiped}
                    </Typography>
                </TableCell>
                {/* <TableCell align="center" sx={{ width: '10px' }}>
                    <Typography variant='t14_600'>
                        {rowData.Rate}
                    </Typography>
                </TableCell>
                <TableCell align="center" sx={{ width: '10px' }}>
                    <Typography variant='t14_600'>
                        {rowData["Total Rate"]}
                    </Typography>
                </TableCell> */}
                <TableCell align="left" sx={{ width: '170px' }}>
                    <Typography variant='t14_600'>
                        {rowData.cVendorName}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '170px' }}>
                    <Typography variant='t14_600'>
                        {rowData.Status}
                    </Typography>
                </TableCell>
                <TableCell align="right" sx={{ minWidth: '100px' }}>
                <ActionButtons status={status} setStatus={setStatus} expandOpen={expandOpen} setExpandOpen={setExpandOpen} type={'conDeliveryHistory'}/>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell colSpan={12} style={{ padding: 0 }}>
                    <Collapse in={expandOpen} timeout="auto" unmountOnExit>
                        <Card sx={{ bgcolor: 'grey.200' }} >
                            <DHistoryChildTable expandOpen={expandOpen} rowData={rowData} handleRequestSort={handleRequestSort} orderBy={orderBy} order={order} status={status} />
                        </Card>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default DeliveryHistoryTableRow
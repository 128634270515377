import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import OrderSummary from "../../../pages/user/home/orderSummary";
import { Autocomplete, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateAlert } from "../../../redux/actionCreators/alert";

export default function TemporaryDrawer({
  name = "Book Now",
  guestType,
  totalCart,
  roleName
}) {
  const [state, setState] = React.useState(false);
  const dispatch = useDispatch();
  // console.log("totalCart", totalCart);
  const {
    UserHomeReducers: { getOrderTotalCost },
  } = useSelector((state) => state);

  const getTotal = () => {
    const total = getOrderTotalCost.length && getOrderTotalCost[0].nTotalost;
    return total;
  };

  const toggleDrawer = (open) => () => {
    if (!getTotal()) {
      return dispatch(updateAlert("Cart is empty", "error"));
    }
    setState(open);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        fullWidth
        
        sx={{ height: 44 }}
        onClick={toggleDrawer(true)}
      >
        <Typography variant="t16_400">{name}</Typography>
      </Button>
      <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
        <OrderSummary toggleDrawer={toggleDrawer} guestType={guestType} />
      </Drawer>
    </React.Fragment>
  );
}

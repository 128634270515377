// import { Add, Remove } from "@mui/icons-material";
import { Card, Typography, } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { base_url } from "../../../http-common";
// import { addCartWatcher, addMinusWatcher } from "../../../redux/actionCreators/user/home";
import { userOrderCancelWatcher } from "../../../redux/actionCreators/user/currentOrder";
// import { getConvertedDate2 } from "../../../utils/common";
import CustTypo from "../../overrides/CustTypo";
import QRCode from 'react-qr-code';
import CancelDialog from "../../admin/kitchen/contractorBook/cancelDialog";

const OrderCards = ({ value, type }) => {

  const { cImage, cMenuName, nToatlRate, cMealName, cMenuDesc, nID, dOrderDateLabel } = value

  const { ProfileReducers: { loginState } } = useSelector(state => state) //UserHomeReducers: { cartList },

  const nUserID = loginState[0]?.nID || 0

  const dispatch = useDispatch()

  // const addToCart = () => {
  //   const data = {
  //     "ACTION": "INSERT",
  //     nUserID,
  //     nMenuID: `${nMenuID}`,
  //     dOrderDate: getConvertedDate2(new Date(dOrderDate)),
  //     "nCafeteriaId" : cafeteriaId
  //   }
  //   const cartData = {
  //     "ACTION": "CHECKLIST",
  //     nUserID
  //   }
  //   dispatch(addCartWatcher(data, cartData, nUserID))
  // }

  // const addMinusApi = (type) => {
  //   const data = {
  //     "ACTION": type
  //   }
  //   const cartData = {
  //     "ACTION": "CHECKLIST",
  //     nUserID
  //   }
  //   dispatch(addMinusWatcher(getCount.NID, data, cartData, nUserID))
  // }

  // const filterList = cartList.filter(d => dOrderDate === d.dOrderDate)

  // const getCount = filterList.find(d => d.nMenuID === nMenuID) || {}

  const handleCancel = () => {
    let data = {
      "nID": nID,
      "nUserID": nUserID
    }
    dispatch(userOrderCancelWatcher(data))
  }

  return (
    <Card
      sx={{
        p: "19px",
        borderRadius: "6px",
        color: "common.white",
        display: "flex",
        width: '100%',
        height: '100%',
        flexDirection: { xs: 'column', md: 'row' }
      }}
    >
      <Box
        component="img"
        src={`${base_url}/${cImage}`}
        sx={{
          width: { md: 163, xs: 'auto' },
          height: 155,
          borderRadius: "12px",
          objectFit: "cover",
          mr: { md: "17px", xs: 0 },
        }}
        alt={cImage}
      ></Box>

      <Box display="flex" height='100%' mt={{ xs: '10px', md: 0 }} width='100%' color="common.black" flexDirection="column">
        <Box display='flex' flexDirection='row' >
          <Box mr='10px'>
            {type === 'upcomingOrder' &&
              <Box display="flex" alignItems="center">
                <Typography variant="t14_400" color="grey.500">
                  Ordered on {dOrderDateLabel}
                </Typography>
              </Box>
            }

            <Box mt="10px">
              <Typography variant="t18_500">{cMealName} ({cMenuName})</Typography>
            </Box>
            
            {cMenuDesc.length > 20 ?
              <CustTypo mt="10px" color="grey.500" variant="t16_400" >
                {cMenuDesc}
              </CustTypo>
              :
              <Box mt="10px">
                <Typography mt="10px" color="grey.500" variant="t16_400">
                  {cMenuDesc}
                </Typography>
              </Box>
            }
          </Box>
          {type === 'currentOrder' &&
            <Box
              sx={{ marginLeft: 'auto' }}
            >
              <QRCode
                value={`nBDID-${nID}`}
                bgColor={'#FFFFFF'}
                fgColor={'#000000'}
                size={100}
              />
            </Box>
          }
        </Box>

        <Box mt="auto" display="flex" alignItems='end' flexDirection='row' >
          <Typography mb={{ xs: '5px', sm: 0 }} variant="t24_700">
            ₹{nToatlRate}
          </Typography>

          {type === 'upcomingOrder' &&
            <Box mt='10px' ml='auto'>
              <CancelDialog handleCancel={handleCancel} type='user' color='primary.main' />
            </Box>
          }

          {/* <Box mt='10px' ml='auto'>
            {!getCount.nQuantity ? (
              <Button
                onClick={() => { addToCart() }}
                sx={{ minWidth: 48, height: 48 }}
                variant="contained"
              >
                <Add />
              </Button>
            ) : (
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
                sx={{
                  height: 48,
                  "& .MuiButtonGroup-grouped": {
                    minWidth: 38,
                  },
                }}
              >
                <Button onClick={() => { addMinusApi("MINUS") }} sx={{ p: 0 }}>
                  <Remove />
                </Button>
                <Button sx={{ p: 0 }}>
                  <Typography variant="t24_700">{getCount.nQuantity}</Typography>
                </Button>
                <Button onClick={() => { addMinusApi("ADD") }} sx={{ p: 0 }}>
                  <Add />
                </Button>
              </ButtonGroup>
            )}
          </Box> */}

        </Box>

      </Box>
    </Card>
  );
};

export default OrderCards;

import { orderSummaryFilteredData, swipeOrderSummaryData, mealCountData } from "../../actionCreators/admin/orderSummary";
import { call, put, takeEvery } from "redux-saga/effects";
import OrderSummaryServices from "../../../services/admin/orderSummary";
import { GET_LAST_WEEK_ORDER_SUMMARY_WATCHER, GET_LAST_DAY_ORDER_SUMMARY_WATCHER, GET_SWIPE_ORDER_SUMMARY_WATCHER } from "../../actionTypes/admin";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getLastWeekOrderSummaryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(OrderSummaryServices.getLastWeek, action.payload);
    yield put(orderSummaryFilteredData(data.recordset));
    yield put(mealCountData(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getLastDayOrderSummaryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(OrderSummaryServices.getLastDay, action.payload);
    yield put(orderSummaryFilteredData(data.recordset));
    yield put(mealCountData(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getSwipeOrderSummaryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(OrderSummaryServices.getTapLog, action.payload);
    yield put(swipeOrderSummaryData(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}


//saga 
export const OrderSummarySaga = [
  takeEvery(GET_LAST_WEEK_ORDER_SUMMARY_WATCHER, getLastWeekOrderSummaryEffect),
  takeEvery(GET_LAST_DAY_ORDER_SUMMARY_WATCHER, getLastDayOrderSummaryEffect),
  takeEvery(GET_SWIPE_ORDER_SUMMARY_WATCHER, getSwipeOrderSummaryEffect)
]



export const CURRENT_ORDER_WATCHER = "CURRENT_ORDER_WATCHER";
export const ORDER_HISTORY_WATCHER = "ORDER_HISTORY_WATCHER";
export const NEXT_DAY_ORDER_WATCHER = "NEXT_DAY_ORDER_WATCHER";
export const NEXT_WEEK_ORDER_WATCHER = "NEXT_WEEK_ORDER_WATCHER";
export const DELIVERED_ORDER_WATCHER = "DELIVERED_ORDER_WATCHER";
export const UPCOMING_ORDER_WATCHER = "UPCOMING_ORDER_WATCHER";
export const CHECKOUT_WATCHER = "CHECKOUT_WATCHER";
export const ORDER_BOOKING_WATCHER = "ORDER_BOOKING_WATCHER";
export const PAYMENT_WATCHER = "PAYMENT_WATCHER";
export const INSERT_FEEDBACK_WATCHER = "INSERT_FEEDBACK_WATCHER";
export const RATE_FEEDBACK_WATCHER = "RATE_FEEDBACK_WATCHER";
export const USER_ORDER_CANCEL_WATCHER = "USER_ORDER_CANCEL_WATCHER";
export const USER_ORDER_HISTORY_CANCEL_WATCHER = "USER_ORDER_HISTORY_CANCEL_WATCHER";
export const VERIFY_PAYTM_WATCHER = "VERIFY_PAYTM_WATCHER";
export const ORDER_BOOKING_PAYNIMO_WATCHER = "ORDER_BOOKING_PAYNIMO_WATCHER"

export const UPDATE_CURRENT_ORDER = "UPDATE_CURRENT_ORDER";
export const UPDATE_ORDER_HISTORY = "UPDATE_ORDER_HISTORY";
export const UPDATE_NEXT_DAY_ORDER = "UPDATE_NEXT_DAY_ORDER";
export const UPDATE_NEXT_WEEK_ORDER = "UPDATE_NEXT_WEEK_ORDER";
export const UPDATE_DELIVERED_ORDER = "UPDATE_DELIVERED_ORDER";
export const UPDATE_UPCOMING_ORDER = "UPDATE_UPCOMING_ORDER";
export const INSTA_MOJO_PAYMENT = "INSTA_MOJO_PAYMENT";
import { carouselList, foodWastageList, userCarouselList, freezeBookingList, specialMenuList } from "../../actionCreators/admin/carousel";
import { call, put, takeEvery } from "redux-saga/effects";
import CarouselService from "../../../services/admin/carousal";
import { GET_CAROUSEL_IMAGE_WATCHER, CREATE_CAROUSEL_WATCHER, UPDATE_CAROUSEL_WATCHER, DELETE_CAROUSEL_WATCHER, GET_USER_CAROUSEL_IMAGE_WATCHER, UPDATE_USER_CAROUSEL_WATCHER, DELETE_USER_CAROUSEL_WATCHER, CREATE_USER_CAROUSEL_WATCHER, FOOD_WASTAGE_WATCHER, GET_FREEZE_BOOKING_WATCHER, CREATE_FREEZE_BOOKING_WATCHER, UPDATE_FREEZE_BOOKING_WATCHER, DELETE_FREEZE_BOOKING_WATCHER, GET_SPECIAL_MENU_WATCHER } from "../../actionTypes/admin";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getCarouselEffect(action) {
    try {
        yield put(updateOpenLoader());
        let { data } = yield call(CarouselService.loginGet, action.payload);
        yield put(carouselList(data.recordset));
        //yield put(updateAlert('Success', 'success'));
        yield put(updateFalseLoader());

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error')); 
    }
}

function* createCarouselEffect(action) {
    try {
        yield put(updateOpenLoader());
        yield call(CarouselService.loginCreate, action.payload);
        action.setOpen(false)
        let { data: get } = yield call(CarouselService.loginGet, { "ACTION": "Select" });
        yield put(carouselList(get.recordset));
        yield put(updateAlert('Created Successfully', 'success'));
        yield put(updateFalseLoader());
    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error'));
    }
}

function* updateCarouselEffect(action) {
    try {
        yield put(updateOpenLoader());
        yield call(CarouselService.loginUpdate, action.id, action.payload);
        let { data: get } = yield call(CarouselService.loginGet, { "ACTION": "Select" });
        yield put(carouselList(get.recordset));
        yield put(updateAlert('Updated Successfully', 'success'));
        yield put(updateFalseLoader());

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error'));
    }
}

function* deleteCarouselEffect(action) {
    try {
        yield put(updateOpenLoader());
        yield call(CarouselService.loginDelete, action.id, action.payload);

        let { data: get } = yield call(CarouselService.loginGet, { "ACTION": "Select" });
        yield put(carouselList(get.recordset));
        yield put(updateAlert('Deleted Successfully', 'success'));
        yield put(updateFalseLoader());

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error'));
    }
}

function* getfreezeBookingEffect(action) {
    try {
        yield put(updateOpenLoader());
        let { data } = yield call(CarouselService.freezeGet, action.payload);
        yield put(freezeBookingList(data.recordset));
        //yield put(updateAlert('Success', 'success'));
        yield put(updateFalseLoader());

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error')); 
    }
}

function* specialMenuEffect(action) {
    try {
        yield put(updateOpenLoader());
        let { data } = yield call(CarouselService.freezeGet, action.payload);
        yield put(specialMenuList(data.recordset));
        //yield put(updateAlert('Success', 'success'));
        yield put(updateFalseLoader());

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error')); 
    }
}

function* createfreezeBookingEffect(action) {
    try {
        yield put(updateOpenLoader());
        let { data } = yield call(CarouselService.freezeCreate, action.payload);
        action.setOpen(false)
        yield put(freezeBookingList(data.data.recordset));
        yield put(updateAlert('Created Successfully', 'success'));
        yield put(updateFalseLoader());
    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error'));
    }
}

function* updatefreezeBookingEffect(action) {
    try {
        yield put(updateOpenLoader());
        let { data } = yield call(CarouselService.freezeUpdate, action.id, action.payload);
        yield put(freezeBookingList(data.data.recordset));
        yield put(updateAlert('Updated Successfully', 'success'));
        yield put(updateFalseLoader());

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error'));
    }
}

function* deletefreezeBookingEffect(action) {
    try {
        yield put(updateOpenLoader());
        let { data } = yield call(CarouselService.freezeDelete, action.id, action.payload);
        yield put(freezeBookingList(data.data.recordset));
        yield put(updateAlert('Deleted Successfully', 'success'));
        yield put(updateFalseLoader());

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error'));
    }
}

function* getUserCarouselEffect(action) {
    try {
        yield put(updateOpenLoader());
        let { data } = yield call(CarouselService.foodWastage, action.payload);
        yield put(foodWastageList(data.recordset));
        //yield put(updateAlert('Success', 'success'));
        yield put(updateFalseLoader());

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error')); 
    }
}

function* createUserCarouselEffect(action) {
    try {
        yield put(updateOpenLoader());
        yield call(CarouselService.userCreate, action.payload);
        action.setOpen(false)
        let { data: get } = yield call(CarouselService.userGet, { "ACTION":"SELECT" });
        yield put(userCarouselList(get.recordset));
        yield put(updateAlert('Created Successfully', 'success'));
        yield put(updateFalseLoader());
    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error'));
    }
}

function* updateUserCarouselEffect(action) {
    try {
        yield put(updateOpenLoader());
        yield call(CarouselService.userUpdate, action.id, action.payload);
        let { data: get } = yield call(CarouselService.userGet, { "ACTION":"SELECT"});
        yield put(userCarouselList(get.recordset));
        yield put(updateAlert('Updated Successfully', 'success'));
        yield put(updateFalseLoader());

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error'));
    }
}

function* deleteUserCarouselEffect(action) {
    try {
        yield put(updateOpenLoader());
        yield call(CarouselService.userDelete, action.id, action.payload);

        let { data: get } = yield call(CarouselService.userGet, { "ACTION":"SELECT" });
        yield put(userCarouselList(get.recordset));
        yield put(updateAlert('Deleted Successfully', 'success'));
        yield put(updateFalseLoader());

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error'));
    }
}

function* foodWastageEffect(action) {
    try {
        yield put(updateOpenLoader());
        let { data } = yield call(CarouselService.foodWastage, action.payload);
        if(action.setOpen) action.setOpen(false)
        yield put(foodWastageList(data.recordset));
        yield put(updateAlert('Deleted Successfully', 'success'));
        yield put(updateFalseLoader());

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error'));
    }
}


//saga 
export const CarouselSaga = [
    takeEvery(GET_CAROUSEL_IMAGE_WATCHER, getCarouselEffect),
    takeEvery(CREATE_CAROUSEL_WATCHER, createCarouselEffect),
    takeEvery(UPDATE_CAROUSEL_WATCHER, updateCarouselEffect),
    takeEvery(DELETE_CAROUSEL_WATCHER, deleteCarouselEffect),
    takeEvery(GET_FREEZE_BOOKING_WATCHER, getfreezeBookingEffect),
    takeEvery(CREATE_FREEZE_BOOKING_WATCHER, createfreezeBookingEffect),
    takeEvery(UPDATE_FREEZE_BOOKING_WATCHER, updatefreezeBookingEffect),
    takeEvery(DELETE_FREEZE_BOOKING_WATCHER, deletefreezeBookingEffect),
    takeEvery(GET_USER_CAROUSEL_IMAGE_WATCHER, getUserCarouselEffect),
    takeEvery(CREATE_USER_CAROUSEL_WATCHER, createUserCarouselEffect),
    takeEvery(UPDATE_USER_CAROUSEL_WATCHER, updateUserCarouselEffect),
    takeEvery(DELETE_USER_CAROUSEL_WATCHER, deleteUserCarouselEffect),
    takeEvery(FOOD_WASTAGE_WATCHER, foodWastageEffect),
    takeEvery(GET_SPECIAL_MENU_WATCHER, specialMenuEffect),
]



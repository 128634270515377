import { GET_ROSTER_WATCHER, CREATE_ROSTER_WATCHER, ROSTER_LIST, UPDATE_ROSTER_WATCHER, DELETE_ROSTER_WATCHER} from "../../actionTypes/admin";

// Worker triggering actionCreators

export function getRosterWatcher(data) {
    return { type: GET_ROSTER_WATCHER, payload: data };
}

export function createRosterWatcher(data, setPage) {
    return { type: CREATE_ROSTER_WATCHER, payload: data, setPage };
}

export function updateRosterWatcher(id, data) {
    return { type: UPDATE_ROSTER_WATCHER, payload: data, id };
}

export function deleteRosterWatcher(id, data) {
    return { type: DELETE_ROSTER_WATCHER, payload: data, id };
}

// Redux state changing actionCreators
export function rosterList(data) {
    return { type: ROSTER_LIST, payload: data };
}

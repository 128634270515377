import { UPDATE_ALERT, UPDATE_ALERT_CLOSE } from "../../actionTypes/spinner";

// Worker triggering actionCreators


// Redux state changing actionCreators

export function updateAlert(message, color) {
    return { type: UPDATE_ALERT, message, color  };
}

export function updateAlertClose() {
    return { type: UPDATE_ALERT_CLOSE };
}

import { GUEST_BOOK_LIST, GUEST_NAME_LIST, CNS_MENUID_GUEST_LIST, CNS_RATE_GUEST_LIST, ALl_MEAL_GUEST_LIST, GET_GUEST_COUNT_LIST } from "../../actionTypes/admin";

const intialState = {
  guestBookList: [],
  guestNameList: [],
  menuIdGuestList: [],
  rateGuestList: [],
  allMealGuestList: [],
  guestUserCount: [],
};

export default function GuestBookReducers(state = intialState, action) {
  switch (action.type) {

    case GUEST_BOOK_LIST:
      return { ...state, guestBookList: action.payload };

    case GUEST_NAME_LIST:
      return { ...state, guestNameList: action.payload };

    case CNS_MENUID_GUEST_LIST:
      return { ...state, menuIdGuestList: action.payload };

    case CNS_RATE_GUEST_LIST:
      return { ...state, rateGuestList: action.payload };

    case ALl_MEAL_GUEST_LIST:
      return { ...state, allMealGuestList: action.payload };

    case GET_GUEST_COUNT_LIST:
      return {...state, guestUserCount: action.payload}

    default:
      return state;
  }
}

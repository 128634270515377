import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import CurrentOrder from './currentOrder';
import UpcomingOrder from './upcomingOrder';
import RecentDelivery from './recentDelivery';
import QuickOrder from './quickOrder';
import { currentOrderWatcher, nextdayOrderWatcher, nextweekOrderWatcher, deliveredOrderWatcher, upcomingOrderWatcher } from '../../../redux/actionCreators/user/currentOrder';
import { useDispatch, useSelector } from 'react-redux';
import SlideBasicArrow from "./slider";
import { getCarouselWatcher } from '../../../redux/actionCreators/admin/carousel';
import VendorDialog from './vendorDialog';
import Context from '../../../context/context';

const Dashboard = () => {

  const dispatch = useDispatch()
  const { CarouselReducers: { CarouselImageList } } = useSelector(state => state)

  const { ProfileReducers: { loginState } } = useSelector(state => state)
  const nUserID = loginState[0]?.nID || 0
  const cEmployeeName = loginState[0]?.cEmployeeName || ''
  const { state } = useContext(Context);
  const vendorId = sessionStorage.getItem('vendorId')

  useEffect(() => {
    // console.log('vendorId', vendorId);
    if (vendorId !== null) {
      dispatch(currentOrderWatcher({ nUserID, "cAction": "TodayOrder", CVENDORID: vendorId !== "" ? `${vendorId}` : 'null' }))
      dispatch(nextdayOrderWatcher({ nUserID, "cAction": "nextdayOrder", CVENDORID: vendorId !== "" ? `${vendorId}` : 'null' }))
      dispatch(nextweekOrderWatcher({ nUserID, "cAction": "nextweekOrder", CVENDORID: vendorId !== "" ? `${vendorId}` : 'null' }))
      dispatch(deliveredOrderWatcher({ nUserID, "cAction": "DeliveredOrder", CVENDORID: vendorId !== "" ? `${vendorId}` : 'null' }))
      dispatch(upcomingOrderWatcher({ nUserID, "cAction": "UpcomingOrder", CVENDORID: vendorId !== "" ? `${vendorId}` : 'null' }))
      let data = {
        "ACTION": "select"
      }
      dispatch(getCarouselWatcher(data))
    }
  }, [dispatch, nUserID, vendorId])

  // const foodWastageKgs = foodWastage.length && ((foodWastage[0]?.foodWastage)/3).toFixed(1)
  // const foodWastageFeed = (+foodWastageKgs*3).toFixed()
  // console.log('vesdfeaf',vendorId)

  return(
    <Box display='flex' width='100%'>

      <Box p='24px' width={{lg:'calc(100% - 235px)', xs: '100%'}}>

        {vendorId == null && <VendorDialog />}

        <Box display='flex' flexDirection='column'>
          <Typography mb='14px' variant='t13_500' sx={{ color: 'grey.600' }}>
            Home
          </Typography>

          <Typography mb='19px' variant='t32_600'>
            Hello, {cEmployeeName}
          </Typography>
        </Box>

        {/* {Boolean(CarouselImageList.length) &&
          <Box>
            <SlideBasicArrow slideBasicArrow={CarouselImageList} />
          </Box>
        } */}
          {/* :
          <Card sx={{ borderRadius: '16px', backgroundColor: "primary.main", backgroundSize: 'cover' }} >
            <Box textAlign='center' m= {{md:'40px 200px', xs: '30px 50px'}} >
              <Typography variant='t32_400' fontStyle='italic' color='grey.0' >
              Yesterday's morning breakfast wastage was { foodWastageKgs || 0} kgs which can feed {foodWastageFeed} people
              </Typography>
            </Box>
          </Card> */}
        {/* } */}

        <CurrentOrder />
        <UpcomingOrder />
        <RecentDelivery />
        <QuickOrder />

      </Box>

    </Box>
  )
}

export default Dashboard
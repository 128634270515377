import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RosterIndex from '.'
import { getRosterWatcher } from '../../../../redux/actionCreators/admin/rosterManagement'
import RosterManagement from './rosterManagement'

export default function Roster() {

    const dispatch = useDispatch();
    const { RosterReducers: { rosterList }, UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)

    useEffect(() => {
        let data = { "ACTION": "SELECT", nDivisionID: cafeteriaId }
        dispatch(getRosterWatcher(data))

    }, [dispatch, cafeteriaId])

    const roster = rosterList.length === 0 ? <RosterIndex /> : <RosterManagement />

    return (
        <>
            {roster}
        </>
    )
}

import React, { useState } from 'react';
import {
    TableRow,
    TableCell,
    Typography,
} from '@mui/material';
import ActionButtons from '../../../dashboard/actionButtons';
import EditTextField from '../../../../overrides/editField';
import { upadteViewConUserWatcher, deleteViewConUserWatcher } from '../../../../../redux/actionCreators/admin/contractorManagement';
import { useDispatch, useSelector } from 'react-redux'
import { updateAlert } from "../../../../../redux/actionCreators/alert";

const ConUserTableRow = ({ rowData }) => {
    const dispatch = useDispatch();
    const { ProfileReducers: { loginState }, ContractorReducers: { allConUserList } } = useSelector(state => state)
    const nUserID = loginState[0]?.nID || 0

    const [status, setStatus] = useState('list');
    const [editData, setEditData] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const handleEditChange = (e) => {
        const { name, value } = e.target
        setEditData({ ...editData, [name]: value })
    }

    const handleEdit = () => {
        let data = { ...rowData }
        setEditData(data);
        setStatus('edit');
    }

    const handleDelete = async () => {
        let data = {
            NUSERID: nUserID,
            NID: rowData.NID
        }
        dispatch(deleteViewConUserWatcher(data, rowData.nContractorID))
        setStatus('list');
        setDeleteDialogOpen(false);
    }

    const tickIconFunction = async () => {
        let data = {
            cRFID: editData.cRFID,
            NUSERID: nUserID,
            NID: rowData.NID
        }

        const checkDup = allConUserList.length && allConUserList.some(m => m.cRFID === editData.cRFID)
           if(checkDup) return dispatch(updateAlert('RFID already exist!', 'error')); 

        dispatch(upadteViewConUserWatcher(data, rowData.nContractorID));
        setStatus('list');
    }

    const cancelIconFunction = () => {
        setEditData({});
        setStatus('list');
    }

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                {status === 'list' ?
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.id}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cContractorID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cUserName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cRFID}
                            </Typography>
                        </TableCell>
                    </>
                    :
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.NID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cContractorID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cUserName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='cRFID'
                                value={editData.cRFID}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                    </>
                }
                <TableCell align="right" sx={{pl:0, pr:0}}>
                    <ActionButtons status={status} setStatus={setStatus} type={'contractorUser'} tickIconFunction={tickIconFunction} handleDelete={handleDelete} handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} setDeleteDialogOpen={setDeleteDialogOpen} deleteDialogOpen={deleteDialogOpen} />
                </TableCell>
            </TableRow>
        </>
    )

}

export default ConUserTableRow
import { call, put, takeEvery, select } from "redux-saga/effects";
import {KIOSK_ADD_CART_WATCHER, KIOSK_ADD_MINUS_WATCHER, KIOSK_CLEAR_CART_WATCHER, KIOSK_GET_CART_WATCHER, KIOSK_BOOKING_WATCHER, KIOSK_WATCHER } from "../../actionTypes/user";
import { kioskUpdateCartList, kioskUpdate, kioskClearCartWatcher } from "../../actionCreators/user/kiosk";
import { updateOpenLoader, updateFalseLoader } from "../../actionCreators/spinner";
import Service from "../../../services/user/kiosk"
import ServiceM from "../../../services/admin/menuMaster"
import { updateAlert } from "../../actionCreators/alert";

function* kioskAddCartEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.addCart, action.payload);

    let { data: cart } = yield call(Service.getCart, action.cartData);
    yield put(kioskUpdateCartList(cart.recordset));

    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* kioskGetCartEffect(action) {
  try {
    yield put(updateOpenLoader());

    let { data } = yield call(Service.getCart, action.payload);
    yield put(kioskUpdateCartList(data.recordset));

    yield put(updateFalseLoader());

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* kioskAddMinusEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.addMinus, action.id, action.payload);

    let { data: cart } = yield call(Service.getCart, action.cartData);
    yield put(kioskUpdateCartList(cart.recordset));

    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* kioskClearCartEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.clearCart, action.id);

    let { data: cart } = yield call(Service.getCart, action.cartData);
    yield put(kioskUpdateCartList(cart.recordset));

    yield put(updateFalseLoader());

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* kioskBookingEffect(action) {
    try {
        yield put(updateOpenLoader());
        // let {data} = yield call(Service.kioskBooking, action.data);
    
        const { loginState } = yield select((state) => state.ProfileReducers)
    
        const nUserID = loginState[0]?.nID || 0
        
        const cartData = {
          "ACTION":"CHECKLIST",
           nUserID
        }
        yield put(kioskClearCartWatcher(nUserID, cartData))
        action.pushCurrent()
        yield put(updateAlert('Successfully order placed', 'success'));
        yield put(updateFalseLoader());
      } catch (e) { 
        yield put(updateAlert(e.response?.data.message, 'error'));
        yield put(updateFalseLoader());
      }
}

function* kioskEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ServiceM.getAll, action.data);
    yield put(kioskUpdate(data.recordset));
    yield put(updateFalseLoader());

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

//saga 
export const KioskSaga = [
  takeEvery(KIOSK_ADD_CART_WATCHER, kioskAddCartEffect),
  takeEvery(KIOSK_GET_CART_WATCHER, kioskGetCartEffect),
  takeEvery(KIOSK_ADD_MINUS_WATCHER, kioskAddMinusEffect),
  takeEvery(KIOSK_CLEAR_CART_WATCHER, kioskClearCartEffect),
  takeEvery(KIOSK_BOOKING_WATCHER, kioskBookingEffect),
  takeEvery(KIOSK_WATCHER, kioskEffect),
]

import { Box, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import Breadcrumbs from '../../../components/breadcrumbs'

export default function TermsAndCondition() {
  const { ProfileReducers: { loginState, userAboutDetails } } = useSelector(state => state)
  const divisionID = loginState[0]?.nDivisionID || 0
  const { shortCode, name, email } = userAboutDetails[0]
  return (
    <>
      <Box p='30px'>
        <Breadcrumbs content={["Terms and Condition"]} />
        <Box p='15px 0'>
          <Typography variant='t24_700' > Terms and Condition </Typography>
        </Box>
        <Card sx={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "6px 6px 0px 0px", padding: { md: '20px', xs: '10px' } }}>
          {
            divisionID === 4 ?
              <>
                <CardContent >
                  <Typography variant='t24_600'>3D Cafe- Terms of Use, Cancellation and Refund Policy, Privacy Policy</Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of www.3dcafe.in website and 3DCafe application for mobile and handheld devices.
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t18_600'>Terms of Use</Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    These terms of use (the "Terms of Use") govern your use of our website www.3dcafe.in (the "Website") and our "3DCafe" application for mobile and handheld devices (the "App"). The Website and the App are jointly referred to as the "Platform". Please read these Terms of Use carefully before you use the services. If you do not agree to these Terms of Use, you may not use the services on the Platform, and we request you to uninstall the App. By installing, downloading or even merely using the Platform, you shall be contracting with 3Dcafe and you signify your acceptance to this Terms of Use and other 3Dcafe policies (including but not limited to the Cancellation & Refund Policy, Privacy Policy) as posted on the Platform and amended from time to time, which takes effect on the date on which you download, install or use the Platform, and create a legally binding arrangement to abide by the same.
                    <br /><br />
                    The Platform is managed by {name}, a private limited company incorporated under the Companies Act, 1956 and having its registered office at Ebony, S1, 2nd Floor, #7-1/9, Eagle Street Cross Road, Langford Town, Off Hosur Road, Bangalore – 560025, Tamilnadu, India. For the purpose of these Terms of Use, wherever the context so requires, "you", “user”, or “User” shall mean any natural or legal person who shall transaction on the Platform by providing registration data while registering on the Platform as a registered user using any computer systems. The terms "3DCafe", "we", "us" or "our" shall mean {name}.
                    <br /><br />
                    3DCafe enables transactions on its platform between participating {name}/{name}s and buyers/users, dealing in (a) prepared food and beverages, (b) consumer goods, and (c) other products and services ("Platform Services"). The buyers ("Buyer/s") can choose and place orders ("Orders") from a variety of products listed and offered for sale by {name} ("{name}"), on the Platform. Further, the buyer can also place orders for undertaking certain tasks on the Platform (“Tasks”).
                    <br /><br />
                    3DCafe enables delivery at cafeteria across different location inside the plant facility ("Delivery Services”).
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>Amendments</Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    These Terms of Use are subject to modifications. We reserve the right to modify or change these Terms of Use and other 3DCafe policies at any time by posting modified documents on the Platform and notifying you of the same for your perusal. You shall be liable to update yourself of such changes, if any, by accessing the same. You shall, at all times, be responsible for regularly reviewing the Terms of Use and the other 3DCafe policies and note the changes made on the Platform. Your continued usage of the Services after any change is posted constitutes your acceptance of the amended Terms of Use and other 3DCafe policies. As long as you comply with these Terms of Use, 3DCafe grants you a personal, non-exclusive, non-transferable, limited privilege to access, enter, and use the Platform. By accepting these Terms of Use, you also accept and agree to be bound by the other terms and conditions and 3DCafe policies (including but not limited to Cancellation & Refund Policy, Privacy Policy and Notice and Take Down Policy) as may be posted on the Platform from time to time.
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t18_600'>Use of Platform and Services</Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    All commercial/contractual terms are offered by and agreed to between Buyers and {name} alone with respect to products and services being offered by the {name}. The commercial/contractual terms include without limitation price, applicable taxes, shipping costs, payment terms, date, period and mode of delivery. 3Dcafe does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Buyers and {name}. 3Dcafe may, however, offer support services to {name} in respect to Order fulfilment, mode of payment, payment collection, On call support, pursuant to independent contracts executed by 3Dcafe with the {name}. The price of the product and services offered by the {name} are determined by the {name} itself and 3Dcafe has no role to play in such determination of price in any way whatsoever.
                    <br /><br />
                    Upon acceptance of any Order, the delivery services or Task completion services (as the case may be) undertaken by him/her, shall constitute a separate contract for services between {name}/Buyers. 3Dcafe shall not be responsible for the services payment collection, price fixing, etc provided by {name}/Buyers through the Platform.
                    <br /><br />
                    3Dcafe is not responsible for any non-performance or breach of any contract entered into between Buyers and {name}. 3Dcafe cannot and does not guarantee that the concerned Buyers, {name}. 3Dcafe is not responsible for unsatisfactory or non-performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.
                    <br /><br />
                    3Dcafe is operating an online marketplace and assumes the role of facilitator, and does not at any point of time during any transaction between Buyer and {name} and/or Buyer. At no time shall 3Dcafe hold any right, title or interest over the products nor shall 3Dcafe have any obligations or liabilities in respect of such contract entered into between Buyer and {name} and/or Buyer.
                    <br /><br />
                    3Dcafe is only providing a platform for communication and it is agreed that the contract for sale of any of the products or services shall be a strictly bipartite contract between the {name} and the Buyer. In case of complaints from the Buyer pertaining to efficacy, quality, or any other such issues, 3Dcafe shall notify the same to {name} and also the Buyer can reach. The {name} shall be liable for redressing Buyer complaints. In the event you raise any complaint on any {name} accessed using our Platform, we shall assist you to the best of our abilities by providing relevant information to you, such as details of the {name} and the specific Order to which the complaint relates, to enable satisfactory resolution of the complaint.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>3Dcafe - Use of the Website and Apps (Android and iOS)</Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    You agree, undertake and confirm that your use of Platform shall be strictly governed by the following binding principles: You shall not host, display, upload, download, modify, publish, transmit, update or share any information in which where:
                    <br /><br />
                    It belongs to another person and which you do not have any right to;
                    <br /><br />
                    It is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, slanderous, criminally inciting or invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;
                    <br /><br />
                    It is misleading or misrepresentative in any way;
                    <br /><br />
                    It is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;
                    <br /><br />
                    harasses or advocates harassment of another person;
                    <br /><br />
                    harasses or advocates harassment of another person;
                    <br /><br />
                    involving the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";
                    <br /><br />
                    promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;
                    <br /><br />
                    infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity;
                    <br /><br />
                    promotes an illegal or unauthorized copy of another person's copyrighted work (see "copyright complaint" below for instructions on how to lodge a complaint about uploaded copyrighted material), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;
                    <br /><br />
                    contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);
                    <br /><br />
                    provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;
                    <br /><br />
                    provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;
                    <br /><br />
                    contains video, photographs, or images of another person (with a minor or an adult);
                    <br /><br />
                    tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;
                    <br /><br />
                    engages in commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of products related to the Platform. Throughout these Terms of Use, 3Dcafe's prior written consent means a communication coming from 3Dcafe's Legal Department, specifically in response to your request, and expressly addressing and allowing the activity or conduct for which you seek authorization;
                    <br /><br />
                    solicits gambling or engages in any gambling activity which is or could be construed as being illegal;
                    <br /><br />
                    interferes with another user's use and enjoyment of the Platform or any third party's user and enjoyment of similar services;
                    <br /><br />
                    refers to any website or URL that, in our sole discretion, contains material that is inappropriate for the Platform or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms of Use;
                    <br /><br />
                    harm minors in any way;
                    <br /><br />
                    infringes any patent, trademark, copyright or other intellectual property rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;
                    <br /><br />
                    violates any law for the time being in force;
                    <br /><br />
                    deceives or misleads the addressee/users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;
                    <br /><br />
                    impersonate another person;
                    <br /><br />
                    contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;
                    <br /><br />
                    threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any criminal offence or prevents investigation of any offence or is insulting any other nation;
                    <br /><br />
                    is false, inaccurate or misleading;
                    <br /><br />
                    directly or indirectly, offers, attempts to offer, trades or attempts to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force; or
                    <br /><br />
                    creates liability for us or causes us to lose (in whole or in part) the services of our internet service provider or other suppliers.
                    <br /><br />
                    You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Platform. We reserve our right to prohibit any such activity.
                    <br /><br />
                    You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the services offered on or through the Platform, by hacking, "password mining" or any other illegitimate means.
                    <br /><br />
                    You shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication measures on the Platform or any network connected to the Platform. You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to Platform, or any other Buyer, including any account on the Platform not owned by you, to its source, or exploit the Platform or any service or information made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by the Platform.
                    <br /><br />
                    You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about us or the brand name or domain name used by us including the name '3Dcafe', or otherwise engage in any conduct or action that might tarnish the image or reputation, of 3Dcafe or {name} on platform or otherwise tarnish or dilute any 3Dcafe's trade or service marks, trade name and/or goodwill associated with such trade or service marks, as may be owned or used by us. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Platform or 3Dcafe's systems or networks, or any systems or networks connected to 3Dcafe.
                    <br /><br />
                    You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Platform or any transaction being conducted on the Platform, or with any other person's use of the Platform.
                    <br /><br />
                    You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to us on or through the Platform or any service offered on or through the Platform. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity.
                    <br /><br />
                    You may not use the Platform or any content on the Platform for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity that infringes the rights of 3Dcafe and/or others.
                    <br /><br />
                    You shall at all times ensure full compliance with the applicable provisions, as amended from time to time, of (a) the Information Technology Act, 2000 and the rules thereunder; (b) all applicable domestic laws, rules and regulations (including the provisions of any applicable exchange control laws or regulations in force); and (c) international laws, foreign exchange laws, statutes, ordinances and regulations (including, but not limited to sales tax/VAT, income tax, octroi, service tax, central excise, custom duty, local levies) regarding your use of our service and your listing, purchase, solicitation of offers to purchase, and sale of products or services. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.
                    <br /><br />
                    In order to allow us to use the information supplied by you, without violating your rights or any laws, you agree to grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights you have in your Information, in any media now known or not currently known, with respect to your Information. We will only use your information in accordance with these Terms of Use and Privacy Policy applicable to use of the Platform.
                    <br /><br />
                    From time to time, you shall be responsible for providing information relating to the products or services proposed to be sold by you. In this connection, you undertake that all such information shall be accurate in all respects. You shall not exaggerate or overemphasize the attributes of such products or services so as to mislead other users in any manner.
                    <br /><br />
                    You shall not engage in advertising to, or solicitation of, other users of the Platform to buy or sell any products or services, including, but not limited to, products or services related to that being displayed on the Platform or related to us. You may not transmit any chain letters or unsolicited commercial or junk email to other users via the Platform. It shall be a violation of these Terms of Use to use any information obtained from the Platform in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than us without our prior explicit consent. In order to protect our users from such advertising or solicitation, we reserve the right to restrict the number of messages or emails which a user may send to other users in any 24-hour period which we deem appropriate in its sole discretion. You understand that we have the right at all times to disclose any information (including the identity of the persons providing information or materials on the Platform) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena. In addition, We can (and you hereby expressly authorize us to) disclose any information about you to law enforcement or other government officials, as we, in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.
                    <br /><br />
                    We reserve the right, but has no obligation, to monitor the materials posted on the Platform. 3Dcafe shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE PLATFORM AND IN YOUR PRIVATE MESSAGES. Please be advised that such Content posted does not necessarily reflect 3Dcafe views. In no event shall 3Dcafe assume or have any responsibility or liability for any Content posted or for any claims, damages or losses resulting from use of Content and/or appearance of Content on the Platform. You hereby represent and warrant that you have all necessary rights in and to all Content which you provide and all information it contains and that such Content shall not infringe any proprietary or other rights of third parties or contain any libellous, tortious, or otherwise unlawful information.
                    <br /><br />
                    Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Platform, including payment and delivery of related products or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Platform.
                    <br /><br />
                    It is possible that other users (including unauthorized users or 'hackers') may post or transmit offensive or obscene materials on the Platform and that you may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about you due to your use of the Platform, and that the recipient may use such information to harass or injure you. We do not approve of such unauthorized uses, but by using the Platform You acknowledge and agree that we are not responsible for the use of any personal information that you publicly disclose or share with others on the Platform. Please carefully select the type of information that you publicly disclose or share with others on the Platform.
                    <br /><br />
                    3Dcafe shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services), hacking, pen testing attempts without our prior consent or a mutual legal agreement.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Account Registration
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    You may access the Platform by registering to create an account with your plant HR (" 3Dcafe Account") and become a member (" Membership"); The Membership is limited for the purpose and are subject to the terms, and strictly not transferable.
                    <br /><br />
                    We will create your 3Dcafe Account for your use of the Platform services based upon the personal information you provide to us, as described above. You can only have one 3Dcafe Account and are not permitted to create multiple accounts. 3Dcafe reserves the right to suspend such multiple accounts without being liable for any compensation where you have created multiple accounts on the Platform.
                    <br /><br />
                    You agree to provide accurate, current and complete information during the registration process and update such information to keep it accurate, current and complete.
                    <br /><br />
                    We reserve the right to suspend or terminate your 3Dcafe Account and your access to the Services (i) if any information provided during the registration process or thereafter proves to be inaccurate, not current or incomplete; (ii) if it is believed that your actions may cause legal liability for you, other users or us; and/or (iii) if you are found to be non-compliant with the Terms of Use or other 3Dcafe policies.
                    <br /><br />
                    You are responsible for safeguarding your password. You agree that you will not disclose your password to any third party and that you will take sole responsibility for any activities or actions under your 3Dcafe Account, whether or not you have authorized such activities or actions. You will immediately notify us of any unauthorized use of your 3Dcafe Account.
                    <br /><br />
                    Goods purchased from the Platform are intended for your personal use and you represent that the same are not for resale or you are not acting as an agent for other parties.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Order Booking and Financial Terms
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    The Platform allows the Buyers to place Orders and upon acceptance of such Orders by the {name}, 3Dcafe will, subject to the terms and conditions set out herein, facilitates delivery of goods or services, or completion of Tasks through PDP.
                    <br /><br />
                    3Dcafe does not own, sell, resell on its own such products offered by the {name}, and/or does not control the {name} or the related services provided in connection thereof. Buyer understands that any Order that he/she places shall be subject to the terms and conditions set out in these Terms of Use including, but not limited to, product availability, delivery, and acceptance of Orders by {name}.
                    <br /><br />
                    As a general rule, all Orders placed on the Platform and Delivery Services are treated as confirmed.
                    <br /><br />
                    However, upon Buyer’s successful completion of booking an Order, 3DCafe will share the booking information to {name} to register your bookings.
                    <br /><br />
                    In addition to the foregoing, 3DCafe is defined the cut-off time for ordering and cancelling the food order. Buyer needs to book/cancel their order prior to 15 Hours which is 5:00 PM every day. Post the cut-off time the book/cancel option will be disabled to buyers. If the food order is not taken at any circumstances the status of food will be marked delivered and the buyer have no rights to refund the amount paid for the order.
                    <br /><br />
                    All payments made against the Orders or Services on the Platform by you shall be compulsorily in Indian Rupees acceptable in the Republic of India. The Platform will not facilitate transactions with respect to any other form of currency with respect to the Orders or Services made on Platform. You can pay by (i) credit card or debit card or net banking; (ii) any other RBI approved payment method at the time of booking an Order; or (iii) credit or debit card or cash at the time of delivery. You understand, accept and agree that the payment facility provided by 3Dcafe is neither a banking nor financial service but is merely a facilitator providing an electronic, automated online electronic payment, receiving payment on delivery, collection and remittance facility for the transactions on the Platform using the existing authorized banking infrastructure and credit card payment gateway networks. Further, by providing payment facility, 3Dcafe is neither acting as trustees nor acting in a fiduciary capacity with respect to the transaction or the transaction price.
                    <br /><br />
                    Buyers acknowledge and agree that 3Dcafe will not acts as the {name}'s payment agent for the limited purpose of accepting payments from Buyers/{name} on behalf of the {name}.
                    <br /><br />
                    Buyer agrees to pay for the total amount for the Order placed on the Platform. 3Dcafe will not collect the amount in accordance with these Terms of Use and the pricing terms set forth in the applicable listing of product for the particular {name}. Please note that we cannot control any amount that may be charged to Buyer by his/her bank related to our collection of the total amount, and we disclaim all liability in this regard.
                    <br /><br />
                    Buyer agrees to pay for the Order placed by you on the Platform, in accordance with these Terms, using the methods described under clause above.If Buyer is directed to our third-party payment processor, he/she may be subject to terms and conditions governing use of that third party's service and that third party's personal information collection practices. Please review such terms and conditions and privacy policy before using the Platform services. Once the Order is confirmed you will receive a confirmation email summarizing the confirmed booking.
                    <br /><br />
                    The final tax bill will be issued by the {name} (if registered for tax purposes) to the Buyer along with the Order and 3Dcafe is merely register the payment on behalf of such {name}. All applicable taxes and levies, the rates thereof and the manner of applicability of such taxes on the bill are being charged and determined by the {name}. 3Dcafe holds no responsibility for the legal correctness/validity of the levy of such taxes. The sole responsibility for any legal issue arising on the taxes shall reside with the {name}.
                    <br /><br />
                    The prices reflected on the Platform are determined solely by the {name} and are listed based on {name}'s information..
                    <br /><br />
                    The {name} shall be solely responsible for any warranty/guarantee of the goods sold to the Buyers and in no event shall be the responsibility of 3Dcafe.
                    <br /><br />
                    The transactions are bilateral between the {name} and Buyer, therefore, 3Dcafe is not liable to charge or deposit any taxes applicable on such transactions.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Cancellations and Refunds
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t18_600'>
                    Cancellation Policy:
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    Buyer can cancel the order for the next day prior to the cut-off time (5:00 PM) of every day agreed by user/buyer and {name}. After 5:00 PM Cancel/Booking option will be disabled to the buyer.
                    <br /><br />
                    Buyer can choose partial cancellation of the orders placed in 3DCafe.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Refund Policy:
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    Refund of bulk or partial orders will be initiated to the user/buyer at the time of cancellation.
                    <br /><br />
                    Once your return is received and inspected, the banker/payment gateway service provider will send you an email to notify you that we have received your returned item. The banker/payment gateway service provider will also notify you of the approval or rejection of your refund.
                    <br /><br />
                    If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.
                    <br /><br />
                    If you haven’t received a refund yet, first check your bank account again.
                    <br /><br />
                    Then contact your credit card company, it may take some time before your refund is officially posted.
                    <br /><br />
                    Next contact your bank. There is often some processing time before a refund is posted.
                    <br /><br />
                    If you’ve done all of this and you still have not received your refund yet, please contact us at [{email}].
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Privacy Policy
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t18_600'>
                    What do we do with your information?
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    When you are ordering something from 3DCafe, as part of the buying and selling process, we will collect the personal information you give us such as your name and email address.
                    <br /><br />
                    Email marketing (Not Allowed): 3DCafe will not allowed to third parties to post their ads, updates to the website. We may send you emails about our bookings/cancellations, deliveries.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    How do you get my consent?
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only. If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    How do I withdraw my consent?
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting to the payment gateway service provider or mailing us at: [{email}]
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Terms of service
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    The Buyer agrees and acknowledges that 3Dcafe shall not be responsible for:
                    <br /><br />
                    The services or goods provided by the {name} including but not limited to serving of food Orders suiting your requirements and taste;
                    <br /><br />
                    The {name}'s services or goods are not being up to Buyer expectations or leading to any loss, harm or damage to him/her;
                    <br /><br />
                    The availability or unavailability of certain items on the menu;
                    <br /><br />
                    The {name} serving the incorrect Orders; or
                    <br /><br />
                    Product liability of goods provided by {name}.
                    <br /><br />
                    The details of the menu and price list available on the Platform with respect to goods or any other services are based on the information provided by the {name} and 3Dcafe shall not be responsible for any change or cancellation or unavailability.
                    <br /><br />
                    Buyers and {name} agree and acknowledge that 3Dcafe is not responsible for any liability arising out of delivery services provided by PDP to them.
                    <br /><br />
                    Buyers may not be able to avail Services if their delivery location is different 3Dcafe’s current scope of Service. 3Dcafe will keep the Buyer informed of the same at the time of confirming his/her Order booking.
                    <br /><br />
                    The Buyer understands that 3Dcafe’s (including {name}’s) liability ends once Order has been delivered to him/her, except where the product liability of the {name} subsists.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Services provided:
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    You agree and acknowledge that 3Dcafe shall be liable in the event you have failed to adhere to the Terms of Use.
                    <br /><br />
                    Buyer shall be required to provide credit or debit card details to the approved payment gateways while making the payment on the Platform. In this regard, Buyer agrees to provide correct and accurate credit/ debit card details to the approved payment gateways for availing the Services. Buyer shall not use the credit/ debit card which is not lawfully owned by Buyer, i.e. in any transaction, Buyer must use his/her own credit/ debit card. The information provided by the Buyer will not be utilized or shared with any third party unless required in relation to fraud verifications or by law, regulation or court order. Buyer shall be solely responsible for the security and confidentiality of his/her credit/ debit card details. We expressly disclaim all liabilities that may arise as a consequence of any unauthorized use of your credit/ debit card.
                    <br /><br />
                    3Dcafe does not offer any refunds against goods already purchased from a {name}. Buyer should reach to contact {name} with transaction and booking reference if an error that is directly attributable to 3Dcafe has occurred during the purchase of such product or services.
                    <br /><br />
                    We constantly strive to provide you with accurate information on the Platform. However, in the event of an error, we may, in our sole discretion, contact you with further instructions.
                    <br /><br />
                    If you use the Platform, you do the same at your own risk.
                    <br /><br />
                    Buyer agrees that the Services shall be provided through the Platform only during the working hours of the relevant {name}.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    No Endorsement
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    We do not endorse any {name}. In addition, although these Terms of Use require you to provide accurate information, we do not attempt to confirm, and do not confirm if it is purported identity. We will not be responsible for any damage or harm resulting from your interactions with other Members.
                    <br /><br />
                    By using the Services, you agree that any legal remedy or liability that you seek to obtain for actions or omissions of other Members or other third parties will be limited to a claim against the particular Members or other third parties who caused you harm and you agree not to attempt to impose liability on, or seek any legal remedy from us with respect to such actions or omissions.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    General:
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to use the Platform. Only individuals who are 18 years of age or older may use the Platform and avail Services. If you are under 18 years of age and you wish to download, install, access or use the Platform, your parents or legal guardian must acknowledge and agree to the Terms of Use and Privacy Policy. Should your parents or legal guardian fail to agree or acknowledge the Terms of Use and 3Dcafe policies, you shall immediately discontinue its use. 3Dcafe reserves the right to terminate your Membership and / or deny access to the platform if it is brought to 3Dcafe's notice that you are under the age of 18 years.
                    <br /><br />
                    If you choose to use the Platform, it shall be your responsibility to treat your user identification code, password and any other piece of information that we may provide, as part of our security procedures, as confidential and not disclose the same to any person or entity other than us. We shall at times and at our sole discretion reserve the right to disable any user identification code or password if you have failed to comply with any of the provisions of these Terms of Use.
                    <br /><br />
                    You shall at all times be responsible for the use of the Services through your computer or mobile device and for bringing these Terms of Use and 3Dcafe policies to the attention of all such persons accessing the Platform on your computer or mobile device.
                    <br /><br />
                    You understand and agree that the use of the Services does not include the provision of a computer or mobile device or other necessary equipment to access it. You also understand and acknowledge that the use of the Platform requires internet connectivity and telecommunication links. You shall bear the costs incurred to access and use the Platform and avail the Services, and we shall not, under any circumstances whatsoever, be responsible or liable for such costs.
                    <br /><br />
                    By using the Platform you represent and warrant that:
                    <br /><br />
                    All registration information you submit is truthful, lawful and accurate and that you agree to maintain the accuracy of such information.
                    <br /><br />
                    Your use of the Platform shall be solely for your personal use and you shall not authorize others to use your account, including your profile or email address and that you are solely responsible for all content published or displayed through your account, including any email messages, and your interactions with other users and you shall abide by all applicable local, state, national and foreign laws, treaties and regulations, including those related to data privacy, international communications and the transmission of technical or personal data.
                    <br /><br />
                    You will not submit, post, upload, distribute, or otherwise make available or transmit any content that: (a) is defamatory, abusive, harassing, insulting, threatening, or that could be deemed to be stalking or constitute an invasion of a right of privacy of another person; (b) is bigoted, hateful, or racially or otherwise offensive; (c) is violent, vulgar, obscene, pornographic or otherwise sexually explicit; (d) is illegal or encourages or advocates illegal activity or the discussion of illegal activities with the intent to commit them.
                    <br /><br />
                    All necessary licenses, consents, permissions and rights are owned by you and there is no need for any payment or permission or authorization required from any other party or entity to use, distribute or otherwise exploit in all manners permitted by these Terms of Use and Privacy Policy, all trademarks, copyrights, patents, trade secrets, privacy and publicity rights and / or other proprietary rights contained in any content that you submit, post, upload, distribute or otherwise transmit or make available.
                    <br /><br />
                    You will not (a) use any services provided by the Platform for commercial purposes of any kind, or (b) advertise or sell any products, services or otherwise (whether or not for profit), or solicit others (including, without limitation, solicitations for contributions or donations) or use any public forum for commercial purposes of any kind. In the event you want to advertise your product or service contact {email}.
                    <br /><br />
                    You will not use the Platform in any way that is unlawful, or harms us or any other person or entity, as determined in our sole discretion.
                    <br /><br />
                    You will not post, submit, upload, distribute, or otherwise transmit or make available any software or other computer files that contain a virus or other harmful component, or otherwise impair or damage the Platform or any connected network, or otherwise interfere with any person or entity's use or enjoyment of the Platform.
                    <br /><br />
                    You will not use another person's username, password or other account information, or another person's name, likeness, voice, image or photograph or impersonate any person or entity or misrepresent your identity or affiliation with any person or entity.
                    <br /><br />
                    You will not engage in any form of antisocial, disrupting, or destructive acts, including "flaming," "spamming," "flooding," "trolling," and "griefing" as those terms are commonly understood and used on the Internet.
                    <br /><br />
                    You will not delete or modify any content of the Platform, including but not limited to, legal notices, disclaimers or proprietary notices such as copyright or trademark symbols, logos, that you do not own or have express permission to modify.
                    <br /><br />
                    You will not post or contribute any information or data that may be obscene, indecent, pornographic, vulgar, profane, racist, sexist, discriminatory, offensive, derogatory, harmful, harassing, threatening, embarrassing, malicious, abusive, hateful, menacing, defamatory, untrue or political or contrary to our interest.
                    <br /><br />
                    You shall not access the Platform without authority or use the Platform in a manner that damages, interferes or disrupts:
                    <br /><br />
                    any part of the Platform or the Platform software; or
                    <br /><br />
                    any equipment or any network on which the Platform is stored or any equipment of any third party
                    <br /><br />
                    You release and fully indemnify 3Dcafe and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the Platform and specifically waive any claims that you may have in this behalf under any applicable laws of India. Notwithstanding its reasonable efforts in that behalf, 3Dcafe cannot take responsibility or control the information provided by other Users which is made available on the Platform. You may find other User's information to be offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution and practice safe trading when using the Platform.
                    <br /><br />
                    Access to the Platform, Accuracy and security
                    <br /><br />
                    We endeavour to make the Services available during {name} or PDP working hours. However, we do not represent that access to the Platform will be uninterrupted, timely, error free, free of viruses or other harmful components or that such defects will be corrected.
                    <br /><br />
                    We do not warrant that the Platform will be compatible with all hardware and software which you may use. We shall not be liable for damage to, or viruses or other code that may affect, any equipment (including but not limited to your mobile device), software, data or other property as a result of your download, installation, access to or use of the Platform or your obtaining any material from, or as a result of using, the Platform. We shall also not be liable for the actions of third parties.
                    <br /><br />
                    We do not represent or warranty that the information available on the Platform will be correct, accurate or otherwise reliable.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Disclaimers
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    The platform may be under constant upgrades, and some functions and features may not be fully operational.
                    <br /><br />
                    Due to the vagaries that can occur in the electronic distribution of information and due to the limitations inherent in providing information obtained from multiple sources, there may be delays, omissions, or inaccuracies in the content provided on the platform or delay or errors in functionality of the platform. As a result, we do not represent that the information posted is correct in every case.
                    <br /><br />
                    We expressly disclaim all liabilities that may arise as a consequence of any unauthorized use of credit/ debit cards.
                    <br /><br />
                    You acknowledge that third party services are available on the platform. We may have formed partnerships or alliances with some of these third parties from time to time in order to facilitate the provision of certain services to you. However, you acknowledge and agree that at no time are we making any representation or warranty regarding any third party's services nor will we be liable to you or any third party for any consequences or claims arising from or in connection with such third party including, and not limited to, any liability or responsibility for, death, injury or impairment experienced by you or any third party. You hereby disclaim and waive any rights and claims you may have against us with respect to third party's / cafs’s/ pdp’s services.
                    <br /><br />
                    3dcafe disclaims and all liability that may arise due to any violation of any applicable laws including the law applicable to products and services offered by the cafs or pdp.
                    <br /><br />
                    While the materials provided on the platform were prepared to provide accurate information regarding the subject discussed, the information contained in these materials is being made available with the understanding that we make no guarantees, representations or warranties whatsoever, whether expressed or implied, with respect to professional qualifications, expertise, quality of work or other information herein. Further, we do not, in any way, endorse any service offered or described herein. In no event shall we be liable to you or any third party for any decision made or action taken in reliance on such information.
                    <br /><br />
                    The information provided hereunder is provided "as is". We and / or our employees make no warranty or representation regarding the timeliness, content, sequence, accuracy, effectiveness or completeness of any information or data furnished hereunder or that the information or data provided hereunder may be relied upon. Multiple responses may usually be made available from different sources and it is left to the judgement of users based on their specific circumstances to use, adapt, modify or alter suggestions or use them in conjunction with any other sources they may have, thereby absolving us as well as our consultants, business associates, affiliates, business partners and employees from any kind of professional liability.
                    <br /><br />
                    We shall not be liable to you or anyone else for any losses or injury arising out of or relating to the information provided on the platform. In no event will we or our employees, affiliates, authors or agents be liable to you or any third party for any decision made or action taken by your reliance on the content contained herein.
                    <br /><br />
                    In no event will we be liable for any damages (including, without limitation, direct, indirect, incidental, special, consequential or exemplary damages, damages arising from personal injury/wrongful death, and damages resulting from lost profits, lost data or business interruption), resulting from any services provided by any third party or cafs accessed through the platform, whether based on warranty, contract, tort, or any other legal theory and whether or not we are advised of the possibility of such damages.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Intellectual property
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    We are either the owner of intellectual property rights or have the non-exclusive, worldwide, perpetual, irrevocable, royalty free, sub-licensable (through multiple tiers) right to exercise the intellectual property, in the Platform, and in the material published on it including but not limited to user interface, layout format, Order placing process flow and any content thereof.
                    <br /><br />
                    You recognize that 3Dcafe is the registered owner of the word mark ‘3Dcafe’ and the logo including but not limited to its variants (IPR) and shall not directly or indirectly, attack or assist another in attacking the validity of, or 3Dcafe’s or its affiliates proprietary rights in, the licensed marks or any registrations thereof, or file any applications for the registration of the licensed marks or any names or logos derived from or confusingly similar to the licensed marks, any variation thereof, or any translation or transliteration thereof in another language, in respect of any products/services and in any territory throughout the world.
                    <br /><br />
                    You may print off one copy, and may download extracts, of any page(s) from the Platform for your personal reference and you may draw the attention of others within your organisation to material available on the Platform.
                    <br /><br />
                    You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
                    <br /><br />
                    You must not use any part of the materials on the Platform for commercial purposes without obtaining a licence to do so from us or our licensors.
                    <br /><br />
                    If you print off, copy or download any part of the Platform in breach of these Terms of Use, your right to use the Platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
                    <br /><br />
                    Treatment of information provided by you
                    <br /><br />
                    We process information provided by you to us in accordance with our Privacy Policy.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Third Party Content
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    We cannot and will not assure that other users are or will be complying with the foregoing rules or any other provisions of these Terms of Use, and, as between you and us, you hereby assume all risk of harm or injury resulting from any such lack of compliance.
                    <br /><br />
                    You acknowledge that when you access a link that leaves the Platform, the site you will enter into is not controlled by us and different terms of use and Privacy Policy may apply. By assessing links to other sites, you acknowledge that we are not responsible for those sites. We reserve the right to disable links to and / or from third-party sites to the Platform, although we are under no obligation to do so.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Severability
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    If any of these Terms of Use should be determined to be illegal, invalid or otherwise unenforceable by reason of the laws of any state or country in which these Terms of Use are intended to be effective, then to the extent and within the jurisdiction where that term is illegal, invalid or unenforceable, it shall be severed and deleted and the remaining Terms of Use shall survive, remain in full force and effect and continue to be binding and enforceable.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Non-assignment
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    You shall not assign or transfer or purport to assign or transfer the contract between you and us to any other person.
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t18_600'>
                    Governing law and dispute resolution
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    These Terms of Use are governed by the laws of India. Any action, suit, or other legal proceeding, which is commenced to resolve any matter arising under or relating to this Platform, shall be subject to the jurisdiction of the courts at Bangalore, India.
                  </Typography>
                </CardContent>

                
                <CardContent>
                  <Typography variant='t18_600'>
                    Shipping Policy
                  </Typography>
                </CardContent>
                <CardContent>
                <Typography variant='t14_400'>
                  No shipping policy applies on deliveries.
                  </Typography>
                </CardContent>
              </>
              :
              <>
                <CardContent >
                  <Typography variant='t24_600'>3D Cafe- Terms of Use, Cancellation and Refund Policy, Privacy Policy</Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of www.3dcafe.in website and 3DCafe application for mobile and handheld devices.
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t18_600'>Terms of Use</Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    These terms of use (the "Terms of Use") govern your use of our website www.3dcafe.in (the "Website") and our "3DCafe" application for mobile and handheld devices (the "App"). The Website and the App are jointly referred to as the "Platform". Please read these Terms of Use carefully before you use the services. If you do not agree to these Terms of Use, you may not use the services on the Platform, and we request you to uninstall the App. By installing, downloading or even merely using the Platform, you shall be contracting with 3Dcafe and you signify your acceptance to this Terms of Use and other 3Dcafe policies (including but not limited to the Cancellation & Refund Policy, Privacy Policy) as posted on the Platform and amended from time to time, which takes effect on the date on which you download, install or use the Platform, and create a legally binding arrangement to abide by the same.
                    <br /><br />
                    The Platform is managed by {shortCode} ({name}), a private limited company incorporated under the Companies Act, 1956 and having its registered office at A Unit of Anjali Hotels Pvt Ltd, 27, Signal Office Road, Pammal, Chennai – 600075, Tamilnadu, India. For the purpose of these Terms of Use, wherever the context so requires, "you", “user”, or “User” shall mean any natural or legal person who shall transaction on the Platform by providing registration data while registering on the Platform as a registered user using any computer systems. The terms "3DCafe", "we", "us" or "our" shall mean {shortCode} (Casino air caterers & Flight Service).
                    <br /><br />
                    3DCafe enables transactions on its platform between participating {shortCode}/{shortCode}'s and buyers/users, dealing in (a) prepared food and beverages, (b) consumer goods, and (c) other products and services ("Platform Services"). The buyers ("Buyer/s") can choose and place orders ("Orders") from a variety of products listed and offered for sale by {shortCode} ("{shortCode}"), on the Platform. Further, the buyer can also place orders for undertaking certain tasks on the Platform (“Tasks”).
                    <br /><br />
                    3DCafe enables delivery at cafeteria across different location inside the plant facility ("Delivery Services”).
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>Amendments</Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    These Terms of Use are subject to modifications. We reserve the right to modify or change these Terms of Use and other 3DCafe policies at any time by posting modified documents on the Platform and notifying you of the same for your perusal. You shall be liable to update yourself of such changes, if any, by accessing the same. You shall, at all times, be responsible for regularly reviewing the Terms of Use and the other 3DCafe policies and note the changes made on the Platform. Your continued usage of the Services after any change is posted constitutes your acceptance of the amended Terms of Use and other 3DCafe policies. As long as you comply with these Terms of Use, 3DCafe grants you a personal, non-exclusive, non-transferable, limited privilege to access, enter, and use the Platform. By accepting these Terms of Use, you also accept and agree to be bound by the other terms and conditions and 3DCafe policies (including but not limited to Cancellation & Refund Policy, Privacy Policy and Notice and Take Down Policy) as may be posted on the Platform from time to time.
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t18_600'>Use of Platform and Services</Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    All commercial/contractual terms are offered by and agreed to between Buyers and {shortCode} alone with respect to products and services being offered by the {shortCode}. The commercial/contractual terms include without limitation price, applicable taxes, shipping costs, payment terms, date, period and mode of delivery. 3Dcafe does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Buyers and {shortCode}. 3Dcafe may, however, offer support services to {shortCode} in respect to Order fulfilment, mode of payment, payment collection, On call support, pursuant to independent contracts executed by 3Dcafe with the {shortCode}. The price of the product and services offered by the {shortCode} are determined by the {shortCode} itself and 3Dcafe has no role to play in such determination of price in any way whatsoever.
                    <br /><br />
                    Upon acceptance of any Order, the delivery services or Task completion services (as the case may be) undertaken by him/her, shall constitute a separate contract for services between {shortCode}/Buyers. 3Dcafe shall not be responsible for the services payment collection, price fixing, etc provided by {shortCode}/Buyers through the Platform.
                    <br /><br />
                    3Dcafe is not responsible for any non-performance or breach of any contract entered into between Buyers and {shortCode}. 3Dcafe cannot and does not guarantee that the concerned Buyers, {shortCode}. 3Dcafe is not responsible for unsatisfactory or non-performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.
                    <br /><br />
                    3Dcafe is operating an online marketplace and assumes the role of facilitator, and does not at any point of time during any transaction between Buyer and {shortCode} and/or Buyer. At no time shall 3Dcafe hold any right, title or interest over the products nor shall 3Dcafe have any obligations or liabilities in respect of such contract entered into between Buyer and {shortCode} and/or Buyer.
                    <br /><br />
                    3Dcafe is only providing a platform for communication and it is agreed that the contract for sale of any of the products or services shall be a strictly bipartite contract between the {shortCode} and the Buyer. In case of complaints from the Buyer pertaining to efficacy, quality, or any other such issues, 3Dcafe shall notify the same to {shortCode} and also the Buyer can reach. The {shortCode} shall be liable for redressing Buyer complaints. In the event you raise any complaint on any {shortCode} accessed using our Platform, we shall assist you to the best of our abilities by providing relevant information to you, such as details of the {shortCode} and the specific Order to which the complaint relates, to enable satisfactory resolution of the complaint.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>3Dcafe - Use of the Website and Apps (Android and iOS)</Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    You agree, undertake and confirm that your use of Platform shall be strictly governed by the following binding principles: You shall not host, display, upload, download, modify, publish, transmit, update or share any information in which where:
                    <br /><br />
                    It belongs to another person and which you do not have any right to;
                    <br /><br />
                    It is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, slanderous, criminally inciting or invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;
                    <br /><br />
                    It is misleading or misrepresentative in any way;
                    <br /><br />
                    It is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;
                    <br /><br />
                    harasses or advocates harassment of another person;
                    <br /><br />
                    harasses or advocates harassment of another person;
                    <br /><br />
                    involving the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";
                    <br /><br />
                    promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;
                    <br /><br />
                    infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity;
                    <br /><br />
                    promotes an illegal or unauthorized copy of another person's copyrighted work (see "copyright complaint" below for instructions on how to lodge a complaint about uploaded copyrighted material), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;
                    <br /><br />
                    contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);
                    <br /><br />
                    provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;
                    <br /><br />
                    provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;
                    <br /><br />
                    contains video, photographs, or images of another person (with a minor or an adult);
                    <br /><br />
                    tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;
                    <br /><br />
                    engages in commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of products related to the Platform. Throughout these Terms of Use, 3Dcafe's prior written consent means a communication coming from 3Dcafe's Legal Department, specifically in response to your request, and expressly addressing and allowing the activity or conduct for which you seek authorization;
                    <br /><br />
                    solicits gambling or engages in any gambling activity which is or could be construed as being illegal;
                    <br /><br />
                    interferes with another user's use and enjoyment of the Platform or any third party's user and enjoyment of similar services;
                    <br /><br />
                    refers to any website or URL that, in our sole discretion, contains material that is inappropriate for the Platform or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms of Use;
                    <br /><br />
                    harm minors in any way;
                    <br /><br />
                    infringes any patent, trademark, copyright or other intellectual property rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;
                    <br /><br />
                    violates any law for the time being in force;
                    <br /><br />
                    deceives or misleads the addressee/users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;
                    <br /><br />
                    impersonate another person;
                    <br /><br />
                    contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;
                    <br /><br />
                    threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any criminal offence or prevents investigation of any offence or is insulting any other nation;
                    <br /><br />
                    is false, inaccurate or misleading;
                    <br /><br />
                    directly or indirectly, offers, attempts to offer, trades or attempts to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force; or
                    <br /><br />
                    creates liability for us or causes us to lose (in whole or in part) the services of our internet service provider or other suppliers.
                    <br /><br />
                    You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Platform. We reserve our right to prohibit any such activity.
                    <br /><br />
                    You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the services offered on or through the Platform, by hacking, "password mining" or any other illegitimate means.
                    <br /><br />
                    You shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication measures on the Platform or any network connected to the Platform. You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to Platform, or any other Buyer, including any account on the Platform not owned by you, to its source, or exploit the Platform or any service or information made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by the Platform.
                    <br /><br />
                    You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about us or the brand name or domain name used by us including the name '3Dcafe', or otherwise engage in any conduct or action that might tarnish the image or reputation, of 3Dcafe or {shortCode} on platform or otherwise tarnish or dilute any 3Dcafe's trade or service marks, trade name and/or goodwill associated with such trade or service marks, as may be owned or used by us. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Platform or 3Dcafe's systems or networks, or any systems or networks connected to 3Dcafe.
                    <br /><br />
                    You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Platform or any transaction being conducted on the Platform, or with any other person's use of the Platform.
                    <br /><br />
                    You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to us on or through the Platform or any service offered on or through the Platform. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity.
                    <br /><br />
                    You may not use the Platform or any content on the Platform for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity that infringes the rights of 3Dcafe and/or others.
                    <br /><br />
                    You shall at all times ensure full compliance with the applicable provisions, as amended from time to time, of (a) the Information Technology Act, 2000 and the rules thereunder; (b) all applicable domestic laws, rules and regulations (including the provisions of any applicable exchange control laws or regulations in force); and (c) international laws, foreign exchange laws, statutes, ordinances and regulations (including, but not limited to sales tax/VAT, income tax, octroi, service tax, central excise, custom duty, local levies) regarding your use of our service and your listing, purchase, solicitation of offers to purchase, and sale of products or services. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.
                    <br /><br />
                    In order to allow us to use the information supplied by you, without violating your rights or any laws, you agree to grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights you have in your Information, in any media now known or not currently known, with respect to your Information. We will only use your information in accordance with these Terms of Use and Privacy Policy applicable to use of the Platform.
                    <br /><br />
                    From time to time, you shall be responsible for providing information relating to the products or services proposed to be sold by you. In this connection, you undertake that all such information shall be accurate in all respects. You shall not exaggerate or overemphasize the attributes of such products or services so as to mislead other users in any manner.
                    <br /><br />
                    You shall not engage in advertising to, or solicitation of, other users of the Platform to buy or sell any products or services, including, but not limited to, products or services related to that being displayed on the Platform or related to us. You may not transmit any chain letters or unsolicited commercial or junk email to other users via the Platform. It shall be a violation of these Terms of Use to use any information obtained from the Platform in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than us without our prior explicit consent. In order to protect our users from such advertising or solicitation, we reserve the right to restrict the number of messages or emails which a user may send to other users in any 24-hour period which we deem appropriate in its sole discretion. You understand that we have the right at all times to disclose any information (including the identity of the persons providing information or materials on the Platform) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena. In addition, We can (and you hereby expressly authorize us to) disclose any information about you to law enforcement or other government officials, as we, in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.
                    <br /><br />
                    We reserve the right, but has no obligation, to monitor the materials posted on the Platform. 3Dcafe shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE PLATFORM AND IN YOUR PRIVATE MESSAGES. Please be advised that such Content posted does not necessarily reflect 3Dcafe views. In no event shall 3Dcafe assume or have any responsibility or liability for any Content posted or for any claims, damages or losses resulting from use of Content and/or appearance of Content on the Platform. You hereby represent and warrant that you have all necessary rights in and to all Content which you provide and all information it contains and that such Content shall not infringe any proprietary or other rights of third parties or contain any libellous, tortious, or otherwise unlawful information.
                    <br /><br />
                    Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Platform, including payment and delivery of related products or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Platform.
                    <br /><br />
                    It is possible that other users (including unauthorized users or 'hackers') may post or transmit offensive or obscene materials on the Platform and that you may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about you due to your use of the Platform, and that the recipient may use such information to harass or injure you. We do not approve of such unauthorized uses, but by using the Platform You acknowledge and agree that we are not responsible for the use of any personal information that you publicly disclose or share with others on the Platform. Please carefully select the type of information that you publicly disclose or share with others on the Platform.
                    <br /><br />
                    3Dcafe shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services), hacking, pen testing attempts without our prior consent or a mutual legal agreement.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Account Registration
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    You may access the Platform by registering to create an account with your plant HR (" 3Dcafe Account") and become a member (" Membership"); The Membership is limited for the purpose and are subject to the terms, and strictly not transferable.
                    <br /><br />
                    We will create your 3Dcafe Account for your use of the Platform services based upon the personal information you provide to us, as described above. You can only have one 3Dcafe Account and are not permitted to create multiple accounts. 3Dcafe reserves the right to suspend such multiple accounts without being liable for any compensation where you have created multiple accounts on the Platform.
                    <br /><br />
                    You agree to provide accurate, current and complete information during the registration process and update such information to keep it accurate, current and complete.
                    <br /><br />
                    We reserve the right to suspend or terminate your 3Dcafe Account and your access to the Services (i) if any information provided during the registration process or thereafter proves to be inaccurate, not current or incomplete; (ii) if it is believed that your actions may cause legal liability for you, other users or us; and/or (iii) if you are found to be non-compliant with the Terms of Use or other 3Dcafe policies.
                    <br /><br />
                    You are responsible for safeguarding your password. You agree that you will not disclose your password to any third party and that you will take sole responsibility for any activities or actions under your 3Dcafe Account, whether or not you have authorized such activities or actions. You will immediately notify us of any unauthorized use of your 3Dcafe Account.
                    <br /><br />
                    Goods purchased from the Platform are intended for your personal use and you represent that the same are not for resale or you are not acting as an agent for other parties.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Order Booking and Financial Terms
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    The Platform allows the Buyers to place Orders and upon acceptance of such Orders by the {shortCode}, 3Dcafe will, subject to the terms and conditions set out herein, facilitates delivery of goods or services, or completion of Tasks through PDP.
                    <br /><br />
                    3Dcafe does not own, sell, resell on its own such products offered by the {shortCode}, and/or does not control the {shortCode} or the related services provided in connection thereof. Buyer understands that any Order that he/she places shall be subject to the terms and conditions set out in these Terms of Use including, but not limited to, product availability, delivery, and acceptance of Orders by {shortCode}.
                    <br /><br />
                    As a general rule, all Orders placed on the Platform and Delivery Services are treated as confirmed.
                    <br /><br />
                    However, upon Buyer’s successful completion of booking an Order, 3DCafe will share the booking information to {shortCode} to register your bookings.
                    <br /><br />
                    In addition to the foregoing, 3DCafe is defined the cut-off time for ordering and cancelling the food order. Buyer needs to book/cancel their order prior to 15 Hours which is 5:00 PM every day. Post the cut-off time the book/cancel option will be disabled to buyers. If the food order is not taken at any circumstances the status of food will be marked delivered and the buyer have no rights to refund the amount paid for the order.
                    <br /><br />
                    All payments made against the Orders or Services on the Platform by you shall be compulsorily in Indian Rupees acceptable in the Republic of India. The Platform will not facilitate transactions with respect to any other form of currency with respect to the Orders or Services made on Platform. You can pay by (i) credit card or debit card or net banking; (ii) any other RBI approved payment method at the time of booking an Order; or (iii) credit or debit card or cash at the time of delivery. You understand, accept and agree that the payment facility provided by 3Dcafe is neither a banking nor financial service but is merely a facilitator providing an electronic, automated online electronic payment, receiving payment on delivery, collection and remittance facility for the transactions on the Platform using the existing authorized banking infrastructure and credit card payment gateway networks. Further, by providing payment facility, 3Dcafe is neither acting as trustees nor acting in a fiduciary capacity with respect to the transaction or the transaction price.
                    <br /><br />
                    Buyers acknowledge and agree that 3Dcafe will not acts as the {shortCode}'s payment agent for the limited purpose of accepting payments from Buyers/{shortCode} on behalf of the {shortCode}.
                    <br /><br />
                    Buyer agrees to pay for the total amount for the Order placed on the Platform. 3Dcafe will not collect the amount in accordance with these Terms of Use and the pricing terms set forth in the applicable listing of product for the particular {shortCode}. Please note that we cannot control any amount that may be charged to Buyer by his/her bank related to our collection of the total amount, and we disclaim all liability in this regard.
                    <br /><br />
                    Buyer agrees to pay for the Order placed by you on the Platform, in accordance with these Terms, using the methods described under clause above.If Buyer is directed to our third-party payment processor, he/she may be subject to terms and conditions governing use of that third party's service and that third party's personal information collection practices. Please review such terms and conditions and privacy policy before using the Platform services. Once the Order is confirmed you will receive a confirmation email summarizing the confirmed booking.
                    <br /><br />
                    The final tax bill will be issued by the {shortCode} (if registered for tax purposes) to the Buyer along with the Order and 3Dcafe is merely register the payment on behalf of such {shortCode}. All applicable taxes and levies, the rates thereof and the manner of applicability of such taxes on the bill are being charged and determined by the {shortCode}. 3Dcafe holds no responsibility for the legal correctness/validity of the levy of such taxes. The sole responsibility for any legal issue arising on the taxes shall reside with the {shortCode}.
                    <br /><br />
                    The prices reflected on the Platform are determined solely by the {shortCode} and are listed based on {shortCode}'s information..
                    <br /><br />
                    The {shortCode} shall be solely responsible for any warranty/guarantee of the goods sold to the Buyers and in no event shall be the responsibility of 3Dcafe.
                    <br /><br />
                    The transactions are bilateral between the {shortCode} and Buyer, therefore, 3Dcafe is not liable to charge or deposit any taxes applicable on such transactions.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Cancellations and Refunds
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t18_600'>
                    Cancellation Policy:
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    Buyer can cancel the order for the next day prior to the cut-off time (5:00 PM) of every day agreed by user/buyer and {shortCode}. After 5:00 PM Cancel/Booking option will be disabled to the buyer.
                    <br /><br />
                    Buyer can choose partial cancellation of the orders placed in 3DCafe.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Refund Policy:
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    Refund of bulk or partial orders will be initiated to the user/buyer at the time of cancellation.
                    <br /><br />
                    Once your return is received and inspected, the banker/payment gateway service provider will send you an email to notify you that we have received your returned item. The banker/payment gateway service provider will also notify you of the approval or rejection of your refund.
                    <br /><br />
                    If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.
                    <br /><br />
                    If you haven’t received a refund yet, first check your bank account again.
                    <br /><br />
                    Then contact your credit card company, it may take some time before your refund is officially posted.
                    <br /><br />
                    Next contact your bank. There is often some processing time before a refund is posted.
                    <br /><br />
                    If you’ve done all of this and you still have not received your refund yet, please contact us at [Catmaa@{shortCode.toLowerCase()}.in].
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Privacy Policy
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t18_600'>
                    What do we do with your information?
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    When you are ordering something from 3DCafe, as part of the buying and selling process, we will collect the personal information you give us such as your name and email address.
                    <br /><br />
                    Email marketing (Not Allowed): 3DCafe will not allowed to third parties to post their ads, updates to the website. We may send you emails about our bookings/cancellations, deliveries.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    How do you get my consent?
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only. If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    How do I withdraw my consent?
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting to the payment gateway service provider or mailing us at: [Catmaa@{shortCode.toLowerCase()}.in]
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Terms of service
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    The Buyer agrees and acknowledges that 3Dcafe shall not be responsible for:
                    <br /><br />
                    The services or goods provided by the {shortCode} including but not limited to serving of food Orders suiting your requirements and taste;
                    <br /><br />
                    The {shortCode}'s services or goods are not being up to Buyer expectations or leading to any loss, harm or damage to him/her;
                    <br /><br />
                    The availability or unavailability of certain items on the menu;
                    <br /><br />
                    The {shortCode} serving the incorrect Orders; or
                    <br /><br />
                    Product liability of goods provided by {shortCode}.
                    <br /><br />
                    The details of the menu and price list available on the Platform with respect to goods or any other services are based on the information provided by the {shortCode} and 3Dcafe shall not be responsible for any change or cancellation or unavailability.
                    <br /><br />
                    Buyers and {shortCode} agree and acknowledge that 3Dcafe is not responsible for any liability arising out of delivery services provided by PDP to them.
                    <br /><br />
                    Buyers may not be able to avail Services if their delivery location is different 3Dcafe’s current scope of Service. 3Dcafe will keep the Buyer informed of the same at the time of confirming his/her Order booking.
                    <br /><br />
                    The Buyer understands that 3Dcafe’s (including {shortCode.toLowerCase()}’s) liability ends once Order has been delivered to him/her, except where the product liability of the {shortCode} subsists.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Services provided:
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    You agree and acknowledge that 3Dcafe shall be liable in the event you have failed to adhere to the Terms of Use.
                    <br /><br />
                    Buyer shall be required to provide credit or debit card details to the approved payment gateways while making the payment on the Platform. In this regard, Buyer agrees to provide correct and accurate credit/ debit card details to the approved payment gateways for availing the Services. Buyer shall not use the credit/ debit card which is not lawfully owned by Buyer, i.e. in any transaction, Buyer must use his/her own credit/ debit card. The information provided by the Buyer will not be utilized or shared with any third party unless required in relation to fraud verifications or by law, regulation or court order. Buyer shall be solely responsible for the security and confidentiality of his/her credit/ debit card details. We expressly disclaim all liabilities that may arise as a consequence of any unauthorized use of your credit/ debit card.
                    <br /><br />
                    3Dcafe does not offer any refunds against goods already purchased from a {shortCode}. Buyer should reach to contact {shortCode} with transaction and booking reference if an error that is directly attributable to 3Dcafe has occurred during the purchase of such product or services.
                    <br /><br />
                    We constantly strive to provide you with accurate information on the Platform. However, in the event of an error, we may, in our sole discretion, contact you with further instructions.
                    <br /><br />
                    If you use the Platform, you do the same at your own risk.
                    <br /><br />
                    Buyer agrees that the Services shall be provided through the Platform only during the working hours of the relevant {shortCode}.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    No Endorsement
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    We do not endorse any {shortCode}. In addition, although these Terms of Use require you to provide accurate information, we do not attempt to confirm, and do not confirm if it is purported identity. We will not be responsible for any damage or harm resulting from your interactions with other Members.
                    <br /><br />
                    By using the Services, you agree that any legal remedy or liability that you seek to obtain for actions or omissions of other Members or other third parties will be limited to a claim against the particular Members or other third parties who caused you harm and you agree not to attempt to impose liability on, or seek any legal remedy from us with respect to such actions or omissions.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    General:
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to use the Platform. Only individuals who are 18 years of age or older may use the Platform and avail Services. If you are under 18 years of age and you wish to download, install, access or use the Platform, your parents or legal guardian must acknowledge and agree to the Terms of Use and Privacy Policy. Should your parents or legal guardian fail to agree or acknowledge the Terms of Use and 3Dcafe policies, you shall immediately discontinue its use. 3Dcafe reserves the right to terminate your Membership and / or deny access to the platform if it is brought to 3Dcafe's notice that you are under the age of 18 years.
                    <br /><br />
                    If you choose to use the Platform, it shall be your responsibility to treat your user identification code, password and any other piece of information that we may provide, as part of our security procedures, as confidential and not disclose the same to any person or entity other than us. We shall at times and at our sole discretion reserve the right to disable any user identification code or password if you have failed to comply with any of the provisions of these Terms of Use.
                    <br /><br />
                    You shall at all times be responsible for the use of the Services through your computer or mobile device and for bringing these Terms of Use and 3Dcafe policies to the attention of all such persons accessing the Platform on your computer or mobile device.
                    <br /><br />
                    You understand and agree that the use of the Services does not include the provision of a computer or mobile device or other necessary equipment to access it. You also understand and acknowledge that the use of the Platform requires internet connectivity and telecommunication links. You shall bear the costs incurred to access and use the Platform and avail the Services, and we shall not, under any circumstances whatsoever, be responsible or liable for such costs.
                    <br /><br />
                    By using the Platform you represent and warrant that:
                    <br /><br />
                    All registration information you submit is truthful, lawful and accurate and that you agree to maintain the accuracy of such information.
                    <br /><br />
                    Your use of the Platform shall be solely for your personal use and you shall not authorize others to use your account, including your profile or email address and that you are solely responsible for all content published or displayed through your account, including any email messages, and your interactions with other users and you shall abide by all applicable local, state, national and foreign laws, treaties and regulations, including those related to data privacy, international communications and the transmission of technical or personal data.
                    <br /><br />
                    You will not submit, post, upload, distribute, or otherwise make available or transmit any content that: (a) is defamatory, abusive, harassing, insulting, threatening, or that could be deemed to be stalking or constitute an invasion of a right of privacy of another person; (b) is bigoted, hateful, or racially or otherwise offensive; (c) is violent, vulgar, obscene, pornographic or otherwise sexually explicit; (d) is illegal or encourages or advocates illegal activity or the discussion of illegal activities with the intent to commit them.
                    <br /><br />
                    All necessary licenses, consents, permissions and rights are owned by you and there is no need for any payment or permission or authorization required from any other party or entity to use, distribute or otherwise exploit in all manners permitted by these Terms of Use and Privacy Policy, all trademarks, copyrights, patents, trade secrets, privacy and publicity rights and / or other proprietary rights contained in any content that you submit, post, upload, distribute or otherwise transmit or make available.
                    <br /><br />
                    You will not (a) use any services provided by the Platform for commercial purposes of any kind, or (b) advertise or sell any products, services or otherwise (whether or not for profit), or solicit others (including, without limitation, solicitations for contributions or donations) or use any public forum for commercial purposes of any kind. In the event you want to advertise your product or service contact catmaa@{shortCode.toLowerCase()}.in.
                    <br /><br />
                    You will not use the Platform in any way that is unlawful, or harms us or any other person or entity, as determined in our sole discretion.
                    <br /><br />
                    You will not post, submit, upload, distribute, or otherwise transmit or make available any software or other computer files that contain a virus or other harmful component, or otherwise impair or damage the Platform or any connected network, or otherwise interfere with any person or entity's use or enjoyment of the Platform.
                    <br /><br />
                    You will not use another person's username, password or other account information, or another person's name, likeness, voice, image or photograph or impersonate any person or entity or misrepresent your identity or affiliation with any person or entity.
                    <br /><br />
                    You will not engage in any form of antisocial, disrupting, or destructive acts, including "flaming," "spamming," "flooding," "trolling," and "griefing" as those terms are commonly understood and used on the Internet.
                    <br /><br />
                    You will not delete or modify any content of the Platform, including but not limited to, legal notices, disclaimers or proprietary notices such as copyright or trademark symbols, logos, that you do not own or have express permission to modify.
                    <br /><br />
                    You will not post or contribute any information or data that may be obscene, indecent, pornographic, vulgar, profane, racist, sexist, discriminatory, offensive, derogatory, harmful, harassing, threatening, embarrassing, malicious, abusive, hateful, menacing, defamatory, untrue or political or contrary to our interest.
                    <br /><br />
                    You shall not access the Platform without authority or use the Platform in a manner that damages, interferes or disrupts:
                    <br /><br />
                    any part of the Platform or the Platform software; or
                    <br /><br />
                    any equipment or any network on which the Platform is stored or any equipment of any third party
                    <br /><br />
                    You release and fully indemnify 3Dcafe and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the Platform and specifically waive any claims that you may have in this behalf under any applicable laws of India. Notwithstanding its reasonable efforts in that behalf, 3Dcafe cannot take responsibility or control the information provided by other Users which is made available on the Platform. You may find other User's information to be offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution and practice safe trading when using the Platform.
                    <br /><br />
                    Access to the Platform, Accuracy and security
                    <br /><br />
                    We endeavour to make the Services available during {shortCode} or PDP working hours. However, we do not represent that access to the Platform will be uninterrupted, timely, error free, free of viruses or other harmful components or that such defects will be corrected.
                    <br /><br />
                    We do not warrant that the Platform will be compatible with all hardware and software which you may use. We shall not be liable for damage to, or viruses or other code that may affect, any equipment (including but not limited to your mobile device), software, data or other property as a result of your download, installation, access to or use of the Platform or your obtaining any material from, or as a result of using, the Platform. We shall also not be liable for the actions of third parties.
                    <br /><br />
                    We do not represent or warranty that the information available on the Platform will be correct, accurate or otherwise reliable.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Disclaimers
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    The platform may be under constant upgrades, and some functions and features may not be fully operational.
                    <br /><br />
                    Due to the vagaries that can occur in the electronic distribution of information and due to the limitations inherent in providing information obtained from multiple sources, there may be delays, omissions, or inaccuracies in the content provided on the platform or delay or errors in functionality of the platform. As a result, we do not represent that the information posted is correct in every case.
                    <br /><br />
                    We expressly disclaim all liabilities that may arise as a consequence of any unauthorized use of credit/ debit cards.
                    <br /><br />
                    You acknowledge that third party services are available on the platform. We may have formed partnerships or alliances with some of these third parties from time to time in order to facilitate the provision of certain services to you. However, you acknowledge and agree that at no time are we making any representation or warranty regarding any third party's services nor will we be liable to you or any third party for any consequences or claims arising from or in connection with such third party including, and not limited to, any liability or responsibility for, death, injury or impairment experienced by you or any third party. You hereby disclaim and waive any rights and claims you may have against us with respect to third party's / {shortCode.toLowerCase()}’s/ pdp’s services.
                    <br /><br />
                    3dcafe disclaims and all liability that may arise due to any violation of any applicable laws including the law applicable to products and services offered by the {shortCode.toLowerCase()} or pdp.
                    <br /><br />
                    While the materials provided on the platform were prepared to provide accurate information regarding the subject discussed, the information contained in these materials is being made available with the understanding that we make no guarantees, representations or warranties whatsoever, whether expressed or implied, with respect to professional qualifications, expertise, quality of work or other information herein. Further, we do not, in any way, endorse any service offered or described herein. In no event shall we be liable to you or any third party for any decision made or action taken in reliance on such information.
                    <br /><br />
                    The information provided hereunder is provided "as is". We and / or our employees make no warranty or representation regarding the timeliness, content, sequence, accuracy, effectiveness or completeness of any information or data furnished hereunder or that the information or data provided hereunder may be relied upon. Multiple responses may usually be made available from different sources and it is left to the judgement of users based on their specific circumstances to use, adapt, modify or alter suggestions or use them in conjunction with any other sources they may have, thereby absolving us as well as our consultants, business associates, affiliates, business partners and employees from any kind of professional liability.
                    <br /><br />
                    We shall not be liable to you or anyone else for any losses or injury arising out of or relating to the information provided on the platform. In no event will we or our employees, affiliates, authors or agents be liable to you or any third party for any decision made or action taken by your reliance on the content contained herein.
                    <br /><br />
                    In no event will we be liable for any damages (including, without limitation, direct, indirect, incidental, special, consequential or exemplary damages, damages arising from personal injury/wrongful death, and damages resulting from lost profits, lost data or business interruption), resulting from any services provided by any third party or {shortCode.toLowerCase()} accessed through the platform, whether based on warranty, contract, tort, or any other legal theory and whether or not we are advised of the possibility of such damages.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Intellectual property
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    We are either the owner of intellectual property rights or have the non-exclusive, worldwide, perpetual, irrevocable, royalty free, sub-licensable (through multiple tiers) right to exercise the intellectual property, in the Platform, and in the material published on it including but not limited to user interface, layout format, Order placing process flow and any content thereof.
                    <br /><br />
                    You recognize that 3Dcafe is the registered owner of the word mark ‘3Dcafe’ and the logo including but not limited to its variants (IPR) and shall not directly or indirectly, attack or assist another in attacking the validity of, or 3Dcafe’s or its affiliates proprietary rights in, the licensed marks or any registrations thereof, or file any applications for the registration of the licensed marks or any names or logos derived from or confusingly similar to the licensed marks, any variation thereof, or any translation or transliteration thereof in another language, in respect of any products/services and in any territory throughout the world.
                    <br /><br />
                    You may print off one copy, and may download extracts, of any page(s) from the Platform for your personal reference and you may draw the attention of others within your organisation to material available on the Platform.
                    <br /><br />
                    You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
                    <br /><br />
                    You must not use any part of the materials on the Platform for commercial purposes without obtaining a licence to do so from us or our licensors.
                    <br /><br />
                    If you print off, copy or download any part of the Platform in breach of these Terms of Use, your right to use the Platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
                    <br /><br />
                    Treatment of information provided by you
                    <br /><br />
                    We process information provided by you to us in accordance with our Privacy Policy.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Third Party Content
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    We cannot and will not assure that other users are or will be complying with the foregoing rules or any other provisions of these Terms of Use, and, as between you and us, you hereby assume all risk of harm or injury resulting from any such lack of compliance.
                    <br /><br />
                    You acknowledge that when you access a link that leaves the Platform, the site you will enter into is not controlled by us and different terms of use and Privacy Policy may apply. By assessing links to other sites, you acknowledge that we are not responsible for those sites. We reserve the right to disable links to and / or from third-party sites to the Platform, although we are under no obligation to do so.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Severability
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    If any of these Terms of Use should be determined to be illegal, invalid or otherwise unenforceable by reason of the laws of any state or country in which these Terms of Use are intended to be effective, then to the extent and within the jurisdiction where that term is illegal, invalid or unenforceable, it shall be severed and deleted and the remaining Terms of Use shall survive, remain in full force and effect and continue to be binding and enforceable.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Non-assignment
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    You shall not assign or transfer or purport to assign or transfer the contract between you and us to any other person.
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t18_600'>
                    Governing law and dispute resolution
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant='t14_400'>
                    These Terms of Use are governed by the laws of India. Any action, suit, or other legal proceeding, which is commenced to resolve any matter arising under or relating to this Platform, shall be subject to the jurisdiction of the courts at Bangalore, India.
                  </Typography>
                </CardContent>

                <CardContent>
                  <Typography variant='t18_600'>
                    Shipping Policy
                  </Typography>
                </CardContent>
                <CardContent>
                <Typography variant='t14_400'>
                  No shipping policy applies on deliveries.
                  </Typography>
                </CardContent>
              </>
          }

        </Card>
      </Box>
    </>
  )
}

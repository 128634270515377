import React, { useState, useRef, useEffect } from "react";
import { Typography, Card, Grid, Button, Stack } from "@mui/material";
import CssTextField from "../../../components/overrides/longSearchField";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import CloseButton from "../../../components/overrides/closeButton";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getTime } from "../../../utils/common";
import { ScheduleNotificationWatcher } from "../../../redux/actionCreators/admin/employeeManagement";

const Notify = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFromChange = (e) => {
    // let convertedTime = getTime(e);
    // console.log("time from------------------", convertedTime);
    setFieldValue("FROMTIME", e);
  };

  const handleToChange = (e) => {
    // let convertedTime = getTime(e);
    // console.log("time to------------------", convertedTime);
    setFieldValue("TOTIME", e);
  };

  const Schema = Yup.object().shape({
    title: Yup.string("Enter your Title").required("Title is required"),
    body: Yup.string("Enter your Body").required("Body is required"),
    FROMTIME: Yup.date("Select From Time").required("Select From Time"),
    TOTIME: Yup.date("Select From Date")
      .required("Select To Time")
      .test("Invalid Time", "Invalid Time", (data) => {
        if (formik.values.FROMTIME > data) {
          return false;
        }
        return true;
      }),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      body: "",
      FROMTIME: null,
      TOTIME: null,
    },
    validationSchema: Schema,

    onSubmit: () => {
      let { title, body, FROMTIME, TOTIME } = { ...formik.values };
      let data = {
        "NTITLE":title,
        "NDESCRIPTION":body,
        "STARTTIME":getTime(FROMTIME),
        "ENDTIME":getTime(TOTIME)
      }
      // console.log("ss", data);
        dispatch(ScheduleNotificationWatcher(data));
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  return (
    <>
      <Card
        style={{
          boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`,
        }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={3}
              display="flex"
              flexDirection="row"
              justifyContent="left"
              sx={{ padding: "30px" }}>
              <Grid item lg={6} xs={12} sm={6}>
                <Typography variant="t14_600"> Title </Typography>
                <CssTextField
                  sx={{
                    mt: "7px",
                  }}
                  placeholder="Title"
                  name="title"
                  fullWidth
                  value={values.title || ""}
                  {...getFieldProps("title", values.title)}
                />
              </Grid>
              <Grid item lg={6} xs={12} sm={6}>
                <Typography variant="t14_600"> Body </Typography>
                <CssTextField
                  sx={{
                    mt: "7px",
                  }}
                  placeholder="Body"
                  name="body"
                  fullWidth
                  value={values.body || ""}
                  {...getFieldProps("body", values.body)}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Box pb="20px">
                  <Typography variant="t14_600"> Select Time </Typography>
                </Box>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="From Time"
                      name="FROMTIME"
                      value={values.FROMTIME}
                      onChange={(e) => handleFromChange(e)}
                      renderInput={(params) => (
                        <CssTextField {...params} fullWidth />
                      )}
                    />
                    {/* <DatePicker
                      value={values.FROMTIME}
                      label="From Date"
                      name="FROMTIME"
                      onChange={handleFromChange}
                      renderInput={(params) => (
                        <CssTextField
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: 50,
                            },
                            "& label": {
                              top: -2,
                            },
                          }}
                          fullWidth
                          {...params}
                          error={Boolean(touched.FROMTIME && errors.FROMTIME)}
                          helperText={touched.FROMTIME && errors.FROMTIME}
                        />
                      )}
                    /> */}
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="To Time"
                      name="TOTIME"
                      value={values.TOTIME}
                      onChange={(e) => handleToChange(e)}
                      renderInput={(params) => (
                        <CssTextField {...params} fullWidth />
                      )}
                    />
                    {/* <DatePicker
                      value={values.TOTIME}
                      label="To Date"
                      name="TOTIME"
                      onChange={handleToChange}
                      renderInput={(params) => (
                        <CssTextField
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: 50,
                            },
                            "& label": {
                              top: -2,
                            },
                          }}
                          fullWidth
                          {...params}
                          error={Boolean(touched.TOTIME && errors.TOTIME)}
                          helperText={touched.TOTIME && errors.TOTIME}
                        />
                      )}
                    /> */}
                  </LocalizationProvider>
                </Stack>
              </Grid>
            </Grid>

            <Grid container sx={{ padding: "30px" }} spacing={3}>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Box display="flex">
                  <Box ml="auto">
                    <CloseButton
                      sx={{
                        width: "120px",
                        height: "42px",
                        mr: "20px",
                      }}
                      variant="contained"
                      //   onClick={handleBack}
                    >
                      <Typography variant="t14_400" color="common.black">
                        Cancel
                      </Typography>
                    </CloseButton>
                    <Button
                      sx={{
                        width: "120px",
                        height: "42px",
                        left: "0px",
                        top: "0px",
                        backgroundColor: "common.Bblack",
                        "&:hover": {
                          backgroundColor: "common.Bblack",
                        },
                      }}
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}>
                      <Typography variant="t14_400" color="inherit">
                        Update
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Card>
    </>
  );
};

export default Notify;

import { EMPLOYEE_LIST, ROLE_NAME_LIST, ACCESS_POINT_LIST, DIVISION_LIST, DEPARTMENT_LIST, TRIP_TYPE_LIST, TYPE_OF_CAB, CONTRACTOR, CAFETERIA_LIST, VENDOR_NAME_LIST, COUNTER_LIST, GUEST_DROP_LIST, GET_LIST_BY_ID_LIST, CATEGORY_NAME_LIST, DEPARTMENT_NAME_LIST, GET_DIVISION_LIST, SCHEDULE_NOTIFICATION_LIST } from "../../actionTypes/admin";

const intialState = {
  employeeList: [],
  roleNameList: [],
  divisionList: [],
  accessPointList: [],
  departmentList: [],
  tripTypeList: [],
  typeOfCab: [],
  contractor: [],
  vendorName: [],
  cafeteriaName: [],
  counter: [],
  guestDrop: [],
  listById: [],
  categoryList: [],
  departmentNameList: [],
  divisionMaster: [],
  ScheduleNotification:[]
};

export default function EmployeeReducers(state = intialState, action) {
  switch (action.type) {

    case EMPLOYEE_LIST:
      return { ...state, employeeList: action.payload };

    case ROLE_NAME_LIST:
      return { ...state, roleNameList: action.payload };

    case DIVISION_LIST:
      return { ...state, divisionList: action.payload };

    case ACCESS_POINT_LIST:
      return { ...state, accessPointList: action.payload };

    case DEPARTMENT_LIST:
      return { ...state, departmentList: action.payload };

    case TRIP_TYPE_LIST:
      return { ...state, tripTypeList: action.payload };

    case TYPE_OF_CAB:
      return { ...state, typeOfCab: action.payload };

    case CONTRACTOR:
      return { ...state, contractor: action.payload };

    case CAFETERIA_LIST:
      return { ...state, cafeteriaName: action.payload };

    case VENDOR_NAME_LIST:
      return { ...state, vendorName: action.payload };

    case COUNTER_LIST:
      return { ...state, counter: action.payload };

    case GUEST_DROP_LIST:
      return { ...state, guestDrop: action.payload };

    case GET_LIST_BY_ID_LIST:
      return { ...state, listById: action.payload };

    case DEPARTMENT_NAME_LIST:
      return { ...state, departmentNameList: action.payload };

    case CATEGORY_NAME_LIST:
      return { ...state, categoryList: action.payload };

    case GET_DIVISION_LIST:
      return { ...state, divisionMaster: action.payload };

    case SCHEDULE_NOTIFICATION_LIST:
      return { ...state, ScheduleNotification: action.payload };

    default:
      return state;
  }
}

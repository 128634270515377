import { Grid, Typography, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import RecentDelivery from "../../../components/user/recentDelivery";

const CurrentOrder = () => {
  const {
    CurrentOrderReducers: { deliveredOrder },
  } = useSelector((state) => state);

  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(2);
  let firstIndex = 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Box display="flex" mt="35px" flexDirection="column">
        <Typography mb="25px" variant="t24_600">
          Recent Delivery
        </Typography>

        {!deliveredOrder.length ? (
          <Typography textAlign="center" mb="25px" variant="t20_400">
            No Recent Delivery
          </Typography>
        ) : (
          <Grid container spacing={2} >
            {deliveredOrder.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page).map((d) => (
              <Grid item key={d.nID} xs={12} sm={6} >
                <RecentDelivery value={d} />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      {deliveredOrder.length > 2 &&
        <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
          <Pagination
            size="large"
            shape="rounded"
            color='primary'
            count={Math.ceil(deliveredOrder?.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
          />
        </Box>
      }
    </>
  );
};

export default CurrentOrder;

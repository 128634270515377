// component
import { HomeOutlined } from '@mui/icons-material'
// ----------------------------------------------------------------------

export const Swipe = [
  {
    title: 'Dashboard',
    path: '/swipe',
    icon: <HomeOutlined color='inherit' />
  }
];


import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {WeekDays} from '../../../utils/constants'
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { addCartWatcher, addMinusWatcher, addQuantityWatcher } from "../../../redux/actionCreators/user/home";
import { useDispatch } from "react-redux";
import { getConvertedDate2 } from '../../../utils/common';
import { base_url } from "../../../http-common";
import QuantityPopover from "./addQuantityPopup";

const WeekFoodCard = ({week, mID}) => {

  const getDate = week.Dates?.slice(0,10)
  const [page, setPage] = useState(1)
  const [openPopup, setOpenPopup] = useState(null);
  const [newQty, setNewQty] = useState('');
  const [qtyError, setQtyError] = useState('');
  const tempinitsform = useRef();
  const dispatch = useDispatch()

  const {UserHomeReducers: {mealMenuList, cartList, cafeteriaId}, ProfileReducers: {loginState}} = useSelector(state => state)
  
  const handleNext = ()=>{
    const limit = Math.ceil(mealMenuList.length/4)
    if(page < limit){
      setPage(page + 1)
    }
  }

  const handlePrev = ()=>{
    const limit = 1
    if(page > limit){
      setPage(page - 1)
    }
  }

  const initsform = () => {
    setPage(1)
  };

  tempinitsform.current = initsform;

  useEffect(() => {
    tempinitsform.current();
  }, [mealMenuList]);


  // useEffect(()=>{
  //   setPage(1)
  // },[mealMenuList])

  const nUserID = loginState[0]?.nID || 0

  const addToCart = (nID) => {
    const data = {
      "ACTION": "INSERT",
       nUserID,
      "nMenuID": `${nID}`,
      "dOrderDate": getDate,
      "nCafeteriaId" : 0
    }
    const cartData = {
      "ACTION":"CHECKLIST",
      nUserID
    }
    dispatch(addCartWatcher(data, cartData, nUserID ))
  }

  const addMinusApi = (type, nID) => {
    const data = {
      "ACTION": type
    }
    const cartData = {
      "ACTION":"CHECKLIST",
      nUserID
    }
    dispatch(addMinusWatcher(getCount(nID).NID, data, cartData, nUserID))
  }

  const DateFilter = cartList.filter(d=> getDate === getConvertedDate2(new Date(d.dOrderDate)))

  const getCount = (nID) => DateFilter.find(d=> d.nMenuID === nID) || {}

  const active = () => {
    const list = mealMenuList.map(d=>d.nID)
    return DateFilter.some(d=> list.includes(d.nMenuID))
  }

  const handleOpenPopup = (event) => {
    setOpenPopup(event.currentTarget);
  }

  const handleQuantityChange = (event) => {
    let value = event.target.value
    const re = /^[0-9\b]+$/;
    if (re.test(value)) {
      setQtyError('')
      return setNewQty(value);
    }
    if (!value) {
      setQtyError('Enter Quantity')
      return setNewQty(value);
    }
    else {
      setQtyError('Must be a number')
    }
  }

  const quantityApiChange = (nID) => {
    const data = {
      "ACTION": "addQuantityCart",
      "nQuantity": newQty
    }
    const cartData = {
      "ACTION": "CHECKLIST",
      nUserID
    }
    if (!newQty) {
      return setQtyError('Please Enter Quantity')
    }
    if (qtyError) {
      setQtyError('Must be a number')
    }
    else {
      dispatch(addQuantityWatcher(getCount(nID).NID, data, cartData, nUserID, setOpenPopup, setNewQty));
    }
  };

  // const open = Boolean(openPopup);
  // const id = open ? 'simple-popover' : undefined;

  return (
    <Card sx={{ bgcolor: "grey.0", p: "20px", height:'100%', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex">
        <Box display="flex" flexDirection="column">
          <Typography color={active() ? "common.weekTab" : "common.black"} variant="t18_600">
            {WeekDays[week.ID - 1]}
          </Typography>
          <Typography mt="4px" variant="t12_400">
            {getDate}
          </Typography>
        </Box>
      </Box>

      <Grid container mt="8px" spacing={3}>
        {mealMenuList.slice(0 + 4 * (page - 1), 4 * page).map((d) => (
          <Grid key ={d.nID} item xs={12} md={6}>
            <Box
              component="img"
              src={`${base_url}/${d.cImage}`}
              borderRadius="8px"
              width="100%"
              height={{xs:"155px", md: '104px'}}
              bgColor="grey"
              mb='10px'
              alt={d.cImage}
              sx={{
                objectFit:'cover'
              }}
            ></Box>
            <Typography variant="t14_600">{typeof d.cMenuName === 'object' ? d.cMenuName[0] : d.cMenuName}</Typography>

            <Box display="flex" alignItems="center" width="100%">
              <Typography variant="t14_400" color='grey.600' >Quantity</Typography>

              <Box sx={{ ml: "auto" }} color='common.gButton' >
                {!getCount(d.nID).nQuantity ?
                  <Button onClick={()=>addToCart(d.nID)} sx={{height: 32}} variant="contained">
                    <Typography variant='t14_700'>
                    Add
                    </Typography>
                  </Button>
                  :
                  <>
                  <ButtonGroup
                    variant="contained"
                    color="primary"
                    aria-label="outlined button group"
                    size="small"
                    sx={{
                      "& .MuiButtonGroup-grouped": {
                        minWidth: 25
                      }
                    }}
                  >
                    <Button onClick={()=>addMinusApi("MINUS", d.nID)} sx={{ p: '6px 0 6px 4px' }}>
                      <RemoveIcon fontSize="small" />
                    </Button>
                    <Button sx={{ p: '6px 0' }} onClick={handleOpenPopup}>
                    <Typography variant='t14_700'>
                    {getCount(d.nID).nQuantity}
                    </Typography> 
                    </Button>
                    <Button onClick={()=>addMinusApi("ADD", d.nID)} sx={{ p: '6px 4px 6px 0' }}>
                      <AddIcon fontSize="small" />
                    </Button>
                  </ButtonGroup>

                <QuantityPopover setOpenPopup={setOpenPopup} openPopup={openPopup} quantityApiChange={quantityApiChange} nID={d.nID} setNewQty={setNewQty} newQty={newQty} handleQuantityChange={handleQuantityChange} qtyError={qtyError} setQtyError={setQtyError}/>
                  </>
                }                
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      
      {mealMenuList.length > 4 &&
      <Box mt='auto'>
      <Grid container mt="19px">
        <Grid item xs={6} color='grey.600' >
          <Button onClick={handlePrev} color='inherit' startIcon={<KeyboardArrowLeftIcon />}>
            <Typography variant="t14_600">Prev</Typography>
          </Button>
        </Grid>

        <Grid item xs={6} color='grey.700' textAlign="right">
          <Button onClick={handleNext} color='inherit' endIcon={<ChevronRightIcon />}>
            <Typography variant="t14_600">Next</Typography>
          </Button>
        </Grid>
      </Grid>
      </Box>
      }
      
      
    </Card>
  );
};

export default WeekFoodCard;

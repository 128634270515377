import { Button, Grid, Pagination, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import DishesCard from "../../../components/user/quickDishes";
// import RecentDelivery from "../../../components/user/recentDelivery";
// import Blackbutton from "../../../components/overrides/blackButton";
import { DoubleArrow } from "@mui/icons-material";
import { useState } from "react";
import { useSelector } from "react-redux";
import useResponsive from "../../../hooks/useResponsive";
import { useEffect, useRef } from "react";

const QuickOrder = () => {
  const [next, setNext] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const tempinitsform = useRef();
  let firstIndex = 0;

  const isDesktop = useResponsive("up", "xl");

  const initsform = () => {
    if (isDesktop) {
      setRowsPerPage(8);
    } else {
      setRowsPerPage(6);
    }
  };

  tempinitsform.current = initsform;

  useEffect(() => {
    tempinitsform.current();
  }, [isDesktop]);


  // useEffect(() => {
  //   if (isDesktop) {
  //     setRowsPerPage(8);
  //   } else {
  //     setRowsPerPage(6);
  //   }
  // }, [isDesktop]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const {
    CurrentOrderReducers: { nextdayOrder, nextweekOrder },
  } = useSelector((state) => state);

  // useEffect(()=>{
  //   if(nextdayOrder.length){
  //     setNext(true)
  //   }else{
  //     setNext(false)
  //   }
  // },[nextdayOrder])

  const getNext = next ? nextdayOrder : nextweekOrder;

  return (
    <Box display="flex" mt="35px" flexDirection="column">
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} >
        <Typography mt="auto" variant="t24_600">
          Quick Orders
        </Typography>

        {/* {Boolean(getNext.length) && ( */}
        <Stack ml="auto" mt={{ xs: '20px', md: '0px' }} spacing={2} direction='row'>
          <Button
            sx={{
              width: 150,
              height: 48,
              backgroundColor: !next ? "grey.300" : "primary.main",
              "&:hover": {
                backgroundColor: !next ? "grey.300" : "primary.main",
              },
              color: !next ? "common.black" : "common.white"
            }}
            variant="contained"
            endIcon={<DoubleArrow />}
            onClick={() => setNext(true)}
          >
            <Typography variant="t16_500">Next Day</Typography>
          </Button>

          <Button
            variant="contained"
            sx={{
              width: 150,
              height: 48,
              backgroundColor: next ? "grey.300" : "primary.main",
              "&:hover": {
                backgroundColor: next ? "grey.300" : "primary.main",
              },
              color: next ? "common.black" : "common.white"
            }}
            endIcon={<DoubleArrow />}
            onClick={() => setNext(false)}
          >
            <Typography variant="t16_500">Next 7 Days</Typography>
          </Button>
        </Stack>
        {/* )} */}
      </Box>

      {!getNext.length ? (
        <Typography mb="25px" mt="25px" textAlign="center" variant="t20_400">
          {next ? 'Next day' : 'Next week'} orders not Available
        </Typography>
      ) : (
        <>
          <Grid mt="20px" container spacing={2}>
            {getNext
              .slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
              .map((d) => (
                <Grid item key={d.nID} xs={12} sm={6} md={4} lg={4} xl={3}>
                  <DishesCard value={{ ...d, nTotalRate: d.nToatlRate }} />
                </Grid>
              ))}
          </Grid>
          {getNext.length > rowsPerPage &&
          <Box
            spacing={2}
            display="flex"
            justifyContent="right"
            paddingTop="20px"
          >
            <Pagination
              size='large'
              shape="rounded"
              color="primary"
              count={Math.ceil(getNext?.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
            />
          </Box>
}
        </>
      )}
    </Box>
  );
};

export default QuickOrder;

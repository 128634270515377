import { Remove, Add } from "@mui/icons-material";
import { Button, ButtonGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { addCartWatcher, addMinusWatcher } from "../../../redux/actionCreators/user/home";
import { useDispatch, useSelector } from 'react-redux';
import { getConvertedDate2 } from "../../../utils/common";
import { base_url } from "../../../http-common";

const ImageTemp = ({value, selectedDate, isViewCart, kiosk}) => {

  const { cImage, cMenuName, nTotalRate, nMenuID, dOrderDate } = value

  const {UserHomeReducers: {cartList, cafeteriaId}, ProfileReducers: {loginState}} = useSelector(state => state)

  const dispatch = useDispatch()

  const nUserID = loginState[0]?.nID || 0

  const addToCart = () => {
    const data = {
      "ACTION": "INSERT",
       nUserID,
      "nMenuID": `${nMenuID}`,
       dOrderDate: getConvertedDate2(new Date(dOrderDate)),
      "nCafeteriaId" : 0
    }
    const cartData = {
      "ACTION":"CHECKLIST",
      nUserID
    }
    dispatch(addCartWatcher(data, cartData, nUserID ))
  }

  const addMinusApi = (type) => {
    const data = {
      "ACTION": type
    }
    const cartData = {
      "ACTION":"CHECKLIST",
      nUserID
    }
    dispatch(addMinusWatcher(getCount.NID, data, cartData, nUserID))
  }

  const filterList = cartList.filter(d=> dOrderDate === d.dOrderDate)
  
  const getCount = filterList.find(d=> d.nMenuID === nMenuID) || {}

  return (
    <>
      <img
        style={{
          width: "100%",
          borderRadius: 12,
          height: 195,
          objectFit: "cover",
        }}
        src={`${base_url}/${cImage}`}
        alt={cImage}
      />

      <Box display="flex" mt="18px">
        <Box>
          <Typography mt="16px" variant="t16_500">
            {typeof cMenuName === 'object' ? cMenuName[0] : cMenuName }
          </Typography>
          <br />
          <Typography mt="16px" variant="t24_700">
            ₹{nTotalRate}
          </Typography>
        </Box>
        <Box ml="auto">
          {!getCount.nQuantity ? (
            <Button
              onClick={()=>{addToCart()}}
              sx={{ minWidth: 48, height: 48 }}
              variant="contained"
            >
              <Add />
            </Button>
          ) : (
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
              sx={{
                height: 48,
                "& .MuiButtonGroup-grouped": {
                  minWidth: 38,
                },
              }}
            >
              <Button onClick={()=>{addMinusApi("MINUS")}} sx={{ p: 0 }}>
                <Remove />
              </Button>
              <Button sx={{ p: 0 }}>
                <Typography variant="t24_700">{getCount.nQuantity}</Typography>
              </Button>
              <Button onClick={()=>{addMinusApi("ADD")}} sx={{ p: 0 }}>
                <Add />
              </Button>
            </ButtonGroup>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ImageTemp;

// component
import { HomeOutlined, ShoppingCartOutlined } from '@mui/icons-material'
// ----------------------------------------------------------------------

export const SuperAdmin = [
  {
    title: 'User Creation',
    path: '/superAdmin',
    icon: <HomeOutlined color='inherit' />
  },
  {
    title: 'Division Creation',
    path: '/superAdmin/divisionCreation',
    icon: <ShoppingCartOutlined color='inherit' />
  }

];

import { Button, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useState } from 'react'
import CreateDialog from '../../../dialog'
import CloseButton from '../../../overrides/closeButton'

const CancelDialog = ({ handleCancel, color='grey.300', type='contractor' }) => {

    const [open, setOpen] = useState(false)

    return (
        <>
            <Button
                sx={{
                    width: '120px',
                    height: '42px',
                    left: '0px',
                    top: '0px',
                    // color:
                    '&:hover': {
                        backgroundColor: color,
                      },
                      backgroundColor : color      
                }}
                variant='contained'
                fullWidth
                size='medium'
                type='cancel'
                onClick={() => setOpen(true)}
            >
                <Typography color={type === 'user' ? 'common.white' : 'common.black' } variant='t16_400'>Cancel</Typography>
            </Button>
            <CreateDialog open={open} setOpen={setOpen} maxWidth='sm' >
                <Box display='flex' height='100%' width='100%' flexDirection='column' >
                    <Typography color='common.black' variant='t16_400'>Are you sure you want to cancel the order ?</Typography>
                    <Stack spacing={2} direction='row' mt='20px' justifyContent='end' >
                        <CloseButton
                            style={{
                                width: '100px',
                                height: '35px',
                                left: '0px',
                                top: '0px',
                            }}
                            variant='contained'
                            fullWidth
                            size='medium'
                            type='cancel'
                            onClick={() => setOpen(false)}
                        >
                            <Typography color='common.black' variant='t14_400'>No</Typography>
                        </CloseButton>
                        <Button
                            style={{
                                width: '100px',
                                height: '36px',
                                left: '0px',
                                top: '0px',
                            }}
                            variant='contained'
                            fullWidth
                            size='medium'
                            onClick={() => {
                                handleCancel();
                                setOpen(false);
                            }}
                        >
                            <Typography color='common.white' variant='t14_400'>Yes</Typography>
                        </Button>
                    </Stack>
                </Box>
            </CreateDialog>
        </>

    )
}

export default CancelDialog
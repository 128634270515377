import React, { useEffect, useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import { getGuestSwipeHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import { useSelector, useDispatch } from 'react-redux';
import { TABLE_HEAD } from '../../../components/admin/reports/guestSwipeHistory/table';
import GuestSwipeHistoryTable from '../../../components/admin/reports/guestSwipeHistory/table';
import GuestSwipeHistoryHeaderCard from '../../../components/admin/reports/guestSwipeHistory/headerCard';

const GuestSwipeHistory = () => {

    const { ReportsReducers: { GuestSwipeHistoryList }, UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [selectedMeal, setSelectedMeal] = useState('Breakfast');
    const [fromDate, setFromDate] = React.useState(new Date());
    const [toDate, setToDate] = React.useState(new Date());
    const [page, setPage] = useState(1);
    const [eventVal, setEventVal] = useState('')

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = GuestSwipeHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        const data = {
            MealType: selectedMeal,
            SwipeDate: fromDate,
            SwipeDateTo: toDate,
            NDIVISIONID: cafeteriaId
        }
        dispatch(getGuestSwipeHistoryWatcher(data))
        setPage(1)
    }

    const selectedMealHandler = (val) => {
        setSelectedMeal(val)
    }

    // const selectedMealHandler = useCallback((val) => {
    //     setSelectedMeal(val)
    // }, [selectedMeal])

    useEffect(()=>{
        if(eventVal)
        handleSearch(eventVal);
    },[GuestSwipeHistoryList])

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Guest Swipe History"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Guest Swipe History</Typography>
                </Box>
                <Box p='10px 0'>
                    <GuestSwipeHistoryHeaderCard selectedMealHandler={selectedMealHandler} handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={GuestSwipeHistoryList} columnData={TABLE_HEAD} setFromDate={setFromDate} setToDate={setToDate} toDate={toDate} fromDate={fromDate}/>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <GuestSwipeHistoryTable page={page} setPage={setPage} selectedMeal={selectedMeal} searchVal={searchVal} searchText={searchText} toDate={toDate} fromDate={fromDate} />
                </Card>

            </Box>
        </>
    )
}

export default GuestSwipeHistory;
import React, { useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow,
    Box,
    Autocomplete,
    Button,
    Stack,
    Switch
} from '@mui/material';
import ActionButtons from '../../dashboard/actionButtons';
import { base_url } from '../../../../http-common';
import EditTextField from '../../../overrides/editField';
import { updateMenuMasterWatcher, deleteMenuMasterWatcher } from '../../../../redux/actionCreators/admin/menuMaster';
import { useDispatch, useSelector } from 'react-redux'
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { styled } from '@mui/material/styles';

const Input = styled('input')({
    display: 'none',
});

const MenuTableRow = ({ rowData, divisionId, role }) => {

    const dispatch = useDispatch();
    const { MenuMasterReducers: { mealDropdownList }, EmployeeReducers: { divisionList }, ProfileReducers: {loginState}, } = useSelector((state) => state)
    // const type = mealDropdownList.length && mealDropdownList[2] || []
    const meal = (mealDropdownList.length && mealDropdownList[3]) || []
    const userData = loginState[0] || {}
    let division = divisionList.find(m => m.id === rowData.nDivisionID) || {}

    const [status, setStatus] = useState('list');
    const [editData, setEditData] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [imageName, setImageName] = useState('')
    // const [imageFile, setImageFile] = useState('')
    const [formErrors, setFormErrors] = useState({
        cMenuName: null,
    });
    const [requiredFields] = useState([
        'cMenuName',
    ]);
    const [valid, setValid] = useState(false)

    const handleEditChange = (e) => {
        const { name, value, checked } = e.target
        if(name === 'bIsActive'){
            setEditData({ ...editData, [name]: checked })
        }
        else{
            setEditData({ ...editData, [name]: value })
        }
        validationHandler(name, value)
    }

    const validationHandler = (name, value) => {
        if (!Object.keys(formErrors).includes(name)) return;
        if (
          requiredFields.includes(name) &&
          (value === null ||
            value === 'null' ||
            value === '' ||
            value === false ||
            (Object.keys(value || {}) && value?.value === null))
        ) {
          setFormErrors({
            ...formErrors,
            [name]: capitalize(name) + ' is Required!',
          });
          setValid(true)
        } 
        else {
          setFormErrors({
            ...formErrors,
            [name]: null,
          });
          setValid(false)
        }
    };
    
    const capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const handleEdit = () => {
        let data = { ...rowData }
        data.cMenuName = data.cMenuName[0]
        setEditData(data);
        setImageName(data.cImage);
        setStatus('edit');
    }

    const handleDelete = async () => {
        let data = { "ACTION": "DELETE", SDIVISIONID: divisionId, SROLEID: role }
        await dispatch(deleteMenuMasterWatcher(rowData.nID, { data }))
        setStatus('list');
        setDeleteDialogOpen(false);
    }

    const tickIconFunction = async () => {
        let data = { ...editData, SDIVISIONID: divisionId, SROLEID: role }
        data.ACTION = "MenuUpdate"
        // data.cMenuName = JSON.stringify(editData.cMenuName)
        if(!valid){
            dispatch(updateMenuMasterWatcher(rowData.nID, data));
            setStatus('list');
        }        
    }

    const cancelIconFunction = () => {
        setEditData({});
        setStatus('list');
    }

    let editMealList = meal.filter((a) => a.nMealApplicable === rowData.MenuTypeID) || []
    let editMealName = editMealList.find(m => m.id === rowData.nMealID) || {}

    const onFileChange = (e) => {
        if (e) {
            var file = e.target.files[0];
            var name = e.target.files[0].name;
            setImageName(name);
            // setImageFile(file);
            var reader = new FileReader();
            reader.onloadend = function () {
                // setImageFile(reader.result)
                setEditData({ ...editData, cImage: reader.result })
            }
            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            <TableRow
                hover={status === 'list' ? true : false}
                tabIndex={-1}
            >
                {status === 'list' ?
                    <>
                        <TableCell align="left">
                            <Box sx={{ display: 'flex', alignItems: 'center', pr: '20px' }}>
                                <Box
                                    component="img"
                                    src={`${base_url}/${rowData.cImage}`}
                                    sx={{
                                        height: 50,
                                        minWidth: 50,
                                        width: 50,
                                        borderRadius: "12px",
                                        objectFit: "cover",
                                        mr: "10px",
                                    }}
                                ></Box>
                                <Typography variant='t14_600' >
                                    {rowData.cMealName}
                                </Typography>
                            </Box>

                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cMenuName && rowData.cMenuName[0]}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cVendorName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.ctype}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cMenuDesc}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.nTotalRate}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cDivisionName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.bIsActive === true ? 'Active' : 'Deactive'}
                            </Typography>
                        </TableCell>
                    </>
                    :
                    <>
                        <TableCell align="left" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Stack direction={{ xs: 'column', md: 'column' }} spacing={2} >

                                <label htmlFor="contained-button-file">
                                    <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={onFileChange} />
                                    <Button
                                        variant="contained"
                                        component="span"
                                        fullWidth
                                        size='medium'
                                        // onClick={imageFunction}
                                        style={{ justifyContent: 'space-between' }}
                                        endIcon={<FileUploadOutlinedIcon />}
                                        type='button'
                                        sx={{
                                            backgroundColor: 'common.editfield', color: 'common.black',
                                            "&:hover": {
                                                backgroundColor: "common.editfield"
                                            }, minWidth: '100px', height: '40px'
                                        }}
                                    >
                                        <Typography variant='t11_600'>
                                            {imageName ? imageName : 'Browse Icon'}
                                        </Typography>
                                    </Button >
                                </label>
                                <Autocomplete
                                    id="combo-box-demo"
                                    name="nMealID"
                                    fullWidth
                                    options={editMealList}
                                    size="small"
                                    autoHighlight
                                    defaultValue={editMealName}
                                    // value={roleName.name}
                                    onChange={(event, value) => {
                                        setEditData({ ...editData, "nMealID": value.id, "cMealName": value.name });
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <EditTextField {...params}
                                        sx={{ minWidth: '160px' }}
                                        name='nMealID'
                                    />}
                                />
                            </Stack>
                        </TableCell>

                        <TableCell align="left">
                            <EditTextField
                                fullWidth
                                sx={{ minWidth: '200px' }}
                                name='cMenuName'
                                value={editData.cMenuName}
                                onChange={handleEditChange}
                                error={editData.cMenuName ? false : formErrors.cMenuName !== null ? true : false}
                                helperText={editData.cMenuName ? '' : formErrors.cMenuName !== null ? 'Menu Code is required' : ''}
                            />
                        </TableCell>

                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cVendorName}
                            </Typography>
                        </TableCell>

                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.ctype}
                            </Typography>
                        </TableCell>

                        <TableCell align="left" >
                            <EditTextField
                                fullWidth
                                sx={{ minWidth: '200px' }}
                                name='cMenuDesc'
                                value={editData.cMenuDesc}
                                onChange={handleEditChange}
                            />
                        </TableCell>

                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {editData.nTotalRate}
                            </Typography>
                        </TableCell>

                        {/* <TableCell align="left">
                            <Autocomplete
                                id="combo-box-demo"
                                name="nDivisionID"
                                fullWidth
                                options={divisionList}
                                size="small"
                                autoHighlight
                                defaultValue={Object.values(division).length === 0 ? divisionList.find(m => m.id === 0) : division}
                                // value={roleName.name}
                                onChange={(event, value) => {
                                    setEditData({ ...editData, nDivisionID: value.id });
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <EditTextField {...params}
                                    sx={{ minWidth: '160px' }}
                                    name='nDivisionID'
                                />}
                            />
                        </TableCell> */}

                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cDivisionName}
                            </Typography>
                        </TableCell>

                        <TableCell align="left">
                            {/* <Typography variant='t14_600'>
                                {editData.bIsActive === true ? 'true' : 'false'}
                            </Typography> */}
                            <Switch 
                                checked={editData.bIsActive === true ? true : false} 
                                // defaultChecked={editData.bIsActive === true ? true : false}
                                onChange={handleEditChange}
                                color="primary" 
                                name='bIsActive'
                            />
                        </TableCell>
                    </>
                }
                {Boolean(userData.cRoleName !== 'Admin') &&
                    <TableCell align="right">
                        <ActionButtons type={'menuMaster'} setStatus={setStatus} status={status} setEditData={setEditData} rowData={rowData} tickIconFunction={tickIconFunction} handleDelete={handleDelete} handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} setDeleteDialogOpen={setDeleteDialogOpen} deleteDialogOpen={deleteDialogOpen} />
                    </TableCell>
                }
            </TableRow>
        </>
    )
}

export default MenuTableRow
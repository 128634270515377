import { call, put, takeEvery } from "redux-saga/effects";
import { GET_EMPLOYEE_CONTRACTOR_WATCHER } from "../../actionTypes/admin";
import { EmployeeContractorList } from "../../actionCreators/admin/emp_con";
import EmployeeContractorService from "../../../services/admin/emp_con";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getEmployeeContractorEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(EmployeeContractorService.get, action.payload);
    yield put(EmployeeContractorList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}


//saga 
export const EmployeeContractorSaga = [
  takeEvery(GET_EMPLOYEE_CONTRACTOR_WATCHER, getEmployeeContractorEffect),
]



import PropTypes from 'prop-types';
// material
import { visuallyHidden } from '@mui/utils';
import { Box, TableRow, TableCell, TableHead, TableSortLabel, Typography } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { useSelector } from 'react-redux'

// ----------------------------------------------------------------------

MenuItemTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func
};

export default function MenuItemTableHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick
}) {

  const { ProfileReducers: {loginState} } = useSelector((state) => state)
  const userData = loginState[0] || {}

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {headLabel.map((headCell) => {
          return <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
                // hideSortIcon
              active={headCell.label ==='#' ? false : true}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={SwapVertIcon}
            >
              <Typography variant='t14_400'>
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        })}

        {Boolean(userData.cRoleName !== 'Admin') &&
          <TableCell align='right' sx={{ minWidth: '115px' }}>
            <Typography variant='t14_400'>
              Action
            </Typography>
          </TableCell>
        }
      </TableRow>
    </TableHead>
  );
}

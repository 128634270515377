import React from 'react';
import Scrollbar from '../../../Scrollbar';
import TableHeader from './tableHeader';
// import { useSelector } from 'react-redux'
// material
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Box,
    Typography
} from '@mui/material';

const TABLE_HEAD = [
    { id: '1', label: 'Add-on 1', alignRight: false },
    { id: '2', label: 'Add-on 2', alignRight: false },
    { id: '3', label: 'Add-on 3', alignRight: false },
    { id: '4', label: 'Add-on 4', alignRight: false },
    { id: '5', label: 'Add-on 5', alignRight: false },
    { id: '6', label: 'Add-on 6', alignRight: false },
    { id: '7', label: 'D1', alignRight: false },
    { id: '8', label: 'D2', alignRight: false },
    { id: '9', label: 'D3', alignRight: false },
    { id: '10', label: 'D4', alignRight: false }

];

const DinnerTable = ({ tableData }) => {
    // const { ReportsReducers: { DinnerList } } = useSelector((state) => state)
    const data = tableData[0] || {}
    const tableHeader = Object.keys(data).map((m, i) => ({ id: i, label: m, alignRight: false }))
    const isUserNotFound = tableData?.length === 0;
    
    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }} >
                        <Table>
                            <TableHeader headLabel={tableHeader.length ? tableHeader : TABLE_HEAD} />
                            <TableBody>
                                <TableRow
                                    // hover={status === 'list' ? true : false}
                                    tabIndex={-1}
                                >
                                </TableRow>
                                {tableData?.map((m, i) => {
                                    return (
                                        <TableRow
                                            key={i}
                                            // hover={status === 'list' ? true : false}
                                            tabIndex={-1}
                                        >
                                            {Object.values(m).map((d, j) => {
                                                return <TableCell key={j} align="left">
                                                    <Typography variant='t11_600' >
                                                        {d || 0}
                                                    </Typography>
                                                </TableCell>
                                            })}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
        </>
    )
}

export default DinnerTable;
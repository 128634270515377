import { ROSTER_LIST } from "../../actionTypes/admin";

const intialState = {
  rosterList: [],
};

export default function RosterReducers(state = intialState, action) {
  switch (action.type) {

    case ROSTER_LIST:
      return { ...state, rosterList: action.payload };

    default:
      return state;
  }
}

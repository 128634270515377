// component
import { HomeOutlined, ShoppingCartOutlined } from '@mui/icons-material'
// ----------------------------------------------------------------------

export const sidebarDashboard = [
  {
    title: 'Dashboard',
    path: '/foodVendor',
    icon: <HomeOutlined color='inherit' />
  },
];

export const sidebarVendorReports = [
  {
    title: 'User',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'User History',
        path: '/foodVendor/userBookingHistory',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Order Tracking',
        path: '/foodVendor/orderTracking',
        icon: <ShoppingCartOutlined color='inherit' />
      }
    ]
  },
  {
    title: 'Contractor',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'Booking History',
        path: '/foodVendor/contractorBookingHistory',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Delivery History',
        path: '/foodVendor/deliveryHistory',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Swipe History',
        path: '/foodVendor/contractorSwipeHistory',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Invoice',
        path: '/foodVendor/contractorInvoice',
        icon: <ShoppingCartOutlined color='inherit' />
      },
    ]
  },
  {
    title: 'Guest',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'Guest History',
        path: '/foodVendor/normalGuest',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Order Tracking',
        path: '/foodVendor/guestOrderTracking',
        icon: <ShoppingCartOutlined color='inherit' />
      }
    ]
  },
  {
    title: 'Other reports',
    icon: <HomeOutlined color='inherit' />,
    children: [
      {
        title: 'Rating',
        path: '/foodVendor/ratings',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Sale Report',
        path: '/foodVendor/saleReport',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Feedbacks',
        path: '/foodVendor/feedbacks',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Day By Day Order',
        path: '/foodVendor/DayByDayOrder',
        icon: <ShoppingCartOutlined color='inherit' />
      },
      {
        title: 'Emp/Contractor/Guest',
        path: '/foodVendor/user_contractor',
        icon: <ShoppingCartOutlined color='inherit' />
      },
    ]
  },
];
import React, { useState, useEffect, useContext } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card, Button, Stack, Autocomplete, TextField } from "@mui/material";
import CommonHeader from '../../../components/admin/reports/contractorInvoice/commonHeader';
import OrderHistoryTable from '../../../components/admin/reports/orderHistory/table';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import { TABLE_HEAD } from '../../../components/admin/reports/orderHistory/table';
import { Link } from 'react-router-dom';
import { getContractorNameListWatcher } from '../../../redux/actionCreators/admin/contractorBook';
import { getConvertedDate4, getCurDate } from '../../../utils/common';
import Context from '../../../context/context';

const ContractorInvoice = () => {
    const { ReportsReducers: { OrderHistoryList }, UserHomeReducers: { cafeteriaId }, ContractorBookReducers: { contractorNameList }, ProfileReducers: {loginState}, } = useSelector((state) => state)

    const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''


    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [contractorID, setContractorID] = useState(0)
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const dataList = OrderHistoryList.filter(d => contractorID ? contractorID === d.nContractorID : true)
    const [eventVal, setEventVal] = useState('')
    const { state, setState } = useContext(Context);
    const [vendorState, setVendorState] = useState('')
    const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })
    
    useEffect(() => {
        if (cafeteriaId) {
            let data = {
                "ACTION": "AdminContractorList",
                NDIVISIONID: cafeteriaId,
                CVENDORID: nRoleID === 3 ? String(vendorId) : 'null'
            }
            dispatch(getOrderHistoryWatcher(data))
        }
    }, [dispatch, cafeteriaId])

    useEffect(() => {
        dispatch(getContractorNameListWatcher())
    }, [dispatch])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = dataList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        let currentDate = new Date()
        let curDate = getConvertedDate4(currentDate)
        
        // if(toDate >= fromDate){
            // && toDate <= curDate && fromDate <= curDate
            const data = {
                ACTION: "AdminSearchContractorList",
                FromDate: fromDate,
                ToDate: toDate,
                UserId: "33",
                NDIVISIONID: cafeteriaId,
                // CVENDORID: state !== "" ? `${state}` : 'null'
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getOrderHistoryWatcher(data))
            setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
            setPage(1)
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true })
        // }
    }

    useEffect(()=>{
        if(eventVal)
        handleSearch(eventVal);
    },[dataList])

    return (
        <>
            <Box p="24px">
                <Breadcrumbs content={["Reports", "Contractor Invoice"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Contractor Invoice</Typography>
                    <Stack sx={{ ml: 'auto' }} flexDirection='row'>
                        <Box>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            fullWidth
                            options={contractorNameList.filter(d => cafeteriaId.includes(d.nDivisionID))}
                            name="contractorID"
                            autoHighlight
                            // defaultValue={values.contractorID === "0" ? selectObj : selectObj}
                            getOptionLabel={(option) => option.Name}
                            onChange={(event, value) => {
                                setContractorID(value?.ID);
                            }}
                            sx={{ width: '300px' }}
                            size='small'
                            renderInput={(params) => <TextField {...params}
                                name="contractorID"
                                label="Select Contractor"
                                required={true}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'common.white',
                                        '&:hover fieldset': {
                                            borderColor: 'primary.main',
                                        },
                                    },

                                }}
                            />}
                        />
                        </Box>
                        <Button disabled={!fromDate || !toDate || !contractorID} sx={{ml: '20px'}} component={Link} to={`/invoice/contractor/${getCurDate(fromDate)}/${getCurDate(toDate)}/${contractorID}`} target='_blank' variant='contained' >
                            <Typography variant='t16_400'>
                            Invoice
                            </Typography>
                        </Button>
                    </Stack>

                </Box>

                <Box p='10px 0'>
                    <CommonHeader toDate={toDate} setToDate={setToDate} fromDate={fromDate} setFromDate={setFromDate} handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={OrderHistoryList} columnData={TABLE_HEAD} setVendorState={setVendorState} invalidErr={invalidErr} setInvalidErr={setInvalidErr}/>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <OrderHistoryTable contractorID={contractorID} page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} dataList={dataList}/>
                </Card>

            </Box>
        </>
    )
}

export default ContractorInvoice
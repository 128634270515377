import React, { useState } from 'react';
import { Button, Typography, Grid, Stack } from '@mui/material';
import CssTextField from '../../../overrides/longSearchField'
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileUploadButton from '../../../overrides/fileUploadButton';
import { createCarouselWatcher } from '../../../../redux/actionCreators/admin/carousel';
// import BlackButton from '../../../../components/overrides/blackButton'

const Input = styled('input')({
    display: 'none',
});


function NewCarousel(props) {
    const dispatch = useDispatch()

    // const { CarouselReducers: { CarouselImageList } } = useSelector((state) => state)

    const { setOpen } = props;
    const [imageName, setImageName] = useState('')
    // const [imageFile, setImageFile] = useState('')

    const formik = useFormik({
        initialValues: {
            cDescription: '',
            cUrl: '',
            cImage: ''
        },

        onSubmit: () => {
            let values = { ...formik.values }
            let data = {
                "ACTION": "Insert",
                "cImage": values.cImage,
                "cDescription": values.cDescription,
                "cUrl": values.cUrl
            }

            dispatch(createCarouselWatcher(data, setOpen))
        }
    });

    const imageFunction = (e) => {
        if (e) {
            var file = e.target.files[0];
            var name = e.target.files[0].name;
            setImageName(name);
            var reader = new FileReader();
            reader.onloadend = function () {
                // setImageFile(reader.result)
                setFieldValue('cImage', reader.result)
            }
            reader.readAsDataURL(file);
        }
    }

    const handleCloseFunc = () => {
        setOpen(false)
    }

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    // const User_list = ['Contractor', 'Employee', 'SpecialGuest']

    return (
        <>
            <FormikProvider value={formik}
            >
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    <Typography variant='h5'
                        align='center'
                        style={{ paddingBottom: '10px' }}>
                        New Carousel
                    </Typography>

                    <Grid
                        container
                        style={{ padding: '30px' }}
                        spacing={3}
                    >
                        <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <label htmlFor="contained-button-file">
                                <Input accept="image/*" onChange={imageFunction} id="contained-button-file" type="file" />
                                <FileUploadButton
                                    variant="contained"
                                    component="span"
                                    fullWidth
                                    // style={{ justifyContent: "flex-start" }}
                                    endIcon={<FileUploadOutlinedIcon sx={{ color: 'common.black' }} />}
                                    type='button'
                                >
                                    <Typography variant='t16_600' color='grey.600'>
                                        {imageName ? imageName : 'Choose File'}
                                    </Typography>
                                </FileUploadButton >
                            </label>
                        </Grid>

                        <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                style={{}}
                                fullWidth={true}
                                label=' Description'
                                name='cDescription'
                                type='text'
                                {...getFieldProps("cDescription")}
                                error={Boolean(touched.cDescription && errors.cDescription)}
                                helperText={touched.cDescription && errors.cDescription}
                            />
                        </Grid>

                        <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                style={{}}
                                fullWidth={true}
                                label='Url'
                                name='cUrl'
                                type='text'
                                {...getFieldProps("cUrl")}
                                error={Boolean(touched.cUrl && errors.cUrl)}
                                helperText={touched.cUrl && errors.cUrl}
                            />
                        </Grid>
                        <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            item={true}
                            display='flex'
                        >

                            <Stack direction='row' spacing={3} ml='auto'>
                                <Button
                                    onClick={handleCloseFunc}
                                    sx={{
                                        height: '42px',
                                        left: '0px',
                                        top: '0px',
                                        minWidth: '120px',
                                        '&:hover': {
                                            backgroundColor:theme => theme.palette.grey[300],
                                          },
                                          backgroundColor:theme => theme.palette.grey[300],
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='button'>
                                    <Typography color='common.black'>Cancel</Typography>
                                </Button>

                                <Button
                                    onClick={handleSubmit}
                                    style={{
                                        height: '42px',
                                        left: '0px',
                                        top: '0px',
                                        minWidth: '120px',
                                        backgroundColor: 'common.Bblack'
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='submit'
                                >
                                    <Typography color='grey.0'>Submit</Typography>
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </>
    );

}

export default NewCarousel;
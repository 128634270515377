// component
import { HomeOutlined, ShoppingCartOutlined } from '@mui/icons-material'
// ----------------------------------------------------------------------

export const KioskAdmin = [
  {
    title: 'Dashboard',
    path: '/kioskAdmin',
    icon: <HomeOutlined color='inherit' />
  },
  {
    title: 'Menu List',
    path: '/kioskAdmin/kioskMenuList',
    icon: <ShoppingCartOutlined color='inherit' />
  }

];

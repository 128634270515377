import { CAB_LIST } from "../../actionTypes/cab";

const intialState = {
  CabList: []
};

export default function CabReducers(state = intialState, action) {
  switch (action.type) {

    case CAB_LIST:
      return { ...state, CabList : action.payload };

    default:
      return state;
  }
}

import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

export default function SimpleBackdrop() {
  const {
    LoaderReducers: { spinnerCount },
  } = useSelector((state) => state);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: 9999 }}
        open={Boolean(spinnerCount)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

import React, { useState, useEffect } from 'react'
import { Typography, Card, Grid, Button, Box, InputAdornment, Autocomplete } from "@mui/material";
import MenuMasterTable from '../../../components/admin/kitchen/menuMaster/table';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CreationDialog from '../../../components/dialog';
import SearchField from '../../../components/overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import Breadcrumbs from '../../../components/breadcrumbs';
import NewMenuMaster from '../../../components/admin/kitchen/menuMaster/NewMenuMaster'
import { useDispatch, useSelector } from 'react-redux'
import { getMealDropdownWatcher, getMenuMasterWatcher } from '../../../redux/actionCreators/admin/menuMaster';
import { getDropDownWatcher } from '../../../redux/actionCreators/admin/employeeManagement';
import CssTextField from '../../../components/overrides/coloredTextField';

const MenuMaster = () => {
    const dispatch = useDispatch()

    const { MenuMasterReducers: { menuMasterList, mealDropdownList }, UserHomeReducers: { cafeteriaId }, } = useSelector((state) => state)

    const [open, setOpen] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [role, setRole] = useState(0);
    const [eventVal, setEventVal] = useState('')

    // const type = mealDropdownList.length && mealDropdownList[2] || []
    const type = (mealDropdownList.length && mealDropdownList[2]) || []

    useEffect(() => {
        if (cafeteriaId) {
            let data1 = {
                ACTION: "GridSELECT", SROLEID: role,
                SDIVISIONID: cafeteriaId
            }
            dispatch(getMenuMasterWatcher(data1))
            setPage(1)
        }
    }, [dispatch, cafeteriaId, role])

    useEffect(()=>{
        dispatch(getMealDropdownWatcher());
        dispatch(getDropDownWatcher());
    },[dispatch])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = menuMasterList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }
    const defaultVal = { name: 'Select Role', id: 0 }

    useEffect(()=>{
        if(eventVal)
        handleSearch(eventVal);
    },[menuMasterList])

    // const filterByRole = (id) => {
    //     let data = {
    //         ACTION: 'GridSELECT',
    //         SROLEID: id,
    //         SDIVISIONID: cafeteriaId
    //     }
    //     dispatch(getMenuMasterWatcher(data))
    // }

    return (
        <>
            <Box p='30px'>
                <Breadcrumbs content={["Kitchen", "Menu Master"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' >Menu Master </Typography>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                    <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                        <Grid item lg={7.5} xs={12} md={7} sm={12} sx={{ justifyContent: 'left' }}>
                            <SearchField
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '50px'
                                    }
                                }}
                                placeholder='Search'
                                fullWidth
                                value={searchText}
                                onChange={handleSearch}
                            />
                        </Grid>
                        <Grid item lg={2.5} xs={12} md={3} sm={6} sx={{ justifyContent: 'right', display: 'flex' }}>
                            <Autocomplete
                                // sx={{ width: '200px' }}
                                disablePortal
                                id="combo-box-demo"
                                name="selectMeal"
                                fullWidth
                                options={type}
                                defaultValue={defaultVal}
                                size="medium"
                                autoHighlight
                                onChange={(event, value) => {
                                    setRole(value.id)
                                    // filterByRole(value.id);
                                }}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <CssTextField sx={{
                                    '& .MuiOutlinedInput-input': {
                                        height: '16px',
                                        color: 'common.white'
                                    },
                                }} {...params} />}
                            />
                        </Grid>
                        <Grid item lg={2} xs={12} md={2} sm={6} sx={{ justifyContent: 'right', display: 'flex' }}>
                            <Button variant='contained' fullWidth endIcon={<DoubleArrowIcon />} sx={{ height: '49px' }} onClick={() => setOpen(true)}>
                                <Typography variant='t16_500'>
                                    Add Menu
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
                <br />
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <MenuMasterTable role={role} divisionId={cafeteriaId} page={page} setPage={setPage} searchVal={searchVal} searchText={searchText}
                    />
                </Card>
                <CreationDialog open={open} setOpen={setOpen} >
                    <NewMenuMaster setOpen={setOpen} />
                </CreationDialog>
            </Box>
        </>
    )
}

export default MenuMaster;
import React from 'react'
import { Typography, Grid } from "@mui/material";
import { useSelector } from 'react-redux';

export default function Dashboard() {
    const { ProfileReducers: { loginState } } = useSelector(state => state)
    const cEmployeeName = loginState[0]?.cEmployeeName || ''
    
    return (
        <>
            <Grid container style={{ padding: '24px' }}>
                <Grid item xs={12}>
                    <Typography variant='t12_400' >Dashboard</Typography>
                </Grid>
                <Grid item mt='8px' xs={12}>
                    <Typography variant='t24_700'>{`Welcome, ${cEmployeeName}`}</Typography>
                </Grid>
            </Grid>
        </>
    )
}

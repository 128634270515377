import React, { useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow,
} from '@mui/material';


const GuestOrderHistoryTableRow = ({ rowData }) => {

    const [status] = useState('list');

    return (
        <>
            <TableRow
                hover={status === 'list' ? true : false}
                tabIndex={-1}
            >
                <TableCell align="left">
                    <Typography variant='t14_600' >
                        {rowData.cUserName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData['Employee Name']}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.cDivisionName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData['OrderDate']}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Breakfast || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Lunch || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Snacks || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Dinner || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData['Early morning snacks'] || 0}
                    </Typography>
                </TableCell>
                {/* <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData['Snacks(Take away)']}
                    </Typography>
                </TableCell> */}
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Status}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    )
}

export default GuestOrderHistoryTableRow
import React from 'react';
import { Button, Typography, Grid, Stack, Box } from '@mui/material';
import CssTextField from '../../../overrides/longSearchField';
//import { List } from '../../../List';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { createPushNotificationWatcher } from '../../../../redux/actionCreators/admin/reports';
import { useDispatch } from 'react-redux'
import {sendNtfy} from '../../../../firebase';

function NewPushNotification(props) {
    const dispatch = useDispatch()

    const { setOpen } = props

    const ContractorBookSchema = Yup.object().shape({

        title: Yup.string().min(2, 'Too Short!').max(500, 'Too Long!').required('Title is required'),
        body: Yup.string().min(2, 'Too Short!').max(500, 'Too Long!').required('Body is required'),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            body: ''
        },

        validationSchema: ContractorBookSchema,

        onSubmit: () => {
            let values = { ...formik.values }
            let data = {
                "ACTION": "pushAdd",
                "nTitle": values.title,
                "nBody": values.body
            }
            sendNtfy(values.title, values.body)
            dispatch(createPushNotificationWatcher(data, setOpen))
        }
    });

    const handleClose = () => {
        setOpen(false)
    }

    const { errors, touched, handleSubmit, getFieldProps } = formik;

    return (
        // <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>

                    <Box textAlign='center'>
                        <Typography variant='t20_600'
                            style={{ paddingBottom: '10px' }}>
                            Push Notification
                        </Typography>
                    </Box>

                    <Grid
                        container
                        style={{ padding: '30px' }}
                        spacing={3}
                        direction='row'
                    >
                        <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                label='Title'
                                name='title'
                                type='text'
                                {...getFieldProps("title")}
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                            />
                        </Grid>

                        <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                label='Body'
                                name='body'
                                type='text'
                                {...getFieldProps("body")}
                                error={Boolean(touched.body && errors.body)}
                                helperText={touched.body && errors.body}
                            />
                        </Grid>

                        <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            item={true}
                            display='flex'
                        >
                            <Stack direction='row' spacing={3} ml='auto' >
                                <Button onClick={() => handleClose()}
                                    color='inherit'
                                    style={{
                                        height: '42px',
                                        minWidth: '100px'
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='button'>
                                    <Typography color='common.black'>Cancel</Typography>
                                </Button>

                                <Button onClick={handleSubmit}
                                    style={{
                                        height: '42px',
                                        left: '0px',
                                        top: '0px',
                                        minWidth: '100px',
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='submit'>
                                    <Typography color='grey.0'>Send</Typography>
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        // </>
    );

}

export default NewPushNotification;
import React, { useEffect } from 'react';
import { Button, Typography, Grid, Autocomplete, Stack, Checkbox } from '@mui/material';
import CssTextField from '../../../overrides/longSearchField'
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
// import { styled } from '@mui/material/styles';
import { createfreezeBookingWatcher, getSpecialMenuWatcher } from '../../../../redux/actionCreators/admin/carousel';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box } from '@mui/system';
import * as Yup from 'yup';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { getVendorWatcher } from '../../../../redux/actionCreators/admin/vendorManagement';

// const Input = styled('input')({
//     display: 'none',
// });


function NewFreeze(props) {
    const dispatch = useDispatch()

    const { setOpen } = props;

    const Schema = Yup.object().shape({
        message: Yup.string('Enter your message').min(2, 'Too Short!').max(100, 'Too Long!').required('message is required'),
        mealName: Yup.array().min(1, "at least 1").required('Meal name is required'),
        menuName: Yup.array().min(1, "at least 1").required('Menu name is required'),
        vendorId: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Vendor Name is required'),
    });

    const formik = useFormik({
        initialValues: {
            freezeDate: new Date(),
            message: '',
            mealName: [],
            menuName: [],
            vendorId: ''
        },
        validationSchema: Schema,
        onSubmit: () => {
            let { freezeDate, message, mealName, menuName, vendorId } = { ...formik.values }
            let data = {
                "ACTION": "INSERT",
                freezeDate,
                message,
                mealName: `${mealName}`,
                menuName: `${menuName}`,
                CVENDORID: `${vendorId}`
            }

            dispatch(createfreezeBookingWatcher(data, setOpen))
        }
    });

    const { MenuMasterReducers: { mealDropdownList }, CarouselReducers: {specialMenu}, VendorReducers: { vendorList },UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)

    // const mealNameList = mealDropdownList.length && mealDropdownList[1] || []
    const mealNameList = (mealDropdownList.length && mealDropdownList[1] )|| []

    useEffect(() => {
        let data = { ACTION: "SELECT", SDIVISIONID: cafeteriaId }
        dispatch(getVendorWatcher(data))
        // setVendorValue([])

    }, [cafeteriaId, dispatch])

    const handleCloseFunc = () => {
        setOpen(false)
    }

    const handleDateChange = (e) => {
        setFieldValue("freezeDate", e)
    }

    const icon = <CheckBoxOutlineBlank fontSize="small" />;
    const checkedIcon = <CheckBox fontSize="small" />;

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = formik;

    const getSpecials = () => {
        if (values.mealName.length) {
            const data = {
                ACTION: 'SelectSpecial',
                mealName: `${values.mealName}`,
                CVENDORID: `${values.vendorId}`
            }
            dispatch(getSpecialMenuWatcher(data))
        }
    }

    return (
        // <>
            <FormikProvider value={formik}
            >
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    <Box textAlign='center' mt='10px'>
                        <Typography variant='t20_600'
                            style={{ paddingBottom: '10px' }}>
                            Freeze Booking
                        </Typography>
                    </Box>

                    <Grid
                        container
                        style={{ padding: '30px' }}
                        spacing={3}
                        direction='row'
                    >
                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={values.freezeDate}
                                    required
                                    label='Freeze Date'
                                    name="freezeDate"
                                    onChange={handleDateChange}
                                    renderInput={(params) => <CssTextField {...params}
                                         fullWidth {...params}
                                        error={Boolean(touched.freezeDate && errors.freezeDate)}
                                        helperText={touched.freezeDate && errors.freezeDate}
                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                fullWidth={true}
                                label='Message'
                                name='message'
                                type='text'
                                {...getFieldProps("message")}
                                error={Boolean(touched.message && errors.message)}
                                helperText={touched.message && errors.message}
                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                // disablePortal
                                id="combo-box-demo"
                                name="vendorId"
                                fullWidth
                                options={vendorList}
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("vendorId", value.NID);
                                }}
                                getOptionLabel={(option) => option.CVENDORNAME}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Select Vendor"
                                    name='vendorId'
                                    required={true}
                                    error={Boolean(touched.vendorId && errors.vendorId)}
                                    helperText={touched.vendorId && errors.vendorId}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="combo-box-demo"
                                name="accessPoints"
                                fullWidth
                                options={mealNameList}
                                size="medium"
                                autoHighlight
                                onChange={(event, value) => {
                                    let Val = value.map(m => m.cMealName)
                                    setFieldValue("mealName", Val);
                                }}
                                getOptionLabel={(option) => option.cMealName}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.cMealName}
                                    </li>
                                )}
                                onClose={getSpecials}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Choose Special Meals"
                                    name='mealName'
                                    required={true}
                                    error={Boolean(touched.mealName && errors.mealName)}
                                    helperText={touched.mealName && errors.mealName}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="combo-box-demo"
                                name="accessPoints"
                                fullWidth
                                options={specialMenu}
                                size="medium"
                                autoHighlight
                                onChange={(event, value) => {
                                    let Val = value.map(m => m.cMenuName)
                                    setFieldValue("menuName", Val);
                                }}
                                getOptionLabel={(option) => option.cMenuName+'('+ option.cMenuDesc+')'}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.cMenuName+'('+ option.cMenuDesc+')'}
                                    </li>
                                )}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Choose Special Menus"
                                    name='mealName'
                                    required={true}
                                    error={Boolean(touched.menuName && errors.menuName)}
                                    helperText={touched.menuName && errors.menuName}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            item={true}
                        >

                            <Stack direction='row' spacing={3} justifyContent='end' >
                                <Button
                                    onClick={handleCloseFunc}
                                    sx={{
                                        height: '42px',
                                        left: '0px',
                                        top: '0px',
                                        minWidth: '120px',
                                        '&:hover': {
                                            backgroundColor: theme => theme.palette.grey[300],
                                        },
                                        backgroundColor: theme => theme.palette.grey[300],
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='button'>
                                    <Typography color='common.black'>Cancel</Typography>
                                </Button>

                                <Button
                                    onClick={handleSubmit}
                                    style={{
                                        height: '42px',
                                        left: '0px',
                                        top: '0px',
                                        minWidth: '120px',
                                        backgroundColor: 'common.Bblack'
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='submit'
                                >
                                    <Typography color='grey.0'>Submit</Typography>
                                </Button>
                            </Stack>
                        </Grid>

                    </Grid>
                </Form>
            </FormikProvider>
        // </>
    );

}

export default NewFreeze;
import { Button, Card, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import CssTextField from "../../overrides/longSearchField";
import { useDispatch, useSelector } from "react-redux";
import { insertFeedBackWatcher, rateFeedBackWatcher } from "../../../redux/actionCreators/user/currentOrder";
import { updateAlert } from "../../../redux/actionCreators/alert";
import { base_url } from "../../../http-common";
import CustTypo from "../../overrides/CustTypo";
import CreationDialog from "../../dialog";
import CloseButton from '../../overrides/closeButton'

const OrderCards = ({ value }) => {
  const { cImage, cMenuName, nToatlRate, cMenuDesc, dDeliveryDate, nBookingID, nMenuID, cMealName } = value;

  const vendorId = sessionStorage.getItem('vendorId')

  const [rateVal, setRateVal] = useState('');
  const [comment, setComment] = useState('');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { ProfileReducers: { loginState } } = useSelector(state => state) //UserHomeReducers: { cartList },

  const nUserID = loginState[0]?.nID || 0

  const handleClick = (data) => {
    setRateVal(data);
  };

  const rateUs = () => {
    if (!rateVal || !comment) {
      return dispatch(updateAlert('Please enter your feedback', 'error'))
    }
    const data = {
      Action: "insertFeed",
      nUserId: nUserID,
      nMenuId: nMenuID,
      cCommand: comment,
      nBookingDetailId: nBookingID,
     CVENDORID: vendorId

      
    };
    const data2 = {
      Action: "insertRate",
      nRating: `${rateVal}`,
      nBookingDetailId: nBookingID,
      nMenuID: nMenuID,
      CVENDORID: vendorId,
      nUserId: nUserID,
    };
    dispatch(insertFeedBackWatcher(data));
    dispatch(rateFeedBackWatcher(nUserID, data2));
    setOpen(false)
  };

  const handleChange = (e) => {
    const { value } = e.target
    setComment(value)
  }

  return (
    <Card
      sx={{
        p: "19px",
        borderRadius: "6px",
        color: "common.white",
        display: "flex",
        height: '100%'
      }}
    >

      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} height="100%" width="100%">
        <Box
          component="img"
          src={`${base_url}/${cImage}`}
          sx={{
            width: { md: 163, xs: 'auto' },
            height: '155px',
            borderRadius: "12px",
            objectFit: "cover",
            mr: { md: "17px", xs: 0 },
          }}
          alt={cImage}
        ></Box>
        <Box
          display="flex"
          width="100%"
          color="common.black"
          flexDirection="column"
          mt={{ xs: '10px', md: 0 }}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="t14_400" color="grey.500">
              Delivered on {dDeliveryDate}
            </Typography>
            <CheckCircleIcon sx={{ color: "common.sgreen", ml: "12px" }} />
          </Box>
          <Typography mt="6px" variant="t18_500">
            {cMealName} ({cMenuName})
          </Typography>

          {cMenuDesc.length > 20 ?
            <CustTypo mt="10px" color="grey.500" variant="t16_400" >
              {cMenuDesc}
            </CustTypo>
            :
            <Box mt="auto">
              <Typography color="grey.500" variant="t16_400">
                {cMenuDesc}
              </Typography>
            </Box>
          }

          <Box display="flex" width='100%' mt="auto" >
            <Typography variant="t24_700" mt="auto">₹{nToatlRate}</Typography>
            <Button
              variant="contained"
              sx={{ height: 44, width: 130, mt: "10px", ml: "auto" }}
              endIcon={<DoubleArrowIcon />}
              onClick={() => setOpen(true)}
            >
              <Typography variant="t16_500">Rate Us</Typography>
            </Button>
          </Box>
        </Box>
      </Box>


      <CreationDialog maxWidth='xs' open={open} setOpen={setOpen}>
        <Box
          ml="auto"
          display="flex"
          flexDirection="column"
        >
          <Box width='max-content' >
            <Box display="flex">
              <Typography variant="t14_400" color="grey.500">
                Not at all likely
              </Typography>
              <Typography ml="auto" variant="t14_400" color="grey.500">
                Extremely likely
              </Typography>
            </Box>

            <Stack direction="row" mt="10px" spacing={{ xs: 1.5, sm: 2 }}>
              {[1, 2, 3, 4, 5].map((d) => (
                <Button
                  key={d}
                  sx={{
                    height: { sm: 46, xs: 35 },
                    minWidth: { sm: 64, xs: 30 },
                    backgroundColor: (theme) =>
                      theme.palette.common[
                      rateVal === d ? "Bblack" : "cButton"
                      ],
                    "&:hover": {
                      backgroundColor: (theme) =>
                        theme.palette.common[
                        rateVal === d ? "Bblack" : "cButton"
                        ],
                    },
                    color: (theme) =>
                      theme.palette.common[rateVal === d ? "white" : "black"],
                  }}
                  variant="contained"
                  onClick={() => handleClick(d)}
                >
                  <Typography variant="t16_400">{d}</Typography>
                </Button>
              ))}
            </Stack>
          </Box>


          <CssTextField
            sx={{ mt: "20px" }}
            placeholder="Enter Comment"
            fullWidth
            multiline
            rows={2}
            onChange={handleChange}
            value={comment}
          />
          <Stack  mt= "16px"  justifyContent='end' spacing={2} direction='row'>
          <CloseButton
            variant="contained"
            sx={{ height: 44, width: 130}}
            onClick={()=>setOpen(false)}
          >
            <Typography variant="t16_500">Close</Typography>
          </CloseButton>
          <Button
            variant="contained"
            sx={{ height: 44, width: 130,}}
            endIcon={<DoubleArrowIcon />}
            onClick={rateUs}
          >
            <Typography variant="t16_500">Rate Us</Typography>
          </Button>
          </Stack>
          
        </Box>
      </CreationDialog>
    </Card>
  );
};

export default OrderCards;

import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Scrollbar from '../../../Scrollbar';
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Pagination,
    Box
} from '@mui/material';
import CommonTableHead from '../../../foodVendor/reports/commonTableHead.js';
import { getEmployeeContractorWatcher } from '../../../../redux/actionCreators/admin/emp_con';
import { useDispatch, useSelector } from 'react-redux';
import EmpConTableRow from './tableRow';
// import Context from '../../../../context/context.js';

export const TABLE_HEAD = [
    { id: 'orderDate ', label: 'Order Date', alignRight: false, title: 'Order Date', field: 'OrderDate' },
    { id: 'Employee', label: 'Employee', alignRight: false, title: 'Employee', field: 'Employee' },
    { id: 'contractor', label: 'Contractor', alignRight: false, title: 'Contractor', field: 'Contractor' },
    // { id: 'intern', label: 'Intern', alignRight: false, title: 'Intern', field: 'Intern' },
    { id: 'splGuest', label: 'Special Guest', alignRight: false, title: 'Special Guest', field: 'Special' }
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const EmpConTable = ({ searchVal, page, setPage, vendorState }) => {

    let firstIndex = 0;

    const dispatch = useDispatch();
    const { ReportsReducers: { EmployeeContractorList }, UserHomeReducers: { cafeteriaId }, ProfileReducers: { loginState } } = useSelector((state) => state)
    
  

    const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage] = useState(5); //, setRowsPerPage
    const tempinitsform = useRef();
    // const { state } = useContext(Context)

    const initsform = () => {
        let data = {
            ACTION: "select",
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) : 'null'
        }
        dispatch(getEmployeeContractorWatcher(data))
    };
    tempinitsform.current = initsform;
    useEffect(() => {
        tempinitsform.current();
    }, [cafeteriaId]);

    // useEffect(() => {
    //     let data = { ACTION: "select" }
    //     dispatch(getEmployeeContractorWatcher(data))
    // }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const tableData = searchVal.length ? searchVal : EmployeeContractorList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }} >
                        <Table>
                            <CommonTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                //   rowCount={USERLIST.length}
                                //   numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    .map((m, i) => {
                                        return (
                                            <EmpConTableRow key={m.ID} rowData={m} index={i} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default EmpConTable;
import {
  Box,
  Breadcrumbs,
  Card,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderTrackingTable from "../../../../components/admin/reports/guestOrderTracking/table";
import CommonHeader from "../../../../components/admin/reports/commonHeader";
import { getGuestOrderTrackingWatcher } from "../../../../redux/actionCreators/admin/reports";
import { TABLE_HEAD } from "../../../../components/admin/reports/guestOrderTracking/table";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ExportCsv } from "@material-table/exporters";
import GuestOrderTrackSubTable, { SUB_TABLE_HEAD } from "../../../../components/admin/reports/guestOrderTracking/subTable";
import moment from "moment/moment";
import Context from "../../../../context/context";
import { getCurDate } from "../../../../utils/common";

export default function GuestOrderTracking() {
  const {
    ReportsReducers: { GuestOrderTrackingList },
    UserHomeReducers: { cafeteriaId },
    ProfileReducers: { loginState },
  } = useSelector((state) => state);

  const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''

  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchVal, setSearchVal] = useState([]);
  const [pageChange, setPageChange] = useState(false);
  const [page, setPage] = useState(1);
  const [eventVal, setEventVal] = useState("");
  const { state, setState } = useContext(Context);
  const [vendorState, setVendorState] = useState('')
  const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })

  useEffect(() => {
    if (cafeteriaId) {
      let data = {
        cAction: "GuestOrderTrack",
        NDIVISIONID: cafeteriaId,
        CVENDORID: nRoleID === 3 ? String(vendorId) : 'null'
    };
      dispatch(getGuestOrderTrackingWatcher(data));
    }
  }, [dispatch, cafeteriaId]);

  useEffect(() => {
    if (eventVal) {
      handleSearch(eventVal);
    }
  });

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  const handleSearch = (e) => {
    let val = e?.target?.value || "";
    setSearchText(val);
    setEventVal(e);
    const searchRegex = new RegExp(escapeRegExp(val), "i");
    const filteredRows = GuestOrderTrackingList.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]);
      });
    });

    setSearchVal(filteredRows);
    setPage(1);
  };

  const nUserID = loginState[0]?.nID || 0;

  const handleSubmitAction = (fromDate, toDate) => {
    let currentDate = new Date()
    let curDate = getCurDate(currentDate)
    // console.log("hansin", toDate);
    // if(toDate >= fromDate && toDate <= curDate && fromDate <= curDate){
      const data = {
        nUserID: nUserID,
        cAction: "GuestOrderTrackSearch",
        Fdate: moment(fromDate).format("DD/MM/YYYY"),
        Tdate: moment(toDate).format("DD/MM/YYYY"),
        NDIVISIONID: cafeteriaId,
        // CVENDORID: state !== "" ? `${state}` : 'null'
        CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
      };
      dispatch(getGuestOrderTrackingWatcher(data));
      setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
      setPage(1);
      // console.log("data-------------->", data); 
    // }
    // else{
    //     setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true })
    // }
  };
  return (
    <>
            {!pageChange ?
                    <Box p="24px">
                    <Breadcrumbs content={["Reports", "Guest Order Tracking"]} />
              
                    <Box mt="14px" mb="8px" display="flex" alignItems="center">
                      <Typography variant="t24_700"> Guest Order Tracking </Typography>
                    </Box>
                    <Box p="10px 0">
                      <CommonHeader
                        handleSearch={handleSearch}
                        searchText={searchText}
                        handleSubmitAction={handleSubmitAction}
                        rowData={GuestOrderTrackingList}
                        columnData={[...TABLE_HEAD]}
                        defaultDate={new Date()} setVendorState={setVendorState} invalidErr={invalidErr} setInvalidErr={setInvalidErr}
                      />
                    </Box>
                    <Card
                      style={{
                        boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`,
                        borderRadius: "6px 6px 0px 0px",
                        padding: "20px",
                      }}>
                      <OrderTrackingTable
                        page={page}
                        setPage={setPage}
                        searchVal={searchVal}
                        searchText={searchText}
                        GuestOrderTrackingList={GuestOrderTrackingList}
                      />
                    </Card>
                  </Box>

                :

                <>
                    <Box p="24px">
                        <Box p='20px 0' display='flex' justifyContent='right' >
                            <Button sx={{ mr: '15px', minWidth: '100px', }} variant='contained' onClick={() => setPageChange(false)} >
                                Back
                            </Button>
                            <Button variant='contained' sx={{ height: '50px', minWidth: '150px', mr: '15px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(SUB_TABLE_HEAD, GuestOrderTrackingList, "Special Order Tracking")}>
                                <Typography variant='t16_500'>Export to Excel</Typography>
                            </Button>

                        </Box>
                        <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                            <GuestOrderTrackSubTable />
                        </Card>
                    </Box>
                </>

            }
        </>
  );
}

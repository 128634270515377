import { GET_LAST_WEEK_ORDER_SUMMARY_WATCHER, GET_LAST_DAY_ORDER_SUMMARY_WATCHER, ORDER_SUMMARY_LAST_DAY_DATA, ORDER_SUMMARY_LAST_WEEK_DATA, ORDER_SUMMARY_FILTERED_DATA, GET_SWIPE_ORDER_SUMMARY_WATCHER, SWIPE_ORDER_SUMMARY_DATA, MEAL_COUNT_DATA } from "../../actionTypes/admin";

// Worker triggering actionCreators

export function getLastWeekOrderSummaryWatcher(authParams) {
    return { type: GET_LAST_WEEK_ORDER_SUMMARY_WATCHER, payload: authParams };
}

export function getLastDayOrderSummaryWatcher(data) {
    return { type: GET_LAST_DAY_ORDER_SUMMARY_WATCHER, payload: data };
}

export function getSwipeOrderSummaryWatcher(authParams) {
    return { type: GET_SWIPE_ORDER_SUMMARY_WATCHER, payload: authParams };
}


// Redux state changing actionCreators
export function orderSummaryLastWeekData(data) {
    return { type: ORDER_SUMMARY_LAST_WEEK_DATA, payload: data };
}

export function orderSummaryLastDayData(data) {
    return { type: ORDER_SUMMARY_LAST_DAY_DATA, payload: data };
}

export function orderSummaryFilteredData(data) {
    return { type: ORDER_SUMMARY_FILTERED_DATA, payload: data };
}

export function swipeOrderSummaryData(data) {
    return { type: SWIPE_ORDER_SUMMARY_DATA, payload: data };
}

export function mealCountData(data) {
    return { type: MEAL_COUNT_DATA, payload: data };
}
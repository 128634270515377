import http from "../../http-common";

class KioskRoleServices {

    getKioskBookingDetails(data) {
        return http.post("kiosk/getKioskDetails", data);
    }

    updateBookingDetailsStatus(id, data) {
        return http.put(`/kiosk/updateKioskStatus/${id}`, data); 
    }

    getKioskMenuListDetails(data) {
        return http.post("kiosk/getMenuList", data);
    }

    updateMenuListStatus(id, data) {
        return http.put(`/admin/updateMenuMasterStatus/${id}`, data);
    }

    getNotificationDetails(data) {
        return http.post("kiosk/getKioskNotifications", data);
    }

    getNotificationCount(data) {
        return http.post("kiosk/getKioskNotifications", data);
    }

    updateNotificationDetails(data) {
        return http.post("/kiosk/updateKioskNotifications", data); 
    }
}

export default new KioskRoleServices();
import http from "../../http-common";

class CabService {

    create(data) {
        return http.post(`/user/addCab`, data);
    }

    get(data) {
        return http.post(`/user/cabBooking`, data);
    }

}

export default new CabService();
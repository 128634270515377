import React, { useContext, useEffect, useState } from 'react';
import { Card, Grid, Button, Stack, Typography, Box } from "@mui/material";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useSelector } from 'react-redux';
import { CSVLink } from "react-csv";
import { getBreakfastDayByDayOrderWatcher, getLunchDayByDayOrderWatcher, getDinnerByDayOrderWatcher, getSnacksByDayOrderWatcher, getSupperByDayOrderWatcher, getNormalGuestBreakfastDayByDayOrderWatcher, getNormalGuestLunchDayByDayOrderWatcher, getNormalGuestSnacksByDayOrderWatcher, getNormalGuestDinnerByDayOrderWatcher, getNormalGuestSupperByDayOrderWatcher, getVipGuestBreakfastDayByDayOrderWatcher, getVipGuestLunchDayByDayOrderWatcher, getVipGuestSnacksByDayOrderWatcher, getVipGuestDinnerByDayOrderWatcher, getVipGuestSupperByDayOrderWatcher } from '../../../../redux/actionCreators/admin/reports';
import { useDispatch } from 'react-redux'
import { getCurDate } from "../../../../utils/common";
import VendorFilter from '../vendorFilter';
import Context from '../../../../context/context';

const DayByDayHeader = () => {

    const { ReportsReducers: { BreakfastList, LunchList, DinnerList, SnacksList, SupperList }, UserHomeReducers: { cafeteriaId }, ProfileReducers: { loginState } } = useSelector((state) => state)
    
   
    const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''

    const dispatch = useDispatch();
    const getDate = getCurDate();
    const [tabVal, setTabVal] = useState(1);
    const brHead = Object.keys(BreakfastList[0]?.[0] || {}) 
    const dnHead = Object.keys(LunchList[0]?.[0] || {}) 
    const lnHead = Object.keys(DinnerList[0]?.[0] || {}) 
    const snHead = Object.keys(SnacksList[0]?.[0] || {}) 
    const erHead = Object.keys(SupperList[0]?.[0] || {}) 

    const brRow = BreakfastList[0]?.map(d=> Object.values(d)) || []
    const dnRow = LunchList[0]?.map(d=> Object.values(d)) || []
    const lnRow = DinnerList[0]?.map(d=> Object.values(d)) || []
    const snRow = SnacksList[0]?.map(d=> Object.values(d)) || []
    const erRow = SupperList[0]?.map(d=> Object.values(d)) || []
    const { state, setState } = useContext(Context);
    const [vendorState, setVendorState] = useState('')

    const data = [
        ["", "BreakFast", ""],
        brHead,
        ...brRow,
        ["", "Dinner", ""],
        dnHead,
        ...dnRow,
        ["", "Early morning snacks", ""],
        lnHead,
        ...lnRow,
        ["", "Lunch", ""],
        snHead,
        ...snRow,
        ["", "Snacks", ""],
        erHead,
        ...erRow,
      ];

    // useEffect(() => {
    //     setVendorState('')
    // }, [cafeteriaId])

    useEffect(() => {
        if (tabVal === 2) {
            weekFunc('null')
        } else {
            todayFunc('null')
        }
        // todayFunc()
        // weekFunc()
        setVendorState('')
    }, [cafeteriaId])

    useEffect(() => {
        if (tabVal === 2) {
            weekFunc(vendorState)
        } else {
            todayFunc(vendorState)
        }
        // todayFunc()
        // weekFunc()
    }, [vendorState])

    const todayFunc = (vendorState) => {
        setTabVal(1);
        let Bdata = {
            "Action": "GetDayByDay",
            "Mela": "Breakfast",
            "Date": getDate,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getBreakfastDayByDayOrderWatcher(Bdata))

        let Ldata = {
            "Action": "GetDayByDay",
            "Mela": "Lunch",
            "Date": getDate,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getLunchDayByDayOrderWatcher(Ldata))

        let Sdata = {
            "Action": "GetDayByDay",
            "Mela": "Snacks",
            "Date": getDate,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getSnacksByDayOrderWatcher(Sdata))

        let Ddata = {
            "Action": "GetDayByDay",
            "Mela": "Dinner",
            "Date": getDate,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getDinnerByDayOrderWatcher(Ddata))

        let SRdata = {
            "Action": "GetDayByDay",
            "Mela": "Early morning snacks",
            "Date": getDate,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getSupperByDayOrderWatcher(SRdata))

        if (cafeteriaId.includes("4")) {
            // normal guest

            let BNdata = {
                "Action": "GetDayByDay",
                "Mela": "Breakfast(NormalGuest)",
                "Date": getDate,
                "NDIVISIONID": cafeteriaId,
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getNormalGuestBreakfastDayByDayOrderWatcher(BNdata))
    
            let LNdata = {
                "Action": "GetDayByDay",
                "Mela": "Lunch(NormalGuest)",
                "Date": getDate,
                "NDIVISIONID": cafeteriaId,
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getNormalGuestLunchDayByDayOrderWatcher(LNdata))
    
            let SNdata = {
                "Action": "GetDayByDay",
                "Mela": "Snacks(NormalGuest)",
                "Date": getDate,
                "NDIVISIONID": cafeteriaId,
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getNormalGuestSnacksByDayOrderWatcher(SNdata))
    
            let DNdata = {
                "Action": "GetDayByDay",
                "Mela": "Dinner(NormalGuest)",
                "Date": getDate,
                "NDIVISIONID": cafeteriaId,
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getNormalGuestDinnerByDayOrderWatcher(DNdata))
    
            let SRNdata = {
                "Action": "GetDayByDay",
                "Mela": "Early morning snacks(NormalGuest)",
                "Date": getDate,
                "NDIVISIONID": cafeteriaId,
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getNormalGuestSupperByDayOrderWatcher(SRNdata))
    
            // vip guest
    
            let BVdata = {
                "Action": "GetDayByDay",
                "Mela": "Breakfast(VIPGuest)",
                "Date": getDate,
                "NDIVISIONID": cafeteriaId,
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getVipGuestBreakfastDayByDayOrderWatcher(BVdata))
    
            let LVdata = {
                "Action": "GetDayByDay",
                "Mela": "Lunch(VIPGuest)",
                "Date": getDate,
                "NDIVISIONID": cafeteriaId,
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getVipGuestLunchDayByDayOrderWatcher(LVdata))
    
            let SVdata = {
                "Action": "GetDayByDay",
                "Mela": "Snacks(VIPGuest)",
                "Date": getDate,
                "NDIVISIONID": cafeteriaId,
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getVipGuestSnacksByDayOrderWatcher(SVdata))
    
            let DVdata = {
                "Action": "GetDayByDay",
                "Mela": "Dinner(VIPGuest)",
                "Date": getDate,
                "NDIVISIONID": cafeteriaId,
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getVipGuestDinnerByDayOrderWatcher(DVdata))
    
            let SRVdata = {
                "Action": "GetDayByDay",
                "Mela": "Early morning snacks(VIPGuest)",
                "Date": getDate,
                "NDIVISIONID": cafeteriaId,
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getVipGuestSupperByDayOrderWatcher(SRVdata))
        }
    }

    const weekFunc = (vendorState) => {
        setTabVal(2);
        var curr = new Date();
        var first = curr.getDate() - curr.getDay();
        var last = first + 6;
        var weekStart = getCurDate(new Date(curr.setDate(first)))
        var weekEnd = getCurDate(new Date(curr.setDate(last)))
        let Bdata = {
            "Action": "GetWeek",
            "Mela": "Breakfast",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getBreakfastDayByDayOrderWatcher(Bdata))

        let Ldata = {
            "Action": "GetWeek",
            "Mela": "Lunch",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getLunchDayByDayOrderWatcher(Ldata))

        let Sdata = {
            "Action": "GetWeek",
            "Mela": "Snacks",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getSnacksByDayOrderWatcher(Sdata))

        let Ddata = {
            "Action": "GetWeek",
            "Mela": "Dinner",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getDinnerByDayOrderWatcher(Ddata))

        let SRdata = {
            "Action": "GetWeek",
            "Mela": "Early morning snacks",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getSupperByDayOrderWatcher(SRdata))

        if (cafeteriaId.includes("4")) {
            // normal guest

        let BNdata = {
            "Action": "GetDayByDay",
            "Mela": "Breakfast(NormalGuest)",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getNormalGuestBreakfastDayByDayOrderWatcher(BNdata))

        let LNdata = {
            "Action": "GetDayByDay",
            "Mela": "Lunch(NormalGuest)",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getNormalGuestLunchDayByDayOrderWatcher(LNdata))

        let SNdata = {
            "Action": "GetDayByDay",
            "Mela": "Snacks(NormalGuest)",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getNormalGuestSnacksByDayOrderWatcher(SNdata))

        let DNdata = {
            "Action": "GetDayByDay",
            "Mela": "Dinner(NormalGuest)",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getNormalGuestDinnerByDayOrderWatcher(DNdata))

        let SRNdata = {
            "Action": "GetDayByDay",
            "Mela": "Early morning snacks(NormalGuest)",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getNormalGuestSupperByDayOrderWatcher(SRNdata))

        // vip guest

        let BVdata = {
            "Action": "GetDayByDay",
            "Mela": "Breakfast(VIPGuest)",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getVipGuestBreakfastDayByDayOrderWatcher(BVdata))

        let LVdata = {
            "Action": "GetDayByDay",
            "Mela": "Lunch(VIPGuest)",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getVipGuestLunchDayByDayOrderWatcher(LVdata))

        let SVdata = {
            "Action": "GetDayByDay",
            "Mela": "Snacks(VIPGuest)",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getVipGuestSnacksByDayOrderWatcher(SVdata))

        let DVdata = {
            "Action": "GetDayByDay",
            "Mela": "Dinner(VIPGuest)",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getVipGuestDinnerByDayOrderWatcher(DVdata))

        let SRVdata = {
            "Action": "GetDayByDay",
            "Mela": "Early morning snacks(VIPGuest)",
            "WeekStart": weekStart,
            "WeekEnd": weekEnd,
            "NDIVISIONID": cafeteriaId,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getVipGuestSupperByDayOrderWatcher(SRVdata))
        }
    } 

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={2} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={7} xs={12} md={6} sm={6} sx={{ justifyContent: 'left' }}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <Button variant='contained' sx={{
                                height: '48px',
                                minWidth: '117px',
                                backgroundColor: tabVal === 1 ? 'primary.main' : 'grey.300',
                                '&:hover':{
                                backgroundColor: tabVal === 1 ? 'primary.main' : 'grey.300',
                                },
                                color: tabVal === 1 ? 'common.white' : 'common.black',
                            }} onClick={() => todayFunc(vendorState)} >
                                <Typography variant='t16_400' color='inherit'>Today</Typography>
                            </Button>

                            <Button variant='contained' sx={{
                                height: '48px',
                                minWidth: '117px',
                                backgroundColor: tabVal === 2 ? 'primary.main' : 'grey.300',
                                '&:hover':{
                                backgroundColor: tabVal === 2 ? 'primary.main' : 'grey.300',
                                },
                                color: tabVal === 2 ? 'common.white' : 'common.black',
                            }} onClick={() => weekFunc(vendorState)}>
                                <Typography variant='t16_400' color='inherit'>Week</Typography>
                            </Button>
                        </Stack>
                    </Grid>

                    {/* <Grid lg={3} xs={12} md={3} sm={5} sx={{ justifyContent: 'center', mt: '12px'}}>
                        <VendorFilter setVendorState={setVendorState}/>
                    </Grid>

                    <Grid lg={1} md={1}></Grid> */}

                    <Grid item lg={2.5} xs={6} md={3} sm={6} display='flex' justifyContent='flex-end' >
                        <Box sx={{ minWidth: '230px'}}>
                            <VendorFilter setVendorState={setVendorState} page='dayByDayOrder'/>
                        </Box>
                    </Grid>
                    <Grid item lg={2.5} xs={6} md={3} sm={12} display='flex' justifyContent='flex-end'>
                        <CSVLink style={{textDecoration: 'unset'}} data={data}><Button variant='contained' sx={{ height: '50px', minWidth: '200px' }} endIcon={<DoubleArrowIcon />}>
                            <Typography variant='t16_500'>Export to Excel</Typography>
                        </Button></CSVLink>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default DayByDayHeader;
import React, { useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card, Button } from "@mui/material";
import CommonHeader from '../../../components/admin/reports/commonHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getKioskUserHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import { TABLE_HEAD } from '../../../components/admin/reports/kioskUserHistory/table';
import { ExportCsv } from "@material-table/exporters";
import { SUBTABLE_HEAD } from '../../../components/admin/reports/kioskUserHistory/subTable';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import KioskUserHistoryTable from '../../../components/admin/reports/kioskUserHistory/table';
import KioskUserHistorySubTable from '../../../components/admin/reports/kioskUserHistory/subTable';

const KioskUserHistory = () => {
    const { ReportsReducers: { KioskUserHistoryList } } = useSelector((state) => state)

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [pageChange, setPageChange] = useState(false);
    const [page, setPage] = useState(1);

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = KioskUserHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        const data = {
            ACTION: "kioskUserHistorySearch",
            Fdate: fromDate,
            Tdate: toDate
        }
        dispatch(getKioskUserHistoryWatcher(data))
        setPage(1)
    }

    return (
        <>
            {!pageChange ?
                <Box p="24px">

                    <Breadcrumbs content={["Reports ", "User History"]} />

                    <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                        <Typography variant="t24_700">User History</Typography>
                    </Box>

                    <Box p='10px 0'>
                        <CommonHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={KioskUserHistoryList} columnData={TABLE_HEAD} />
                    </Box>

                    <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                        <KioskUserHistoryTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} setPageChange={setPageChange} />
                    </Card>

                </Box>

                :

                <>
                    <Box p="24px">
                        <Box p='20px 0' display='flex' justifyContent='right' >
                            <Button sx={{ mr: '15px', minWidth: '100px', }} variant='contained' onClick={() => setPageChange(false)} >
                            <Typography variant='t16_500'>
                                Back
                                </Typography>
                            </Button>
                            <Button variant='contained' sx={{ height: '50px', minWidth: '150px', mr: '15px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(SUBTABLE_HEAD, KioskUserHistoryList, "Delivery History")}>
                                <Typography variant='t16_500'>Export to Excel</Typography>
                            </Button>

                        </Box>
                        <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                            <KioskUserHistorySubTable />
                        </Card>
                    </Box>
                </>

            }
        </>
    )
}

export default KioskUserHistory;
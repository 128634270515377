import { CREATE_MENU_MASTER_WATCHER, UPDATE_MENU_MASTER_WATCHER, DELETE_MENU_MASTER_WATCHER, GET_MENU_MASTER_WATCHER, MENU_MASTER_LIST, GET_MENU_DROPDOWN_WATCHER, GET_MEAL_DROPDOWN_WATCHER, MEAL_DROPDOWN_LIST, MENU_DROPDOWN_LIST } from "../../actionTypes/admin";

// Worker triggering actionCreators

export function getMenuMasterWatcher(data) {
    return { type: GET_MENU_MASTER_WATCHER, payload: data };
}

export function getMealDropdownWatcher() {
    return { type: GET_MEAL_DROPDOWN_WATCHER };
}

export function getMenuDropdownWatcher(data) {
    return { type: GET_MENU_DROPDOWN_WATCHER, payload: data };
}

export function createMenuMasterWatcher(data, setOpen) {
    return { type: CREATE_MENU_MASTER_WATCHER, payload: data , setOpen};
}

export function updateMenuMasterWatcher(id, data) {
    return { type: UPDATE_MENU_MASTER_WATCHER, payload: data, id };
}

export function deleteMenuMasterWatcher(id, data) {
    return { type: DELETE_MENU_MASTER_WATCHER, payload: data, id };
}


// Redux state changing actionCreators
export function menuMasterList(data) {
    return { type: MENU_MASTER_LIST, payload: data };
}

export function mealDropdownList(data) {
    return { type: MEAL_DROPDOWN_LIST, payload: data };
}

export function menuDropdownList(data) {
    return { type: MENU_DROPDOWN_LIST, payload: data };
}
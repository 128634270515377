import React, { useEffect, useState } from 'react'
import { Typography, Card, Grid, Button, InputAdornment } from "@mui/material";
import ContractorManagementTable from '../../../components/admin/userManagement/contractor';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CreationDialog from '../../../components/dialog';
import ContractorCreation from '../../../components/admin/userManagement/contractor/contractorCreation';
import SearchField from '../../../components/overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import { ExportCsv } from "@material-table/exporters";
import { useSelector } from 'react-redux';

const table_head = [
    { id: 'NID', title: 'ID Number', field: 'NID' },
    { id: 'cUserName', title: 'User Name', field: 'cUserName' },
    { id: 'CCONTRACTORID', title: 'Contractor ID', field: 'CCONTRACTORID' },
    { id: 'CCONTRACTORNAME', title: 'Contractor Name', field: 'CCONTRACTORNAME' },
    { id: 'CCONTRACTORDESC', title: 'Contractor Desc', field: 'CCONTRACTORDESC' },
    { id: 'CCONTRACTORADDRESS', title: 'Contractor Address', field: 'CCONTRACTORADDRESS' },
    { id: 'CMOBILENUMBER', title: 'Mobile Number', field: 'CMOBILENUMBER' },
    { id: 'CEMAILID', title: 'Email ID', field: 'CEMAILID' },
    { id: 'divisionId', title: 'Division', field: 'cDivisionName' },
];

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const ContractorManagementTemp = () => {

    const [open, setOpen] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [eventVal, setEventVal] = useState('')
    const [page, setPage] = useState(1);

    const { ContractorReducers: { contractorList } } = useSelector((state) => state)

    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = contractorList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });
        setSearchVal(filteredRows);
        setPage(1)
    }

    useEffect(()=>{
        handleSearch(eventVal);
    },[contractorList])

    return (
        <>
            <Card style={{ boxShadow: (theme)=> `0px 12px 23px ${theme.palette.grey[200]}` }}>
                <Grid container spacing={3} sx={{ padding: '30px', pb: '20px' }} >
                    {/* <Grid item lg={true} md={12} sm={12} xs={12} sx={{ justifyContent: 'left' }}>
                        <div>
                            <Typography variant='t20_600'>Contractor Management </Typography>
                        </div>
                    </Grid> */}
                    <Grid item lg={6} xs={12} md={6} sm={5} sx={{ justifyContent: 'right' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px'
                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            onChange={handleSearch}
                            value={searchText}
                        />
                    </Grid>
                    <Grid item lg={3} xs={12} md={3} sm={3.5} sx={{ justifyContent: { lg: 'right', xs: 'center' } }} >
                        <Button variant='contained' fullWidth sx={{ height: '50px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(table_head, contractorList, "Exported file")}>
                            <Typography variant='t16_500'>Export to Excel</Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3.5} xs={12} >
                        <Button variant='contained' fullWidth endIcon={<DoubleArrowIcon />} sx={{ height: '50px' }} onClick={() => setOpen(true)} >
                        <Typography variant='t16_500' >
                            Add Contractor
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <ContractorManagementTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                    </Grid>
                </Grid>
            </Card>
            <CreationDialog open={open} setOpen={setOpen} >
                <ContractorCreation setOpen={setOpen} />
            </CreationDialog >
        </>
    )
}

export default ContractorManagementTemp;
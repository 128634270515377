import PropTypes from "prop-types";
// material
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
} from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";

// ----------------------------------------------------------------------

SpecialGuestHistoryTableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
};

export default function SpecialGuestHistoryTableHead({
  order,
  orderBy,
  headLabel,
  onRequestSort,
  action,
  invoice
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {/* <TableCell align='left'>
        <Typography variant='t14_400'>
          #
        </Typography>
      </TableCell> */}
        {headLabel.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.alignRight ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                //   hideSortIcon
                active={true}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={SwapVertIcon}>
                <Typography variant="t14_400">{headCell.label}</Typography>
                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
        {invoice && (
          <TableCell align="right" sx={{ minWidth: "115px" }}>
            <Typography variant="t14_400">Action</Typography>
          </TableCell>
        )}
        {action && (
          <TableCell align="right" sx={{ minWidth: "115px" }}>
            <Typography variant="t14_400">Action</Typography>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

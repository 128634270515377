import { Badge, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { getNotificationDetailsWatcher, updateNotificationDetailsWatcher } from "../../redux/actionCreators/kiosk";
import { useDispatch, useSelector } from "react-redux";

const NotificationAction = ({ setOpenNotification }) => {

    const { KioskRoleReducers: { notificationCount } } = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(() => {
        let data = {
            "ACTION": "Select"
        }
        // let dataCount = {
        //     "ACTION": "Count"
        // }
        dispatch(getNotificationDetailsWatcher(data))
        // dispatch(getNotificationCountWatcher(dataCount))
    }, [dispatch])

    const handleUpdate = () => {
        setOpenNotification(true)
        if(notificationCount.length > 0)
        {
            let data = {
                "ACTION": "update",
                "nID": notificationCount.map((data) => { return ( data.nID )})
            }
            dispatch(updateNotificationDetailsWatcher(data))
        }
        
    }

    return (
        <>
            <IconButton
                onClick={handleUpdate}
                color='primary'
            >
                {
                    notificationCount.length > 0 ?
                        <Badge badgeContent={notificationCount.length} color="primary">
                            <NotificationsNoneOutlinedIcon />
                        </Badge>
                        :
                            <NotificationsNoneOutlinedIcon />
                }

            </IconButton>
        </>
    )
}
export default NotificationAction;
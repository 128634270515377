import http from "../../http-common";

class currentOrderService {
    currentOrder(data) {
        return http.post(`/user/currentOrder`, data);
    }

    orderHistory(data) {
        return http.post(`/user/orderHistory`, data);
    }

    orderBooking(data) {
        return http.post(`/user/orderBooking`, data);
    }

    orderBookingPaytm(data) {
        return http.post(`/user/orderBookingPaytm`, data);
    }

    orderBookingCashfree(data) {
        return http.post(`/user/orderBookingCashfree`, data);
    }

    orderBookingCashfreeNew(data) {
        return http.post(`/user/orderBookingCashfreeNew`, data);
    }

    payment(data) {
        return http.post(`/user/payment`, data);
    }

    insertFeedBack(data) {
        return http.post(`/user/insertFeedBack`, data);
    }
    
    rateFeedBack(id, data) {
        return http.post(`/user/rateFeedBack/${id}`, data);
    }

    cancelOrder(data) {
        return http.post("/user/orderCancel", data);
    }

    cancelOrderPaytm(data) {
        return http.post("/user/orderCancelPaytm", data);
    }

    cancelOrderCashfree(data) {
        return http.post("/user/orderCancelCashfree", data);
    }

    cancelOrderCashfreeNew(data) {
        return http.post("/user/orderCancelCashfreeNew", data);
    }

    cancelOrderPaynimo(data) {
        return http.post("/user/orderCancelPaynimo", data);
    }

    cancelOrderHistory(data) {
        return http.post("/user/orderCancel", data);
    }

    verifyPaytm(data) {
        return http.post("/user/verifyRazorpay", data);
    }

    cancelOrderIntern(data) {
        return http.post("/user/orderCancelIntern", data);
    }

    instaMojo(data) {
        return http.post(`https://www.instamojo.com/api/1.1/payment-requests/`, data);
    }

    orderBookingPaynimo(data) {
        return http.post(`/user/orderBookingPaynimo`, data);
    }

    verifyPaynimo(data) {
        return http.post(`/user/verifyPaynimo`, data);
    }
}

export default new currentOrderService();
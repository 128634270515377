import { UPDATE_CURRENT_ORDER, UPDATE_ORDER_HISTORY, UPDATE_DELIVERED_ORDER, UPDATE_NEXT_DAY_ORDER, UPDATE_NEXT_WEEK_ORDER, UPDATE_UPCOMING_ORDER } from "../../actionTypes/currentOrder";

const intialState = {
  currentOrder: [],
  orderHistory: [],
  nextdayOrder: [],
  nextweekOrder: [],
  deliveredOrder: [],
  upcomingOrder: []
};

export default function CurrentOrderReducers(state = intialState, action) {
  switch (action.type) {

    case UPDATE_CURRENT_ORDER:
      return { ...state, currentOrder: action.payload };

    case UPDATE_ORDER_HISTORY:
      return { ...state, orderHistory: action.payload };

    case UPDATE_NEXT_DAY_ORDER:
      return { ...state, nextdayOrder: action.payload };

    case UPDATE_NEXT_WEEK_ORDER:
      return { ...state, nextweekOrder: action.payload };

    case UPDATE_DELIVERED_ORDER:
      return { ...state, deliveredOrder: action.payload };

    case UPDATE_UPCOMING_ORDER:
      return { ...state, upcomingOrder: action.payload };

    default:
      return state;
  }
}

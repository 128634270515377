import React, { useState } from 'react';
import { Typography, Card, Grid, ToggleButton, Box, Stack } from "@mui/material";
// import CardTemplate from "./cardTemp"
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import StyledToggleButtonGroup from '../../../overrides/toggleButton';
import TableIndex from './user';
import TableConGuest from './conGuest';
import TotQtyTableIndex from './TotalQuantity';
import { Masonry } from '@mui/lab';

const MealCount = ({ dayTabVal }) => {

    const { FoodVendorReducers: { DashboardList } } = useSelector((state) => state)
    // const dispatch = useDispatch();

    // employee
    const userVal = (DashboardList.length && DashboardList[dayTabVal === 1 ? 1 : 6]) || []
    const userSessionVal = (DashboardList.length && DashboardList[dayTabVal === 1 ? 2 : 7]) || []
    // contractor
    const contractorVal = (DashboardList.length && DashboardList[dayTabVal === 1 ? 3 : 8]) || []
    // guest
    const guestVal = (DashboardList.length && DashboardList[dayTabVal === 1 ? 16 : 18]) || []
    const guestSessionVal = (DashboardList.length && DashboardList[dayTabVal === 1 ? 17 : 19]) || []
    // total count
    const totalVal = (DashboardList.length && DashboardList[dayTabVal === 1 ? 0 : 5]) || []
    const sessionTotalVal = (DashboardList.length && DashboardList[dayTabVal === 1 ? 10 : 11]) || []
    // spl guest
    const specialGuestVal = (DashboardList.length && DashboardList[dayTabVal === 1 ? 12 : 14]) || []
    const splGuestSessionVal = (DashboardList.length && DashboardList[dayTabVal === 1 ? 13 : 15]) || []

    const [alignment, setAlignment] = useState(1);

    const handleAlignment = (event, newAlignment) => {
        if (!newAlignment) {
            return
        }
        setAlignment(newAlignment);
    };

    // const cardVal = alignment === 1 ? userVal : (alignment === 2 ? contractorVal : guestVal)

    const cardVal = alignment === 1 ? userVal : (alignment === 2 ? contractorVal : (alignment === 3 ? guestVal : specialGuestVal) )

    return (
        <>
            <Card sx={{ border: (theme) => `1px solid ${theme.palette.grey[200]}`, boxShadow: (theme) => `0px 15px 23px ${theme.palette.grey[200]}`, margin: '30px' }}>
                <Box margin='30px 0 0 30px'>
                    <Typography variant='t20_600'>{dayTabVal === 1 ? `Today's Order` : `Next Day's Order`}</Typography>
                    <Typography variant='t20_600' color='primary' fontFamily='inter' pl='10px'>
                        {totalVal.length && totalVal[0].TotalQuantity}
                    </Typography>
                </Box>

                <Grid container spacing={3} sx={{ marginBottom: '35px', marginTop: '10px' }} >
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'left' }}>
                        <Box sx={{ width: '800px', marginLeft: '35px', marginRight: '30px' }} >
                            <TotQtyTableIndex tblData={sessionTotalVal} />
                        </Box>
                    </Grid>
                </Grid>

                <Stack direction={{ sm: 'row', xs: 'column' }}>
                    <div style={{ margin: '30px', marginBottom: 0 }}>
                        <Paper
                            elevation={0}
                            sx={{
                                display: 'flex',
                                border: (theme) => `1px solid ${theme.palette.divider}`,
                                flexWrap: 'wrap',
                                width: 'max-content'
                            }}
                        >
                            <StyledToggleButtonGroup
                                size="medium"
                                value={alignment}
                                exclusive
                                onChange={handleAlignment}
                                aria-label="text alignment"
                            >
                                <ToggleButton value={1} aria-label="left aligned" sx={{ width: '100px' }}>
                                    <Typography variant='t15_600'>Employee</Typography>
                                </ToggleButton>
                                <ToggleButton value={2} aria-label="centered">
                                    <Typography variant='t15_600'>Contractor</Typography>
                                </ToggleButton>
                                <ToggleButton value={3} aria-label="centered" sx={{ width: '100px' }}>
                                    <Typography variant='t15_600'>Guest</Typography>
                                </ToggleButton>
                                <ToggleButton value={4} aria-label="centered" >
                                    <Typography variant='t15_600'>Special Guest</Typography>
                                </ToggleButton>

                            </StyledToggleButtonGroup>
                        </Paper>
                    </div>
                </Stack>

                {alignment !== 1 && alignment !== 4 && alignment !== 3 &&
                    <Box sx={{ padding: '35px', width: '870px' }}>
                        <TableConGuest alignment={alignment} tblData={cardVal} />
                    </Box>
                }

                {alignment === 1 &&
                    // <Grid container spacing={3} sx={{ marginBottom: '35px', marginTop: '10px' }} >
                        <Box sx={{m:'20px 10px 10px 35px'}}>
                        <Masonry columns={{xs: 1, lg: 2}} spacing={3}>
                        {userVal.map((m, i) => {
                            const tblData = userSessionVal?.filter(d => d.cMealName === m.cMealName)
                            return <Box >
                                    <TableIndex cardData={m} tblData={tblData} />
                                </Box>
                        })}
                        </Masonry>
                        </Box>
                    // </Grid>
                }

                {alignment === 4 &&
                    <Box sx={{ m: '20px 10px 10px 35px' }}>
                        <Masonry columns={{ xs: 1, lg: 2 }} spacing={3}>
                            {specialGuestVal.map((m, i) => {
                                const tblData = splGuestSessionVal?.filter(d => d.cMealName === m.cMealName)
                                return (
                                    <Box>
                                        <TableIndex cardData={m} tblData={tblData} />
                                    </Box>
                                )
                            })}
                        </Masonry>
                    </Box>
                }

{alignment === 3 &&
                    <Box sx={{ m: '20px 10px 10px 35px' }}>
                        <Masonry columns={{ xs: 1, lg: 2 }} spacing={3}>
                            {guestVal.map((m, i) => {
                                const tblData = guestSessionVal?.filter(d => d.cMealName === m.cMealName)
                                return (
                                    <Box>
                                        <TableIndex cardData={m} tblData={tblData} />
                                    </Box>
                                )
                            })}
                        </Masonry>
                    </Box>
                }
            </Card>
        </>

    )
}

export default MealCount;
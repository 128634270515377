import http from "../../http-common";

class ContractorRoleServices {

    getContractorBookingHistory(data) {
        return http.post("/contractor/contractorOrderBooking", data);
    }

    getContractorDashboard(data) {
        return http.post("/contractor/dashboard", data);
    }

    createContractorOrder(data) {
        return http.post("/contractor/addContractorBook", data);
    }
    
    cancelContractorOrder(id, data) {
        return http.post(`/contractor/cancelOrder/${id}`, data); //contractor/cancelGuestOrder
    }

    getCRoleSwipeHistory(data) {
        return http.post("/contractor/swipeHistory", data);
    }

    
}

export default new ContractorRoleServices();
import http from "../../http-common";

class GuestManagementService {

    getAll(data) {
        return http.post("/admin/getGuestManagement", data);
    }
    get(id) {
        return http.get(`/admin/guestManagement/${id}`);
    }
    create(data) {
        return http.post("/admin/addGuestManagement", data);
    }
    update(id, data) {
        return http.put(`/admin/guestManagement/${id}`, data);
    }
    delete(id, data) {
        return http.delete(`/admin/guestManagement/${id}`, data);
    }
    addUser(data) {
        return http.post("/contractor/addGuestUser", data); ///guest/addUser
    }
    getAllConUser() {
        return http.post("/contractor/selectAllGuestUser"); ///guest/selectAllUser
    }
    getViewConUser(data) {
        return http.post("/contractor/viewGuestUser", data); ///guest/viewUser
    }
    updateViewConUser(data) {
        return http.post("/contractor/updateGuestUser", data); ///guest/updateUser
    }
    deleteViewConUser(data) {
        return http.post("/contractor/deleteGuestUser", data); ///guest/deleteUser
    }

}

export default new GuestManagementService();
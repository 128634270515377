import React from 'react'
import { Box, Card, Chip, Stack } from '@mui/material'
import Breadcrumbs from "../../components/breadcrumbs";
// import Employee from '../../components/swipeScreen/employee'
import Contractor from '../../components/swipeScreen';
import { useSelector } from 'react-redux';
import OldSwipes from '../../components/swipeScreen/oldSwipes';

export default function SwipeDashboardContractor() {

    const { ProfileReducers: { loginState } } = useSelector((state) => state)

    const counterName = loginState[0]?.counterName || false

    return (
        <>
            <Box p="24px">

                <Stack direction='row' spacing={3} >
                    <Breadcrumbs content={[]} />

                    {counterName && <Chip label={counterName} />}

                    {/* <Box width='100%' display='flex' justifyContent='end' alignItems='center'>
                        <OldSwipes />
                    </Box> */}
                </Stack>

                <Card sx={{ borderRadius: "6px 6px 0px 0px", padding: '20px', mt: '30px', width: '100%' }}>
                    <Contractor />
                </Card>

            </Box>
        </>
    )
}

import { Autocomplete, Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect } from "react";
// import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CssTextField from "../../components/overrides/searchField";
import { getDivisionWatcher } from "../../redux/actionCreators/admin/employeeManagement";
import { updateCafeteriaId } from "../../redux/actionCreators/user/home";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useState } from "react";
import Context from "../../context/context";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const Division = () => {
  const dispatch = useDispatch();

  const {
    EmployeeReducers: { divisionMaster },
    UserHomeReducers: { cafeteriaId },
  } = useSelector((state) => state);
  const [divS, setDiv] = useState([])
  const { state, setState } = useContext(Context)

  useEffect(() => {
    if (!divisionMaster.length) {
      dispatch(getDivisionWatcher({ "ACTION": "SELECT" }));
    }
  }, [dispatch, divisionMaster.length]);

  useEffect(() => {
    if (divisionMaster.length) {
      const getLocal = JSON.parse(localStorage.getItem('division')) || []
      let obj = []
      if (getLocal.length) {
        obj = getLocal
      } else {
        obj = [divisionMaster[0]]
        localStorage.setItem('division', JSON.stringify(obj))
      }
      const getId = obj.map(d => d.NID).join()
      dispatch(updateCafeteriaId(getId));
      setDiv(obj)
    }
  }, [divisionMaster, dispatch]);

  const handleChange = (e, value) => {
    setState('')
    const get = value.map(d => d.NID).join()
    dispatch(updateCafeteriaId(get));
    setDiv(value)
    localStorage.setItem('division', JSON.stringify(value))
  };

  // const getName = () => {
  //   const { cDivisionName } = divisionMaster.find((d) => d.NID === cafeteriaId) || {};
  //   return cDivisionName || null;
  // };

  // const newCafe = divisionMaster.map((d) => ({ ...d, label: d.cDivisionName }));
  return (
    <Box>
      {/* <Autocomplete
        disablePortal
        value={getName()}
        id="combo-box-demo"
        options={newCafe}
        sx={{ width: 173 }}
        onChange={handleChange}
        isOptionEqualToValue={(data) => data.NID === cafeteriaId}
        renderInput={(params) => (
          <CssTextField {...params} label='Division' size='small' placeholder="Division" />
        )}
      /> */}

      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={divisionMaster}
        value={divS}
        // disableCloseOnSelect
        onChange={handleChange}
        getOptionLabel={(option) => option.cDivisionName}
        isOptionEqualToValue={(data, option) => data.NID === option.NID}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.cDivisionName}
          </li>
        )}
        sx={{ minWidth: 173 }}
        renderInput={(params) => (
          <CssTextField {...params} label='Division' size='small' placeholder="Division" />
        )}
      />
    </Box>
  );
};

export default Division;

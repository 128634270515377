import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import Scrollbar from '../../../Scrollbar';
// material
import {
    Table,
    TableBody,
    TableContainer,
    Pagination,
    Box,
    Typography,
    TableCell,
    TableRow
} from '@mui/material';
import CommonTableHead from '../../../foodVendor/reports/commonTableHead.js';
import { getRatingFeedbackWatcher } from '../../../../redux/actionCreators/admin/reports';
import { useDispatch, useSelector } from 'react-redux';
import FeedbackTableRow from './tableRow';
import Context from '../../../../context/context.js';

export const TABLE_HEAD = [
    { id: 'UserName', label: 'User Name', alignRight: false, title: 'User Name', field: 'EmployeeName' },
    { id: 'MenuName', label: 'Menu Name', alignRight: false, title: 'Menu Name', field: 'MenuName' },
    { id: 'Command', label: 'Comment', alignRight: false, title: 'comment', field: 'Command' },
    { id: 'Reply', label: 'Reply', alignRight: false, title: 'Reply', field: 'Reply' },
    { id: 'FeedDate', label: 'Feedback Date', alignRight: false, title: 'Feedback Date', field: 'FeedDate' },
    { id: 'vendorName', label: 'Vendor Name', alignRight: false, title: 'Vendor Name', field: 'vendorName' },
    { id: 'action', label: 'Action', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const FeedbackTable = ({ searchVal, searchText, page, setPage, vendorState  }) => {

    const { ReportsReducers: { RatingFeedbackList }, UserHomeReducers: { cafeteriaId }, ProfileReducers: { loginState }, } = useSelector((state) => state)
  
    const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''

    let firstIndex = 0;

    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage] = useState(5);
    const { state } = useContext(Context);

    useEffect(() => {
        let data = {
            "Action": "adminFeedList",
            "NDIVISIONID": cafeteriaId,
            // "CVENDORID": vendorId === '' ? null : vendorId
            CVENDORID: nRoleID === 3 ? String(vendorId) : 'null' 
        }
        dispatch(getRatingFeedbackWatcher(data))
    }, [dispatch, cafeteriaId])

    // useEffect(() => {
    //     if (cafeteriaId) {
    //     let data = { "ACTION": "adminFeedList", "NDIVISIONID": cafeteriaId }
    //     dispatch(getRatingFeedbackWatcher(data))
    //     }
    // }, [dispatch, cafeteriaId])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const tableData = searchText ? searchVal : RatingFeedbackList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{overflow:'unset'}} >
                        <Table>
                            <CommonTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    .map((f, i) => {
                                        return (
                                            <FeedbackTableRow key={i} rowData={f} index={i} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default FeedbackTable;
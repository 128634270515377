import { UPDATE_FEEDBACK_LIST } from "../../actionTypes/user";

const intialState = {
  feedBackList: []
};

export default function UserFeedBackReducers(state = intialState, action) {
  switch (action.type) {

    case UPDATE_FEEDBACK_LIST:
      return { ...state, feedBackList: action.payload };

    default:
      return state;
  }
}

import React from 'react';
import { useState } from 'react';
import Scrollbar from '../../../Scrollbar';
import CommonTableHead from '../../../foodVendor/reports/commonTableHead.js';
import { useSelector } from 'react-redux'
// material
import {
    Table,
    TableBody,
    TableContainer,
    Pagination,
    Box,
    TableCell,
    TableRow,
    Typography,
    Button
} from '@mui/material';
import SwipeTableRow from './tableRow';

const SWIPE_TABLE_HEAD = [
    { id: 'EmployeeName', label: 'EmployeeName', alignRight: false },
    { id: 'Username', label: 'Username', alignRight: false },
    { id: 'menuName', label: 'MenuCode', alignRight: false },
    { id: 'dOrderDate', label: 'OrderDate', alignRight: false },
    { id: 'deliveredDate', label: 'DeliveredDate', alignRight: false },
];

const UN_TABLE_HEAD = [
    { id: 'EmployeeName', label: 'EmployeeName', alignRight: false },
    { id: 'Username', label: 'Username', alignRight: false },
    { id: 'menuName', label: 'MenuCode', alignRight: false },
    { id: 'dOrderDate', label: 'OrderDate', alignRight: false },
];

const SwipeModalTable = ({setOpen, type}) => {

    const { OrderSummaryReducers: { swipeOrderSummaryData } } = useSelector((state) => state)

    let firstIndex = 0;

    // const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [order] = useState('asc');
    const [orderBy] = useState('name');
    const [rowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const isUserNotFound = swipeOrderSummaryData?.length === 0;

    return (
        <>
            <Box >
            <Box p='20px 0' display='flex'  >

                <Typography variant='t20_600'>{type ==='swipe' ? 'Swiped History' : 'UnSwiped History'}</Typography>

                {/* <Box p='20px 0' > */}
                    <Button sx={{ minWidth: '100px', ml:'auto'}} variant='contained' onClick={() => setOpen(false)} >
                <Typography variant='t16_400'>Back</Typography>
                        
                    </Button>
                    {/* </Box> */}
                </Box>
                <Scrollbar>
                    <TableContainer sx={{  overflow: 'unset' }}>
                        <Table>
                            <CommonTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={type === 'swipe' ? SWIPE_TABLE_HEAD : UN_TABLE_HEAD}
                            />
                            <TableBody>
                                {swipeOrderSummaryData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    // .slice(page * 5, page * 5 + 5)
                                    .map((m, i) => {
                                        return (
                                            <SwipeTableRow key={i} rowData={m} index={i} type={type}/>
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(swipeOrderSummaryData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default SwipeModalTable;
import React, { useState } from 'react';
import { Button, Typography, Grid, Autocomplete, Box } from '@mui/material';
import CssTextField from '../../../overrides/longSearchField'
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { createMenuMasterWatcher } from '../../../../redux/actionCreators/admin/menuMaster';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
// import BlackButton from "../../../overrides/blackButton"
import FileUploadButton from "../../../overrides/fileUploadButton"
import CloseButton from '../../../overrides/closeButton';

export const Input = styled('input')({
    display: 'none',
});


function NewMenuMaster(props) {
    const dispatch = useDispatch()

    const { MenuMasterReducers: { mealDropdownList }, EmployeeReducers: { vendorName, divisionList }, UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)

    const type = (mealDropdownList.length && mealDropdownList[2]) || []

    const meal = (mealDropdownList.length && mealDropdownList[3]) || []

    const { setOpen } = props;
    const [imageName, setImageName] = useState('')
    // const [imageFile, setImageFile] = useState('')

    const MenuMasterSchema = Yup.object().shape({

        userType: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('User Type is required'),
        vendorId: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Vendor Name is required'),
        mealName: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Meal Name is required'),
        menuCode: Yup.string('Enter your User Name').min(1, 'Too Short!').max(100, 'Too Long!').required('Menu Code is required'),
        // emailId: Yup.string('Enter your EmailId').email('Email must be a valid email address').required('Email is required'),
        divisionId: Yup.string().required('Division Id is required'),
    });

    const formik = useFormik({
        initialValues: {
            userType: '',
            mealName: '',
            menuCode: '',
            menuDescription: '',
            menuIcon: '',
            vendorId: '',
            divisionId: '',
        },

        validationSchema: MenuMasterSchema,

        onSubmit: () => {
            let values = { ...formik.values }
            let data = {
                ACTION: "INSERT",
                nMealID: `${values.mealName}`,
                cMenuName: values.menuCode,
                cMenuDesc: values.menuDescription,
                cImage: values.menuIcon,
                nCreatedBy: values.userType,
                CVENDORID: `${values.vendorId}`,
                SDIVISIONID: cafeteriaId,
                NDIVISIONID: values.divisionId,
            }

            dispatch(createMenuMasterWatcher(data, setOpen))
        }
    });

    const onFileChange = (e) => {
        if (e) {
            var file = e.target.files[0];
            var name = e.target.files[0].name;
            setImageName(name);
            // setImageFile(file);
            var reader = new FileReader();
            reader.onloadend = function () {
                // setImageFile(reader.result)
                setFieldValue("menuIcon", reader.result)
            }
            reader.readAsDataURL(file);
        }
    }

    const handleCloseFunc = () => {
        setOpen(false)
    }

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>

                    <Box textAlign='center'>
                        <Typography variant='t20_600'
                            style={{ paddingBottom: '10px' }}>
                            New Items
                        </Typography>
                    </Box>


                    <Grid
                        container
                        style={{ padding: '30px' }}
                        spacing={3}
                        direction='row'
                    >

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                id="combo-box-demo"
                                name="divisionId"
                                fullWidth
                                options={divisionList}
                                size="medium"
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("divisionId", value.id);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Division"
                                    name='divisionId'
                                    required={true}
                                    error={Boolean(touched.divisionId && errors.divisionId)}
                                    helperText={touched.divisionId && errors.divisionId}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                // disablePortal
                                id="combo-box-demo"
                                name="vendorId"
                                fullWidth
                                options={vendorName.filter(d=> values.divisionId === d.nDivisionID)}
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("vendorId", value.id);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Select Vendor"
                                    name='vendorId'
                                    required={true}
                                    error={Boolean(touched.vendorId && errors.vendorId)}
                                    helperText={touched.vendorId && errors.vendorId}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                required={true}
                                // disablePortal
                                id="combo-box-demo"
                                name="userType"
                                fullWidth
                                options={type}
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("userType", value.id);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Select Type"
                                    name='userType'
                                    required={true}
                                    error={Boolean(touched.userType && errors.userType)}
                                    helperText={touched.userType && errors.userType}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                required={true}
                                disablePortal
                                id="combo-box-demo"
                                name="mealName"
                                fullWidth
                                options={meal.filter((a) => a.nMealApplicable === values.userType)}
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("mealName", value.id);
                                }}
                                getOptionLabel={(option) => option.name}
                                // error={Boolean(touched.mealName && errors.mealName)}
                                // helperText={touched.mealName && errors.mealName}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Select Meal"
                                    error={Boolean(touched.mealName && errors.mealName)}
                                    helperText={touched.mealName && errors.mealName}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                style={{}}
                                fullWidth={true}
                                label='Menu Code'
                                name='menuCode'
                                // color='primary'
                                type='text'
                                //variant='filled'
                                {...getFieldProps("menuCode")}
                                error={Boolean(touched.menuCode && errors.menuCode)}
                                helperText={touched.menuCode && errors.menuCode}

                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                style={{}}
                                fullWidth={true}
                                label='Menu Description'
                                name='menuDescription'
                                // color='primary'
                                type='text'
                                //variant='filled'
                                {...getFieldProps("menuDescription")}
                                error={Boolean(touched.menuDescription && errors.menuDescription)}
                                helperText={touched.menuDescription && errors.menuDescription}

                            />
                        </Grid>

                        <Grid
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            <label htmlFor="contained-button-file">
                                <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={onFileChange} />
                                <FileUploadButton
                                    variant="contained"
                                    component="span"
                                    fullWidth
                                    // onClick={imageFunction}
                                    endIcon={<FileUploadOutlinedIcon sx={{ color: 'common.black' }} />}
                                    type='button'
                                >
                                    <Typography variant='t16_600' color='grey.600'>
                                        {imageName ? imageName : 'Browse Icon'}
                                    </Typography>
                                </FileUploadButton >
                            </label>
                        </Grid>

                        <Grid container
                            display='flex'
                            flexDirection='row'
                            justifyContent='flex-end'
                            spacing={2}
                            sx={{ paddingTop: '30px' }}
                        >
                            <Grid item >
                                <CloseButton sx={{
                                    width: '120px',
                                    height: '42px',
                                    left: '0px',
                                    top: '0px',
                                }} variant='contained' type='button'
                                    onClick={handleCloseFunc}>
                                    <Typography variant='t14_400' color='common.black'> Cancel </Typography>
                                </CloseButton>
                            </Grid>
                            <Grid item >
                                <Button sx={{
                                    width: '120px',
                                    height: '42px',
                                    left: '0px',
                                    top: '0px',
                                    backgroundColor: 'common.Bblack',
                                    '&:hover': {
                                        backgroundColor: 'common.Bblack',
                                    }
                                }} variant='contained' color='primary' type='submit' onClick={handleSubmit}>
                                    <Typography variant='t14_400' color='inherit'>Submit</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </>
    );

}

export default NewMenuMaster;
import React from 'react';
import {
    TableCell,
    Typography,
    TableRow,
} from '@mui/material';
//import { base_url } from '../../../../http-common';

const Emp_ConTableRow = ({ rowData, index }) => {

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                <TableCell align="left">
                    <Typography variant='t14_600' >
                        {rowData.OrderDate}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Employee}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Contractor}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Guest}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    )
}

export default Emp_ConTableRow
import React, { useEffect, useState } from 'react'
import { getInvoice } from './common'
import Services from '../../../services/admin/reports'
import { useParams } from 'react-router-dom'
import { getGst, getGstOnly } from '../../../utils/gst'
import { inWords } from '../../../utils/common'
import { companyDetails } from '../../../utils/constants'
import { updateFalseLoader, updateOpenLoader } from '../../../redux/actionCreators/spinner'
import { useDispatch } from 'react-redux'
import { updateAlert } from '../../../redux/actionCreators/alert'

function Invoice() {
    // console.log('test');
    const { from, to, contractorID } = useParams()
    const [invoiceData, setInvoiceData] = useState({})
    const dispatch = useDispatch()

    const fetchFun = async () => {
        const data = {
            Active: 'ContractorInvoice',
            Fdate: from,
            Tdate: to,
            Nid: contractorID
        }
        try {
            dispatch(updateOpenLoader())
            const { data: { recordset } } = await Services.contractorInvoice(data)
            dispatch(updateFalseLoader())

            const gst = recordset[0].GstNo
            const nId = recordset[0].nID
            const bankname = recordset[0].bankName
            const accountname = recordset[0].accountName
            const accountno = recordset[0].bankAccountNo
            const ifsc = recordset[0].IFSC
            const branchname = recordset[0].branchName
            const companydetails = recordset[0].companyDetails
            const divisionName = recordset[0].cDivisionName

            let cEmployeeName = "";
            let cUserName = "";
            let nGst = 0
            let nGst1 = 0
            let Amount = 0
            let TotalAmount = 0
            let nID = "";
            let RupeesInwords = "";
            let fDateformat = "";
            let tDateformat = "";
            let info = "";
            let cVendorName = "";
            let accountName = "";
            let bankName = "";
            let bankAccountNo = "";
            let IFSC = ""
            const date = new Date();

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            let currentDate = `${day}-${month}-${year}`;

            // console.log('currentDate', String(year).slice(2, 4));
            
            let yearDiff_1 = date.getFullYear() + 1;

            let yearDiff = String(year).slice(2, 4) + "-" + String(yearDiff_1).slice(2, 4)

            if (recordset.length > 0) {
                info += "<tr>"
                    + "<td colspan='4' class='thick-border th'>Menu Name</td>"
                    + " <td colspan='4' class='thick-border th'> Menu Quantity</td>"
                    + " <td colspan='4' class='thick-border th'>Menu Amount</td>"
                    +
                    "</tr>";

                for (let i = 0; i < recordset.length; i++) {
                    cEmployeeName = recordset[i]["cEmployeeName"]
                    cUserName = recordset[i]["cUserName"]
                    nGst += getGstOnly(+recordset[i]["nToatlRate"]);
                    nGst1 = getGstOnly(+recordset[i]["nToatlRate"]);
                    Amount += recordset[i]["nToatlRate"]
                    TotalAmount += +recordset[i]["nToatlRate"] + +nGst1
                    nID = from.split('-')[2] + to.split('-')[2] + contractorID.padStart(6, '0')
                    RupeesInwords = inWords(`${TotalAmount}`);
                    info += "<tr>"
                        + "<td colspan='4' class='text-center bordery-0'>" + recordset[i]["cMenuName"] + "</td>"
                        + " <td colspan='4' class='text-center bordery-0'> " + recordset[i]["nQuantity"] + "</td>"
                        + " <td colspan='4' class='text-center bordery-0'> " + recordset[i]["nRate"] + "</td>"
                        +
                        "</tr>";
                    fDateformat = from
                    tDateformat = to
                    cVendorName += recordset[0].cVendorName
                    accountName += recordset[i]["accountName"]
                    bankName += recordset[i]["bankName"]
                    bankAccountNo += recordset[i]["bankAccountNo"]
                    IFSC += recordset[i]["IFSC"]
                }

                const obj = {
                    NO: divisionName + "/" + nID + "/" + yearDiff,
                    DATE: currentDate,
                    CUSTOMER_USERNAME: cEmployeeName,
                    CUSTOMER_USERID: cUserName,
                    CUSTOMER_NAME: accountname,
                    CUSTOMER_ADDRESS: companydetails,
                    CUSTOMER_GSTIN: gst === null ? "-" : gst,
                    FROM: fDateformat,
                    TO: tDateformat,
                    FOOD_AMOUNT: Amount,
                    SERVICE_AMOUNT: 0,
                    TAXABLE: Amount + 0,
                    SGST: nGst / 2,
                    CGST: nGst / 2,
                    SUBTOTAL: Amount,
                    ROUNDOFF: 0,
                    TOTAL: TotalAmount,
                    TOTAL_AS_TEXT: RupeesInwords,
                    INFO: info,
                    BANKNAME: bankname === null ? "-" : bankname,
                    ACCOUNTNAME: accountname === null ? "-" : accountname,
                    ACCOUNTNO: accountno === null ? "-" : accountno,
                    IFSCNO: ifsc === null ? "-" : ifsc,
                    BRANCHNAME: branchname === null ? "-" : branchname,
                }
                setInvoiceData(obj)

            }
        } catch (e) {
            dispatch(updateFalseLoader())
            dispatch(updateAlert('Something went wrong', 'error'))
        }

    }

    useEffect(() => {
        fetchFun()
    }, [])

    useEffect(()=>{
        if(Object.keys(invoiceData).length)
        window.print();
    },[invoiceData])

    return (
        <div dangerouslySetInnerHTML={{ __html: getInvoice(invoiceData) }} ></div>
    )
}

export default Invoice
import React, { useState } from 'react';
import Scrollbar from '../../../Scrollbar';
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Pagination,
    Box
} from '@mui/material';
import SaleReportTableHead from '../../../foodVendor/reports/saleReport/saleReportTableHead';
// import { getSaleMenuWatcher, getSaleReportWatcher } from '../../../../redux/actionCreators/admin/reports';
import SaleReportTableRow from './tableRow';
import { useSelector } from 'react-redux'
import SaleTableFooter from './tableFooter';

export const TABLE_HEAD_ADMIN = (name, divisionName) => {

    return [
        { id: name, label: name, alignRight: false, title: name, field: name },
        { id: 'rate', label: 'Rate', alignRight: false, title: 'Rate', field: 'Rate' },
        { id: divisionName, label: divisionName, alignRight: false, title: divisionName, field: divisionName },
        // { id: 'IPSD', label: 'IPSD', alignRight: false, title: 'IPSD', field: 'IPSD' },
        // { id: 'ILC', label: 'ILC', alignRight: false, title: 'ILC', field: 'ILC' },
        // { id: 'RIOP', label: 'RIOP', alignRight: false, title: 'RIOP', field: 'RIOP' },
        { id: 'others', label: 'Others', alignRight: false, title: 'Others', field: 'OTHERS' },
        { id: 'totalQty', label: 'Total Qty', alignRight: false, title: 'Total Qty', field: 'Quantity' },
        { id: 'total', label: 'Total', alignRight: false, title: 'Total', field: 'Dinner' },
        { id: 'gst', label: 'GST -5%', alignRight: false, title: 'Gst', field: 'Gst' },
        { id: 'totalAmt', label: 'Total Amount', alignRight: true, title: 'Toatl Amount', field: 'ToatlRate' },
    ];
}

export const TABLE_HEAD = (name) => {

    return [
        { id: name, label: name, alignRight: false, title: name, field: name },
        { id: 'rate', label: 'Rate', alignRight: false, title: 'Rate', field: 'Rate' },
        // { id: divisionName, label: divisionName, alignRight: false, title: divisionName, field: divisionName },
        { id: 'IPSD', label: 'IPSD', alignRight: false, title: 'IPSD', field: 'IPSD' },
        { id: 'ILC', label: 'ILC', alignRight: false, title: 'ILC', field: 'ILC' },
        { id: 'RIOP', label: 'RIOP', alignRight: false, title: 'RIOP', field: 'RIOP' },
        { id: 'others', label: 'Others', alignRight: false, title: 'Others', field: 'OTHERS' },
        { id: 'totalQty', label: 'Total Qty', alignRight: false, title: 'Total Qty', field: 'Quantity' },
        { id: 'total', label: 'Total', alignRight: false, title: 'Total', field: 'Dinner' },
        { id: 'gst', label: 'GST -5%', alignRight: false, title: 'Gst', field: 'Gst' },
        { id: 'totalAmt', label: 'Total Amount', alignRight: true, title: 'Toatl Amount', field: 'ToatlRate' },
    ];
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const SaleReportTable = ({ searchVal, searchText, table_header, page, setPage, NDIVISIONID, vendorState }) => {

    const { ReportsReducers: { SaleReportList, SaleMenuList }, ProfileReducers: { loginState }, UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)
// console.log("saless",SaleReportList, SaleMenuList)
    const roleName = loginState[0]?.cRoleName
    const divisionName = loginState[0]?.cDivisionName

    let firstIndex = 0;

    // const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const tableData = SaleReportList[1].filter(m => (m.nMealID === table_header.nID)) //&& (divisionID === m.nDivisionID)
    // console.log("1", tableData);
    // const tableData = searchText ? searchVal : rowVal
    // console.log("1", tableData);

    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    // console.log("1", filteredData);

    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box >
                <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }} >
                        <Table sx={{width:'100%'}}>
                            <SaleReportTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={ roleName !== 'Admin' && roleName !== 'FoodAdmin' ? TABLE_HEAD(table_header.cMealName, cafeteriaId) : TABLE_HEAD_ADMIN(table_header.cMealName, divisionName)}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    .map((s, i) => {
                                        return (
                                            <SaleReportTableRow key={s.nID} rowData={s} index={i} SaleMenuList={SaleMenuList} divisionName={divisionName} roleName={roleName} NDIVISIONID={NDIVISIONID} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                            <TableBody>
                                <SaleTableFooter rowVal={tableData} divisionName={divisionName} roleName={roleName} />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>

        </>
    )
}

export default SaleReportTable;
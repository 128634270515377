import http from "../../http-common";

class ContractorManagementService {

    getAll(data) {
        return http.post("/admin/getContractorManagement", data);
    }
    get(id) {
        return http.get(`/admin/contractorManagement/${id}`);
    }
    create(data) {
        return http.post("/admin/addContractorManagement", data);
    }
    update(id, data) {
        return http.put(`/admin/contractorManagement/${id}`, data);
    }
    delete(id, data) {
        return http.delete(`/admin/contractorManagement/${id}`, data);
    }
    addUser(data) {
        return http.post("/contractor/addUser", data);
    }
    getAllConUser() {
        return http.post("/contractor/selectAllUser");
    }
    getViewConUser(data) {
        return http.post("/contractor/viewUser", data);
    }
    updateViewConUser(data) {
        return http.post("/contractor/updateUser", data);
    }
    deleteViewConUser(data) {
        return http.post("/contractor/deleteUser", data);
    }

}

export default new ContractorManagementService();
import React, { useEffect } from 'react';
import { Card, Grid, Button, InputAdornment, Typography, Stack, Autocomplete, Checkbox } from "@mui/material";
import SearchField from '../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getCurDate } from '../../../utils/common';
import { ExportCsv } from "@material-table/exporters";
import CssTextField from '../../overrides/coloredTextField';
import VendorFilter from './vendorFilter';
import { useSelector } from 'react-redux';

const UserHistoryHeader = ({ handleSearch, searchText, handleSubmitAction, rowData, columnData, type, setVendorState, invalidErr, setInvalidErr }) => {

    // const [open, setOpen] = useState(false);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const [fieldErr, setFieldErr] = React.useState(false)
    const currentDate = new Date()
    const { UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)

    useEffect(()=>{
        setFromDate(null)
        setToDate(null)
        setVendorState('')
    }, [cafeteriaId])    

    const handleFromChange = (e) => {
        setFromDate(e)
        // setToDate(null)
        // if(fromDate <= toDate){
        //     setInvalidErr({ ...invalidErr, fromInvalidErr:false, toInvalidErr:false})
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr:true, toInvalidErr:true})
        // }
    }

    const handleToChange = (e) => {
        setToDate(e)
        // if(fromDate !== null){
        //     // setToDate(e)
        //     setFieldErr(false)     
        //     console.log('scs',toDate, fromDate)       
        //     if(toDate >= fromDate){
        //         setInvalidErr({ ...invalidErr, fromInvalidErr:false, toInvalidErr:false})
        //     }
        //     else{
        //         console.log('zxcsfsfes50')
        //         setInvalidErr({ ...invalidErr, fromInvalidErr:true, toInvalidErr:true})
        //     }
        // }
        // else{
        //     setFieldErr(true)
        // }
    }

    console.log('sdfsdfrg',invalidErr.fromInvalidErr,invalidErr.toInvalidErr)

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >

                    <Grid item lg={4} xs={12} md={4} sm={4} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: 50
                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            onChange={handleSearch}
                            value={searchText}
                        />
                    </Grid>

                    <Grid item lg={8} xs={12} md={8} sm={8} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={fromDate}
                                    label='From Date'
                                    inputFormat= "MM/dd/yyyy"
                                    // maxDate={currentDate}
                                    onChange={handleFromChange}
                                    renderInput={(params) => <SearchField sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: 50
                                            },
                                            '& label': {
                                                fontSize: '.9rem'
                                            }
                                        }} 
                                        fullWidth {...params}
                                        error={invalidErr.fromInvalidErr === true ? true : false}
                                        helperText={invalidErr.fromInvalidErr === true ? 'Invalid Date' : ''}
                                    />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    // value={fromDate !== null ? toDate : null}
                                    value={toDate}
                                    label='To Date'
                                    inputFormat= "MM/dd/yyyy"
                                    // minDate={fromDate}
                                    // maxDate={currentDate}
                                    onChange={handleToChange}
                                    // disabled={fromDate !== null ? false : true}
                                    renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: 50
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                        }} 
                                        fullWidth {...params} 
                                        error={invalidErr.toInvalidErr === true ? true : fieldErr === true ? fromDate !== null ? false : true : false}
                                        helperText={invalidErr.toInvalidErr === true ? 'Invalid Date' : fieldErr === true ? fromDate !== null ? '' : 'Please Choose From Date First..!' : ''}
                                    />}
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Grid>

                    <Grid lg={4} xs={12} md={4} sm={4} sx={{ justifyContent: 'center', mt: '25px' }}>
                        <VendorFilter setVendorState={setVendorState}/>
                    </Grid>

                    <Grid item lg={4} xs={12} md={4} sm={4} sx={{ justifyContent: 'center' }} >
                        <Button
                            variant='contained'
                            sx={{ height: 50, backgroundColor: 'common.Bblack', '&:hover': { backgroundColor: 'common.Bblack' } }}
                            fullWidth
                            endIcon={<DoubleArrowIcon />}
                            onClick={() => {
                                handleSubmitAction(getCurDate(fromDate), getCurDate(toDate))
                                setFieldErr(false)
                                // if (fromDate === null || toDate === null) {
                                //     setFromDate(new Date())
                                //     setToDate(new Date())
                                // }
                            }}
                        >
                            <Typography variant='t16_400_1' color='common.white'>Submit</Typography>
                        </Button>
                    </Grid>

                    <Grid item lg={4} xs={12} md={4} sm={4} sx={{ justifyContent: 'center' }} >
                        <Button variant='contained' fullWidth sx={{ height: '50px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(columnData, rowData, "Exported file")}>
                            <Typography variant='t16_500'>Export to Excel</Typography>
                        </Button>
                    </Grid>

                </Grid>
            </Card>
        </>
    )
}

export default UserHistoryHeader;
import React, { useState, useRef, useEffect } from 'react';
import { Button, Typography, Grid, Autocomplete, Stack, Checkbox } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { createEmployeeWatcher, getListByIdWatcher } from '../../../../redux/actionCreators/admin/employeeManagement';
import { useDispatch, useSelector } from 'react-redux'
import CssTextField from '../../../overrides/longSearchField';
import { styled } from '@mui/material/styles';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileUploadButton from "../../../overrides/fileUploadButton"
import CloseButton from "../../../overrides/closeButton"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const Input = styled('input')({
    display: 'none',
});

function EmployeeCreation(props) {
    const dispatch = useDispatch()
    const { EmployeeReducers: { roleNameList, accessPointList, divisionList, contractor, counter, employeeList, guestDrop, vendorName, listById, departmentNameList, categoryList }, ProfileReducers: { loginState } } = useSelector((state) => state)
    const nUserID = loginState[0]?.nID
    const nDivisionID = loginState[0]?.nDivisionID
    const userRoleName = loginState[0]?.cRoleName

    const { setOpen, status } = props;

    const [imageName, setImageName] = useState('')
    const [roleName, setroleName] = useState('')
    const tempinitsform = useRef();

    const nUserId = loginState[0].nID

    const getSimilar = (roleName) => {
        return (roleName === 'Employee') || (roleName === 'FoodClerk') || (roleName === 'SpecialGuest') || (roleName === 'NewJoiners')
    }

    const custValidation = (Yup) => {
        if (getSimilar(roleName)) {
            const baseSchema = {
                RFID_number: Yup.string()
                  .min(1, 'Too Short!')
                  .max(50, 'Too Long!')
                  .required('RFID Number is required')
                  .test("Already exist", "Already exist", (data) => (
                    !employeeList.some(d => d.cRFID === data)
                  )),
                category: Yup.string()
                  .min(1, 'Too Short!')
                  .max(50, 'Too Long!')
                  .required('Category is required'),
                department: Yup.string()
                  .min(1, 'Too Short!')
                  .max(50, 'Too Long!')
                  .required('Department is required'),
              };
            
              if (roleName === 'NewJoiners') {
                baseSchema.daysToExpiry = Yup.number().moreThan(0, 'Too Short!').required('Days to Expiry is required')
              }
            
              return baseSchema;
            // return {
            //     RFID_number: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('RFID Number is required').test("Already exist", "Already exist", (data) => (
            //         !employeeList.some(d => d.cRFID === data)
            //     )),
            //     category: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Category is required'),
            //     department: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Department is required')
            // }
        }
        if (roleName === 'ContractorAdmin') {
            return { contractor: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Contractor is required') }
        }
        if (roleName === 'CounterAdmin') {
            return { counter: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Counter is required') }
        }
        if (roleName === 'GuestAdmin') {
            return { guest: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Guest is required') }
        }
        if (roleName === 'FoodAdmin') {
            return { vendor: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Vendor is required') }
        }
        // if (roleName === 'NewJoiners') {
        //     return {
        //         RFID_number: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('RFID Number is required').test("Already exist", "Already exist", (data) => (
        //             !employeeList.some(d => d.cRFID === data)
        //         )),
        //         category: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Category is required'),
        //         department: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Department is required'),
        //         daysToExpiry: Yup.number().moreThan(0, 'Too Short!').required('Days to Expiry is required')
        //     }
        // }
        return {}
    }

    const EmployeeSchema = Yup.object().shape({
        employeeId: Yup.string('Enter your User Id').min(1, 'Too Short!').max(50, 'Too Long!').required('User Id is required'),
        employeeName: Yup.string('Enter your User Name').min(2, 'Too Short!').max(100, 'Too Long!').required('User Name is required'),
        role: Yup.string().required('Role is required'),
        // accessPoints: Yup.string().min(1, 'Select at least one option').required('Access Point is required'),
        division: Yup.string().required('Division is required'),
        mobileNumber: Yup.string().matches(/^(?!.*-.*-.*-)(?=(?:\d{8,10}$)|(?:(?=.{9,11}$)[^-]*-[^-]*$)|(?:(?=.{10,12}$)[^-]*-[^-]*-[^-]*$)  )[\d-]+$/, 'Mobile Number is Invalid').required('Mobile Number is required'),
        password: Yup.string().min(8, 'Password should be of minimum 8 characters length').required('Password is required'),
        emailId: Yup.string().email('Email must be a valid email address').required('Email Id is required'),
        ...custValidation(Yup)
    });

    const formik = useFormik({
        initialValues: {
            employeeId: '',
            employeeName: '',
            role: '',
            emailId: '',
            mobileNumber: '',
            password: '',
            division: userRoleName === 'Admin' ? nDivisionID : '',
            RFID_number: '',
            accessPoints: '',
            profileImage: '',
            contractor: '',
            counter: '',
            roleName: '',
            guest: '',
            vendor: '',
            department: '',
            category: '',
            daysToExpiry: ''
        },

        validationSchema: EmployeeSchema,
        
        onSubmit: () => {
            let values = { ...formik.values }
            let data = {
                ACTION: "INSERT",
                CEMPLOYEEID: values.employeeId,
                CEMPLOYEENAME: values.employeeName,
                CUSERNAME: "CU10439",
                cPassword: values.password,
                NROLEID: values.role,
                CMOBILENUMBER: values.mobileNumber,
                CEMAILID: values.emailId,
                NDEPARTMENTID: "2",
                NCONTRACTORID: values.contractor,
                NSECTORID: "1",
                NDESIGNATIONID: "1",
                NDIVISIONID: `${values.division}`,
                CRFID: values.RFID_number,
                NMODIFIEDBY: nUserId,
                nCounterID: values.counter,
                nAccessPoint: `${values.accessPoints}`,
                nRFIDUserID: 1,
                CIMAGE: values.profileImage,
                NGUESTID: values.guest,
                NVENDORID: values.vendor,
                NCATEGORY: `${values.category}`,
                NDEPARTMENT: `${values.department}`,
                isSA: props.isSA,
                SROLEID: props.role,
                SDIVISIONID: props.divisionId,
                DAYSTOEXPIRY: String(values.daysToExpiry)
            }

            if(values.accessPoints.length){
                dispatch(createEmployeeWatcher(data, setOpen))
            }
        }
    });
    
    const { errors, touched, handleSubmit, values, getFieldProps, setFieldValue, setFieldTouched } = formik;

    const handleClose = () => {
        setOpen(false)
    }

    // console.log('pppp', values.division, userRoleName, nDivisionID)

    const onFileChange = (e) => {
        if (e) {
            var file = e.target.files[0];
            var name = e.target.files[0].name;
            setImageName(name);
            var reader = new FileReader();
            reader.onloadend = function () {
                setFieldValue("profileImage", reader.result)
            }
            reader.readAsDataURL(file);
        }
    }

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    // const neglectArr = ['ContractorAdmin', 'GuestAdmin', 'FoodAdmin']

    const getDataById = (ACTION, NID) => {
        dispatch(getListByIdWatcher({ ACTION, NID }))
    }

    const initsform = () => {
        if (listById.length && (values.contractor || values.guest || values.vendor)) {
            const { userId, userName, cEmailID, cMobileNumber } = listById[0]
            setFieldValue("employeeId", userId);
            setFieldValue("employeeName", userName);
            setFieldValue("emailId", cEmailID);
            setFieldValue("mobileNumber", cMobileNumber);
            let fields = ['RFID_number', 'category', 'department', 'daysToExpiry', 'contractor', 'counter', 'guest', 'vendor', 'emailId', 'password', 'mobileNumber', 'division', 'employeeName', 'employeeId', 'accessPoints']
            fields.forEach((f) => {
                setFieldTouched(f, false);
            })
        }
    };

    tempinitsform.current = initsform;

    useEffect(() => {
        tempinitsform.current();
    }, [listById]);

    useEffect(() => {
        setFieldValue("vendor", '')
        setFieldValue("employeeId", '')
        setFieldValue("employeeName", '')
        setFieldValue("emailId", '')
        setFieldValue("mobileNumber", '')
        setFieldValue("contractor", '')
        setFieldValue("guest", '')
    }, [values.division])

    useEffect(() => {
        let fields = ['RFID_number', 'category', 'department', 'daysToExpiry', 'contractor', 'counter', 'guest', 'vendor', 'emailId', 'password', 'mobileNumber', 'division', 'employeeName', 'employeeId', 'accessPoints']
        fields.forEach((f) => {
            setFieldTouched(f, false);
            setFieldValue(f, '');
        })
        // setFieldValue("department", '')
    }, [values.role])

    // useEffect(() => {
    //     if (listById.length && (values.contractor || values.guest || values.vendor)) {
    //         const { userId, userName, cEmailID, cMobileNumber } = listById[0]
    //         setFieldValue("employeeId", userId);
    //         setFieldValue("employeeName", userName);
    //         setFieldValue("emailId", cEmailID);
    //         setFieldValue("mobileNumber", cMobileNumber);
    //     }
    // }, [listById])

    // console.log('sdfggr',guestDrop.filter(d => d.id === values.guest).map((d) => d)[0] || {name : ''})
    //arrayOfObjects.filter(obj => arrayOfValues.includes(obj.name));
    console.log('accessPointList',accessPointList.filter(f => values.accessPoints.includes(f.id)),values.accessPoints)

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    <Typography variant='h5'
                        align='left'
                        style={{ paddingBottom: '10px' }}>
                        {status === 'edit' ? 'Edit User' : 'User Creation'}
                    </Typography>

                    <Grid
                        container
                        style={{ paddingTop: '10px' }}
                        spacing={3}
                        direction='row'
                    >

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item
                        >
                            <Autocomplete
                                required={true}
                                // disablePortal
                                id="checkboxes-tags-demo"
                                name="role"
                                fullWidth
                                options={props.isSA ? roleNameList.filter(d => d.id === 1) : roleNameList}
                                size="medium"
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                    setFieldValue("role", value.id);
                                    setroleName(value.name);
                                }}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Select Role"
                                    name='role'
                                    required={true}
                                    error={Boolean(touched.role && errors.role)}
                                    helperText={touched.role && errors.role}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item
                        >
                            {
                                userRoleName === 'Admin' ?
                                    <CssTextField
                                        placeholder='Division'
                                        name={`Division`}
                                        fullWidth
                                        // {...getFieldProps(`divisionId`)}
                                        value={divisionList.find(d => d.id === values.division)?.name || ''}
                                        InputProps={{
                                            readOnly: true,
                                        }}

                                    />
                                    :
                                    <Autocomplete
                                        id="combo-box-demo"
                                        name="division"
                                        fullWidth
                                        options={divisionList}
                                        value={divisionList.filter(d => d.id === values.division).map((d) => d)[0] || {name : ''}}
                                        size="medium"
                                        autoHighlight
                                        onChange={(event, value) => {
                                            setFieldValue("division", value.id);
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <CssTextField {...params}
                                            label="Division"
                                            name='division'
                                            required={true}
                                            error={Boolean(touched.division && errors.division)}
                                            helperText={touched.division && errors.division}
                                        />}
                                    />
                            }
                        </Grid>

                        {roleName === 'ContractorAdmin' &&
                            <Grid
                                lg={3}
                                md={4}
                                sm={6}
                                xs={12}
                                item
                            >
                                <Autocomplete
                                    id="combo-box-demo"
                                    name="contractor"
                                    fullWidth
                                    options={contractor.filter(d => d.nDivisionID === values.division)}
                                    size="medium"
                                    value={contractor.filter(d => d.id === values.contractor).map((d) => d)[0] || {name : ''}}
                                    autoHighlight
                                    onChange={(event, value) => {
                                        setFieldValue("contractor", value.id);
                                        getDataById('contractorById', value.id)
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <CssTextField {...params}
                                        label="Contractor"
                                        name='contractor'
                                        required={true}
                                        error={Boolean(touched.contractor && errors.contractor)}
                                        helperText={touched.contractor && errors.contractor}
                                    />}
                                />
                            </Grid>
                        }

                        {roleName === 'GuestAdmin' &&
                            <Grid
                                lg={3}
                                md={4}
                                sm={6}
                                xs={12}
                                item
                            >
                                <Autocomplete
                                    id="combo-box-demo"
                                    name="guest"
                                    fullWidth
                                    options={guestDrop.filter(d => d.nDivisionID === values.division)}
                                    size="medium"
                                    value={guestDrop.filter(d => d.id === values.guest).map((d) => d)[0] || {name : ''}}
                                    autoHighlight
                                    onChange={(event, value) => {
                                        setFieldValue("guest", value.id);
                                        getDataById('guestById', value.id)
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <CssTextField {...params}
                                        label="Guest"
                                        name='guest'
                                        required={true}
                                        error={Boolean(touched.guest && errors.guest)}
                                        helperText={touched.guest && errors.guest}
                                    />}
                                />
                            </Grid>
                        }

                        {roleName === 'CounterAdmin' &&
                            <Grid
                                lg={3}
                                md={4}
                                sm={6}
                                xs={12}
                                item
                            >
                                <Autocomplete
                                    id="combo-box-demo"
                                    name="counter"
                                    fullWidth
                                    options={counter}
                                    size="medium"
                                    autoHighlight
                                    onChange={(event, value) => {
                                        setFieldValue("counter", value.id);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <CssTextField {...params}
                                        label="Counter"
                                        name='counter'
                                        required={true}
                                        error={Boolean(touched.counter && errors.counter)}
                                        helperText={touched.counter && errors.counter}
                                    />}
                                />
                            </Grid>
                        }

                        {roleName === 'FoodAdmin' &&
                            <Grid
                                lg={3}
                                md={4}
                                sm={6}
                                xs={12}
                                item
                            >
                                <Autocomplete
                                    id="combo-box-demo"
                                    name="vendor"
                                    fullWidth
                                    options={vendorName.filter(d => d.nDivisionID === values.division)}
                                    size="medium"
                                    value={vendorName.filter(d => d.id === values.vendor).map((d) => d)[0] || {name : ''}}
                                    autoHighlight
                                    onChange={(event, value) => {
                                        setFieldValue("vendor", value.id);
                                        getDataById('vendorById', value.id)
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <CssTextField {...params}
                                        label="Vendor"
                                        name='vendor'
                                        required={true}
                                        error={Boolean(touched.vendor && errors.vendor)}
                                        helperText={touched.vendor && errors.vendor}
                                    />}
                                />
                            </Grid>
                        }

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item
                        >
                            <CssTextField
                                required={true}
                                fullWidth
                                placeholder='User Id'
                                label='User Id'
                                name="email"
                                {...getFieldProps("employeeId")}
                                error={Boolean(touched.employeeId && errors.employeeId)}
                                helperText={touched.employeeId && errors.employeeId}
                                color='primary'
                                type='text'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='User Name'
                                label='User Name'
                                name='employeeName'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("employeeName")}
                                error={Boolean(touched.employeeName && errors.employeeName)}
                                helperText={touched.employeeName && errors.employeeName}
                            />
                        </Grid>
                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Email Id'
                                label='Email Id'
                                name='emailId'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("emailId")}
                                error={Boolean(touched.emailId && errors.emailId)}
                                helperText={touched.emailId && errors.emailId}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Mobile Number'
                                label='Mobile Number'
                                name='mobileNumber'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("mobileNumber")}
                                error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                                helperText={touched.mobileNumber && errors.mobileNumber}
                            />
                        </Grid>


                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Password'
                                label='Password'
                                name='password'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("password")}
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}
                            />
                        </Grid>



                        {getSimilar(roleName) &&
                            <>
                                <Grid
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item
                                >
                                    <CssTextField
                                        required={true}
                                        style={{}}
                                        fullWidth={true}
                                        placeholder='RFID Number'
                                        label='RFID/QR Number'
                                        name='RFID_number'
                                        color='primary'
                                        type='text'
                                        variant='outlined'
                                        {...getFieldProps('RFID_number')}
                                        error={Boolean(touched.RFID_number && errors.RFID_number)}
                                        helperText={touched.RFID_number && errors.RFID_number}
                                    />
                                </Grid>

                                <Grid
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item
                                >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        name="department"
                                        fullWidth
                                        value={departmentNameList.filter((f) => f.id === values.department).map((d) => d)[0] || {name : ''}}
                                        options={departmentNameList}
                                        size="medium"
                                        autoHighlight
                                        onChange={(event, value) => {
                                            if(value !== null){
                                                setFieldValue("department", value.id);
                                            }
                                            else{
                                                setFieldValue("department", '');
                                            }
                                        }}
                                        getOptionLabel={(option) => option?.name}
                                        renderInput={(params) => <CssTextField {...params}
                                            label="Department"
                                            name='department'
                                            required={true}
                                            error={Boolean(touched.department && errors.department)}
                                            helperText={touched.department && errors.department}
                                        />}
                                    />
                                </Grid>

                                <Grid
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item
                                >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        name="category"
                                        fullWidth
                                        value={categoryList.filter((f) => f.id === values.category).map((d) => d)[0] || {name : ''}}
                                        options={categoryList}
                                        size="medium"
                                        autoHighlight
                                        onChange={(event, value) => {                                            
                                            if(value !== null){
                                                setFieldValue("category", value.id);
                                            }
                                            else{
                                                setFieldValue("category", '');
                                            }
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <CssTextField {...params}
                                            label="Category"
                                            name='category'
                                            required={true}
                                            error={Boolean(touched.category && errors.category)}
                                            helperText={touched.category && errors.category}
                                        />}
                                    />
                                </Grid>

                            </>
                        }

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item
                        >
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="combo-box-demo"
                                name="accessPoints"
                                fullWidth
                                value={accessPointList.filter(f => values.accessPoints.includes(f.id)) || {name : ''}}
                                options={accessPointList}
                                size="medium"
                                autoHighlight
                                onChange={(event, value) => {
                                    let Val = value.map(m => m.id)
                                    setFieldValue("accessPoints", Val);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </li>
                                )}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Access Ponits"
                                    name='accessPoints'
                                    required={true}
                                    error={values.accessPoints.length > 0 ? false : Boolean(touched.accessPoints && 'Access Point is required')}
                                    helperText={values.accessPoints.length > 0 ? '' : touched.accessPoints && 'Access Point is required'}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item
                        >
                            <CssTextField
                                required={roleName === 'NewJoiners' && true}
                                fullWidth
                                placeholder='Days To Expiry'
                                label='Days To Expiry'
                                name='daysToExpiry'
                                color='primary'
                                type='number'
                                variant='outlined'
                                {...getFieldProps('daysToExpiry')}
                                error={(roleName === 'NewJoiners') ? (Boolean(touched.daysToExpiry && errors.daysToExpiry)) : ''}
                                helperText={(roleName === 'NewJoiners') ? (touched.daysToExpiry && errors.daysToExpiry) : ''}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item
                        >
                            <label htmlFor="contained-button-file">
                                <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={onFileChange} />
                                <FileUploadButton
                                    variant="contained"
                                    component="span"
                                    fullWidth
                                    // onClick={imageFunction}
                                    endIcon={<FileUploadOutlinedIcon sx={{ color: 'common.black' }} />}
                                    type='button'
                                    sx={{ height: '55px' }}
                                >
                                    <Typography variant='t16_600' color='grey.600'>
                                        {imageName ? imageName : 'Upload Profile'}
                                    </Typography>
                                </FileUploadButton >
                            </label>
                        </Grid>

                        {/* ------------------------------------------------------------------------ button ------------------------------------------------------------------------------ */}

                        <Grid
                            xs={12}
                            item
                        >
                            <Stack spacing={2} direction='row' justifyContent='end' >
                                <CloseButton onClick={() => handleClose()}
                                    sx={{
                                        //width: '120px',
                                        height: '42px',
                                        left: '0px',
                                        top: '0px',
                                        minWidth: '120px',
                                        marginTop: 'auto',
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='button'>
                                    <Typography variant='t14_400' color='common.black'>Cancel</Typography>
                                </CloseButton>
                                <Button onClick={handleSubmit}
                                    style={{
                                        //width: '120px',
                                        height: '42px',
                                        left: '0px',
                                        top: '0px',
                                        minWidth: '120px',
                                        backgroundColor: 'common.Bblack',
                                        marginTop: 'auto'
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='submit'
                                >
                                    <Typography variant='t14_400' color='grey.0'>Submit</Typography>
                                </Button>
                            </Stack>

                        </Grid>

                    </Grid>
                </Form>
            </FormikProvider>

        </>
    );

}

export default EmployeeCreation;
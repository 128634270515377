import React from 'react';
import { IconButton } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const OrderTActionButtons = ({ viewFunction, type, expandOpen = false, setExpandOpen, role_id}) => {
// console.log('role_id',role_id);
    return (
        <>
            <div>
                {
                    type === 'orderTracking' &&
                    <>
                        <IconButton onClick={() => { setExpandOpen(!expandOpen) }}>
                            {expandOpen ? <ExpandLessIcon sx={{ color: 'common.black' }} /> : <ExpandMoreIcon sx={{ color: 'common.black' }} />}
                        </IconButton>
                        
                        {role_id !== 7 && (
                            <IconButton onClick={viewFunction}>
                                <RemoveRedEyeOutlinedIcon sx={{ color: 'common.black' }} />
                            </IconButton>
                        )}
                    </>
                }
            </div >
        </>

    )
}

export default OrderTActionButtons;
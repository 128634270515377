import { Box, Card } from "@mui/material";
import React, { useState } from "react";
import ConUserTable from "./guestUserTable";
import Header from "./header";
import { TABLE_HEAD } from "./guestUserTable";
import { useSelector } from 'react-redux'

export default function ViewDesign({ setViewDialogOpen }) {

    const { GuestReducers: { viewGuestUserList } } = useSelector((state) => state)

    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = viewGuestUserList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
    }

    return (
        <>
            <Box p='10px 0'>
                <Header handleSearch={handleSearch} setViewDialogOpen={setViewDialogOpen} searchText={searchText} rowData={viewGuestUserList} columnData={TABLE_HEAD} />
            </Box>

            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                <ConUserTable searchText={searchText} searchVal={searchVal} />
            </Card>
        </>
    )
}
import React from 'react';
import {
    TableCell,
    Typography,
    TableRow
} from '@mui/material';

const GuestTableRow = ({ rowData }) => {

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                <TableCell align="left">
                    <Typography variant='t14_600' >
                        {rowData.cGuestID}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.cEmployeeName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.cGuestID}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.CreatedDate}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Breakfast || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Lunch || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Snacks || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.Dinner || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData['Early morning snacks'] || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData['Early morning snacks'] || 0}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData['cVendorName'] || 0}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    )
}

export default GuestTableRow
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { GetRoutes } from "../utils/constants";
import { useSelector } from "react-redux";

export default function CustomSeparator({ content = [] }) {

    const { ProfileReducers: { loginState } } = useSelector(state => state)

    return (
        <Breadcrumbs separator="-" aria-label="breadcrumb" >

            <Typography sx={{ color: 'common.weekTab' }} to={GetRoutes(loginState[0]?.cRoleName) || '/login'} component={Link} variant="t13_500">Home</Typography>

            {content.map(d => <Typography key={d} variant="t13_500">{d}</Typography>)}

        </Breadcrumbs>
    );
}

import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card, Button } from "@mui/material";
import CommonHeader from '../../../components/foodVendor/commonHeader/header';
import OrderTrackingTable from '../../../components/admin/reports/orderTracking/orderTrackingTable';
import { getOrderTrackingWatcher, getSnacksReportWatcher } from '../../../redux/actionCreators/admin/reports';
import { useDispatch, useSelector } from 'react-redux';

import SplOrderSubTable, { SUBTABLE_HEAD } from '../../../components/foodVendor/reports/specialOrderTracking/subTable'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { ExportCsv } from "@material-table/exporters";
import { SUB_TABLE_HEAD } from '../../../components/admin/reports/orderTracking/orderTrackChildTable';
import Context from '../../../context/context';
import { getConvertedDate } from '../../../utils/common';
import SnacksReportsTable, { TABLE_HEAD } from '../../../components/admin/reports/snacksReport/snacksReportTable';
import CommonHeaderForSnacksReport from '../../../components/admin/reports/snacksReport/header';

const SnacksReport = () => {

    const { ReportsReducers: { getSnacksReportsList }, ProfileReducers: {loginState}, UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)
console.log("getSnacksReportsList",getSnacksReportsList)
    // const vendorId = loginState[0]?.nVendorID || ''
    const role = loginState[0]?.nRoleID || ''

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [pageChange, setPageChange] = useState(false)
    const [page, setPage] = useState(1);
    const [eventVal, setEventVal] = useState('')
    const { state, setState } = useContext(Context);
    const [vendorState, setVendorState] = useState('')
    // const [fieldErr, setFieldErr] = React.useState({ fromErr : false, toErr : false })
    const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = getSnacksReportsList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }
    const nUserID = loginState[0]?.nID || 0


    const handleSubmitAction = (fromDate, toDate) => {
        let currentDate = new Date()

            const data = {
                nUserID,
                "cAction": "getSnacksReportSearch",
                "Fdate": fromDate !== null ? fromDate : currentDate,
                "Tdate": toDate !== null ? toDate : currentDate,
                NDIVISIONID: cafeteriaId,
               
             
            }
            dispatch(getSnacksReportWatcher(data))
            setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
            setPage(1)
      
    }

    useEffect(() => {
        if (cafeteriaId) {
            let data = {
                "cAction": "getSnacksReport",
                NDIVISIONID: cafeteriaId,
            }
            dispatch(getSnacksReportWatcher(data))
        }
    }, [dispatch, cafeteriaId])

    useEffect(()=>{
        if(eventVal)
        handleSearch(eventVal);
    },[getSnacksReportsList])

    return (
        <>

                <Box p="24px">

                    <Breadcrumbs content={["Reports", "Snacks Report"]} />

                    <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                        <Typography variant="t24_700"> Snacks Report </Typography>
                    </Box>
                    <Box p='10px 0'>
                        <CommonHeaderForSnacksReport handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={getSnacksReportsList} 
                        columnData={TABLE_HEAD} defaultDate={new Date()} setVendorState={setVendorState} invalidErr={invalidErr} setInvalidErr={setInvalidErr}/>
                    </Box>
                    <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                        <SnacksReportsTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                    </Card>

                </Box>

              
        </>
    )
}

export default SnacksReport;
import { Box, Card, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Breadcrumbs from '../../../components/breadcrumbs'
import FreezeHeader from '../../../components/admin/reports/freeze/header'
import FreezeTable from '../../../components/admin/reports/freeze/freezeTable'
import {useDispatch, useSelector } from 'react-redux';
import NewFreeze from '../../../components/admin/reports/freeze/newFreeze'
import CreationDialog from '../../../components/dialog'
import { getfreezeBookingWatcher } from '../../../redux/actionCreators/admin/carousel';
import { getMealDropdownWatcher } from '../../../redux/actionCreators/admin/menuMaster'

export default function FreezeIndex() {

    const dispatch = useDispatch();
    const { CarouselReducers: { freezeBooking } } = useSelector((state) => state)
    const [open, setOpen] = useState(false)

    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);

    useEffect(() => {
        let data = {
            "ACTION":"SELECT",            
            CVENDORID: 'null'
        }
        dispatch(getfreezeBookingWatcher(data))
        dispatch(getMealDropdownWatcher())
    }, [dispatch])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = freezeBooking.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });
        setSearchVal(filteredRows);
        setPage(1)
    }

    return (
        // <>
            <Box p='30px'>
                <Breadcrumbs content={["Reports", "Freeze Booking"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' >Freeze Booking</Typography>
                </Box>
                <Box p='10px 0'>
                    <FreezeHeader setOpen={setOpen} handleSearch={handleSearch} searchText={searchText} />
                </Box>
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <FreezeTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                </Card>
                <CreationDialog open={open} setOpen={setOpen} maxWidth='md' >
                    <NewFreeze setOpen={setOpen} />
                </CreationDialog>
            </Box>
        // </>
    )
}

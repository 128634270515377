import React from 'react';
// import RosterTableHead from './tableHead';
// import EditTextField from '../../../overrides/editField';

// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    // Autocomplete,
    Grid
} from '@mui/material';
// import { useSelector } from 'react-redux'


// const TABLE_HEAD = [
//     { id: 'id', label: 'ID Number', alignRight: false },
//     { id: 'vendorId', label: 'vendor ID', alignRight: false },
//     { id: 'vendorName', label: 'Vendor Name', alignRight: false },
//     { id: 'vendorDesc', label: 'Vendor Desc', alignRight: false },
//     { id: 'landline', label: 'Landline Number', alignRight: false },
//     { id: 'mobile', label: 'Mobile Number', alignRight: false },
//     { id: 'email', label: 'Email ID', alignRight: false },
//     { id: 'vendorAddress', label: 'Vendor Address', alignRight: false },
// ];

const RosterChildTable = ({ expandOpen, status, rowData }) => {

    // const { RosterReducers: { rosterViewList } } = useSelector((state) => state)

    return (
        <>
            {expandOpen &&
                <TableContainer sx={{ maxWidth: 1000, overflow: 'unset' }}>
                    {/* <Table>
                        <RosterTableHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            //   rowCount={USERLIST.length}
                            //   numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                        /> */}
                    <Table>
                        <TableBody>
                            <TableRow
                                // hover
                                tabIndex={-1}
                                sx={{ '& > *': { borderBottom: 'unset' } }}
                            >
                                {status === 'list' ?
                                    <>
                                        {/* <TableCell align="left" width='400px'>
                                            <Typography variant='t14_600'>
                                                {"aaa"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left" width='400px'>
                                            <Typography variant='t14_600'>
                                                {"aaa"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left" width='400px'>
                                            <Typography variant='t14_600'>
                                                {"aaa"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left" width='400px'>
                                            <Typography variant='t14_600'>
                                                {"aaa"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left" width='400px'>
                                            <Typography variant='t14_600'>
                                                {"aaa"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left" width='400px'>
                                            <Typography variant='t14_600'>
                                                {"aaa"}
                                            </Typography>
                                        </TableCell> */}
                                        <TableCell>
                                            <Grid container spacing={7} p='30px'>
                                                <Grid item lg={3}>
                                                    <Typography variant='t14_400' color='common.t'>ID Number</Typography>
                                                    <Grid mt='15px'>
                                                        <Typography variant='t14_600' color='common.black'>{rowData?.nID}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={3}>
                                                    <Typography variant='t14_400' color='common.t'>Vendor ID</Typography>
                                                    <Grid mt='15px'>
                                                        <Typography variant='t14_600' color='common.black'>{rowData.VendorID}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={3}>
                                                    <Typography variant='t14_400' color='common.t'>Vendor Name</Typography>
                                                    <Grid mt='15px'><Typography variant='t14_600' color='common.black'>{rowData.cVendorName}</Typography></Grid>
                                                </Grid>
                                                <Grid item lg={3}>
                                                    <Typography variant='t14_400' color='common.t'>Vendor Desc</Typography>
                                                    <Grid mt='15px'>
                                                        <Typography variant='t14_600' color='common.black'>{rowData.cVendorDesc}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={3}>
                                                    <Typography variant='t14_400' color='common.t'>Landline Number</Typography>
                                                    <Grid mt='15px'>
                                                        <Typography variant='t14_600' color='common.black'>{rowData.cLandlineNumber}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={3}>
                                                    <Typography variant='t14_400' color='common.t'>Mobile Number</Typography>
                                                    <Grid mt='15px'>
                                                        <Typography variant='t14_600' color='common.black'>{rowData.cMobileNumber}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={3}>
                                                    <Typography variant='t14_400' color='common.t'>Email ID</Typography>
                                                    <Grid mt='15px'>
                                                        <Typography variant='t14_600' color='common.black'>{rowData.cEmailID}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={3}>
                                                    <Typography variant='t14_400' color='common.t'>Vendor Address</Typography>
                                                    <Grid mt='15px'>
                                                        <Typography variant='t14_600' color='common.black'>{rowData.cVendorAddress}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TableCell>

                                    </>
                                    :
                                    <>

                                    </>
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    )
}

export default RosterChildTable;
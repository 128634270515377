import React, { useEffect } from 'react';
import { Typography, Autocomplete, Grid, Button, Stack } from "@mui/material";
import CssTextField from '../../../components/overrides/longSearchField';
// import BlackButton from '../../../components/overrides/blackButton';
// import { List } from '../../List';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchField from '../../overrides/longSearchField';
import { getConvertedDate3 } from '../../../utils/common';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createCabBookingWatcher } from '../../../redux/actionCreators/cab';
import { getDropDownWatcher } from '../../../redux/actionCreators/admin/employeeManagement';
import * as Yup from 'yup';
import { Gender } from '../../../utils/common';
import CloseButton from '../../overrides/closeButton';

const Cab = (props) => {

    const dispatch = useDispatch()

    const { EmployeeReducers: { departmentList, tripTypeList, typeOfCab } } = useSelector((state) => state)

    // useEffect(() => {
    //     dispatch(getDropDownWatcher())
    // }, [dispatch])

    const handleFromChange = (e) => {
        setFieldValue("DATEOFTRAVELON", e)
    }

    const handleToChange = (e) => {
        setFieldValue("DATEOFTRAVELOUT", e)
    }

    const CabSchema = Yup.object().shape({

        EMPLOYEENAME: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('User Name is required'),
        MOBILENO: Yup.string('Enter your Mobile Number').min(10, 'Enter Valid Mobile Number').max(10, 'Enter Valid Mobile Number').required('Mobile Number is required'),
        ORDERDATE: Yup.string('Enter your Date').min(1, 'Invalid').max(50, 'Invalid').required('Order Date is required'),
        EMAILID: Yup.string('Enter your EmailId').email('Email must be a valid email address').required('Email is required'),
    });

    const formik = useFormik({
        initialValues: {
            EMPLOYEENAME: "",
            GENDER: "",
            DEPARTMENT: "",
            COSTCENTRE: "",
            MOBILENO: "",
            EMAILID: "",
            SUPERVISIOREMAILID: "",
            HREMAILID: "",
            USAGETYPE: "",
            TYPEOFCAB: "",
            TYPEOFTRIP: "",
            ORDERDATE: "",
            DATEOFTRAVELON: null,
            DATEOFTRAVELOUT: null,
            TRAVELDESTINATIONFROM: "",
            TRAVELDESTINATIONTO: "",
            REPORTINGADDRESS: "",
            FLIGHTORTRAINNUMBER: "",
            REPORTINGTIMEFROM: "",
            REPORTTIMETO: "",
            SPECIALINSTRUCTIONS: ""
        },

        validationSchema: CabSchema,

        onSubmit: () => {
            let values = { ...formik.values }
            let data = {
                ACTION: "INSERT",
                EMPLOYEENAME: values.EMPLOYEENAME,
                GENDER: values.GENDER,
                DEPARTMENT: values.DEPARTMENT,
                COSTCENTRE: values.COSTCENTRE,
                MOBILENO: values.MOBILENO,
                EMAILID: values.EMAILID,
                SUPERVISIOREMAILID: values.SUPERVISIOREMAILID,
                HREMAILID: values.HREMAILID,
                USAGETYPE: values.USAGETYPE,
                TYPEOFCAB: values.TYPEOFCAB,
                TYPEOFTRIP: values.TYPEOFTRIP,
                ORDERDATE: values.ORDERDATE,
                DATEOFTRAVELON: getConvertedDate3(values.DATEOFTRAVELON),
                DATEOFTRAVELOUT: getConvertedDate3(values.DATEOFTRAVELOUT),
                TRAVELDESTINATIONFROM: values.TRAVELDESTINATIONFROM,
                TRAVELDESTINATIONTO: values.TRAVELDESTINATIONTO,
                REPORTINGADDRESS: values.REPORTINGADDRESS,
                FLIGHTORTRAINNUMBER: values.FLIGHTORTRAINNUMBER,
                REPORTINGTIMEFROM: values.REPORTINGTIMEFROM,
                REPORTTIMETO: values.REPORTTIMETO,
                SPECIALINSTRUCTIONS: values.SPECIALINSTRUCTIONS
            }

            dispatch(createCabBookingWatcher(data))
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = formik;

    return (
        <>
            <FormikProvider value={formik}
            >
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3} sx={{ padding: '30px' }} >
                        <Grid item lg={12} xs={12} sm={12} md={12} sx={{ justifyContent: 'left' }}>
                            <div>
                                <Typography variant='t20_600'> Cab Booking </Typography>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} display='flex' flexDirection='row' justifyContent='left' sx={{ padding: {md:'30px', xs: 0} }}>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> User / Guest Name </Typography>
                            <CssTextField 
                                required={true}
                                placeholder='User / Guest Name'
                                name='empGuestName'
                                fullWidth
                                {...getFieldProps("EMPLOYEENAME")}
                                error={Boolean(touched.EMPLOYEENAME && errors.EMPLOYEENAME)}
                                helperText={touched.EMPLOYEENAME && errors.EMPLOYEENAME}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Gender </Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                name="gender"
                                fullWidth
                                options={Gender}
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("GENDER", value.name);
                                }}
                                //{...getFieldProps("GENDER")}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    name='gender' placeholder='Gender'
                                />}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Department </Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                name="department"
                                fullWidth
                                options={departmentList}
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("DEPARTMENT", value.name);
                                }}
                                //{...getFieldProps("DEPARTMENT")}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    name='department' placeholder='Department'
                                />}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Cost Centre </Typography>
                            <CssTextField 
                                placeholder='Cost Centre'
                                name='costCentre'
                                fullWidth
                                size='medium'
                                {...getFieldProps("COSTCENTRE")}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Mobile No. </Typography>
                            <CssTextField 
                                required={true}
                                placeholder='Mobile No'
                                name='mobileNo'
                                fullWidth
                                size='medium'
                                {...getFieldProps("MOBILENO")}
                                error={Boolean(touched.MOBILENO && errors.MOBILENO)}
                                helperText={touched.MOBILENO && errors.MOBILENO}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Email ID </Typography>
                            <CssTextField 
                                required={true}
                                placeholder='Email ID'
                                name='email'
                                fullWidth
                                size='medium'
                                {...getFieldProps("EMAILID")}
                                error={Boolean(touched.EMAILID && errors.EMAILID)}
                                helperText={touched.EMAILID && errors.EMAILID}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Supervisor Email ID </Typography>
                            <CssTextField 
                                placeholder='Supervisor Email ID'
                                name='supervisorEmail'
                                fullWidth
                                size='medium'
                                {...getFieldProps("SUPERVISIOREMAILID")}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> HR Email ID </Typography>
                            <CssTextField 
                                placeholder='HR Email ID'
                                name='hrEmail'
                                fullWidth
                                size='medium'
                                {...getFieldProps("HREMAILID")}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Usage Type </Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                name="usageType"
                                fullWidth
                                options={tripTypeList}
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("USAGETYPE", value.name);
                                }}
                                //{...getFieldProps("USAGETYPE")}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    name='usageType' placeholder='Usage Type'
                                />}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Type of Cab </Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                name="cabType"
                                fullWidth
                                options={typeOfCab}
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("TYPEOFCAB", value.name);
                                }}
                                //{...getFieldProps("TYPEOFCAB")}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    name='cabType' placeholder='Cab Type'
                                />}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Type of Trip </Typography>
                            <CssTextField 
                                placeholder='Type of Trip'
                                name='tripType'
                                fullWidth
                                size='medium'
                                {...getFieldProps("TYPEOFTRIP")}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Order Date </Typography>
                            <CssTextField 
                                placeholder='Order Date'
                                name='orderDate'
                                fullWidth
                                size='medium'
                                {...getFieldProps("ORDERDATE")}
                                error={Boolean(touched.ORDERDATE && errors.ORDERDATE)}
                                helperText={touched.ORDERDATE && errors.ORDERDATE}
                            />
                        </Grid>
                        <Grid item
                            lg={6}
                            xs={12}
                            md={6}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Date Of Travel </Typography>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={values.DATEOFTRAVELON}
                                        label='From'
                                        onChange={handleFromChange}
                                        renderInput={(params) => <SearchField  fullWidth {...params} />}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={values.DATEOFTRAVELOUT}
                                        label='To'
                                        onChange={handleToChange}
                                        renderInput={(params) => <SearchField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </Grid>
                        <Grid item
                            lg={6}
                            xs={12}
                            md={6}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Travel Destination </Typography>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <CssTextField 
                                    placeholder='From'
                                    name='from'
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps("TRAVELDESTINATIONFROM")}
                                />

                                <CssTextField 
                                    placeholder='To'
                                    name='to'
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps("TRAVELDESTINATIONTO")}
                                />
                            </Stack>
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Reporting Address </Typography>
                            <CssTextField 
                                placeholder='Reporting Address'
                                name='reportingAddress'
                                fullWidth
                                size='medium'
                                {...getFieldProps("REPORTINGADDRESS")}
                            />
                        </Grid>
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Flight / Train Number </Typography>
                            <CssTextField 
                                placeholder='Flight / Train Number'
                                name='fligh_train_no'
                                fullWidth
                                size='medium'
                                {...getFieldProps("FLIGHTORTRAINNUMBER")}
                            />
                            {/* <Autocomplete
                            id="combo-box-demo"
                            name="fligh_train_no"
                            fullWidth
                            options={List}
                            autoHighlight
                            onChange={(event, value) => {
                                setFieldValue("FLIGHTORTRAINNUMBER", value.name);
                            }}
                            //{...getFieldProps("FLIGHTORTRAINNUMBER")}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <CssTextField {...params}
                                name='fligh_train_no' placeholder='Flight / Train Number'
                            />}
                        /> */}
                        </Grid>
                        
                        <Grid item
                            lg={4}
                            xs={12}
                            md={4}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Special Instruction </Typography>
                            <CssTextField 
                                placeholder='Special Instruction'
                                name='specialInstruction'
                                fullWidth
                                size='medium'
                                {...getFieldProps("SPECIALINSTRUCTIONS")}
                            />
                        </Grid>

                        <Grid item
                            lg={6}
                            xs={12}
                            md={6}
                            sm={6}
                        >
                            <Typography variant='t14_600'> Reporting Time </Typography>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                                <CssTextField 
                                    placeholder='From'
                                    name='orderDate'
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps("REPORTINGTIMEFROM")}
                                />
                                <CssTextField 
                                    placeholder='To'
                                    name='orderDate'
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps("REPORTTIMETO")}
                                />
                            </Stack>

                        </Grid>
                    </Grid>

                        <Stack spacing={2} mt='30px' justifyContent='end' direction='row' >
                        <CloseButton sx={{
                                width: '120px',
                                height: '42px',
                                left: '0px',
                                top: '0px',
                            }}
                                variant='contained'
                                //onClick={{}}
                            >
                                <Typography variant='t14_400' color='common.black'> Cancel </Typography>
                            </CloseButton>
                            <Button sx={{
                                width: '120px',
                                height: '42px',
                                left: '0px',
                                top: '0px',
                                backgroundColor: 'common.Bblack'
                            }}
                                variant='contained'
                                onClick={handleSubmit}
                            >
                                <Typography variant='t14_400' color='inherit'>Submit</Typography>
                            </Button>
                        </Stack>
                            
                </Form>
            </FormikProvider>
        </>
    )
}

export default Cab;
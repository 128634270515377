import React from 'react';
import { IconButton } from '@mui/material';
// import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const KioskOrderTActionButtons = ({ viewFunction, type, expandOpen = false, setExpandOpen}) => {

    return (
        <>
            <div>
                {
                    type === 'kioskOrderTracking' &&
                    <>
                        <IconButton onClick={() => { setExpandOpen(!expandOpen) }}>
                            {expandOpen ? <ExpandLessIcon sx={{ color: 'common.black' }} /> : <ExpandMoreIcon sx={{ color: 'common.black' }} />}
                        </IconButton>
                        
                        {/* <IconButton onClick={viewFunction}>
                            <RemoveRedEyeOutlinedIcon sx={{ color: 'common.black' }} />
                        </IconButton> */}
                    </>
                }
            </div >
        </>

    )
}

export default KioskOrderTActionButtons;
import React, { useState } from 'react'
import { Box, Card, Typography } from '@mui/material'
import Breadcrumbs from '../../../components/breadcrumbs'
import { useDispatch, useSelector } from 'react-redux'
import { TABLE_HEAD } from '../../../components/admin/reports/activity/table'
import ActivityHeader from '../../../components/admin/reports/activity/headerCard'
import ActivityTable from '../../../components/admin/reports/activity/table'
import { getActivityWatcher } from '../../../redux/actionCreators/admin/reports';

export default function Activity() {

    const { ReportsReducers: { ActivityList } } = useSelector((state) => state)

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = ActivityList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        const data = {
            Action: "AdminSearchActivityList",
            FromDate: fromDate,
            ToDate: toDate,
        }
        dispatch(getActivityWatcher(data))
        setPage(1)
    }

    return (
        <>
            <Box p="24px">
                <Breadcrumbs content={["Reports", "Activity"]} />
                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant='t24_700' > Activity </Typography>
                </Box>
                <Box p='10px 0'>
                    <ActivityHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={ActivityList} columnData={TABLE_HEAD} />
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <ActivityTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                </Card>
            </Box>
        </>
    )
}

import { Grid, Pagination, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import OrderCards from "../../../components/user/orderCards.js/index.js";

const CurrentOrder = () => {
  const {
    CurrentOrderReducers: { upcomingOrder },
  } = useSelector((state) => state);

  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(2);
  let firstIndex = 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Box display="flex" mt="35px" flexDirection="column">
        <Typography mb="25px" variant="t24_600">
          Upcoming Orders
        </Typography>

        {!upcomingOrder.length ? (
          <Typography mb="25px" textAlign="center" variant="t20_400">
            No Upcoming Orders
          </Typography>
        ) : (
          <Grid container spacing={2} >
            <Grid item xs={12} md={true}>
              <Grid container spacing={2}>
                {upcomingOrder.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page).map((d) => (
                  <Grid key={d.nID} item xs={12} sm={6} xl={6}>
                    <OrderCards value={d} type={'upcomingOrder'}/>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
      {upcomingOrder.length > 2 &&
        <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
          <Pagination
            size="large"
            shape="rounded"
            color='primary'
            count={Math.ceil(upcomingOrder?.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
          />
        </Box>
      }
    </>

  );
};

export default CurrentOrder;

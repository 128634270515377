import React from 'react';
import { Card, Grid, Button, InputAdornment, Typography, Stack } from "@mui/material";
import SearchField from '../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { getConvertedDate } from '../../../utils/common';
import { ExportCsv } from "@material-table/exporters";
import BlackButton from '../../overrides/blackButton';

const RatingHeaderCard = ({ handleSearch, searchText, handleDateAction, rowData, columnData, setRatingListLocal, ratingList, fromDate, setFromDate, toDate, setToDate }) => {

    // const [fromDate, setFromDate] = React.useState(null);
    // const [toDate, setToDate] = React.useState(null);
    
    const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })
    const [fieldErr, setFieldErr] = React.useState(false)
    const currentDate = new Date()

    const handleFromChange = (e) => {
        // let convertedDate = getConvertedDate(e)
        e.setHours(0, 0, 0, 0)
        setFromDate(e)
        // if(fromDate <= toDate){
        //     setInvalidErr({ ...invalidErr, fromInvalidErr:false, toInvalidErr:false})
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr:true, toInvalidErr:true})
        // }
    }

    const handleToChange = (e) => {
        e.setHours(0, 0, 0, 0)
        setToDate(e)
        // if(fromDate !== null){
        //     e.setHours(0, 0, 0, 0)
        //     setToDate(e)
        //     setFieldErr(false)
        //     if(toDate <= fromDate){
        //         setInvalidErr({ ...invalidErr, fromInvalidErr:false, toInvalidErr:false})
        //     }
        //     else{
        //         setInvalidErr({ ...invalidErr, fromInvalidErr:true, toInvalidErr:true})
        //     }
        // }
        // else{
        //     setFieldErr(true)
        // }
    }

    const handleSubmit = () => {
        // if (fromDate === null || toDate === null) {
        //     setFromDate(new Date())
        //     setToDate(new Date())
        // }
        let curDate = new Date()
        // if(toDate >= fromDate && toDate <= curDate && fromDate <= curDate){
            const filterList = ratingList.filter(d => {
                const date = new Date(d.RateDate);
                return (date >= fromDate && date <= toDate);
            })
            setRatingListLocal(filterList)
            setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true })
        // }
        setFieldErr(false)
    }

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={4} xs={12} md={4} sm={12} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: 50
                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            value={searchText}
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Grid item lg={8} xs={12} md={8} sm={12} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={fromDate}
                                    label='From Date'
                                    // maxDate={currentDate}
                                    onChange={handleFromChange}
                                    renderInput={(params) => <SearchField sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: 50
                                            },
                                            '& label': {
                                                fontSize: '.9rem'
                                            }
                                        }} 
                                        fullWidth {...params} 
                                        error={invalidErr.fromInvalidErr === true ? true : false}
                                        helperText={invalidErr.fromInvalidErr === true ? 'Invalid Date' : ''}
                                    />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={fromDate !== null ? toDate : null}
                                    label='To Date'
                                    // minDate={fromDate}
                                    // maxDate={currentDate}
                                    onChange={handleToChange}
                                    renderInput={(params) => <SearchField sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: 50
                                            },
                                            '& label': {
                                                fontSize: '.9rem'
                                            }
                                        }} 
                                        fullWidth {...params} 
                                        error={invalidErr.toInvalidErr === true ? true : fieldErr === true ? fromDate !== null ? false : true : false}
                                        helperText={invalidErr.toInvalidErr === true ? 'Invalid Date' : fieldErr === true ? fromDate !== null ? '' : 'Please Choose From Date First..!' : ''}
                                    />}
                                />
                            </LocalizationProvider>

                            <BlackButton variant='contained' sx={{ height: '50px', minWidth: '100px'}} onClick={handleSubmit}>
                                <Typography variant='t16_400_1' >Submit</Typography>
                            </BlackButton>

                            <Button variant='contained' sx={{ height: '50px', minWidth: '200px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(columnData, rowData, "Exported file")}>
                                <Typography variant='t16_500'>Export to Excel</Typography>
                            </Button>

                        </Stack>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default RatingHeaderCard;
import { Badge, Box, CircularProgress, circularProgressClasses, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFoodDetailsWatcher, getSwipeEmployeeWatcher, updateDeliveryWatcher } from '../../redux/actionCreators/screen/screen'
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

let preventTotal = true

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: -5,
        padding: '0 5px',
    },
}));

function OldSwipes() {

    const [isShow, setShow] = useState(false)
    const [isComplete, setComplete] = useState(false)
    const [btCount, setBtCount] = useState(0)
    const [swipeTotal, setSwipeTotal] = useState(0)

    const dispatch = useDispatch()

    const { ProfileReducers: { loginState } } = useSelector((state) => state)

    const AccessPointID = loginState[0]?.nAccessPointID || 0
    const nMealTime = loginState[0]?.nMealTime || 0
    const nCounterID = loginState[0]?.nCounterID || 0

    useEffect(() => {
        const getId = setInterval(function () {

            var hdnProcess = localStorage.getItem('hdnProcessold') || ""
            var hdnRFID = localStorage.getItem('hdnRFIDold') || ""
            var vCreatedAt = localStorage.getItem('vCreatedAtold') || ""

            // if (hdnRFID) {
            //     GetBookingDetails(hdnRFID, vCreatedAt);
            // }
            if (hdnProcess === "Start") {
                GetNextSwipeEntry();
            }
            else if (hdnProcess === "") {
                GetNextSwipeEntry();
            }

        }, 2000);
        localStorage.setItem('intervalId', getId)

        return () => {
            clearInterval(getId)
        }

    }, []);

    function GetNextSwipeEntry() {
        let data = {
            "nAccessPointID": Number(AccessPointID),
            "ACTION": 'OldSwipes'
        }
        dispatch(getSwipeEmployeeWatcher(data, (response) => {
            const res = response.recordset
            const sets = response.recordsets[1][0]?.OldSwipes || 0

            if (res.length > 0) {
                localStorage.setItem('hdnRFIDold', res[0].nRFIDUserID)
                localStorage.setItem('vCreatedAtold', res[0].vCreatedAt)
                GetBookingDetails(res[0].nRFIDUserID, res[0].vCreatedAt);
                setShow(true)
                setBtCount(p => p + 1)
                if (preventTotal) {
                    setSwipeTotal(sets)
                    preventTotal = false
                }
            }
            else {

                localStorage.setItem('hdnRFIDold', "")
                localStorage.setItem('hdnProcessold', "Start")
                var intervalId = localStorage.getItem('intervalId') || 0
                setComplete(true)
                clearInterval(intervalId)
            }
        }))
    }

    function GetBookingDetails(nRFIDUserID, vCreatedAt) {

        let data = {
            "nAccessPointID": Number(AccessPointID),
            nRFIDUserId: nRFIDUserID,
            nMealTimeId: nMealTime,
            vCreatedAt
        }
        dispatch(getFoodDetailsWatcher(data, (response) => {
            const res = response.recordset

            if (res.length > 0) {

                if (res[0].cUserType === "CONTRACTOR" && res[0].cMealName === "Already Delivered") {
                    localStorage.setItem('hdnRFIDold', "")
                    localStorage.setItem('hdnProcessold', "Start")
                }
                else {
                    localStorage.setItem('hdnProcessold', "Stop")

                    for (var i = 1; i <= res.length; i++) {

                        if (res[i - 1].bIsDelivered === false) {

                            UpdateDeliveredAuto(res[i - 1].nBDID, res[i - 1].cUserType, nRFIDUserID, vCreatedAt)
                        }
                        else {
                            localStorage.setItem('hdnRFIDold', "")
                            localStorage.setItem('hdnProcessold', "Start")

                        }
                    }
                }

            }
            else {

                localStorage.setItem('hdnRFIDold', "")
                localStorage.setItem('hdnProcessold', "Start")

            }
        }))
    }

    function UpdateDeliveredAuto(BDID, UserType, nRFIDUserID, vCreatedAt) {

        let data = {
            nBDID: BDID,
            nRFIDUserID: nRFIDUserID,
            nCounterID,
            vCreatedAt
        }
        dispatch(updateDeliveryWatcher(data, (res) => {
            GetNextSwipeEntry();
        }))

    }

    return (
        <Box display='flex' >
            {isShow &&
                <>
                    <StyledBadge badgeContent={<Typography sx={{ color: 'common.white' }} variant='t14_400'>{btCount}/{swipeTotal}</Typography>} color={isComplete ? "success" : "primary"} >
                        {isComplete ?
                            <CheckCircleOutlineIcon sx={{fontSize: 30}} color='success' />
                            :
                            <CircularProgress
                                variant={"indeterminate"}
                                disableShrink={true}
                                sx={{
                                    color: (theme) => theme.palette.primary.main,
                                    animationDuration: '550ms',
                                    [`& .${circularProgressClasses.circle}`]: {
                                        strokeLinecap: 'round',
                                    },
                                }}
                                size={30}
                                value={100}
                                thickness={4}
                            />
                        }

                    </StyledBadge>
                    <Box ml='10px'  >
                        {isComplete ?
                            <Typography sx={{ verticalAlign: 'bottom', color: 'success.main' }} variant='t14_600' >Sync completed.</Typography>
                            :
                            <Typography sx={{ verticalAlign: 'bottom' }} variant='t14_600' >Syncing old data...</Typography>
                        }
                    </Box>
                </>
            }

        </Box>
    )
}

export default OldSwipes
import React, { useState, useEffect } from 'react';
import Scrollbar from '../../../Scrollbar';
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Pagination,
    Box,
} from '@mui/material';
import OrderTrackingTableHead from './tableHead';
import { getOrderTrackingWatcher } from '../../../../redux/actionCreators/admin/reports';
import { useDispatch, useSelector } from 'react-redux';
import OrderTrackingTableRow from './tableRow';

export const TABLE_HEAD = [
    { id: 'department', label: 'Department', alignRight: false, title: 'Department', field: 'department' },
    { id: 'dOrderDate', label: 'Order Date', alignRight: false, title: 'Order Date', field: 'dOrderDate' },
    { id: 'category', label: 'Category', alignRight: false, title: 'Category', field: 'category' },
    { id: 'booked', label: 'Booked', alignRight: false, title: 'Booked', field: 'booked' },
    { id: 'swiped', label: 'Swiped', alignRight: false, title: 'Swiped', field: 'swiped' },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const OrderTrackingTable = ({ searchVal, searchText, page, setPage }) => {

    const { ReportsReducers: { OrderTrackingList } } = useSelector((state) => state)

    let firstIndex = 0;

    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage] = useState(5);

    useEffect(() => {
        let data = {
            "cAction": "eTrack"
        }
        dispatch(getOrderTrackingWatcher(data))
    }, [dispatch])


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    const tableData = searchText.length ? searchVal : OrderTrackingList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    // const arr = [{EmployeeName:'one', cMenuName:'two'}]

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{overflow:'unset'}} >
                        <Table>
                            <OrderTrackingTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                //   rowCount={USERLIST.length}
                                //   numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    // .slice(page * 5, page * 5 + 5)
                                    .map((m, i) => {
                                        return (
                                            <OrderTrackingTableRow key={i} rowData={m} index={i} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default OrderTrackingTable;
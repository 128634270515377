import { CREATE_MENU_ITEM_MASTER_WATCHER, UPDATE_MENU_ITEM_MASTER_WATCHER, DELETE_MENU_ITEM_MASTER_WATCHER, GET_MENU_ITEM_MASTER_WATCHER, MENU_ITEM_MASTER_LIST } from "../../actionTypes/admin";

// Worker triggering actionCreators

export function getMenuItemMasterWatcher(authParams) {
    return { type: GET_MENU_ITEM_MASTER_WATCHER, payload: authParams };
}

export function createMenuItemMasterWatcher(authParams, setOpen) {
    return { type: CREATE_MENU_ITEM_MASTER_WATCHER, payload: authParams, setOpen };
}

export function updateMenuItemMasterWatcher(id, data) {
    return { type: UPDATE_MENU_ITEM_MASTER_WATCHER, payload: data, id };
}

export function deleteMenuItemMasterWatcher(id, data) {
    return { type: DELETE_MENU_ITEM_MASTER_WATCHER, payload: data, id };
}


// Redux state changing actionCreators
export function menuItemMasterList(data) {
    return { type: MENU_ITEM_MASTER_LIST, payload: data };
}
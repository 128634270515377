import { LOGIN_WATCHER, UPDATE_LOGIN, GET_PROFILE_WATCHER, PROFILE_UPDATE_WATCHER, PASSWORD_UPDATE_WATCHER, FORGET_PASSWORD_WATCHER, GET_ROLE_NAMES_WATCHER, UPDATE_PROFILE, UPDATE_ROLE_NAMES, USER_CHECK_WATCHER, SEND_MAIL_WATCHER, USER_ABOUT_DETAILS_WATCHER, USER_ABOUT_DETAILS_LIST } from "../../actionTypes/profile";

// Worker triggering actionCreators

export function loginWatcher(data, goTo, remember) {
    return { type: LOGIN_WATCHER, payload: data, goTo, remember };
}

export function UserCheckWatcher(data) {
    return { type: USER_CHECK_WATCHER, payload: data };
}

export function getProfileWatcher(id, data) {
    return { type: GET_PROFILE_WATCHER, payload: data, id };
}

export function profileUpdateWatcher(id, data, getProfile) {
    return { type: PROFILE_UPDATE_WATCHER, payload: data, id, getProfile };
}

export function passwordUpdateWatcher(id, data, handleBack) {
    return { type: PASSWORD_UPDATE_WATCHER, payload: data, id, handleBack };
}

export function forgetPasswordWatcher(id, data) {
    return { type: FORGET_PASSWORD_WATCHER, payload: data, id };
}

export function getRoleNamesWatcher(id, data) {
    return { type: GET_ROLE_NAMES_WATCHER, payload: data, id };
}

export function senMailWatcher(data, setOpen) {
    return { type: SEND_MAIL_WATCHER, payload: data, setOpen };
}

export function getUserAboutDetailsWatcher(data) {
    return { type: USER_ABOUT_DETAILS_WATCHER, payload: data };
}

// Redux state changing actionCreators

export function updateLogin(data) {
    return { type: UPDATE_LOGIN, payload: data };
}

export function updateProfile(data) {
    return { type: UPDATE_PROFILE, payload: data };
}

export function updateRoleNames(data) {
    return { type: UPDATE_ROLE_NAMES, payload: data };
}

export function getUserAboutDetailsList(data) {
    return { type: USER_ABOUT_DETAILS_LIST, payload: data };
}

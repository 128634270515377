import React, { useEffect, useState } from 'react'
import { Box, Card, Typography } from '@mui/material'
import Breadcrumbs from '../../../components/breadcrumbs'
import FeedbackTable from '../../../components/admin/reports/feedbacks/table'
import FeedBackHeader from '../../../components/admin/reports/feedbacks/headerCard'
import { useDispatch, useSelector } from 'react-redux'
import { TABLE_HEAD } from '../../../components/admin/reports/feedbacks/table'
import { getRatingFeedbackWatcher, getUserHistoryWatcher } from '../../../redux/actionCreators/admin/reports'

export default function Feedbacks() {

    const { ReportsReducers: { RatingFeedbackList }, UserHomeReducers: { cafeteriaId }, ProfileReducers: { loginState }, } = useSelector((state) => state)

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [eventVal, setEventVal] = useState('')
    const [vendorState, setVendorState] = useState('')

    const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''
    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = RatingFeedbackList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    console.log('adminrep',vendorId, nRoleID)

    const handleSubmitAction = () => {
        let data = {
            "Action": "adminFeedList",
            "NDIVISIONID": cafeteriaId, 
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getRatingFeedbackWatcher(data))
    }

    
    useEffect(() => {
        setVendorState('')
       }, [cafeteriaId])

    return (
        <>
            <Box p="24px">
                <Breadcrumbs content={["Reports", "Feedback"]} />
                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant='t24_700' > Feedback </Typography>
                </Box>
                <Box p='10px 0'>
                    <FeedBackHeader handleSearch={handleSearch} searchText={searchText} rowData={RatingFeedbackList} columnData={TABLE_HEAD} handleSubmitAction={handleSubmitAction} setVendorState={setVendorState} />
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <FeedbackTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} vendorState={vendorState} setVendorState={setVendorState} />
                </Card>
            </Box>
        </>
    )
}

export const getInvoice = (data={}) =>{
    return `<!DOCTYPE html>
	<html>
	<head>
		<meta charset="utf-8" />
		<title>Contractor Invoice</title>
		<style type="text/css">
			body {
				font-family:sans-serif;
				font-size:9pt;
				line-height:1.5;
			}
			.invoice {
				width:100%;
			}
			.invoice td {
				padding:5px;
			}
			.d-block{
				display:block;
			}
			.table-bordered {
				border-collapse:collapse;
			}
			.table-bordered td {
				border: solid 1px #000;
			}        
			.thick-border {
				border:solid 2px #000!important;
			}
			.borderx-0 {
				border-left: none !important;
				border-right: none !important;
			}
			.bordery-0 {
				border-top: none !important;
				border-bottom: none !important;
			}
			.borderl-0 {
				border-left: none !important;
			}
			.th {
				font-weight: bold;
				text-align: center;
			}
			.h1 {
				font-size: 12pt;
			}
			.h2 {
				font-size:10pt;
			}
			.text-center {
				text-align: center;
			}

			.text-right {
				text-align: right;
			}

			.text-left {
				text-align: left;
			}
			.w-100 
			{
				width:100%;
			}
			.h-100 {
				height:100%;
			}
			.d-flex{
				display:flex;
			}
			.align-items-end {
				align-items:end;
			}
			.justify-content-end {
				justify-content:end;
			}
		</style>
	</head>
	<body>
		<div>
			<table class="invoice table-bordered">
				<colgroup>
					<col span="1" style="width:22.57%;" />
					<col span="1" style="width:9.46%;" />
					<col span="1" style="width:2.72%;" />
					<col span="1" style="width:13%;" />
					<col span="1" style="width:9.69%;" />
					<col span="1" style="width:0.86%;" />
					<col span="1" style="width:17.14%;" />
					<col span="1" style="width:6.15%;" />
					<col span="1" style="width:12.42%;" />
					<col span="1" style="width:1%;" />
					<col span="1" style="width:4.97%;" />
				</colgroup>
				<tr>
					<td class="text-center" colspan="11">
						<b class="h1 d-block">${data.CUSTOMER_NAME}</b>
						<!--Unit of ANJALI HOTELS PVT LTD<br /> --!>
						${data.CUSTOMER_ADDRESS}<br />
						GSTIN : ${data.CUSTOMER_GSTIN}<br />
					</td>
				</tr>
				<tr>
					<td class="text-center" colspan="11">
						<b class="h2">TAX INVOICE</b>
					</td>
				</tr>
				<tr>
					<td colspan="3" class="th text-left bordery-0">Bill to</td>
					<td colspan="2" class="bordery-0">
					</td>
					<td colspan="2" class="th">Invoice No.</td>
					<td colspan="4" class="th">Date</td>
				</tr>
				<tr>
					<td colspan="3" class="th bordery-0"></td>
					<td colspan="2" class="bordery-0"></td>
					<td colspan="2" class="text-center">${data.NO}</td>
					<td colspan="4" class="text-center">${data.ORDERDATE}</td>
				</tr>
				<tr>
					<td colspan="3" class="bordery-0">
						M/s. ${data.CUSTOMER_USERNAME}
						<br/>
						Employee ID : ${data.CUSTOMER_USERID}
                        <br/>
						<!-- ${data.CUSTOMER_ADDRESS} --!>
					</td>
					<td colspan="2" class="thick-border th">
					<!--Vendor Code : W65 --!>
					</td>
					<td colspan="6" class="bordery-0"></td>
				</tr>
				<tr>
					<td colspan="3" class="th text-left bordery-0">GST : ${data.CUSTOMER_GSTIN} </td>
					<td colspan="2" class="bordery-0"></td>
					<td colspan="6">Period/Date of Supply : ${data.ORDERDATE}</td>
				</tr>
				<tr>
					<td colspan="5" class="th text-left">Description of Goods</td>
					<td colspan="2" class="th">SAC/HSN Code</td>
					<td colspan="4" class="th text-right">Amount</td>
				</tr>
				<tr>
					<td colspan="5" class="text-left bordery-0">Preparation and/or supply services of food, edible preparations, alchoholic & non-alchocholic beverages for ODC</td>
					<td colspan="2" class="bordery-0 text-center">996333</td>
					<td colspan="4" class="text-right bordery-0">${data.FOOD_AMOUNT}</td>
				</tr>
				<tr>
					<td colspan="5" class="text-left bordery-0">Preparation and/or supply services of food, edible preparations, alchoholic & non-alchocholic beverages for ODC - Supporting services</td>
					<td colspan="2" class="bordery-0 text-center">996333</td>
					<td colspan="4" class="text-right bordery-0">${data.SERVICE_AMOUNT}</td>
				</tr>
				
				<tr>
					<td colspan="5" class="text-right bordery-0">Taxable Value</td>
					<td colspan="2" class="bordery-0">&nbsp;</td>
					<td colspan="4" class="text-right bordery-0">${data.TAXABLE}</td>
				</tr>
				<tr>
					<td colspan="5" class="text-right bordery-0">SGST Output Tax 2.5%</td>
					<td colspan="2" class="bordery-0">&nbsp;</td>
					<td colspan="4" class="text-right bordery-0">${data.SGST}</td>
				</tr>
				<tr>
					<td colspan="5" class="text-right bordery-0">CGST Output Tax 2.5%</td>
					<td colspan="2" class="bordery-0">&nbsp;</td>
					<td colspan="4" class="text-right bordery-0">${data.CGST}</td>
				</tr>
				
				<tr>
					<td colspan="5" class="text-right bordery-0">Sub Total</td>
					<td colspan="2" class="bordery-0">&nbsp;</td>
					<td colspan="4" class="text-right bordery-0">${data.SUBTOTAL}</td>
				</tr>
				<tr>
					<td colspan="5" class="text-right bordery-0">Round off</td>
					<td colspan="2" class="bordery-0">&nbsp;</td>
					<td colspan="4" class="text-right bordery-0">${data.ROUNDOFF}</td>
				</tr>
				<tr>
					<td colspan="5" class="th text-right bordery-0">Total</td>
					<td colspan="2" class="bordery-0">&nbsp;</td>
					<td colspan="4" class="th text-right">${data.TOTAL}</td>
				</tr>
				<tr>
					<td colspan="3" class="th text-left">Amount Chargeable (in words)</td>
					<td colspan="9" class="th text-left"><b class="d-block">Rupees : ${data.TOTAL_AS_TEXT}</td>
				</tr>
				${data.INFO}
				<tr>
					<td colspan="2" rowspan="2"></td>
					<td colspan="3" class="th">Taxable Value</td>
					<td class="th" colspan="3">GST</td>
					<td colspan="2" class="th">SGST</td>
					<!--<td class="th">Cess</td>-->
					<td colspan="3" class="th text-left">Total</td>
				</tr>
				
				<tr>
					<td colspan="3" class="text-center">${data.TAXABLE}</td>
					<td class="text-center" colspan="3">${data.CGST}</td>
					<td colspan="2" class="text-center">${data.SGST}</td>
					<!--<td class="text-right">{{CESS}}</td>-->
					<td colspan="3" class="text-center">${data.TOTAL}</td>
				</tr>
				<tr>
					<td colspan="2">
					<!--CIN NO : U55101KL1971PTC002353<br/> --!>
					<!--Company's PAN : AACCA2654H<br /> --!>
						Bank Details : ${data.BANKNAME}<br />
						Account Name : ${data.ACCOUNTNAME}<br />
						Bank Account No : ${data.ACCOUNTNO}<br />
						IFSC : ${data.IFSCNO}<br />
						Branch Name: ${data.BRANCHNAME}
					</td>
					<td colspan="8" class="borderx-0" valign="bottom">
						<span class="d-block text-right">Authorised Signatory</span>
					</td>
					<td class="borderl-0">

					</td>
				</tr>
			</table>
		</div>
	</body>
	</html>`
}


{/* <script type="text/javascript">
window.onload = function () {
	window.print();
}
</script> */}
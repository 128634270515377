import { UPDATE_LOGIN, UPDATE_PROFILE, UPDATE_ROLE_NAMES, USER_ABOUT_DETAILS_LIST } from "../../actionTypes/profile";

const intialState = {
  loginState: JSON.parse(sessionStorage.getItem("login")) ||
              JSON.parse(localStorage.getItem("login")) || [],
  profile: [],
  roleNames: [],
  userAboutDetails: []
};

export default function LoaderReducers(state = intialState, action) {
  switch (action.type) {

    case UPDATE_LOGIN:
      return { ...state, loginState: action.payload };

    case UPDATE_PROFILE:
      return { ...state, profile: action.payload };

    case UPDATE_ROLE_NAMES:
      return { ...state, roleNames: action.payload };

    case USER_ABOUT_DETAILS_LIST:
      return { ...state, userAboutDetails: action.payload }

    default:
      return state;
  }
}

import React from 'react';
import { useState} from 'react';
import Scrollbar from '../../../Scrollbar';
import GuestOrderHistoryTableHead from './tableHead';
import { useSelector } from 'react-redux'
// import { getOrderHistoryWatcher } from '../../../../redux/actionCreators/admin/reports';
import GuestOrderHistoryTableRow from './tableRow';
// material
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Pagination,
    Box,
    Typography
} from '@mui/material';

export const TABLE_HEAD = [
    { id: 'ID', label: 'User ID', alignRight: false, title: 'User ID', field:'ID' },
    { id: 'User Name', label: 'User Name', alignRight: false, title: 'User Name', field:'Employee Name' },
    { id: 'Division', label: 'Division', alignRight: false, title: 'Division', field:'cDivisionName' },
    { id: 'Created Date', label: 'Order Date', alignRight: false, title: 'Order Date', field:'OrderDate' },
    { id: 'Breakfast', label: 'Breakfast', alignRight: false, title: 'Breakfast', field:'Breakfast' },
    { id: 'Lunch', label: 'Lunch', alignRight: false, title: 'Lunch', field:'Lunch' },
    { id: 'Snacks', label: 'Snacks', alignRight: false, title: 'Snacks', field:'Snacks' },
    { id: 'Dinner', label: 'Dinner', alignRight: false, title: 'Dinner', field:'Dinner' },
    { id: 'Early morning snacks', label: 'Early morning snacks', alignRight: false, title: 'Early morning snacks', field:'Early morning snacks' },
    // { id: 'Snacks(Take away)', label: 'Snacks(Take away)', alignRight: false, title: 'Snacks(Take away)', field:'Snacks(Take away)' },
    { id: 'Status', label: 'Status', alignRight: false, title: 'Status', field:'Status' }
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const GuestOrderHistoryTable = ({ searchVal, searchText, page, setPage }) => {

    const { ReportsReducers: { GuestOrderHistoryList } } = useSelector((state) => state)

    let firstIndex = 0;

    // const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const tableData = searchText ? searchVal : GuestOrderHistoryList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{overflow:'unset'}} >
                        <Table>
                            <GuestOrderHistoryTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                //   rowCount={USERLIST.length}
                                //   numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)
                                    // .slice(page * 5, page * 5 + 5)
                                    .map((m, i) => {
                                        return (
                                            <GuestOrderHistoryTableRow key={i} rowData={m} index={i} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default GuestOrderHistoryTable;
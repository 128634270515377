import React, { useState, useEffect, useContext } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import SaleReportHeader from '../../../components/foodVendor/reports/saleReport/headerCard';
import SaleReportTable from '../../../components/admin/reports/saleReport/saleReportTable';
import { useDispatch, useSelector } from 'react-redux';
import { getSaleMenuWatcher } from '../../../redux/actionCreators/admin/reports';
import { TABLE_HEAD } from '../../../components/admin/reports/saleReport/saleReportTable';
import { getConvertedDate, getConvertedDate4 } from '../../../utils/common';
import { getDropDownWatcher } from '../../../redux/actionCreators/admin/employeeManagement';
import moment from 'moment/moment';
import Context from '../../../context/context';

const AdminSaleReport = () => {

    const { ReportsReducers: { SaleReportList }, UserHomeReducers: { cafeteriaId }, GuestRoleReducers: { guestTypeList }, ProfileReducers: {loginState} } = useSelector((state) => state)
    
    const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''
    
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    // const [selectedType, setSelectedType] = useState('');
    const [page, setPage] = useState(1);
    const past = new Date()
    past.setDate(past.getDate() - 7)
    const [fromDate, setFromDate] = React.useState(past);
    const [toDate, setToDate] = React.useState(new Date());
    const NDIVISIONID = cafeteriaId
    const { state, setState } = useContext(Context);
    const [vendorState, setVendorState] = useState('')
    const [guestType, setGuestType] = useState('1')
    const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })


    // console.log('sdeffefer',state,vendorId, guestTypeList)

    useEffect(() => {
        let data = {
            "Action": "MealMenuList",
            "Fdate": getConvertedDate4(fromDate),
            "Tdate": getConvertedDate4(toDate),
            "NDIVISIONID": NDIVISIONID,
            CVENDORID: nRoleID === 3 ? String(vendorId) : 'null'
            // CVENDORID: state !== "" ? `${state}` : 'null'
            
        }
        dispatch(getSaleMenuWatcher(data))
    }, [dispatch])

    useEffect(() => {
        setFromDate(past)
        setToDate(new Date())
        setVendorState('')
    }, [cafeteriaId])

    // useEffect(() => {
    //     let data1 = {
    //         "Action": "MealMenuListCon",
    //         "Fdate": "11/06/2021",
    //         "Tdate": "11/06/2022"
    //     }
    //     dispatch(getSaleMenuWatcher(data1))
    // }, [])

    // useEffect(() => {
    //     if (guestTypeList === "1") {
    //         let data = {
    //             "Action": "MealMenuList",
    //             "Fdate": getConvertedDate4(fromDate),
    //             "Tdate": getConvertedDate4(toDate),
    //             "NDIVISIONID": NDIVISIONID,
    //             CVENDORID: state !== "" ? `${state}` : 'null'
    //         }
    //         dispatch(getSaleMenuWatcher(data))
    //     } else if (guestTypeList === "2") {
    //         let data1 = {
    //             "Action": "MealMenuListCon",
    //             "Fdate": getConvertedDate4(fromDate),
    //             "Tdate": getConvertedDate4(toDate),
    //             "NDIVISIONID": NDIVISIONID,
    //             CVENDORID: state !== "" ? `${state}` : 'null'
    //         }
    //         dispatch(getSaleMenuWatcher(data1))
    //     } else if (guestTypeList === "3"){
    //         let dataSplGuest = {
    //             "Action": "MealMenuListSpecial",
    //             "Fdate": getConvertedDate4(fromDate),
    //             "Tdate": getConvertedDate4(toDate),
    //             "NDIVISIONID": NDIVISIONID,
    //             CVENDORID: state !== "" ? `${state}` : 'null'
    //         }
    //         dispatch(getSaleMenuWatcher(dataSplGuest))
    //     } else {
    //         let dataNormalGuest = {
    //             "Action": "MealMenuListNormal",
    //             "Fdate": getConvertedDate4(fromDate),
    //             "Tdate": getConvertedDate4(toDate),
    //             "NDIVISIONID": NDIVISIONID,
    //             CVENDORID: state !== "" ? `${state}` : 'null'
    //         }
    //         dispatch(getSaleMenuWatcher(dataNormalGuest))
    //     }
    // }, [dispatch, guestTypeList])
    

    useEffect(()=>{
        dispatch(getDropDownWatcher())
    },[dispatch])


    // const selectedTypeHandler = useCallback((val) => {
    //     setSelectedType(val)
    //     if (val === "Employee") {
    //         let data = {
    //             "Action": "MealMenuList",
    //             "Fdate": "11/06/2021",
    //             "Tdate": "11/06/2022"
    //         }
    //         dispatch(getSaleMenuWatcher(data))
    //     } else {
    //         let data1 = {
    //             "Action": "MealMenuListCon",
    //             "Fdate": "11/06/2021",
    //             "Tdate": "11/06/2022"
    //         }
    //         dispatch(getSaleMenuWatcher(data1))
    //     }
    // }, [selectedType])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = SaleReportList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        let actionName = guestType === '1' ? 'MealMenuList' : guestType === '2' ? 'MealMenuListCon' : guestType === '3' ? 'MealMenuListSpecial' : 'MealMenuListNormal'
        let currentDate = new Date()
        let curDate = getConvertedDate4(currentDate)
        // console.log('guestTypeList',vendorState)
        // if(toDate >= fromDate && toDate <= curDate && fromDate <= curDate){
            let data = {
                Action: actionName,
                Fdate: fromDate,
                Tdate: toDate,
                "NDIVISIONID": NDIVISIONID !== '' ? NDIVISIONID : '2',
                // CVENDORID: state !== "" ? `${state}` : 'null'
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null') 
            }
            dispatch(getSaleMenuWatcher(data))
            setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
            setPage(1)
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true })
        // }
    }

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Sale Report"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Sale Report</Typography>
                </Box>
                <Box p='10px 0'>
                    <SaleReportHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={SaleReportList} columnData={TABLE_HEAD}  fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} setVendorState={setVendorState} setGuestType={setGuestType} invalidErr={invalidErr} setInvalidErr={setInvalidErr}/>
                </Box>

                {Boolean(SaleReportList.length) && SaleReportList[0]?.map((m) => (
                    <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", marginBottom: '20px', marginTop: '30px', paddingBottom: '20px' }} key={m.nID}>
                        <SaleReportTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} table_header={m} NDIVISIONID={NDIVISIONID} vendorState={vendorState} />
                    </Card>
                ))}
                
                {/* {(NDIVISIONID.includes("4") && guestTypeList === "3") &&
                    (<>
                        {Boolean(SaleReportList.length) && SaleReportList[2]?.map((m) => (
                            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", marginBottom: '20px', marginTop: '30px', paddingBottom: '20px' }} key={m.nID}>
                                <SaleReportTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} table_header={m} NDIVISIONID={NDIVISIONID} />
                            </Card>
                        ))}
                    </>)
                } */}

            </Box>
        </>
    )
}

export default AdminSaleReport;
import React, { useState, useRef } from "react";
import { Typography, Card, Grid, Button } from "@mui/material";
import CssTextField from "../../components/overrides/longSearchField";
// import BlackButton from "../../components/overrides/blackButton";
import { useDispatch, useSelector } from "react-redux";
import { profileUpdateWatcher } from "../../redux/actionCreators/profile";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileUploadButton from "../../components/overrides/fileUploadButton"
import { Input } from "../../components/admin/kitchen/menuMaster/NewMenuMaster";
import CloseButton from "../../components/overrides/closeButton";

const AdminUserDetails = () => {
  const {
    ProfileReducers: { profile, loginState },
  } = useSelector((state) => state);

  const { cEmailID, cUserName, cEmployeeID, cImage } = profile[0] || {};
  const [imageName, setImageName] = useState('')
  // const [imageFile, setImageFile] = useState('')
  const tempinitsform = useRef();

  const nUserID = loginState[0]?.nID || 0

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const Schema = Yup.object().shape({
    CEMAILID: Yup.string("Enter your EmailId")
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      CEMAILID: '',
      cImage: ''
    },
    validationSchema: Schema,

    onSubmit: () => {
      let { CEMAILID, cImage } = { ...formik.values };
      let data = {
        ACTION: "UserProfileUpdate",
        CEMAILID,
        cImage: cImage
      };

      const getProfile = {
        "ACTION": "ProfileUpdate"
      }

      dispatch(profileUpdateWatcher(nUserID, data, getProfile));
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  const initsform = () => {
    if(cEmailID){
      setFieldValue("CEMAILID", cEmailID);
      setFieldValue("cImage", cImage);
    }
  };
  
  tempinitsform.current = initsform;
  
  useEffect(() => {
     tempinitsform.current();
  }, [cEmailID]);

  // useEffect(() => {
  //   if(cEmailID){
  //     setFieldValue("CEMAILID", cEmailID);
  //     setFieldValue("cImage", cImage);
  //   }
  // }, [cEmailID]);

  const handlePass = () => {
    const getPath = pathname.split("/")[1];
    navigate(`/${getPath}/changePassword`);
  };

  const handleBack = () => {
    const getPath = pathname.split("/")[1];
    navigate(`/${getPath}`);
  };

  const onFileChange = (e) => {
    if (e) {
      var file = e.target.files[0];
      var name = e.target.files[0].name;
      setImageName(name);
      // setImageFile(file);
      var reader = new FileReader();
      reader.onloadend = function () {
        // setImageFile(reader.result)
        setFieldValue("cImage", reader.result)
      }
      reader.readAsDataURL(file);
    }
  }

  return (
    <>
      <Card
        style={{
          boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`,
        }}
      >
        <Grid container spacing={3} sx={{ padding: "30px" }}>
          <Grid item lg={6} xs={6} sx={{ justifyContent: "left" }}>
            <div>
              <Typography variant="t20_600">User Details</Typography>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          display="flex"
          flexDirection="row"
          justifyContent="left"
          sx={{ padding: "30px" }}
        >
          <Grid item lg={6} xs={12} sm={6}>
            <Typography variant="t14_600"> User Name </Typography>
            <CssTextField
              sx={{
                mt: '7px'
              }}
              placeholder="User Name"
              name="userName"
              fullWidth
              value={cUserName || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={6}>
            <Typography variant="t14_600"> User Id </Typography>
            <CssTextField
              sx={{
                mt: '7px'
              }}
              placeholder="User Id"
              name="userId"
              fullWidth
              value={cEmployeeID || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={6}>
            <Typography variant="t14_600">
              Personal Mail Id ( Use Only Personal Mail Id )
            </Typography>
            <CssTextField
              sx={{
                mt: '7px'
              }}
              placeholder="Email"
              name="email"
              fullWidth
              {...getFieldProps("CEMAILID")}
              error={Boolean(touched.CEMAILID && errors.CEMAILID)}
              helperText={touched.CEMAILID && errors.CEMAILID}
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={6}>
            <Typography variant="t14_600">
              Profile Image
            </Typography>
            <label htmlFor="contained-button-file">
              <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={onFileChange} />
              <FileUploadButton
                variant="contained"
                component="span"
                fullWidth
                sx={{mt: '7px'}}
                endIcon={<FileUploadOutlinedIcon sx={{ color: 'common.black' }} />}
                type='button'
              >
                <Typography variant='t16_600' color='grey.600'>
                  {imageName ? imageName : cImage}
                </Typography>
              </FileUploadButton >
            </label>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{ padding: "30px" }}
          spacing={3}
        >
          <Grid item xs={12} md={6}>
            <Box display="flex" height='100%' alignItems="center">
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={handlePass}
                variant="t14_600"
                color="common.weekTab"
              >
                Change Password
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex">
              <Box ml="auto">
                <CloseButton
                  sx={{
                    width: "120px",
                    height: "42px",
                    mr: "20px",
                  }}
                  variant="contained"
                  onClick={handleBack}
                >
                  <Typography variant="t14_400" color="common.black">
                    Cancel
                  </Typography>
                </CloseButton>
                <Button
                  sx={{
                    width: "120px",
                    height: "42px",
                    left: "0px",
                    top: "0px",
                    backgroundColor: 'common.Bblack',
                    '&:hover':{
                    backgroundColor: 'common.Bblack',
                    }
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  <Typography variant="t14_400" color="inherit">
                    Update
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AdminUserDetails;

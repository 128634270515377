import { Button, Card, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import ImageTemp from "../../../components/user/dishesCard/imageTemp";
import BlackButton from "../../../components/overrides/blackButton";
import { clearCartWatcher } from "../../../redux/actionCreators/user/home";
import { useDispatch, useSelector } from "react-redux";
import { getGst, gstRate } from "../../../utils/gst";
import { orderBookingPaynimoWatcher, orderBookingWatcher } from "../../../redux/actionCreators/user/currentOrder";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../../components/user/confirmDialog/confirmDialog";

const ViewCart = () => {

  const { UserHomeReducers: { cartList, getOrderTotalCost }, ProfileReducers: { loginState }, GuestRoleReducers: { guestTypeList } } = useSelector(state => state)
  
  // console.log("guestTypeList", guestTypeList)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const nUserID = loginState[0]?.nID || 0
  const cRFID = loginState[0]?.cRFID || 0
  const roleName = loginState[0]?.cRoleName


  
  const vendorId = sessionStorage.getItem('vendorId')
  
  const [openCD, setOpenCD] = useState(false);

  const mealType = cartList[0]?.Cafeteria

  const clearCartApi = () => {
    const cartData = {
      "ACTION": "CHECKLIST",
      nUserID
    }
    dispatch(clearCartWatcher(nUserID, cartData))
  }

  const handleCheckout = () => {

// const data={
  
//     "ACTION": "INSERT",
//     "UserID": 889,
//     "RFID": "1004104",
//     "nGst": "4.00",
//     "Total": 80,
//     "OrderBY": "E",
//     "CreatedBy": 889,
//     "CVENDORID": "1",
//     "cEmailID": "emailukp@gmail.com",
//     "cMobileNumber": "1234567890",
//     "cEmployeeName": "UDAYAKUMAR PARAMASIVAM",
//     "cafeteriaId": ""

// }
    const data = {
      "ACTION": "INSERT",
      "UserID": nUserID,
      "RFID": cRFID,
      "nGst": getGst(getTotal()),
      "Total": getTotal(),
      "OrderBY": (roleName === 'Employee') ? "E" : (roleName === 'FoodClerk') 
        ? 'FC' : (roleName === 'NewJoiners') ? 'I' : (roleName === 'SpecialGuest')? 'SG' :'S',
      "CreatedBy": nUserID,
      "CVENDORID": vendorId
    }
    dispatch(orderBookingWatcher(data, dispatch, navigate))

    // dispatch(orderBookingPaynimoWatcher(data, dispatch, navigate))
  }

  const handleDialogOpen = () => {
    setOpenCD(true)
  }

  // const dispatchPayment = (data) => {
  //   dispatch(paymentWatcher(data, pushCurrent))
  // }

  // const pushCurrent = () => {
  //   navigate('/user/currentOrder')
  // }

  const getTotal = () => {
    const total = getOrderTotalCost.length && getOrderTotalCost[0].nTotalost
    return total
  }

  const addItems = () => {
    navigate(`/user/menu`)
  }

  const sumData = cartList.map(o => o.nQuantity).reduce((a, c) => { return a + c }, 0);

  const totalCart = sumData

  return (
    <>
    <Box p="24px">
      <Breadcrumbs content={["View Cart"]} />

      <Box mt="10px" display='flex' alignItems='center'>
        <Typography variant="t24_700">View Cart</Typography>

        {/* <Button onClick={addItems} bgcolor="primary" variant="contained" sx={{ height: 42, width: 120, ml: 'auto' }}>
          <Typography variant='t14_400'>
            Add Items
          </Typography>
        </Button> */}
        <BlackButton onClick={clearCartApi} sx={{ height: 42, width: 120, ml: 'auto' }}>
          <Typography variant='t14_400'>
            Clear All
          </Typography>
        </BlackButton>
      </Box>

      <Box mt="20px">
        <Card
          sx={{
            bgcolor: "common.white",
            borderRadius: "16px 16px 0px 0px",
            p: "30px"
          }}
        >
          <Grid container spacing={4}>
            {cartList.map((d) => (
              <Grid
                display='flex'
                flexDirection='column'
                key={d.NID} item xs={12} sm={6} md={4} lg={4} xl={3}>
                <ImageTemp isViewCart={true} value={{ ...d, nTotalRate: d.nTotalPrice, nID: d.nMenuID }} />
                <Divider sx={{ mt: "20px" }} />
                <Box mt="16px" display="flex" justifyContent="center">
                  <Typography variant="t14_400" color="grey.500">
                    {d.dOrderDate}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Grid container mt="54px" spacing={2}>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column">
                <Typography variant="t20_600">Order Summary</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Box display="flex" textAlign="right" flexDirection="column">
                    <Typography variant="t18_600">Sub Total</Typography>
                    <Typography mt="8px" color="grey.500" variant="t14_400">
                      GST {gstRate}%
                    </Typography>
                    <Typography mt="28px" variant="t18_600">
                      Total
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" textAlign="right" flexDirection="column">
                    <Typography variant="t18_600">₹{getTotal()}</Typography>
                    <Typography mt="8px" color="grey.500" variant="t18_600">
                      ₹{getGst(getTotal())}
                    </Typography>
                    <Typography mt="22px" color="primary" variant="t24_600">
                      ₹{+getTotal() + +getGst(getTotal())}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box display='flex' mt='45px'>
            <Button onClick={handleDialogOpen} sx={{ ml: 'auto', minWidth: 223, height: 64 }} variant="contained" 
            
            // disabled={ guestTypeList === 2 && totalCart < 15 }
            >
              <Typography>
                Place order
              </Typography>
              </Button>
          </Box>
        </Card>
      </Box>
    </Box>
    {
        openCD &&
          <ConfirmDialog openCD={openCD} setOpenCD={setOpenCD} handleCheckout={handleCheckout} mealType={mealType}/>
    }
    </>
  );
};

export default ViewCart;

import http from "../../http-common";

class MenuItemMasterService {

    getAll(data) {
        return http.post("/admin/kitchen/getMenuItemMaster", data);
    }
    get(id) {
        return http.get(`/admin/kitchen/menuItemMaster/${id}`);
    }
    create(data) {
        return http.post("/admin/kitchen/addMenuItemMaster", data);
    }
    update(id, data) {
        return http.put(`/admin/kitchen/menuItemMaster/${id}`, data);
    }
    delete(id, data) {
        return http.delete(`/admin/kitchen/menuItemMaster/${id}`, data);
    }

}

export default new MenuItemMasterService();
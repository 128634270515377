import React from 'react';
import { useState } from 'react';
import VendorChildHead from './vendorChildHead';
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Autocomplete
} from '@mui/material';
import EditTextField from '../../../overrides/editField';
import { useSelector } from 'react-redux';


const TABLE_HEAD = [
    { id: 'landLineNumber ', label: 'Landline Number ', alignRight: false },
    { id: 'mobileNumber', label: 'Mobile Number', alignRight: false },
    { id: 'emailID', label: 'Email ID', alignRight: false },
    { id: 'divisionId', label: 'Division', alignRight: false },
];


// function descendingComparator(a, b, orderBy) {
//     if (b[orderBy] < a[orderBy]) {
//         return -1;
//     }
//     if (b[orderBy] > a[orderBy]) {
//         return 1;
//     }
//     return 0;
// }

// function getComparator(order, orderBy) {
//     return order === 'desc'
//         ? (a, b) => descendingComparator(a, b, orderBy)
//         : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator) {
//     const stabilizedThis = array.map((el, index) => [el, index]);
//     stabilizedThis.sort((a, b) => {
//         const order = comparator(a[0], b[0]);
//         if (order !== 0) return order;
//         return a[1] - b[1];
//     });
//     return stabilizedThis.map((el) => el[0]);
// }

const VendorChildTable = ({ expandOpen, rowData, handleEditChange, status, editData, setEditData, formErrors, phoneValid, emailValid }) => {

    // const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    // const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const { EmployeeReducers: { divisionList } } = useSelector((state) => state)

    let division = divisionList.find(m => m.id === rowData.nDivisionID) || {}

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    // let arr = []

    // const filtered = applySortFilter(arr, getComparator(order, orderBy));


    return (
        <>
            {expandOpen &&
                <TableContainer sx={{ overflow:'unset' }}>
                    <Table>
                        <VendorChildHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            //   rowCount={USERLIST.length}
                            //   numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            <TableRow
                                // hover
                                tabIndex={-1}
                                sx={{ '& > *': { borderBottom: 'unset' } }}
                            >
                                {status === 'list' ?
                                    <>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.CLANDLINENUMBER}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.CMOBILENUMBER}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.CEMAILID}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.cDivisionName}
                                            </Typography>
                                        </TableCell>
                                    </>
                                    :
                                    <>
                                        <TableCell align="left">
                                            <EditTextField
                                                name='CLANDLINENUMBER'
                                                value={editData.CLANDLINENUMBER}
                                                onChange={handleEditChange}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <EditTextField
                                                name='CMOBILENUMBER'
                                                value={editData.CMOBILENUMBER}
                                                onChange={handleEditChange}
                                                error={editData.CMOBILENUMBER ? phoneValid ? false : true : formErrors.CMOBILENUMBER !== null ? true : false}
                                                helperText={editData.CMOBILENUMBER ? phoneValid ? '' : 'Mobile Number is Invalid' : formErrors.CMOBILENUMBER !== null ? 'Mobile Number is required' : ''}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <EditTextField
                                                name='CEMAILID'
                                                value={editData.CEMAILID}
                                                onChange={handleEditChange}
                                                error={editData.CEMAILID ? emailValid ? false : true : formErrors.CEMAILID !== null ? true : false}
                                                helperText={editData.CEMAILID ? emailValid ? '' : 'Email Id is Invalid' : formErrors.CEMAILID !== null ? 'Email Id is required' : ''}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                name="nDivisionID"
                                                fullWidth
                                                options={divisionList}
                                                size="small"
                                                autoHighlight
                                                defaultValue={Object.values(division).length === 0 ? divisionList.find(m => m.id === 0) : division}
                                                // value={roleName.name}
                                                onChange={(event, value) => {
                                                    if(value.id !== 0){
                                                        setEditData({ ...editData, nDivisionID: value.id });
                                                    }
                                                }}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => <EditTextField {...params}
                                                    sx={{ minWidth: '160px' }}
                                                    name='nDivisionID'
                                                />}
                                            />
                                        </TableCell>
                                    </>
                                }
                            </TableRow>
                        </TableBody>

                    </Table>
                </TableContainer>
            }
        </>
    )
}

export default VendorChildTable;
// USER Watcher Actions
    //dashboard
    export const GET_CONTRACTOR_DASHBOARD_WATCHER = "GET_CONTRACTOR_DASHBOARD_WATCHER";

    export const CREATE_CONTRACTOR_ORDER_WATCHER = "CREATE_CONTRACTOR_ORDER_WATCHER";
    export const CANCEL_CONTRACTOR_ORDER_WATCHER = "CANCEL_CONTRACTOR_ORDER_WATCHER";

    //reports
    export const GET_BOOKING_HISTORY_WATCHER = "GET_BOOKING_HISTORY_WATCHER";

    //contractor role >> Swipe History

    export const GET_CROLE_SWIPE_HISTORY_WATCHER = "GET_CROLE_SWIPE_HISTORY_WATCHER"
    

// USER Reducer Actions
    //dashboard
    export const BOOKING_HISTORY_LIST = "BOOKING_HISTORY_LIST";

    //reports
    export const CONTRACTOR_DASHBOARD_DATA = "CONTRACTOR_DASHBOARD_DATA";

    //contractor role >> Swipe History
    export const CROLE_SWIPE_HISTORY_LIST = "CROLE_SWIPE_HISTORY_LIST"

// ADMIN Watcher Actions

// DASHBOARD

export const GET_SWIPE_CONTRACTOR_WATCHER = "GET_SWIPE_CONTRACTOR_WATCHER";

export const GET_SWIPE_EMPLOYEE_WATCHER = "GET_SWIPE_EMPLOYEE_WATCHER";

export const GET_COUNTER_ADMIN_WATCHER = "GET_COUNTER_ADMIN_WATCHER";

export const GET_FOOD_DETAILS_WATCHER = "GET_FOOD_DETAILS_WATCHER";

export const UPDATE_DELIVERY_WATCHER = "UPDATE_DELIVERY_WATCHER";

//.............................................................................................

// ADMIN Reducer Actions

export const SWIPE_CONTRACTOR_LIST = "SWIPE_CONTRACTOR_LIST";

export const SWIPE_EMPLOYEE_LIST = "SWIPE_EMPLOYEE_LIST";

export const SWIPE_LIST = "SWIPE_LIST";
import React from 'react'
import { Box, Typography, Card } from "@mui/material";
import TotalQuantityTable from './totalQuantityTable';

const TotQtyTableIndex = ({ tblData, userType }) => {

    // const TotalQuantity = tblData.reduce((a, v) => +a + +v.Quantity, 0)

    return (
        <>

            {/* <Box display='flex' justifyContent='right' >
                <Box display='flex' justifyContent='center' alignItems='center' bgcolor='common.black' height='37px' width='100px' borderRadius="6px 6px 0px 0px">
                    <Typography variant="t18_800" color='common.white'>
                        {TotalQuantity}
                    </Typography>
                </Box>
            </Box> */}

            <Box display='flex' >
                <Box display='flex' justifyContent='center' alignItems='center' bgcolor='primary.main' width='43px' borderRadius="6px 0px 0px 6px" >
                    <Typography variant="t14_600__18" color='common.white' style={{ transform: "rotate(-90deg)",whiteSpace:'nowrap'}} >
                        {'OverAll Booking'}
                    </Typography>
                </Box>

                <Card sx={{ borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`, borderRight: (theme) => `1px solid ${theme.palette.grey[300]}`, width: '100%', height: '100%', borderRadius: "0px 0px 6px 0px" }}>
                    <TotalQuantityTable tableData={tblData} userType={userType} />
                </Card>
            </Box>
        </>
    )
}

export default TotQtyTableIndex
import React, { useContext, useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow
} from '@mui/material';
// import { EditOutlined } from '@mui/icons-material';
import ActionButtons from '../actionButton';
import { useDispatch, useSelector } from 'react-redux'
import EditTextField from '../../../overrides/editField';
import { updateFeedbackWatcher } from '../../../../redux/actionCreators/admin/reports';
import Context from '../../../../context/context';

const FeedbackTableRow = ({ rowData, index }) => {

    const { UserHomeReducers: { cafeteriaId }, ProfileReducers: { loginState } } = useSelector((state) => state)
    const { state } = useContext(Context);

    // const vendorId = sessionStorage.getItem('vendorId')
    const dispatch = useDispatch();

    const [status, setStatus] = useState('list');
    const [editData, setEditData] = useState({});
    const vendorId = loginState[0]?.nVendorID || ''

    const handleEditChange = (e) => {
        const { name, value } = e.target
        setEditData({ ...editData, [name]: value })
    }

    const handleEdit = () => {
        let data = { ...rowData }
        //data.cMenuName = data.cMenuName[0]
        setEditData(data);
        setStatus('edit');
    }

    const tickIconFunction = async () => {
        let values = { ...editData }
        let data = {
            "Action": "replyFeed",
            "nReply": values.Reply,
            CVENDORID: `${vendorId}`
        }
        await dispatch(updateFeedbackWatcher(rowData.nID, data, cafeteriaId, state));
        setStatus('list');
    }

    const cancelIconFunction = () => {
        setEditData({});
        setStatus('list');
    }

    return (
        <>
            <TableRow
                hover={status === 'list' ? true : false}
                tabIndex={-1}
            >
                {status === 'list' ?
                    <>
                        <TableCell align="left" sx={{ width: '200px' }}>
                            <Typography variant='t14_600'>
                                {rowData.EmployeeName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '200px' }}>
                            <Typography variant='t14_600'>
                                {rowData.MenuName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '200px' }}>
                            <Typography variant='t14_600'>
                                {rowData.Command}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '400px' }}>
                            <Typography variant='t14_600'>
                                {rowData.Reply}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '200px' }}>
                            <Typography variant='t14_600'>
                                {rowData.FeedDate}
                            </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ width: '200px' }}>
                            <Typography variant='t14_600'>
                                {rowData.cVendorName}
                            </Typography>
                        </TableCell>
                    </>
                    :
                    <>
                        <TableCell>
                            <Typography>
                                {rowData.EmployeeName}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {rowData.MenuName}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {rowData.Command}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <EditTextField
                                name='Reply'
                                value={editData.Reply || ""  }
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {rowData.FeedDate}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {rowData.cVendorName}
                            </Typography>
                        </TableCell>
                    </>
                }
                <TableCell align="left" sx={{ width: '150px' }}>
                    <ActionButtons type={'feedback'} setStatus={setStatus} status={status} setEditData={setEditData} rowData={rowData} tickIconFunction={tickIconFunction}
                        handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} />
                </TableCell>
            </TableRow>
        </>
    )
}

export default FeedbackTableRow
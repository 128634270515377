import React, { useEffect, useRef } from 'react'
import { Autocomplete, Button, Grid, Stack, Typography } from '@mui/material'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CssTextField from '../overrides/coloredTextField';
import { useDispatch } from 'react-redux';
import { getSwipeEmployeeWatcher } from '../../redux/actionCreators/screen/screen';

export const Opt = [
    {
        id: '1',
        name: 'Employee'
    },
    {
        id: '2',
        name: 'Contractor'
    },
    {
        id: '3',
        name: 'Special Guest'
    },
    {
        id: '4',
        name: 'Normal Guest'
    },
]

export default function Employee({ setChange }) {

    const dispatch = useDispatch()
    const tempinitsform = useRef();

    // const { SwipeReducers: { SwipeEmployeeList } } = useSelector((state) => state)

    const initsform = () => {
        let data = {
            "nAccessPointID": "916"
        }
        dispatch(getSwipeEmployeeWatcher(data))
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, []);

    // useEffect(() => {
    //     let data = {
    //         "nAccessPointID": "916"
    //     }
    //     dispatch(getSwipeEmployeeWatcher(data))
    // }, [])


    return (
        <>
            <Grid container spacing={3} minHeight='500px' sx={{ padding: '20px', display: 'flex' }} >
                <Grid item lg={4} xs={4} md={4} sm={6} sx={{ justifyContent: 'left' }}>
                    <Typography variant="t24_700">Dinner</Typography>
                </Grid>
                <Grid item lg={8} xs={8} md={8} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Autocomplete
                            onChange={() => setChange(true)}
                            disablePortal
                            id="combo-box-demo"
                            name="selectMeal"
                            //value={formValues.selectType === null ? '' : formValues.selectType}
                            fullWidth
                            options={Opt}
                            size="medium"
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <CssTextField sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '48px',
                                    minWidth: '187px',
                                    backgroundColor: 'common.sideBar'
                                },
                                '& label': {
                                    fontSize: '.9rem',
                                    color: 'common.white',
                                },
                            }} {...params} label="User" />}
                        />
                        <Button variant='contained' sx={{ height: '50px', minWidth: '200px' }} endIcon={<DoubleArrowIcon />} >
                            <Typography variant='t16_400_1'> Refresh </Typography>
                        </Button>
                    </Stack>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant='t24_700'>Waiting for Next Swipe</Typography>
                </Grid>
            </Grid>
        </>
    )
}

import { KIOSK_ADD_CART_WATCHER, KIOSK_ADD_MINUS_WATCHER, KIOSK_CLEAR_CART_WATCHER, KIOSK_GET_CART_WATCHER, KIOSK_GET_ORDER_TOTALCOST_WATCHER, KIOSK_UPDATE_CART, KIOSK_UPDATE_ORDER_TOTALCOST, KIOSK_UPDATE_VIEW_CART, KIOSK_VIEW_CART_WATCHER, KIOSK_WATCHER, KIOSK_UPDATE, KIOSK_BOOKING_WATCHER } from "../../actionTypes/user";

// Worker triggering actionCreators

export function kioskAddCartWatcher(data, cartData, userId) {
    return { type: KIOSK_ADD_CART_WATCHER, payload: data, cartData, userId };
}

export function kioskGetCartWatcher(data, viewCart) {
    return { type: KIOSK_GET_CART_WATCHER, payload: data, viewCart };
}

export function kioskAddMinusWatcher(id, data, cartData, userId) {
    return { type: KIOSK_ADD_MINUS_WATCHER, payload: data, id, cartData, userId };
}

export function kioskClearCartWatcher(id, cartData) {
    return { type: KIOSK_CLEAR_CART_WATCHER, id, cartData };
}

export function kioskGetOrderTotalCostWatcher(id) {
    return { type: KIOSK_GET_ORDER_TOTALCOST_WATCHER, id };
}

export function kioskViewCartWatcher(data) {
    return { type: KIOSK_VIEW_CART_WATCHER, data };
}

export function kioskWatcher(data) {
    return { type: KIOSK_WATCHER, data };
}

export function kioskBookingWatcher(data, pushCurrent) {
    return { type: KIOSK_BOOKING_WATCHER, data, pushCurrent };
}


// Redux state changing actionCreators

export function kioskUpdateCartList(data) {
    return { type: KIOSK_UPDATE_CART, payload: data };
}

export function kioskUpdateGetOrderTotalCost(data) {
    return { type: KIOSK_UPDATE_ORDER_TOTALCOST, payload: data };
}

export function kioskUpdateViewCart(data) {
    return { type: KIOSK_UPDATE_VIEW_CART, payload: data };
}

export function kioskUpdate(data) {
    return { type: KIOSK_UPDATE, payload: data };
}


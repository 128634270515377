import React, { useState } from 'react'
import { Typography, Card, Grid, Button } from "@mui/material";
import VendorManagementTable from '../../../components/admin/userManagement/vendor';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CreationDialog from '../../../components/dialog';
import VendorCreation from '../../../components/admin/userManagement/vendor/vendorCreation';

const VendorManagementTemp = () => {

    const [open, setOpen] = useState(false)

    return (
        <>
            <Card style={{ boxShadow: (theme)=> `0px 12px 23px ${theme.palette.grey[200]}` }}>
                <Grid container spacing={3} sx={{ padding: '30px', pb: '20px' }} >
                    <Grid item lg={6} xs={6} sx={{ justifyContent: 'left' }}>
                        <div>
                            <Typography variant='t20_600'>Vendor Management </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6} sx={{ justifyContent: 'right', display: 'flex' }}>
                        <Button variant='contained' endIcon={<DoubleArrowIcon />} sx={{ width: '197px', height: '50px' }} onClick={() => setOpen(true)} >
                        <Typography variant='t16_500' >
                            Add Vendor
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <VendorManagementTable />
                    </Grid>
                </Grid>
            </Card>
            <CreationDialog open={open} setOpen={setOpen} >
                <VendorCreation setOpen={setOpen} />
            </CreationDialog >
        </>
    )
}

export default VendorManagementTemp;
import { dashboardList } from "../../actionCreators/foodVendor";
import { call, put, takeEvery } from "redux-saga/effects";
import DashboardService from "../../../services/foodVendor/index";
import { GET_DASHBOARD_WATCHER } from "../../actionTypes/foodVendor";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getDashboardEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(DashboardService.get, action.payload);
    yield put(dashboardList(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

//saga 
export const FoodVendorSaga = [
  takeEvery(GET_DASHBOARD_WATCHER, getDashboardEffect),
]



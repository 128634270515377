import React, { useState } from 'react'
import { Box, Card, Typography } from '@mui/material'
import Breadcrumbs from '../../../../components/breadcrumbs'
import Index from '../../../../components/admin/kitchen/rosterManagement/index'
import NewRoster from '../../../../components/admin/kitchen/rosterManagement/newRoster'
// import RosterManagementTable from '../../../../components/admin/kitchen/rosterManagement/rosterManagement'
// import RosterManagement from './rosterManagement'

export default function RosterIndex() {


    const [pageChange, setPageChange] = useState(false);


    return (
        <>
            {!pageChange ?
                <Box p='30px'>
                    <Breadcrumbs content={["Roster Management"]} />
                    <Box p='15px 0'>
                        <Typography variant='t24_700' > Roster Management </Typography>
                    </Box>

                    <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 6px 6px", padding: '20px' }}>
                        <Index setPageChange={setPageChange} />
                    </Card>

                </Box>

                :

                <Box p='30px'>
                    <Breadcrumbs content={["Roster Management", "Add Roster"]} />
                    <Box p='15px 0'>
                        <Typography variant='t24_700' > Add Roster </Typography>
                    </Box>

                    <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 6px 6px", padding: '20px' }}>
                      <NewRoster setPageChange={setPageChange} />
                    </Card>
                </Box>

            }
        </>
    )
}

import { call, put, takeEvery } from "redux-saga/effects";
import { GET_BOOKING_HISTORY_WATCHER, GET_CONTRACTOR_DASHBOARD_WATCHER, CREATE_CONTRACTOR_ORDER_WATCHER, CANCEL_CONTRACTOR_ORDER_WATCHER, GET_CROLE_SWIPE_HISTORY_WATCHER } from "../../actionTypes/contractor";
import { bookingHistoryList, contractorDashboardList,cRoleSwipeHistoryList } from "../../actionCreators/contractor";
import ContractorRoleServices from "../../../services/contractor/index"
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";
import { cnsMenuIdList } from "../../actionCreators/admin/contractorBook";

function* bookingHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorRoleServices.getContractorBookingHistory, action.payload);
    yield put(bookingHistoryList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* contractorDashboardEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorRoleServices.getContractorDashboard, action.payload);

    yield put(contractorDashboardList(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* createContractorOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorRoleServices.createContractorOrder, action.payload);

    // yield put(contractorDashboardList(data.recordsets));
    yield put(cnsMenuIdList(data));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
}

function* cancelContractorOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorRoleServices.cancelContractorOrder, action.id, action.payload);
    // action.response(data)
    yield put(cnsMenuIdList(data));
    // yield put(contractorDashboardList(data.recordsets));
    yield put(updateFalseLoader());
    yield put(updateAlert('Order Cancelled', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
}

function* getCRoleSwipeHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorRoleServices.getCRoleSwipeHistory, action.payload);
    yield put(cRoleSwipeHistoryList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}


//saga 
export const contractorRoleSaga = [
  takeEvery(GET_BOOKING_HISTORY_WATCHER, bookingHistoryEffect),
  takeEvery(GET_CONTRACTOR_DASHBOARD_WATCHER, contractorDashboardEffect),
  takeEvery(CREATE_CONTRACTOR_ORDER_WATCHER, createContractorOrderEffect),
  takeEvery(CANCEL_CONTRACTOR_ORDER_WATCHER, cancelContractorOrderEffect),
  takeEvery(GET_CROLE_SWIPE_HISTORY_WATCHER, getCRoleSwipeHistoryEffect)
]
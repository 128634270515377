import React, { useEffect, useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import RatingHeader from '../../../components/foodVendor/reports/rating/headerCard';
import RatingTable from '../../../components/admin/reports/rating/ratingTable'
import { useDispatch, useSelector } from 'react-redux';
import { TABLE_HEAD } from '../../../components/admin/reports/rating/ratingTable';
import { getRatingFeedbackWatcher } from '../../../redux/actionCreators/admin/reports';

const Rating = () => {

    const { ProfileReducers: { loginState }, ReportsReducers: { RatingFeedbackList }, UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [vendorState, setVendorState] = useState('')
    const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''
    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    useEffect(() => {
        setVendorState('')
       }, [cafeteriaId])

       
    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = RatingFeedbackList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = () => {
        const data = {
            "Action": "adminRateList",
            "NDIVISIONID": cafeteriaId,
            // "CVENDORID": vendorId === '' ? null : vendorId
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
        }
        dispatch(getRatingFeedbackWatcher(data))
    }

        


    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Rating"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Ratings</Typography>
                </Box>
                <Box p='10px 0'>
                    <RatingHeader handleSearch={handleSearch} searchText={searchText} rowData={RatingFeedbackList} columnData={TABLE_HEAD} handleSubmitAction={handleSubmitAction} setVendorState={setVendorState}/>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <RatingTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} vendorState={vendorState}/>
                </Card>

            </Box>
        </>
    )
}

export default Rating;
import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

export default function CustTypo({ children, WebkitLineClamp = '2', ...rest }) {
    return (<>
        <Tooltip title={children} arrow placement="top">
            <Typography sx={{
                display: "-webkit-box",
                WebkitLineClamp,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis"
            }} {...rest}>
                {children}
            </Typography>
        </Tooltip>
    </>
    );
}
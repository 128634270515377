import http from "../../http-common";

class RosterManagementService {

    getAll(data) {
        return http.post("/user/roster", data);
    }
    get(id, data) {
        return http.post(`/admin/rosterManagement/${id}`, data);
    }
    create(data) {
        return http.post("/user/addRoster", data);
    }
    update(id, data) {
        return http.put(`/user/portalDesign/roster/${id}`, data);
    }
    delete(id, data) {
        return http.delete(`/user/portalDesign/roster/${id}`, data);
    }

}

export default new RosterManagementService();
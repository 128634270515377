import { UPDATE_FEEDBACK_LIST, FEEDBACK_LIST_WATCHER } from "../../actionTypes/user";

// Worker triggering actionCreators

export function feedBackListWatcher(id, data) {
    return { type: FEEDBACK_LIST_WATCHER, payload: data, id };
}

// Redux state changing actionCreators

export function updateMenuList(data) {
    return { type: UPDATE_FEEDBACK_LIST, payload: data };
}

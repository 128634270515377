import { allContractorUserList, contractorList, viewContractorUserList } from "../../actionCreators/admin/contractorManagement";
import { call, put, takeEvery } from "redux-saga/effects";
import ContractorManagementService from "../../../services/admin/contractorManagement";
import { CREATE_CONTRACTOR_WATCHER, UPDATE_CONTRACTOR_WATCHER, DELETE_CONTRACTOR_WATCHER, GET_CONTRACTOR_WATCHER, ADD_USER, GET_ALL_CON_USER, GET_VIEW_CON_USER, UPDATE_VIEW_CON_USER, DELETE_VIEW_CON_USER } from "../../actionTypes/admin";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getContractorEffect(action) {
    try {
      yield put(updateOpenLoader());
      let { data } = yield call(ContractorManagementService.getAll, action.payload);
  
      yield put(contractorList(data.recordset));
      yield put(updateFalseLoader());
      //yield put(updateAlert('Success', 'success'));

    } catch (e) {
      yield put(updateFalseLoader());
      yield put(updateAlert('Something Went Wrong!', 'error'));
    }
  }
  
function* createContractorEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorManagementService.create, action.payload);
    action.setOpen(false)
    yield put(contractorList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* updateContractorEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorManagementService.update, action.id, action.payload);
    yield put(contractorList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* deleteContractorEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorManagementService.delete, action.id, action.payload);

    yield put(contractorList(data.data.recordset));
    yield put(updateFalseLoader());
    yield put(updateAlert('Deleted Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* addUserEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(ContractorManagementService.addUser, action.payload);
    action.setAddDialogOpen(false);
    yield put(updateFalseLoader());
    yield put(updateAlert('Added Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getAllConUserEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorManagementService.getAllConUser, action.payload);
    yield put(allContractorUserList(data.recordset));

    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getViewConUserEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ContractorManagementService.getViewConUser, action.payload);
    yield put(viewContractorUserList(data.recordset));

    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* updateViewConUserEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(ContractorManagementService.updateViewConUser, action.payload);
    let getdata = {nContractorID: action.nContractorID}
    let { data : data1 } = yield call(ContractorManagementService.getViewConUser, getdata);
    yield put(viewContractorUserList(data1.recordset));

    yield put(updateFalseLoader());
    yield put(updateAlert('Updated Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.message, 'error'));
  }
}

function* deleteViewConUserEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(ContractorManagementService.deleteViewConUser, action.payload);
    let getdata = {nContractorID: action.nContractorID}
    let { data: data1 } = yield call(ContractorManagementService.getViewConUser, getdata);
    yield put(viewContractorUserList(data1.recordset));

    yield put(updateFalseLoader());
    yield put(updateAlert('Deleted Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

//saga 
export const ContractorManagementSaga= [
     takeEvery(GET_CONTRACTOR_WATCHER, getContractorEffect),
     takeEvery(CREATE_CONTRACTOR_WATCHER, createContractorEffect),
     takeEvery(UPDATE_CONTRACTOR_WATCHER, updateContractorEffect),
     takeEvery(DELETE_CONTRACTOR_WATCHER, deleteContractorEffect),
     takeEvery(ADD_USER, addUserEffect),
     takeEvery(GET_ALL_CON_USER, getAllConUserEffect),
     takeEvery(GET_VIEW_CON_USER, getViewConUserEffect),
     takeEvery(UPDATE_VIEW_CON_USER, updateViewConUserEffect),
     takeEvery(DELETE_VIEW_CON_USER, deleteViewConUserEffect)
]


// Food Vendor
import Index from "../pages/foodVendor/dashboard";
import ContractorBookingHistory from "../pages/admin/reports/orderHIstory";
import ContractorInvoice from "../pages/admin/reports/contractorInvoice";
import EmployeeContractor from "../pages/admin/reports/emp_con";
import DeliveryHistory from "../pages/admin/reports/deliveryHistory";
import SwipeHistory from "../pages/admin/reports/swipeHistory";
import Rating from "../pages/admin/reports/rating";
import Feedback from "../pages/admin/reports/feedbacks";
import EmpBookingHistory from "../pages/admin/reports/userHistory";
//import SpecialGuestHistory from "../pages/admin/reports/specialHistory";
import OrderTracking from "../pages/admin/reports/orderTracking";
//import SpecialTracking from "../pages/admin/reports/specialTracking";
import PushNotification from "../pages/foodVendor/reports/pushNotification";
// import SaleReport from "../pages/foodVendor/reports/saleReport";
import SaleReport from "../pages/admin/reports/saleReport";
import DayByDayOrder from "../pages/admin/reports/dayByDayOrder";
//profile
import Profile from "../pages/profile";
import ChangePassword from "../pages/changePassword";
//layout
import NavLayout from '../layouts/dashboard';
import GuestOrderHistory from "../pages/admin/reports/guestOrderHistory";
import GuestDeliveryHistory from "../pages/admin/reports/guestDeliveryHistory";
import GuestSwipeHistory from "../pages/admin/reports/guestSwipeHistory";
import NormalGuest from "../pages/admin/reports/splGuest/normalGuest";
// import VipGuest from "../pages/admin/reports/splGuest/vipGuest";
import SpecialGuest from "../pages/admin/reports/splGuest/specialGuest";
import GuestOrderTracking from "../pages/admin/reports/splGuest/guestOrderTracking";

const FoodVendor = {
  path: "/foodVendor",
  element: <NavLayout />,
  children: [
    { index: true, element: <Index /> },
    // Profile
    { path: "profile", element: <Profile /> },
    { path: "changePassword", element: <ChangePassword /> },
    // Reports
    { path: "contractorBookingHistory", element: <ContractorBookingHistory /> },
    { path: "contractorInvoice", element: <ContractorInvoice /> },
    { path: "user_contractor", element: <EmployeeContractor /> },
    { path: "deliveryHistory", element: <DeliveryHistory /> },
    { path: "contractorSwipeHistory", element: <SwipeHistory /> },
    { path: "ratings", element: <Rating /> },
    { path: "feedbacks", element: <Feedback /> },
    { path: "userBookingHistory", element: <EmpBookingHistory /> },
    // { path: "specialGuestHistory", element: <SpecialGuestHistory /> },
    { path: "orderTracking", element: <OrderTracking /> },
    // { path: "specialOrderTracking", element: <SpecialTracking /> },
    { path: "pushNotification", element: <PushNotification /> },
    { path: "saleReport", element: <SaleReport /> },
    { path: "dayByDayOrder", element: <DayByDayOrder /> },
    //guest
    { path: "guestOrderHistory", element: <GuestOrderHistory /> },
    { path: "guestDeliveryHistory", element: <GuestDeliveryHistory /> },
    { path: "guestSwipeHistory", element: <GuestSwipeHistory /> },
    // { path: "specialGuest", element: <SpecialGuest /> },
    { path: "normalGuest", element: <NormalGuest /> },
    { path: "guestOrderTracking", element: <GuestOrderTracking /> },

  ],
};

export default FoodVendor;

export const gstRate = 5

export const getGst = (val) =>{
  return (val / 100 * gstRate).toFixed(2)
}

export const getGstOnly = (val) =>{
  return (val / 100 * gstRate)
}

export const getWithoutGst = (val) =>{
  return (val - (val / 100 * gstRate)).toFixed(2)
}
// component
import { HomeOutlined, ShoppingCartOutlined } from '@mui/icons-material'
// ----------------------------------------------------------------------

export const Contractor = [
  {
    title: 'Dashboard',
    path: '/contractor',
    icon: <HomeOutlined color='inherit' />
  },
  {
    title: 'Booking History',
    path: '/contractor/contractorBookingHistory',
    icon: <ShoppingCartOutlined color='inherit'/>
  },
  {
    title: 'Delivery History',
    path: '/contractor/deliveryHistory',
    icon: <ShoppingCartOutlined color='inherit'/>
  },
  {
    title: 'Order Booking',
    path: '/contractor/orderBooking',
    icon: <ShoppingCartOutlined color='inherit'/>
  },
  {
    title: 'Swipe History',
    path: '/contractor/swipeHistory',
    icon: <ShoppingCartOutlined color='inherit'/>
  },

];

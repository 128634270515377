import * as React from 'react';
import Box from '@mui/material/Box';
import { Card } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function CardTemplate(props) {
    const { cardVal } = props
    return (
        <Card sx={{ height: '124px', border: (theme) => `1px solid ${theme.palette.grey[200]}` }}>
            <Box display='flex' height='100%' p='20px' alignItems='center' >
                <Box width='100%' pl='16px'>
                    <img src='/static/admin/coffee.svg' alt='' style={{ height: '50px', width: '50px' }} />
                    <Box display='flex' mt='4px' alignItems='center'>

                        <Typography variant='t16_500' >
                            {cardVal?.cMealName}
                        </Typography>
                    </Box>
                </Box>
                <Box width='100%' pl='16px'>
                    <Typography variant='t32_600' >
                        {cardVal?.Quantity}
                    </Typography>
                    <Box display='flex' mt='4px' alignItems='center'>
                        <Typography variant='t16_500' >
                            {'Booked'}
                        </Typography>
                    </Box>
                </Box>

                <Box width='100%' pl='16px'>
                    <Typography variant='t32_600' >
                        {cardVal?.swiped}
                    </Typography>
                    <Box display='flex' mt='4px' alignItems='center'>
                        <Typography variant='t16_500' >
                            {'Swiped'}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
}

import React from 'react'
import { Box, Typography, Card } from "@mui/material";
import SessionTable from './conGuestTable';

const TableIndex = ({ alignment, tblData }) => {

    return (
        <>
            <Box display='flex' >
                <Box display='flex' justifyContent='center' alignItems='center' bgcolor='primary.main' width='43px' borderRadius="6px 0px 0px 6px" >
                    <Typography variant="t14_600__18" color='common.white' style={{ transform: "rotate(-90deg)" }} >
                        {alignment === 2 ? 'Contractor' : 'Guest'}
                    </Typography>
                </Box>
                <Card sx={{ borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`, borderRight: (theme) => `1px solid ${theme.palette.grey[300]}`, width: '100%', height: '100%', borderRadius: "0px 0px 6px 0px" }}>
                    <SessionTable tableData={tblData} />
                </Card>
            </Box>
        </>
    )
}

export default TableIndex
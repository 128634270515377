import { GET_CAROUSEL_IMAGE_WATCHER, CREATE_CAROUSEL_WATCHER, UPDATE_CAROUSEL_WATCHER, DELETE_CAROUSEL_WATCHER, CAROUSEL_IMAGE_LIST, GET_USER_CAROUSEL_IMAGE_WATCHER, USER_CAROUSEL_IMAGE_LIST, UPDATE_USER_CAROUSEL_WATCHER, DELETE_USER_CAROUSEL_WATCHER, CREATE_USER_CAROUSEL_WATCHER, FOOD_WASTAGE_WATCHER, FOOD_WASTAGE_LIST, GET_FREEZE_BOOKING_WATCHER, CREATE_FREEZE_BOOKING_WATCHER, UPDATE_FREEZE_BOOKING_WATCHER, DELETE_FREEZE_BOOKING_WATCHER, FREEZE_BOOKING_LIST,GET_SPECIAL_MENU_WATCHER, SPECIAL_MENU_LIST } from "../../actionTypes/admin";

// Worker triggering actionCreators

export function getCarouselWatcher(authParams) {
    return { type: GET_CAROUSEL_IMAGE_WATCHER, payload: authParams };
}

export function createCarouselWatcher(data, setOpen) {
    return { type: CREATE_CAROUSEL_WATCHER, payload: data, setOpen };
}

export function updateCarouselWatcher(id, data) {
    return { type: UPDATE_CAROUSEL_WATCHER, payload: data, id };
}

export function deleteCarouselWatcher(id, data) {
    return { type: DELETE_CAROUSEL_WATCHER, payload: data, id };
}

export function getUserCarouselWatcher(authParams) {
    return { type: GET_USER_CAROUSEL_IMAGE_WATCHER, payload: authParams };
}

export function createUserCarouselWatcher(data, setOpen) {
    return { type: CREATE_USER_CAROUSEL_WATCHER, payload: data, setOpen };
}

export function updateUserCarouselWatcher(id, data) {
    return { type: UPDATE_USER_CAROUSEL_WATCHER, payload: data, id };
}

export function deleteUserCarouselWatcher(id, data) {
    return { type: DELETE_USER_CAROUSEL_WATCHER, payload: data, id };
}

export function getfreezeBookingWatcher(authParams) {
    return { type: GET_FREEZE_BOOKING_WATCHER, payload: authParams };
}

export function getSpecialMenuWatcher(authParams) {
    return { type: GET_SPECIAL_MENU_WATCHER, payload: authParams };
}

export function createfreezeBookingWatcher(data, setOpen) {
    return { type: CREATE_FREEZE_BOOKING_WATCHER, payload: data, setOpen };
}

export function updatefreezeBookingWatcher(id, data) {
    return { type: UPDATE_FREEZE_BOOKING_WATCHER, payload: data, id };
}

export function deletefreezeBookingWatcher(id, data) {
    return { type: DELETE_FREEZE_BOOKING_WATCHER, payload: data, id };
}

export function foodWastageWatcher(data, setOpen) {
    return { type: FOOD_WASTAGE_WATCHER, payload: data, setOpen};
}


// Redux state changing actionCreators
export function carouselList(data) {
    return { type: CAROUSEL_IMAGE_LIST, payload: data };
}

export function userCarouselList(data) {
    return { type: USER_CAROUSEL_IMAGE_LIST, payload: data };
}

export function freezeBookingList(data) {
    return { type: FREEZE_BOOKING_LIST, payload: data };
}

export function foodWastageList(data) {
    return { type: FOOD_WASTAGE_LIST, payload: data };
}

export function specialMenuList(data) {
    return { type: SPECIAL_MENU_LIST, payload: data };
}
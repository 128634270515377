import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CancelDialog from "../../../../components/admin/kitchen/contractorBook/cancelDialog";
import CustTypo from "../../../../components/overrides/CustTypo";
import { base_url } from "../../../../http-common";
import { userOrderCancelWatcher } from "../../../../redux/actionCreators/user/currentOrder";
import { format } from 'date-fns';

const  OrderList = ({value}) => {
  const {cMenuName, nToatlRate, cImage, cMealName, cMenuDesc, nID, bIsCancelled, bIsDelivered, dOrderDate} = value || {}

  const { ProfileReducers: { loginState } } = useSelector(state => state) 

  const nUserID = loginState[0]?.nID || 0

  // const orderDate = new Date(dOrderDate).toLocaleDateString()
  const orderDate = format(new Date(dOrderDate), 'MM/dd/yyyy')
  const currentDate = format(new Date(), 'MM/dd/yyyy')
  const dispatch = useDispatch()

  const handleCancel = () => {
    let data = {
      "nID": nID,
      "nUserID": nUserID,
      "orderHistory": true
    }
    dispatch(userOrderCancelWatcher(data))
  }

  return (
    <Box display="flex" alignItems="center">
      <Box
        component="img"
        src={`${base_url}/${cImage}`}
        sx={{
          height: 64,
          width: 64,
          minWidth: 64,
          borderRadius: "12px",
          objectFit: "cover",
          mr:'10px'
        }}
        alt='logo'
      ></Box>

      <Box display="flex" flexDirection="column" width="100%" pr='5px' >
        {/* <Typography variant="t14_400" color="grey.500" mb="8px">
          {dOrderDate}
        </Typography> */}
        <Typography variant="t14_600" mb="8px">
          {cMealName} ({cMenuName})
        </Typography>
        <Typography variant="t18_600">
          ₹{nToatlRate}
        </Typography>
        {cMenuDesc.length > 20 ?
          <CustTypo color="grey.500" variant="t16_400" WebkitLineClamp = '1'>
            {cMenuDesc}
          </CustTypo>
          :
          <Box >
            <Typography color="grey.500" variant="t16_400">
              {cMenuDesc}
            </Typography>
          </Box>
        }
      </Box>

      <Typography variant="t18_600">
        {
          (`${bIsDelivered}` !== "true" ) && (`${bIsCancelled}` !== "true") && (orderDate > currentDate) ?
            <CancelDialog handleCancel={handleCancel} type='user' color='primary.main' />
          :
          ''
        }
      </Typography>
    </Box>
  );
};

export default OrderList;

import React, { useState } from 'react';
import {
    TableRow,
    TableCell,
    Typography,
    Collapse,
    Card
} from '@mui/material';
import VendorChildTable from './vendorChildTable';
import ActionButtons from '../../dashboard/actionButtons';
import EditTextField from '../../../overrides/editField';
import { updateVendorWatcher, deleteVendorWatcher } from '../../../../redux/actionCreators/admin/vendorManagement';
import { useDispatch } from 'react-redux'
import { emailValidation, phoneValidation } from '../employee/commonRegex';

const VendorTableRow = ({ rowData, divisionId }) => {
    const dispatch = useDispatch();

    const [expandOpen, setExpandOpen] = useState(false);
    const [status, setStatus] = useState('list');
    const [editData, setEditData] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [formErrors, setFormErrors] = useState({
        CVENDORID: null,
        CVENDORNAME: null,
        CVENDORADDRESS: null,
        CMOBILENUMBER: null,
        CEMAILID: null,
    });
    const [requiredFields] = useState([
        'CVENDORID',
        'CVENDORNAME',
        'CVENDORADDRESS',
        'CMOBILENUMBER',
        'CEMAILID',
    ]);
    const [emailValid, setEmailValid] = useState(true)
    const [phoneValid, setPhoneValid] = useState(true)
    const [valid, setValid] = useState(false)

    const handleEditChange = (e) => {
        const { name, value } = e.target
        setEditData({ ...editData, [name]: value })
        if(name === 'CEMAILID'){
            let isValidEmail = emailValidation(value)
            setEmailValid(isValidEmail)
        }
        if(name === 'CMOBILENUMBER'){
            let isValidPhoneNo = phoneValidation(value)
            setPhoneValid(isValidPhoneNo)
        }
        validationHandler(name, value)
    }

    const validationHandler = (name, value) => {
        if (!Object.keys(formErrors).includes(name)) return;
        if (
          requiredFields.includes(name) &&
          (value === null ||
            value === 'null' ||
            value === '' ||
            value === false ||
            (Object.keys(value || {}) && value?.value === null))
        ) {
          setFormErrors({
            ...formErrors,
            [name]: capitalize(name) + ' is Required!',
          });
          setValid(true)
        } 
        else {
          setFormErrors({
            ...formErrors,
            [name]: null,
          });
          setValid(false)
        }
    };
    
    const capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const handleEdit = () => {
        let data = { ...rowData }
        setEditData(data);
        setStatus('edit');
    }

    const handleDelete = async () => {
        let data = { "ACTION": "DELETE", SDIVISIONID: divisionId }
        await dispatch(deleteVendorWatcher(rowData.NID, { data }))
        setStatus('list');
        setDeleteDialogOpen(false);
    }

    const tickIconFunction = async () => {
        let data = { ...editData }
        data.ACTION = "UPDATE"
        data.SDIVISIONID= divisionId
        // data.cMenuName = JSON.stringify(editData.cMenuName)
        if(!valid && emailValid && phoneValid){
            dispatch(updateVendorWatcher(rowData.NID, data));
            
            setEmailValid(true)
            setPhoneValid(true)
            setStatus('list');
        }
    }

    const cancelIconFunction = () => {
        setEditData({});
        setEmailValid(true)
        setPhoneValid(true)
        setStatus('list');
    }

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                {status === 'list' ?
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.NID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.CVENDORID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.CVENDORNAME}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.CVENDORDESC}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.CVENDORADDRESS}
                            </Typography>
                        </TableCell>
                    </>
                    :
                    <>
                        <TableCell align="left" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant='t14_600'>
                                {rowData.NID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='CVENDORID'
                                value={editData.CVENDORID}
                                onChange={handleEditChange}
                                error={editData.CVENDORID ? false : formErrors.CVENDORID !== null ? true : false}
                                helperText={editData.CVENDORID ? '' : formErrors.CVENDORID !== null ? 'Vendor Id is required' : ''}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='CVENDORNAME'
                                value={editData.CVENDORNAME}
                                onChange={handleEditChange}
                                error={editData.CVENDORNAME ? false : formErrors.CVENDORNAME !== null ? true : false}
                                helperText={editData.CVENDORNAME ? '' : formErrors.CVENDORNAME !== null ? 'Vendor Name is required' : ''}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='CVENDORDESC'
                                value={editData.CVENDORDESC}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='CVENDORADDRESS'
                                value={editData.CVENDORADDRESS}
                                onChange={handleEditChange}
                                error={editData.CVENDORADDRESS ? false : formErrors.CVENDORADDRESS !== null ? true : false}
                                helperText={editData.CVENDORADDRESS ? '' : formErrors.CVENDORADDRESS !== null ? 'Vendor Address is required' : ''}
                            />
                        </TableCell>
                    </>
                }
                <TableCell align="right">
                    <ActionButtons status={status} setStatus={setStatus} expandOpen={expandOpen} setExpandOpen={setExpandOpen} type={'vendor'} tickIconFunction={tickIconFunction} handleDelete={handleDelete} handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} setDeleteDialogOpen={setDeleteDialogOpen} deleteDialogOpen={deleteDialogOpen} />
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell colSpan={6} style={{ padding: 0 }}>
                    <Collapse in={expandOpen} timeout="auto" unmountOnExit>
                        <Card sx={{ bgcolor: 'grey.200' }} >
                            <VendorChildTable expandOpen={expandOpen} rowData={rowData} handleEditChange={handleEditChange} status={status} editData={editData} setEditData={setEditData} formErrors={formErrors} phoneValid={phoneValid} emailValid={emailValid} />
                        </Card>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )

}

export default VendorTableRow
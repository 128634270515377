import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import DeliveryHistoryHeader from '../../../components/admin/reports/deliveryHistory/header';
import DHistoryTable from '../../../components/admin/reports/deliveryHistory/deliveryHistoryTable';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import { TABLE_HEAD } from '../../../components/admin/reports/deliveryHistory/deliveryHistoryTable';
import { SUB_TABLE_HEAD } from '../../../components/admin/reports/deliveryHistory/dHistoryChildTable';
import Context from '../../../context/context';
import { getConvertedDate, getCurDate } from '../../../utils/common';

const AdminDeliveryHistory = () => {

    const { ReportsReducers: { DeliveryHistoryList }, UserHomeReducers: { cafeteriaId }, ProfileReducers: {loginState} } = useSelector((state) => state)

    const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [eventVal, setEventVal] = useState('')
    const { state, setState } = useContext(Context);
    const [vendorState, setVendorState] = useState('')
    const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = DeliveryHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        let currentDate = new Date()
        let curDate = getConvertedDate(currentDate)
        // let curDate = getCurDate(currentDate)
        console.log('sdcsdfwe',toDate, fromDate, curDate)
        // if(toDate >= fromDate){
            //&& toDate <= curDate && fromDate <= curDate
            const data = {
                "ACTION": "ContractorDeliverySearch",
                "FromDate": fromDate !== null ? fromDate : currentDate,
                "ToDate": toDate !== null ? toDate : currentDate,
                // "nCafeteriaId": cafeteriaId,
                "UserID": 33,
                NDIVISIONID: cafeteriaId,
                // CVENDORID: state !== "" ? `${state}` : 'null'
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getDeliveryHistoryWatcher(data))
            setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
            setPage(1)
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true })
        // }
    }

    useEffect(()=>{
        if(eventVal)
        handleSearch(eventVal);
    },[DeliveryHistoryList])

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Delivery History"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Delivery History</Typography>
                </Box>
                <Box p='10px 0'>
                    <DeliveryHistoryHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData = {DeliveryHistoryList} columnData ={[...TABLE_HEAD,...SUB_TABLE_HEAD]} setVendorState={setVendorState} invalidErr={invalidErr} setInvalidErr={setInvalidErr}/>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <DHistoryTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} vendorState={vendorState} />
                </Card>

            </Box>
        </>
    )
}

export default AdminDeliveryHistory;
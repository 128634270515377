import { GUEST_LIST, ALL_GUEST_USER_LIST, VIEW_GUEST_USER_LIST } from "../../actionTypes/admin";

const intialState = {
  guestList: [],
  allGuestUserList: [],
  viewGuestUserList: [],
};

export default function GuestReducers(state = intialState, action) {
  switch (action.type) {

    case GUEST_LIST:
      return { ...state, guestList: action.payload };

    case ALL_GUEST_USER_LIST:
      return { ...state, allGuestUserList: action.payload };

    case VIEW_GUEST_USER_LIST:
      return { ...state, viewGuestUserList: action.payload };

    default:
      return state;
  }
}

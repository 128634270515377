import * as React from 'react';
import Box from '@mui/material/Box';
// import { Card } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function CardTemplate(props) {
    const { count, title } = props
    return (
        //<Card sx={{ height: '124px', border: (theme) => `1px solid ${theme.palette.grey[200]}`, boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}` }}>
        <Box display='flex' height='100%' p='20px' alignItems='center' >
            <img src='/static/admin/coffee.svg' alt='' />
            <Box width='100%' pl='16px'>
                <Typography variant='t32_600' >
                    {count}
                </Typography>
                <Box display='flex' mt='4px' alignItems='center'>
                    <Typography variant='t16_500' >
                        {title}
                    </Typography>
                </Box>
            </Box>
        </Box>
        //</Card>
    );
}

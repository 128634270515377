import { KIOSK_UPDATE_CART, KIOSK_UPDATE_ORDER_TOTALCOST, KIOSK_UPDATE_VIEW_CART, KIOSK_UPDATE } from "../../actionTypes/user";

const intialState = {
  kioskCartList: [],
  kioskGetOrderTotalCost: [],
  kioskViewCartList: [],
  kiosk: [],
};

export default function UserKioskReducers(state = intialState, action) {
  switch (action.type) {

    case KIOSK_UPDATE_CART:
      return { ...state, kioskCartList: action.payload };

    case KIOSK_UPDATE_ORDER_TOTALCOST:
      return { ...state, kioskGetOrderTotalCost: action.payload };

    case KIOSK_UPDATE_VIEW_CART:
      return { ...state, kioskViewCartList: action.payload };

    case KIOSK_UPDATE:
      return { ...state, kiosk: action.payload };

    default:
      return state;
  }
}

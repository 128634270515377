import { call, put, takeEvery } from "redux-saga/effects";
import { CREATE_GUEST_BOOK_WATCHER, GUEST_NAME_LIST_WATCHER, CANCEL_GUEST_BOOK_WATCHER, CNS_WATCHER_GUEST, ALl_MEAL_WATCHER_GUEST,GUEST_USER_COUNT_WATCHER } from "../../actionTypes/admin";
import { guestBookList, guestNameList, cnsMenuIdGuestList, selectAllMealGuestList, selectGuestCountList } from "../../actionCreators/admin/guestBook";
import GuestBookService from "../../../services/admin/guestBook"
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* createGuestBookEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestBookService.create, action.payload);

    yield put(guestBookList(data));
    yield put(cnsMenuIdGuestList(data));
    yield put(updateFalseLoader());
    yield put(updateAlert('Created Successfully', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
}

function* cancelGuestBookEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestBookService.cancel, action.id, action.payload);
    // action.response(data)
    yield put(guestBookList(data));
    yield put(cnsMenuIdGuestList(data));
    yield put(updateFalseLoader());
    yield put(updateAlert('Cancelled', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getGuestNameListEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestBookService.getGuestNameList, action.payload);
    yield put(guestNameList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getCnsListGuestEffect(action) {
  try {
    yield put(updateOpenLoader());
    const api = !action.isCon ? GuestBookService.getMealQuantity : GuestBookService.getMealQuantityGuest
    let { data } = yield call(api , action.payload);
    yield put(cnsMenuIdGuestList(data));
    // yield put(cnsRateGuestList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.response?.data?.message === "No Meals" ? "No Meals" : 'Something Went Wrong!', 'error'));
  }
}

function* selectAllMealGuestEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestBookService.selectAllMeal, action.payload);
    yield put(selectAllMealGuestList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* selectGuestCountEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(GuestBookService.getGuestCount, action.payload);
    yield put(selectGuestCountList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}


//saga 
export const GuestBookSaga = [
  takeEvery(CREATE_GUEST_BOOK_WATCHER, createGuestBookEffect),
  takeEvery(GUEST_NAME_LIST_WATCHER, getGuestNameListEffect),
  takeEvery(CANCEL_GUEST_BOOK_WATCHER, cancelGuestBookEffect),
  takeEvery(CNS_WATCHER_GUEST, getCnsListGuestEffect),
  takeEvery(ALl_MEAL_WATCHER_GUEST, selectAllMealGuestEffect),
  takeEvery(GUEST_USER_COUNT_WATCHER, selectGuestCountEffect)
]



import React from 'react';

// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer
} from '@mui/material';
import GuestOrderTrackSubTableHead from './subTableHead';


export const SUB_TABLE_HEAD = [
    { id: 'cRefId', label: 'cReference ID', alignRight: false, title: 'Reference ID', field: 'cReferenceID' },
    { id: 'cBankId', label: 'cBank Tracking ID', alignRight: false, title: 'BankTracking ID', field: 'cBankTrackingID' },
    { id: 'employeeName ', label: 'Employee Name', alignRight: false, title: 'Employee Name', field: 'EmployeeName' },
    { id: 'mealType', label: 'Meal Type', alignRight: false, title: 'Meal Name', field: 'cMealName' },
    { id: 'qty', label: 'Qty', alignRight: false, title: 'Quantity', field: 'nQuantity' },
    { id: 'amount', label: 'Amount', alignRight: false, title: 'Amount', field: 'nToatlRate' },
    { id: 'totalAmt', label: 'Total Amount', alignRight: false, title: 'Total Amount', field: 'totalAmount' },
];

const GuestOrderTrackSubTable = ({ expandOpen, rowData, order, orderBy, handleRequestSort}) => {

    // const { EmployeeReducers: { accessPointList, divisionList, departmentNameList, categoryList } } = useSelector((state) => state)

    // let division = divisionList.find(m => m.id === rowData.nDivisionID) || {}
    // let category = categoryList.find(m => m.name === rowData.categoryName) || {}
    // let department = departmentNameList.find(m => m.name === rowData.departmentName) || {}
    // let getAccesspointNames = rowData.nAccessPoint?.split(',') || []
    // let accessPoints = accessPointList.filter(m => getAccesspointNames.includes(m.name)) || []

    return (
        <>
            {expandOpen &&
                <TableContainer sx={{ overflow: 'unset' }}>
                    <Table>
                        <GuestOrderTrackSubTableHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={SUB_TABLE_HEAD}
                            //   rowCount={USERLIST.length}
                            //   numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            <TableRow
                                // hover
                                tabIndex={-1}
                                sx={{ '& > *': { borderBottom: 'unset' } }}
                            >
                                    <>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.cReferenceID}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.cBankTrackingID}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.EmployeeName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.cMealName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.nQuantity}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.nToatlRate}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant='t14_600'>
                                                {rowData.totalAmount}
                                            </Typography>
                                        </TableCell>
                                    </>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    )
}

export default GuestOrderTrackSubTable;
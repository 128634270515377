import React, { useEffect, useRef, useState } from 'react';
import { Typography, Card, Grid, Button, Box } from "@mui/material";
import CssTextField from '../../../../components/overrides/longSearchField'
// import BlackButton from '../../../../components/overrides/blackButton'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { cnsListWatcherGuest } from '../../../../redux/actionCreators/admin/guestBook';
import { createGuestOrderWatcher, cancelGuestOrderWatcher } from '../../../../redux/actionCreators/guest';
import { updateAlert } from '../../../../redux/actionCreators/alert';
import CancelDialog from '../../../../components/admin/kitchen/contractorBook/cancelDialog'; //guestBook
import { getDropDownWatcher } from '../../../../redux/actionCreators/admin/employeeManagement';
import { noteWatcher } from '../../../../redux/actionCreators/admin/contractorBook';

const MealBooking = () => {

    const dispatch = useDispatch();
    const { GuestBookReducers: { menuIdGuestList }, EmployeeReducers: { cafeteriaName }, GuestRoleReducers: { guestRoleUserCount }, ContractorBookReducers: { noteState } } = useSelector((state) => state)

    // console.log('menuIdGuestList', menuIdGuestList);

    const { ProfileReducers: { loginState } } = useSelector(state => state)
    const nUserName = loginState[0]?.cUserName || ''
    const nGuestName = loginState[0]?.cEmployeeName || ''
    const nGuestId = loginState[0]?.nID || ''
    const nUserID = loginState[0]?.nID
    // const nGRoleID = loginState[0]?.nGuestID
    const gCount = guestRoleUserCount[0]?.GuserCount ? guestRoleUserCount[0]?.GuserCount : 0
    const nDivisionID = loginState[0]?.nDivisionID || 0
    const vendorId = sessionStorage.getItem('vendorId')

    const tempinitsform = useRef();
    const tempinits = useRef();
    const tempinit = useRef();
    const temp = useRef();

    const [todayOrder, setTodayOrder] = useState(false)

    // const cafeteriaList = cafeteriaName?.filter(m => m.id !== 0) || []

    // const custValidation = (Yup) => {
    //     if (cafeteriaList.length) {
    //         const cust = {}
    //         const meals = ['Breakfast', 'Lunch', 'Snacks', 'Dinner', 'Supper']
    //         cafeteriaList.forEach(m => {
    //             const diff = cafeteriaList.filter(f => f.id !== m.id)

    //             meals.forEach(d => {
    //                 const getBal = (values) => {
    //                     const bal = diff.reduce((a, b) => {
    //                         return a + (values[`${d}-${b.id}`] || 0)
    //                     }, 0)
    //                     return bal
    //                 }

    //                 cust[`${d}-${m.id}`] = Yup.number().test("Invalid count", "Invalid count", (data = 0, mm) => {
    //                     if ((data + getBal(mm.parent)) > gCount) {
    //                         return false
    //                     }
    //                     return true
    //                 })
    //             })

    //         })
    //         return cust
    //     }
    //     return {}
    // }

    const Schema = Yup.object().shape({
        // ...custValidation(Yup)
    });

    const formik = useFormik({
        initialValues: {
            userName: '',
            guestID: '',
            guestName: '',
            orderDate: new Date(),
        },
        validationSchema: Schema,

        onSubmit: () => {
            const { userName, guestID, orderDate, guestName, ...rest } = { ...formik.values }
            let mealArr = []

            Object.keys(rest).forEach((key, index) => {
                const mealObj = menuIdGuestList.find(m => m.cmealName === key) || {}
                if (rest[key] !== 0 && +rest[key] !== +mealObj.nQuantity) {
                    mealArr.push({ ...mealObj, nQuantity: +rest[key] })
                }
            })

            const data = {
                "ACTION": "InsertGuestOrderBooking",
                "nGuestID": `${guestID}`,
                "bookingData": mealArr,
                nUserID,
                nBookingID: getBookingId(),
                OrderDate: orderDate,
                nDivisionID,                
                CVENDORID: `${vendorId}`
            }

            if (mealArr.length) {
                dispatch(createGuestOrderWatcher(data))
            }
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = formik;

    useEffect(() => {
        dispatch(noteWatcher({ role: 'guest', orderDate: values.orderDate, gCount: gCount }))
    }, [values.orderDate]);

    const initsform = () => {
        if (values.guestID !== '') {

            let OrderDate = ''
            const curTime = new Date()
            const getTime = curTime.toTimeString().slice(0, 5)

            if (getTime >= '07:00' && getTime <= '09:00') {
                setTodayOrder(true)
                OrderDate = curTime
            } else {
                curTime.setDate(curTime.getDate() + 1);
                OrderDate = curTime
            }

            let data = {
                "ACTION": "Cns",
                "nID": `${values.guestID}`,
                OrderDate,
                nDivisionID,
                CVENDORID: `${vendorId}`
            }

            // let dat = {
            //     "ACTION": "GuserCount",
            //     "nID": `${nGRoleID}`
            // }

            setFieldValue("orderDate", OrderDate)
            dispatch(cnsListWatcherGuest(data, true))
            // dispatch(selectGuestRoleCountWatcher(dat))
        }
    };

    tempinitsform.current = initsform;

    useEffect(() => {
        tempinitsform.current();
    }, [values.guestID, nDivisionID, vendorId]);

    // useEffect(() => {
    //     if (values.guestID !== '') {
    //         let data = {
    //             "ACTION": "Cns",
    //             "nID": `${values.guestID}`
    //         }
    //         dispatch(cnsListWatcherGuest(data, true))
    //     }
    // }, [values.guestID])

    const getBookingId = () => {
        const get = menuIdGuestList.find(d => d.nid) || {}
        return get.nid
    }

    const inits = () => {
        if (menuIdGuestList.length) {
            menuIdGuestList.map(m => (setFieldValue(m.cmealName, m.nQuantity)))
        }
    };

    tempinits.current = inits;

    useEffect(() => {
        tempinits.current();
    }, [menuIdGuestList]);

    // useEffect(() => {
    //     if (menuIdGuestList.length) {
    //         menuIdGuestList.map(m => (setFieldValue(m.cmealName, m.nQuantity)))
    //     }
    // }, [menuIdGuestList])

    const init = () => {
        if (!cafeteriaName.length) {
            dispatch(getDropDownWatcher());
        }
    };

    tempinit.current = init;

    useEffect(() => {
        tempinit.current();
    }, [cafeteriaName]);

    // useEffect(() => {
    //     if (!cafeteriaName.length) {
    //         dispatch(getDropDownWatcher());
    //     }
    // }, [cafeteriaName])

    const tempform = () => {
        setFieldValue("guestID", nGuestId)
        setFieldValue("guestName", nGuestName)
        setFieldValue("userName", nUserName)
    };

    temp.current = tempform;

    useEffect(() => {
        temp.current();
    }, []);

    // useEffect(() => {
    //     setFieldValue("guestID", nGuestId)
    //     setFieldValue("guestName", nGuestName)
    //     setFieldValue("userName", nUserName)
    //     const today = new Date();
    //     let tomorrow = new Date(today);
    //     tomorrow.setDate(tomorrow.getDate() + 1);
    //     setFieldValue("orderDate", tomorrow)
    // }, [])

    const handleCancel = () => {

        if (!getBookingId()) {
            return dispatch(updateAlert('There is no order to cancel', 'error'))
        }
        if (values.guestID !== '') {
            let id = getBookingId()
            let data = {
                ACTION: "OrderCancel",
                nGuestID: values.guestID,
                CVENDORID: `${vendorId}`
            }
            dispatch(cancelGuestOrderWatcher(id, data))
        }
    }



    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}` }}>
                <Grid container spacing={3} sx={{ padding: '30px' }} >
                    <Grid item lg={6} xs={6} sx={{ justifyContent: 'left' }}>
                        <div>
                            <Typography variant='t20_600'> Meal Booking </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={3} display='flex' flexDirection='row' justifyContent='left' sx={{ padding: '30px', pb: '60px' }}>
                    <Grid item
                        lg={4}
                        xs={4}
                        md={4}
                        sm={4}
                    >
                        <Typography variant='t14_600'> User Name </Typography>
                        <CssTextField sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '42px'
                            }
                        }}
                            placeholder='User Name'
                            name='guestName'
                            fullWidth
                            size='medium'
                            InputProps={{ readOnly: true }}
                            {...getFieldProps("guestName")}
                            error={Boolean(touched.guestName && errors.guestName)}
                            helperText={touched.guestName && errors.guestName}
                        />
                    </Grid>

                    <Grid item
                        lg={4}
                        xs={4}
                        md={4}
                        sm={4}
                    >
                        <Typography variant='t14_600'> User ID </Typography>
                        <CssTextField sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '42px'
                            }
                        }}
                            placeholder='User ID'
                            name='userName'
                            fullWidth
                            size='medium'
                            InputProps={{ readOnly: true }}
                            {...getFieldProps("userName")}
                            error={Boolean(touched.userName && errors.userName)}
                            helperText={touched.userName && errors.userName}
                        />
                    </Grid>

                    <Grid item
                        lg={4}
                        xs={4}
                        md={4}
                        sm={4}
                    >
                        <Typography variant='t14_600'> Order Date </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                // label='Order Date'
                                name="orderDate"
                                inputFormat="dd/MM/yyyy"
                                // {...getFieldProps("orderDate")}
                                value={values.orderDate}
                                renderInput={(params) => <CssTextField {...params} fullWidth sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                />}
                                onChange={(newValue) => {
                                    setFieldValue("orderDate", newValue);
                                    if (values.guestID) {
                                        let data = {
                                            "ACTION": "Cns",
                                            "nID": `${values.guestID}`,
                                            OrderDate: newValue,
                                            CVENDORID: vendorId
                                        }
                                        dispatch(cnsListWatcherGuest(data, true))
                                    }
                                }}
                                disablePast
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
             
                    <Grid  container spacing={3} display='flex' flexDirection='row' justifyContent='left' sx={{ padding: '30px', pt: 0 }}>

                        {!todayOrder &&
                            <Grid item
                                lg={2.3}
                                xs={12}
                                sm={4}
                                md={3}
                            >
                                <Typography variant='t14_600'> Breakfast </Typography>
                                <CssTextField sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                    placeholder='Breakfast'
                                    name={`Breakfast`}
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps(`Breakfast`)}
                                    value={values[`Breakfast`] || ''}
                                    error={Boolean(touched[`Breakfast`] && errors[`Breakfast`])}
                                />
                            </Grid>
                        }

                        <Grid item
                            lg={2.3}
                            xs={12}
                            sm={4}
                            md={3}
                        >
                            <Typography variant='t14_600'> Lunch </Typography>
                            <CssTextField sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '42px'
                                }
                            }}
                                placeholder='Lunch'
                                name={`Lunch`}
                                fullWidth
                                size='medium'
                                {...getFieldProps(`Lunch`)}
                                value={values[`Lunch`] || ''}
                                error={Boolean(touched[`Lunch`] && errors[`Lunch`])}
                            />
                        </Grid>
                        {!todayOrder && <>
                            <Grid item
                                lg={2.3}
                                xs={12}
                                sm={4}
                                md={3}
                            >
                                <Typography variant='t14_600'> Snacks </Typography>
                                <CssTextField sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                    placeholder='Snacks'
                                    name={`Snacks`}
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps(`Snacks`)}
                                    value={values[`Snacks`] || ''}
                                    error={Boolean(touched[`Snacks`] && errors[`Snacks`])}
                                />
                            </Grid>

                            <Grid item
                                lg={2.3}
                                xs={12}
                                sm={4}
                                md={3}
                            >
                                <Typography variant='t14_600'> Dinner </Typography>
                                <CssTextField sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                    placeholder='Dinner'
                                    name={`Dinner`}
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps(`Dinner`)}
                                    value={values[`Dinner`] || ''}
                                    error={Boolean(touched[`Dinner`] && errors[`Dinner`])}
                                />
                            </Grid>

                            <Grid item
                                lg={2.5}
                                xs={12}
                                sm={6.5}
                                md={3}
                            >
                                <Typography variant='t14_600'> Early morning snacks </Typography>
                                <CssTextField sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                    placeholder='Early morning snacks'
                                    name={`Early morning snacks`}
                                    fullWidth
                                    size='medium'
                                    {...getFieldProps(`Early morning snacks`)}
                                    value={values[`Early morning snacks`] || ''}
                                    error={Boolean(touched[`Early morning snacks`] && errors[`Early morning snacks`])}
                                />
                            </Grid>
                        </>
                        }

                    </Grid>

                <Box color='common.sred' style={{ padding: '20px', display: 'flex', flexDirection: 'column', spacing: '10px' }}>
                    <Typography variant='t14_600'>Note:</Typography>
                    {noteState}
                </Box>

                <Grid container
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                    spacing={2}
                    sx={{ padding: '50px' }}
                >
                    <Grid item >
                        <CancelDialog handleCancel={handleCancel} />
                    </Grid>
                    <Grid item >
                        <Button sx={{
                            width: '120px',
                            height: '42px',
                            left: '0px',
                            top: '0px',
                            backgroundColor: 'common.Bblack',
                            '&: hover': {
                                backgroundColor: 'common.Bblack',
                            }
                        }} variant='contained' color='primary' onClick={handleSubmit}
                            type='submit'
                        >
                            <Typography variant='t16_400' color='inherit'>Save</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </>

    )
}

export default MealBooking;
import React from 'react';
import { Card, Grid, Button, InputAdornment, Typography } from "@mui/material";
import SearchField from '../../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { ExportCsv } from "@material-table/exporters";
import VendorFilter from '../vendorFilter';
import { useSelector } from 'react-redux';

const FeedBackHeader = ({ handleSearch, searchText, rowData, columnData, setVendorState, handleSubmitAction }) => {

    const { ProfileReducers: { loginState } } = useSelector((state) => state)
    const { nRoleID, nVendorID } = loginState[0]

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={3} xs={12} md={3} sm={6} >
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px',

                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            onChange={handleSearch}
                            value={searchText} />
                    </Grid>

                    <Grid lg={3} xs={12} md={3} sm={6} sx={{ justifyContent: 'center', mt: '20px' }}>
                        <VendorFilter setVendorState={setVendorState}/>
                    </Grid>

                    {
                        nRoleID !== 3 ?
                        <Grid item lg={3} xs={12} md={3} sm={6} sx={{ justifyContent: 'center' }} >
                        <Button variant='contained' sx={{ height: 50, backgroundColor: 'common.Bblack', '&:hover':{backgroundColor: 'common.Bblack'} }} fullWidth endIcon={<DoubleArrowIcon />} onClick={() => handleSubmitAction()}>
                            <Typography variant='t16_400_1' color='common.white'>Submit</Typography>
                        </Button>
                        </Grid>
                        :
                        <Grid item lg={3} xs={12} md={3} sm={6}></Grid>
                    }

                    <Grid item lg={3} xs={12} md={3} sm={6} >

                        <Button variant='contained' sx={{ height: '50px' }} fullWidth endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(columnData, rowData, "Feedbacks")}>
                            <Typography variant='t16_500'>Export to Excel</Typography>
                        </Button>

                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default FeedBackHeader;
import React from 'react';
import { Card, Grid, InputAdornment } from "@mui/material";
import SearchField from '../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";

const FeedbackHeaderCard = ({handleSearch, searchText}) => {

    return (
        <>
            <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={12} xs={12} md={12} sm={12} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: 50
                                }
                            }}
                            placeholder='Search' 
                            fullWidth
                            value={searchText}
                            onChange={handleSearch} 
                            />
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default FeedbackHeaderCard;
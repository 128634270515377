// Contractor
import UserCreation from '../pages/superAdmin/userCreation/employeeManagement';
import DivisionCreation from '../pages/superAdmin/divisionCreation';
//profile
import Profile from '../pages/profile'
import ChangePassword from '../pages/changePassword'
//layout
import NavLayout from '../layouts/dashboard';

const SuperAdmin =  {
  path: '/superAdmin',
  element: <NavLayout />,
  children: [
    { index: true, element: <UserCreation /> },
    { path: "divisionCreation", element: <DivisionCreation/> },
    { path: "profile", element: <Profile /> },
    { path: "changePassword", element: <ChangePassword /> },
  ]
}

export default SuperAdmin;

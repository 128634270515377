import http from "../../http-common";

class GuestRoleServices {

    getGuestBookingHistory(data) {
        return http.post("/contractor/contractorOrderBooking", data);
    }

    getGuestDashboard(data) {
        return http.post("/contractor/dashboard", data);
    }

    createGuestOrder(data) {
        return http.post("/contractor/addGuestBook", data); ///contractor/addContractorBook
    }
    
    cancelGuestOrder(id, data) {
        return http.post(`/contractor/cancelGuestOrder/${id}`, data); ///contractor/cancelOrder
    }

    getGRoleSwipeHistory(data) {
        return http.post("/contractor/guestSwipeHistory", data);
    }

    getGuestRoleCount(data) {
        return http.post("/admin/guestCount", data);
    }

    
}

export default new GuestRoleServices();
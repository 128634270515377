import { Box, Button } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { paymentWatcher } from '../../../redux/actionCreators/user/currentOrder'
import services from '../../../services/admin/reports'

function VerifyPayment() {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [msg, setmsg] = useState('')

     const { ProfileReducers: { loginState } } = useSelector(state => state)

    const vendorId = sessionStorage.getItem('vendorId')
    const { nDivisionID} = loginState[0] || {}

    useEffect(() => {
        const fetch = async () => {
            try {
                const {data} = await services.verifyCashfree({ order_id: id })

                if (data.order_status === 'PAID') {
                    const payload = {
                        nOrderID: data.order_tags?.OrderNID,
                        cOrderID: data.order_tags?.OrderID,
                        nTotalRate: data.order_amount,
                        cPaymentStatus: 'Success',
                        cPaymentDesc: 'Success',
                        cBankTrackingID: data.order_id,
                        cReferenceID: `${data.cf_order_id}`,
                        nUserID: data.customer_details?.customer_id,
                        nCafeteriaId: nDivisionID,
                        CVENDORID : `${vendorId}`
                    };
                    dispatch(paymentWatcher(payload, navigate))
                    setmsg('This order is paid!')
                } else {
                    setmsg('Order has not been paid!')
                }
            }
            catch (e) {
                console.log(e.response.data)
            }
        }
        fetch()
    }, [])

    return (
        <Box sx={{ textAlign: 'center', mt: '100px' }}>
            <h3>
                {msg}
            </h3>
            <Button
                variant="contained"
                component={Link}
                to='/user'
                type='button'
                sx={{ mt: '20px' }}
            >Home</Button>
        </Box>

    )
}

export default VerifyPayment
import { Divider, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const NotificationSidebar = ({ setOpenNotification }) => {

    const { KioskRoleReducers: { notificationDetails } } = useSelector(state => state)

    const handleClick = () => {
        setOpenNotification(false)
    }

    return (
        <Box width="357px" p="24px" bgcolor="grey.0">

            <Box display='flex' alignItems='center' >
                <Typography variant='t24_700'>
                    Notifications
                </Typography>
                <Typography ml="auto">
                    <IconButton
                        onClick={handleClick}
                        color='primary'
                    >
                        <CloseOutlinedIcon color="primary" />
                    </IconButton>
                </Typography>
            </Box>
            <Divider sx={{ mt: '40px', mb: '24px' }} />

            {
                notificationDetails.map((data) => {
                    return(
                        <>
                        <Box display='flex' sx={{ mb: '10px' }}>
                            <Typography sx={{width: 50, minWidth: 50}}>
                                <AccountCircleIcon color="primary" fontSize="large" />
                            </Typography>

                            <Box display="flex" flexDirection="column" width="100%" pr='5px' >
                                <Typography variant="t16_600">{data.title}</Typography>
                                <Typography variant="t14_600" color='grey.500'>{data.body}</Typography>
                            </Box>

                        </Box>
                        </>
                    )
                })
            }
        </Box>

    );
};

export default NotificationSidebar;

import { motion } from 'framer-motion';
import { Link as RouterLink, Navigate } from 'react-router-dom';
// material
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../../components/animate';
import { GetRoutes } from '../../utils/constants';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export default function Page404() {

  const { ProfileReducers: { loginState } } = useSelector(state => state)

  if(!loginState.length){
    return <Navigate to='/login' />
  }
  
  return (
      <Container sx={{height: '100%', display: 'flex', alignItems:'center'}}>
        <MotionContainer initial="initial" open sx={{ width: '100%'}}>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Sorry, page not found!
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL?
              Be sure to check your spelling.
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/404.jpg"
                sx={{ height: 360, mx: 'auto', objectFit: 'cover', my: { xs: 5, sm: 10 } }}
              />
            </motion.div>

            <Button to={GetRoutes(loginState[0]?.cRoleName) || '/login'} size="large" variant="contained" component={RouterLink}>
              Go to Home
            </Button>
          </Box>
        </MotionContainer>
      </Container>
  );
}

import React, { useEffect, useState } from "react";
import { getInvoice } from "./invoiceTemp";
import Services from "../../../services/admin/reports";
import { useParams } from "react-router-dom";
import { getGst, getGstOnly } from "../../../utils/gst";
import { inWords } from "../../../utils/common";
import { companyDetails } from "../../../utils/constants";
import {
    updateFalseLoader,
    updateOpenLoader,
} from "../../../redux/actionCreators/spinner";
import { useDispatch } from "react-redux";
import { updateAlert } from "../../../redux/actionCreators/alert";
import moment from "moment/moment";
import { ToWords } from "to-words";

function UserInvoice() {
    const { orderDate, userName } = useParams();
    const toWords = new ToWords();
    const [invoiceData, setInvoiceData] = useState({});
    const dispatch = useDispatch();
    const fetchFun = async () => {
        const data = {
            Active: "AdminInvoice",
            userName: userName,
            dOrderdate: orderDate,
        };
        try {
            dispatch(updateOpenLoader());
            const {
                data: { recordset },
            } = await Services.contractorInvoice(data);
            dispatch(updateFalseLoader());

            // console.log('recordset', recordset[0].GstNo);
            const gst = recordset[0].GstNo;
            const nId = recordset[0].nID;
            const bankname = recordset[0].bankName;
            const accountname = recordset[0].accountName;
            const accountno = recordset[0].bankAccountNo;
            const ifsc = recordset[0].IFSC;
            const branchname = recordset[0].branchName;
            const companydetails = recordset[0].companyDetails;
            const divisionName = recordset[0].cDivisionName;

            let cEmployeeName = "";
            let cUserName = "";
            let nGst = 0;
            let nGst1 = 0;
            let Amount = 0;
            let TotalAmount = 0;
            let nID = "";
            let RupeesInwords = "";
            let info = "";
            let cVendorName = "";
            let accountName = "";
            let bankName = "";
            let bankAccountNo = "";
            let IFSC = "";
            // let GstNo = ""
            let dOrderDate = "";

            const date = new Date();

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let yearDiff_1 = date.getFullYear() + 1;

            let yearDiff =
                String(year).slice(2, 4) + "-" + String(yearDiff_1).slice(2, 4);

            if (recordset.length > 0) {
                info +=
                    "<tr>" +
                    "<td colspan='2' class='thick-border th'>Menu Name</td>" +
                    " <td colspan='3' class='thick-border th'> Menu Quantity</td>" +
                    " <td colspan='6' class='thick-border th'>Menu Amount</td>" +
                    "</tr>";

                for (let i = 0; i < recordset.length; i++) {
                    cEmployeeName = recordset[i]["cEmployeeName"];
                    cUserName = recordset[i]["cUserName"];
                    nGst += getGstOnly(+recordset[i]["nToatlRate"]);
                    nGst1 = getGstOnly(+recordset[i]["nToatlRate"]);
                    Amount += recordset[i]["nToatlRate"];
                    TotalAmount += +recordset[i]["nToatlRate"] + +nGst1;
                    // nID = from.split('-')[2] + to.split('-')[2] + contractorID.padStart(6, '0')
                    // nID += recordset[i]["nid"]
                    RupeesInwords = inWords(`${TotalAmount}`);
                    info +=
                        "<tr>" +
                        "<td colspan='2' class='text-center bordery-0'>" +
                        recordset[i]["cMenuName"] +
                        "</td>" +
                        " <td colspan='3' class='text-center bordery-0'> " +
                        recordset[i]["nQuantity"] +
                        "</td>" +
                        " <td colspan='6' class='text-center bordery-0'> " +
                        recordset[i]["nRate"] +
                        "</td>" +
                        "</tr>";
                    dOrderDate = moment(orderDate).format("DD/MM/YYYY");
                    cVendorName += recordset[0].cVendorName;
                    accountName += recordset[i]["accountName"];
                    bankName += recordset[i]["bankName"];
                    bankAccountNo += recordset[i]["bankAccountNo"];
                    IFSC += recordset[i]["IFSC"];
                    // GstNo += gst
                }

                const obj = {
                    NO: divisionName + "/" + "000000" + nId + "/" + yearDiff,
                    CUSTOMER_USERNAME: cEmployeeName === null ? "-" : cEmployeeName,
                    CUSTOMER_USERID: cUserName === null ? "-" : cUserName,
                    CUSTOMER_NAME: accountname === null ? "-" : accountname,
                    CUSTOMER_ADDRESS: companydetails === null ? "-" : companydetails,
                    CUSTOMER_GSTIN: gst === null ? "-" : gst,
                    ORDERDATE: dOrderDate === null ? "-" : dOrderDate,
                    FOOD_AMOUNT: Amount === null ? "-" : Amount,
                    SERVICE_AMOUNT: 0,
                    TAXABLE: Amount + 0,
                    SGST: nGst / 2,
                    CGST: nGst / 2,
                    SUBTOTAL: Amount === null ? "-" : Amount,
                    ROUNDOFF: 0,
                    TOTAL: TotalAmount === null ? "-" : TotalAmount,
                    TOTAL_AS_TEXT: RupeesInwords === null ? "-" : RupeesInwords,
                    INFO: info,
                    VENDORNAME: cVendorName === null ? "-" : cVendorName,
                    BANKNAME: bankname === null ? "-" : bankname,
                    ACCOUNTNAME: accountname === null ? "-" : accountname,
                    ACCOUNTNO: accountno === null ? "-" : accountno,
                    IFSCNO: ifsc === null ? "-" : ifsc,
                    BRANCHNAME: branchname === null ? "-" : branchname,
                };
                setInvoiceData(obj);
            }
        } catch (e) {
            dispatch(updateFalseLoader());
            dispatch(updateAlert("Something went wrong", "error"));
        }
    };

    useEffect(() => {
        fetchFun();
    }, []);

    useEffect(() => {
        if (Object.keys(invoiceData).length) window.print();
    }, [invoiceData]);

    return <div dangerouslySetInnerHTML={{ __html: getInvoice(invoiceData) }} />;
}

export default UserInvoice;

// USER Watcher Actions
    //dashboard
    export const GET_KIOSK_DASHBOARD_WATCHER = "GET_KIOSK_DASHBOARD_WATCHER";

    export const UPDATE_BOOKING_DETAILS_STATUS_WATCHER = "UPDATE_BOOKING_DETAILS_STATUS_WATCHER";
    
    export const GET_KIOSK_MENU_LIST_WATCHER = "GET_KIOSK_MENU_LIST_WATCHER";

    export const UPDATE_MENU_LIST_STATUS_WATCHER = "UPDATE_MENU_LIST_STATUS_WATCHER";

    export const GET_NOTIFICATION_DETAILS_WATCHER = "GET_NOTIFICATION_DETAILS_WATCHER";

    export const GET_NOTIFICATION_COUNT_WATCHER = "GET_NOTIFICATION_COUNT_WATCHER";

    export const UPDATE_NOTIFICATION_DETAILS_WATCHER = "UPDATE_NOTIFICATION_DETAILS_WATCHER";

// USER Reducer Actions
    
    export const GET_KIOSK_DASHBOARD_LIST = "GET_KIOSK_DASHBOARD_LIST"

    export const GET_KIOSK_MENU_LIST = "GET_KIOSK_MENU_LIST"

    export const GET_NOTIFICATION_DETAILS_LIST = "GET_NOTIFICATION_DETAILS_LIST"

    export const GET_NOTIFICATION_COUNT_LIST = "GET_NOTIFICATION_COUNT_LIST"

import React, { useEffect, useRef, useState } from 'react'
import { Typography, Card, Grid, Box, Autocomplete } from "@mui/material";
import Breadcrumbs from "../../../components/breadcrumbs";
// import ContractorBookTable from '../../../components/admin/kitchen/contractorBook/contractorTable';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CssTextField from '../../../components/overrides/longSearchField';
import BlackButton from '../../../components/overrides/blackButton'
import { createGuestBookWatcher, getGuestNameListWatcher, cancelGuestBookWatcher, cnsListWatcherGuest, selectAllMealWatcherGuest, selectGuestCountWatcher } from '../../../redux/actionCreators/admin/guestBook';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getConvertedDate } from '../../../utils/common';
// import _ from 'lodash'
import CancelDialog from '../../../components/admin/kitchen/guestBook/cancelDialog';
import { updateAlert } from '../../../redux/actionCreators/alert';
import { getDropDownWatcher } from "../../../redux/actionCreators/admin/employeeManagement";
import { noteWatcher } from '../../../redux/actionCreators/admin/contractorBook';

export default function GuestBook() {

    const dispatch = useDispatch();
    const { GuestBookReducers: { guestNameList, menuIdGuestList }, ProfileReducers: { loginState }, EmployeeReducers: { divisionList }, ContractorBookReducers: { noteState } } = useSelector((state) => state)

    const nUserID = loginState[0]?.nID
    const nDivisionID = loginState[0]?.nDivisionID
    const roleName = loginState[0]?.cRoleName
    // const [gCount, setGcount] = useState('')//guestUserCount[0]?.GuserCount ? guestUserCount[0]?.GuserCount : ' '
    // const gCount = guestUserCount[0]?.GuserCount ? guestUserCount[0]?.GuserCount : ' '
    const tempinitsform = useRef();
    const tempinits = useRef();
    const tempinit = useRef();

    const [todayOrder, setTodayOrder] = useState(false)

    // const cafeteriaList = cafeteriaName?.filter(m => m.id !== 0) || []

    // const [dateChange, setDateChange] = useState(false)

    // const custValidation = (Yup) => {
    //     if (cafeteriaList.length) {
    //         const cust = {}
    //         const meals = ['Breakfast', 'Lunch', 'Snacks', 'Dinner', 'Supper']
    //         cafeteriaList.forEach(m => {
    //             const diff = cafeteriaList.filter(f => f.id !== m.id)

    //             meals.forEach(d => {
    //                 const getBal = (values) => {
    //                     const bal = diff.reduce((a, b) => {
    //                         return a + (values[`${d}-${b.id}`] || 0)
    //                     }, 0)
    //                     return bal
    //                 }

    //                 cust[`${d}-${m.id}`] = Yup.number().test("Invalid count", "Invalid count", (data = 0, mm) => {
    //                     if ((data + getBal(mm.parent)) > gCount) {
    //                         return false
    //                     }
    //                     return true
    //                 })
    //             })

    //         })
    //         return cust
    //     }
    //     return {}
    // }

    const Schema = Yup.object().shape({
        guestID: Yup.string().min(1, 'Too Short!').max(500, 'Too Long!').required('Please select guest'),
        // ...custValidation(Yup)
    });

    const formik = useFormik({
        initialValues: {
            guestID: '',
            orderDate: new Date(),
            supperDate: '',
            divisionId: roleName === 'Admin' ? nDivisionID : ''
        },
        validationSchema: Schema,

        onSubmit: () => {
            const { guestID, orderdate, menuID, orderDate, supperDate, divisionId, ...rest } = { ...formik.values }

            let mealArr = []

            Object.keys(rest).forEach((key, index) => {
                const mealObj = menuIdGuestList.find(m => m.cmealName === key) || {}
                if (rest[key] !== 0 && +rest[key] !== +mealObj.nQuantity) {
                    mealArr.push({ ...mealObj, nQuantity: +rest[key] })
                }
            })
            const data = {
                "ACTION": "InsertGuestOrderBooking",
                "nGuestID": `${guestID}`,
                "bookingData": mealArr,
                nUserID,
                nBookingID: getBookingId(),
                OrderDate: orderDate,
                nDivisionID: values.divisionId
            }
            if (mealArr.length) {
                dispatch(createGuestBookWatcher(data))
            }
        }
    });

    const inits = () => {
        // dispatch(getDropDownWatcher());
        dispatch(getGuestNameListWatcher())
        dispatch(selectAllMealWatcherGuest({ "ACTION": "SelectAllMeal" }))
        let dayAfterTomorrow = new Date();
        dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
        setFieldValue("supperDate", getConvertedDate(dayAfterTomorrow))
        //setGcount('');
    };

    tempinits.current = inits;

    useEffect(() => {
        tempinits.current();
    }, []);

    // useEffect(() => {
    //     dispatch(getDropDownWatcher());
    //     dispatch(getGuestNameListWatcher())
    //     dispatch(selectAllMealWatcherGuest({ "ACTION": "SelectAllMeal" }))
    //     let tomorrow = new Date();
    //     tomorrow.setDate(tomorrow.getDate() + 1);
    //     let dayAfterTomorrow = new Date();
    //     dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    //     setFieldValue("orderDate", tomorrow)
    //     setFieldValue("supperDate", getConvertedDate(dayAfterTomorrow))
    // }, [])

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = formik;

    useEffect(() => {
        dispatch(noteWatcher({ role: 'guestBook', orderDate: values.orderDate }))
    }, [values.orderDate]);

    const initsform = () => {
        if (values.guestID) {
            let data = {
                "ACTION": "Cns",
                "nID": `${values.guestID}`,
                OrderDate: values.orderDate,
                nDivisionID: values.divisionId
            }
            
            dispatch(cnsListWatcherGuest(data))
            // setGcount(guestUserCount[0]?.GuserCount ? guestUserCount[0]?.GuserCount : ' ')
        }
    };

    useEffect(()=>{
        let dat = {
            "ACTION": "GuserCount",
            "nID": `${values.guestID}`
        }
        dispatch(selectGuestCountWatcher(dat))

    },[values.guestID, dispatch])

    tempinitsform.current = initsform;

    useEffect(() => {
        tempinitsform.current();
    }, [values.guestID, values.divisionId]);

    // useEffect(() => {
    //     if (values.guestID) {
    //         let data = {
    //             "ACTION": "Cns",
    //             "nID": `${values.guestID}`
    //         }
    //         dispatch(cnsListWatcherGuest(data))
    //     }
    // }, [values.guestID])

    const getBookingId = () => {
        const get = menuIdGuestList.find(d => d.nid) || {}
        return get.nid
    }

    const init = () => {
        if (menuIdGuestList.length && values.guestID) {
            menuIdGuestList.map(m => (setFieldValue(m.cmealName, m.nQuantity)))
        }
    };

    tempinit.current = init;

    useEffect(() => {
        tempinit.current();
    }, [menuIdGuestList]);

    // useEffect(() => {
    //     if (menuIdGuestList.length && values.guestID) {
    //         menuIdGuestList.map(m => (setFieldValue(m.cmealName, m.nQuantity)))
    //     }
    // }, [menuIdGuestList])

    const handleCancel = () => {

        if (!getBookingId()) {
            return dispatch(updateAlert('There is no order to cancel', 'error'))
        }

        if (values.guestID !== '') {
            let id = getBookingId()
            let data = {
                ACTION: "OrderCancel",
                nGuestId: values.guestID

            }
            dispatch(cancelGuestBookWatcher(id, data))
        }
    }
    // const selectObj = { ID: 0, Name: "-SELECT-" }

    useEffect(() => {
        const curTime = new Date()
        const getTime = curTime.toTimeString().slice(0, 5)
        if (getTime >= '07:00' && getTime <= '09:00') {
            setTodayOrder(true)
            setFieldValue("orderDate", curTime)
        } else {
            curTime.setDate(curTime.getDate() + 1);
            setFieldValue("orderDate", curTime)
        }
    }, [setFieldValue])

    useEffect(()=>{
        dispatch(getDropDownWatcher())
    },[dispatch])

    useEffect(() => {
        setFieldValue('guestID', '')
    }, [values.divisionId])

    return (
        <div>
            <Box p='30px'>
                <Breadcrumbs content={["Kitchen", "Guest Book"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' >Guest Book </Typography>
                </Box>
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "20px 20px 0px 0px" }}>
                    <Grid container spacing={3} sx={{ padding: '30px', display: 'flex' }} >

                    <Grid
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                            item={true}
                        >
                            {
                                roleName === 'Admin' ?
                                    <CssTextField
                                        placeholder='Division'
                                        name={`Division`}
                                        fullWidth
                                        // {...getFieldProps(`divisionId`)}
                                        value={divisionList.find(d=>d.id === values.divisionId)?.name || ''}
                                        InputProps={{
                                            readOnly: true,
                                        }}

                                    />
                                    :
                            <Autocomplete
                                id="combo-box-demo"
                                name="divisionId"
                                fullWidth
                                options={divisionList}
                                size="medium"
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("divisionId", value.id);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Division"
                                    name='divisionId'
                                    required={true}
                                    error={Boolean(touched.divisionId && errors.divisionId)}
                                    helperText={touched.divisionId && errors.divisionId}
                                />}
                            />
                        }
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12} sx={{ justifyContent: 'left' }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                fullWidth
                                options={guestNameList.filter(d=> values.divisionId === d.nDivisionID)}
                                name="guestID"
                                autoHighlight
                                value={guestNameList.find(d=> values.guestID === d.ID) || {Name:'', ID:'0', nDivisionID: '0'}}
                                isOptionEqualToValue={(option, value) => option.ID === (values.guestID || null)}
                                // defaultValue={values.contractorID === "0" ? selectObj : selectObj}
                                getOptionLabel={(option) => option.Name}
                                onChange={(event, value) => {
                                    setFieldValue("guestID", value?.ID);
                                }}
                                renderInput={(params) => <CssTextField {...params}
                                    name="guestID"
                                    label="Select Guest"
                                    required={true}
                                    error={Boolean(touched.guestID && errors.guestID)}
                                    helperText={touched.guestID && errors.guestID}
                                />}
                            />
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12} sx={{ justifyContent: 'left' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label='Order Date'
                                    inputFormat="dd/MM/yyyy"
                                    // inputFormat="DD-MM-YYYY"
                                    renderInput={(params) => <CssTextField {...params}
                                        fullWidth />}
                                    name="orderDate"
                                    // {...getFieldProps("orderDate")}
                                    value={values.orderDate}
                                    // error={Boolean(touched.orderDate && errors.orderDate)}
                                    // helperText={dateChange ? "Order date cannot be changed" : ""}
                                    onChange={(newValue) => {
                                        setFieldValue("orderDate", newValue);
                                        if(values.guestID){
                                            let data = {
                                                "ACTION": "Cns",
                                                "nID": `${values.guestID}`,
                                                OrderDate: newValue
                                            }
                                            dispatch(cnsListWatcherGuest(data))
                                        }
                                    }}
                                    disablePast
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Card>
                <br />
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "6px 6px 0px 0px", padding: '20px', paddingTop: 50 }}>
                    
                        <Grid container spacing={2} display='flex' flexDirection='row' justifyContent='left' sx={{ padding: '15px', pt: 0 }}>

                            {!todayOrder &&
                                <Grid item
                                    lg={2.4}
                                    xs={4}
                                    md={3}
                                >
                                    <Typography variant='t14_600'> Breakfast </Typography>
                                    <CssTextField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '42px'
                                        }
                                    }}
                                        placeholder='Breakfast'
                                        type='number'
                                        name={`Breakfast`}
                                        fullWidth
                                        size='medium'
                                        {...getFieldProps(`Breakfast`)}
                                        error={Boolean(touched[`Breakfast`] && errors[`Breakfast`])}

                                    />
                                </Grid>
                            }


                            <Grid item
                                lg={todayOrder ? 4 : 2.4}
                                xs={4}
                                md={3}
                            >
                                <Typography variant='t14_600'> Lunch </Typography>
                                <CssTextField sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                    placeholder='Lunch'
                                    name={`Lunch`}
                                    fullWidth
                                    type='number'
                                    size='medium'
                                    {...getFieldProps(`Lunch`)}
                                    error={Boolean(touched[`Lunch`] && errors[`Lunch`])}

                                />
                            </Grid>

                            {!todayOrder &&
                                <>
                                    <Grid item
                                        lg={2.4}
                                        xs={4}
                                        md={3}
                                    >
                                        <Typography variant='t14_600'> Snacks </Typography>
                                        <CssTextField sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '42px'
                                            }
                                        }}
                                            placeholder='Snacks'
                                            name={`Snacks`}
                                            fullWidth
                                            type='number'
                                            size='medium'
                                            {...getFieldProps(`Snacks`)}
                                            error={Boolean(touched[`Snacks`] && errors[`Snacks`])}

                                        />
                                    </Grid>

                                    <Grid item
                                        lg={2.4}
                                        xs={4}
                                        md={3}
                                    >
                                        <Typography variant='t14_600'> Dinner </Typography>
                                        <CssTextField sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '42px'
                                            }
                                        }}
                                            placeholder='Dinner'
                                            name={`Dinner`}
                                            fullWidth
                                            type='number'
                                            size='medium'
                                            {...getFieldProps(`Dinner`)}
                                            error={Boolean(touched[`Dinner`] && errors[`Dinner`])}

                                        />
                                    </Grid>

                                    <Grid item
                                        lg={2.4}
                                        xs={4}
                                        md={3}
                                    >
                                        <Typography variant='t14_600'> Early morning snacks </Typography>
                                        <CssTextField sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '42px'
                                            }
                                        }}
                                            placeholder='Early morning snacks'
                                            name={`Early morning snacks`}
                                            fullWidth
                                            type='number'
                                            size='medium'
                                            {...getFieldProps(`Early morning snacks`)}
                                            error={Boolean(touched[`Early morning snacks`] && errors[`Early morning snacks`])}

                                        />
                                    </Grid>
                                </>
                            }

                        </Grid>
                       
                        <Box color='common.sred' style={{ padding: '20px', display: 'flex', flexDirection: 'column', spacing: '10px' }}>
                        <Typography variant='t14_600'>Note:</Typography>
                        {noteState}
                    </Box>
                </Card>

                {/* <Grid container
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                    spacing={2}
                    sx={{ paddingTop: '50px' }}
                >
                    <Grid item >
                        <Button sx={{
                            width: '120px',
                            height: '42px',
                            left: '0px',
                            top: '0px',
                        }} variant='contained' color='inherit'
                            onClick={handleCancel}>
                            <Typography variant='t14_400' color='common.black'> Cancel </Typography>
                        </Button>
                    </Grid>
                    <Grid item >
                        <Button sx={{
                            width: '120px',
                            height: '42px',
                            left: '0px',
                            top: '0px',
                            backgroundColor: 'common.Bblack'
                        }} variant='contained' color='primary' onClick={handleSubmit}>
                            <Typography variant='t14_400' color='inherit'>Save</Typography>
                        </Button>
                    </Grid>
                </Grid> */}

                <Grid container
                    item={true}
                    display='flex'
                    justifyContent='flex-end'
                    flexDirection='row'
                    paddingTop='30px'
                    spacing={2}
                >
                    <Grid
                        item={true}
                    >
                        <CancelDialog handleCancel={handleCancel} />
                    </Grid>
                    <Grid
                        item={true}
                    >
                        <BlackButton
                            style={{
                                width: '120px',
                                height: '42px',
                                left: '0px',
                                top: '0px',
                            }}
                            variant='contained'
                            fullWidth
                            size='medium'
                            type='save'
                            onClick={handleSubmit}
                        >
                            <Typography color='inherit' variant='t16_400'>Save</Typography>
                        </BlackButton>
                    </Grid>
                </Grid>
            </Box>
        </div >
    )
}

import React from 'react';
import VendorManagementTemp from './vendorManagementTemp';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography } from "@mui/material";


const VendorManagement = () => {
    return (
        <>
            <Box p='30px'>
                <Breadcrumbs content={["User Management", "Vendor"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' > Vendor Management </Typography>
                </Box>
                <VendorManagementTemp />
            </Box>
        </>
    )
}

export default VendorManagement;
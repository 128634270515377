// component
import { HomeOutlined, ShoppingCartOutlined } from '@mui/icons-material'
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import LocalTaxiOutlinedIcon from '@mui/icons-material/LocalTaxiOutlined';
// ----------------------------------------------------------------------

export const sidebarFoodClerkMain = [
  {
    title: 'Dashboard',
    path: '/foodClerk',
    icon: <ShoppingCartOutlined color='inherit' />
  },
  {
    title: 'Employee Booking',
    path: '/foodClerk/employeeBooking',
    icon: <ShoppingCartOutlined color='inherit' />
  },
  {
    title: 'Contractor Booking',
    path: '/foodClerk/contractorBooking',
    icon: <ShoppingCartOutlined color='inherit' />
  },
  {
    title: 'Order History',
    path: '/foodClerk/orderHistory',
    icon: <ShoppingCartOutlined color='inherit' />
  },
 
];

import { SWIPE_CONTRACTOR_LIST, GET_SWIPE_CONTRACTOR_WATCHER, SWIPE_EMPLOYEE_LIST, GET_SWIPE_EMPLOYEE_WATCHER, SWIPE_LIST, GET_COUNTER_ADMIN_WATCHER, GET_FOOD_DETAILS_WATCHER, UPDATE_DELIVERY_WATCHER } from "../../actionTypes/screen";

// Worker triggering actionCreators
export function getSwipeContractorWatcher(data) {
    return { type: GET_SWIPE_CONTRACTOR_WATCHER, payload: data };
}

export function getSwipeEmployeeWatcher(data, response) {
    return { type: GET_SWIPE_EMPLOYEE_WATCHER, payload: data, response };
}

export function getCounterAdminWatcher(data) {
    return { type: GET_COUNTER_ADMIN_WATCHER, payload: data };
}

export function getFoodDetailsWatcher(data, response) {
    return { type: GET_FOOD_DETAILS_WATCHER, payload: data, response };
}

export function updateDeliveryWatcher(data, response) {
    return { type: UPDATE_DELIVERY_WATCHER, payload: data, response };
}

// Redux state changing actionCreators
export function swipeContractorList(data) {
    return { type: SWIPE_CONTRACTOR_LIST, payload: data };
}

export function swipeEmployeeList(data) {
    return { type: SWIPE_EMPLOYEE_LIST, payload: data };
}

export function swipeList(data) {
    return { type: SWIPE_LIST, payload: data };
}
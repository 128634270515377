import React from 'react';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { Button, IconButton, Typography } from '@mui/material';
// import DeleteDialog from '../../../pages/admin/kitchen/deleteDialog';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Link } from 'react-router-dom';
import { getCurDate } from '../../../utils/common';
import { useSelector } from 'react-redux';

const ActionButtons = ({ viewFunction, type, status, tickIconFunction, handleDelete, handleEdit, cancelIconFunction, deleteDialogOpen, setDeleteDialogOpen, invoice, userName, orderDate, isCancelled, orderNo }) => {

    const { ProfileReducers: {loginState} } = useSelector((state) => state)
    // console.log('loginState', loginState[0].nRoleID);
    const role_id = loginState[0].nRoleID
    // const [dialogOpen, setDialogOpen] = useState(false);

    const editIconFunc = () => {
        // setDialogOpen(true);
        // setArrow(1)
        handleEdit()
    }

    return (
        <>
            <div>
                {status === 'list' ?
                    <>
                        {(type === 'userHistory' || type === 'kioskUserHistory' || type === 'specialHistory' || type === 'orderTracking' || type === 'splOrder' || type === 'employeeBooking' || type === 'SplGuest') &&
                            <IconButton onClick={viewFunction}>
                                <RemoveRedEyeOutlinedIcon sx={{ color: 'common.black' }} />
                            </IconButton>
                        }


                        {(type === 'feedback') &&
                            <IconButton onClick={editIconFunc}>
                                <ModeOutlinedIcon sx={{ color: 'common.black' }} />
                            </IconButton>
                        }

                        

                    </>
                    :
                    <>
                        {type === 'feedback' &&
                            <>
                                <IconButton onClick={cancelIconFunction}>
                                    <CancelIcon sx={{ color: 'common.black' }} />
                                </IconButton>
                                <IconButton onClick={tickIconFunction}>
                                    <CheckCircleIcon sx={{ color: 'common.sgreen' }} />
                                </IconButton>
                            </>
                        }

                        {(type === 'userHistory' || type === 'SplGuest' ) && invoice === "invoice" &&
                            <Button disabled = {isCancelled && true} variant='outlined' component={Link} to={`/invoice/user/${orderDate}/${userName}`} target='_blank'>
                                <Typography variant='t16_500'>{"Invoice"}</Typography>
                            </Button>
                        }

                        {type === 'userOrderHistory' && invoice === "invoice" && 
                            <Button disabled = {isCancelled && true} variant='outlined' component={Link} to={`/invoice/user/individual/user/${orderDate}/${orderNo}`} target='_blank'>
                                <Typography variant='t16_500'>{"Invoice"}</Typography>
                            </Button>
                        }
                    </>
                }

            </div >
        </>

    )
}

export default ActionButtons;
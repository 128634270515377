import http from "../../http-common";

class HomeService {
    getMenus(data) {
        return http.post("/user/mealMenu", data);
    }

    addCart(data) {
        return http.post("/user/cart", data);
    }

    getCart(data) {
        return http.post("/user/getCart", data);
    }

    addMinus(id, data) {
        return http.post(`/user/cart/${id}`, data);
    }

    addQuantity(id, data) {
        return http.post(`/user/addQuantity/${id}`, data);
    }

    clearCart(id) {
        return http.delete(`/user/clearCart/${id}`);
    }

    getDaysOfWeek(data) {
        return http.post(`/user/getDaysOfWeek`, data);
    }

    getOrderTotalCost(id) {
        return http.post(`/user/getOrderTotalCost/${id}`);
    }

    viewCart(data) {
        return http.post(`/user/viewCart`, data);
    }

    weeklyBooking(data) {
        return http.post(`/user/weeklyBookingDropDown`, data);
    }

    cafeteria(data) {
        return http.post(`/user/cafeteria`, data);
    }
}

export default new HomeService();
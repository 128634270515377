import React, { useState } from 'react';
import { Button, Typography, Grid, Stack, Autocomplete } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { createGuestWatcher } from '../../../../redux/actionCreators/admin/guestManagement';
import { useDispatch, useSelector } from 'react-redux'
import CssTextField from '../../../overrides/longSearchField';
import { styled } from '@mui/material/styles';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileUploadButton from "../../../overrides/fileUploadButton"
import CloseButton from '../../../overrides/closeButton';

export const Input = styled('input')({
    display: 'none',
});


function GuestCreation(props) {
    const dispatch = useDispatch()

    const { setOpen, status } = props;

    const { ProfileReducers: { loginState }, EmployeeReducers: { divisionList }, UserHomeReducers: { cafeteriaId } } = useSelector(state => state)
    const nUserID = loginState[0]?.nID || 0

    const [imageName, setImageName] = useState('')

    const GuestSchema = Yup.object().shape({

        guestId: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Guest Id is required'),
        guestName: Yup.string('Enter your name').min(2, 'Too Short!').max(50, 'Too Long!').required('Guest Name is required'),
        guestAddress: Yup.string('Enter your address').required('Guest Address is required'),
        mobileNumber: Yup.string().matches(new RegExp('[0-9]{9}'), 'Invalid').required('Mobile number is required'),
        emailId: Yup.string('Enter your EmailId').email('Email must be a valid email address').required('Email is required'),
        divisionId: Yup.string().required('Division Id is required'),

    });

    const formik = useFormik({
        initialValues: {
            guestId: '',
            guestName: '',
            guestDesc: '',
            guestAddress: '',
            landLineNumber: '',
            mobileNumber: '',
            emailId: '',
            profileImage: '',
            divisionId: '',

        },

        validationSchema: GuestSchema,

        onSubmit: () => {
            let values = { ...formik.values }
            let data = {
                ACTION: "INSERT",
                CGUESTID: values.guestId,
                CGUESTNAME: values.guestName,
                CGUESTADDRESS: values.guestAddress,
                CLANDLINENUMBER: values.landLineNumber,
                CMOBILENUMBER: values.mobileNumber,
                CEMAILID: values.emailId,
                CGUESTDESC: values.guestDesc,
                NUSERID: nUserID,
                CIMAGE: values.profileImage,
                NDIVISIONID: values.divisionId,
                SDIVISIONID: cafeteriaId,

            }
            dispatch(createGuestWatcher(data, setOpen))
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    const handleClose = () => {
        setOpen(false)
    }

    const onFileChange = (e) => {
        if (e) {
            var file = e.target.files[0];
            var name = e.target.files[0].name;
            setImageName(name);
            var reader = new FileReader();
            reader.onloadend = function () {
                setFieldValue("profileImage", reader.result)
            }
            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    <Typography variant='h5'
                        align='left'
                        style={{ paddingBottom: '10px' }}>
                        {status === 'edit' ? 'Edit Guest' : 'Guest Creation'}
                    </Typography>

                    <Grid
                        container
                        style={{ paddingTop: '10px' }}
                        spacing={3}
                        direction='row'
                    >

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                id="combo-box-demo"
                                name="divisionId"
                                fullWidth
                                options={divisionList}
                                size="medium"
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("divisionId", value.id);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Division"
                                    name='divisionId'
                                    required={true}
                                    error={Boolean(touched.divisionId && errors.divisionId)}
                                    helperText={touched.divisionId && errors.divisionId}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Guest Id'
                                label='Guest Id'
                                name='guestId'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("guestId")}
                                error={Boolean(touched.guestId && errors.guestId)}
                                helperText={touched.guestId && errors.guestId}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Guest Name'
                                label='Guest Name'
                                name='guestName'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("guestName")}
                                error={Boolean(touched.guestName && errors.guestName)}
                                helperText={touched.guestName && errors.guestName}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                style={{}}
                                fullWidth={true}
                                placeholder='Guest Desc'
                                label='Guest Description'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("guestDesc")}
                                error={Boolean(touched.guestDesc && errors.guestDesc)}
                                helperText={touched.guestDesc && errors.guestDesc}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                style={{}}
                                fullWidth={true}
                                required
                                placeholder='Guest Address'
                                label='Guest Address'
                                name='guestAddress'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("guestAddress")}
                                error={Boolean(touched.guestAddress && errors.guestAddress)}
                                helperText={touched.guestAddress && errors.guestAddress}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                // required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Land Line Number'
                                label='Land Line Number'
                                name='landLineNumber'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("landLineNumber")}
                                error={Boolean(touched.landLineNumber && errors.landLineNumber)}
                                helperText={touched.landLineNumber && errors.landLineNumber}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                style={{}}
                                fullWidth={true}
                                required
                                placeholder='Mobile Number'
                                label='Mobile Number'
                                name='mobileNumber'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("mobileNumber")}
                                error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                                helperText={touched.mobileNumber && errors.mobileNumber}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                // required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Email Id'
                                label='Email Id'
                                name='emailId'
                                color='primary'
                                required
                                type='text'
                                variant='outlined'
                                {...getFieldProps("emailId")}
                                error={Boolean(touched.emailId && errors.emailId)}
                                helperText={touched.emailId && errors.emailId}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <label htmlFor="contained-button-file">
                                <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={onFileChange} />
                                <FileUploadButton
                                    variant="contained"
                                    component="span"
                                    fullWidth
                                    // onClick={imageFunction}
                                    endIcon={<FileUploadOutlinedIcon sx={{ color: 'common.black' }} />}
                                    type='button'
                                    sx={{ height: '55px' }}
                                >
                                    <Typography variant='t16_600' color='grey.600'>
                                        {imageName ? imageName : 'Upload Profile'}
                                    </Typography>
                                </FileUploadButton >
                            </label>
                        </Grid>

                        <Grid
                            xs={12}
                            item={true}
                        >
                            <Stack spacing={2} direction='row' justifyContent='end' >
                                <CloseButton onClick={() => handleClose()}
                                    sx={{
                                        //width: '120px',
                                        height: '42px',
                                        left: '0px',
                                        top: '0px',
                                        minWidth: '120px',
                                        marginTop: 'auto',
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='button'>
                                    <Typography variant='t14_400' color='common.black'>Cancel</Typography>
                                </CloseButton>
                                <Button onClick={handleSubmit}
                                    style={{
                                        //width: '120px',
                                        height: '42px',
                                        left: '0px',
                                        top: '0px',
                                        minWidth: '120px',
                                        backgroundColor: 'common.Bblack',
                                        marginTop: 'auto'
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='submit'
                                >
                                    <Typography variant='t14_400' color='grey.0'>Submit</Typography>
                                </Button>
                            </Stack>

                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </>
    );

}

export default GuestCreation;
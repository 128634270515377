import { DASHBOARD_LIST, GET_DASHBOARD_WATCHER } from "../../actionTypes/foodVendor";

// Worker triggering actionCreators

export function getFoodDashboardWatcher(data) {
    return { type: GET_DASHBOARD_WATCHER, payload: data };
}

// Redux state changing actionCreators
export function dashboardList(data) {
    return { type: DASHBOARD_LIST, payload: data };
}

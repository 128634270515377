import { CREATE_CONTRACTOR_WATCHER, UPDATE_CONTRACTOR_WATCHER, DELETE_CONTRACTOR_WATCHER, GET_CONTRACTOR_WATCHER, CONTRACTOR_LIST, ADD_USER, GET_ALL_CON_USER, GET_VIEW_CON_USER, UPDATE_VIEW_CON_USER, DELETE_VIEW_CON_USER, ALL_CONTRACTOR_USER_LIST, VIEW_CONTRACTOR_USER_LIST } from "../../actionTypes/admin";

// Worker triggering actionCreators

export function getContractorWatcher(data) {
    return { type: GET_CONTRACTOR_WATCHER, payload: data };
}

export function createContractorWatcher(data, setOpen) {
    return { type: CREATE_CONTRACTOR_WATCHER, payload: data, setOpen };
}

export function updateContractorWatcher(id, data) {
    return { type: UPDATE_CONTRACTOR_WATCHER, payload: data, id };
}

export function deleteContractorWatcher(id, data) {
    return { type: DELETE_CONTRACTOR_WATCHER, payload: data, id };
}

export function addConUserWatcher(data, setAddDialogOpen) {
    return { type: ADD_USER, payload: data, setAddDialogOpen};
}

export function getAllConUserWatcher(data) {
    return { type: GET_ALL_CON_USER, payload: data};
}

export function getViewConUserWatcher(data) {
    return { type: GET_VIEW_CON_USER, payload: data};
}

export function upadteViewConUserWatcher(data, nContractorID) {
    return { type: UPDATE_VIEW_CON_USER, payload: data, nContractorID};
}

export function deleteViewConUserWatcher(data, nContractorID) {
    return { type: DELETE_VIEW_CON_USER, payload: data, nContractorID};
}


// Redux state changing actionCreators
export function contractorList(data) {
    return { type: CONTRACTOR_LIST, payload: data };
}

export function allContractorUserList(data) {
    return { type: ALL_CONTRACTOR_USER_LIST, payload: data };
}

export function viewContractorUserList(data) {
    return { type: VIEW_CONTRACTOR_USER_LIST, payload: data };
}

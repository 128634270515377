import React from 'react';
import { Dialog, DialogContent } from '@mui/material';

const CreationDialog = (props) => {

    const { open, setOpen, children, maxWidth='md'} = props;
    return (
        <>
            <Dialog
            fullWidth
                maxWidth= {maxWidth}
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent >
                    {children}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreationDialog;
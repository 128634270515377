import React, { useState, useEffect, useRef } from 'react'
import { Card, Box } from "@mui/material";
import MenuMasterTable from '../../../components/admin/kitchen/menuItemMaster/table';
import FilterCard from '../../../components/admin/kitchen/menuItemMaster/filterCard';
import { getMenuItemMasterWatcher } from '../../../redux/actionCreators/admin/menuItemMaster';
import { useDispatch, useSelector } from 'react-redux'
import { getMealDropdownWatcher } from '../../../redux/actionCreators/admin/menuMaster';
import { getDropDownWatcher } from '../../../redux/actionCreators/admin/employeeManagement';

const MenuItemMaster = () => {
    const dispatch = useDispatch();

    const { UserHomeReducers: { cafeteriaId }, } = useSelector((state) => state)

    const [searchVal, setSearchVal] = useState([])
    const [searchText, setSearchText] = useState('')
    const [tableArr, setTableArr] = useState([])
    const [tabVal, setTabVal] = useState('All');
    const [page, setPage] = useState(1);
    const tempinitsform = useRef();
    const [eventVal, setEventVal] = useState('')

    useEffect(() => {
        if (cafeteriaId) {
            let data2 = { ACTION: "SELECT", SDIVISIONID: cafeteriaId }
            dispatch(getMenuItemMasterWatcher(data2))
        }
    }, [dispatch, cafeteriaId])

    useEffect(() => {
        dispatch(getMealDropdownWatcher());
    }, [dispatch])

    const initsform = () => {
        setPage(1);
    };

    tempinitsform.current = initsform;

    useEffect(() => {
        tempinitsform.current();
    }, [tabVal]);

    // useEffect(() => {
    //     // if(tableArr){
    //     setPage(1);
    //     // }
    // }, [tabVal])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = tableArr.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1);
    }

    useEffect(()=>{
        handleSearch(eventVal);
    },[tableArr])

    return (
        <>
            <Box p='30px'>
                <Box>
                    <FilterCard handleSearch={handleSearch} searchText={searchText} setTableArr={setTableArr} setTabVal={setTabVal} tabVal={tabVal} />
                </Box>
                <br />
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <MenuMasterTable divisionId={cafeteriaId} searchVal={searchVal} searchText={searchText} tableArr={tableArr} tabVal={tabVal} setPage={setPage} page={page} />
                </Card>
            </Box>
        </>
    )
}

export default MenuItemMaster;
import React from 'react';
import {
    TableCell,
    Typography,
    TableRow,
} from '@mui/material';

const OrderTrackingTableRow = ({ rowData }) => {


    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                <TableCell align="left" sx={{ width: '150px' }}>
                    <Typography variant='t14_600'>
                        {rowData.department || '---'}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '150px' }}>
                    <Typography variant='t14_600'>
                    {rowData.dOrderDate}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '150px' }}>
                    <Typography variant='t14_600'>
                        {rowData.category || '---'}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '150px' }}>
                    <Typography variant='t14_600'>
                        {rowData.booked}
                    </Typography>
                </TableCell>
                <TableCell align="left" sx={{ width: '150px' }}>
                    <Typography variant='t14_600'>
                    {rowData.swiped}
                    </Typography>
                </TableCell>
            </TableRow>

        </>
    )
}

export default OrderTrackingTableRow
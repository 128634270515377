import { swipeContractorList, swipeEmployeeList, swipeList } from "../../actionCreators/screen/screen";
import { call, put, takeEvery } from "redux-saga/effects";
import ScreenService from "../../../services/screen/screen";
import { GET_SWIPE_CONTRACTOR_WATCHER, GET_SWIPE_EMPLOYEE_WATCHER, GET_COUNTER_ADMIN_WATCHER, GET_FOOD_DETAILS_WATCHER, UPDATE_DELIVERY_WATCHER } from "../../actionTypes/screen";
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* getContractorEffect(action) {
  try {
    // yield put(updateOpenLoader());
    let { data } = yield call(ScreenService.getContractor, action.payload);
    yield put(swipeContractorList(data.recordset));
    // yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    // yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }  
}

function* getEmployeeEffect(action) {
  try {
    // yield put(updateOpenLoader());
    let { data } = yield call(ScreenService.getEmployee, action.payload);
    yield put(swipeEmployeeList(data.recordset));
    action.response(data)
    // yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    // yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getCounterAdminEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(ScreenService.getSwipeData, action.payload);
    yield put(swipeList(data.recordsets));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* getFoodDetailsEffect(action) {
  try {
    // yield put(updateOpenLoader());
    let { data } = yield call(ScreenService.getFoodDetails, action.payload);
    // yield put(swipeEmployeeList(data.recordset));
    action.response(data)
    // yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* updateDeliveryEffect(action) {
  try {
    // yield put(updateOpenLoader());
    let { data } = yield call(ScreenService.updateDelivery, action.payload);
    // yield put(swipeEmployeeList(data.recordset));
    action.response(data)
    // yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

//saga 
export const SwipeSaga = [
  takeEvery(GET_SWIPE_CONTRACTOR_WATCHER, getContractorEffect),
  takeEvery(GET_SWIPE_EMPLOYEE_WATCHER, getEmployeeEffect),
  takeEvery(GET_COUNTER_ADMIN_WATCHER, getCounterAdminEffect),
  takeEvery(GET_FOOD_DETAILS_WATCHER, getFoodDetailsEffect),
  takeEvery(UPDATE_DELIVERY_WATCHER, updateDeliveryEffect),
]

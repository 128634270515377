import React, { useState } from 'react';
import {
    TableRow,
    TableCell,
    Typography,
    Collapse,
    Card
} from '@mui/material';
import ContractorChildTable from './contractorChildTable';
import ActionButtons from '../../dashboard/actionButtons';
import EditTextField from '../../../overrides/editField';
import { updateContractorWatcher, deleteContractorWatcher, getViewConUserWatcher, getAllConUserWatcher } from '../../../../redux/actionCreators/admin/contractorManagement';
import { useDispatch } from 'react-redux'
import AddDesign from './addIcon/addEmployeeDesign';
import ViewDesign from './viewIcon/viewEmployeeDesign';
import CreationDialog from '../../../dialog';
import DuplicationDesign from "./addIcon/duplicationDesign";
import { emailValidation, phoneValidation } from '../employee/commonRegex';

const ContractorTableRow = ({ rowData, divisionId }) => {
    const dispatch = useDispatch();

    const [expandOpen, setExpandOpen] = useState(false);
    const [status, setStatus] = useState('list');
    const [editData, setEditData] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [addDialogOpen, setAddDialogOpen] = useState(false)
    const [viewDialogOpen, setViewDialogOpen] = useState(false)
    const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false)
    const [dupRfid, setDupRfid] = useState([])
    const [emailValid, setEmailValid] = useState(true)
    const [phoneValid, setPhoneValid] = useState(true)
    const [formErrors, setFormErrors] = useState({
        CCONTRACTORID: null,
        CCONTRACTORNAME: null,
        CCONTRACTORADDRESS: null,
        CMOBILENUMBER: null,
        CEMAILID: null,
        cDivisionName: null,
        DaysToBook: null,
    });
    const [requiredFields] = useState([
        'CCONTRACTORID',
        'CCONTRACTORNAME',
        'CCONTRACTORADDRESS',
        'CMOBILENUMBER',
        'CEMAILID',
        'cDivisionName',
        'DaysToBook',
    ]);
    const [valid, setValid] = useState(false)

    const handleEditChange = (e) => {
        const { name, value } = e.target
        setEditData({ ...editData, [name]: value })
        if(name === 'CEMAILID'){
            let isValidEmail = emailValidation(value)
            setEmailValid(isValidEmail)
        }
        if(name === 'CMOBILENUMBER'){
            let isValidPhoneNo = phoneValidation(value)
            setPhoneValid(isValidPhoneNo)
        }
        validationHandler(name, value)
    }

    const validationHandler = (name, value) => {
        if (!Object.keys(formErrors).includes(name)) return;
        if (
          requiredFields.includes(name) &&
          (value === null ||
            value === 'null' ||
            value === '' ||
            value === false ||
            (Object.keys(value || {}) && value?.value === null))
        ) {
          setFormErrors({
            ...formErrors,
            [name]: capitalize(name) + ' is Required!',
          });
          setValid(true)
        } 
        else {
          setFormErrors({
            ...formErrors,
            [name]: null,
          });
          setValid(false)
        }
    };
    
    const capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const handleEdit = () => {
        let data = { ...rowData }
        setEditData(data);
        setStatus('edit');
    }

    const handleDelete = async () => {
        let data = { "ACTION": "DELETE", SDIVISIONID: divisionId }
        await dispatch(deleteContractorWatcher(rowData.NID, { data }))
        setStatus('list');
        setDeleteDialogOpen(false);
    }

    const tickIconFunction = async () => {
        let data = { ...editData }
        data.ACTION = "UPDATE"
        data.SDIVISIONID = divisionId
        // data.cMenuName = JSON.stringify(editData.cMenuName)
        if(!valid && emailValid && phoneValid){
            dispatch(updateContractorWatcher(rowData.NID, data));
            setEmailValid(true)
            setPhoneValid(true)
            setStatus('list');
        }
    }

    const cancelIconFunction = () => {
        setEditData({});
        setEmailValid(true)
        setPhoneValid(true)
        setStatus('list');
    }

    const addIconFunc = () => {
        setAddDialogOpen(true)
        dispatch(getAllConUserWatcher())
    }

    const viewIconFunc = () => {
        setViewDialogOpen(true)
        let data = {
            nContractorID: rowData.NID
        }
        dispatch(getViewConUserWatcher(data))
        dispatch(getAllConUserWatcher())
    }

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >
                {status === 'list' ?
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.NID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cUserName || '—'}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.CCONTRACTORID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.CCONTRACTORNAME}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.CCONTRACTORDESC}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.CCONTRACTORADDRESS}
                            </Typography>
                        </TableCell>
                    </>
                    :
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.NID}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cUserName || '—'}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='CCONTRACTORID'
                                value={editData.CCONTRACTORID}
                                onChange={handleEditChange}
                                error={editData.CCONTRACTORID ? false : formErrors.CCONTRACTORID !== null ? true : false}
                                helperText={editData.CCONTRACTORID ? '' : formErrors.CCONTRACTORID !== null ? 'Contractor Id is required' : ''}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='CCONTRACTORNAME'
                                value={editData.CCONTRACTORNAME}
                                onChange={handleEditChange}
                                error={editData.CCONTRACTORNAME ? false : formErrors.CCONTRACTORNAME !== null ? true : false}
                                helperText={editData.CCONTRACTORNAME ? '' : formErrors.CCONTRACTORNAME !== null ? 'Contractor Name is required' : ''}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='CCONTRACTORDESC'
                                value={editData.CCONTRACTORDESC}
                                onChange={handleEditChange}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <EditTextField
                                name='CCONTRACTORADDRESS'
                                value={editData.CCONTRACTORADDRESS}
                                onChange={handleEditChange}
                                error={editData.CCONTRACTORADDRESS ? false : formErrors.CCONTRACTORADDRESS !== null ? true : false}
                                helperText={editData.CCONTRACTORADDRESS ? '' : formErrors.CCONTRACTORADDRESS !== null ? 'Contractor Address is required' : ''}
                            />
                        </TableCell>
                    </>
                }
                <TableCell align="right" sx={{ minWidth: '252px' }}>
                    <ActionButtons status={status} setStatus={setStatus} expandOpen={expandOpen} setExpandOpen={setExpandOpen} type={'contractor'} tickIconFunction={tickIconFunction} handleDelete={handleDelete} handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} setDeleteDialogOpen={setDeleteDialogOpen} deleteDialogOpen={deleteDialogOpen} viewIconFunc={viewIconFunc} addIconFunc={addIconFunc} />
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell colSpan={12} style={{ padding: 0 }}>
                    <Collapse in={expandOpen} timeout="auto" unmountOnExit>
                        <Card sx={{ bgcolor: 'grey.200' }} >
                            <ContractorChildTable expandOpen={expandOpen} rowData={rowData} handleEditChange={handleEditChange} status={status} editData={editData} setEditData={setEditData} formErrors={formErrors} phoneValid={phoneValid} emailValid={emailValid} />
                        </Card>
                    </Collapse>
                </TableCell>
            </TableRow>
            <CreationDialog setOpen={setAddDialogOpen} open={addDialogOpen} maxWidth='sm'>
                <AddDesign setAddDialogOpen={setAddDialogOpen} rowDatas={rowData} setDuplicateDialogOpen={setDuplicateDialogOpen} setDupRfid={setDupRfid} />
            </CreationDialog>
            <CreationDialog setOpen={setViewDialogOpen} open={viewDialogOpen} maxWidth='md'>
                <ViewDesign setViewDialogOpen={setViewDialogOpen} />
            </CreationDialog>
            <CreationDialog setOpen={setDuplicateDialogOpen} open={duplicateDialogOpen} maxWidth='sm'>
                <DuplicationDesign setDuplicateDialogOpen={setDuplicateDialogOpen} rfid={dupRfid} />
            </CreationDialog>
        </>
    )

}

export default ContractorTableRow
import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
// import CommonHeader from '../../../components/foodVendor/commonHeader/header';
import EmpConTable from '../../../components/admin/reports/employee_contractor/emp_conTable';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeContractorWatcher } from '../../../redux/actionCreators/admin/emp_con';
import { TABLE_HEAD } from '../../../components/admin/reports/employee_contractor/emp_conTable';
import CRoleSwipeHistoryHeaderCard from '../../../components/contractor/reports/cRoleSwipeHistory/headerCard';
import { getConvertedDate } from '../../../utils/common';
import Context from '../../../context/context';

const Employee_contractor = () => {

    const { ReportsReducers: { EmployeeContractorList },UserHomeReducers: { cafeteriaId }, ProfileReducers: {loginState} } = useSelector((state) => state)

    const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''
    const roleName = loginState[0]?.cRoleName

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const curDate = new Date()
    const past = curDate.setDate(curDate.getDate() - 7)
    const [fromDate, setFromDate] = React.useState(new Date(past));
    const [toDate, setToDate] = React.useState(new Date());
    const [selectedMeal, setSelectedMeal] = useState('');
    const { state, setState } = useContext(Context);
    const [vendorState, setVendorState] = useState('')
    const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })

    // useEffect(() => {
    //     handleSubmitAction(getConvertedDate(fromDate), getConvertedDate(toDate))
    // }, [selectedMeal, state])
    
    useEffect(() => {
        setFromDate(new Date(past))
        setToDate(new Date())
        setSelectedMeal('')
    }, [cafeteriaId])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = EmployeeContractorList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        let currentDate = new Date()
        let curDate = getConvertedDate(currentDate)
        
        // if(toDate >= fromDate && toDate <= curDate && fromDate <= curDate){
            const data = {
                "ACTION": "Search",
                "FROMDATE": fromDate,   
                "TODATE": toDate,
                MealType: selectedMeal === '' ? 'Breakfast' : selectedMeal,
                "NDIVISIONID": cafeteriaId,
                // CVENDORID: vendorId === '' ? 'null' : String(vendorId)
                // CVENDORID: state !== "" ? `${state}` : 'null'
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorState !== "" ? `${vendorState}` : 'null')
            }
            dispatch(getEmployeeContractorWatcher(data))
            setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
            setPage(1)
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true })
        // }
    }

    const selectedMealHandler = (val) => {
        setSelectedMeal(val)
    }

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Employee/Contractor/Special Guest"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Employee/Contractor/Special Guest</Typography>
                </Box>
                <Box p='10px 0'>
                <CRoleSwipeHistoryHeaderCard selectedMealHandler={selectedMealHandler} handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={EmployeeContractorList} columnData={TABLE_HEAD} setFromDate={setFromDate} setToDate={setToDate} toDate={toDate} fromDate={fromDate} roleName={roleName} setVendorState={setVendorState} setSelectedMeal={setSelectedMeal} selectedMeal={selectedMeal} invalidErr={invalidErr} setInvalidErr={setInvalidErr}/>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <EmpConTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} vendorState={vendorState} />
                </Card>

            </Box>
        </>
    )
}

export default Employee_contractor;
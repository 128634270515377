import { call, put, takeEvery } from "redux-saga/effects";
import { RATING_LIST_WATCHER } from "../../actionTypes/user";
import { updateRatingList } from "../../actionCreators/user/rating";
import Service from "../../../services/user/rating"
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";

function* ratingListEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.get, action.id, action.payload);

    yield put(updateRatingList(data.recordset));
    yield put(updateFalseLoader());
    //yield put(updateAlert('Success', 'success'));
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}


//saga 
export const ratingListSaga = [
  takeEvery(RATING_LIST_WATCHER, ratingListEffect),
]
import React, { useEffect, useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import { getGRoleSwipeHistoryWatcher } from '../../../redux/actionCreators/guest';
import { useSelector, useDispatch } from 'react-redux';
import { TABLE_HEAD } from '../../../components/guest/reports/gRoleSwipeHistory/table';
import GRoleSwipeHistoryHeaderCard from '../../../components/guest/reports/gRoleSwipeHistory/headerCard';
import GRoleSwipeHistoryTable from '../../../components/guest/reports/gRoleSwipeHistory/table';
import { getConvertedDate } from '../../../utils/common';

const GRoleSwipeHistory = () => {

    const { GuestRoleReducers: { GRoleSwipeHistoryList }, ProfileReducers: { loginState } } = useSelector((state) => state)

    
    const nGuestUser = loginState[0]?.nGuestID || 0
    const nGuser = String(nGuestUser);

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [selectedMeal, setSelectedMeal] = useState('');
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const [page, setPage] = useState(1);
    const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })
    const vendorId = sessionStorage.getItem('vendorId')    

    useEffect(() => {
        setSelectedMeal('')
        setFromDate(null)
        setToDate(null)
    },[vendorId])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = GRoleSwipeHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        let currentDate = new Date()
        let curDate = getConvertedDate(currentDate)
        
        // if(toDate >= fromDate && toDate <= curDate && fromDate <= curDate){
            const data = {
                MealType: selectedMeal === "" ? "Breakfast" : selectedMeal,
                SwipeDate: fromDate !== null ? fromDate : currentDate,
                SwipeDateTo: toDate !== null ? toDate : currentDate,
                nGuestID: nGuser
            }
            dispatch(getGRoleSwipeHistoryWatcher(data))
            setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
            setPage(1)
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true })
        // }
    }

    const selectedMealHandler = (val) => {
        setSelectedMeal(val)
    }

    // const selectedMealHandler = useCallback((val) => {
    //     setSelectedMeal(val)
    // }, [selectedMeal])

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Guest Swipe History"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Guest Swipe History</Typography>
                </Box>
                <Box p='10px 0'>
                    <GRoleSwipeHistoryHeaderCard selectedMealHandler={selectedMealHandler} handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={GRoleSwipeHistoryList} columnData={TABLE_HEAD} setFromDate={setFromDate} setToDate={setToDate} toDate={toDate} fromDate={fromDate} setSelectedMeal={setSelectedMeal} selectedMeal={selectedMeal} invalidErr={invalidErr} setInvalidErr={setInvalidErr}/>
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <GRoleSwipeHistoryTable page={page} setPage={setPage} selectedMeal={selectedMeal} searchVal={searchVal} searchText={searchText} toDate={toDate} fromDate={fromDate} />
                </Card>

            </Box>
        </>
    )
}

export default GRoleSwipeHistory;
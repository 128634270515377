import http from "../../http-common";

class GuestBookService {
    create(data) {
        return http.post("/admin/addGuestBook", data);
    }

    cancel(id, data) {
        return http.post(`/admin/cancelOrder/${id}`, data);
    }

    getGuestNameList(data) {
        return http.post("/admin/guestDropDown", data);
    }

    getMealQuantity(data) {
        return http.post("/admin/getGuestMealQuantity", data);
    }

    getGuestCount(data) {
        return http.post("/admin/guestCount", data);
    }

    getMealQuantityGuest(data) {
        return http.post("/contractor/getGuestMealQuantity", data);
    }

    selectAllMeal(data) {
        return http.post("/admin/selectAllMeal", data);
    }
}

export default new GuestBookService();
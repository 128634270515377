import http from "../../http-common";

class DashboardService {

    get(data) {
        return http.post(`/foodVendor/dashboard`, data);
    }
    
}

export default new DashboardService();
import React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { TextField, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButton } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export default function QuantityPopover({ openPopup, setOpenPopup, nID, quantityApiChange, setNewQty, newQty, handleQuantityChange, qtyError, setQtyError }) {

    const handleClose = () => {
        setOpenPopup(null);
        setNewQty('')
        setQtyError('')
    };

    const open = Boolean(openPopup);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Box>
            <Popover
                id={id}
                open={openPopup}
                anchorEl={openPopup}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: -50,
                }}
            >
                <Box >
                    <Box display='flex' >
                        <Typography sx={{ p: 2 }}>Add Quantity</Typography>
                        <Box sx={{ ml: 'auto' }}>
                            <IconButton onClick={handleClose} sx={{}} >
                                <ClearOutlinedIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <TextField
                        size='small'
                        sx={{ width: '150px', p: '0 0 12px 12px' }}
                        placeholder='Enter Quantity'
                        value={newQty}
                        onChange={handleQuantityChange}
                        onBlur={handleQuantityChange}
                        error={qtyError ? true : false}
                        helperText={qtyError ? qtyError : ''}
                    />
                    <IconButton sx={{ ml: 1.5, mr: 1.5 }} onClick={() => quantityApiChange(nID)}>
                        <CheckCircleIcon sx={{ color: 'common.sgreen' }} />
                    </IconButton>
                </Box>
            </Popover>
        </Box>
    );
}

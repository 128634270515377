import React, { useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow,
    Collapse,
    Card,
} from '@mui/material';
import moment from 'moment';



const SnacksReportsTableRow = ({ rowData, handleRequestSort, order, orderBy }) => {

 // console.log("orderTrackingViewList",orderTrackingViewList);

    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
            >

                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.cUserName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.cEmployeeName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.cDivisionName}
                    </Typography>
                </TableCell>

                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.SwipeDate}
                    </Typography>
                </TableCell>
        </TableRow>
     </>
    )
}

export default SnacksReportsTableRow
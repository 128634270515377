import React, { useState, useEffect } from 'react'
import { Typography, Card, Grid, Button, Autocomplete, InputAdornment, Stack, Box } from "@mui/material";
import EmployeeManagementTable from '../../../components/admin/userManagement/employee';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CreationDialog from '../../../components/dialog';
import EmployeeCreation from '../../../components/admin/userManagement/employee/employeeCreation';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BulkAddDesign from '../../../components/admin/userManagement/employee/bulkAdd/bulkAddDesign';
import DuplicationDesign from '../../../components/admin/userManagement/contractor/addIcon/duplicationDesign';
import { useSelector, useDispatch } from 'react-redux'
import { getEmployeeWatcher, getDropDownWatcher } from '../../../redux/actionCreators/admin/employeeManagement';
import CssTextField from '../../../components/overrides/coloredTextField';
import SearchField from '../../../components/overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import { ExportCsv } from "@material-table/exporters";

const EmployeeManagementTemp = () => {
    const dispatch = useDispatch();
    const { EmployeeReducers: { roleNameList, employeeList }, UserHomeReducers: { cafeteriaId }, } = useSelector((state) => state)

    const [open, setOpen] = useState(false)
    const [addDialogOpen, setAddDialogOpen] = useState(false)
    const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false)
    const [dupRfid, setDupRfid] = useState([])
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [role, setRole] = useState(0)
    const [eventVal, setEventVal] = useState('')

    // const filterByRole = (id) => {
    //     let data = {
    //         ACTION: 'userRoleSelect',
    //         NROLEID: id,
    //         NDIVISIONID: cafeteriaId
    //     }
    //     dispatch(getEmployeeWatcher(data))
    // }
    useEffect(() => {
        if(cafeteriaId){
            let data = {
                ACTION: 'userRoleSelect',
                NDIVISIONID: cafeteriaId,
                NROLEID: role,
            }
            dispatch(getEmployeeWatcher(data))
        }
    }, [dispatch, cafeteriaId, role])

    useEffect(()=>{
        dispatch(getDropDownWatcher())
    },[dispatch])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = employeeList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });
        setSearchVal(filteredRows);
        setPage(1)
    }

    useEffect(()=>{
        handleSearch(eventVal);
    },[employeeList])

    const defaultVal = { name: 'Select Role', id: 0 }

    const table_head = [
        { field: 'cEmployeeID', title: 'UserID' },
        { field: 'cEmployeeName', title: 'User Name' },
        { field: 'cEmailID', title: 'EmailID' },
        { field: 'cMobileNumber', title: 'Mobile Number' },
        { field: 'cRoleName', title: 'Role' },
        { field: 'cUserName', title: 'UserName' },
        { field: 'apNames', title: 'Access Point' },
        { field: 'cDivisionName', title: 'Division' },
        { field: 'cRFID', title: 'RFID' },
        { field: 'cPassword', title: 'Password' }
    ]

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}` }}>
                <Grid container spacing={3} sx={{ padding: '30px', pb: '20px', display: 'flex' }} >
                    <Grid item lg={6} xs={12} md={4} sm={8} sx={{ justifyContent: 'right' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px'
                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            onChange={handleSearch}
                            value={searchText}
                        />
                    </Grid>
                    <Grid item lg={2} xs={12} md={3} sm={4}>
                        <Button variant='contained' fullWidth sx={{ height: '50px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(table_head, employeeList, "Exported file")}>
                            <Typography variant='t16_500'>Export to Excel</Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12} lg={2} md={3} sm={8} sx={{ width: '100%', display: 'flex' }}>
                                <Autocomplete
                                    // sx={{ minWidth: '200px' }}
                                    disablePortal
                                    id="combo-box-demo"
                                    name="selectMeal"
                                    fullWidth
                                    options={roleNameList}
                                    defaultValue={defaultVal}
                                    size="medium"
                                    autoHighlight
                                    onChange={(event, value) => {
                                        setRole(value.id)
                                        // filterByRole(value.id);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <CssTextField sx={{
                                        '& .MuiOutlinedInput-input': {
                                            height: '18px',
                                            color: 'common.white'
                                        },
                                    }} {...params} />}
                                />
                            {/* <Button variant='contained' endIcon={<PersonAddIcon />} sx={{ height: '50px', minWidth: '210px' }} onClick={() => setAddDialogOpen(true)}>
                                <Typography variant='t16_500' >
                                    Add bulk Employee
                                </Typography>
                            </Button> */}
                    </Grid>
                    <Grid item lg={2} xs={12} md={2} sm={4}>
                    <Button variant='contained' fullWidth endIcon={<DoubleArrowIcon />} sx={{ height: '50px' }} onClick={() => setOpen(true)}>
                                <Typography variant='t16_500' >
                                    Add User
                                </Typography>
                            </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <EmployeeManagementTable role={role} page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                    </Grid>
                </Grid>
            </Card>
            <CreationDialog open={open} setOpen={setOpen} type={'employee'} >
                <EmployeeCreation role={role} divisionId={cafeteriaId} setOpen={setOpen} />
            </CreationDialog>
            <CreationDialog setOpen={setAddDialogOpen} open={addDialogOpen} maxWidth='sm'>
                <BulkAddDesign setAddDialogOpen={setAddDialogOpen} setDupRfid={setDupRfid} setDuplicateDialogOpen={setDuplicateDialogOpen} />
            </CreationDialog>
            <CreationDialog setOpen={setDuplicateDialogOpen} open={duplicateDialogOpen} maxWidth='sm'>
                <DuplicationDesign setDuplicateDialogOpen={setDuplicateDialogOpen} rfid={dupRfid} />
            </CreationDialog>
        </>
    )
}

export default EmployeeManagementTemp;
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import CssTextField from "../../components/overrides/longSearchField";
import SlideBasicArrow from "../../components/SlideBasicArrow";
// import { slideBasicArrow } from "../../utils/constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialogcontent from "./dialogcontent";
import { getUserAboutDetailsWatcher, loginWatcher } from "../../redux/actionCreators/profile";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GetRoutes } from "../../utils/constants";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getCarouselWatcher } from "../../redux/actionCreators/admin/carousel";
import Scrollbar from "../../components/Scrollbar";


const Login = () => {
  const [open, setOpen] = useState(false);
  const [remember, setChecked] = useState(false);
  const [eye, setEye] = useState(false);
  const tempinitsform = useRef();
  const tempUserAboutDetails = useRef()

  const { ProfileReducers: { loginState }, CarouselReducers: { CarouselImageList } } = useSelector(state => state)

  const dispatch = useDispatch();
  const navigate = useNavigate()


  const Schema = Yup.object().shape({
    cUserName: Yup.string("Enter your user name")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("User name is required"),
    cPassword: Yup.string("Enter your password")
      .min(6, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    // emailId: Yup.string('Enter your EmailId').email('Email must be a valid email address').required('Email is required'),
  });


  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      cUserName: "",
      cPassword: "",
    },

    validationSchema: Schema,

    onSubmit: () => {
      let { cUserName, cPassword } = { ...formik.values };
      let data = {
        ACTION: "Login",
        cUserName,
        cPassword,
      };

      dispatch(loginWatcher(data, goTo, remember));
      
      // if(loginState){
      //   const divisionID = loginState[0].nDivisionID
      //   let userData = {
      //     "nDivisionID": `${divisionID}`
      //   }
      //   dispatch(getUserAboutDetailsWatcher(userData))
      // }
    },
  });

  const goTo = (path) => {
    // console.log('pathaaaaaa', path);
    navigate(path)
  }

  const initsform = () => {
    if (loginState.length) {
      const path = GetRoutes(loginState[0].cRoleName)
      // console.log('ddffff', path);
      navigate(path)
    }
  };

  tempinitsform.current = initsform;

  useEffect(() => {
    tempinitsform.current();
  }, []);

  // useEffect(() => {
  //   if (loginState.length) {
  //     const path = GetRoutes(loginState[0].cRoleName)
  //     navigate(path)
  //   }
  // }, [])

  // const initsUserAboutDetails = () => {
  //   if(loginState.length){
  //     const divisionID = loginState[0].nDivisionID
  //     let userData = {
  //       "nDivisionID": `${divisionID}`
  //     }
  //     dispatch(getUserAboutDetailsWatcher(userData))
  //   }
  // };

  // tempUserAboutDetails.current = initsUserAboutDetails;

  // useEffect(() => {
  //   tempUserAboutDetails.current();
  // }, [loginState]);

  useEffect(() => {
    let data = {
      "ACTION": "Select"
    }
    dispatch(getCarouselWatcher(data))
  }, [dispatch])

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Dialogcontent open={open} setOpen={setOpen} />
      <Box
        bgcolor="common.searchBG"
        borderRadius="6px"
        width="100%"
        height="100%"
        display="flex"
        flexDirection='column'
      >
        <Box p={{ xs: 0, md: "0 80px" }} m="auto" width='100%' >

          <Card
            sx={{
              bgcolor: "common.white",
              p: { sm: "42px", xs: '20px' },
              width: '100%'
            }}
          >
            <Scrollbar>
              <Grid container spacing={{ sm: 7, xs: 4 }}>
                <Grid item xs={12} md={6} lg={6}>
                  <Box display="flex" alignItems="center" height="100%" width='100%'>
                    <Box width='100%' >
                      <SlideBasicArrow slideBasicArrow={CarouselImageList} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <form onSubmit={handleSubmit}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      width
                      alignItems="center"
                    >

                      <Box
                        component="img"
                        src='3DCafe_Logo 1.svg'
                        sx={{
                          height: 200,
                          width: 200,
                          // borderRadius: "12px",
                          objectFit: "cover",
                          // mr: "10px",
                        }}
                      ></Box>
                    </Box>

                    <Box mt="34px">
                      <Typography variant="t14_600">User Name</Typography>
                      <CssTextField
                        sx={{
                          mt: "7px",
                          "& .MuiOutlinedInput-input": {
                            height: '12px',
                          },
                        }}
                        fullWidth
                        autoComplete="username"
                        {...getFieldProps("cUserName")}
                        error={Boolean(touched.cUserName && errors.cUserName)}
                        helperText={touched.cUserName && errors.cUserName}
                      />
                    </Box>

                    <Box mt="27px">
                      <Typography variant="t14_600">Password</Typography>
                      <CssTextField
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={() => setEye(!eye)}>
                              {!eye ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          mt: "7px",
                          "& .MuiOutlinedInput-input": {
                            height: '12px',
                          },
                        }}
                        {...getFieldProps("cPassword")}
                        error={Boolean(touched.cPassword && errors.cPassword)}
                        helperText={touched.cPassword && errors.cPassword}
                        fullWidth
                        autoComplete="current-password"
                        type={eye ? 'text' : 'password'}
                      />
                    </Box>

                    <Box mt="10px" display="flex" alignItems="center">
                      <FormControlLabel
                        control={<Checkbox checked={remember}
                          onChange={handleChange} />}
                        label={
                          <Typography ml="auto" color="grey.600" variant="t14_400">
                            Remember Me
                          </Typography>
                        }
                      />

                      <Typography
                        sx={{ cursor: "pointer" }}
                        onClick={() => setOpen(true)}
                        color="common.weekTab"
                        ml="auto"
                        variant="t14_400"
                      >
                        Forgot Password?
                      </Typography>
                    </Box>

                    <Box mt="32px" textAlign="center">
                      <Button
                        variant="contained"
                        sx={{ minWidth: 223, height: 48 }}
                        type='submit'
                      >
                        <Typography variant="t18_600">Sign in</Typography>
                      </Button>
                    </Box>
                  </form>
                </Grid>
              </Grid>
            </Scrollbar>
          </Card>
        </Box>

        {/* <Box p='10px' bgcolor='common.sideBarBg' borderTop={theme => `4px solid ${theme.palette.primary.main}`} >
          <Stack ml={{ md: '70px', xs: 0 }} direction='row' spacing={5} >
            <Typography variant="t14_400" color='common.tabclr' >© {new Date().getFullYear()}, A Unit of Anjali Hotels Pvt Ltd</Typography>

            <Typography sx={{ textDecoration: 'unset', '&: active': { color: 'inherit' } }} variant="t14_400" component={Link} to='/user/terms&condition' color='common.tabclr' >Terms & Conditions</Typography>

            <Typography sx={{ textDecoration: 'unset', '&: active': { color: 'inherit' } }} variant="t14_400" component={Link} to='/user/contactUs' color='common.tabclr' >Contact Us</Typography>

          </Stack>

        </Box> */}
      </Box>
    </>
  );
};

export default Login;

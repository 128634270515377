import React, { useContext, useEffect, useRef } from 'react'
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography } from "@mui/material";
import DayByDayHeader from '../../../components/admin/reports/dayByDayOrder/headerCard';
import TableIndex from '../../../components/admin/reports/dayByDayOrder/Index';
import { getBreakfastDayByDayOrderWatcher, getLunchDayByDayOrderWatcher, getDinnerByDayOrderWatcher, getSnacksByDayOrderWatcher, getSupperByDayOrderWatcher, getVipGuestSupperByDayOrderWatcher, getVipGuestDinnerByDayOrderWatcher, getVipGuestSnacksByDayOrderWatcher, getVipGuestLunchDayByDayOrderWatcher, getVipGuestBreakfastDayByDayOrderWatcher, getNormalGuestSupperByDayOrderWatcher, getNormalGuestDinnerByDayOrderWatcher, getNormalGuestSnacksByDayOrderWatcher, getNormalGuestLunchDayByDayOrderWatcher, getNormalGuestBreakfastDayByDayOrderWatcher } from '../../../redux/actionCreators/admin/reports';
import { useDispatch, useSelector } from 'react-redux'
import { getCurDate } from "../../../utils/common";
import Context from '../../../context/context';

export default function DayByDayOrder() {

    const { ReportsReducers: { DinnerList, BreakfastList, SupperList, LunchList, SnacksList, SupperVipGuestList, SnacksVipGuestList, DinnerVipGuestList, LunchVipGuestList, BreakfastVipGuestList, SupperNormalGuestList, SnacksNormalGuestList, DinnerNormalGuestList, LunchNormalGuestList, BreakfastNormalGuestList },UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)
    const dispatch = useDispatch();
    const getDate = getCurDate();
    const tempinitsform = useRef();
    const { setState } = useContext(Context)

//     const initsform = () => {
//         let Bdata = {
//             "Action": "GetDayByDay",
//             "Mela": "Breakfast",
//             "Date": getDate,
//             "NDIVISIONID": cafeteriaId
//         }
//         dispatch(getBreakfastDayByDayOrderWatcher(Bdata))

//         let Ldata = {
//             "Action": "GetDayByDay",
//             "Mela": "Lunch",
//             "Date": getDate,
//             "NDIVISIONID": cafeteriaId
//         }
//         dispatch(getLunchDayByDayOrderWatcher(Ldata))

//         let Sdata = {
//             "Action": "GetDayByDay",
//             "Mela": "Snacks",
//             "Date": getDate,
//             "NDIVISIONID": cafeteriaId
//         }
//         dispatch(getSnacksByDayOrderWatcher(Sdata))

//         let Ddata = {
//             "Action": "GetDayByDay",
//             "Mela": "Dinner",
//             "Date": getDate,
//             "NDIVISIONID": cafeteriaId
//         }
//         dispatch(getDinnerByDayOrderWatcher(Ddata))

//         let SRdata = {
//             "Action": "GetDayByDay",
//             "Mela": "Early morning snacks",
//             "Date": getDate,
//             "NDIVISIONID": cafeteriaId
//         }
//         dispatch(getSupperByDayOrderWatcher(SRdata))
//         setState('')

// // // normal guest

// //         let BNdata = {
// //             "Action": "GetDayByDay",
// //             "Mela": "Breakfast(NormalGuest)",
// //             "Date": getDate,
// //             "NDIVISIONID": cafeteriaId
// //         }
// //         dispatch(getNormalGuestBreakfastDayByDayOrderWatcher(BNdata))

// //         let LNdata = {
// //             "Action": "GetDayByDay",
// //             "Mela": "Lunch(NormalGuest)",
// //             "Date": getDate,
// //             "NDIVISIONID": cafeteriaId
// //         }
// //         dispatch(getNormalGuestLunchDayByDayOrderWatcher(LNdata))

// //         let SNdata = {
// //             "Action": "GetDayByDay",
// //             "Mela": "Snacks(NormalGuest)",
// //             "Date": getDate,
// //             "NDIVISIONID": cafeteriaId
// //         }
// //         dispatch(getNormalGuestSnacksByDayOrderWatcher(SNdata))

// //         let DNdata = {
// //             "Action": "GetDayByDay",
// //             "Mela": "Dinner(NormalGuest)",
// //             "Date": getDate,
// //             "NDIVISIONID": cafeteriaId
// //         }
// //         dispatch(getNormalGuestDinnerByDayOrderWatcher(DNdata))

// //         let SRNdata = {
// //             "Action": "GetDayByDay",
// //             "Mela": "Early morning snacks(NormalGuest)",
// //             "Date": getDate,
// //             "NDIVISIONID": cafeteriaId
// //         }
// //         dispatch(getNormalGuestSupperByDayOrderWatcher(SRNdata))

// // // vip guest

// //         let BVdata = {
// //             "Action": "GetDayByDay",
// //             "Mela": "Breakfast(VIPGuest)",
// //             "Date": getDate,
// //             "NDIVISIONID": cafeteriaId
// //         }
// //         dispatch(getVipGuestBreakfastDayByDayOrderWatcher(BVdata))

// //         let LVdata = {
// //             "Action": "GetDayByDay",
// //             "Mela": "Lunch(VIPGuest)",
// //             "Date": getDate,
// //             "NDIVISIONID": cafeteriaId
// //         }
// //         dispatch(getVipGuestLunchDayByDayOrderWatcher(LVdata))

// //         let SVdata = {
// //             "Action": "GetDayByDay",
// //             "Mela": "Snacks(VIPGuest)",
// //             "Date": getDate,
// //             "NDIVISIONID": cafeteriaId
// //         }
// //         dispatch(getVipGuestSnacksByDayOrderWatcher(SVdata))

// //         let DVdata = {
// //             "Action": "GetDayByDay",
// //             "Mela": "Dinner(VIPGuest)",
// //             "Date": getDate,
// //             "NDIVISIONID": cafeteriaId
// //         }
// //         dispatch(getVipGuestDinnerByDayOrderWatcher(DVdata))

// //         let SRVdata = {
// //             "Action": "GetDayByDay",
// //             "Mela": "Early morning snacks(VIPGuest)",
// //             "Date": getDate,
// //             "NDIVISIONID": cafeteriaId
// //         }
// //         dispatch(getVipGuestSupperByDayOrderWatcher(SRVdata))
//     };
    
//     tempinitsform.current = initsform;
    
//     useEffect(() => {
//        tempinitsform.current();
//     }, []);

    // useEffect(() => {
    //     let Bdata = {
    //         "Action": "GetDayByDay",
    //         "Mela": "Breakfast",
    //         "Date": getDate
    //     }
    //     dispatch(getBreakfastDayByDayOrderWatcher(Bdata))

    //     let Ldata = {
    //         "Action": "GetDayByDay",
    //         "Mela": "Lunch",
    //         "Date": getDate
    //     }
    //     dispatch(getLunchDayByDayOrderWatcher(Ldata))

    //     let Sdata = {
    //         "Action": "GetDayByDay",
    //         "Mela": "Snacks",
    //         "Date": getDate
    //     }
    //     dispatch(getSnacksByDayOrderWatcher(Sdata))

    //     let Ddata = {
    //         "Action": "GetDayByDay",
    //         "Mela": "Dinner",
    //         "Date": getDate
    //     }
    //     dispatch(getDinnerByDayOrderWatcher(Ddata))

    //     let SRdata = {
    //         "Action": "GetDayByDay",
    //         "Mela": "Supper",
    //         "Date": getDate
    //     }
    //     dispatch(getSupperByDayOrderWatcher(SRdata))
    // }, [])

    const mergeResult = [BreakfastList, LunchList, SnacksList, DinnerList, SupperList];

    const splGuestMergeResult = [BreakfastNormalGuestList, LunchNormalGuestList, SnacksNormalGuestList, DinnerNormalGuestList, SupperNormalGuestList, BreakfastVipGuestList, LunchVipGuestList, SnacksVipGuestList, DinnerVipGuestList, SupperVipGuestList ];

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Day By Day Order"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Day By Day Order</Typography>
                </Box>

                <Box p='10px 0'>
                    <DayByDayHeader />
                </Box>

                {
                    mergeResult.map((m, i) => {
                        return <Box p='10px 0' key={i}>
                            <TableIndex cardData={m} />
                        </Box>
                    })
                }

                {(cafeteriaId.includes("4")) &&
                    (splGuestMergeResult.map((m, i) => {
                        return <Box p='10px 0' key={i}>
                            <TableIndex cardData={m} />
                        </Box>
                    }))
                }  
            </Box>
        </>
    )
}

import React from 'react';
import { useState, useEffect } from 'react';
import Scrollbar from '../../Scrollbar';
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Pagination,
    Box
} from '@mui/material';
import CommonTableHead from '../../foodVendor/reports/commonTableHead.js.js';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingHistoryWatcher } from '../../../redux/actionCreators/contractor/index';
// import { getConvertedDate } from '../../../utils/common';
import CTableRow from './tableRow';

export const TABLE_HEAD = [
    { id: 'userId ', label: 'User ID', alignRight: false, title: 'User Id', field: 'cUserName' },
    { id: 'userName', label: 'User Name', alignRight: false, title: 'User Name', field: 'cEmployeeName' },
    { id: 'orderDate', label: 'Order Date', alignRight: false, title: 'Order Date', field: 'CreatedDate' },
    { id: 'breakfast', label: 'Breakfast', alignRight: false, title: 'Breakfast', field: 'Breakfast' },
    { id: 'lunch', label: 'Lunch', alignRight: false, title: 'Lunch', field: 'Lunch' },
    { id: 'snacks', label: 'Snacks', alignRight: false, title: 'Snacks', field: 'Snacks' },
    { id: 'dinner', label: 'Dinner', alignRight: false, title: 'Dinner', field: 'Dinner' },
    { id: 'Early morning snacks', label: 'Early morning snacks', alignRight: false, title: 'Early morning snacks', field: 'Early morning snacks' },
    { id: 'vendorName', label: 'Vendor Name', alignRight: false, title: 'Vendor Name', field: 'vendorName' }
    // { id: 'Snacks(Take away)', label: 'Snacks(Take away)', alignRight: false, title: 'Snacks(Take away)', field: 'Snacks(Take away)' },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const ContractorBookingTable = ({ searchText, searchVal, page, setPage }) => {

    const { ContractorRoleReducers: { bookingHistoryList }, ProfileReducers: { loginState } } = useSelector((state) => state)
    const nUserID = loginState[0]?.nID || 0

    const vendorId = sessionStorage.getItem('vendorId')

    let firstIndex = 0;

    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage] = useState(5);

    useEffect(() => {
        const data = {
            "ACTION": "ContractorList",
            "NID": `${nUserID}`,
            CVENDORID: vendorId !== "" ? `${vendorId}` : 'null'
            // "FromDate": getConvertedDate(new Date()),
            // "ToDate": getConvertedDate(new Date()),
        }
        dispatch(getBookingHistoryWatcher(data))
    }, [dispatch, nUserID, vendorId])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const tableData = searchText ? searchVal : bookingHistoryList
    const filteredData = applySortFilter(tableData, getComparator(order, orderBy));
    const isUserNotFound = filteredData?.length === 0;

    return (
        <>
            <Box>
                <Scrollbar>
                    <TableContainer sx={{overflow:'unset'}} >
                        <Table>
                            <CommonTableHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredData?.slice(firstIndex + rowsPerPage * (page - 1), rowsPerPage * page)?.map((r, i) => {
                                        return (
                                            <CTableRow key={r.ID} rowData={r} index={i} />
                                        )
                                    })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow display='flex'>
                                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                                            <Typography variant='t18_500' >
                                                No records found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Box>
            <Box spacing={2} display='flex' justifyContent='right' paddingTop='20px' >
                <Pagination
                    shape="rounded"
                    color='primary'
                    count={Math.ceil(tableData?.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </>
    )
}

export default ContractorBookingTable;
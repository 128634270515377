import PropTypes from 'prop-types';
// material
// import { visuallyHidden } from '@mui/utils';
import { Box, TableRow, TableCell, TableHead, Typography } from '@mui/material';
// import SwapVertIcon from '@mui/icons-material/SwapVert';

// ----------------------------------------------------------------------

TableHeader.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    rowCount: PropTypes.number,
    headLabel: PropTypes.array,
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func,
    onSelectAllClick: PropTypes.func
};

export default function TableHeader({ headLabel }) {

    return (
        <TableHead>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                {headLabel.map((headCell) => {
                    return <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                    >                        
                        <Box sx={{width:'fit-content' , display:'flex'}}>
                        <Typography variant='t11_600' >
                            {headCell.label}
                        </Typography>
                        </Box>
                    </TableCell>
                })}
            </TableRow>
        </TableHead>
    );
}

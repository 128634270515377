import React from "react";
import { Typography, Card, Grid, Button, Box } from "@mui/material";
import CssTextField from "../../components/overrides/longSearchField";
// import BlackButton from "../../components/overrides/blackButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { passwordUpdateWatcher } from "../../redux/actionCreators/profile";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {decryptAes} from '../../utils/common'
import CloseButton from "../../components/overrides/closeButton";

const ChangePassword = () => {
  const dispatch = useDispatch();

  const {
    ProfileReducers: { loginState },
  } = useSelector((state) => state);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const nUserID = loginState[0]?.nID || 0
  const cPassword = loginState[0]?.cPassword || 0

  const Schema = Yup.object().shape({
    cPassword: Yup.string().required("This field is required").test('oldPass', 'Password is wrong', 
    (value) => value === decryptAes(cPassword)
    ),
    newPassword: Yup.string().min(8, 'Password must be at least 8 characters').required("This field is required"),
    RetypePASSWORD: Yup.string().min(8, 'Password must be at least 8 characters').oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must match"
    ).required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      RetypePASSWORD: "",
      cPassword: "",
    },
    validationSchema: Schema,

    onSubmit: () => {
      let { cPassword, RetypePASSWORD } = { ...formik.values };
      let data = {
        ACTION: "UserProfilePaswordUpdate",
        cPassword,
        RetypePASSWORD,
      };

      dispatch(passwordUpdateWatcher(nUserID, data, handleBack));
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } =
    formik;

  // useEffect(() => {
  //   if (cPassword) {
  //     setFieldValue("cPassword", cPassword);
  //   } else {
  //     handleBack();
  //   }
  // }, [cPassword]);

  const handleBack = () => {
    const getPath = pathname.split("/")[1];
    navigate(`/${getPath}/profile`);
  };

  return (
    <>
      <Card
        style={{
          boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`,
        }}
      >
        <Grid container spacing={3} sx={{ padding: "30px" }}>
          <Grid item lg={6} xs={6} sx={{ justifyContent: "left" }}>
            <div>
              <Typography variant="t20_600">Change Password</Typography>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          display="flex"
          flexDirection="row"
          justifyContent="left"
          sx={{ padding: "30px" }}
        >
          <Grid item lg={4} xs={12} md={4}>
            <Typography variant="t14_600"> Old Password </Typography>
            <CssTextField
              sx={{
                mt: '7px'
              }}
              placeholder="Old Password"
              name="oldPassword"
              fullWidth
              {...getFieldProps("cPassword")}
              error={Boolean(touched.cPassword && errors.cPassword)}
              helperText={touched.cPassword && errors.cPassword}
            />
          </Grid>
          <Grid item lg={4} xs={12} md={4}>
            <Typography variant="t14_600"> New Password </Typography>
            <CssTextField
              sx={{
                mt: '7px'
              }}
              placeholder="New Password"
              name="newPassword"
              fullWidth
              {...getFieldProps("newPassword")}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
            />
          </Grid>
          <Grid item lg={4} xs={12} md={4}>
            <Typography variant="t14_600"> Retype Password </Typography>
            <CssTextField
              sx={{
                mt: '7px'
              }}
              placeholder="Retype Password"
              name="retype"
              fullWidth
              {...getFieldProps("RetypePASSWORD")}
              error={Boolean(touched.RetypePASSWORD && errors.RetypePASSWORD)}
              helperText={touched.RetypePASSWORD && errors.RetypePASSWORD}
            />
          </Grid>
        </Grid>

            <Box display="flex" p='0 30px 30px 0'>
              <Box ml="auto">
                <CloseButton
                  sx={{
                    width: "120px",
                    height: "42px",
                    mr: "20px",
                  }}
                  variant="contained"
                  color="inherit"
                  onClick={handleBack}
                >
                  <Typography variant="t14_400" color="common.black">
                    Cancel
                  </Typography>
                </CloseButton>
                <Button
                  sx={{
                    width: "120px",
                    height: "42px",
                    left: "0px",
                    top: "0px",
                    backgroundColor: 'common.Bblack',
                    '&:hover':{
                      backgroundColor: 'common.Bblack',
                      }
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  <Typography variant="t14_400" color="inherit">
                    Update
                  </Typography>
                </Button>
              </Box>
            </Box>

      </Card>
    </>
  );
};

export default ChangePassword;

import { GET_KIOSK_DASHBOARD_WATCHER, UPDATE_BOOKING_DETAILS_STATUS_WATCHER, GET_KIOSK_MENU_LIST_WATCHER, UPDATE_MENU_LIST_STATUS_WATCHER, GET_NOTIFICATION_DETAILS_WATCHER, GET_KIOSK_DASHBOARD_LIST, GET_KIOSK_MENU_LIST, GET_NOTIFICATION_DETAILS_LIST, GET_NOTIFICATION_COUNT_WATCHER, GET_NOTIFICATION_COUNT_LIST, UPDATE_NOTIFICATION_DETAILS_WATCHER } from "../../actionTypes/kiosk";


// Worker triggering actionCreators

export function getKioskBookingDetailsWatcher(data, noLoader) {
    return { type: GET_KIOSK_DASHBOARD_WATCHER, payload: data, noLoader };
}

export function updateBookingDetailsStatusWatcher(id, data) {
    return { type: UPDATE_BOOKING_DETAILS_STATUS_WATCHER, payload: data, id };
}

export function getKioskMenuListWatcher(data) {
    return { type: GET_KIOSK_MENU_LIST_WATCHER, payload: data };
}

export function updateMenuListStatusWatcher(id, data) {
    return { type: UPDATE_MENU_LIST_STATUS_WATCHER, payload: data, id };
}

export function getNotificationDetailsWatcher(data) {
    return { type: GET_NOTIFICATION_DETAILS_WATCHER, payload: data };
}

export function getNotificationCountWatcher(data) {
    return { type: GET_NOTIFICATION_COUNT_WATCHER, payload: data };
}

export function updateNotificationDetailsWatcher(data) {
    return { type: UPDATE_NOTIFICATION_DETAILS_WATCHER, payload: data };
}

// Redux state changing actionCreators

export function getKioskBookingDetailsList(data) {
    return { type: GET_KIOSK_DASHBOARD_LIST, payload: data };
}

export function getKioskMenuList(data) {
    return { type: GET_KIOSK_MENU_LIST, payload: data};
}

export function getNotificationDetailsList(data) {
    return { type: GET_NOTIFICATION_DETAILS_LIST, payload: data };
}

export function getNotificationCountList(data) {
    return { type: GET_NOTIFICATION_COUNT_LIST, payload: data };
}
import React, { useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card, Button } from "@mui/material";
import CommonHeader from '../../../components/foodVendor/commonHeader/header';
import OrderTrackingTable from '../../../components/admin/reports/empDeliveryHistory/orderTrackingTable';
import { getOrderTrackingWatcher } from '../../../redux/actionCreators/admin/reports';
import { useDispatch, useSelector } from 'react-redux';
import { TABLE_HEAD } from '../../../components/admin/reports/empDeliveryHistory/orderTrackingTable';
import SplOrderSubTable, { SUBTABLE_HEAD } from '../../../components/foodVendor/reports/specialOrderTracking/subTable'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { ExportCsv } from "@material-table/exporters";

const EmpDeliveryHistory = () => {

    const { ReportsReducers: { OrderTrackingList }, ProfileReducers: {loginState} } = useSelector((state) => state)

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [pageChange, setPageChange] = useState(false)
    const [page, setPage] = useState(1);

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = OrderTrackingList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }
    const nUserID = loginState[0]?.nID || 0

    const handleSubmitAction = (fromDate, toDate) => {
        const data = {
             nUserID,
            "cAction": "eTrackSearch",
            "Fdate": fromDate,
            "Tdate": toDate
        }
        dispatch(getOrderTrackingWatcher(data))
        setPage(1)
    }

    return (
        <>
            {!pageChange ?
                <Box p="24px">

                    <Breadcrumbs content={["Reports", "Delivery History"]} />

                    <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                        <Typography variant="t24_700"> Delivery History </Typography>
                    </Box>
                    <Box p='10px 0'>
                        <CommonHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={OrderTrackingList} columnData={TABLE_HEAD} defaultDate={new Date()} />
                    </Box>
                    <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                        <OrderTrackingTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                    </Card>

                </Box>

                :

                <>
                    <Box p="24px">
                        <Box p='20px 0' display='flex' justifyContent='right' >
                            <Button sx={{ mr: '15px', minWidth: '100px', }} variant='contained' onClick={() => setPageChange(false)} >
                                Back
                            </Button>
                            <Button variant='contained' sx={{ height: '50px', minWidth: '150px', mr: '15px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(SUBTABLE_HEAD, OrderTrackingList, "Special Order Tracking")}>
                                <Typography variant='t16_500'>Export to Excel</Typography>
                            </Button>

                        </Box>
                        <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                            <SplOrderSubTable />
                        </Card>
                    </Box>
                </>

            }
        </>
    )
}

export default EmpDeliveryHistory;
import http from "../../http-common";

class OrderSummaryServices {

    getLastWeek() {
        return http.get("/admin/orderSummary/lastweek");
    }
    getLastDay(data) {
        return http.post("/admin/orderSummary/lastday", data);
    }
    getTapLog(data) {
        return http.post("/user/currentOrder", data);
    }

}

export default new OrderSummaryServices();
import React from 'react';
import { Button, Typography, Grid, Stack, Autocomplete } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { createVendorWatcher } from '../../../../redux/actionCreators/admin/vendorManagement';
import { useDispatch, useSelector } from 'react-redux'
import CssTextField from '../../../overrides/longSearchField';
// import BlackButton from '../../../overrides/blackButton'
import CloseButton from '../../../overrides/closeButton';

function VendorCreation(props) {
    const dispatch = useDispatch()

    const { setOpen, status } = props;

    const { ProfileReducers: { loginState }, EmployeeReducers: { divisionList }, UserHomeReducers: { cafeteriaId } } = useSelector(state => state)
    const nUserID = loginState[0]?.nID || 0

    const VendorSchema = Yup.object().shape({
        divisionId: Yup.string().required('Division Id is required'),
        vendorId: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Vendor Id is required'),
        vendorName: Yup.string('Enter vendor name').min(2, 'Too Short!').max(50, 'Too Long!').required('Vendor Name is required'),
        vendorAddress: Yup.string('Enter vendor address').required('Vendor address is required'),
        mobileNumber: Yup.string().matches(/^(?!.*-.*-.*-)(?=(?:\d{8,10}$)|(?:(?=.{9,11}$)[^-]*-[^-]*$)|(?:(?=.{10,12}$)[^-]*-[^-]*-[^-]*$)  )[\d-]+$/, 'Mobile Number is Invalid').required('Mobile Number is required'),
        emailId: Yup.string('Enter your Email Id').email('Email must be a valid email address').required('Email Id is required'),
    });

    const formik = useFormik({
        initialValues: {
            vendorId: '',
            vendorName: '',
            vendorDesc: '',
            vendorAddress: '',
            landLineNumber: '',
            mobileNumber: '',
            emailId: '',
            divisionId: '',
        },

        validationSchema: VendorSchema,

        onSubmit: () => {
            const { vendorId, vendorName, vendorDesc, vendorAddress, landLineNumber, mobileNumber, emailId, divisionId } = formik.values
            let data = {
                "ACTION": "INSERT",
                "CVENDORID": vendorId,
                "CVENDORNAME": vendorName,
                "CVENDORADDRESS": vendorAddress,
                "CLANDLINENUMBER": landLineNumber,
                "CMOBILENUMBER": mobileNumber,
                "CEMAILID": emailId,
                "CVENDORDESC": vendorDesc,
                "NUSERID": nUserID,
                "NDIVISIONID": divisionId,
                "SDIVISIONID": cafeteriaId,

            }
            dispatch(createVendorWatcher(data, setOpen))
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    <Typography variant='h5'
                        align='left'
                        style={{ paddingBottom: '10px' }}>
                        {status === 'edit' ? 'Edit Vendor' : 'Vendor Creation'}
                    </Typography>

                    <Grid
                        container
                        style={{ paddingTop: '10px' }}
                        spacing={3}
                        direction='row'
                    >

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <Autocomplete
                                id="combo-box-demo"
                                name="divisionId"
                                fullWidth
                                options={divisionList}
                                size="medium"
                                autoHighlight
                                onChange={(event, value) => {
                                    setFieldValue("divisionId", value.id);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <CssTextField {...params}
                                    label="Division"
                                    name='divisionId'
                                    required={true}
                                    error={Boolean(touched.divisionId && errors.divisionId)}
                                    helperText={touched.divisionId && errors.divisionId}
                                />}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='vendor Id'
                                label='Vendor Id'
                                name='vendorId'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("vendorId")}
                                error={Boolean(touched.vendorId && errors.vendorId)}
                                helperText={touched.vendorId && errors.vendorId}
                            />
                        </Grid>
                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='vendor Name'
                                label='Vendor Name'
                                name='vendorName'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("vendorName")}
                                error={Boolean(touched.vendorName && errors.vendorName)}
                                helperText={touched.vendorName && errors.vendorName}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                // required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='vendor Description'
                                label='Vendor Description'
                                name='vendorDesc'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("vendorDesc")}
                                error={Boolean(touched.vendorDesc && errors.vendorDesc)}
                                helperText={touched.vendorDesc && errors.vendorDesc}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='vendor Address'
                                label='Vendor Address'
                                name='vendorAddress'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("vendorAddress")}
                                error={Boolean(touched.vendorAddress && errors.vendorAddress)}
                                helperText={touched.vendorAddress && errors.vendorAddress}
                            />
                        </Grid>
                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                // required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Land Line Number'
                                label='Land Line Number'
                                name='landLineNumber'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("landLineNumber")}
                            // error={Boolean(touched.landLineNumber && errors.landLineNumber)}
                            // helperText={touched.landLineNumber && errors.landLineNumber}
                            />
                        </Grid>
                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Mobile Number'
                                label='Mobile Number'
                                name='mobileNumber'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("mobileNumber")}
                                error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                                helperText={touched.mobileNumber && errors.mobileNumber}
                            />
                        </Grid>

                        <Grid
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <CssTextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Email Id'
                                label='Email Id'
                                name='emailId'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("emailId")}
                                error={Boolean(touched.emailId && errors.emailId)}
                                helperText={touched.emailId && errors.emailId}
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            item={true}
                        >
                            <Stack spacing={2} direction='row' justifyContent='end' >
                                <CloseButton onClick={() => handleClose()}
                                    sx={{
                                        //width: '120px',
                                        height: '42px',
                                        left: '0px',
                                        top: '0px',
                                        minWidth: '120px',
                                        marginTop: 'auto',
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='button'>
                                    <Typography variant='t14_400' color='common.black'>Cancel</Typography>
                                </CloseButton>
                                <Button onClick={handleSubmit}
                                    style={{
                                        //width: '120px',
                                        height: '42px',
                                        left: '0px',
                                        top: '0px',
                                        minWidth: '120px',
                                        backgroundColor: 'common.Bblack',
                                        marginTop: 'auto'
                                    }}
                                    variant='contained'
                                    size='medium'
                                    type='submit'
                                >
                                    <Typography variant='t14_400' color='grey.0'>Submit</Typography>
                                </Button>
                            </Stack>

                        </Grid>
                    </Grid>

                </Form>
            </FormikProvider>
        </>
    );

}

export default VendorCreation;
import React, { useState, useEffect } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import CommonHeader from '../../../components/admin/reports/commonHeader';
import GuestOrderHistoryTable from '../../../components/admin/reports/guestOrderHistory/table';
import { useDispatch, useSelector } from 'react-redux';
import { getGuestOrderHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import {TABLE_HEAD} from '../../../components/admin/reports/guestOrderHistory/table';

const GuestOrderHistory = () => {
    const { ReportsReducers: { GuestOrderHistoryList }, UserHomeReducers: { cafeteriaId } } = useSelector((state) => state)

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [eventVal, setEventVal] = useState('')

    useEffect(() => {
        if (cafeteriaId) {
            let data = { "ACTION": "AdminGuestList", NDIVISIONID: cafeteriaId }
            dispatch(getGuestOrderHistoryWatcher(data))
        }
    }, [dispatch, cafeteriaId])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e?.target?.value || ''
        setSearchText(val);
        setEventVal(e);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = GuestOrderHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate) => {
        const data = {
            ACTION: "AdminSearchGuestList",
            FromDate: fromDate,
            ToDate: toDate,
            NDIVISIONID: cafeteriaId
        }
        dispatch(getGuestOrderHistoryWatcher(data))
        setPage(1)
    }

    useEffect(()=>{
        if(eventVal)
        handleSearch(eventVal);
    },[GuestOrderHistoryList])

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports ", "Guest Order History"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Guest Order History</Typography>
                </Box>
                <Box p='10px 0'>
                    <CommonHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData = {GuestOrderHistoryList} columnData ={TABLE_HEAD} type= {'order'} />
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <GuestOrderHistoryTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText}/>
                </Card>
                
            </Box>
        </>
    )
}

export default GuestOrderHistory;
import { TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

export default function SaleTableFooter({rowVal, divisionName, roleName }) {

    const BCP_Total = rowVal.reduce((a,v) =>  +a + +v.BCP , 0 ).toFixed(2)
    const ILC_Total = rowVal.reduce((a,v) =>  +a + +v.ILC , 0 ).toFixed(2)
    const RIOP_Total = rowVal.reduce((a,v) =>  +a + +v.RIOP , 0 ).toFixed(2)
    const OTHERS_Total = rowVal.reduce((a,v) =>  +a + +v.OTHERS , 0 ).toFixed(2)
    const Quantity_Total = rowVal.reduce((a,v) =>  +a + +v.Quantity , 0 ).toFixed(2)
    const nTotalRate_Total = rowVal.reduce((a,v) =>  +a + +v.Rate , 0 ).toFixed(2)
    const Gst_Total = rowVal.reduce((a,v) =>  +a + +v.Gst , 0 ).toFixed(2)
    const ToatlRate_Total = rowVal.reduce((a,v) =>  +a + +v.ToatlRate , 0 ).toFixed(2)

    return (
        <>
            <TableRow>
                <TableCell sx={{ backgroundColor: 'common.Bblack' }}>
                    <Typography variant='t14_600' color='common.white'>Total</Typography>
                </TableCell>
                <TableCell sx={{ backgroundColor: 'common.Bblack' }} />

                {
                    roleName !== 'Admin' && roleName !== 'FoodAdmin' ?

                        <>
                            <TableCell>
                                <Typography variant='t14_600'>{BCP_Total}</Typography>
                            </TableCell>

                            <TableCell>
                                <Typography variant='t14_600'>{ILC_Total}</Typography>
                            </TableCell>

                            <TableCell>
                                <Typography variant='t14_600'>{RIOP_Total}</Typography>
                            </TableCell>
                        </>

                    :

                    <>

                        {
                            divisionName === 'IPSD' ?
                            <TableCell>
                                <Typography variant='t14_600'>{BCP_Total}</Typography>
                            </TableCell>
                            :
                            ''
                        }

                        {
                            divisionName === 'ILC' ?
                            <TableCell>
                                <Typography variant='t14_600'>{ILC_Total}</Typography>
                            </TableCell>
                            :
                            ''
                        }

                        {
                            divisionName === 'RIOP' ?
                            <TableCell>
                                <Typography variant='t14_600'>{RIOP_Total}</Typography>
                            </TableCell>
                            :
                            ''
                        }
                    </>
                }

                <TableCell>
                    <Typography variant='t14_600'>{OTHERS_Total}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='t14_600'>{Quantity_Total}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='t14_600'>{nTotalRate_Total}</Typography> 
                </TableCell>
                <TableCell >
                    <Typography variant='t14_600'>{Gst_Total}</Typography>
                </TableCell>
                <TableCell align='right'>
                    <Typography variant='t14_600'>{ToatlRate_Total}</Typography>
                </TableCell>
            </TableRow>
        </>
    )
}

import http from "../../http-common";

class MenuMasterService {

    getAll(data) {
        return http.post("/admin/kitchen/getMenuMaster", data);
    }
    getMealDropDown() {
        return http.post("/admin/mealDropDown");
    }
    getMenuDropDown(data) {
        return http.post("/admin/menuDropDown", data);
    }
    create(data) {
        return http.post("/admin/kitchen/addMenuMaster", data);
    }
    update(id, data) {
        return http.put(`/admin/kitchen/menuMaster/${id}`, data);
    }
    delete(id, data) {
        return http.delete(`/admin/kitchen/menuMaster/${id}`, data);
    }

}

export default new MenuMasterService();
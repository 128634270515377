import React, { useEffect, useRef } from "react";
import { Autocomplete, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CategoryCard from "../../../components/user/categoryCard";
import Dishes from "./dishes";
import { useDispatch, useSelector } from "react-redux";
import {
  cafeMenusWatcher,
  getDaysOfWeekWatcher,
  // getMealListWatcher,
  getMenuListWatcher,
} from "../../../redux/actionCreators/user/home";
import { getCurDate } from "../../../utils/common";
import SummaryDrawer from "../../../components/user/summaryDrawer";
import Scrollbar from "../../../components/Scrollbar";
import useResponsive from "../../../hooks/useResponsive";
import { useLocation } from "react-router-dom";
import CssTextField from "../../../components/overrides/textField";
import { clearCartWatcher } from "../../../redux/actionCreators/user/home";
import { guestTypeWatcher } from "../../../redux/actionCreators/guest";
import Context from "../../../context/context";

const CategoryPage = () => {
  const {
    UserHomeReducers: { categoryList: multiCat, cafeteriaId, cartList },
    ProfileReducers: { loginState }
  } = useSelector((state) => state);

  const [activeCateg, setActiveCateg] = React.useState("");
  const [value, setValue] = React.useState({});
  const [guestType, setGuestType] = React.useState(1);
  const [activeMealName,setActiveMealName] = React.useState('');
  const { state } = React.useContext(Context);
  const vendorId = sessionStorage.getItem('vendorId')
  const tempinitsform = useRef();
  const tempinitform = useRef();
  const tempform = useRef();

  const isMobile = useResponsive("down", "sm");

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const nUserID = loginState[0]?.nID || 0;
  const nDivisionID = loginState[0]?.nDivisionID || 0;
  const roleName = loginState[0]?.cRoleName;

  const categoryList =
    roleName === "SpecialGuest" && nDivisionID === 4
      ? guestType === 1
        ? multiCat[4]
        : multiCat[3]
      : ((roleName === "SpecialGuest" && nDivisionID === 2) ||
        (roleName === "SpecialGuest" && nDivisionID === 3)
          ? multiCat[3]
          : pathname.includes("contractor")
          ? multiCat[2]
          : multiCat[0]) || [];

         

  let defaultUser = guestType[1];

  const handleChange = (nID) => {
    setActiveCateg(nID);
  };

  const initsform = () => {
    dispatch(getMenuListWatcher({ Action: "MealMenuList" }));
    const getDate = getCurDate();

    const dateData = {
      cCurrentDay: getDate,
    };
    dispatch(getDaysOfWeekWatcher(dateData));
  };

  tempinitsform.current = initsform;

  useEffect(() => {
    tempinitsform.current();
  }, []);

  // useEffect(() => {
  //   dispatch(getMenuListWatcher({ Action: "MealMenuList" }));
  //   const getDate = getCurDate();

  //   const dateData = {
  //     cCurrentDay: getDate,
  //   };
  //   dispatch(getDaysOfWeekWatcher(dateData));
  // }, []);

  const initform = () => {
    
    if(categoryList?.length){
      setActiveCateg(categoryList[0].nID);
      setActiveMealName(categoryList[0].cMealName)
    }

   
  };

  tempinitform.current = initform;

  useEffect(() => {
    tempinitform.current();
  }, [categoryList]);

  // useEffect(()=>{
  //   // const data = {
  //   //   ACTION: "CHECKLIST",
  //   //   nUserID,
  //   // };
  //   // const viewCart = {
  //   //   nUserID,
  //   // };
  //   // dispatch(getCartWatcher(data, viewCart));
  //   if(cartList){
  //     if(vendorId !== cartList?.map((d) => d.cVendorID)[0]){
  //       clearCartApi();
  //     }
  //   }
  // },[state])

  // useEffect(()=>{
  //   categoryList?.length && setActiveCateg(categoryList?[0].nID)
  // },[categoryList?])

  const inits = () => {
    if (activeCateg && Object.keys(value).length) {
      const data = {
        ACTION: "ConMealSELECT",
        NID: nDivisionID,
        MEALID: `${activeCateg}`,
        CVENDORID: vendorId !== "" ? `${vendorId}` : 'null',
        UserId: nUserID,
        orderDate: value.formatDate,
      };
      // dispatch(getMealListWatcher(data))
      dispatch(cafeMenusWatcher(data));
    }
  };

  // tempform.current = inits;

  // useEffect(() => {
  //   tempform.current();
  // }, [activeCateg, cafeteriaId, value]);

  // const inits = () => {
  //   if (activeCateg && Object.keys(value).length) {
  //     const data = {
  //       ACTION: "venBaseMealSELECT",
  //       NID: vendorId,
  //       MEALID: `${activeCateg}`,
  //       UserId: nUserID,
  //       orderDate: value.formatDate,
  //     };
  //     // dispatch(getMealListWatcher(data))
  //     dispatch(cafeMenusWatcher(data));
  //   }
  // };

  tempform.current = inits;

  useEffect(() => {
    tempform.current();
  }, [activeCateg, cafeteriaId, value, vendorId]);

  // useEffect(() => {
  //   if (activeCateg && Object.keys(value).length) {
  //     const data = {
  //       "ACTION":"MealSELECT",
  //       "NID": cafeteriaId,
  //       "MEALID":  `${activeCateg}`,
  //       "UserId": nUserID,
  //       "orderDate": removeSymbolDate(value.date)
  //   }
  //     // dispatch(getMealListWatcher(data))
  //     dispatch(cafeMenusWatcher(data))
  //   }
  // }, [activeCateg, cafeteriaId, value])

  const type = [
    {
      id: 1,
      name: "Guest",
    },
    {
      id: 2,
      name: "Special Guest",
    },
  ];

  // useEffect(() => {
  //   dispatch(guestTypeWatcher(guestType));
  // }, [guestType])

  const clearCartApi = () => {
    const cartData = {
      ACTION: "CHECKLIST",
      nUserID,
    };
    dispatch(clearCartWatcher(nUserID, cartData));
  };

  const handleGuest = (value) => {
    setGuestType(value.id);
    dispatch(guestTypeWatcher(value.id));
    clearCartApi();
  };

  // const sumData = () => {
  //   let oldData = cartList;
  //   let totalInwards = 0;
  //   oldData.forEach((d) => {
  //     if (d.nQuantity) { totalInwards = totalInwards + d.nQuantity }
  //   })
  //   return totalInwards
  // }

  const sumData = cartList
    .map((o) => o.nQuantity)
    .reduce((a, c) => {
      return a + c;
    }, 0);

  const totalCart = sumData;

  return (
    <Box p="24px" pb="0px">
      <Box display="flex" mb="24px" flexDirection="column">
        <Typography variant="t13_500" sx={{ color: "grey.600" }}>
          Home
        </Typography>

        <Grid
          container
          spacing={3}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center">
          <Grid item lg={7.5} md={7.5} sm={6} xs={12}>
            <Typography variant="t24_700">Category</Typography>
          </Grid>
          {roleName === "SpecialGuest" && nDivisionID === 4 ? (
            <Grid
              item
              lg={3}
              md={3}
              sm={3}
              xs={12}
              display="flex"
              justifyContent="flex-end">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="guestType"
                fullWidth
                options={type}
                defaultValue={type.find(v => v.name === 'Guest') || {}}
                onChange={(event, value) => {
                  handleGuest(value);
                }}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <CssTextField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "48px",
                      },
                    }}
                    {...params}
                    label="Select Type"
                  />
                )}
              />
            </Grid>
          ) : (
            <Grid
              item
              lg={3}
              md={3}
              sm={3}
              xs={12}
              display="flex"
              justifyContent="flex-end"></Grid>
          )}
          <Grid
            item
            lg={1.5}
            md={1.5}
            sm={3}
            xs={12}
            display="flex"
            justifyContent="flex-end">
            <SummaryDrawer guestType={guestType} totalCart={totalCart} roleName={roleName}  categoryList={categoryList}/>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="flex-end">
            {roleName === 'NewJoiners' ? (
              totalCart <= 5 ? (
                <Typography
                  color="green"
                  variant="t14_400">{`Add minimum 1 Items to place order for One Session`}</Typography>
              ) : (
                <Typography
                  color="common.sred"
                  variant="t14_400">{`Maximum Allowed Quantity is 1 for One Session`}</Typography>
              )
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        {/* <Box mt="11px" display="flex" alignItems='center'>
          <Typography  variant="t24_700">
            Category
          </Typography>
          <Box width='20%' ml="58%">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              name="guestType"
              fullWidth
              options={type}
              // onChange={(event, value) => {
              //     setFieldValue("userType", value.id);
              // }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <CssTextField sx={{
                '& .MuiOutlinedInput-root': {
                  height: '48px'
                }
              }} {...params}
                label="Select Type"
              />}
            />
          </Box>
          <Box ml='auto'>
            <SummaryDrawer />
          </Box>
        </Box> */}
      </Box>

      <Box height={isMobile ? "auto" : "calc(100vh - 182px)"}>
        <Scrollbar>
          <Box>
            <Grid container spacing={2}>
              {categoryList?.map((d, i) => (
                <Grid
                  item
                  lg={2.4}
                  md={2.4}
                  sm={
                    categoryList?.length - 1 === i ||
                    categoryList?.length - 2 === i
                      ? 6
                      : 4
                  }
                  xs={12}
                  onClick={() => {handleChange(d.nID)
                  setActiveMealName(d.cMealName)
                  }
                }
                  key={d.nID}>
                  <CategoryCard
                    selectedDate={value.date}
                    activeCateg={activeCateg}
                    value={d}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box pb="24px">
            <Dishes
              value={value}
              setValue={setValue}
              activeCateg={activeCateg}
              activeMealName={activeMealName}
            />
          </Box>
        </Scrollbar>
      </Box>
    </Box>
  );
};

export default CategoryPage;

import React, { useEffect, useState } from "react";
import { TableCell, Typography, TableRow } from "@mui/material";
import ActionButtons from "../../reports/actionButton";
import { useDispatch, useSelector } from "react-redux";
import { getGuestHistoryViewWatcher } from "../../../../redux/actionCreators/admin/reports";
import { getConvertedDate2, getCurDate } from "../../../../utils/common";

const SplGuestHistoryTableRow = ({ rowData, setPageChange, type }) => {
  const dispatch = useDispatch();
  const [status] = useState("list");

  const {
    UserHomeReducers: { cafeteriaId },
  } = useSelector((state) => state);
  
  const viewFunction = () => {
    setPageChange(true);

    let data = {
      ACTION: "GetSpecialGuestFoodDetailsReport",
      UserId: rowData.UserName,
      Date: rowData.OrderDate?.split("/").reverse().join("-"),
      NDIVISIONID: cafeteriaId 
    };

    let data1 = {
      NDIVISIONID: cafeteriaId ,
      ACTION: "GetGuestFoodDetailsReport",
      UserId: rowData.UserName,
      Date: rowData.OrderDate?.split("/").reverse().join("-")
    };

    const d = type === "vip" ? data : data1;
    dispatch(getGuestHistoryViewWatcher(data1));
  };

  const invoice_orderdate = rowData.OrderDate

  // const formatedDate = invoice_orderdate.toISOString().format("yyyy/mm/dd")

  // console.log('getConvertedDate2', formatedDate);

  return (
    <>
      {" "}
      <TableRow hover tabIndex={-1}>
        {" "}
        <TableCell align="left">
          {" "}
          <Typography variant="t14_600">{rowData.EmployeeName}</Typography>{" "}
        </TableCell>{" "}
        <TableCell align="left">
          {" "}
          <Typography variant="t14_600">{rowData.UserName}</Typography>{" "}
        </TableCell>{" "}
        {/* <TableCell align="left">          <Typography variant="t14_600">{rowData.cDivisionName}</Typography>        </TableCell> */}
        <TableCell align="left">
          {" "}
          <Typography variant="t14_600">{rowData.OrderDate}</Typography>{" "}
        </TableCell>{" "}
        <TableCell align="left">
          {" "}
          <Typography variant="t14_600">
            {" "}
            {rowData["Breakfast"] || rowData["BreakFast"] || 0}
          </Typography>{" "}
        </TableCell>{" "}
        <TableCell align="left">
          {" "}
          <Typography variant="t14_600">
            {" "}
            {rowData["Dinner"] || 0}
          </Typography>{" "}
        </TableCell>{" "}
        <TableCell align="left">
          {" "}
          <Typography variant="t14_600">
            {" "}
            {rowData["Early morning snacks"] || 0}
          </Typography>{" "}
        </TableCell>{" "}
        <TableCell align="left">
          {" "}
          <Typography variant="t14_600">
            {" "}
            {rowData["Lunch"] || 0}
          </Typography>{" "}
        </TableCell>{" "}
        <TableCell align="left">
          {" "}
          <Typography variant="t14_600"> {rowData["Snacks"] || 0}</Typography>
        </TableCell>
        <TableCell align="left">
          {" "}
          <Typography variant="t14_600"> {rowData["Snacks"] || 0}</Typography>
        </TableCell>
        {/* {type === "vip" && (
          <>
            <TableCell align="left">
              <Typography variant="t14_600">
                {rowData["Breakfast(VIPGuest)"] || 0}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="t14_600">
                {rowData["Dinner(VIPGuest)"] || 0}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="t14_600">
                {rowData["Early morning snacks(VIPGuest)"] || 0}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="t14_600">
                {rowData["Lunch(VIPGuest)"] || 0}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="t14_600">
                {rowData["Snacks(VIPGuest)"] || 0}
              </Typography>
            </TableCell>
          </>
        )} */}
        <TableCell align="right">
          <ActionButtons
            type={"SplGuest"}
            invoice={"invoice"}
            orderDate={invoice_orderdate}
            userName={rowData.UserName}
          />
        </TableCell>
        <TableCell align="right">
          {" "}
          <ActionButtons
            type={"SplGuest"}
            status={status}
            viewFunction={viewFunction}
          />{" "}
        </TableCell>{" "}
      </TableRow>{" "}
    </>
  );
};
export default SplGuestHistoryTableRow;

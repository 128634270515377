import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Typography,
  Collapse,
  Button,
  Grid,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box } from "@mui/system";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import OrderList from "../../currentOrder/orderList";
// import { DataUsageTwoTone } from "@mui/icons-material";
import { getGst } from "../../../../../utils/gst";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ActionButtons from "../../../../../components/admin/reports/actionButton";
import { useSelector } from "react-redux";
import moment from "moment/moment";

const ChildTableRow = ({ rowData, index, page, rowsPerPage, userName }) => {
  const [open, setOpen] = useState(false);

  const date = moment(rowData.dOrderDate).format("YYYY-MM-DD")
  // console.log('fstfisuyfgvs', rowData.nID);
  
  return (
    <>
      <TableRow hover sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="left">
          <Typography variant="t14_600">{index + 1 + ((page - 1) * rowsPerPage)}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="t14_600">{rowData.nID}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="t14_600">{rowData.dOrderDate || ''}</Typography>
        </TableCell>
        {/* <TableCell align="left">
          <Typography variant="t14_600">{rowData.mealType}</Typography>
        </TableCell> */}
        <TableCell align="left">
          <Box display="flex" alignItems="center">
            {rowData.bIsCancelled ?
              <>
                <Typography variant="t14_600">Cancelled</Typography>
                <CancelOutlinedIcon
                  sx={{ ml: "10px", color: "common.black" }}
                />
              </>
              :
              rowData.bIsDelivered ?
                <>
                  <Typography variant="t14_600">Delivered</Typography>
                  <CheckCircleOutlineIcon
                    sx={{ ml: "14px", color: "common.sgreen" }}
                  />
                </>
                :
                <>
                  <Typography variant="t14_600">Pending</Typography>
                  <ErrorOutlineIcon
                    sx={{ ml: "22px", color: "primary.main" }}
                  />
                </>
            }
          </Box>
        </TableCell>
        {/* <TableCell align="left">
          <Typography variant="t14_600">{rowData.Cafeteria}</Typography>
        </TableCell> */}
        <TableCell align="left">
          <Typography variant="t14_600">{rowData.nToatlRate + +getGst(rowData.nToatlRate)}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="t14_600">{rowData.paymentStatus}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="t14_600">{rowData.cVendorName}</Typography>
        </TableCell>
        <TableCell align="center">
          <ActionButtons type = {'userOrderHistory'} invoice = {"invoice"} orderDate = {date} userName = {userName} isCancelled = {rowData.bIsCancelled} orderNo = {rowData.nID} />
        </TableCell>
        <TableCell align="right">
          <Button
            variant="contained" 
            onClick={() => setOpen(!open)}
            sx={{ minWidth: 35, height: 33, p: 0 }}
          >
            {!open ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
          </Button>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={12} style={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ p: "20px 0" }}>
              <Grid container spacing={3}>
                {/* {data?.map((d) => ( */}
                  <Grid item xs={12} sm={6} md={6}>
                    <OrderList value={rowData} />
                  </Grid>
                {/* ))} */}
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ChildTableRow;

import { GROLE_SWIPE_HISTORY_LIST, GET_GUEST_ROLE_COUNT_LIST, GUEST_TYPE_LIST } from "../../actionTypes/guest";

const intialState = {
//   bookingHistoryList: [],
//   guestDashboardData: [],
  GRoleSwipeHistoryList: [],
  guestRoleUserCount: [],
  guestTypeList: 1
  
};

export default function GuestRoleReducers(state = intialState, action) {
  switch (action.type) {

    // case GUEST_BOOKING_HISTORY_LIST:
    //   return { ...state, bookingHistoryList: action.payload };

    // case GUEST_DASHBOARD_DATA:
    //   return { ...state, guestDashboardData: action.payload };

    case GROLE_SWIPE_HISTORY_LIST:
      return { ...state, GRoleSwipeHistoryList: action.payload };

    case GET_GUEST_ROLE_COUNT_LIST:
      return { ...state, guestRoleUserCount: action.payload };
    
    case GUEST_TYPE_LIST:
        return { ...state, guestTypeList: action.payload };

    default:
      return state;
  }
}

import React from 'react';
import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
// import BlackButton from '../../../components/overrides/blackButton'

const DeleteDialog = (props) => {

    const { open, setOpen, handleDelete } = props;

    return (
        <>
            <Dialog
                maxWidth='md'
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Grid container
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    style={{ height: '110px', width: '700px' }}>
                    <Grid item lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        paddingLeft='20px'>
                        <DialogContent>
                        <Typography color='common.black' variant='t18_400'>
                            Are you sure want to delete ?
                            </Typography>
                        </DialogContent>
                    </Grid>
                    <Grid item lg={6}
                        md={6}
                        sm={3}
                        xs={6}>
                        <Grid container
                            spacing={1} style={{ padding: '15px', paddingLeft: '30px' }}>
                            <Grid item lg={6}
                                md={6}
                                sm={6}
                                xs={6}>
                                <Button variant='contained' color='inherit' sx={{
                                    minWidth: '120px',
                                    height: '42px',
                                    left: '0px',
                                    top: '0px',
                                    backgroundColor: 'grey.300',
                                    '&:hover' :{
                                        backgroundColor: 'grey.300',
                                    }
                                }} onClick={() => setOpen(false)} >
                                    <Typography color='common.black' variant='t14_400'>No</Typography>
                                </Button>
                            </Grid>
                            <Grid item lg={6}
                                md={6}
                                sm={6}
                                xs={6}>
                                <Button variant='contained' sx={{
                                    minWidth: '120px',
                                    height: '42px',
                                    left: '0px',
                                    top: '0px',
                                    backgroundColor: 'common.Bblack',
                                    '&:hover' :{
                                        backgroundColor: 'common.Bblack',
                                    }
                                }} onClick={handleDelete}>
                                    <Typography color='inherit' variant='t14_400'>Yes</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}

export default DeleteDialog;
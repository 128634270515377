import { EMPLOYEE_CONTRACTOR_LIST, GET_EMPLOYEE_CONTRACTOR_WATCHER } from "../../actionTypes/admin";

// Worker triggering actionCreators
export function getEmployeeContractorWatcher(authParams) {
    return { type: GET_EMPLOYEE_CONTRACTOR_WATCHER, payload: authParams };
}

// Redux state changing actionCreators
export function EmployeeContractorList(data) {
    return { type: EMPLOYEE_CONTRACTOR_LIST, payload: data };
}
import http from "../../http-common";

class CarouselService {

    // Login carousel

    loginGet(data) {
        return http.post(`/admin/getCarousel`, data);
    }
    loginCreate(data) {
        return http.post(`/admin/addCarousel`, data);
    }
    loginUpdate(id, data) {
        return http.put(`/admin/Carousel/${id}`, data);
    }
    loginDelete(id, data) {
        return http.delete(`/admin/Carousel/${id}`, data);
    }

    // Freeze Booking

    freezeGet(data) {
        return http.post(`/admin/getFreezeBooking`, data);
    }
    freezeCreate(data) {
        return http.post(`/admin/addFreezeBooking`, data);
    }
    freezeUpdate(id, data) {
        return http.put(`/admin/freezeBooking/${id}`, data);
    }
    freezeDelete(id, data) {
        return http.delete(`/admin/freezeBooking/${id}`, data);
    }

    // User carousel

    userGet(data) {
        return http.post(`/user/getCarousel`, data);
    }
    userCreate(data) {
        return http.post(`/user/addCarousel`, data);
    }
    userUpdate(id, data) {
        return http.post(`/user/Carousel/${id}`, data);
    }
    userDelete(id, data) {
        return http.delete(`/user/Carousel/${id}`, data);
    }
    foodWastage(data) {
        return http.post(`/admin/foodWastage`, data);
    }

}

export default new CarouselService();
import React, { useEffect, useState, useRef } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import DeliveryHistoryHeader from './header';
import { getDeliveryHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import { useDispatch, useSelector } from 'react-redux';
import { TABLE_HEAD } from '../../../components/guest/deliveryHistory/table';
import { getDropDownWatcher } from '../../../redux/actionCreators/admin/employeeManagement';
import GuestDeliveryHistoryTable from '../../../components/guest/deliveryHistory/table';
import { SUB_TABLE_HEAD } from '../../../components/guest/deliveryHistory/gRoleDHistoryChildTable';
import { getConvertedDate } from '../../../utils/common';

const GuestDeliveryHistory = () => {
    const { ReportsReducers: { DeliveryHistoryList }, ProfileReducers: { loginState } } = useSelector((state) => state)
    const nUserID = loginState[0]?.nID || 0

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })
    const tempinitsform = useRef();
    const vendorId = sessionStorage.getItem('vendorId')

    const initsform = () => {
        let data = {
            "ACTION": "GuestDeliveryUser",
            "UserID": nUserID,
            CVENDORID: vendorId !== "" ? `${vendorId}` : 'null',
        }
        dispatch(getDeliveryHistoryWatcher(data));
        dispatch(getDropDownWatcher());
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, [vendorId]);
    

    // useEffect(() => {
    //     let data = {
    //         "ACTION": "GuestDeliveryUser",
    //         "UserID": nUserID
    //     }
    //     dispatch(getDeliveryHistoryWatcher(data));
    //     dispatch(getDropDownWatcher());
    // }, [])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = DeliveryHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate, cafeteriaId=0) => {
        let currentDate = new Date()
        let curDate = getConvertedDate(currentDate)
        
        // if(toDate >= fromDate && toDate <= curDate && fromDate <= curDate){
            const data = {
                ACTION: "GuestDeliveryUserSearch",
                FromDate: fromDate !== null ? fromDate : currentDate,
                ToDate: toDate !== null ? toDate : currentDate,
                nCafeteriaId: cafeteriaId,
                UserID: nUserID,
                CVENDORID: vendorId !== "" ? `${vendorId}` : 'null',
            }
            if(!fromDate || !toDate ) return 
            dispatch(getDeliveryHistoryWatcher(data))
            setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
            setPage(1)
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true })
        // }
    }

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Delivery History"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Guest Delivery History</Typography>
                </Box>
                <Box p='10px 0'>
                    <DeliveryHistoryHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={DeliveryHistoryList} columnData={[...TABLE_HEAD,...SUB_TABLE_HEAD]} invalidErr={invalidErr} setInvalidErr={setInvalidErr} />
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <GuestDeliveryHistoryTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                </Card>
            </Box>
        </>
    )
}

export default GuestDeliveryHistory;
import { CREATE_EMPLOYEE_WATCHER, UPDATE_EMPLOYEE_WATCHER, DELETE_EMPLOYEE_WATCHER, GET_EMPLOYEE_WATCHER, EMPLOYEE_LIST, ROLE_NAME_LIST, GET_DROPDOWN_WATCHER, DIVISION_LIST, ACCESS_POINT_LIST, DEPARTMENT_LIST, TRIP_TYPE_LIST, TYPE_OF_CAB, CONTRACTOR, CAFETERIA_LIST, VENDOR_NAME_LIST, COUNTER_LIST, CREATE_BULK_EMPLOYEE_WATCHER, GUEST_DROP_LIST, GET_LIST_BY_ID_WATCHER, GET_LIST_BY_ID_LIST, CATEGORY_NAME_LIST, DEPARTMENT_NAME_LIST, GET_DIVISION_WATCHER, ADD_DIVISION_WATCHER, UPDATE_DIVISION_WATCHER, DELETE_DIVISION_WATCHER, GET_DIVISION_LIST, SCHEDULE_NOTIFICATION_LIST, CREATE_SCHEDULE_NOTIFICATION_WATCHER} from "../../actionTypes/admin";

// Worker triggering actionCreators

export function getEmployeeWatcher(data) {
    return { type: GET_EMPLOYEE_WATCHER, payload: data };
}

export function createEmployeeWatcher(data, setOpen) {
    return { type: CREATE_EMPLOYEE_WATCHER, payload: data, setOpen };
}

export function updateEmployeeWatcher(id, data) {
    return { type: UPDATE_EMPLOYEE_WATCHER, payload: data, id };
}

export function deleteEmployeeWatcher(id, data) {
    return { type: DELETE_EMPLOYEE_WATCHER, payload: data, id };
}

export function getDropDownWatcher() {
    return { type: GET_DROPDOWN_WATCHER };
}

export function createBulkEmployeeWatcher(data, setAddDialogOpen) {
    return { type: CREATE_BULK_EMPLOYEE_WATCHER, payload: data, setAddDialogOpen };
}

export function getListByIdWatcher(data) {
    return { type: GET_LIST_BY_ID_WATCHER, payload: data };
}

export function getDivisionWatcher(data) {
    return { type: GET_DIVISION_WATCHER, payload: data };
}

export function addDivisionWatcher(data) {
    return { type: ADD_DIVISION_WATCHER, payload: data };
}

export function updateDivisionWatcher(id, data) {
    return { type: UPDATE_DIVISION_WATCHER, payload: data, id };
}

export function deleteDivisionWatcher(data) {
    return { type: DELETE_DIVISION_WATCHER, payload: data };
}

export function ScheduleNotificationWatcher(data) {
    return { type: CREATE_SCHEDULE_NOTIFICATION_WATCHER, payload: data };
}


// Redux state changing actionCreators
export function employeeList(data) {
    return { type: EMPLOYEE_LIST, payload: data };
}

export function roleNameList(data) {
    return { type: ROLE_NAME_LIST, payload: data };
}

export function divisionList(data) {
    return { type: DIVISION_LIST, payload: data };
}

export function accessPointList(data) {
    return { type: ACCESS_POINT_LIST, payload: data };
}

export function counterList(data) {
    return { type: COUNTER_LIST, payload: data };
}

export function departmentList(data) {
    return { type: DEPARTMENT_LIST, payload: data };
}

export function tripTypeList(data) {
    return { type: TRIP_TYPE_LIST, payload: data };
}

export function typeOfCab(data) {
    return { type: TYPE_OF_CAB, payload: data };
}

export function contractorList(data) {
    return { type: CONTRACTOR, payload: data};
}

export function cafeteriaList(data) {
    return { type: CAFETERIA_LIST, payload: data};
}

export function vendorList(data) {
    return { type: VENDOR_NAME_LIST, payload: data};
}

export function categoryList(data) {
    return { type: CATEGORY_NAME_LIST, payload: data};
}

export function deparmentNameList(data) {
    return { type: DEPARTMENT_NAME_LIST, payload: data};
}

export function guestDropList(data) {
    return { type: GUEST_DROP_LIST, payload: data};
}

export function getListByIdList(data) {
    return { type: GET_LIST_BY_ID_LIST, payload: data};
}

export function getDivisionList(data) {
    return { type: GET_DIVISION_LIST, payload: data};
}

export function ScheduleNotificationList(data) {
    return { type: SCHEDULE_NOTIFICATION_LIST, payload: data};
}
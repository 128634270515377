// component
import { HomeOutlined, ShoppingCartOutlined } from '@mui/icons-material'
// ----------------------------------------------------------------------

export const Guest = [
  {
    title: 'Dashboard',
    path: '/guest',
    icon: <HomeOutlined color='inherit' />
  },
  {
    title: 'Booking History',
    path: '/guest/bookingHistory',
    icon: <ShoppingCartOutlined color='inherit'/>
  },
  {
    title: 'Delivery History',
    path: '/guest/deliveryHistory',
    icon: <ShoppingCartOutlined color='inherit'/>
  },
  {
    title: 'Order Booking',
    path: '/guest/orderBooking',
    icon: <ShoppingCartOutlined color='inherit'/>
  },
  {
    title: 'Swipe History',
    path: '/guest/swipeHistory',
    icon: <ShoppingCartOutlined color='inherit'/>
  },

];

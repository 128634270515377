import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import WeeksTabs from "./weekTabs";
import WeekFoodCard from "../../../components/user/weekFoodCard.js";
// import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { getMenuListWatcher, weeklyBookingWatcher } from "../../../redux/actionCreators/user/home";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { getGst } from "../../../utils/gst";
// import { orderBookingWatcher } from "../../../redux/actionCreators/user/currentOrder";
// import { useNavigate } from 'react-router-dom';
// import { updateAlert } from "../../../redux/actionCreators/alert";
import SummaryDrawer from "../../../components/user/summaryDrawer";

const Bulk = () => {

  const [value, setValue] = React.useState('');

  const dispatch = useDispatch()
  // const navigate = useNavigate()

  const { UserHomeReducers: { weeklyBookingDates, mealMenuList }} = useSelector((state) => state);

  // const nUserID = loginState[0]?.nID || 0
  // const cRFID = loginState[0]?.cRFID || 0

  // const getTotal = () =>{
  //   const total = getOrderTotalCost.length && getOrderTotalCost[0].nTotalost
  //   return total
  // }

  useEffect(() => {
    dispatch(getMenuListWatcher({ Action: "MealMenuList" }));
    dispatch(weeklyBookingWatcher({ "ACTION": "select" }));
  }, [dispatch]);

  // const handleCheckout = () => {
  //   if(!getTotal()){
  //     return dispatch(updateAlert('Cart is empty', 'error'));
  //   }
  //   const data = {
  //     "ACTION": "INSERT",
  //     "UserID": nUserID,
  //     "RFID": cRFID,
  //     "nGst": getGst(getTotal()),
  //     "Total": getTotal(),
  //     "OrderBY": "E",
  //     "CreatedBy": nUserID
  //   }
  //   dispatch(orderBookingWatcher(data, dispatch, navigate))
  // }
  
  // const dispatchPayment = (data) => {
  //   dispatch(paymentWatcher(data, pushCurrent))
  // }

  // const pushCurrent = () => {
  //   navigate('/user/currentOrder')
  // }

  return (
    <Box p="24px">
      <Breadcrumbs content={["Bulk"]} />

      <Box mt="14px">
        <Typography variant="t24_700">Book your meal for a week</Typography>
      </Box>

      <WeeksTabs value={value} setValue={setValue} />

      {
        mealMenuList.length ?
          <Box mt="30px" mb='88px'>
            <Grid container spacing={3}>
              {weeklyBookingDates.map((d) => (
                <Grid item xs={12} sm={6} xl={4} key={d.ID}>
                  <WeekFoodCard week={d} mID={value} />
                </Grid>
              ))}
            </Grid>
          </Box>
          :
          <Box mt='20px' height='calc(100vh - 400px)' alignItems='center' display='flex' justifyContent='center' >
            <Typography variant='t24_700' color='grey.500' >
              Dishes for this cafeteria currently not Available!
            </Typography>
          </Box>
      }

      <Box bgcolor='common.white'
        sx={{ boxShadow: theme => `0px -2px 10px ${theme.palette.grey[400]}` }}
        display='flex' p='20px'
        width={{ xs: '100%', lg: 'calc(100% - 235px)' }}
        position='fixed' bottom={{sm:0, xs:56}}
        left={{ xs: '0px', lg: '235px' }} >
        {/* <Button onClick={handleCheckout} sx={{ height: 48, width: 211, ml: 'auto' }} variant="contained" endIcon={<DoubleArrowIcon />} >
          <Typography>
            Complete Order
          </Typography>
        </Button> */}
        <Box ml='auto' >
        <SummaryDrawer name='Complete Order' />
        </Box>
      </Box>
    </Box>
  );
};

export default Bulk;

import { Button, Typography, Box, Link } from "@mui/material";
import React, { useState } from "react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { styled } from '@mui/material/styles';
import { read, utils } from 'xlsx';
import { useDispatch, useSelector } from "react-redux";
import { addConUserWatcher } from '../../../../../redux/actionCreators/admin/contractorManagement';
import { updateAlert } from "../../../../../redux/actionCreators/alert";
import { IconButton } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { ExportCsv } from "@material-table/exporters";

const Input = styled('input')({
    display: 'none',
});

export default function AddDesign({ setAddDialogOpen, rowDatas, setDuplicateDialogOpen, setDupRfid }) {

    const dispatch = useDispatch()
    const { ProfileReducers: { loginState }, ContractorReducers: { allConUserList } } = useSelector(state => state)
    const nUserID = loginState[0]?.nID || 0

    const [fileName, setFileName] = useState('')
    const [fileData, setFileData] = useState([])

    const onFileSelect = (e) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        const file = e.target.files[0]
        var name = e.target.files[0].name;
        setFileName(name);
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = utils.sheet_to_json(ws);
            setFileData(data)
        };

        if (rABS) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        };
    }

    const submitFunc = () => {

        // existing data duplication
        const rfidFilter = (allConUserList.length && allConUserList.map(m => m.cRFID)) || []
        const duplication = (fileData.length && fileData.filter(m => rfidFilter.includes(`${m.RFID}`))) || []
        const duplicateRfid = (duplication.length && duplication.map(m => m.RFID)) || []
        // setDupRfid(duplicateRfid)

        // new insert duplication
        const getFileDup = fileData.map(d => d.RFID)
        const duplicates = (getFileDup.length && getFileDup.filter((item, index) => index !== getFileDup.indexOf(item))) || [];
        setDupRfid([...duplicateRfid, ...duplicates])


        if (!fileName.length || !fileData.length) {
            return dispatch(updateAlert('Please select a valid file!', 'error'));
        }

        if (duplication.length || duplicates.length) {
            setAddDialogOpen(false)
            setDuplicateDialogOpen(true)
            return dispatch(updateAlert('There are some duplications!', 'error'));
        }
        else {
            let data = {
                users: fileData,
                nContractorID: rowDatas.NID,
                nUserID: nUserID
            }
            dispatch(addConUserWatcher(data, setAddDialogOpen))

        }
    }

    const columnData = [{ 'title': 'RFID' }]
    const rowData = []

    return (
        <>

            <Box p='0 10px 10px 0'>
                <Box display='flex' alignItems='center' mb='30px'>
                    <Box >
                        <Typography variant='t16_500' color='common.black'>Select file to upload </Typography>
                    </Box>
                    <Box ml='50px'>
                        <Link href="#" color='common.weekTab' onClick={() => ExportCsv(columnData, rowData, "Sample contractorUser")}>
                            Sample
                        </Link>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'right', ml: 'auto' }}>
                        <IconButton onClick={() => setAddDialogOpen(false)}>
                            <ClearOutlinedIcon sx={{ color: 'common.black' }} />
                        </IconButton>
                    </Box>
                </Box>
                <Box display='flex' mt='20px' >
                    <label style={{ display: 'flex', width: '100%' }} htmlFor="contained-button-file">
                        <Input id="contained-button-file" multiple type="file" onChange={onFileSelect} />
                        <Button
                            variant="contained"
                            component="span"
                            fullWidth
                            size='large'
                            style={{ justifyContent: 'space-between' }}
                            endIcon={<FileUploadOutlinedIcon />}
                            type='button'
                            sx={{
                                backgroundColor: 'common.editfield', color: 'common.black',
                                "&:hover": {
                                    backgroundColor: "common.editfield"
                                }, height: '40px', minWidth: '200px'
                            }}
                        >
                            <Typography variant='t14_400'>
                                {fileName ? fileName : 'Browse File'}
                            </Typography>
                        </Button >
                    </label>

                    <Box display='flex' ml='auto' >
                        <Button onClick={submitFunc}
                            sx={{
                                height: '38px',
                                ml: '30px',
                                minWidth: '100px',
                                backgroundColor: 'primary.main',
                                marginTop: 'auto',
                            }}
                            variant='contained'
                            size='medium'
                            type='submit'
                        >
                            <Typography variant='t16_500' color='grey.0'>Submit</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
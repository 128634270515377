import { CONTRACTOR_LIST, ALL_CONTRACTOR_USER_LIST, VIEW_CONTRACTOR_USER_LIST } from "../../actionTypes/admin";

const intialState = {
  contractorList: [],
  allConUserList: [],
  viewConUserList: [],
};

export default function ContractorReducers(state = intialState, action) {
  switch (action.type) {

    case CONTRACTOR_LIST:
      return { ...state, contractorList: action.payload };

    case ALL_CONTRACTOR_USER_LIST:
      return { ...state, allConUserList: action.payload };

    case VIEW_CONTRACTOR_USER_LIST:
      return { ...state, viewConUserList: action.payload };

    default:
      return state;
  }
}

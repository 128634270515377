import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs from "../../../components/breadcrumbs";
import { Box, Typography, Card } from "@mui/material";
import DeliveryHistoryHeader from './header';
import DHistoryTable from '../../../components/contractor/deliveryHistory/table';
import { getDeliveryHistoryWatcher } from '../../../redux/actionCreators/admin/reports';
import { useDispatch, useSelector } from 'react-redux';
// import { TABLE_HEAD } from '../../../components/admin/reports/deliveryHistory/deliveryHistoryTable';
import { TABLE_HEAD } from '../../../components/contractor/deliveryHistory/table';
import { getDropDownWatcher } from '../../../redux/actionCreators/admin/employeeManagement';
import { SUB_TABLE_HEAD } from '../../../components/contractor/deliveryHistory/cRoleDHistoryChildTable';
import Context from '../../../context/context';
import { getConvertedDate } from '../../../utils/common';

const DeliveryHistory = () => {
    const { ReportsReducers: { DeliveryHistoryList }, ProfileReducers: { loginState } } = useSelector((state) => state)
    const nUserID = loginState[0]?.nID || 0

    // const vendorId = loginState[0]?.nVendorID || ''
    const nRoleID = loginState[0]?.nRoleID || ''
    const vendorId = sessionStorage.getItem('vendorId')

    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    const [page, setPage] = useState(1);
    const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })
     const { state } = useContext(Context);

    useEffect(() => {
        let data = {
            "ACTION": "ContractorDeliveryUser",
            "UserID": nUserID,
            CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorId !== "" ? `${vendorId}` : 'null')
        }
        dispatch(getDeliveryHistoryWatcher(data))
        dispatch(getDropDownWatcher());
    }, [nUserID, dispatch, vendorId])

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = DeliveryHistoryList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });

        setSearchVal(filteredRows);
        setPage(1)
    }

    const handleSubmitAction = (fromDate, toDate, cafeteriaId=0) => {
        let currentDate = new Date()
        let curDate = getConvertedDate(currentDate)
        
        // if(toDate >= fromDate){
            // && toDate <= curDate && fromDate <= curDate
            const data = {
                ACTION: "ContractorDeliveryUserSearch",
                FromDate: fromDate !== null ? fromDate : currentDate,
                ToDate: toDate !== null ? toDate : currentDate,
                nCafeteriaId: cafeteriaId,
                UserID: nUserID,
                CVENDORID: nRoleID === 3 ? String(vendorId) :  ( vendorId !== "" ? `${vendorId}` : 'null')
            }
            if(!fromDate || !toDate ) return 
            dispatch(getDeliveryHistoryWatcher(data))
            setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
            setPage(1)
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true })
        // }
    }

    return (
        <>
            <Box p="24px">

                <Breadcrumbs content={["Reports", "Delivery History"]} />

                <Box mt="14px" mb='8px' display='flex' alignItems='center' >
                    <Typography variant="t24_700">Contractor Delivery History</Typography>
                </Box>
                <Box p='10px 0'>
                    <DeliveryHistoryHeader handleSearch={handleSearch} searchText={searchText} handleSubmitAction={handleSubmitAction} rowData={DeliveryHistoryList} columnData={[...TABLE_HEAD,...SUB_TABLE_HEAD]} invalidErr={invalidErr} setInvalidErr={setInvalidErr} />
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <DHistoryTable page={page} setPage={setPage} searchVal={searchVal} searchText={searchText} />
                </Card>
            </Box>
        </>
    )
}

export default DeliveryHistory;
import { call, put, takeEvery, select } from "redux-saga/effects";
import { CURRENT_ORDER_WATCHER, DELIVERED_ORDER_WATCHER, NEXT_DAY_ORDER_WATCHER, NEXT_WEEK_ORDER_WATCHER, ORDER_HISTORY_WATCHER, UPCOMING_ORDER_WATCHER, ORDER_BOOKING_WATCHER, PAYMENT_WATCHER, INSERT_FEEDBACK_WATCHER, RATE_FEEDBACK_WATCHER, USER_ORDER_CANCEL_WATCHER, USER_ORDER_HISTORY_CANCEL_WATCHER, VERIFY_PAYTM_WATCHER, INSTA_MOJO_PAYMENT, ORDER_BOOKING_PAYNIMO_WATCHER } from "../../actionTypes/currentOrder";
import { orderHistoryWatcher, paymentWatcher, updateCurrentOrder, updateDeliveredOrder, updateNextDayOrder, updateNextWeekOrder, updateOrderHistory, updateUpcomingOrder } from "../../actionCreators/user/currentOrder";
import Service from "../../../services/user/currentOrder"
import { updateFalseLoader, updateOpenLoader } from "../../actionCreators/spinner";
import { updateAlert } from "../../actionCreators/alert";
// import theme from '../../../theme/palette'
import { clearCartWatcher } from "../../actionCreators/user/home";
import { upcomingOrderWatcher } from '../../actionCreators/user/currentOrder';
import theme from '../../../theme/palette'
import { GetRoutes } from "../../../utils/constants";
// import axios from "axios";


function* currentOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.currentOrder, action.payload);

    yield put(updateCurrentOrder(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}


function* orderHistoryEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.orderHistory, action.payload);

    yield put(updateOrderHistory(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* nextdayOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.currentOrder, action.payload);

    yield put(updateNextDayOrder(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* nextweekOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.currentOrder, action.payload);

    yield put(updateNextWeekOrder(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* deliveredOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.currentOrder, action.payload);
    yield put(updateDeliveredOrder(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

function* upcomingOrderEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.currentOrder, action.payload);

    yield put(updateUpcomingOrder(data.recordset));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

// Razorpay

function* orderBookingEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.orderBooking, action.payload);

    const { ProfileReducers: { loginState }, UserHomeReducers: { cafeteriaId } } = yield select((state) => state)

    const { cEmployeeName, cEmailID, cMobileNumber, nID, nRoleID,nDivisionID } = loginState[0] || {}
    const nUserID = nID || 0
    const vendorId = sessionStorage.getItem('vendorId')

    if(nRoleID === 7){
      const nUserID = nID || 0
      const payload = {
        nOrderID: data?.notes.OrderNID,
        cOrderID: data?.notes.OrderID,
        nTotalRate: data?.amount,
       cPaymentStatus: 'Y',
       cPaymentDesc: 'Success',
       cBankTrackingID: "",
        cReferenceID: "",
        nUserID:nUserID,
       nCafeteriaId: nDivisionID,
       CVENDORID: `${vendorId}`
      };
      yield paymentInternEffect(payload,action.navigate)
      yield put(updateFalseLoader());
    }
    else{
       // Getting the order details back
    const { amount, id: order_id, currency, notes } = data;
    
    const key = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_KEY_ID : process.env.REACT_APP_KEY_ID_DEV
    const options = {
      key, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: cEmployeeName,
      description: "3D Cafe",
      image: '',
      order_id: order_id,
      handler: function (response) {
        const payload = {
          nOrderID: notes.OrderNID,
          cOrderID: notes.OrderID,
          nTotalRate: amount,
          cPaymentStatus: 'Success' ,
          cPaymentDesc: 'Success',
          cBankTrackingID: response.razorpay_payment_id,
          cReferenceID: response.razorpay_order_id,
          nUserID,
          nCafeteriaId: nDivisionID,
          CVENDORID : `${vendorId}`
        };

        action.dispatch(paymentWatcher(payload, action.navigate))
      },
      prefill: {
        name: cEmployeeName,
        email: cEmailID,
        contact: cMobileNumber,
      },
      notes: {
        address: "3dcafe enhancement",
      },
      theme: {
        color: theme.primary.main,
      },
    };

    yield put(updateFalseLoader());
    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
  //  console.log("res",response)
    });
    paymentObject.open();
    }

   
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
}

// Paytm

function* orderBookingPaytmEffect(action) {
  try {
    yield put(updateOpenLoader());
    let { data } = yield call(Service.orderBookingPaytm, action.payload);

    // Getting the order details back
    const { body: { amount, OrderNID, OrderID, txnToken } } = data;
    const { ProfileReducers: { loginState }, UserHomeReducers: { cafeteriaId } } = yield select((state) => state)

    const { nID,nDivisionID } = loginState[0] || {}
    const nUserID = nID || 0

    const config = {
      "root": "",
      "flow": "DEFAULT",
      "data": {
        "orderId": OrderID, /* update order id */
        "token": txnToken, /* update token value */
        "tokenType": "TXN_TOKEN",
        "amount": amount /* update amount */
      },
      "handler": {
        "notifyMerchant": function () {

        },
        transactionStatus: function (data) {
          window.Paytm.CheckoutJS.close()
          if (data.STATUS === 'TXN_SUCCESS') {
            const payload = {
              nOrderID: OrderNID,
              cOrderID: OrderID,
              nTotalRate: amount,
              cPaymentStatus: 'Success',
              cPaymentDesc: 'Success',
              cBankTrackingID: data.TXNID,
              cReferenceID: data.BANKTXNID,
              nUserID,
              nCafeteriaId: nDivisionID
            };
            action.dispatch(paymentWatcher(payload, action.navigate))
          } else {
            action.dispatch(updateAlert('Payment failed', 'error'))
          }

        }
      },
      "merchant": {
        "redirect": false
      },
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
        action.dispatch(updateFalseLoader())
        window.Paytm.CheckoutJS.invoke();
      }).catch(function onError(error) {
        action.dispatch(updateFalseLoader())
      });
    }

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
}

function* orderBookingCashfreeEffect(action) {
  const { ProfileReducers: { loginState }, UserHomeReducers: { cafeteriaId } } = yield select((state) => state)

  const { cEmployeeName, cEmailID, cMobileNumber, nRoleID, nID ,nDivisionID} = loginState[0] || {}
  const vendorId = sessionStorage.getItem('vendorId')
  // console.log("dsds",vendorId)
  try {
    yield put(updateOpenLoader());
   

    

    let { data } = yield call(Service.orderBookingCashfree, {...action.payload, cEmailID, cMobileNumber, cEmployeeName, cafeteriaId, CVENDORID : `${vendorId}`});
    console.log("dfsffed",data)
    if(nRoleID === 7){
      // const { cEmployeeName, cEmailID, cMobileNumber, nID,nRoleID } = loginState[0] || {}
      const nUserID = nID || 0
      const payload = {
        nOrderID: data?.order_tags.OrderNID  ,
        cOrderID: data?.order_tags.OrderID,
        nTotalRate: data?.order_amount,
       cPaymentStatus: 'Y',
       cPaymentDesc: 'Success',
       cBankTrackingID: "",
        cReferenceID: "",
        nUserID:nUserID,
       nCafeteriaId: nDivisionID,
       CVENDORID: `${vendorId}`
      };
      yield paymentInternEffect(payload,action.navigate)
      
    }
    else{
      // Getting the order details back
    const { payment_link } = data;
    
    window.location.replace(payment_link)
    // const payload = {
    //   nOrderID: notes.OrderNID,
    //   cOrderID: notes.OrderID,
    //   nTotalRate: amount,
    //   cPaymentStatus: 'Y',
    //   cPaymentDesc: 'Success',
    //   cBankTrackingID: response.razorpay_payment_id,
    //   cReferenceID: response.razorpay_order_id,
    //   nUserID,
    //   nCafeteriaId: cafeteriaId
    // };

    // action.dispatch(paymentWatcher(payload, action.navigate))
    }
    
    

    yield put(updateFalseLoader());
    
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
}


function* orderBookingCashfreeNewEffect(action) {
  const { ProfileReducers: { loginState }, UserHomeReducers: { cafeteriaId } } = yield select((state) => state)

  const { cEmployeeName, cEmailID, cMobileNumber, nRoleID, nID ,nDivisionID} = loginState[0] || {}
  const vendorId = sessionStorage.getItem('vendorId')
  // console.log("dsds",vendorId)
  try {
    yield put(updateOpenLoader());
   

    

    let { data } = yield call(Service.orderBookingCashfreeNew, {...action.payload, cEmailID, cMobileNumber, cEmployeeName, cafeteriaId, CVENDORID : `${vendorId}`});
    console.log("dfsffed",data)
    if(nRoleID === 7){
      // const { cEmployeeName, cEmailID, cMobileNumber, nID,nRoleID } = loginState[0] || {}
      const nUserID = nID || 0
      const payload = {
        nOrderID: data?.order_tags.OrderNID  ,
        cOrderID: data?.order_tags.OrderID,
        nTotalRate: data?.order_amount,
       cPaymentStatus: 'Y',
       cPaymentDesc: 'Success',
       cBankTrackingID: "",
        cReferenceID: "",
        nUserID:nUserID,
       nCafeteriaId: nDivisionID,
       CVENDORID: `${vendorId}`
      };
      yield paymentInternEffect(payload,action.navigate)
      
    }
    else{
      // Getting the order details back
    const { payment_link } = data;
    
    window.location.replace(payment_link)
    // const payload = {
    //   nOrderID: notes.OrderNID,
    //   cOrderID: notes.OrderID,
    //   nTotalRate: amount,
    //   cPaymentStatus: 'Y',
    //   cPaymentDesc: 'Success',
    //   cBankTrackingID: response.razorpay_payment_id,
    //   cReferenceID: response.razorpay_order_id,
    //   nUserID,
    //   nCafeteriaId: cafeteriaId
    // };

    // action.dispatch(paymentWatcher(payload, action.navigate))
    }
    
    

    yield put(updateFalseLoader());
    
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e.response?.data.message, 'error'));
  }
}



function* dynamicOrderBookingEffect(action) {
  try {
    const { loginState } = yield select((state) => state.ProfileReducers)
    const nDivisionID = loginState[0]?.nDivisionID || 0
    const vendorId = sessionStorage.getItem('vendorId')
    console.log("vendorId",nDivisionID , vendorId)

    if (nDivisionID === 4) {
      // yield instaMojoEffect(action)
      yield orderBookingEffect(action) //---------------------------------------------------------------- Razorpay ---------------------------------------------------------------------------------
    }
    else if (nDivisionID == 2 && vendorId == 4) {
      // yield orderBookingPaynimoEffect(action) //---------------------------------------------------------------- paynimo ---------------------------------------------------------------------------------
      yield orderBookingCashfreeNewEffect(action) // --------------------------------------new cashfree
    }


    else {

      yield orderBookingCashfreeEffect(action) //---------------------------------------------------------------- Cashfree ---------------------------------------------------------------------------------

    }
  } catch (e) {
  }
}

function* paymentEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.payment, action.payload);

    const { loginState } = yield select((state) => state.ProfileReducers)

    const nUserID = loginState[0]?.nID || 0
    const userRoleName = loginState[0]?.cRoleName

    const cartData = {
      "ACTION": "CHECKLIST",
      nUserID
    }
    yield put(clearCartWatcher(nUserID, cartData))
    action.navigate(`${GetRoutes(userRoleName)}/orderHistory`)
    yield put(updateAlert('Successfully order placed', 'success'));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
  }
}

function* insertFeedBackEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.insertFeedBack, action.payload);

    yield put(updateAlert('Thanks for your FeedBack', 'success'));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
  }
}

function* rateFeedBackEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.rateFeedBack, action.id, action.payload);

    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
  }
}

function* orderCancelEffect(action) {
  try {
    yield put(updateOpenLoader());
    const { ProfileReducers: { loginState } } = yield select((state) => state)

    const { nRoleID } = loginState[0] || {}
    // const nUserID = nID || 0
    const vendorId = sessionStorage.getItem('vendorId')

    if (nRoleID === 7) {
      yield orderCancelInternEffect(action.payload)
      yield put(updateFalseLoader());
    } else {
      yield call(Service.cancelOrder, action.payload);

      let data1 = { "nUserID": action.payload.nUserID, "cAction": "UpcomingOrder" , CVENDORID : vendorId }
      yield put(upcomingOrderWatcher(data1));
      yield put(updateAlert("Order cancelled successfully", 'success'));
      yield put(updateFalseLoader());
    }
  } catch (e) {
    yield put(updateAlert(e.response.data?.slice(0, 50) || 'Something Went Wrong!', 'error'));
    yield put(updateFalseLoader());
  }
}

function* orderCancelInternEffect(payload) {
  try {
    const vendorId = sessionStorage.getItem('vendorId')
    // console.log('action', payload);
    yield put(updateOpenLoader());
    yield call(Service.cancelOrderIntern, payload);

    if (payload.orderHistory) {
      let data1 = { "nUserID": payload.nUserID, "cAction": "History" , CVENDORID : vendorId  }
      yield put(orderHistoryWatcher(data1));
    } else {
      let data1 = { "nUserID": payload.nUserID, "cAction": "UpcomingOrder",CVENDORID : vendorId }
      yield put(upcomingOrderWatcher(data1));
    }

    yield put(updateAlert("Order cancelled successfully", 'success'));
    yield put(updateFalseLoader());

    // let data1 = { "nUserID": payload.nUserID, "cAction": "History", CVENDORID : vendorId }
    // yield put(upcomingOrderWatcher(data1));
    // yield put(updateAlert("Order cancelled successfully", 'success'));
    // yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateAlert(e.response.data?.slice(0, 50) || 'Something Went Wrong!', 'error'));
    yield put(updateFalseLoader());
  }
}

function* orderCancelPaytmEffect(action) {
  try {
    const vendorId = sessionStorage.getItem('vendorId')
    yield put(updateOpenLoader());
    yield call(Service.cancelOrderPaytm, action.payload);

    if (action.payload.orderHistory) {
      let data1 = { "nUserID": action.payload.nUserID, "cAction": "History", CVENDORID : vendorId }
      yield put(orderHistoryWatcher(data1));
    } else {
      let data1 = { "nUserID": action.payload.nUserID, "cAction": "UpcomingOrder" , CVENDORID : vendorId }
      yield put(upcomingOrderWatcher(data1));
    }

    yield put(updateAlert("Order cancelled successfully", 'success'));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateAlert(e.response.data?.slice(0, 50) || 'Something Went Wrong!', 'error'));
    yield put(updateFalseLoader());
  }
}

function* orderCancelCashfreeEffect(action) {
  try {
    yield put(updateOpenLoader());

    const { ProfileReducers: { loginState } } = yield select((state) => state)

    const { nRoleID } = loginState[0] || {}
    // const nUserID = nID || 0
    const vendorId = sessionStorage.getItem('vendorId')

    if (nRoleID === 7) {
      yield orderCancelInternEffect(action.payload)
      yield put(updateFalseLoader());
    } else {
      yield call(Service.cancelOrderCashfree, action.payload);
      // yield call(Service.cancelOrderPaynimo, action.payload)

      if (action.payload.orderHistory) {
        let data1 = { "nUserID": action.payload.nUserID, "cAction": "History" , CVENDORID : vendorId  }
        yield put(orderHistoryWatcher(data1));
      } else {
        let data1 = { "nUserID": action.payload.nUserID, "cAction": "UpcomingOrder",CVENDORID : vendorId }
        yield put(upcomingOrderWatcher(data1));
      }

      yield put(updateAlert("Order cancelled successfully", 'success'));
      yield put(updateFalseLoader());
    }
  } catch (e) {
    const getmsg = typeof e.response?.data === 'object' ? e.response?.data?.code : e.response?.data?.slice(0, 50)
    yield put(updateAlert(getmsg || 'Something Went Wrong!', 'error'));
    yield put(updateFalseLoader());
  }
}


function* orderCancelPaynimoEffect(action) {
  try {
    yield put(updateOpenLoader());

    const { ProfileReducers: { loginState } } = yield select((state) => state)

    const { nRoleID } = loginState[0] || {}
    // const nUserID = nID || 0
    const vendorId = sessionStorage.getItem('vendorId')

    if (nRoleID === 7) {
      yield orderCancelInternEffect(action.payload)
      yield put(updateFalseLoader());
    } else {
      // yield call(Service.cancelOrderCashfree, action.payload);
      yield call(Service.cancelOrderPaynimo, action.payload)

      if (action.payload.orderHistory) {
        let data1 = { "nUserID": action.payload.nUserID, "cAction": "History" , CVENDORID : vendorId  }
        yield put(orderHistoryWatcher(data1));
      } else {
        let data1 = { "nUserID": action.payload.nUserID, "cAction": "UpcomingOrder",CVENDORID : vendorId }
        yield put(upcomingOrderWatcher(data1));
      }

      yield put(updateAlert("Order cancelled successfully", 'success'));
      yield put(updateFalseLoader());
    }
  } catch (e) {
    const getmsg = typeof e.response?.data === 'object' ? e.response?.data?.code : e.response?.data?.slice(0, 50)
    yield put(updateAlert(getmsg || 'Something Went Wrong!', 'error'));
    yield put(updateFalseLoader());
  }
}
function* dynamicOrderCancelEffect(action) {
  try {
    const { loginState } = yield select((state) => state.ProfileReducers)
    const nDivisionID = loginState[0]?.nDivisionID || 0
    const vendorId = sessionStorage.getItem('vendorId')

    if (nDivisionID === 4) {
      yield orderCancelEffect(action)
    } 
    else if(nDivisionID == 2 && vendorId == 4  ){
      yield orderCancelPaynimoEffect(action)
      
      }
    else {
      // yield orderCancelCashfreeEffect(action)

      yield orderCancelCashfreeEffect(action) //---------------------------------------------------------------- Cashfree ---------------------------------------------------------------------------------
    }
  } catch (e) {
  }
}

function* userOrderHistoryCancelEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.cancelOrderHistory, action.payload);
    const vendorId = sessionStorage.getItem('vendorId')
    let data1 = { "nUserID": action.payload.nUserID, "cAction": "History" ,CVENDORID : vendorId }
    yield put(orderHistoryWatcher(data1));
    yield put(updateAlert("Order cancelled successfully", 'success'));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateAlert(e.response.data?.slice(0, 50) || 'Something Went Wrong!', 'error'));
    yield put(updateFalseLoader());
  }
}

function* verifyPaytmEffect(action) {
  try {
    yield put(updateOpenLoader());
    yield call(Service.verifyPaytm, action.payload);

    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateAlert("Something Went Wrong!", 'error'));
    yield put(updateFalseLoader());
  }
}



function* paymentInternEffect(action,navigate) {

  // const { ProfileReducers: { loginState }, UserHomeReducers: { cafeteriaId } } = yield select((state) => state)

  // const { nID } = loginState[0] || {}
  // const nUserID = nID || 0
// console.log("nUserID",nUserID)
// const vendorId = sessionStorage.getItem('vendorId')

//  const payload = {
//   nOrderID: action?.order_tags.OrderNID  ,
//   cOrderID: action?.order_tags.OrderID,
//   nTotalRate: action?.order_amount,
//  cPaymentStatus: 'Y',
//  cPaymentDesc: 'Success',
//  cBankTrackingID: "",
//   cReferenceID: "",
//   nUserID:nUserID,
//  nCafeteriaId: cafeteriaId,
//  CVENDORID: `${vendorId}`
// };



  try {
    yield put(updateOpenLoader());
    yield call(Service.payment, action);

    const { loginState } = yield select((state) => state.ProfileReducers)

    const nUserID = loginState[0]?.nID || 0
    const userRoleName = loginState[0]?.cRoleName

    const cartData = {
      "ACTION": "CHECKLIST",
      nUserID
    }
    yield put(clearCartWatcher(nUserID, cartData))
    navigate(`${GetRoutes(userRoleName)}/orderHistory`)
    yield put(updateAlert('Successfully order placed', 'success'));
    yield put(updateFalseLoader());
  } catch (e) {
    yield put(updateFalseLoader());
  }
}

function* instaMojoEffect(action) {
//   try {
//     yield put(updateOpenLoader());
//     const { loginState } = yield select((state) => state.ProfileReducers)
//     const { nID } = loginState[0] || {}
//     const nUserID = nID || 0

//     let { data } = yield call(Service.orderBooking, action.payload);
//     const { amount, id: order_id, currency, notes, status } = data;

//     const cartData = {
//       "ACTION": "CHECKLIST",
//       nUserID
//     }
//     yield put(clearCartWatcher(nUserID, cartData))
//     // action.navigate(`${GetRoutes(cRoleName)}/orderHistory`)
//     // yield put(updateAlert('Successfully order placed', 'success'));
//     yield put(updateFalseLoader());

//     var headers = { 'X-Api-Key': 'test_8e0a68c51fcbc7af49d68390afe', 'X-Auth-Token': 'test_8d8734b1cb2a791b7c2b4704cec' }
//     var payload = {
//       purpose: 'FIFA 16',
//       amount: '2500',
//       phone: '9361138715',
//       buyer_name: 'karan',
//       redirect_url: 'https://docs.instamojo.com/v1.1/reference/create-a-request',
//       send_email: true,
//       webhook: 'http://www.example.com/webhook/',
//       send_sms: true,
//       email: 'mkaran71999@gmail.com',
//       allow_repeated_payments: false
//     }
//     // let { response } = yield call(`https://www.instamojo.com/api/1.1/payment-requests/`, { form: payload, headers: headers });
//     // console.log('response', response);
//     // if (response.data) {
//     //   window.location.href = response.data.payment_request.longurl;
//     // }

//     // const response = yield call(() => {
//     //   return axios.post(
//     //     `https://www.instamojo.com/api/1.1/payment-requests/`,
//     //     {form: payload,  headers: headers}
//     //   );
//     // });

// //     const encodedParams = new URLSearchParams();
// // encodedParams.set('allow_repeated_payments', 'true');
// // encodedParams.set('send_email', 'false');
// // encodedParams.set('send_sms', 'false');

// //     const options = {
// //       method: 'POST',
// //       url: 'https://www.instamojo.com/api/1.1/payment-requests/',
// //       headers: {
// //         accept: 'application/json',
// //         'content-type': 'application/json', // Set content type to JSON
// //         'X-Api-Key': 'test_8e0a68c51fcbc7af49d68390afe',
// //         'X-Auth-Token': 'test_8d8734b1cb2a791b7c2b4704cec'
// //       },
// //       data: encodedParams, // Set the payload here
// //     };
    
// //     axios
// //       .request(options)
// //       .then(function (response) {
// //         console.log(response.data);
// //       })
// //       .catch(function (error) {
// //         console.error(error);
// //       });

//         try {
//       const payload = {
//         purpose: 'FIFA 16',
//         amount: '2500',
//         phone: '9361138715',
//         buyer_name: 'karan',
//         redirect_url: 'https://docs.instamojo.com/v1.1/reference/create-a-request',
//         send_email: true,
//         webhook: 'http://www.example.com/webhook/',
//         send_sms: true,
//         email: 'mkaran71999@gmail.com',
//         allow_repeated_payments: false
//       };

//       // Make a POST request to Instamojo API to create a payment link
//       const response = axios.post(
//         'https://api.instamojo.com/v2/payment_links/',
//         payload,
//         {
//           headers: {
//             'X-Api-Key': 'test_8e0a68c51fcbc7af49d68390afe',
//             'X-Auth-Token': 'test_8d8734b1cb2a791b7c2b4704cec',
//           },
//         }
//       );

//       // Extract the payment URL from the response
//       const { longurl } = response.data;

//       // Redirect the user to the payment page
//       window.location.href = longurl;
//     } catch (error) {
//       console.error('Error initiating payment:', error);
//     }

//     // if (response.data) {
//     //   window.location.href = response.data.payment_request.longurl;
//     // }

//     yield put(updateFalseLoader());
//   } catch (e) {
//     yield put(updateAlert("Something Went Wrong!", 'error'));
//     yield put(updateFalseLoader());
//   }
}

function handleResponseEffect(res) {
  console.log(res);
    if (
      typeof res != "undefined" &&
      typeof res.paymentMethod != "undefined" &&
      typeof res.paymentMethod.paymentTransaction != "undefined" &&
      typeof res.paymentMethod.paymentTransaction.statusCode != "undefined" &&
      res.paymentMethod.paymentTransaction.statusCode == "0300"
    ) {
      console.log('successRes',res)
      // yield put(updateOpenLoader());
      let verifyPaynimoData = {
        "response" : res.merchantResponseString
      }
      let verifyData = Service.verifyPaynimo(verifyPaynimoData)
      console.log('resDataaa',verifyData)

    } else if (
      typeof res != "undefined" &&
      typeof res.paymentMethod != "undefined" &&
      typeof res.paymentMethod.paymentTransaction != "undefined" &&
      typeof res.paymentMethod.paymentTransaction.statusCode != "undefined" &&
      res.paymentMethod.paymentTransaction.statusCode == "0398"
    ) {
      console.log('initiRes',res)
    } else {
      console.log('errorRes',res)
    }
}

function* orderBookingPaynimoEffect(action) {
  const { ProfileReducers: { loginState }, UserHomeReducers: { cafeteriaId } } = yield select((state) => state)
  const { cEmployeeName, cEmailID, cMobileNumber, nRoleID, nID,nDivisionID } = loginState[0] || {}
  const vendorId = sessionStorage.getItem('vendorId')
  try {
    yield put(updateOpenLoader());

    let { data } = yield call(Service.orderBookingPaynimo, {...action.payload, cEmailID, cMobileNumber, cEmployeeName, cafeteriaId, CVENDORID : `${vendorId}`});
    console.log("jshdbcud",data)
    console.log('empData',cEmployeeName, cEmailID, cMobileNumber, nRoleID, nID)

    if(nRoleID === 7){
      const nUserID = nID || 0
      const payload = {
        nOrderID: data?.consumerData.OrderNID  ,
        cOrderID: data?.consumerData.OrderID,
        nTotalRate: data?.consumerData.amount,
       cPaymentStatus: 'Y',
       cPaymentDesc: 'Success',
       cBankTrackingID: "",
        cReferenceID: "",
        nUserID:nUserID,
       nCafeteriaId: nDivisionID,
       CVENDORID: `${vendorId}`
      };
      yield paymentInternEffect(payload,action.navigate)      
    }

    else{
      // Getting the order details back
      var reqJson = {
        features: {
          enableAbortResponse: true,
          enableExpressPay: true,
          enableInstrumentDeRegistration: true,
          enableMerTxnDetails: true,
          enableNewWindowFlow: true, //for hybrid applications please disable this by passing false
        },
        consumerData: {
          deviceId: "WEBSH2", //possible values "WEBSH1" or "WEBSH2"
          token: data.token,
          // returnUrl:"https://pgproxyuat.in.worldline-solutions.com/linuxsimulator/MerchantResponsePage.jsp",
          // returnUrl: `http://localhost:3000/${GetRoutes(userRoleName)}/orderHistory`, 
          // returnUrl: "",

          // responseHandler: handleResponseEffect,

          responseHandler : async function (res) {
            if (
              typeof res != "undefined" &&
              typeof res.paymentMethod != "undefined" &&
              typeof res.paymentMethod.paymentTransaction != "undefined" &&
              typeof res.paymentMethod.paymentTransaction.statusCode != "undefined" &&
              res.paymentMethod.paymentTransaction.statusCode == "0300"
            ) {
              console.log('successRes',res)
              // yield put(updateOpenLoader());
              if(res.merchantResponseString){
                try{
                  let verifyPaynimoData = {
                    "response" : res.merchantResponseString
                  }
                  let verifyData = await Service.verifyPaynimo(verifyPaynimoData)
                  console.log('resDataaa',verifyData)
                  
                  if(verifyData.data.merchantTransactionIdentifier){
                    const payload = 
                    {
                      "nOrderID": data.consumerData.OrderNID,
                      "cOrderID": data.consumerData.OrderID,
                      "nTotalRate": data.consumerData.amount,
                      "cPaymentStatus": "Success",
                      "cPaymentDesc": "Success",
                      "cBankTrackingID": data.consumerData.OrderID,
                      "cReferenceID": verifyData.data.merchantTransactionIdentifier,
                      "nUserID": nID,
                      "nCafeteriaId": cafeteriaId,
                      "CVENDORID": `${vendorId}`
                    }
              
                    action.dispatch(paymentWatcher(payload, action.navigate))
                  }
                  else{
                    updateFalseLoader();
                    updateAlert('Something Went Wrong!', 'error');
                  }
                }
                catch (e) { 
                  updateFalseLoader();
                  updateAlert('Something Went Wrong!', 'error');
                } 
              }
              else{
                updateFalseLoader();
                updateAlert('Something Went Wrong!', 'error');
              }
        
            } 
            else if (
              typeof res != "undefined" &&
              typeof res.paymentMethod != "undefined" &&
              typeof res.paymentMethod.paymentTransaction != "undefined" &&
              typeof res.paymentMethod.paymentTransaction.statusCode != "undefined" &&
              res.paymentMethod.paymentTransaction.statusCode == "0398") 
            {
              console.log('initiRes',res)
            } 
            else {
              console.log('errorRes',res)
            }
          },

          paymentMode: "all",
          merchantLogoUrl:
            "https://www.paynimo.com/CompanyDocs/company-logo-vertical.png", //provided merchant logo will be displayed
          merchantId: data.consumerData.merchantId,
          currency: "INR",
          consumerId: data.consumerData.consumerId,
          consumerMobileNo: data.consumerData.consumerMobileNo,
          consumerEmailId: data.consumerData.consumerEmailId,
          txnId: data.consumerData.txnId,
          items: [
            {
              itemId: "first",
              amount: data.consumerData.amount,
              comAmt: "0",
            },
          ],
          customStyle: {
            PRIMARY_COLOR_CODE: "#45beaa", //merchant primary color code
            SECONDARY_COLOR_CODE: "#FFFFFF", //provide merchant's suitable color code
            BUTTON_COLOR_CODE_1: "#2d8c8c", //merchant's button background color code
            BUTTON_COLOR_CODE_2: "#FFFFFF", //provide merchant's suitable color code for button text
          },
        },
      };
  
      if (window.$checkout && typeof window.$checkout.pnCheckout === "function") {
        window.$checkout.pnCheckout(reqJson);
  
        console.log("reqJson.features.enableNewWindowFlow",reqJson.features.enableNewWindowFlow);
  
        console.log("window.pnCheckoutShared",window.pnCheckoutShared.openNewWindow);
  
        // Check and open a new window if necessary
        if (reqJson.features.enableNewWindowFlow && window.pnCheckoutShared &&
          typeof window.pnCheckoutShared.openNewWindow === "function") 
        {
          window.pnCheckoutShared.openNewWindow();
        }
      } 
      else {
        console.error("Error: pnCheckout function not available");
      }
    }    
    yield put(updateFalseLoader());
    
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert('Something Went Wrong!', 'error'));
  }
}

//saga 
export const currentOrderSaga = [
  takeEvery(CURRENT_ORDER_WATCHER, currentOrderEffect),
  takeEvery(ORDER_HISTORY_WATCHER, orderHistoryEffect),
  takeEvery(NEXT_DAY_ORDER_WATCHER, nextdayOrderEffect),
  takeEvery(NEXT_WEEK_ORDER_WATCHER, nextweekOrderEffect),
  takeEvery(DELIVERED_ORDER_WATCHER, deliveredOrderEffect),
  takeEvery(UPCOMING_ORDER_WATCHER, upcomingOrderEffect),
  takeEvery(ORDER_BOOKING_WATCHER, dynamicOrderBookingEffect),
  takeEvery(PAYMENT_WATCHER, paymentEffect),
  takeEvery(ORDER_BOOKING_PAYNIMO_WATCHER, orderBookingPaynimoEffect),

  takeEvery(INSERT_FEEDBACK_WATCHER, insertFeedBackEffect),
  takeEvery(RATE_FEEDBACK_WATCHER, rateFeedBackEffect),
  takeEvery(USER_ORDER_CANCEL_WATCHER, dynamicOrderCancelEffect),
  takeEvery(USER_ORDER_HISTORY_CANCEL_WATCHER, userOrderHistoryCancelEffect),
  takeEvery(VERIFY_PAYTM_WATCHER, verifyPaytmEffect),
  takeEvery(INSTA_MOJO_PAYMENT, instaMojoEffect),
]
import { BOOKING_HISTORY_LIST, CONTRACTOR_DASHBOARD_DATA, CROLE_SWIPE_HISTORY_LIST } from "../../actionTypes/contractor";

const intialState = {
  bookingHistoryList: [],
  contractorDashboardData: [],
  CRoleSwipeHistoryList: [],
};

export default function ContractorRoleReducers(state = intialState, action) {
  switch (action.type) {

    case BOOKING_HISTORY_LIST:
      return { ...state, bookingHistoryList: action.payload };

    case CONTRACTOR_DASHBOARD_DATA:
      return { ...state, contractorDashboardData: action.payload };

    case CROLE_SWIPE_HISTORY_LIST:
      return { ...state, CRoleSwipeHistoryList: action.payload };

    default:
      return state;
  }
}

import React from "react";
import { Typography, Grid } from "@mui/material";
import Notify from "../../../components/admin/dynamicNotify/notify";
import Breadcrumbs from "../../../components/breadcrumbs"

export default function DynamicNotification() {
  return (
    <>
      <Grid container style={{ padding: "24px" }}>
        <Breadcrumbs content={["Portal Design ", "Schedule Notification"]} />
        <Grid item mt="8px" xs={12}>
          <Typography variant="t24_700">Schedule Notification</Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "20px" }}>
          <Notify />
        </Grid>
      </Grid>
    </>
  );
}

import { SELECTED_ITEMS, MENU_LIST_WATCHER, UPDATE_CATEGORY, UPDATE_MENUS, ADD_CART_WATCHER, GET_CART_WATCHER, ADD_MINUS_WATCHER, UPDATE_CART, CLEAR_CART_WATCHER, GET_DAYSOFWEEK_WATCHER, UPDATE_DAYSOFWEEK, GET_ORDER_TOTALCOST_WATCHER, UPDATE_ORDER_TOTALCOST, UPDATE_VIEW_CART, VIEW_CART_WATCHER, WEEKLY_BOOKING_WATCHER, UPDATE_WEEKLY_BOOKING, KIOSK_WATCHER, UPDATE_KIOSK, MEAL_LIST_WATCHER, CAFE_MENUS_WATCHER, UPDATE_CAFETERIA_ID, ADD_QUANTITY_WATCHER } from "../../actionTypes/user";

// Worker triggering actionCreators
export function getMenuListWatcher(data) {
    return { type: MENU_LIST_WATCHER, payload: data };
}

export function getMealListWatcher(data) {
    return { type: MEAL_LIST_WATCHER, payload: data };
}

export function addCartWatcher(data, cartData, userId) {
    return { type: ADD_CART_WATCHER, payload: data, cartData, userId };
}

export function getCartWatcher(data, viewCart) {
    return { type: GET_CART_WATCHER, payload: data, viewCart };
}

export function addMinusWatcher(id, data, cartData, userId) {
    return { type: ADD_MINUS_WATCHER, payload: data, id, cartData, userId };
}

export function addQuantityWatcher(id, data, cartData, userId, setOpenPopup, setNewQty) {
    return { type: ADD_QUANTITY_WATCHER, payload: data, id, cartData, userId, setOpenPopup, setNewQty };
}

export function clearCartWatcher(id, cartData) {
    return { type: CLEAR_CART_WATCHER, id, cartData };
}

export function getDaysOfWeekWatcher(data) {
    return { type: GET_DAYSOFWEEK_WATCHER, data };
}

export function getOrderTotalCostWatcher(id) {
    return { type: GET_ORDER_TOTALCOST_WATCHER, id };
}

export function viewCartWatcher(data) {
    return { type: VIEW_CART_WATCHER, data };
}

export function weeklyBookingWatcher(data) {
    return { type: WEEKLY_BOOKING_WATCHER, data };
}

export function kioskWatcher(data) {
    return { type: KIOSK_WATCHER, data };
}

export function cafeMenusWatcher(data) {
    return { type: CAFE_MENUS_WATCHER, data };
}


// Redux state changing actionCreators
export function changeSelectedItems(data) {
    return { type: SELECTED_ITEMS, payload: data };
}

export function updateCategory(data) {
    return { type: UPDATE_CATEGORY, payload: data };
}

export function updateMenuList(data) {
    return { type: UPDATE_MENUS, payload: data };
}

export function updateCartList(data) {
    return { type: UPDATE_CART, payload: data };
}

export function updateDaysOfWeekList(data) {
    return { type: UPDATE_DAYSOFWEEK, payload: data };
}

export function updateGetOrderTotalCost(data) {
    return { type: UPDATE_ORDER_TOTALCOST, payload: data };
}

export function updateViewCart(data) {
    return { type: UPDATE_VIEW_CART, payload: data };
}

export function updateWeeklyBooking(data) {
    return { type: UPDATE_WEEKLY_BOOKING, payload: data };
}

export function updateKiosk(data) {
    return { type: UPDATE_KIOSK, payload: data };
}

export function updateCafeteriaId(data) {
    return { type: UPDATE_CAFETERIA_ID, payload: data };
}

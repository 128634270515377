import { OPEN_LOADER, FALSE_LOADER } from "../../actionTypes/spinner";

// Worker triggering actionCreators


// Redux state changing actionCreators

export function updateOpenLoader() {
    return { type: OPEN_LOADER, payload: true };
}

export function updateFalseLoader() {
    return { type: FALSE_LOADER, payload: false };
}

import React from "react";
import { useState } from "react";
import Scrollbar from "../../../Scrollbar";
import UserHistoryTableHead from "./tableHead";
import { useSelector } from "react-redux";
// material
import {
  Table,
  TableBody,
  TableContainer,
  Pagination,
  Box,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

export const SUBTABLE_HEAD = [
  {
    id: "cMealName",
    label: "Meal Name",
    alignRight: false,
    title: "Meal Name",
    field: "cMealName",
  },
  {
    id: "cMenuName",
    label: "Menu Name",
    alignRight: false,
    title: "Menu Name",
    field: "cMenuName",
  },
  {
    id: "cMenuDesc",
    label: "Menu Desc",
    alignRight: false,
    title: "Menu Desc",
    field: "cMenuDesc",
  },
  {
    id: "nQuantity",
    label: "Quentity",
    alignRight: false,
    title: "Quentity",
    field: "nQuantity",
  },
  {
    id: "OrderDate",
    label: "Order Date",
    alignRight: false,
    title: "Order Date",
    field: "OrderDate",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const GuestHistorySubTable = () => {
  const {
    ReportsReducers: { GuestHistoryViewList },
  } = useSelector((state) => state);

  let firstIndex = 0;

  // const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [rowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filteredData = applySortFilter(
    GuestHistoryViewList,
    getComparator(order, orderBy)
  );
  const isUserNotFound = filteredData?.length === 0;

  return (
    <>
      <Box>
        <Scrollbar>
          <TableContainer sx={{ overflow: "unset" }}>
            <Table>
              <UserHistoryTableHead
                order={order}
                orderBy={orderBy}
                headLabel={SUBTABLE_HEAD}
                onRequestSort={handleRequestSort}
                action={false}
              />
              <TableBody>
                {filteredData
                  ?.slice(
                    firstIndex + rowsPerPage * (page - 1),
                    rowsPerPage * page
                  )
                  // .slice(page * 5, page * 5 + 5)
                  .map((rowData, i) => {
                    return (
                      <TableRow key={i} hover tabIndex={-1}>
                        {/* <TableCell align="left">
                          <Typography variant="t14_600">
                            {rowData.EmployeeName}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="t14_600">
                            {rowData.UserName}
                          </Typography>
                        </TableCell> */}
                        <TableCell align="left">
                          <Typography variant="t14_600">
                            {rowData.cMealName}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="t14_600">
                            {rowData.cMenuName}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="t14_600">
                            {rowData.cMenuDesc}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="t14_600">
                            {rowData.nQuantity}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="t14_600">
                            {rowData.OrderDate}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow display="flex">
                    <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                      <Typography variant="t18_500">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Box>
      <Box spacing={2} display="flex" justifyContent="right" paddingTop="20px">
        <Pagination
          shape="rounded"
          color="primary"
          count={Math.ceil(filteredData?.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </>
  );
};

export default GuestHistorySubTable;

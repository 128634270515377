import React from 'react'
import { Typography, Grid } from "@mui/material";
import OrderSummary from './orderSummary';
// import EmployeeManagement from '../userManagement/employeeManagementTemp';
// import ContractorManagement from '../userManagement/contractorManagementTemp';
import { useSelector } from 'react-redux';

function AdminDashboard() {
    const { ProfileReducers: { loginState } } = useSelector(state => state)
    const cEmployeeName = loginState[0]?.cEmployeeName || ''

    return (
        <>
        <Grid container style={{padding:'24px'}}>

            <Grid item xs={12}>
                <Typography variant='t12_400' >Dashboard</Typography>
            </Grid>
            <Grid item mt='8px' xs={12}>
                <Typography variant='t24_700'>{`Welcome, ${cEmployeeName}`}</Typography>
            </Grid>
            <Grid item xs={12} style={{paddingTop:'20px'}}>
                <OrderSummary />
            </Grid>
            {/* <Grid item xs={12} style={{paddingTop:'20px'}}>
                <EmployeeManagement />
            </Grid>
            <Grid item xs={12} style={{paddingTop:'20px'}}>
                <ContractorManagement />
            </Grid> */}
            </Grid>
        </>
    )
}
// lg={} md={} sm ={} xs={}
// lg={} md={} sm ={} xs={}
export default AdminDashboard;
import React, { useState } from 'react'
import { Box, Typography, Card } from '@mui/material'
import Breadcrumbs from '../../../components/breadcrumbs'
import PushNotificationHeader from '../../../components/admin/reports/pushNotification/pushNotifyHeader'
import PushNotificationTable from '../../../components/admin/reports/pushNotification/notifyTable'
import { useSelector } from 'react-redux';

export default function PushNotification() {

    const { ReportsReducers: { PushNotificationList } } = useSelector((state) => state)

    // const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState([])
    // const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearchText(val);
        const searchRegex = new RegExp(escapeRegExp(val), 'i');
        const filteredRows = PushNotificationList.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });
        setSearchVal(filteredRows);
        setPage(1)
    }

    return (
        // <>
            <Box p='24px'>

                <Breadcrumbs content={["Reports", "Push Notification"]} />

                <Box mt='14px' mb='8px' display='flex' alignItems='center'>
                    <Typography variant="t24_700"> Push Notification</Typography>
                </Box>
                <Box p='10px 0'>
                    <PushNotificationHeader handleSearch={handleSearch} searchText={searchText} />
                </Box>
                <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
                    <PushNotificationTable page={page} setPage={setPage} searchText={searchText} searchVal={searchVal} />
                </Card>
            </Box>
        // </>
    )
}

import { CAROUSEL_IMAGE_LIST, FOOD_WASTAGE_LIST, USER_CAROUSEL_IMAGE_LIST, FREEZE_BOOKING_LIST, SPECIAL_MENU_LIST } from "../../actionTypes/admin";

const intialState = {
    CarouselImageList: [],
    userCarouselImageList: [],
    foodWastage: [],
    freezeBooking: [],
    specialMenu: []
};

export default function CarouselReducers(state = intialState, action) {
    switch (action.type) {

        case CAROUSEL_IMAGE_LIST:
            return { ...state, CarouselImageList: action.payload };
        case USER_CAROUSEL_IMAGE_LIST:
            return { ...state, userCarouselImageList: action.payload };
        case FOOD_WASTAGE_LIST:
            return { ...state, foodWastage: action.payload };
        case FREEZE_BOOKING_LIST:
            return { ...state, freezeBooking: action.payload };
        case SPECIAL_MENU_LIST:
            return { ...state, specialMenu: action.payload };

        default:
            return state;
    }
}

import axios from 'axios';

const { REACT_APP_FIREBASE_KEY } = process.env;

export const sendNtfy = (title, body) => {
  const data = {
    "to": "/topics/all",
    "notification": {
      "title": title,
      "body": body
    }

  };

  axios({
    method: 'post',
    url: 'https://fcm.googleapis.com/fcm/send',
    data: data,
    headers: {
      Authorization: `key=${REACT_APP_FIREBASE_KEY}`,
      'Content-Type': 'application/json',
    },
  })
    .then(function () {
      //handle success
    })
    .catch(function () {
      //handle error
    });
};

export const sendNtfySingle = (title, body, token) => {
  const data = {
    "to": token,
    "notification": {
      "title": title,
      "body": body
    }

  };

  axios({
    method: 'post',
    url: 'https://fcm.googleapis.com/fcm/send',
    data: data,
    headers: {
      Authorization: `key=${REACT_APP_FIREBASE_KEY}`,
      'Content-Type': 'application/json',
    },
  })
    .then(function () {
      //handle success
    })
    .catch(function () {
      //handle error
    });
};
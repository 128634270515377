import { Remove, Add } from "@mui/icons-material";
import { Button, ButtonGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { addCartWatcher, addMinusWatcher } from "../../../redux/actionCreators/user/home";
import { useDispatch, useSelector } from 'react-redux';
import { getConvertedDate2, getCurDate } from "../../../utils/common";
import { base_url } from "../../../http-common";
import CustTypo from "../../overrides/CustTypo";

const ImageTemp = ({ value, selectedDate, isViewCart, kiosk,activeMealName }) => {

  const { cImage, cMenuName, nTotalRate, nID, cMenuDesc } = value || {};

  const { UserHomeReducers: { cartList, cafeteriaId ,categoryList: multiCat}, ProfileReducers: { loginState } } = useSelector(state => state)
  const dispatch = useDispatch()

  // console.log("cartList",cartList,value)

  let alreadyExist = cartList.some(v=>v?.cMealName == activeMealName)



  // console.log("alreadyExist",alreadyExist,activeMealName)
  
  const getDate = selectedDate?.formatDate

  const nUserID = loginState[0]?.nID || 0

  const roleName = loginState[0]?.cRoleName;

  const nDivisionID = loginState[0]?.nDivisionID || 0;

 

  const addToCart = () => {
    const data = {
      "ACTION": "INSERT",
      nUserID,
      "nMenuID": `${nID}`,
      "dOrderDate": kiosk ? getCurDate() : getDate,
      "nCafeteriaId" : 0
    }
    const cartData = {
      "ACTION": "CHECKLIST",
      nUserID
    }
    dispatch(addCartWatcher(data, cartData, nUserID))
  }

  const addMinusApi = (type) => {
    const data = {
      "ACTION": type
    }
    const cartData = {
      "ACTION": "CHECKLIST",
      nUserID
    }
    dispatch(addMinusWatcher(getCount.NID, data, cartData, nUserID))
  }

  const filterList = cartList.filter(d => getDate === getConvertedDate2(new Date(d.dOrderDate)))

  const getCartList = isViewCart ? cartList : filterList
  const getCount = getCartList.find(d => d.nMenuID === nID) || {}

  return (
    <>
    <Box width= "100%">
      <img
        style={{
          width: "100%",
          borderRadius: 12,
          height: 195,
          objectFit: "cover",
        }}
        src={`${base_url}/${cImage}`}
        alt={cImage}
      />
    </Box>

      <Box display="flex" height='100%' >
        <Box display='flex' width='100%' flexDirection='column' >
          <Typography mt="16px" variant="t16_500">
            {typeof cMenuName === 'object' ? cMenuName[0] : cMenuName}
          </Typography>
          <CustTypo mt="5px" mb='5px' color='grey.500' variant="t16_400">
            {cMenuDesc}
          </CustTypo>


          <Box mt='auto' display='flex'>

            <Typography mt='auto' variant="t24_700">
              ₹{nTotalRate}
            </Typography>

            <Box ml="auto" mt='auto'>
              {!getCount.nQuantity ? (
                <Button
                  onClick={() => { addToCart() }}
                  sx={{ minWidth: 48, height: 48 }}
                  variant="contained"
                  disabled={
                    roleName === "NewJoiners" ? ( alreadyExist ) : false
                  }
                >
                  <Add />
                </Button>
              ) : (
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                  sx={{
                    height: 48,
                    "& .MuiButtonGroup-grouped": {
                      minWidth: 38,
                    },
                  }}
                >
                  <Button onClick={() => { addMinusApi("MINUS") }} sx={{ p: 0 }}>
                    <Remove />
                  </Button>
                  <Button sx={{ p: 0 }}>
                    <Typography variant="t24_700">{getCount.nQuantity}</Typography>
                  </Button>
                  <Button onClick={() => { addMinusApi("ADD") }} sx={{ p: 0 }}
                  disabled={
                    roleName === "NewJoiners" ? (getCount.nQuantity == 1 ? true : false) : false
                  }>
                    <Add />
                  </Button>
                </ButtonGroup>
              )}
            </Box>
          </Box>

        </Box>

      </Box>
    </>
  );
};

export default ImageTemp;

import React, { useEffect } from 'react';
import { Card, Grid, Button, InputAdornment, Stack, Autocomplete, Typography } from "@mui/material";
import SearchField from '../../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { List } from '../../../List';
// import BlackButton from '../../../overrides/blackButton'
import CssTextField from '../../../overrides/coloredTextField';
import { ExportCsv } from "@material-table/exporters";
import { getConvertedDate } from '../../../../utils/common';
import { getMealDropdownWatcher } from '../../../../redux/actionCreators/admin/menuMaster';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

const GRoleSwipeHistoryHeaderCard = ({ handleSearch, searchText, handleSubmitAction, rowData, columnData, selectedMealHandler, setFromDate, setToDate, toDate, fromDate, setSelectedMeal, selectedMeal, invalidErr, setInvalidErr }) => {

    const dispatch = useDispatch()

    const [fieldErr, setFieldErr] = React.useState(false)
    const currentDate = new Date()
    const { MenuMasterReducers: { mealDropdownList, mealOnlyDropdownList } } = useSelector((state) => state);

    const formik = useFormik({
        initialValues: {
            selectMeal: '',
        },

        onSubmit: () => {
            // let values = { ...formik.values }
            // let data = {
            //     name: values.selectMeal,
            // }
        }
    });

    useEffect(() => {
        dispatch(getMealDropdownWatcher());
    }, [dispatch])

    const handleFromChange = (e) => {
        setFromDate(e)
        // setToDate(null)
        // if(fromDate <= toDate){
        //     setInvalidErr({ ...invalidErr, fromInvalidErr:false, toInvalidErr:false})
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr:true, toInvalidErr:true})
        // }
    }

    const handleToChange = (e) => {
        setToDate(e)
        // if(fromDate !== null){
        //     setToDate(e)
        //     setFieldErr(false)
        //     if(toDate <= fromDate){
        //         setInvalidErr({ ...invalidErr, fromInvalidErr:false, toInvalidErr:false})
        //     }
        //     else{
        //         setInvalidErr({ ...invalidErr, fromInvalidErr:true, toInvalidErr:true})
        //     }
        // }
        // else{
        //     setFieldErr(true)
        // }
    }

    const { setFieldValue } = formik;

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={2} sx={{ padding: '30px', display: 'flex' }} >
                    <Grid item lg={3} xs={12} md={3} sm={6} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px'
                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            onChange={handleSearch}
                            value={searchText}
                        />
                    </Grid>
                    <Grid item lg={6} xs={12} md={6} sm={6} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={fromDate}
                                    label='From Date'
                                    // maxDate={currentDate}
                                    onChange={handleFromChange}
                                    renderInput={(params) => <SearchField sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '48px'
                                            },
                                            '& label': {
                                                fontSize: '.9rem'
                                            }
                                        }} 
                                        fullWidth {...params} 
                                        error={invalidErr.fromInvalidErr === true ? true : false}
                                        helperText={invalidErr.fromInvalidErr === true ? 'Invalid Date' : ''}
                                    />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={fromDate !== null ? toDate : null}
                                    label='To Date'
                                    // minDate={fromDate}
                                    // maxDate={currentDate}
                                    onChange={handleToChange}
                                    renderInput={(params) => <SearchField sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '48px'
                                            },
                                            '& label': {
                                                fontSize: '.9rem'
                                            }
                                        }} 
                                        fullWidth {...params} 
                                        error={invalidErr.toInvalidErr === true ? true : fieldErr === true ? fromDate !== null ? false : true : false}
                                        helperText={invalidErr.toInvalidErr === true ? 'Invalid Date' : fieldErr === true ? fromDate !== null ? '' : 'Please Choose From Date First..!' : ''}
                                    />}
                                />
                            </LocalizationProvider>

                            {/* <Button variant='contained'
                                sx={{
                                    height: '48px',
                                    minWidth: '120px',
                                    backgroundColor: 'common.Bblack',
                                    '&:hover':{backgroundColor: 'common.Bblack'}
                                }} endIcon={<DoubleArrowIcon />} onClick={() => handleSubmitAction(getConvertedDate(fromDate), getConvertedDate(toDate))}
                            >
                                <Typography variant='t16_400_1'>Submit</Typography>
                            </Button> */}

                        </Stack>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12} sm={6}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            name="selectMeal"
                            fullWidth
                            size="medium"
                            autoHighlight
                            value={mealOnlyDropdownList.filter((f) => f.name === selectedMeal).map((d) => d)[0] || {name : ''}}
                            // options={mealDropdownList[0] || []}
                            options={mealOnlyDropdownList}
                            onChange={(event, value) => {
                                // setFieldValue("mealName", value.name);
                                // selectedMealHandler(value.name);
                                if(value.id !== 0){
                                    setSelectedMeal(value.name)
                                }
                                else{
                                    setSelectedMeal('Breakfast')
                                }
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <CssTextField sx={{
                                '& .MuiOutlinedInput-input': {
                                    height: '16px',
                                    color: 'common.white',
                                },
                                '& label': {
                                    // fontSize: '.9rem',
                                    color: 'common.white',
                                    top: -2
                                },
                                '& .MuiInputLabel-shrink': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    display: 'none'
                                }

                            }} {...params} fullWidth label="Select Meal" />}
                        />
                    </Grid>
                    <Grid item lg={3} xs={12} md={3} sm={6} sx={{ justifyContent: 'center'}}>
                        <Button variant='contained'
                            fullWidth
                            sx={{
                                height: '48px',
                                // minWidth: '120px',
                                backgroundColor: 'common.Bblack',
                                '&:hover':{backgroundColor: 'common.Bblack'}
                            }} endIcon={<DoubleArrowIcon />} onClick={() => handleSubmitAction(getConvertedDate(fromDate), getConvertedDate(toDate), setFieldErr(false))}
                        >
                            <Typography variant='t16_400_1'>Submit</Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12} sm={6}>
                        <Button variant='contained' sx={{ height: '50px' }} fullWidth endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(columnData, rowData, "Contractor swipe history")}>
                            <Typography variant='t16_500'>Export to Excel</Typography> 
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default GRoleSwipeHistoryHeaderCard;
import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getConvertedDate2 } from "../../../utils/common";
import { useDispatch } from "react-redux";
import { cafeMenusWatcher } from "../../../redux/actionCreators/user/home";

const BreakInterval = {
  Supper: "Diet plan | 2:30AM - 3:30AM",
  Breakfast: "Diet plan | 6AM - 9AM",
  Lunch: "Diet plan | 10:30AM - 2:00PM",
  Snacks: "Diet plan | 4PM - 5:30PM",
  Dinner: "Diet plan | 6:30PM - 9PM"
}

export default function CustomizedTabs({value, setValue}) {

  const { UserHomeReducers: { categoryList, cartList, weeklyBookingDates, cafeteriaId }, ProfileReducers: {loginState}} = useSelector((state) => state);

  const dispatch = useDispatch()
  const nUserID = loginState[0]?.nID || 0
  const tempinitsform = React.useRef();
  const tempinitform = React.useRef();

  const handleChange = (name) => {
    setValue(name);
  };

  const getCount = (cMealName) => {
    let count = 0
    weeklyBookingDates.forEach(d => {

      // const list = mealMenuList.map(d => d.nID)

      cartList.some(t => {
        if (t.cMealName === cMealName && getConvertedDate2(new Date(t.dOrderDate)) === d.Dates?.slice(0, 10)) {
          count += 1
          return true
        }
        return false
      })
    })
    return count
  }

  const initsform = () => {
    categoryList.length && setValue(categoryList[0].nID)
  };

  tempinitsform.current = initsform;

  React.useEffect(() => {
    tempinitsform.current();
  }, [categoryList]);

  // React.useEffect(()=>{
  //   categoryList.length && setValue(categoryList[0].nID)
  // },[categoryList])

  const initform = () => {
    if (value) {
      const data = {
        "ACTION": "MealSELECT",
        "NID": cafeteriaId,
        "MEALID": `${value}`,
        "UserId": nUserID
      }
      dispatch(cafeMenusWatcher(data))
    }
  };

  tempinitform.current = initform;

  React.useEffect(() => {
    tempinitform.current();
  }, [value, cafeteriaId]);

  // React.useEffect(() => {
  //   if (value) {
  //     const data = {
  //       "ACTION": "MealSELECT",
  //       "NID": cafeteriaId,
  //       "MEALID": `${value}`,
  //       "UserId": nUserID
  //     }
  //     dispatch(cafeMenusWatcher(data))
  //   }
  // }, [value, cafeteriaId])

  return (
    <Box sx={{ width: "100%", mt: "24px" }}>
      <Box sx={{ bgcolor: "#fff", borderRadius: "8px", padding: "10px 15px" }}>
        <Grid container spacing={2}>
          {categoryList.map((d, i) => (
            <Grid key={d.nID} item xs={12} sm={4} md={2.4}>
              <Box
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  p: '10px',
                  pt: '12px',
                  bgcolor: value === d.nID ? 'primary.main' : 'grey.200',
                  borderRadius: '6px',
                  color: value === d.nID ? 'common.white' : 'grey.600',
                  display: 'flex',
                  alignItems: 'center',
                  height:'100%'
                }}
                onClick={() => handleChange(d.nID)}
              >

                <Box display="flex" flexDirection="column" >
                  <Typography
                    variant="t14_700"
                    textTransform="uppercase"
                    className="TypographyHoverClass"
                  >
                    {d.cMealName}
                  </Typography>
                  <Typography variant="t12_400" mt="4px">
                    {BreakInterval[d.cMealName]}
                  </Typography>
                </Box>

                <Typography ml='auto' variant="t14_600" >
                {getCount(d.cMealName)}/7
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

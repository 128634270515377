import React from 'react';
import { Card, Grid, Button, InputAdornment, Stack, Autocomplete, Typography, Box } from "@mui/material";
import SearchField from '../../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { List } from '../../../List';
import BlackButton from '../../../overrides/blackButton'
import CssTextField from '../../../overrides/coloredTextField';
import { getConvertedDate, getConvertedDate4 } from '../../../../utils/common';
import { ExportCsv } from "@material-table/exporters";
import { TABLE_HEAD } from '../../../admin/reports/saleReport/saleReportTable'
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { Opt } from '../../../swipeScreen/employee';
import { guestTypeWatcher } from '../../../../redux/actionCreators/guest';
import VendorFilter from '../../../admin/reports/vendorFilter';
// import { useFormik, Form, FormikProvider } from 'formik';

const SaleReportHeader = ({ handleSearch, searchText, handleSubmitAction, rowData, columnData, fromDate, setFromDate, toDate, setToDate, setVendorState, setGuestType, invalidErr, setInvalidErr }) => {

    // const [open, setOpen] = useState(false);

    const [fieldErr, setFieldErr] = React.useState(false)
    const currentDate = new Date()
    const { ReportsReducers: { SaleReportList }, EmployeeReducers: { divisionList }, ProfileReducers: { loginState } } = useSelector((state) => state)

    const dispatch = useDispatch();

    const roleName = loginState[0]?.cRoleName
    const divisionName = loginState[0]?.cDivisionName

    const handleFromChange = (e) => {
        setFromDate(e)
        // setToDate(null)
        // if(fromDate <= toDate){
        //     setInvalidErr({ ...invalidErr, fromInvalidErr:false, toInvalidErr:false})
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr:true, toInvalidErr:true})
        // }
    }

    const handleToChange = (e) => {
        setToDate(e)
        // if(fromDate !== null){
        //     setToDate(e)
        //     setFieldErr(false)
        //     if(toDate <= fromDate){
        //         setInvalidErr({ ...invalidErr, fromInvalidErr:false, toInvalidErr:false})
        //     }
        //     else{
        //         setInvalidErr({ ...invalidErr, fromInvalidErr:true, toInvalidErr:true})
        //     }
        // }
        // else{
        //     setFieldErr(true)
        // }
    }

    const makeHeader = (type) => {
        const arr = []
        TABLE_HEAD(type).forEach(d => {
            arr.push(d.label)
        })
        return arr
    }

    const brHead = makeHeader('Breakfast')
    const dnHead = makeHeader('Dinner')
    const lnHead = makeHeader('Lunch')
    const snHead = makeHeader('Snacks')
    const erHead = makeHeader('Early morning snacks')

    const str = "2023"

    const str1 = str.slice(str.length - 2, str.length);

    // console.log('str1', str1);

    const makeValue = (type) => {
        const getMeal = SaleReportList[0]?.find(d => type === d.cMealName) || {}
        const rowVal = SaleReportList[1]?.filter(m => (m.nMealID === getMeal.nID)) || []
        // console.log("rowVal",rowVal)
        return rowVal.map(d => [d.cMenuName, d.nTotalRate,d.BCP, d.ILC, d.RIOP,  d.OTHERS, d.Quantity, d.Rate, d.Gst, d.ToatlRate])
    }

    const brRow = makeValue('Breakfast')
    const lnRow = makeValue('Lunch')
    const snRow = makeValue('Snacks')
    const dnRow = makeValue('Dinner')
    const erRow = makeValue('Early morning snacks')

    // console.log("brRow",brRow)

    const data = [
        ["", "BreakFast", ""],
        brHead,
        ...brRow,
        ["", "Lunch", ""],
        lnHead,
        ...lnRow,
        ["", "Snacks", ""],
        snHead,
        ...snRow,
        ["", "Dinner", ""],
        dnHead,
        ...dnRow,
        ["", "Early morning snacks", ""],
        erHead,
        ...erRow,
    ];

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={2} sx={{ padding: '30px', display: 'flex' }} >
                    {roleName !== 'Admin' && roleName !== 'FoodAdmin' ?
                        <>
                          <Grid item lg={4} xs={12} md={4} sm={6} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={fromDate}
                                label='From Date'
                                // maxDate={currentDate}
                                onChange={handleFromChange}
                                renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '48px'
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} 
                                    fullWidth {...params} 
                                    error={invalidErr.fromInvalidErr === true ? true : false}
                                    helperText={invalidErr.fromInvalidErr === true ? 'Invalid Date' : ''}
                                />}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item lg={4} xs={12} md={4} sm={6} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={fromDate !== null ? toDate : null}
                                label='To Date'
                                // minDate={fromDate}
                                // maxDate={currentDate}
                                onChange={handleToChange}
                                renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '48px'
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} 
                                    fullWidth {...params}
                                    error={invalidErr.toInvalidErr === true ? true : fieldErr === true ? fromDate !== null ? false : true : false}
                                    helperText={invalidErr.toInvalidErr === true ? 'Invalid Date' : fieldErr === true ? fromDate !== null ? '' : 'Please Choose From Date First..!' : ''}
                                />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {/* <Grid item lg={2} xs={12} md={4} sm={4} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <BlackButton variant='contained' fullWidth
                            sx={{
                                height: '48px',
                                // backgroundColor: 'common.Bblack'
                            }} onClick={() => handleSubmitAction(getConvertedDate(fromDate), getConvertedDate(toDate))}
                        >
                            <Typography variant='t16_400_1'>Submit</Typography>
                        </BlackButton>
                    </Grid> */}

                    <Grid lg={4} xs={12} md={4} sm={6} sx={{ justifyContent: 'center', mt: '10px' }}>
                        <VendorFilter setVendorState={setVendorState} />
                    </Grid>

                    <Grid item lg={4} xs={12} md={4} sm={6} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            // pl={{ xs: 0, md: '40%' }}
                            // width='100%'
                            spacing={2}
                        >

                            <Box width='100%' >
                                <Autocomplete
                                    onChange={(event, value) => {
                                        setGuestType(value.id)
                                        // dispatch(guestTypeWatcher(value.id))
                                    }}
                                    disablePortal
                                    id="combo-box-demo"
                                    name="selectMeal"
                                    //value={formValues.selectType === null ? '' : formValues.selectType}
                                    fullWidth
                                    options={Opt}
                                    defaultValue={Opt[0]}
                                    size="medium"
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <CssTextField sx={{
                                        '& .MuiOutlinedInput-input': {
                                            height: '16px',
                                            color: 'common.white'
                                        },
                                    }} {...params} />}
                                />
                            </Box>

                        </Stack>
                    </Grid>

                    <Grid item lg={4} xs={12} md={4} sm={6} sx={{ justifyContent: 'center', color: 'common.black' }}>
                        <BlackButton variant='contained' fullWidth
                            sx={{
                                height: '48px',
                                // backgroundColor: 'common.Bblack'
                            }} onClick={() => handleSubmitAction(getConvertedDate4(fromDate),   getConvertedDate4(toDate), setFieldErr(false))
                            }
                            >
                                <Typography variant='t16_400_1'>Submit</Typography>
                        </BlackButton>
                    </Grid>

                    <Grid item lg={4} xs={12} md={4} sm={6} sx={{ justifyContent: 'center'}}>                       

                        <CSVLink style={{ textDecoration: 'unset', width: '100%' }} data={data}>
                            <Button variant='contained' fullWidth sx={{ height: '50px' }} endIcon={<DoubleArrowIcon />} >
                                <Typography variant='t16_500'>Export to Excel</Typography>
                            </Button>
                        </CSVLink>
                    </Grid>
                        </>
                        : 
                        <>
                            <Grid item lg={3} xs={12} md={3} sm={3} sx={{ justifyContent: 'center', color: 'common.black' }}>
                            <SearchField
                                        label='Division'
                                        name='divisionId'
                                        fullWidth
                                        size='medium'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ 
                                            '& .MuiOutlinedInput-input': {
                                                height: '16px'
                                            },
                                        }}
                                        value={ divisionName }
                                    />
                            </Grid>
                            <Grid item lg={3} xs={12} md={3} sm={4.5} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={fromDate}
                                label='From Date'
                                // maxDate={currentDate}
                                onChange={handleFromChange}
                                renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '48px'
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} 
                                    fullWidth {...params}
                                    error={invalidErr.fromInvalidErr === true ? true : false}
                                    helperText={invalidErr.fromInvalidErr === true ? 'Invalid Date' : ''} 
                                />}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item lg={3} xs={12} md={3} sm={4.5} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={fromDate !== null ? toDate : null}
                                label='To Date'
                                // minDate={fromDate}
                                // maxDate={currentDate}
                                onChange={handleToChange}
                                renderInput={(params) => <SearchField sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '48px'
                                        },
                                        '& label': {
                                            fontSize: '.9rem'
                                        }
                                    }} 
                                    fullWidth {...params} 
                                    error={invalidErr.toInvalidErr === true ? true : fieldErr === true ? fromDate !== null ? false : true : false}
                                    helperText={invalidErr.toInvalidErr === true ? 'Invalid Date' : fieldErr === true ? fromDate !== null ? '' : 'Please Choose From Date First..!' : ''}
                                />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {/* <Grid item lg={2} xs={12} md={4} sm={4} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <BlackButton variant='contained' fullWidth
                            sx={{
                                height: '48px',
                                // backgroundColor: 'common.Bblack'
                            }} onClick={() => handleSubmitAction(getConvertedDate(fromDate), getConvertedDate(toDate))}
                        >
                            <Typography variant='t16_400_1'>Submit</Typography>
                        </BlackButton>
                    </Grid> */}

                    <Grid lg={3} xs={12} md={3} sm={6} sx={{ justifyContent: 'center', mt: '10px' }}>
                        <VendorFilter setVendorState={setVendorState}/>
                    </Grid>

                    <Grid item lg={4} xs={12} md={4} sm={6} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            // pl={{ xs: 0, md: '40%' }}
                            // width='100%'
                            spacing={2}
                        >

                            <Box width='100%' >
                                <Autocomplete
                                    onChange={(event, value) => {
                                        setGuestType(value.id)
                                        // dispatch(guestTypeWatcher(value.id))
                                    }}
                                    disablePortal
                                    id="combo-box-demo"
                                    name="selectMeal"
                                    //value={formValues.selectType === null ? '' : formValues.selectType}
                                    fullWidth
                                    options={Opt}
                                    defaultValue={Opt[0]}
                                    size="medium"
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <CssTextField sx={{
                                        '& .MuiOutlinedInput-input': {
                                            height: '16px',
                                            color: 'common.white'
                                        },
                                    }} {...params} />}
                                />
                            </Box>

                        </Stack>
                    </Grid>

                    <Grid item lg={4} xs={12} md={4} sm={6} sx={{ justifyContent: 'center', color: 'common.black' }}>
                        <BlackButton variant='contained' fullWidth
                            sx={{
                                height: '48px',
                                // backgroundColor: 'common.Bblack'
                            }} onClick={() => handleSubmitAction(getConvertedDate4(fromDate), getConvertedDate4(toDate), setFieldErr(false))}
                            >
                                <Typography variant='t16_400_1'>Submit</Typography>
                        </BlackButton>
                    </Grid>

                    <Grid item lg={4} xs={12} md={4} sm={6} sx={{ justifyContent: 'center'}} >
                        <CSVLink style={{ textDecoration: 'unset', width: '100%' }} data={data}>
                            <Button variant='contained' fullWidth sx={{ height: '50px' }} endIcon={<DoubleArrowIcon />} >
                                <Typography variant='t16_500'>Export to Excel</Typography>
                            </Button>
                        </CSVLink>
                    </Grid>
                        </>        
                }
                    {/* <Grid item lg={5} xs={12} md={5} sm={12} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px'
                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            onChange={handleSearch}
                            value={searchText}
                        />
                    </Grid> */}
                    {/* <Grid item lg={2} xs={12} md={4} sm={4} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        {
                            roleName  !== 'Admin' && roleName !== 'FoodAdmin' ?
""
                                // <Autocomplete
                                //     id="combo-box-demo"
                                //     name="divisionId"
                                //     fullWidth
                                //     options={divisionList}
                                //     size="medium"
                                //     autoHighlight
                                //     defaultValue={{name: 'RIOP', id: 2}}
                                //     onChange={(event, value) => {
                                //         setDivisionID(value.id);
                                //     }}
                                //     getOptionLabel={(option) => option.name}
                                //     renderInput={(params) => <SearchField {...params}
                                //         name='divisionId'
                                //         required={true}
                                //         label='Division'
                                //         sx={{
                                //             '& .MuiOutlinedInput-input': {
                                //                 height: '16px'
                                //             },
                                           
                                //         }}
                                //     />}
                                // />
                            :
                                <>
                                    <SearchField
                                        label='Division'
                                        name='divisionId'
                                        fullWidth
                                        size='medium'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ 
                                            '& .MuiOutlinedInput-input': {
                                                height: '16px'
                                            },
                                        }}
                                        value={ divisionName }
                                    />
                                </>
                        }
                        </Grid> */}
                </Grid>
            </Card>
        </>
    )
}

export default SaleReportHeader;
import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
// import RosterTable from '../rosterManagement/rosterManagement'

export default function Index({ setPageChange }) {

    const handleChange = () => {
        setPageChange(true);
    }

    return (
        <Grid container sx={{ display: 'flex', flexDirection: 'column', padding: '30px', alignItems: 'center' }}>
            <Grid item lg={12} md={12} sm={12} xs={12} >

                <Typography variant='t32_700' color='common.b'> Create Roster Management </Typography>

            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} paddingTop='10px' >

                <Typography variant='t16_400_1' color='common.ash'> Risus ultricis tristique nulla aliquet enum.</Typography>

            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} paddingTop='10px' >

                <Button variant='contained' sx={{
                    backgroundColor: 'primary',
                    padding: '12px 56px',
                    borderRadius: 50,
                    width: '164px',
                    height: '48px'
                }}
                    onClick={handleChange}
                >
                    <Typography variant='t16_400_1'> Create </Typography>
                </Button>

            </Grid>
        </Grid>
    )
}

import { UPDATE_RATING_LIST, RATING_LIST_WATCHER } from "../../actionTypes/user";

// Worker triggering actionCreators

export function ratingListWatcher(id, data) {
    return { type: RATING_LIST_WATCHER, payload: data, id };
}

// Redux state changing actionCreators

export function updateRatingList(data) {
    return { type: UPDATE_RATING_LIST, payload: data };
}

import React, { useEffect, useRef, useState } from 'react'
import { Grid, Typography, Autocomplete, TextField, Button} from '@mui/material'
import { Box } from '@mui/system'
import DishesCard from '../../../components/user/dishesCard';
import { useSelector } from 'react-redux';
import Context from '../../../context/context';

const Dishes = ({ setOrderCount, activeCateg, orderCount, products, value, setValue,activeMealName}) => {

  const {UserHomeReducers: {mealMenuList, daysOfWeek}} = useSelector(state => state)
  const tempinitsform = useRef();
  const { state } = React.useContext(Context);
  const [mealData, setMealData] = useState([])
  const vendorId = sessionStorage.getItem('vendorId')
// console.log("mealMenuList", mealMenuList)
  const handleChange = (name) => {
    setValue(name || {});
  };

  const initsform = () => {
    daysOfWeek.length && setValue(daysOfWeek[0])
  };

  tempinitsform.current = initsform;

  useEffect(() => {
    tempinitsform.current();
  }, [daysOfWeek]);

  // useEffect(()=>{
  //   daysOfWeek.length && setValue(daysOfWeek[0])
  // },[daysOfWeek])

  useEffect(() => {
    setMealData(mealMenuList)
  }, [mealMenuList])

  useEffect(() => {
    setMealData([])
  }, [vendorId])

  console.log('oisjdnf',mealData)
  
  return (
    <Box mt='44px'>

      {/* <Grid container spacing={2} justifyContent='center' >
        {daysOfWeek.map((d, i) => (
          <Grid item key={d.nID} >
            <Chip onClick={() => handleChange(d)} color={value.nID === d.nID ? 'primary' : 'default'} sx={{ height:40 }} label={
              <Typography variant='t12_500'>
                {d.date}
              </Typography>
            } />
          </Grid>
        ))}
      </Grid> */}

      {/* <Grid container mt='20px' spacing={2.5}>

        <Grid item xs={12} sm={6} md={4} lg={10} xl={8}></Grid>

        <Grid item xs={12} sm={6} md={4} lg={1} xl={1.5}>

          <Button variant="contained" sx={{height: 44}} >
            <Typography variant="t16_400">
              {state.CVENDORNAME}
            </Typography>
          </Button>

        </Grid> */}

        {/* <Grid item xs={12} sm={6} md={4} lg={1} xl={2.5}> */}

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            name="userType"
            size='medium'
            fullWidth={false}
            options={daysOfWeek}
            value={Object.keys(value).length === 0 ? null : value}
            isOptionEqualToValue={(option, value) => option.nID === (value.nID || null)}
            onChange={(event, value) => {
              handleChange(value)
            }}
            autoHighlight
            getOptionLabel={(option) => option.date || ''}
            renderInput={(params) => <TextField {...params}
              label="Select Date"
              sx={{ width: '250px', '& .MuiOutlinedInput-root': { backgroundColor: 'common.white' }, float:'right' }}
            />}
          />

        {/* </Grid> 

      </Grid> */}

      {mealData.length ?
        <Grid container mt='20px' spacing={2.5}>
          {
            mealData.map((d) =>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={d.nID}>
                <DishesCard products={products} setOrderCount={setOrderCount} activeCateg={activeCateg} activeMealName={activeMealName} orderCount={orderCount} value={d} selectedDate={value} />

              </Grid>)
          }

        </Grid>
        :
        <Box mt='20px' height='calc(100vh - 530px)' alignItems='center' display='flex' justifyContent='center' width='100%' >
          <Typography variant='t24_700' color='grey.500' >
            Dishes for this cafeteria currently not Available!
          </Typography>
        </Box>
      }
        
      </Box>
  )
}

export default Dishes
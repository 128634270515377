export const CategoryList = [
    {
        id: 2,
        src: '/static/category/break.svg',
        content: 'Breakfast'
    },
    {
        id: 3,
        src: '/static/category/lunch.svg',
        content: 'Lunch'
    },
    {
        id: 4,
        src: '/static/category/snacks.svg',
        content: 'Snacks'
    },
    {
        id: 5,
        src: '/static/category/dinner.svg',
        content: 'Dinner'
    },
    {
        id: 1,
        src: '/static/category/supper.svg',
        content: 'Supper'
    },
]

export const DishesList = [
    {
        id: 1,
        src: '/static/dishes/dho.jfif',
        name: 'MN1',
        rating: 4,
        reviews: 454,
        price: '42.00'
    },
    {
        id: 2,
        src: '/static/dishes/dho.jfif',
        name: 'MN2',
        rating: 4,
        reviews: 454,
        price: '42.00'
    },
    {
        id: 3,
        src: '/static/dishes/dho.jfif',
        name: 'MN3',
        rating: 4,
        reviews: 454,
        price: '42.00'
    },
    {
        id: 4,
        src: '/static/dishes/dho.jfif',
        name: 'MN4',
        rating: 4,
        reviews: 454,
        price: '42.00'
    },
    {
        id: 5,
        src: '/static/dishes/dho.jfif',
        name: 'MN5',
        rating: 4,
        reviews: 454,
        price: '42.00'
    },
    {
        id: 6,
        src: '/static/dishes/dho.jfif',
        name: 'MN6',
        rating: 4,
        reviews: 454,
        price: '42.00'
    },
  ]

 export const slideBasicArrow = [
    {
      id: 2,
      srcImg: '/static/dishes/rice.avif',
      title: 'Slide Basic',
    },
    {
      id: 3,
      srcImg: '/static/dishes/rice.avif',
      title: 'Slide Basic',
    },
    {
      id: 4,
      srcImg: '/static/dishes/rice.avif',
      title: 'Slide Basic',
    },
    {
      id: 1,
      srcImg: '/static/dishes/rice.avif',
      title: 'Slide Basic',
    },
    {
      id: 5,
      srcImg: '/static/dishes/rice.avif',
      title: 'Slide Basic',
    },
  ]

export const GetRoutes = (type) => {
    const roles = {
        Admin: '/admin',
        Employee: '/user',
        FoodAdmin: '/foodVendor',
        ContractorAdmin: '/contractor',
        GuestAdmin: '/guest',
        CounterAdmin: '/swipe',
        FoodClerk: '/foodClerk',
        Intern: '/user',
        SpecialGuest: '/user',
        SuperAdmin: '/admin',
        NewJoiners: '/user',
    }
    return roles[type]
}

export const WeekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday','Saturday', 'Sunday']

export const companyDetails = {
    EmployeeCompanyName: "Caterpillar India Pvt Ltd",
    EmployeeCompanyAddress: " Division  Melnallathur, Thiruvallur, India  - 602 004 ",
    EmployeeCompanyGSTIN: "33AABCC4615K1ZW",
    SGSTPercent: 0.025,
    CGSTPercent: 0.025,
    CESSPercent: 0
}

import React, { useState } from 'react';
import {
    TableCell,
    Typography,
    TableRow,
    Card,
    Collapse,
    Stack
} from '@mui/material';
import ActionButtons from '../../dashboard/actionButtons';
import RosterChildTable from './childTable';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchField from '../../../overrides/longSearchField';
// import { getConvertedDate3 } from '../../../../utils/common';
import { deleteRosterWatcher, updateRosterWatcher } from '../../../../redux/actionCreators/admin/rosterManagement';
import { useDispatch } from 'react-redux'


const RosterTableRow = ({ rowData, setPageChange, handleRequestSort, order, orderBy }) => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState('list');
    const [editData, setEditData] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [expandOpen, setExpandOpen] = useState(false);

    const requiredFields = ['fromDate', 'ToDate']

    const handleEditChange = (e, name) => {
        // console.log('date',e)
        // const { name, value } = e
        setEditData({ ...editData, [name]: e })
    }

    const handleEdit = () => {
        let data = { ...rowData }
        // console.log('da',data)
        setEditData(data);
        setStatus('edit');
    }

    const handleDelete = async () => {
        let data = { "ACTION": "ROSTERDELETE", nDivisionID: String(rowData.nDivisionID )}
        await dispatch(deleteRosterWatcher(rowData.nID, { data }))
        setStatus('list');
        setDeleteDialogOpen(false);
    }

// -------------------------------------------------------------------------------------------------- submit func --------------------------------------------------------------------------------------------

    const tickIconFunction = async () => {
        let isValid = true
        let data = { ...editData }
        data.ACTION = "rosterUpdate"
        data.nDivisionID = String(data.nDivisionID)
        await Object.keys(data).map((key, i) => {
            if (
                requiredFields.includes(key) &&
                (data[key] === null || data[key] === '' || data[key].length === 0)
            ) {
                isValid = false;
            }
            return null;
        });

        if (isValid) {
            await dispatch(updateRosterWatcher(rowData.nID, data));
            setStatus('list');
        }
    }

    const cancelIconFunction = () => {
        setEditData({});
        setStatus('list');
    }

    // const viewData = rowData.viewData ? rowData.viewData.length ? rowData.viewData[0] : {} : {}

    return (
        <>
            <TableRow
                hover={status === 'list' ? true : false}
                tabIndex={-1}
            >
                {status === 'list' ?
                    <>
                <TableCell align="left">
                    <Typography variant='t14_600' >
                        {rowData.cDivisionName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.cVendorName}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.fromDate.slice(0,10)}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography variant='t14_600'>
                        {rowData.ToDate.slice(0,10)}
                    </Typography>
                </TableCell>
                </>
                    :
                    <>
                        <TableCell align="left">
                            <Typography variant='t14_600' >
                                {rowData.cDivisionName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant='t14_600'>
                                {rowData.cVendorName}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={editData.fromDate}
                                        // label='From Date'
                                        name="fromDate"
                                        onChange={(e) => handleEditChange(e, 'fromDate')}
                                        renderInput={(params) =>
                                            <SearchField
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {                                               
                                                        height: 50                                                      
                                                    },                                                  
                                                    '& label': {                                               
                                                        top: -2                                                       
                                                    }                                                   
                                                }}
                                                fullWidth
                                                {...params}
                                                error={editData.fromDate === null ? true : false}
                                                helperText={editData.fromDate === null ? 'Select From Date' : ''}
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </TableCell>

                        <TableCell align="left">
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={editData.ToDate}
                                        // label='To Date'
                                        name="ToDate"
                                        onChange={(e) => handleEditChange(e, 'ToDate')}
                                        renderInput={(params) =>
                                            <SearchField
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {                                                
                                                        height: 50                                                       
                                                    },                                                   
                                                    '& label': {                                               
                                                        top: -2                                                       
                                                    }                                                   
                                                }}
                                                fullWidth
                                                {...params}
                                                error={editData.ToDate === null ? true : false}                                               
                                                helperText={editData.ToDate === null ? 'Select To Date' : ''}                                               
                                        />}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </TableCell>
                    </>
                }
                <TableCell align="right" sx={{ minWidth: '115px' }}>
                <ActionButtons setExpandOpen={setExpandOpen} expandOpen={expandOpen} status={status} setStatus={setStatus} type={'roster'} setEditData={setEditData} rowData={rowData} tickIconFunction={tickIconFunction} handleDelete={handleDelete} handleEdit={handleEdit} cancelIconFunction={cancelIconFunction} setDeleteDialogOpen={setDeleteDialogOpen} deleteDialogOpen={deleteDialogOpen} />
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell colSpan={6} style={{ padding: 0, paddingLeft: '30px', paddingRight: '30px' }}>
                    <Collapse in={expandOpen} timeout="auto" unmountOnExit>
                        <Card sx={{ bgcolor: 'primary.table' }} >
                            <RosterChildTable expandOpen={expandOpen} rowData={rowData} handleRequestSort={handleRequestSort} orderBy={orderBy} order={order} status={status} />
                        </Card>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default RosterTableRow
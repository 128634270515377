import { Card, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useRef } from "react";
import Breadcrumbs from "../../../../components/breadcrumbs";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustTextField from '../../../../components/overrides/searchField'
import OrderHistoryTable from './table'
import { useDispatch, useSelector } from "react-redux";
import { orderHistoryWatcher } from "../../../../redux/actionCreators/user/currentOrder";
import BlackButton from "../../../../components/overrides/blackButton";
import _ from 'lodash'
import { getConvertedDate } from "../../../../utils/common";
import Context from "../../../../context/context";

const OrderHistory = () => {
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [fieldErr, setFieldErr] = React.useState(false)
  const currentDate = new Date()
  const [page, setPage] = React.useState(1);
  const [invalidErr, setInvalidErr] = React.useState({ fromInvalidErr : false, toInvalidErr : false })
  const tempinitsform = useRef();

  const dispatch = useDispatch()
  const { state } = useContext(Context);
  const {ProfileReducers: {loginState}, CurrentOrderReducers: { orderHistory }} = useSelector(state => state)
  const nUserID = loginState[0]?.nID || 0
  const userName = loginState[0]?.cUserName || 0

  const vendorId = sessionStorage.getItem('vendorId')

  // console.log('loginState', loginState);

  const initsform = () => {
    const data = {
      nUserID,
      "cAction": "History",
      CVENDORID: vendorId !== "" ? `${vendorId}` : 'null'
      // "Fdate": "10/06/2022",
      // "Tdate": "10/06/2022"
    }
    setFromDate(null)
    setToDate(null)
    dispatch(orderHistoryWatcher(data))
  };

  tempinitsform.current = initsform;

  useEffect(() => {
    tempinitsform.current();
  }, [vendorId]);

  // useEffect(() => {
  //   const data = {
  //     nUserID,
  //     "cAction": "History",
  //     // "Fdate": "10/06/2022",
  //     // "Tdate": "10/06/2022"
  //   }
  //   dispatch(orderHistoryWatcher(data))
  //   // let today = new Date()
  //   // let toDate= new Date()
  //   // toDate.setDate(toDate.getDate()+3)
  //   // setFromDate(today)
  //   // setToDate(toDate)
  // }, [])

    const handleFromChange = (e) => {
        setFromDate(e)
        setToDate(new Date())
        // if(fromDate <= toDate){
        //     setInvalidErr({ ...invalidErr, fromInvalidErr:false, toInvalidErr:false})
        // }
        // else{
        //     setInvalidErr({ ...invalidErr, fromInvalidErr:true, toInvalidErr:true})
        // }
    }

    const handleToChange = (e) => {
      setToDate(e)
      // if(fromDate !== null){
      //   setToDate(e)
      //   setFieldErr(false)
      //   if(toDate <= fromDate){
      //     setInvalidErr({ ...invalidErr, fromInvalidErr:false, toInvalidErr:false})
      //   }
      //   else{
      //       setInvalidErr({ ...invalidErr, fromInvalidErr:true, toInvalidErr:true})
      //   }
      // }
      // else{
      //     setFieldErr(true)
      // }
    }

  const handleSubmit = () => {
    // if (fromDate === null || toDate === null) {
    //   setFromDate(new Date())
    //   setToDate(new Date())
    // }
    let currentDate = new Date()
    // let curDate = getConvertedDate(currentDate)
    console.log('sdfert',toDate, fromDate)
    // if(toDate >= fromDate && toDate <= currentDate && fromDate <= currentDate){
      // let currentDate = new Date()
      const data = {
        nUserID,
        "cAction": "HistorySearch",
        "Fdate": getConvertedDate(fromDate) !== null ? getConvertedDate(fromDate) : currentDate,
        "Tdate": getConvertedDate(toDate) !== null ? getConvertedDate(toDate) : currentDate,
        CVENDORID: vendorId !== "" ? `${vendorId}` : 'null',
      }
      dispatch(orderHistoryWatcher(data))
      setInvalidErr({ ...invalidErr, fromInvalidErr : false, toInvalidErr : false })
      setPage(1)
    // }
    // else{
    //   setInvalidErr({ ...invalidErr, fromInvalidErr : true, toInvalidErr : true })
    // }
    setFieldErr(false)
  }

  const groupOrder = _.groupBy(orderHistory, (d) => d.nBookingID);

  return (
    <Box p="24px">
      <Breadcrumbs content={["My Order", "Order History"]} />

      <Box mt="14px" display='flex' flexDirection={{xs:'column', md: 'row'}} >
        <Typography variant="t24_700">User Order History</Typography>
        <Stack direction='row' ml={{md:'auto', xs:0}} mt={{md:'0px', xs: '20px'}} spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={fromDate}
              label='From Date'
              // maxDate={currentDate}
              onChange={handleFromChange}
              renderInput={(params) => <CustTextField {...params} sx={{
                width: 176,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'common.white',
                },
              }} 
              size='small'
              error={invalidErr.fromInvalidErr === true ? true : false}
              helperText={invalidErr.fromInvalidErr === true ? 'Invalid Date' : ''}
              />}
            />
          </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={fromDate !== null ? toDate : null}
              label='To Date'
              // minDate={fromDate}
              // maxDate={currentDate}
              onChange={handleToChange}
              // disabled={fromDate !== null ? false : true}
              renderInput={(params) => <CustTextField {...params} sx={{
                width: 176,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'common.white',
                },
              }} 
              size='small'               
              error={invalidErr.toInvalidErr === true ? true : fieldErr === true ? fromDate !== null ? false : true : false}
              helperText={invalidErr.toInvalidErr === true ? 'Invalid Date' : fieldErr === true ? fromDate !== null ? '' : 'Please Choose From Date First..!' : ''}
              />}
            />
          </LocalizationProvider>

          <BlackButton onClick={handleSubmit} sx={{pl:'20px', pr:'20px'}}>
            <Typography variant="t14_400" >
            Submit
            </Typography>
          </BlackButton>
        </Stack>
        
      </Box>

      <Box mt='24px'>
      <Card style={{ boxShadow: (theme)=> `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "6px 6px 0px 0px", padding: '20px' }}>
          <OrderHistoryTable page={page} setPage={setPage} groupOrder={groupOrder} userName={userName} />

        </Card>
      </Box>
    </Box>
  );
};

export default OrderHistory;

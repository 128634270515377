//User
import { Navigate } from "react-router-dom";

import ContactPage from "../pages/user/home/ContactPage";
import TermsAndCondition from "../pages/user/home/TermsAndCondition";
import AboutUs from "../pages/user/home/AboutUs";

//layout
import Layout from "../layouts";

const NoLogin = {
  path: "/user",
  element: <Layout />,
  children: [
    { index: true, element: <Navigate to="/login" replace /> },
    { path: "contactUs", element: <ContactPage /> },
    { path: "terms&condition", element: <TermsAndCondition /> },
    { path: "aboutUs", element: <AboutUs /> },
  ],
};

export default NoLogin;

import React, { useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import { useSelector } from 'react-redux'

export default function MenuItemTabs({ setTableArr, tabVal, setTabVal }) {
    const { MenuItemMasterReducers: { menuItemMasterList } } = useSelector((state) => state)
    const tempinitsform = useRef();

    // console.log("menuItemMasterList",menuItemMasterList)

    const initsform = () => {
        if (tabVal === 'All') {
            setTableArr(menuItemMasterList)
        }
        // if (tabVal === 'Kiosk') {
        //     let tableVal = menuItemMasterList.filter(m => m.ctype === 'Kiosk') || []
        //     setTableArr(tableVal)
        // }
        if (tabVal === 'Contractor' || tabVal === 'Employee'|| tabVal === 'Guest' || tabVal === 'SpecialGuest') {
            let tableVal = menuItemMasterList.filter(m => m.ctype === tabVal) || []
            setTableArr(tableVal)
        }
    };
    
    tempinitsform.current = initsform;
    
    useEffect(() => {
       tempinitsform.current();
    }, [tabVal,menuItemMasterList]);

    // console.log('ppp', menuItemMasterList.filter(m => m.ctype ))

    // useEffect(() => {

    //     if (tabVal === 'All') {
    //         setTableArr(menuItemMasterList)
    //     }
    //     if (tabVal === 'Kiosk') {
    //         let tableVal = menuItemMasterList.filter(m => m.ctype === 'SpecialGuest') || []
    //         setTableArr(tableVal)
    //     }
    //     if (tabVal === 'Contractor' || tabVal === 'Employee') {
    //         let tableVal = menuItemMasterList.filter(m => m.ctype === tabVal) || []
    //         setTableArr(tableVal)
    //     }
    // },[tabVal,menuItemMasterList])

    const handleChange = (name) => {
        setTabVal(name);
    };

    const arr = ['All', 'Employee', 'Contractor', 'Guest', 'SpecialGuest'];

    return (
        <Box sx={{ width: "100%" }}>
            <Box>
                <Grid container>
                    {arr.map((d, i) => (
                        <Grid
                            key={d}
                            sx={{
                                "&:first-of-type": {
                                    borderRadius: "8px 0 0 8px"
                                },
                                "&:last-of-type": {
                                    borderRadius: "0px 8px 8px 0px"
                                },
                                height: "50px",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer'
                            }}
                            bgcolor={tabVal === d ? "common.tabclr" : "common.bg"}
                            color={tabVal === d ? "common.white" : "common.black"}

                            item
                            xs={6} sm={2.4} md={2.4} lg={2.4}
                            onClick={() => handleChange(d)}
                        >
                            <Typography variant='t16_500' >
                                {d}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}




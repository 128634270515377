import React from 'react'
import { Box, Card, Typography } from '@mui/material'
import Breadcrumbs from '../../../components/breadcrumbs'
import Cab from '../../../components/user/cabBooking/cabBooking'

export default function CabBooking() {

    return (
        <>
            <Box p='30px'>
                <Breadcrumbs content={["Cab Booking"]} />
                <Box p='15px 0'>
                    <Typography variant='t24_700' > Cab Booking </Typography>
                </Box>
                <Card style={{ boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)", borderRadius: "20px 20px 0px 0px", padding: '20px' }}>
                    <Cab />
                </Card>
            </Box>
        </>
    )
}

// Contractor
import GuestDashboard from '../pages/guest/dashboard';
import GuestDeliveryHistory from '../pages/guest/reports/deliveryHistory'
import GuestBooking from '../pages/guest/reports/guestBooking';
import GuestOrderBooking from '../pages/guest/dashboard/orderBooking/orderBooking';
import GRoleSwipeHistory from '../pages/guest/reports/GRoleSwipeHistory';
//profile
import Profile from '../pages/profile'
import ChangePassword from '../pages/changePassword'
//layout
import NavLayout from '../layouts/dashboard';

const Guest =  {
  path: '/guest',
  element: <NavLayout />,
  children: [
    { index: true, element: <GuestDashboard /> },
    { path: 'bookingHistory', element: <GuestBooking /> },
    { path: 'deliveryHistory', element: <GuestDeliveryHistory /> },
    { path: 'orderBooking', element: <GuestOrderBooking /> },
    { path: "swipeHistory", element: <GRoleSwipeHistory /> },
    { path: "profile", element: <Profile /> },
    { path: "changePassword", element: <ChangePassword /> },
  ]
}

export default Guest;

import { CREATE_VENDOR_WATCHER, UPDATE_VENDOR_WATCHER, DELETE_VENDOR_WATCHER, GET_VENDOR_WATCHER, VENDOR_LIST, GET_VENDOR_ONLY_WATCHER, VENDOR_ONLY_LIST } from "../../actionTypes/admin";

// Worker triggering actionCreators

export function getVendorWatcher(data) {
    return { type: GET_VENDOR_WATCHER, payload: data };
}

export function getVendorOnlyWatcher(data) {
    return { type: GET_VENDOR_ONLY_WATCHER, payload: data };
}

export function createVendorWatcher(data, setOpen) {
    return { type: CREATE_VENDOR_WATCHER, payload: data, setOpen };
}

export function updateVendorWatcher(id, data) {
    return { type: UPDATE_VENDOR_WATCHER, payload: data, id };
}

export function deleteVendorWatcher(id, data) {
    return { type: DELETE_VENDOR_WATCHER, payload: data, id };
}


// Redux state changing actionCreators
export function vendorList(data) {
    return { type: VENDOR_LIST, payload: data };
}

export function vendorOnlyList(data){
    return { type: VENDOR_ONLY_LIST, payload: data}
}

import React, { useState } from 'react';
import { Card, Grid, Button, InputAdornment, Typography } from "@mui/material";
import SearchField from '../../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CreationDialog from '../../../dialog';
import NewPushNotification from './newPushNotify'

const PushNotificationHeader = ({ handleSearch, searchText }) => {

    const [open, setOpen] = useState(false);

    return (
        // <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '30px', display: 'flex', justifyContent: 'center' }} >
                    <Grid item lg={9} xs={12} md={8} sm={8} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px',
                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            onChange={handleSearch}
                            value={searchText}
                        />
                    </Grid>
                    <Grid item lg={3} xs={12} md={4} sm={4} sx={{ justifyContent: 'center', color: 'common.black' }} >
                        <Button variant='contained' sx={{ height: '50px' }} fullWidth endIcon={<DoubleArrowIcon />} onClick={() => setOpen(true)}>
                            <Typography variant='t18_500'>Send Notification</Typography>
                        </Button>
                    </Grid>
                </Grid>

                <CreationDialog open={open} setOpen={setOpen} maxWidth='sm'>
                    <NewPushNotification setOpen={setOpen} />
                </CreationDialog>
            </Card>
        // </>
    )
}

export default PushNotificationHeader;
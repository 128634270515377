import React from 'react';
import { Card, Grid, Button, InputAdornment, Typography } from "@mui/material";
import SearchField from '../../../../overrides/longSearchField';
import SearchIcon from "@mui/icons-material/Search";
import { ExportCsv } from "@material-table/exporters";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const Header = ({ handleSearch, searchText, rowData, columnData, setViewDialogOpen }) => {

    return (
        <>
            <Card style={{ boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`, borderRadius: "20px 20px 0px 0px" }}>
                <Grid container spacing={3} sx={{ padding: '20px', display: 'flex', justifyContent: 'center' }} >
                    <Grid item lg={7} xs={12} md={7} sm={12} sx={{ justifyContent: 'left' }}>
                        <SearchField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: 42
                                }
                            }}
                            placeholder='Search'
                            fullWidth
                            onChange={handleSearch}
                            value={searchText}
                        />
                    </Grid>
                    <Grid item lg={2} xs={4} md={2} sm={3} sx={{ justifyContent: 'center' }} >
                        <Button onClick={() => setViewDialogOpen(false)}
                            sx={{
                                height: '42px',
                                ml: '30px',
                                minWidth: '80px',
                                backgroundColor: 'common.black',
                                marginTop: 'auto',
                                "&:hover": {
                                    backgroundColor: "common.black"
                                }
                            }}
                            variant='contained'
                            size='medium'
                        >
                            <Typography variant='t14_400' color='grey.0'>Back</Typography>
                        </Button>
                    </Grid>
                    <Grid item lg={3} xs={8} md={3} sm={5} sx={{ justifyContent: 'center' }} >
                        <Button variant='contained' fullWidth sx={{ height: '42px' }} endIcon={<DoubleArrowIcon />} onClick={() => ExportCsv(columnData, rowData, "Exported file")}>
                            <Typography variant='t16_500'>Export to Excel</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default Header;
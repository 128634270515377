import React from "react";
import { useState, useEffect } from "react";
import Scrollbar from "../../../Scrollbar";
import SpecialGuestHistoryTableHead from "./tableHead";
import { useDispatch, useSelector } from "react-redux";
import { getUserHistoryWatcher } from "../../../../redux/actionCreators/admin/reports";
import SplGuestHistoryTableRow from "./tableRow";
import {
  Table,
  TableBody,
  TableContainer,
  Pagination,
  Box,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

export const NORMAL_GUEST_TABLE_HEAD = [
  {
    id: "EmployeeName",
    label: "Employee Name",
    alignRight: false,
    title: "Employee Name",
    field: "EmployeeName",
  },
  {
    id: "UserName",
    label: "User Name",
    alignRight: false,
    title: "User Name",
    field: "UserName",
  },
  {
    id: "OrderDate",
    label: "Order Date",
    alignRight: false,
    title: "Order Date",
    field: "OrderDate",
  },
  {
    id: "Breakfast",
    label: "Breakfast",
    alignRight: false,
    title: "Breakfast",
    field: "BreakFast(NormalGuest)",
  },
  {
    id: "Dinner",
    label: "Dinner",
    alignRight: false,
    title: "Dinner",
    field: "Dinner(NormalGuest)",
  },
  {
    id: "Early morning snacks",
    label: "Early morning snacks",
    alignRight: false,
    title: "Early morning snacks",
    field: "Early morning snacks(NormalGuest)",
  },
  {
    id: "Lunch",
    label: "Lunch",
    alignRight: false,
    title: "Lunch",
    field: "Lunch(NormalGuest)",
  },
  {
    id: "vendorName",
    label: "Vendor Name",
    alignRight: false,
    title: "Vendor Name",
    field: "vendorName",
  },
  {
    id: "Snacks",
    label: "Snacks",
    alignRight: false,
    title: "Snacks",
    field: "Snacks(NormalGuest)",
  },
];

export const VIP_TABLE_HEAD = [
  {
    id: "EmployeeName",
    label: "Employee Name",
    alignRight: false,
    title: "Employee Name",
    field: "EmployeeName",
  },
  {
    id: "UserName",
    label: "User Name",
    alignRight: false,
    title: "User Name",
    field: "UserName",
  },
  {
    id: "OrderDate",
    label: "Order Date",
    alignRight: false,
    title: "Order Date",
    field: "OrderDate",
  },
  {
    id: "Breakfast",
    label: "Breakfast",
    alignRight: false,
    title: "Breakfast",
    field: "BreakFast",
  },
  {
    id: "Dinner",
    label: "Dinner",
    alignRight: false,
    title: "Dinner",
    field: "Dinner",
  },
  {
    id: "Early morning snacks",
    label: "Early morning snacks",
    alignRight: false,
    title: "Early morning snacks",
    field:
      "Early morning snacks",
  },
  {
    id: "Lunch",
    label: "Lunch",
    alignRight: false,
    title: "Lunch",
    field: "Lunch",
  },
  {
    id: "Snacks",
    label: "Snacks",
    alignRight: false,
    title: "Snacks",
    field: "Snacks",
  },
  {
    id: "Breakfast(VIPGuest)",
    label: "Breakfast (VIPGuest)",
    alignRight: false,
    title: "Breakfast (VIPGuest)",
    field: "BreakFast(VIPGuest)",
  },
  {
    id: "Dinner(VIPGuest)",
    label: "Dinner (VIPGuest)",
    alignRight: false,
    title: "Dinner (VIPGuest)",
    field: "Dinner(VIPGuest)",
  },
  {
    id: "Early morning snacks(VIPGuest)",
    label: "Early morning snacks (VIPGuest)",
    alignRight: false,
    title: "Early morning snacks (VIPGuest)",
    field:
      "Early morning snacks(VIPGuest)",
  },
  {
    id: "Lunch(VIPGuest)",
    label: "Lunch (VIPGuest)",
    alignRight: false,
    title: "Lunch (VIPGuest)",
    field: "Lunch(VIPGuest)",
  },
  {
    id: "Snacks(VIPGuest)",
    label: "Snacks (VIPGuest)",
    alignRight: false,
    title: "Snacks (VIPGuest)",
    field: "Snacks(VIPGuest)",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}
const SpecialGuestHistoryTable = ({
  searchVal,
  searchText,
  setPageChange,
  page,
  setPage,
  SplGuestList,
  type,
  cafeteriaId
}) => {
  let firstIndex = 0;
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [rowsPerPage] = useState(5);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // const filteredData = [{ EmployeeName: 'aaa' }]  
  
  const tableData = searchText ? searchVal : SplGuestList;
  const filteredData = applySortFilter(
    tableData,
    getComparator(order, orderBy)
  );
  const isUserNotFound = filteredData?.length === 0;
  return (
    <>
      {" "}
      <Box>
        {" "}
        <Scrollbar>
          {" "}
          <TableContainer sx={{ overflow: "unset" }}>
            {" "}
            <Table>
              {" "}
              <SpecialGuestHistoryTableHead
                order={order}
                orderBy={orderBy}
                headLabel={ NORMAL_GUEST_TABLE_HEAD }
                onRequestSort={handleRequestSort}
                action={true}
                invoice={true}
              />{" "}
              <TableBody>
                {" "}
                {filteredData
                  ?.slice(
                    firstIndex + rowsPerPage * (page - 1),
                    rowsPerPage * page
                  )
                  // .slice(page * 5, page * 5 + 5)
                  .map((m, i) => {
                    return (
                      <SplGuestHistoryTableRow
                        key={i}
                        rowData={m}
                        index={i}
                        setPageChange={setPageChange}
                        type={type}
                        cafeteriaId={cafeteriaId}
                      />
                    );
                  })}
              </TableBody>{" "}
              {isUserNotFound && (
                <TableBody>
                  {" "}
                  <TableRow display="flex">
                    {" "}
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      {" "}
                      <Typography variant="t18_500">
                        {" "}
                        No records found{" "}
                      </Typography>{" "}
                    </TableCell>{" "}
                  </TableRow>{" "}
                </TableBody>
              )}
            </Table>{" "}
          </TableContainer>{" "}
        </Scrollbar>{" "}
      </Box>{" "}
      <Box spacing={2} display="flex" justifyContent="right" paddingTop="20px">
        {" "}
        <Pagination
          shape="rounded"
          color="primary"
          count={Math.ceil(filteredData?.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />{" "}
      </Box>{" "}
    </>
  );
};
export default SpecialGuestHistoryTable;

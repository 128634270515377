import { Box, Card, Grid, Rating, Typography } from "@mui/material";
import React from "react";
import ImageTemp from "./imageTemp";

const DishesCard = ({ value, setOrderCount, activeCateg, orderCount, products, selectedDate, kiosk, isViewCart }) => {
  const {rate, reviews=0} = value
  return (
    <Card
      sx={{
        p: "16px",
        borderRadius: "12px",
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      
      <ImageTemp isViewCart={isViewCart} kiosk={kiosk} selectedDate={selectedDate} products={products} setOrderCount={setOrderCount} activeCateg={activeCateg} orderCount={orderCount} value={value} />

      <Box mt='auto'>
      <Grid container mt='16px'>
            <Grid item xs={6}>
            <Rating name="read-only" size="small" value={rate} readOnly />
            </Grid>
            <Grid item xs={6} alignItems='center' display='flex'>
            <Typography variant="t12_400" color="grey.500">
          {`(${reviews} reviews)`}
        </Typography>
            </Grid>
          </Grid>
      </Box>
      
    </Card>
  );
};

export default DishesCard;
